import { Button, IconButton, Modal, TextField } from "@mui/material"
import CloseIcon from "@mui/icons-material/Close";
import { useCallback, useEffect, useMemo } from "react";
import { useState } from "react";
import { createTenant } from "app/store/evocsSlice/tenants/tenantsActions";
import TenantSelect from "../SharingComponents/Inputs/TenantSelect";
import { modalsComponentNames } from 'app/configs/routesNames';
import { useTranslation } from 'react-i18next';
import { translate } from "src/utilities/utils";
import { setGlobalError, setGlobalSuccess } from "app/store/evocsSlice/globalError/errorActions";
import LoadingGif from "../SharingComponents/LoadingGif";
import ActionSelect from "../SharingComponents/Inputs/ActionSelect";
import GroupActionSelect from "../SharingComponents/Inputs/GroupActionSelect";
import ViewSelect from "../SharingComponents/Inputs/ViewSelect";
import { useSelector } from "react-redux";
import AlertCategorySelect from "../SharingComponents/Inputs/AlertCategorySelect";

const styles = {
  containerStyle: {
    position: "absolute",
    display: "flex",
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    background: "#2d3740",
    padding: "1rem",
    borderRadius: "1rem",
    height: "80vh",
    width: "95%",
    maxWidth: "800px",
    flexDirection: "column"
  },
  loadingPopup: {
    position: "absolute",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    background: "rgba(255, 255, 255, .5)",
    top: 0,
    right: 0,
    bottom: 0,
    left: 0
  }
}

const requiredInputs = ["name", "views", "limitUsers"];

const PermittedActions = ({listViews, permittedActions, groupActions, onChangeGroupActions, onChangeActions}) => {
  const { t } = useTranslation("evocs");
  const actions = useSelector((state) => state.configuration.constants.action);
  const views = useSelector((state) => state.configuration.constants.view_name);
  const [actionsList, setActionsList] = useState({});
  const [groupActionsList, setGroupActionsList] = useState({});
  const [selectedActions, setSelectedActions] = useState(permittedActions);
  const [selectedGroupActions, setSelectedGroupActions] = useState(groupActions);
  const handleTranslate = (textId, general = false) => {
    if (general) return translate(t, textId);
    return translate(t, textId, modalsComponentNames.MODALS);
  };

  const getGroupActionsList = useCallback(() => {
    let list = {};
    listViews.forEach(viewId => {
      list[viewId] = [];
      views[viewId].actions.forEach((actionId) => {
        const groupId = actions[actionId].groupId;
        if(!list[viewId].includes(groupId)) list[viewId].push(groupId);
      })
    });
    return list;
  }, [listViews, views, actions]);

  const getActionsList = useCallback((groupActions) => {
    let list = {};
    listViews.forEach(viewId => {
      list[viewId] = [];
      groupActions[viewId]?.forEach((groupId) => {
        list[viewId] = list[viewId].concat(views[viewId].actions.filter((actionId) => actions[actionId].groupId === groupId));
      })
    });
    return list;
  }, [listViews]);

  useEffect(() => {
    setGroupActionsList(getGroupActionsList());
    setActionsList(getActionsList(selectedGroupActions));
  }, [getGroupActionsList, getActionsList]);

  useEffect(() => {
    setSelectedGroupActions(groupActions);
  }, [groupActions])

  useEffect(() => {
    setSelectedActions(permittedActions);
  }, [permittedActions])



  const handleChangeGroupActions = (viewId, value) => {
    const newSelectedGroupActions = {...selectedGroupActions, [viewId]: value};
    const newList = getActionsList(newSelectedGroupActions);
    let newSelectedActions = {};
    listViews.forEach((viewId) => {
      const newSelected = newList[viewId].filter((actionId) => !actionsList[viewId]?.includes(actionId));
      const deselected = actionsList[viewId]?.filter((actionId) => !newList[viewId]?.includes(actionId)) ?? [];
      const oldSelected = selectedActions[viewId]?.filter((actionId) => !deselected?.includes(actionId)) ?? [];
      newSelectedActions[viewId] = oldSelected?.concat(newSelected) ?? [];
    })
    setActionsList(newList);
    setSelectedActions(newSelectedActions);
    setSelectedGroupActions(newSelectedGroupActions);
    onChangeActions?.(newSelectedActions);
    onChangeGroupActions?.(newSelectedGroupActions);
  }

  const handleChangeActions = (viewId, value) => {
    const newSelected = {...selectedActions, [viewId]: value}
    setSelectedActions(newSelected);
    onChangeActions?.(newSelected);
  }

  const isActionsSelectDisabled = (viewId) => {
    if(selectedGroupActions[viewId] && selectedGroupActions[viewId].length > 0) return false;
    return true;
  }

  return listViews.length > 0 && (
    <div>
      <h4 style={{marginBottom: "1rem"}}>{handleTranslate("PERMITTED_ACTIONS", true)}:</h4>
      <div style={{ display: "flex", flexDirection: "column", gap: "1rem" }}>
        {listViews?.map((viewId) => (
          <div key={viewId} style={{ display: "flex", gap: "1rem", alignItems: "center" }}>
            <label style={{ width: "100%", maxWidth: "200px" }}>{views[viewId].view_name}</label>
            <GroupActionSelect multiple groupsId={groupActionsList[viewId]} value={selectedGroupActions[viewId]} onChange={(value) => handleChangeGroupActions(viewId, value)}/>
            <ActionSelect multiple disabled={isActionsSelectDisabled(viewId)} actionsId={actionsList[viewId]} value={selectedActions[viewId]} onChange={(value) => handleChangeActions(viewId, value)}/>
          </div>
        ))}
      </div>
    </div>
  )
}


const FormCreation = ({ onFinally, onPending }) => {
  const { t } = useTranslation("evocs");
  const views = useSelector((state) => state.configuration.constants.view_name);
  const viewsWithActions = useMemo(() => {
    return Object.keys(views).filter((viewId) => views[viewId].activable === 0 && views[viewId].actions.length > 0);
  }, [views]);
  const defaultInputs = useMemo(() => {
    let permittedActions = {}, groupActions={};
    viewsWithActions.forEach((viewId) => {
      permittedActions[viewId] = [];
      groupActions[viewId] = [];
    });
    return {
      tenantImage: "",
      name: "",
      email: "",
      headquarter: "",
      limitUsers: 1,
      tenants: [],
      views: [],
      categoryAlerts: [],
      permittedActions: permittedActions,
      groupActions: groupActions
    }
  }, [viewsWithActions]);
  const [inputs, setInputs] = useState(defaultInputs);
  const [listViews, setListViews] = useState([]);
  const [buttonDisabled, setButtonDisabled] = useState(true);


  const handleTranslate = (textId, general = false) => {
    if (general) return translate(t, textId);
    return translate(t, textId, modalsComponentNames.MODALS);
  };

  useEffect(() => {
    const disabled = requiredInputs.some((key) => inputs[key] === "" || inputs[key].length === 0);
    setButtonDisabled(disabled);
  }, [inputs]);

  useEffect(() => {
    if(!inputs) return;
    setListViews([...viewsWithActions, ...inputs.views]);
  }, [inputs?.views])


  const handleChangeInput = (key, value) => {
    setInputs((prev) => ({ ...prev, [key]: value }));
  };

  const handleChangeEnabledViews = (value) => {
    const stationViewId = "655f295e86458728760700b5", singleStationViewId = "655f295f86458728760700b7";
    let newSelectedView = value.filter((viewId) => !inputs.views.includes(viewId));
    let deselectedViews = inputs.views.filter((viewId) => !value.includes(viewId));
    let newActions = {...inputs.permittedActions};
    let newGroupActions = {...inputs.groupActions};
    newSelectedView.forEach((viewId) => {
      newActions[viewId] = [];
      newGroupActions[viewId] = [];
      if(viewId === stationViewId) {
        value.push(singleStationViewId);
        newActions[singleStationViewId] = [];
        newGroupActions[singleStationViewId] = [];
      }
      else if(viewId === singleStationViewId) {
        value.push(stationViewId);
        newActions[stationViewId] = [];
        newGroupActions[stationViewId] = [];
      }
    });
    deselectedViews.forEach((viewId) => {
      value = value.filter((id) => id !== viewId);
      delete newActions[viewId];
      delete newGroupActions[viewId];
      if(viewId === stationViewId || viewId === singleStationViewId) {
        value = value.filter((id) => id !== stationViewId && id !== singleStationViewId);
        delete newActions[stationViewId];
        delete newActions[singleStationViewId];
        delete newGroupActions[stationViewId];
        delete newGroupActions[singleStationViewId];
      }
    });
    setInputs((prev) => ({...prev, views: value, permittedActions: newActions, groupActions: newGroupActions}));
  }

  const handleConfirm = () => {
    const tenantImage = inputs.tenantImage;
    const imageWithoutPrefix = tenantImage.substring(tenantImage.indexOf(',') + 1);
    onPending?.();
    createTenant(inputs.name, inputs.email, inputs.headquarter, inputs.limitUsers, inputs.tenants, imageWithoutPrefix, inputs.categoryAlerts, listViews, inputs.permittedActions)
    .then(() => setGlobalSuccess(true, "MODAL_SUCCESS_CREATION_TENANT"))
    .catch(() => setGlobalError(true))
    .finally(onFinally)
  };

  const handleChangeImage = (event) => {
    const file = event.target.files[0];

    if (file) {
      const reader = new FileReader();

      reader.onloadend = () => {
        handleChangeInput("tenantImage", reader.result);
      };
      reader.readAsDataURL(file);
    }
  };

  return (
    <div style={{ display: "flex", flexDirection: "column", gap: "2rem", padding: "2rem" }}>
      <h2 style={{ fontWeight: "bold" }}> {handleTranslate("NEW_TENANT")} </h2>
      <div>
        <h4 style={{marginBottom: "1rem"}}>{handleTranslate("TENANT_LOGO")}: </h4>
        <TextField sx={{ width: "100%", maxWidth: "300px" }} type="file" onChange={handleChangeImage} />
      </div>
      <TextField
        sx={{ width: "100%", maxWidth: "300px" }}
        required
        value={inputs.name}
        onChange={(e) => handleChangeInput("name", e.target.value)}
        label={handleTranslate("NAME", true)}
      />
      <TextField
        sx={{ width: "100%", maxWidth: "300px" }}
        value={inputs.email}
        onChange={(e) => handleChangeInput("email", e.target.value)}
        label="Email"
      />
      <TextField
        sx={{ width: "100%", maxWidth: "300px" }}
        value={inputs.headquarter}
        onChange={(e) => handleChangeInput("headquarter", e.target.value)}
        label={handleTranslate("HEADQUARTER", true)}
      />
      <TextField sx={{width: "100%", maxWidth: "300px"}} required type="number" 
        InputProps={{ inputProps: { min: 1, max: 10} }}
        label={handleTranslate("USER_LIMIT", true)} 
        value={inputs.limitUsers} onChange={(e) => handleChangeInput("limitUsers", e.target.value)} />
      <AlertCategorySelect multiple value={inputs.categoryAlerts} onChange={(value) => handleChangeInput("categoryAlerts", value)} />
      <div>
        <h4 style={{marginBottom: "1rem"}}>{handleTranslate("CAN_SHARE_WITH", true)}:</h4>
        <TenantSelect multiple value={inputs.tenants} onChange={(value) => handleChangeInput("tenants", value)} />
      </div>
      <div>
        <h4 style={{marginBottom: "1rem"}}>{handleTranslate("ENABLED_VIEWS", true)}:</h4>
        <ViewSelect onlyActivableViews required multiple value={inputs.views} onChange={handleChangeEnabledViews}/>
      </div>
      <PermittedActions listViews={listViews} groupActions={inputs.groupActions} permittedActions={inputs.permittedActions} onChangeGroupActions={(value) => handleChangeInput("groupActions", value)} onChangeActions={(value) => handleChangeInput("permittedActions", value)} />
      <Button sx={{ width: "fit-content", margin: "0 auto", border: "1px solid black" }} disabled={buttonDisabled} onClick={handleConfirm}> {handleTranslate('CONFIRM', true)} </Button>
    </div>
  );
}

const CreateTenantModal = ({ isOpen = false, onClose, onFinally }) => {
  const [loading, setLoading] = useState(false);

  const handleFinally = () => {
    setLoading(false);
    onFinally && onFinally();
  }

  const handleClose = () => {
    setLoading(false);
    onClose?.();
  }

  return (
    <Modal open={isOpen}>
      <div style={styles.containerStyle}>
        <div style={{ display: "flex" }}>
          <IconButton sx={{ marginLeft: "auto" }} onClick={handleClose}>
            <CloseIcon fontSize="small" sx={{ color: "white" }} />
          </IconButton>
        </div>
        <div style={{ background: "white", borderRadius: "0 1rem 1rem 1rem", height: "100%", overflowY: "auto" }}>
          <FormCreation onPending={() => setLoading(true)} onFinally={handleFinally} />
          {loading &&
            <div style={styles.loadingPopup}>
              <LoadingGif />
            </div>
          }
        </div>
      </div>
    </Modal>
  )
}

export default CreateTenantModal