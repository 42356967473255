import { combineReducers } from "@reduxjs/toolkit";
import fuse from "./fuse";
import i18n from "./i18nSlice/i18nSlice";
import controlUnits from "./evocsSlice/controlUnits/cuSlice";
import sensors from "./evocsSlice/sensors/sensorSlice";
import reports from "./evocsSlice/reportsSlice";
import reports_v2 from "./evocsSlice/reports/reportsSlice";
import maps from "./evocsSlice/maps/mapsSlice";
import filters from "./evocsSlice/filters/filtersSlice";
import error from "./evocsSlice/globalError/errorSlice";
import file from "./evocsSlice/fileSlice/fileSlice";
import login from "./evocsSlice/login/loginSlice";
import openData from "./evocsSlice/openaData/openDataSlice";
import vessels from "./evocsSlice/vessels/vesselsSlice";
import configuration from "./configurationSlice/configurationSlice";
import tenants from "./evocsSlice/tenants/tenantsSlice";
import views from "./evocsSlice/views/viewsSlice";
import stationsMongo from "./evocsSlice/stationsMongo/stationsMongoSlice";
import dataset from "./evocsSlice/dataset/datasetSlice";
import multimedia from "./evocsSlice/multimedia/multimediaSlice";
import user from "./evocsSlice/user/userSlice";
import networkDevices from "./evocsSlice/network/networkSlice"

const createReducer = () => {
  const combinedReducer = combineReducers({
    fuse,
    i18n,
    user,
    error,
    controlUnits,
    sensors,
    stationsMongo,
    reports,
    reports_v2,
    openData,
    maps,
    filters,
    file,
    login,
    vessels,
    configuration,
    tenants,
    views,
    dataset,
    multimedia,
    networkDevices
  });

  const rootReducer = (state, action) => {
    const LOGOUT = "evocs/logout";

    if (action.type === LOGOUT) {
      // Reset each slice to its initial state
      const resetState = {};
      Object.keys(state).forEach((key) => {
        resetState[key] = combinedReducer(undefined, { type: "@@INIT" })[key];
      });
      return resetState;
    }

    return combinedReducer(state, action);
  };

  return rootReducer;
};

export default createReducer;
