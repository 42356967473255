import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { BsThreeDots } from "react-icons/bs";
import { useState } from "react";
export default function NetworkMenu({ items, pairing, type }) {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div>
      <Button
        id="networkBtn"
        aria-controls={open ? "basic-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        onClick={handleClick}
        className="p-0"
      >
        <BsThreeDots className="text-2xl lg:text-4xl text-white" />
      </Button>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "networkBtn",
        }}
      >
        {items.map((item, index) => (
          <MenuItem
            key={index}
            onClick={() => {
              handleClose();
              if (item.onClick) item.onClick(); // Esegue l'azione associata
            }}
            disabled={type === "blueooth" && item.label=== "Connetti" && !pairing}
          >
            {item.label}
          </MenuItem>
        ))}
      </Menu>
    </div>
  );
}
