import { useSelector } from 'react-redux';
import { getSelectedTheme } from 'src/utilities/utils';

// Esempio di hook personalizzato
const useTenantPalette = () => {
    const selectedTenant = useSelector((state) => state.user.selectedTenant);
    const themes = useSelector((state) => state.tenants.themes.colorPalettes);
  return getSelectedTheme(selectedTenant, themes);
};

export default useTenantPalette;
