import { useLayoutEffect, useState } from 'react';
import { styled } from '@mui/material/styles';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';

const IOSSwitch = styled(({size, ...props}) => (
    <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
  ))(({ theme, size}) => {
    let widthValue, heightValue;
    switch(size) {
      case "small":
        widthValue = 35; 
        break;
      case "medium":
        widthValue = 42;
        break;
      case "large":
        widthValue = 84;
        return;
      default:
        if(!size) widthValue = 35;
        else widthValue = size;
        break;
    }
    heightValue = widthValue-16;
    return {
      width: widthValue,
      height: heightValue,
      padding: 0,
      '& .MuiSwitch-switchBase': {
        padding: 0,
        margin: 2,
        transitionDuration: '300ms',
        '&.Mui-checked': {
          transform: 'translateX(16px)',
          color: '#fff',
          '& + .MuiSwitch-track': {
            backgroundColor: theme.palette.mode === 'dark' ? '#2ECA45' : '#65C466',
            opacity: 1,
            border: 0,
          },
          '&.Mui-disabled + .MuiSwitch-track': {
            opacity: 0.5,
          },
        },
        '&.Mui-focusVisible .MuiSwitch-thumb': {
          color: '#33cf4d',
          border: '6px solid #fff',
        },
        '&.Mui-disabled .MuiSwitch-thumb': {
          color:
            theme.palette.mode === 'light'
              ? theme.palette.grey[100]
              : theme.palette.grey[600],
        },
        '&.Mui-disabled + .MuiSwitch-track': {
          opacity: theme.palette.mode === 'light' ? 0.7 : 0.3,
        },
      },
      '& .MuiSwitch-thumb': {
        boxSizing: 'border-box',
        width: heightValue - 4,
        height: heightValue - 4,
      },
      '& .MuiSwitch-track': {
        borderRadius: heightValue / 2,
        backgroundColor: theme.palette.mode === 'light' ? '#E9E9EA' : '#39393D',
        opacity: 1,
        transition: theme.transitions.create(['background-color'], {
          duration: 500,
        }),
      }
    }
  });

const SwitchComponent = ({style, switchStyle, checked=undefined, label=undefined, labelPlacement="end", disabled=false, size="medium", onChange}) => {
  const [check, setCheck] = useState(checked);
  
  useLayoutEffect(() => {
    checked !== undefined && setCheck(checked);
  }, [checked]);

  const handleChange = (value) => {
    setCheck(value);
    onChange?.(value);
  }

  return (
    <FormGroup sx={style}>
      <FormControlLabel
        disabled={disabled} control={<IOSSwitch sx={{ m: 1, ...switchStyle}} checked={check} size={size} onChange={(e) => handleChange(e.target.checked)}/>}
        label={label} labelPlacement={labelPlacement}
      />
    </FormGroup>
  );
}

export default SwitchComponent;