import { useState } from "react";
import { MdOutlineFileDownload } from "react-icons/md";
import {
  BsFiletypeCsv,
  BsFiletypeXls,
  BsFilePdf,
  BsFiletypeJson,
} from "react-icons/bs";
import { AnimatePresence, motion } from "framer-motion";

import LoadingReportGif from "../SharingComponents/LoadingReportGif";

export default function ModalDownload({
  stationId,
  summaryOpen,
  setSummaryOpen,
  setSummaryValue,
}) {
  const [isDownloadOpen, setIsDownloadOpen] = useState(false);
  const handleDownloadOpen = () => {
    setIsDownloadOpen((prev) => !prev);
  };
  return (
    <>
      <motion.button
        whileHover={{ scale: 1.12 }}
        whileTap={{ scale: 0.8 }}
        transition={{
          duration: 0.1,
          ease: "linear",
        }}
        className="fixed z-20 bottom-[5%] right-[30px] rounded-full p-14 border border-green-300 shadow-xl bg-white"
        onClick={handleDownloadOpen}
      >
        <MdOutlineFileDownload className="text-4xl" />
      </motion.button>
      <div>
        {isDownloadOpen && (
          <DownloadContent
            summaryOpen={summaryOpen}
            setSummaryOpen={setSummaryOpen}
            stationId={stationId}
            setSummaryValue={setSummaryValue}
          />
        )}
      </div>
    </>
  );
}

const DownloadContent = ({
  stationId,
  summaryOpen,
  setSummaryOpen,
  setSummaryValue,
}) => {
  const isStation = stationId ? true : false;
  const [loading, setLoading] = useState(false);

  const handleDownload = (event, fileExtension) => {
    if (isStation) event.stopPropagation();
    setSummaryOpen(!summaryOpen);
    setSummaryValue(fileExtension);
  };

  const downloadBtns = [
    { id: "json", icon: BsFiletypeJson },
    { id: "pdf", icon: BsFilePdf },
    { id: "excel", icon: BsFiletypeXls },
    { id: "csv", icon: BsFiletypeCsv },
  ];

  const variants = {
    hidden: { opacity: 0, x: 20 },
    visible: (index) => ({
      opacity: 1,
      x: 0,
      transition: { delay: index * 0.15, duration: 0.3 },
    }),
  };

  return (
    <>
      <div className="fixed z-20 bottom-[5%] right-[100px] flex gap-8 rotate-180">
        <AnimatePresence>
          {downloadBtns.map(({ id, icon: Icon }, index) => (
            <motion.div
              key={id}
              variants={variants}
              initial="hidden"
              animate="visible"
              exit="hidden"
              custom={index}
            >
              {loading !== id ? (
                <motion.div
                  onClick={(event) => handleDownload(event, id)}
                  whileHover={{ scale: 1.12 }}
                  whileTap={{ scale: 0.8 }}
                  transition={{
                    duration: 0.1,
                    ease: "linear",
                  }}
                  className="p-14 rounded-full bg-gray-800 text-white flex items-center justify-center cursor-pointer"
                >
                  <Icon className="text-4xl rotate-180" />
                </motion.div>
              ) : (
                <LoadingReportGif />
              )}
            </motion.div>
          ))}
        </AnimatePresence>
      </div>
    </>
  );
};
