import { useMemo, useState } from "react";
import useTenantPalette from "../../app/hooks/useTenantPalette";
import DashboardTable from "./DashboardTable";
import { useSelector } from "react-redux";
const TestPageContent = () => {
  const [stationId, setStationId] = useState(null)

  const selectedPalette = useTenantPalette();
  const stations = useSelector((state) => state.stationsMongo.list);
  const availableStationsId = useSelector(
    (state) => state.stationsMongo.available
  );
  const control_units = useSelector((state) => state.controlUnits.list);
  const sensors = useSelector((state) => state.sensors.list);
  const measures = useSelector(
    (state) => state.configuration.constants.measure
  );

  const memoizedValues = useMemo(() => {

    const filteredStations = Object.fromEntries(
      Object.entries(stations).filter(([key]) =>
        availableStationsId.includes(key)
      )
    );

  }, [stations, availableStationsId]);


  // const getInstalledCus = (stationId) => {
  //   return stations[stationId].list_cu;
  // };

  const getInstalledSensors = (stationId) => {
   
    const availableSensorIds = stations[stationId].list_sensors.filter((id) =>
      sensors[id].available
    );

    return availableSensorIds;

  };

  return (
    <div className="h-[85vh]  flex flex-col">
      <div className="flex  basis-1/2 gap-10 p-10">
        <div className="h-full basis-1/3 bg-white rounded-lg shadow-lg p-10"></div>
        <div className="h-full basis-2/3 bg-white rounded-lg shadow-lg p-10"></div>
      </div>
      <div className="flex basis-1/2 gap-10 p-10">
        <div className="h-[400px] basis-2/3 bg-white rounded-lg shadow-lg p-10 overflow-y-auto">
          <DashboardTable
            getInstalledCus={getInstalledCus}
            getInstalledSensors={getInstalledSensors}
            stations={filteredStations}
          />
        </div>
        <div className="h-full basis-1/3 bg-white rounded-lg shadow-lg p-10"></div>
      </div>
    </div>
  );
};
{
  /* <DashboardTable stations={filteredStations} /> */
}

export default TestPageContent;
