import { Divider, Grid, Paper, TextField } from "@mui/material"
import { getStationsThreshold } from "app/store/evocsSlice/stationsMongo/stationsMongoActions";
import { useEffect, useLayoutEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux"
// import LockIcon from '@mui/icons-material/Lock';
// import LockOpenIcon from '@mui/icons-material/LockOpen';
// import CheckIcon from '@mui/icons-material/Check';
// import RestartAltIcon from '@mui/icons-material/RestartAlt';
// import EditIcon from '@mui/icons-material/Edit';
import LoadingGif from "../SharingComponents/LoadingGif";
import { updateSensorThreshold } from "app/store/evocsSlice/sensors/sensorsActions";
import SelectSomething from "../SharingComponents/SelectSomething";
import ThresholdCard from "../SharingComponents/ThresholdCard";
import { viewNames } from "app/configs/routesNames";
import { useTranslation } from 'react-i18next';
import { translate } from "src/utilities/utils";



// const SingleThreshold = ({enabledUpdate, placeholder="", value, defaultValue=undefined, locked, defaultLocked=true, onChange, onConfirmEdit}) => {
//   const [inputClassName, setInputClassName] = useState("input-container");
//   const [val, setValue] = useState(defaultValue);
//   const [lock, setLock] = useState(defaultLocked);

//   useLayoutEffect(() => {
//     value && setValue(value);
//   }, [value])

//   useLayoutEffect(() => {
//     locked !== undefined && setLock(locked);
//   }, [locked])

//   const handleInputFocus = () => {
//     setInputClassName("input-container focus");
//   }

//   const handleInputBlur = () => {
//     setInputClassName("input-container");
//   }

//   const handleChangeValue = (newValue) => {
//     setValue(newValue);
//     onChange && onChange(newValue);
//   }


//   const handleConfirm = () => {
//     setLock(true);
//     onConfirmEdit && onConfirmEdit(Number(val));
//   }

//   return (
//     <div className="threshold">
//       <label style={{width: "50px"}}>{placeholder}</label>
//       { enabledUpdate ?
//       <>
//       <div className={inputClassName}>
//         <input type="number" min={0} disabled={lock} value={val || ""} onChange={(e) => handleChangeValue(e.target.value)} onFocus={handleInputFocus} onBlur={handleInputBlur}/>
//         {lock ? <LockIcon fontSize="small" />: <LockOpenIcon fontSize="small"/>}
//       </div>
//       {lock ? 
//         <button onClick={() => setLock(false)}><EditIcon fontSize="small"/></button> 
//       :
//         <div style={{whiteSpace: "nowrap"}}>
//           <button onClick={handleConfirm}><CheckIcon fontSize="small"/></button> 
//           <button onClick={() => handleChangeValue(undefined)}><RestartAltIcon fontSize="small"/></button> 
//         </div>}
//       </>
//       :
//       <span>{val || "nessuna soglia"}</span>
//       }
//     </div>
//   );
// };


// const ThresholdCard = ({enabledUpdate, measureId, sensorId, min, max}) => {
//   const user = useSelector((state) => state.user);
//   const measures = useSelector((state) => state.configuration.constants.measure);

//   const handleConfirmThreshold = (key, value) => {
//     if(key === "min") updateSensorThreshold(user.tenantId, sensorId, measureId, value, undefined);
//     else updateSensorThreshold(user.tenantId, sensorId, measureId, undefined, value);
//   }

//   return(
//     <Paper sx={{padding: "1rem"}}>
//       <h3 style={{marginBottom: "1rem"}}>{measures[measureId]?.label}</h3>
//       <div style={{display: "flex", flexDirection: "column", gap: "1rem"}}>
//         <SingleThreshold enabledUpdate={enabledUpdate} placeholder="Min" value={min} onConfirmEdit={(value) => handleConfirmThreshold("min", value)}/>
//         <SingleThreshold enabledUpdate={enabledUpdate} placeholder="Max" value={max} onConfirmEdit={(value) => handleConfirmThreshold("max", value)}/>
//       </div>
//     </Paper>
//   )
// }

const ThresholdsChip = ({ enabledActions, stationId, loadingThresholds, thresholds}) => {
  const { t } = useTranslation("evocs");
  const user = useSelector((state) => state.user);
  const stations = useSelector((state) => state.stationsMongo.list);
  const controlUnits = useSelector((state) => state.controlUnits.list);
  const sensors = useSelector((state) => state.sensors.list);
  const measures = useSelector((state) => state.configuration.constants.measure);
  const [data, setData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [searchText, setSearchText] = useState("");

  const allCusParent = useMemo(() => {
    const cus = [];
    filteredData?.forEach((element) => {
      if(!cus.includes(element.cuParent)) cus.push(element.cuParent);
    });
    return cus;
  }, [filteredData]);

  useEffect(() => {
    setData(
      thresholds.map((element) => ({
        ...element,
        measure: handleTranslate(`MEASURE_${measures[element.measureId]?.measure.toUpperCase()}`, true),
        sensor: sensors[element.sensorParent].serial,
        cu: controlUnits[element.cuParent]?.serial,
      }))
    );
  }, [thresholds, t])

  useEffect(() => {
    if(!data) {
      setFilteredData([]);
      return;
    }
    if(!searchText || searchText === "") {
      setFilteredData(data);
      return;
    }

    const timeoutId = setTimeout(() => {
      setFilteredData(() => {
        return data.filter((element) => Object.keys(element).some((key) => element[key]?.toString().toLowerCase().includes(searchText.toLowerCase())));
      })
    }, 700);

    return () => clearTimeout(timeoutId);
  }, [data, searchText])
  
  const handleChangeThreshold = (sensorId, measureId, key, value) => {
    if (key === "min") updateSensorThreshold(user.tenantId, sensorId, measureId, value, undefined);
    else updateSensorThreshold(user.tenantId, sensorId, measureId, undefined, value);
  }
  
  const handleTranslate = (textId, general = false) => {
    if (general) return translate(t, textId);
    return translate(t, textId, viewNames.DASHBOARD_VIEW);
  };

  return (
    <div style={{ padding: "1rem", width: "100%", height: "100%" }}>
      <h2 style={{ marginBottom: "1rem" }}>{stations[stationId]?.serial ? stations[stationId]?.serial : ""}</h2>
      {stationId && data?.length > 0 && !loadingThresholds && 
        <TextField sx={{width: "100%", maxWidth: "300px"}} placeholder={handleTranslate("SEARCH", true)} value={searchText} onChange={(e) => setSearchText(e.target.value)}/>
      }
      {stationId ?
        data?.length > 0 ?
          loadingThresholds ?
            <LoadingGif />
            :
            <div style={{ display: "flex", flexDirection: "column", gap: "2rem", paddingBottom: "1rem"}}>
              {allCusParent?.map((cuId, index) => (
                <div key={index}>
                  <h3 style={{marginBottom: "0.5rem"}}><strong>{controlUnits[cuId] ? `${handleTranslate("CONTROL_UNIT", true)}: ` + controlUnits[cuId]?.serial : "Sensori installati"}</strong></h3>
                  <Grid container spacing={2}>
                    {filteredData?.map((element, j) => element.cuParent === cuId && (
                      <Grid key={j} item xs={12} sm={6} md={4} lg={3}>
                        <ThresholdCard enableUpdate={enabledActions.includes("update-thresholds")} sensorId={element.sensorParent} measureName={element.measure} measureId={element.measureId} min={element.min} max={element.max}
                          onConfirmEdit={handleChangeThreshold} />
                      </Grid>
                    ))}
                  </Grid>
                  {index < allCusParent.length -1 && <Divider sx={{m: "1rem 0"}}/>}
                </div>
              ))}
            </div>
          :
          <span> {handleTranslate("NO_SENSORS_INSTALLED", true)} </span>
        :
        <SelectSomething text={handleTranslate("SELECT_STATION", true)} />
      }
    </div>
  )
}

export default ThresholdsChip