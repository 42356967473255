import { getApiTimestamp } from "src/utilities/utils";
import store from "../.."
import { reportsSliceActions } from "./reportsSlice"

export const getAllReports = async(startDate, endDate, type=null, level=null) => {
    const data = await store.dispatch(reportsSliceActions.getAllReports({startDate: getApiTimestamp(startDate), endDate: getApiTimestamp(endDate), type, level})).then(({payload, error}) => {
        if(error) throw error;
        return payload;
    });
    return data;
};

export const addOdourReport = async(userId, odourDate, odourLevelId, odourTypeId, baseLocationId=null, newLat=null, newLon=null) => {

    const date = getApiTimestamp(odourDate?.valueOf());
    const data = await store.dispatch(reportsSliceActions.addOdourReport({userId, odourDate: date, odourLevelId, odourTypeId, baseLocationId, newLat, newLon})).then(({payload, error}) => {
        if(error) throw error;
        return payload;
    });
    return data;
} 

export const deleteOdourReport = async(odourId) => {
    const data = await store.dispatch(reportsSliceActions.deleteOdourReport({odourId})).then(({payload, error}) => {
        if(error) throw error;
        return payload;
    });
    return data;
} 

export const getReportsPercentages = async(startDate, endDate, kind, type=null, level=null) => {
    const data = await store.dispatch(reportsSliceActions.getReportsPercentages({startDate: getApiTimestamp(startDate), endDate: getApiTimestamp(endDate), kind, type, level})).then(({payload, error}) => {
        if(error) throw error;
        return payload;
    });
    return data;
};

// export const getUnitFileData = async( unitId, startDate, endDate, unitType, fileType, tenantId, fileName, measureIds) => {    
//     const data = await store.dispatch(
//         reportsSliceActions.getUnitFileData({ 
//             unitId:unitId, 
//             startDate:startDate, 
//             endDate:endDate, 
//             unitType:unitType, 
//             fileType:fileType, 
//             tenantId:tenantId,
//             fileName:fileName,
//             measureIds: measureIds
//         })
//         ).then(({payload, error}) => {
//         if(error) throw error;
//         return payload;
//     });
//     return data;
// };
 export const getUnitFileData = async( stationId, startDate, endDate, fileType, tenantId, fileName, cuId, sensorIds, measureIds) => {    
     const data = await store.dispatch(
         reportsSliceActions.getUnitFileData({ 
             stationId:stationId, 
             startDate:startDate, 
             endDate:endDate, 
             fileType:fileType, 
             tenantId:tenantId,
             fileName:fileName,
             cuId: cuId,
             sensorIds: sensorIds,
             measureIds: measureIds
         })
         ).then(({payload, error}) => {
         if(error) throw error;
         return payload;
     });
     return data;
 };

export const getOdourFile = async(startDate, endDate, typi, level, fileType, fileName) => {
    const data = await store.dispatch(
        reportsSliceActions.getOdourFile({
            startDate:startDate, endDate:endDate, typi:typi, level:level, fileType:fileType, fileName:fileName
        })
        ).then(({payload, error}) => {
        if(error) throw error;
        return payload;
    });
    return data;
};


/**
 * Generates an open data file based on the provided parameters.
 *
 * @param {Object} params - The parameters object.
 * @param {number} params.startDate - The start date of the data range (in milliseconds).
 * @param {number} params.endDate - The end date of the data range (in milliseconds).
 * @param {string[]} params.sources - An array of ObjectId of data sources (e.g. "EEA", "mistral", "prot_civ", "Arpa-Puglia", "rmn" in ObjectId format)
 * @param {number[]} params.stationIds - An array of station IDs.
 * @param {string[]} params.fields - An array of ObjectId of measures
 * @param {string} params.typeStation - The type of station (e.g. "AQ" or "METEO").
 * @param {string} params.fileType - The file type of the generated file (e.g. "csv", "excel", or "json").
 * @param {string} params.fileName - The name of the generated file.
 * @returns {Promise<Object>} A promise that resolves with the response data from the `/api/py/createOpenFile` endpoint.
 * @throws {Error} If the request fails or the response is invalid.
 */
export const getOpenDataFiles = async(startDate, endDate, sources=[], stationIds=[], fields=[], typeStation, fileType, fileName) => {

    if(!startDate || !endDate) throw new Error("startDate & endDate are required");
    if(!Array.isArray(sources) || !Array.isArray(stationIds) || !Array.isArray(fields)) throw new Error("sources, stationIds and fields must be arrays");

    const data = await store.dispatch(
        reportsSliceActions.getOpenDataFiles({
            startDate:startDate, endDate:endDate, sources:sources, stationIds:stationIds, fields:fields, typeStation:typeStation , fileType:fileType, fileName:fileName
        })
        ).then(({payload, error}) => {
        if(error) throw error;
        return payload;
    });
    return data;
};

export const getReportPdfInfo = async (startDate, endDate, stationId, cuIds, sensorIds) =>{
    // console.log('startDate, endDate, stationId, cuId, sensorIds', startDate, endDate, stationId, cuId, sensorIds);
    let inputCuIds = (cuIds && Array.isArray(cuIds)) ? cuIds.length == 0 ? undefined : cuIds : undefined;
    let inputSensorIds = (sensorIds && Array.isArray(sensorIds)) ? sensorIds.length == 0 ? undefined : sensorIds : undefined;
    const data = await store.dispatch(reportsSliceActions.getReportPdfInfo({
        startDate: startDate,
        endDate: endDate,
        stationId: !inputCuIds && !inputSensorIds ? stationId : undefined,
        cuId: inputCuIds,
        sensorIds: inputSensorIds
    })).then(({payload, error})=> {
        if(error) throw error;
        return payload
    })
    return data
}

 export const getSensorReportInfo = async(startDate, endDate, sensorIds) => {
     const data = await store.dispatch(
         reportsSliceActions.getSensorReportInfo({
             startDate:startDate, endDate:endDate, sensorIds:sensorIds
         })
         ).then(({payload, error}) => {
         if(error) throw error;
         return payload;
     });
     return data;
 };
 export const getStationReportInfo = async(startDate, endDate, stationId) => {
     const data = await store.dispatch(
         reportsSliceActions.getStationReportInfo({
             startDate:startDate, endDate:endDate, stationId:stationId
         })
         ).then(({payload, error}) => {
         if(error) throw error;
         return payload;
     });
     return data;
 };
 export const getCuReportInfo = async(startDate, endDate, cuId) => {
     const data = await store.dispatch(
         reportsSliceActions.getCuReportInfo({
             startDate:startDate, endDate:endDate, cuId:cuId
         })
         ).then(({payload, error}) => {
         if(error) throw error;
         return payload;
     });
     return data;
 };