import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

const createConstant = createAsyncThunk(
  "configuration/createConstant",
  async (payload) => {
    const {tenantId, userId, constants, constantType} = payload;
    const data = await axios
      .post(`/api/py/createConstant`, {
        tenantId: tenantId,
        userId: userId,
        data: constants, // array, ogni oggetto deve avere constantId e constanType
      })
      .then((res) => {
        return res.data;
      })
      .catch((error) => {
        console.log("Error: ", error);
        throw error;
      });
    return {constantType, data};
  }
);
const readConstant = createAsyncThunk(
  "configuration/readConstant",
  async (constantCategories) => {
    const data = await axios
      .post(`/api/py/readConstant`, {
        constantCategories,
      })
      .then((res) => {
        return res.data;
      })
      .catch((error) => {
        console.log("Error: ", error);
      });
    return data;
  }
);
const updateConstant = createAsyncThunk(
  "configuration/updateConstant",
  async (payload) => {
    const {tenantIds, userId, constants} = payload;
    console.log('tenantIds, userId, constants', tenantIds, userId, constants);
    const data = await axios
      .post(`/api/py/updateConstant`,  {
        tenantIds: tenantIds,
        userId: userId,
        data: constants,
      })
      .then((res) => {
        return res.data;
      })
      .catch((error) => {
        console.log("Error: ", error);
        throw error;
      });
    return data;
  }
);

const deleteConstant = createAsyncThunk(
  "configuration/deleteConstant",
  async (payload) => {
    const {tenantId, userId, constants} = payload;
    const data = await axios
      .post(`/api/py/deleteConstant`, {
        tenantId: tenantId,
        userId: userId,
        data: constants, // array, ogni oggetto deve avere constantId e constanType
      })
      .then((res) => {
        return res.data;
      })
      .catch((error) => {
        console.log("Error: ", error);
      });
    return constants;
  }
);

const createBaselocation = createAsyncThunk(
  "configuration/createBaselocation",
  async (payload) => {
    const {lat, lon, name} = payload;
    const data = await axios
      .post(`/api/py/createBaselocation`, {
          lat: lat ?? Number(lat),
          lon: lon ?? Number(lon),
          name: name,
      })
      .then((res) => {
        return res.data;
      })
      .catch((error) => {
        console.log("Error: ", error);
        throw error;
      });
    return {id: data.id, lat, lon, name};
  }
);
const readBaselocations = createAsyncThunk(
  "configuration/readBaselocations",
  async (baseLocationId) => {
    const data = await axios
      .get(`/api/py/readBaselocation`, {
        params: {
          baseLocationId: baseLocationId
        }
      })
      .then((res) => {
        return res.data;
      })
      .catch((error) => {
        console.log("Error: ", error);
        throw error;
      });
    return data;
  }
);
const updateBaselocation = createAsyncThunk(
  "configuration/updateBaselocation",
  async (payload, { dispatch, getState }) => {
    const {baselocationId, lat, lon, name} = payload;
    const body = [
      {
        baselocationId: baselocationId,
        lat: lat,
        lon: lon,
        name: name,
      },
    ];
    const data = await axios
      .post(`/api/py/updateBaselocation`, body)
      // .then((res) => {
      // })
      .catch((error) => {
        console.log("Error: ", error);
      });
    return data;
  }
);
const deleteBaselocation = createAsyncThunk(
  "configuration/deleteBaselocation",
  async (baseLocationId) => {
    const data = await axios
      .post(`/api/py/deleteBaselocation`, {
        id_locations: baseLocationId,
      })
      .then((res) => {
        console.log("res: ", res);
        return res;
      })
      .catch((error) => {
        console.log("Error: ", error);
        throw error;
      });
    return baseLocationId;
  }
);



const configuration = createSlice({
  name: "configuration",
  initialState: {
    constants: {},
  },
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(readConstant.fulfilled, (state, action) => {
      state.constants = {...state.constants, ...action.payload};
    });
    builder.addCase(createConstant.fulfilled, (state, action) => {
      const {constantType, data} = action.payload;
      const constantId = Object.keys(data)[0];
      state.constants[constantType][constantId] = data[constantId];
    });
    builder.addCase(updateConstant.fulfilled, (state, action) => {
      const data = action.payload;
      data?.forEach((constant) => {
        const constantObj = {...constant};
        delete constantObj._id; delete constantObj.constantType;
        state.constants[constant.constantType][constant._id] = constantObj;
      });
    });
    builder.addCase(deleteConstant.fulfilled, (state, action) => {
      action.payload.forEach((constant) => {
        const {constantType, constantId} = constant;
        delete state.constants[constantType][constantId];
      })
    });
    builder.addCase(createBaselocation.fulfilled, (state, action) => {
      const {id, lat, lon, name} = action.payload;
      state.constants.base_location[id] = {name, lat, lon};
    });
    builder.addCase(readBaselocations.fulfilled, (state, action) => {
      state.constants.base_location = action.payload;
    });
  },
});


export const configurationSliceActions = {
  ...configuration.actions,
  createConstant,
  readConstant,
  updateConstant,
  deleteConstant,
  createBaselocation,
  readBaselocations,
  updateBaselocation,
  deleteBaselocation,
};
export default configuration.reducer;
