import { useEffect, useMemo, useState } from "react";
import MapComponent from "../SharingComponents/MapComponent";
import { Button, Paper, Slider, Typography } from "@mui/material";
import {
  getPlanetekCapabilities,
  getPlanetekImageWms,
  getPlanetekLegend,
} from "app/store/evocsSlice/maps/mapsActions";
import { setGlobalError } from "app/store/evocsSlice/globalError/errorActions";
import LoadingGif from "../SharingComponents/LoadingGif";
import { ImageOverlay, useMap } from "react-leaflet";
import { formatDateFromTimestamp, TIME_FORMAT } from "../StationHandling/utils";
import { useTranslation } from "react-i18next";
import { translate } from "src/utilities/utils";
import { viewNames } from "app/configs/routesNames";
import useMobile from "src/app/hooks/useMobile";
import Control from "react-leaflet-custom-control";
import SwitchComponent from "../SharingComponents/Inputs/SwitchComponent";
import CustomControl from "./CustomControl";

const DropDownList = ({
  layers,
  selectedLayer,
  selectedYear,
  selectedMonth,
  onChangeLayer,
  onChangeYear,
  onChangeMonth,
}) => {
  const { t } = useTranslation("evocs");
  const [xs, sm, md] = useMobile();

  const handleTranslate = (textId, general = false) => {
    if (general) return translate(t, textId);
    return translate(t, textId, viewNames.TEST_PAGE_VIEW);
  };

  return (
    <div>
      <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
        {handleTranslate("SELECT_LAYER", true)}
      </label>
      <form
        style={{
          display: "flex",
          flexDirection: !xs && !sm && !md ? "row" : "column",
          gap: "0.5rem",
        }}
      >
        <select
          id="layers"
          value={selectedLayer.name}
          onChange={(e) => onChangeLayer?.(e.target.value)}
          className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
        >
          {layers.map((layer, index) => (
            <option key={index} value={layer.name}>
              {layer.label}
            </option>
          ))}
        </select>
        <select
          id="years"
          value={selectedYear}
          onChange={(e) => onChangeYear?.(e.target.value)}
          className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
        >
          {selectedLayer.years.map((year, index) => (
            <option key={index} value={year}>
              {year}
            </option>
          ))}
        </select>
        <select
          id="months"
          value={selectedMonth}
          onChange={(e) => onChangeMonth?.(e.target.value)}
          className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
        >
          {selectedLayer.months[selectedYear].map((month, index) => (
            <option key={index} value={month}>
              {handleTranslate("MONTH_" + month, true)}
            </option>
          ))}
        </select>
      </form>
    </div>
  );
};

const PlanetekMap = ({
  children,
  ...otherProps
}) => {
  const { t } = useTranslation("evocs");
  const handleTranslate = (textId, general = false) => {
    if (general) return translate(t, textId);
    return translate(t, textId, viewNames.DASHBOARD_VIEW);
  };
  const [xs, sm, md] = useMobile();
  const [loading, setLoading] = useState(false);
  const [loadingLayer, setLoadingLayer] = useState(false);
  const [layers, setLayers] = useState([]);
  const [selectedLayer, setSelectedLayer] = useState();
  const [selectedYear, setSelectedYear] = useState();
  const [selectedMonth, setSelectedMonth] = useState();
  const [maxSliderSteps, setMaxSliderSteps] = useState(0);
  const [currentSliderStep, setCurrentSliderStep] = useState(0);
  const [currentSelection, setCurrentSelection] = useState();
  const [sliderMarks, setSliderMarks] = useState([]);
  const [imageLayer, setImageLayer] = useState();
  const [openPlanetekControl, setOpenPlanetekControl] = useState(false);
  const [showLayer, setShowLayer] = useState(false);
  const [showLegend, setShowLegend] = useState(false);
  const [mapRef, setMapRef] = useState();
  const disableMarks = useMemo(() => {
    if (!xs && !sm && !md) return false;
    return true;
  }, [xs, sm, md]);

  useEffect(() => {
    let canSet = true;
    setLoading(true);
    getPlanetekCapabilities()
      .then((res) => {
        if (!canSet) return;
        const newSelectedLayer = res.layers[0];
        const newSelectedYear =
          newSelectedLayer.years[newSelectedLayer.years.length - 1];
        const newSelectedMonth =
          newSelectedLayer.months[newSelectedYear][
            newSelectedLayer.months[newSelectedYear].length - 1
          ];
        setLayers(
          res.layers.filter((layer) => Object.keys(layer.dimension).length > 0)
        );
        setSelectedLayer(newSelectedLayer);
        setSelectedYear(newSelectedYear);
        setSelectedMonth(newSelectedMonth);
      })
      .catch((error) => {
        setGlobalError(true);
      })
      .finally(() => setLoading(false));
    return () => (canSet = false);
  }, []);

  useEffect(() => {
    if (!selectedLayer) return;
    const max = selectedLayer.dimension[selectedYear][selectedMonth].length;
    setMaxSliderSteps(max);
    setCurrentSliderStep(0);
    setCurrentSelection({
      layerName: selectedLayer.name,
      timestamp: selectedLayer.dimension[selectedYear][selectedMonth][0],
    });
    // console.log("timestamp: ", selectedLayer.dimension[selectedYear][selectedMonth][0]);
    setSliderMarks(
      selectedLayer.dimension[selectedYear][selectedMonth].map(
        (element, index) => ({
          value: index,
          label: new Date(element * 1000).getDate().toString(),
        })
      )
    );
  }, [selectedLayer, selectedYear, selectedMonth]);

  useEffect(() => {
    if (!showLayer || !currentSelection) return;
    setLoadingLayer(true);
    let canSet = true;
    getPlanetekImageWms(
      selectedLayer.bbox,
      currentSelection.timestamp,
      selectedLayer.name
    )
      .then((res) => {
        if (!canSet) return;
        const boundsArray = selectedLayer.bbox.split(",");
        const bounds = [
          [boundsArray[0], boundsArray[1]],
          [boundsArray[2], boundsArray[3]],
        ];
        setImageLayer((prev) => ({
          ...prev,
          url: "data:image/png+xml;base64," + res,
          bounds,
        }));
      })
      .catch(() => {
        setGlobalError(true);
      })
      .finally(() => {
        if (!canSet) return;
        setLoadingLayer(false);
      });

    getPlanetekLegend(selectedLayer.name)
      .then((res) => {
        if (!canSet) return;
        setImageLayer((prev) => ({
          ...prev,
          legendUrl: "data:image/png+xml;base64," + res,
        }));
      })
      .catch(() => {
        setGlobalError(true);
      });

    return () => (canSet = false);
  }, [showLayer, currentSelection?.layerName, currentSelection?.timestamp]);

  const handleChangeLayer = (layerName) => {
    const layerIndex = layers.findIndex((layer) => layer.name === layerName);
    const newSelectedLayer = layers[layerIndex];
    setSelectedLayer(newSelectedLayer);
  };

  const handleChangeYear = (year) => {
    if (!selectedLayer.months[year].includes(selectedMonth)) {
      const newSelectedMonth =
        selectedLayer.months[year][selectedLayer.months[year].length - 1];
      setSelectedMonth(newSelectedMonth);
    }
    setSelectedYear(year);
  };

  const handleChangeSlider = () => {
    setCurrentSelection((prev) => ({
      ...prev,
      timestamp:
        selectedLayer.dimension[selectedYear][selectedMonth][currentSliderStep],
    }));
  };

  const handleClickPlanetek = () => {
    if (loadingLayer) return;
    setOpenPlanetekControl((prev) => {
      if (!xs && !sm) setShowLayer(!prev);
      return !prev;
    });
  };

  const planetekToggle = () => {
    return (
      selectedLayer && (
        <Paper
          sx={{
            cursor: "pointer",
            display: "flex",
            padding: "5px",
            width: "60px",
            flexDirection: "column",
            alignItems: "center",
          }}
          onClick={handleClickPlanetek}
        >
          <Typography
            sx={{
              fontWeight: openPlanetekControl ? "bold" : "normal",
              fontSize: "10px",
            }}
          >
            PLANETK
          </Typography>
        </Paper>
      )
    );
  };

  const planetekControls = () => {
    return (
      selectedLayer &&
      openPlanetekControl && (
        <CustomControl
          style={{
            width: !xs && !sm ? "35%" : "100%",
            bottom: 0,
            padding: "1rem",
          }}
        >
          <Paper sx={{ padding: "1rem" }}>
            {(xs || sm) && (
              <SwitchComponent
                disabled={loadingLayer}
                label={handleTranslate("ACTIVE_LAYER", true)}
                labelPlacement="start"
                checked={showLayer}
                onChange={setShowLayer}
              />
            )}
            <SwitchComponent
              label={"Legend"}
              labelPlacement="start"
              checked={showLegend}
              onChange={setShowLegend}
            />
            <DropDownList
              layers={layers}
              selectedLayer={selectedLayer}
              selectedYear={selectedYear}
              selectedMonth={selectedMonth}
              onChangeLayer={handleChangeLayer}
              onChangeYear={handleChangeYear}
              onChangeMonth={setSelectedMonth}
            />
            {currentSelection && (
              <Typography sx={{ marginTop: "1rem" }}>
                {formatDateFromTimestamp(
                  currentSelection.timestamp * 1000,
                  TIME_FORMAT.COMPLETE_DATE
                )}
              </Typography>
            )}
            {maxSliderSteps > 1 && (
              <Slider
                value={currentSliderStep}
                onChangeCommitted={(e, value) => handleChangeSlider(value)}
                marks={!disableMarks ? sliderMarks : true}
                onChange={(e, value) => setCurrentSliderStep(value)}
                max={maxSliderSteps - 1}
                sx={{ width: "100%", minWidth: "100px", color: "#2d3740" }}
              />
            )}
            {(xs || sm) && (
              <div className="w-full flex justify-end">
                <Button
                  onClick={() => {
                    mapRef?.dragging.enable();
                    setOpenPlanetekControl(false);
                  }}
                  className="p-5 rounded-12 shadow-2 text-sm font-medium"
                >
                  {handleTranslate("CLOSE", true)}
                </Button>
              </div>
            )}
          </Paper>
        </CustomControl>
      )
    );
  };

  return (
    <MapComponent
      onGetMap={setMapRef}
      {...otherProps}
    >
      <Control position="topright">{planetekToggle()}</Control>
      {planetekControls()}
      {showLayer && (
        <>
          {imageLayer?.url && (
            <ImageOverlay
              url={imageLayer.url}
              bounds={imageLayer.bounds}
              opacity={0.9}
            />
          )}
          {showLegend && imageLayer?.legendUrl && (
            <div
              style={{
                position: "absolute",
                padding: "1rem",
                zIndex: 1000,
                top: 0,
                left: 0,
              }}
            >
              <Paper
                sx={{ background: "rgba(255, 255, 255, 0.4)", padding: "1rem" }}
              >
                <img style={{ height: 200 }} src={imageLayer.legendUrl} />
              </Paper>
            </div>
          )}
        </>
      )}
      {(loading || loadingLayer) && (
        <div
          style={{
            position: "absolute",
            display: "flex",
            zIndex: 1000,
            background: "rgba(0,0,0,.3)",
            top: 0,
            bottom: 0,
            left: 0,
            right: 0,
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <LoadingGif />
        </div>
      )}
      {children}
    </MapComponent>
  );
};

export default PlanetekMap;
