import {
  Checkbox,
  CircularProgress,
  Divider,
  Paper,
  styled,
} from "@mui/material";
import { Line } from "react-chartjs-2";
import {
  Fragment,
  memo,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import useMobile from "src/app/hooks/useMobile";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import LoadingGif from "../SharingComponents/LoadingGif";
import { viewNames } from 'app/configs/routesNames';
import { useTranslation } from 'react-i18next';
import { translate } from "src/utilities/utils";

const CustomMenu = styled(Paper)(({ theme, icon }) => ({
  position: "relative",
  cursor: "pointer",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  width: "40px",
  height: "40px",
  ":hover": {
    "& div": {
      // Nome utilizzato per assegnare il colore del figlio all'hover
      display: "flex",
    },
  },
}));

const MenuContent = styled(Paper)(({ theme }) => ({
  position: "absolute",
  display: "none",
  flexDirection: "column",
  top: 0,
  right: 0,
  padding: "1rem",
  width: "fit-content",
}));

// const labelsXOrientation = (isMobile, isTablet, unitOnStation) => {
//   let maxRotation = 0;
//   let minRotation = 0;
//   // if (isMobile || unitOnStation > 2 || (isTablet && unitOnStation > 1)) {
//   if (isMobile) {
//     maxRotation = 90;
//     minRotation = 90;
//   }

//   return { maxRotation, minRotation };
// };

// const chartOptions = (measureName, measureUnit, isMobile, unitOnStation, maxRotation, minRotation, color) => ({
//   maintainAspectRatio: false,
//   responsive: true,
//   normalized: true,
//   hitRadius: 20,
//   hoverRadius: 10,
//   cubicInterpolationMode: "monotone",
//   scales: {
//     y: {
//       ticks: {
//         callback: (value) => {
//           let unit = measureUnit? " " + measureUnit : "";
//           return (value ? value.toFixed(3).toString() + unit : "")
//         },
//         maxTicksLimit: 8,
//       },
//       grid: {
//         drawOnChartArea: true,
//         color: "#ccc",
//         lineWidth: 0.8,
//       },
//     },
//     x: {
//       ticks: {
//         // maxTicksLimit: 6,
//         maxRotation: maxRotation,
//         minRotation: minRotation,
//       },
//       afterBuildTicks: (axis) => {
//         if (axis.ticks.length > 2) {
//           let newTicks = axis.ticks;
//           const minXTicks = 6;
//           const firstElement = newTicks.shift();
//           const lastElement = newTicks.pop();
//           const step = Math.floor(newTicks.length / (minXTicks));
//           newTicks = newTicks.filter((elem, index) => {
//             if (index < step - 3) return false;
//             if (index >= (axis.ticks.length - step + 3)) return false;
//             return index % step === 0 && index / step < minXTicks;
//           });
//           newTicks = [firstElement, ...newTicks, lastElement];
//           axis.ticks = newTicks;
//         }
//       },
//       grid: {
//         drawOnChartArea: true,
//         color: "#ccc",
//         lineWidth: 0.7,
//       },
//     },
//   },
//   plugins: {
//     legend: {
//       display: false,
//     },
//     tooltip: {
//       backgroundColor: defaultTooltipBackAplha,
//       titleFont: {
//         size: 11,
//         weight: "bold",
//       },
//       titleColor: "white",
//       displayColors: false,
//       callbacks: {
//         title: (context) => measureName.toUpperCase(),
//         label: (context) => `Data: ${context?.label || ""}`,
//         footer: (context) => {
//           let unit = measureUnit? " " + measureUnit : "";
//           return `Valore: ${context?.[0].raw.toFixed(4).toString() + unit || ""}`
//         },
//       },
//     },
//   },
// });

const drawMinMaxLine = {
  id: "drawMinMaxLine",
  beforeDatasetsDraw(chart, args, options) {
    // console.log(options);
    const {
      ctx,
      chartArea: { top, right, bottom, left, width, height },
      scales,
    } = chart;
    // console.log("chart ", chart)
    // if(options.display?.length <= 0 || options.minMax?.length <= 0) return;

    ctx.save();

    Object.keys(options).forEach((yId, index) => {
      const yScale = options[yId];

      if (yScale.minMax !== null) {
        // draw min max line
        ctx.setLineDash([10, 20]);
        ctx.strokeStyle = "blue";
        ctx.strokeRect(
          left,
          scales[yId].getPixelForValue(yScale.minMax.min),
          width,
          0
        );
        ctx.strokeStyle = "red";
        ctx.strokeRect(
          left,
          scales[yId].getPixelForValue(yScale.minMax.max),
          width,
          0
        );
        ctx.restore();
        // draw text
        ctx.font = 'bold 12px "Microsoft Yahei"';
        ctx.fillStyle = "black";
        ctx.textAlign = "center";
        const minText = `MIN: ${yScale.minMax.min} ${yScale.measureUnit || ""}`,
          maxText = `MAX: ${yScale.minMax.max} ${yScale.measureUnit || ""}`;
        const minTextSpace =
            index === 0
              ? ctx.measureText(minText).width / 2 + left + 10
              : right - ctx.measureText(minText).width / 2 - 10,
          maxTextSpace =
            index === 0
              ? ctx.measureText(maxText).width / 2 + left + 10
              : right - ctx.measureText(minText).width / 2 - 10;
        ctx.fillText(
          minText,
          minTextSpace,
          scales[yId].getPixelForValue(yScale.minMax.min) - 4
        );
        ctx.fillText(
          maxText,
          maxTextSpace,
          scales[yId].getPixelForValue(yScale.minMax.max) - 4
        );
        ctx.restore();
      }
      if (yScale.avg !== null) {
        // draw avg line
        ctx.setLineDash([10, 20]);
        ctx.strokeStyle = "black";
        ctx.strokeRect(
          left,
          scales[yId].getPixelForValue(yScale.avg),
          width,
          0
        );
        ctx.restore();
        // draw text
        ctx.font = 'bold 12px "Microsoft Yahei"';
        ctx.fillStyle = "black";
        ctx.textAlign = "center";
        const avgText = `AVERAGE: ${yScale.avg} ${yScale.measureUnit || ""}`;
        const avgTextSpace =
          index === 0
            ? ctx.measureText(avgText).width / 2 + left + 10
            : right - ctx.measureText(avgText).width / 2 - 10;
        ctx.fillText(
          avgText,
          avgTextSpace,
          scales[yId].getPixelForValue(yScale.avg) - 4
        );
        ctx.restore();
      }
    });
  },
};

const MenuGroup = ({ title, options, checked, onChange }) => {
  const handleClickLabel = (item) => {
    const value = !checked.includes(item.id);
    onChange && onChange(item, value);
  };

  return (
    <div style={{ display: "flex", flexDirection: "column" }}>
      <label
        style={{ fontSize: "0.9em", fontWeight: "bold", whiteSpace: "nowrap" }}
      >
        {title}
      </label>
      {options?.map((item, index) => (
        <div key={item.id} style={{ display: "flex", alignItems: "center" }}>
          <Checkbox
            size="small"
            checked={checked?.includes(item.id) || false}
            onChange={(e, value) => onChange(item, value)}
          />
          <label
            style={{
              cursor: "pointer",
              fontSize: "0.8em",
              whiteSpace: "nowrap",
            }}
            onClick={() => handleClickLabel(item)}
          >
            {item.label}
          </label>
        </div>
      ))}
    </div>
  );
};

const GraphMenu = ({ style, groups, checked, onChange }) => {
  const getCheckedOptions = useCallback(
    (groupId) => {
      const checkedIds = [];
      checked.forEach((element) => {
        if (element.groupId === groupId) checkedIds.push(element.id);
      });
      return checkedIds;
    },
    [checked]
  );

  return (
    <div style={style}>
      <CustomMenu>
        <FilterAltIcon />
        <MenuContent>
          {groups &&
            Object.keys(groups).map(
              (groupId, index) =>
                groups[groupId].options.length > 0 && (
                  <Fragment key={groupId}>
                    <MenuGroup
                      title={groups[groupId].title}
                      options={groups[groupId].options}
                      checked={getCheckedOptions(groupId)}
                      onChange={(item, value) => onChange(item, value)}
                    />
                    {index === 0 && Object.keys(groups).length > 1 && (
                      <Divider sx={{ marginBottom: "0.5rem" }} />
                    )}
                    {/* {index < Object.keys(groups).length - 1 && <Divider sx={{marginBottom: "0.5rem"}}/>} */}
                  </Fragment>
                )
            )}
        </MenuContent>
      </CustomMenu>
    </div>
  );
};

const CorrelationMatrix = ({ style, display, image, loading }) => {
  return (
    display && (
      <div
        style={{
          position: "absolute",
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          background: "rgba(0, 0, 0, 0.5)",
        }}
      >
        <div
          style={{
            display: "flex",
            height: "100%",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          {!loading ? (
            <img style={{ height: "100%", objectFit: "contain" }} src={image} />
          ) : (
            <CircularProgress sx={{ color: "#92c870" }} />
          )}
        </div>
      </div>
    )
  );
};

const LineGraph = memo(
  ({
    datasets = [],
    labels = [],
    tooltips = [],
    measuresUnit = [],
    disableMenu = false,
    disableCompare = false,
    loadingMatrix = false,
    matrixImage,
    avg = [],
    minMax = [],
    minMaxRange = [],
    graphShadow = true,
    checkedMenu = [],
    onChangeMenu,
  }) => {
    const [xs, sm, md] = useMobile();
    const [menuGroups, setMenuGroups] = useState({});
    const [menuChecked, setMenuChecked] = useState([]);
    const chartRef = useRef();
    const [menuStyle, setMenuStyle] = useState();
    const displayMatrix = useMemo(() => {
      return menuGroups.general?.options.find(
        (option) => option.id === "matrix"
      ) && menuChecked.find((option) => option.id === "matrix")
        ? true
        : false;
    }, [menuGroups, menuChecked]);
    const xMaxLabels = 10;

    const { t } = useTranslation("evocs");
    const handleTranslate = (textId, general = false) => {
      if (general) return translate(t, textId);
      return translate(t, textId, viewNames.DASHBOARD_VIEW);
    };

    function reduceTicks(ticks, maxLabels) {
      const totalElements = ticks.length;

      if (maxLabels >= totalElements) {
        return ticks;
      }

      const reducedArray = [ticks[0]];
      const elementsToSelect = maxLabels - 2;

      if (elementsToSelect > 0) {
        const step = (totalElements - 1) / (elementsToSelect + 1);
        for (let i = 1; i <= elementsToSelect; i++) {
          reducedArray.push(ticks[Math.round(i * step)]);
        }
      }

      reducedArray.push(ticks[totalElements - 1]);
      return reducedArray;
    }

    const graphMenuGroups = useMemo(() => {
      return {
        general: {
          title: handleTranslate("GENERAL", true),
          options: [
            {
              id: "matrix",
              groupId: "general",
              label: handleTranslate("CORRELATION_MATRIX", true),
            },
            {
              id: "r2",
              groupId: "general",
              label: "R2",
            },
          ],
        },
        measure: {
          options: [
            {
              id: "minMax",
              label: handleTranslate("MIN_AND_MAX", true),
            },
            {
              id: "avg",
              label: handleTranslate("AVERAGE", true),
            },
          ],
        },
      };
    }, [t])
    
    

    const scalesConfig = useMemo(() => {
      const generateScales = (measuresUnit, minMaxRange, datasets) => {
        const scales = {
          x: {
            afterBuildTicks: (axis) => {
              if (axis.ticks.length > 2) {
                axis.ticks = reduceTicks(axis.ticks, xMaxLabels);
              }
            },
            grid: {
              drawOnChartArea: true,
              color: "#ccc",
              lineWidth: 0.7,
            },
          },
        };
        let scalesUnit = [];
        let yAxPos = "right";
        measuresUnit.forEach((unit, index) => {
          const newUnit = !scalesUnit.includes(unit);
          yAxPos = newUnit ? (yAxPos === "right" ? "left" : "right") : yAxPos;
          scalesUnit.push(unit);
          scales[`y${index}`] = {
            ticks: {
              callback: (value) => {
                return value ? `${value.toFixed(3)} ${unit}` : "";
              },
              maxTicksLimit: 10,
            },
            grid: {
              drawOnChartArea: index === 0,
              color: "#ccc",
              lineWidth: 0.8,
            },
            // min: minMaxRange[index]?.min,
            // max: minMaxRange[index]?.max,
            suggestedMin: minMaxRange[index]?.min * 0.95, // Lascia un po' di margine sotto
            suggestedMax: minMaxRange[index]?.max * 1.05,
            type: "linear",
            display: newUnit,
            position: yAxPos,
          };
        });
        return scales;
      };

      return generateScales(measuresUnit, minMaxRange, datasets);
    }, [measuresUnit, minMaxRange, datasets]);

    const drawMinMaxLineConfig = useMemo(() => {
      const generateDrawMinMaxLine = (
        measuresUnit,
        avg,
        minMax,
        menuChecked
      ) => {
        return measuresUnit.reduce((acc, unit, index) => {
          acc[`y${index}`] = {
            avg: menuChecked?.find(
              (element) =>
                element.groupId === `y${index}` && element.id === "avg"
            )
              ? avg[index]
              : null,
            minMax: menuChecked?.find(
              (element) =>
                element.groupId === `y${index}` && element.id === "minMax"
            )
              ? minMax[index]
              : null,
            measureUnit: unit,
          };
          return acc;
        }, {});
      };

      return generateDrawMinMaxLine(measuresUnit, avg, minMax, menuChecked);
    }, [measuresUnit, avg, minMax, menuChecked]);

    const getMenuPosition = (chart) =>
      chart.chartArea && {
        position: "absolute",
        top: `${Math.floor(chart.chartArea.top + 5)}px`,
        right: datasets.length > 1 ? "7rem" : xs || sm ? "2rem" : "5rem",
        zIndex: 10,
        // left: `${Math.floor(chart.chartArea.width + 20)}px`
      };

    useEffect(() => {
      let groups = {};
      const generalOptions =
        datasets?.length > 2 || datasets?.length === 1
          ? graphMenuGroups.general.options.filter((element) => element.id !== "r2")
          : graphMenuGroups.general.options;
      groups.general = { ...graphMenuGroups.general, options: generalOptions };
      datasets.forEach((dataset) => {
        const axisOptions = [];
        graphMenuGroups.measure.options.forEach((element) =>
          axisOptions.push({ ...element, groupId: dataset.yAxisID })
        );
        groups[dataset.yAxisID] = {
          title: dataset.label,
          options: axisOptions,
        };
      });
      setMenuStyle(getMenuPosition(chartRef.current));
      setMenuGroups(groups);
    }, [datasets]);

    useEffect(() => {
      setMenuChecked(checkedMenu);
    }, [checkedMenu]);

    const handleResize = (chart, options) => {
      setMenuStyle(getMenuPosition(chart));
    };

    const getTooltip = (context) => {
      const index =
        Number(context.tooltipItems[0]?.dataset.yAxisID.slice(1)) || 0;
      return tooltips[index];
    };

    const handleChangeMenu = (item, value) => {
      let newChecked = [...menuChecked];
      if (value) {
        newChecked.push({ id: item.id, groupId: item.groupId });
      } else {
        const elementIndex = menuChecked.findIndex(
          (element) => element.groupId == item.groupId && element.id === item.id
        );
        newChecked = menuChecked.toSpliced(elementIndex, 1);
      }
      setMenuChecked(newChecked);
      onChangeMenu && onChangeMenu(newChecked, item, value);
    };

    return (
      <Paper
        sx={{
          position: "relative",
          boxShadow: !graphShadow && "none",
          width: "100%",
        }}
      >
        {!disableMenu && (
          <GraphMenu
            style={menuStyle}
            groups={menuGroups}
            checked={menuChecked}
            onChange={handleChangeMenu}
          />
        )}
        <Line
          ref={chartRef}
          data={{
            labels: labels,
            datasets: datasets,
          }}
          plugins={[drawMinMaxLine]}
          options={{
            onResize: handleResize,
            maintainAspectRatio: false,
            responsive: true,
            normalized: true,
            hitRadius: 20,
            hoverRadius: 10,
            cubicInterpolationMode: "monotone",
            scales: scalesConfig,
            plugins: {
              legend: {
                display: datasets.length > 1,
                labels: {
                  color: "black",
                },
                onClick: null,
              },
              tooltip: {
                backgroundColor: "rgba(0, 0, 0, 0.8)",
                titleFont: {
                  size: 11,
                  weight: "bold",
                },
                titleColor: "white",
                displayColors: false,
                callbacks: getTooltip,
              },
              drawMinMaxLine: drawMinMaxLineConfig,
            },
          }}
        />
        {!disableMenu && (
          <CorrelationMatrix
            display={displayMatrix}
            loading={loadingMatrix}
            image={matrixImage}
          />
        )}
      </Paper>
    );
  }
);

export default LineGraph;
