import { Modal, Button, TextField, IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { useEffect, useState, useMemo, useCallback } from "react";
import LoadingGif from "../SharingComponents/LoadingGif";
import { updateCu } from "app/store/evocsSlice/controlUnits/cuActions";
import TabsComponent from "../SharingComponents/TabsComponent";
import StationSelect from "../SharingComponents/Inputs/StationSelect";
import TenantSelect from "../SharingComponents/Inputs/TenantSelect";
import SwitchComponent from "../SharingComponents/Inputs/SwitchComponent";
import EditIcon from "@mui/icons-material/Edit";
import SensorAutocomplete from "../SharingComponents/Inputs/SensorAutocomplete";
import CuHistoryChip from "./CuHistoryChip";
import { modalsComponentNames } from "app/configs/routesNames";
import { useTranslation } from "react-i18next";
import { translate } from "src/utilities/utils";
import { useSelector } from "react-redux";
import {
  setGlobalError,
  setGlobalSuccess,
} from "app/store/evocsSlice/globalError/errorActions";
import FileUploader from "../SharingComponents/FileUploader";
import { readFileCu } from "app/store/evocsSlice/controlUnits/cuActions";
import { handleDownloadB64File } from "src/utilities/utils";
import { getTilestationInfo } from "app/store/evocsSlice/controlUnits/cuActions";
import { getCuMeasures } from "app/store/evocsSlice/controlUnits/cuActions";
import { getSelectedTheme } from "src/utilities/utils";
import useTenantPalette from "src/app/hooks/useTenantPalette";
import FloatingButtons from "../SharingComponents/FloatingButtons";
import { display, height, width } from "@mui/system";
import useMobile from "src/app/hooks/useMobile";

const styles = {
  containerStyle: {
    position: "absolute",
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    background: "#2d3740",
    padding: "1rem",
    borderRadius: "1rem",
    height: "80vh",
    width: "95%",
    maxWidth: "1200px",
  },
  loadingPopup: {
    position: "absolute",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    background: "rgba(255, 255, 255, .5)",
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
  },
};

const requiredInputs = ["serial", "owner"];

const ConfigurationChip = ({
  enabledActions,
  cuId,
  onPending,
  onFinally,
  onChangeInputs,
}) => {
  const { t } = useTranslation("evocs");
  const controlUnits = useSelector((state) => state.controlUnits.list);
  const sensors = useSelector((state) => state.sensors.list);
  const stations = useSelector((state) => state.stationsMongo.list);
  const [disableEdit, setDisableEdit] = useState(true);
  const [disableConfirm, setDisableConfirm] = useState(true);
  const [changedInputs, setChangedInputs] = useState([]);
  const deviceParent = controlUnits[cuId]?.deviceParent;
  const userTenantId = useSelector((state) => state.user.tenantId);
  const [sm] = useMobile()
  let filesCu = controlUnits[cuId]?.filesCu ?? [];
  filesCu = filesCu.map((e) => {
    return { fileName: e.fileName, fileData: null };
  });
  const getDefaultInputs = useCallback(() => {
    if (!controlUnits[cuId]) return {};
    return {
      serial: controlUnits[cuId].serial || "",
      available: controlUnits[cuId].available === 1 ? true : false,
      owner: controlUnits[cuId].owner || "",
      station: deviceParent?.device,
      sensors: controlUnits[cuId].list_sensors,
      dictFileList: filesCu,
    };
  }, []);
  const [inputs, setInputs] = useState(getDefaultInputs());
  const [base64Files, setBase64Files] = useState(filesCu);
  const [isTilestation, setIsTilestation] = useState(false);
  const selectedTheme = useTenantPalette();

  useEffect(() => {
    if (!base64Files) return;
    if (inputs.dictFileList !== base64Files)
      handleChangeInput("dictFileList", base64Files);
  }, [base64Files]);

  const handleTranslate = (textId, general = false) => {
    if (general) return translate(t, textId);
    return translate(t, textId, modalsComponentNames.MODALS);
  };

  const sensorOptions = useMemo(() => {
    if (!controlUnits || !sensors) return [];
    const options = [];
    Object.keys(sensors).forEach((sensorId) => {
      if (
        controlUnits[cuId].list_sensors.includes(sensorId) ||
        !sensors[sensorId].deviceParent
      )
        options.push(sensorId);
    });
    return options;
  }, [controlUnits, sensors]);

  useEffect(() => {
    if (changedInputs.length === 0) {
      setDisableConfirm(true);
      return;
    }
    const disabled = Object.keys(inputs).some(
      (key) =>
        requiredInputs.includes(key) &&
        (inputs[key] === "" || inputs[key]?.length === 0)
    );
    setDisableConfirm(disabled);
    onChangeInputs(changedInputs);
  }, [changedInputs]);

  const handleChangeInput = (key, value) => {
    setInputs({ ...inputs, [key]: value });
    if (
      key === "dictFileList" &&
      JSON.stringify(value) === JSON.stringify(filesCu)
    )
      return;
    setChangedInputs((prev) => [...prev, key]);
  };

  const handleCancelChanges = () => {
    setChangedInputs([]);
    setDisableEdit(true);
    setInputs(getDefaultInputs());
    onChangeInputs([]);
    setBase64Files(filesCu);
  };

  const handleConfirm = () => {
    onPending();
    let uninstall;
    if (deviceParent) {
      if (inputs.station === null) uninstall = true;
      else uninstall = false;
    } else {
      uninstall = false;
    }
    let changed = { ...inputs };
    Object.keys(changed).forEach(
      (key) => !changedInputs.includes(key) && delete changed[key]
    );
    updateCu(
      cuId,
      changed.serial,
      changed.owner,
      changed.available,
      changed.station,
      changed.sensors,
      uninstall,
      changed.dictFileList
    )
      .then(() => setGlobalSuccess(true, "MODAL_SUCCESS_UPDATE_CU"))
      .catch(() => setGlobalError(true))
      .finally(onFinally);
  };

  const handleDownloadFile = async (fileName) => {
    if (!cuId || !fileName) return;
    const base64FileString = await readFileCu(cuId, fileName); // base64 del file
    handleDownloadB64File(base64FileString, fileName);
  };

  const handleDownloadTilestationConfig = async () => {
    let configObj = await getTilestationInfo(cuId);
    configObj = JSON.stringify(configObj);
    configObj = btoa(configObj);
    let cuSerial = controlUnits[cuId]?.serial ?? "";
    cuSerial = cuSerial.split(" ").join("_");
    handleDownloadB64File(configObj, `config_${cuSerial}.json`);
  };

  const checkIsTilestation = useCallback(
    async (cuId) => {
      if (!cuId) return false;
      const measureOnCu = getCuMeasures(cuId);
      const test = measureOnCu.some((measure) => {
        return (
          measure === "65548ac0af4b91cdd6da3229" ||
          measure === "6554a8f2af4b91cdd6da328c"
        );
      });
      setIsTilestation(test);
    },
    [cuId]
  );

  useEffect(() => {
    if (!cuId) return;
    checkIsTilestation(cuId);
  }, [cuId]);

  return (
    inputs && (
      <>
        <div
          className="h-full w-full"
          style={{
            position: "relative",
            display: "flex",
            flexDirection: "column",
            gap: "2rem",
            padding: "2rem",
            paddingBottom: "7rem",
          }}
        >
          <div
            style={{
              display: "flex",
              gap: "5rem",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <h2 style={{ fontWeight: "bold" }}>
              {handleTranslate("CONTROL_UNIT", true)}:{" "}
              {controlUnits[cuId]?.serial}
              {disableEdit && (
                <IconButton
                  sx={{ ml: "1rem" }}
                  onClick={() => setDisableEdit(false)}
                >
                  <EditIcon />
                </IconButton>
              )}
            </h2>
            {enabledActions?.includes("manage-device") && (
              <SwitchComponent
                label={handleTranslate("ACTIVE", true)}
                disabled={disableEdit}
                checked={inputs.available}
                onChange={(value) => handleChangeInput("available", value)}
              />
            )}
          </div>
          {userTenantId === "6554a3a2af4b91cdd6da3285" && (
            <div className="pb-14">
              {" "}
              <span className="text-lg font-semibold ">Id: </span>
              {cuId}{" "}
            </div>
          )}
          {enabledActions?.includes("update-info-device") && (
            <>
              <TextField
                sx={{ width: "100%", maxWidth: "300px" }}
                disabled={disableEdit}
                label={`${handleTranslate("SERIAL", true)}`}
                required
                className="rounded-2xl"
                value={inputs.serial}
                onChange={(e) => handleChangeInput("serial", e.target.value)}
              />
              <TenantSelect
                required
                disabled={disableEdit}
                value={inputs.owner}
                onChange={(value) => handleChangeInput("owner", value)}
              />
            </>
          )}
          {enabledActions?.includes("manage-device") && (
            <>
              <div
                style={{ display: "flex", gap: "1rem", alignItems: "center" }}
              >
                <span style={{ minWidth: "100px" }}>
                  {" "}
                  {handleTranslate("INSTALLED_ON", true)}{" "}
                </span>
                <StationSelect
                  disablePortal
                  disabled={disableEdit}
                  value={inputs.station}
                  onChange={(value) => handleChangeInput("station", value)}
                />
              </div>
              <div
                style={{ display: "flex", gap: "1rem", alignItems: "center" }}
              >
                <span style={{ minWidth: "100px" }}>
                  {" "}
                  {handleTranslate("SENSORS_INSTALLED", true)}{" "}
                </span>
                <SensorAutocomplete
                  disablePortal
                  multiple
                  disabled={disableEdit}
                  limitTags={2}
                  sensorsId={sensorOptions}
                  value={inputs.sensors}
                  onChange={(value) => handleChangeInput("sensors", value)}
                />
              </div>
            </>
          )}
          {isTilestation && (
            <button
              onClick={handleDownloadTilestationConfig}
              className="flex w-full md:w-1/3 justify-center items-center gap-2 text-white rounded-lg hover:bg-blue-600 p-16"
              style={{ background: selectedTheme.primaryColor ?? "#242A30" }}
            >
              {handleTranslate("DONWLOAD_CONFIG")}
            </button>
          )}

          <div className="flex justify-start items-center min-w-3/4 min-h-xs w-full h-full">
            <FileUploader
              base64Files={base64Files}
              setBase64Files={setBase64Files}
              disabled={disableEdit}
              handleDonwload={handleDownloadFile}
            />
          </div>
          {!disableEdit && (
            <FloatingButtons
              containerStyle={sm && {
                position: "fixed",
                height: "fit-content",
                width: "fit-content",
                left: "50%",
                top: "90%",
                transform: "translateX(-50%)",
                display: "flex",
                justifyContent: "center",
                alignItems: "center"
              }}
              disableDelete={true}
              disableSave={disableConfirm}
              onSave={handleConfirm}
              onDiscard={handleCancelChanges}
            />
          )}
        </div>
      </>
    )
  );
};

const CuConfigurationModal = ({
  enabledActions,
  isOpen = false,
  cuId,
  onFinally,
  onClose,
}) => {
  const { t } = useTranslation("evocs");
  const [updateLoading, setUpdateLoading] = useState(false);
  const [changedInputs, setChangedInputs] = useState([]);

  const handleFinally = () => {
    setUpdateLoading(false);
    onFinally && onFinally();
  };

  const handleTranslate = (textId, general = false) => {
    if (general) return translate(t, textId);
    return translate(t, textId, modalsComponentNames.MODALS);
  };

  const tabs = useMemo(() => {
    return {
      generals: {
        header: `${handleTranslate("GENERAL", true)}`,
        content: (
          <ConfigurationChip
            enabledActions={enabledActions}
            cuId={cuId}
            onPending={() => setUpdateLoading(true)}
            onFinally={handleFinally}
            onChangeInputs={setChangedInputs}
          />
        ),
      },
      history: {
        header: `${handleTranslate("HISTORY", true)}`,
        content: <CuHistoryChip cuId={cuId} />,
      },
    };
  }, [cuId, enabledActions]);

  useEffect(() => {
    return () => setChangedInputs([]);
  }, [isOpen]);

  const handleClose = () => {
    setUpdateLoading(false);
    if (!onClose) return;
    if (changedInputs?.length > 0) onClose(false);
    else onClose();
  };

  return (
    <Modal open={isOpen}>
      <div style={styles.containerStyle}>
        <div style={{ display: "flex" }}>
          <IconButton sx={{ marginLeft: "auto" }} onClick={handleClose}>
            <CloseIcon fontSize="small" sx={{ color: "white" }} />
          </IconButton>
        </div>
        <div style={{ position: "relative", height: "calc(100% - 36px)" }}>
          <TabsComponent tabs={tabs} unselectTextColor={"white"} />
          {updateLoading && (
            <div style={styles.loadingPopup}>
              <LoadingGif />
            </div>
          )}
        </div>
      </div>
    </Modal>
  );
};

export default CuConfigurationModal;
