import { Divider, Stack } from "@mui/material"
import ToggleButton from "../SharingComponents/Inputs/ToggleButton"
import ReorderIcon from '@mui/icons-material/Reorder';
import { PiGridFourFill } from "react-icons/pi";
import { BsPieChartFill } from "react-icons/bs";


const SwitchDisplayStack = ({tabular=false, onChange, pieIcon}) => {

  const handleChange = (value) => {
    onChange && onChange(value)
  }

  return (
    <Stack direction="row" alignItems="center" divider={<Divider orientation="vertical" flexItem />}>
      <ToggleButton enabled={tabular} onChange={() => handleChange(true)}><ReorderIcon size={20} /></ToggleButton>
      {!pieIcon && <ToggleButton enabled={!tabular} onChange={() => handleChange(false)}><PiGridFourFill size={20} /></ToggleButton>}
      {pieIcon && <ToggleButton enabled={!tabular} onChange={() => handleChange(false)}><BsPieChartFill size={20}/></ToggleButton>}
    </Stack>
  )
}

export default SwitchDisplayStack;