import { Checkbox, FormControl, FormHelperText, InputLabel, MenuItem, Select } from '@mui/material'
import React, { useLayoutEffect, useMemo, useState } from 'react'
import { viewNames } from 'app/configs/routesNames';
import { useTranslation } from 'react-i18next';
import { translate } from "src/utilities/utils";


const LanguageSelect = ({ style = {}, languages, value, defaultValue, onChange, multiple = false, required = false, disabled = false, fullWidth = false, error=false, helperText="" }) => {
  const options = useMemo(() => {
    if (languages) {
      return languages;
    } else {
      return ["it", "en"];
    }
  }, [languages])
  const [val, setValue] = useState(defaultValue ? defaultValue : multiple ? [] : "");

  useLayoutEffect(() => {
    value !== undefined && setValue(value);
  }, [value]);

  const handleChange = (newValue) => {
    setValue(newValue);
    onChange && onChange(newValue)
  };

  const { t } = useTranslation("evocs");
  const handleTranslate = (textId, general = false) => {
    if (general) return translate(t, textId);
    return translate(t, textId, viewNames.DASHBOARD_VIEW);
  };

  return (
    <FormControl error={error} fullWidth={fullWidth} disabled={disabled} sx={!fullWidth && { ...style, width: "100%", minWidth: "100px", maxWidth: "300px" }}>
      <InputLabel id="language-select-label">{required ? `${handleTranslate("LANGUAGE", true)}*` : `${handleTranslate("LANGUAGE", true)}`}</InputLabel>
      <Select
        labelId="language-select-label"
        value={val}
        multiple={multiple}
        renderValue={(selected) => multiple ? selected.join(", ") : selected}
        label={required ? `${handleTranslate("LANGUAGE", true)}*` : `${handleTranslate("LANGUAGE", true)}`}
        onChange={(e) => handleChange(e.target.value)}
      >
        {options?.map((language, index) => (
          <MenuItem key={index} value={language}>
            {multiple && <Checkbox checked={val?.indexOf(language) > -1} />}
            {language}
          </MenuItem>
        ))}
      </Select>
      <FormHelperText>{helperText}</FormHelperText>
    </FormControl>
  )
}

export default LanguageSelect