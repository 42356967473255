import axios from "axios";
import { 
    TIME_FORMAT, 
    modeStructure, 
    timeRangeInterval, 
    defaultId, 
    defaultIntervalLabel, 
} from "src/components/StationHandling/utils";
import { getNewStartEndDate } from "src/utilities/utils";
import { viewNames } from "app/configs/routesNames";
import { DATA_TYPE_KEY, OPEN_DATA_FILTERS } from "src/utilities/utils";

import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
let {start, end} = getNewStartEndDate(defaultId, defaultIntervalLabel.maxRangeValue);
start = start.valueOf(); 
end = end.valueOf();

const getFilters = createAsyncThunk(
  "filters/readFilters",
  async (tenantId) => {
    const data = await axios
    .get(`/api/py/readFilters`, {params: {
        tenantId: tenantId
    }})
    .then((res) => {
        return res.data;
    })
    .catch((error) => {
        console.log("Error: ", error);
        throw error;
    });
    return data;
  }
);

const updateTenantFilters = createAsyncThunk(
  "filters/updateTenantFilters",
  async (payload) => {
    const {tenantId, viewId, dictFilters} = payload;
    const data = await axios
    .post(`/api/py/updateTenantFilters`, {
        tenantId: tenantId,
        viewId: viewId,
        dictFilters: dictFilters
    })
    .then((res) => {
        return res.data;
    })
    .catch((error) => {
        console.log("Error: ", error);
        throw error;
    });
    return {tenantId, viewId, data};
  }
);

const activeTenantFilters = createAsyncThunk(
  "filters/updateObjectFilters",
  async (payload) => {
    const {tenantId, viewId, dictFilters} = payload;
    const data = await axios
    .post(`/api/py/updateObjectFilters`, {
        tenantId: tenantId,
        viewId: viewId,
        dictFilters: dictFilters
    })
    .then((res) => {
        return res.data;
    })
    .catch((error) => {
        console.log("Error: ", error);
        throw error;
    });
    return {tenantId, viewId, data};
  }
);


const filtersSlice = createSlice({
    name: "filters",
    initialState: {
        [viewNames.STATIONS_VIEW]: {
            CUModes: modeStructure,
            // timeRangeInterval: {
            //     list: timeRangeInterval.filter((e) => [0, 1].includes(e.id)),
            //     selected: defaultIntervalLabel.value,
            //     maxEndingDate: end,
            //     minStartingDate: start,
            //     endingDate: end,
            //     startingDate: start,
            //     timeRangeValue: [0, defaultIntervalLabel.maxRangeValue],
            //     maxRangeValue: defaultIntervalLabel.maxRangeValue,
            //     timeFormat: TIME_FORMAT.DATE_AND_HOUR
            // },
            timeInterval: {
                selected: null,
                startDate: null,
                endDate: null
            },
            stationSelect: {
                list: [],
                selected: null
            },
            measureSelect: {
                list: [],
                selected: null
            },
            // stationType: {
            //     list: Object.keys(STATION_TYPE_LABELS).map((key) => STATION_TYPE_LABELS[key]),
            //     selected: []
            // },
            // projectType: {
            //     list: Object.keys(PROJECT_TYPE_LABELS).map((key) => PROJECT_TYPE_LABELS[key]),
            //     selected: []
            // },
            // stationDataType: {
            //     list: Object.keys(STATION_DATA_TYPE_LABELS).map((key) => STATION_DATA_TYPE_LABELS[key]),
            //     selected: []
            // }
        },
        [viewNames.MAPS_VIEW]: {
            CUModes: modeStructure,
            timeRangeInterval: {
                list: timeRangeInterval.filter((e) =>
                [0, 1].includes(e.id)),
                selected: defaultIntervalLabel.value,
                maxEndingDate: end,
                minStartingDate: start,
                endingDate: end,
                startingDate: start,
                timeRangeValue: [0, defaultIntervalLabel.maxRangeValue],
                maxRangeValue: defaultIntervalLabel.maxRangeValue,
                timeFormat: TIME_FORMAT.DATE_AND_HOUR
            }
        },
        [viewNames.SINGLE_STATION_VIEW]: {
            CUModes: modeStructure,
            measureSelect: {
                list: [],
                selected: null
            },
            timeInterval: {
                selected: null,
                startDate: null,
                endDate: null
            },
        },
        [viewNames.ODOR_REPORTS_VIEW]: {
            timeInterval: {
                selected: null,
                startDate: null,
                endDate: null
            },
            odourLevelSelect: {
                selected: null,
            },
            odourTypeSelect: {
                selected: null,
            },
        },
        [viewNames.PORT_STATE_VIEW]: {
            timeRangeInterval: {
                list: timeRangeInterval, 
            }
        },
        [viewNames.OPEN_DATA_AIR_QUALITY_VIEW]: {
            timeInterval: {
                selected: null,
                startDate: null,
                endDate: null
            },
            sourceSelect: null,
            // dataType: {
            //     list: Object.keys(OPEN_DATA_FILTERS.dataType).map((key) => OPEN_DATA_FILTERS.dataType[key]),
            //     selected: OPEN_DATA_FILTERS.dataType.TUTTI,
            //     key: DATA_TYPE_KEY.TUTTI
            // },
            // source: {
            //     list: [OPEN_DATA_FILTERS.source.TUTTI, ...Object.keys(OPEN_DATA_FILTERS.source)?.slice(1).map((key) => Object.keys(OPEN_DATA_FILTERS.source[key])?.map((value) => OPEN_DATA_FILTERS.source[key][value])).flat()],
            //     selected: OPEN_DATA_FILTERS.source.TUTTI,
            //     dataTypeKEY: DATA_TYPE_KEY.TUTTI
            // },
            // measure: {
            //     list: [OPEN_DATA_FILTERS.source.TUTTI, ...Object.keys(OPEN_DATA_FILTERS.measure)?.slice(1).map((key) => Object.keys(OPEN_DATA_FILTERS.measure[key])?.map((value) => OPEN_DATA_FILTERS.measure[key][value])).flat()],
            //     selected: {
            //         [DATA_TYPE_KEY.AQ]: [OPEN_DATA_FILTERS.measure.TUTTI],
            //         [DATA_TYPE_KEY.METEO]: [OPEN_DATA_FILTERS.measure.TUTTI]
            //     }
            // }
        },
        [viewNames.OPEN_DATA_METEOROLOGY_VIEW]: {
            timeInterval: {
                selected: null,
                startDate: null,
                endDate: null
            },
            sourceSelect: null,
        },
        [viewNames.CONFIGURATION_VIEW]: {
            station: {
                list: [],
                selected: []
            },
            controlUnits: {
                selected: []
            },
            sensors: {
                selected: []
            }
        }
    },
    reducers: {
        setFilter: (state, action) => {
            const {tenantId, viewId, filterId, selectedId, listIds} = action.payload;
            if(selectedId !== undefined) state[tenantId][viewId][filterId].selected = selectedId;
            if(listIds) state[tenantId][viewId][filterId].list = listIds;
        },
        setTimeRangeFilter: (state, action) => {
            const {tenantId, viewId, filterId, intervalId, startDate, endDate} = action.payload;
            if(intervalId !== undefined) state[tenantId][viewId][filterId].selected = intervalId;
            if(startDate !== undefined) state[tenantId][viewId][filterId].startDate = startDate;
            if(endDate !== undefined) state[tenantId][viewId][filterId].endDate = endDate; 
        },
        setTimeIntervalValue: (state, action) => {
            const {viewName, intervalId, startDate, endDate} = action.payload;
            if(intervalId !== undefined) state[viewName].timeInterval.selected = intervalId;
            if(startDate !== undefined) state[viewName].timeInterval.startDate = startDate;
            if(endDate !== undefined) state[viewName].timeInterval.endDate = endDate; 
        },
        setStationSelect: (state, action) => {
            const {viewName, stationId, stationList} = action.payload;
            if(stationList !== undefined) state[viewName].stationSelect.list = stationList;
            if(stationId !== undefined) state[viewName].stationSelect.selected = stationId; 
        },
        setMeasureSelect: (state, action) => {
            const {viewName, measureId, measureList} = action.payload;
            if(measureList !== undefined) state[viewName].measureSelect.list = measureList;
            if(measureId !== undefined) state[viewName].measureSelect.selected = measureId; 
        },
        setOpenDataSourceSelect: (state, action) => {
            const {viewName, sourceId} = action.payload;
            state[viewName].sourceSelect = sourceId; 
        },
        setOdourLevelSelect: (state, action) => {
            const {viewName, odourLevelId} = action.payload;
            state[viewName].odourLevelSelect.selected = odourLevelId; 
        },
        setOdourTypeSelect: (state, action) => {
            const {viewName, odourTypeId} = action.payload;
            state[viewName].odourTypeSelect.selected = odourTypeId; 
        },
        setCUModes: (state, action) => {
            const {type, payload} = action;
            state[payload.key].CUModes = payload.CUModes;
        },
        setSelectedTimeRangeInterval: (state, action) => {
            const {type, payload} = action;
            state[payload.key].timeRangeInterval.selected = payload.selected;
        },
        setEndingDate: (state, action) => {
            const {type, payload} = action;
            state[payload.key].timeRangeInterval.endingDate = payload.date;
        },
        setStartingDate: (state, action) => {
            const {type, payload} = action;
            state[payload.key].timeRangeInterval.startingDate = payload.date;
        },
        setMaxEndingDate: (state, action) => {
            const {type, payload} = action;
            state[payload.key].timeRangeInterval.maxEndingDate = payload.date;
        },
        setMinStartingDate: (state, action) => {
            const {type, payload} = action;
            state[payload.key].timeRangeInterval.minStartingDate = payload.date;
        },
        setTimeRangeValue: (state, action) => {
            const {type, payload} = action;
            state[payload.key].timeRangeInterval.timeRangeValue = payload.range;
        },
        setMaxRangeValue: (state, action) => {
            const {type, payload} = action;
            state[payload.key].timeRangeInterval.maxRangeValue = payload.max;
        },
        setIsHourFormatted: (state, action) => {
            const {type, payload} = action;
            state[payload.key].timeRangeInterval.isHourFormatted = payload.value;
        },
        setTimeFormat: (state, action) => {
            const {type, payload} = action;
            state[payload.key].timeRangeInterval.timeFormat = payload.value;
        },
        setSingleModeBtnTrue : (state, action) => {
            const {viewName, value} = action.payload;
            if (viewName && value){
                state[viewName].CUModes?.forEach(e => {
                    if (e.value === value) e.loaded = true;
                });
            }
        },
        setSingleModeBtnFalse : (state, action) => {
            const {viewName, value} = action.payload;
            if (viewName && value){
                state[viewName].CUModes?.forEach(e => {
                    if (e.value === value) e.loaded = false;
                });
            }
        },
        setAllModeBtnTrue : (state, action) => {
            const {viewName} = action.payload;
            if (viewName){
                state[viewName].CUModes?.forEach(e => { e.loaded = true;});
            }
        },
        setAllModeBtnFalse : (state, action) => {
            const {viewName} = action.payload;
            if (viewName){
                state[viewName].CUModes.forEach(e => { e.loaded = false;});
            }
        },
        setTypeFilterLabels: (state, action) => {
            const {viewName, labels} = action.payload;
            state[viewName].typeReportsFilter.labels = labels;
        },
        setLevelFilterLabels: (state, action) => {
            const {viewName, labels} = action.payload;
            state[viewName].levelReportsFilter.labels = labels;
        },
        setSelectedTypeFilter: (state, action) => {
            const {viewName, selected} = action.payload;
            state[viewName].typeReportsFilter.selected = selected;
        },
        setSelectedLevelFilter: (state, action) => {
            const {viewName, selected} = action.payload;
            state[viewName].levelReportsFilter.selected = selected;
        },
        setSelectedDataTypeFilter: (state, action) => {
            const {viewName, selected, key} = action.payload;
            state[viewName].dataType.selected = selected;
            state[viewName].dataType.key = key;
        },
        setSelectedSourceFilter: (state, action) => {
            const {viewName, selected, dataTypeKEY} = action.payload;
            state[viewName].source.selected = selected;
            state[viewName].source.dataTypeKEY = dataTypeKEY;
        },
        setSelectedMeasureFilter: (state, action) => {
            const {viewName, selected} = action.payload;
            const selectedAQ = [], selectedMETEO = [];
            selected.forEach(element => {
                const key = element===OPEN_DATA_FILTERS.measure.TUTTI ? "TUTTI":Object.keys(OPEN_DATA_FILTERS.measure).slice(1).map((key) => Object.keys(OPEN_DATA_FILTERS.measure[key]).map((value) => element===OPEN_DATA_FILTERS.measure[key][value] ? key: null).filter((e) => e)[0]).filter((e) => e)[0]
                if(key === DATA_TYPE_KEY.AQ) selectedAQ.push(element);
                else selectedMETEO.push(element);
            });
            selectedAQ.length === 0 && selectedAQ.push(OPEN_DATA_FILTERS.measure.TUTTI);
            selectedMETEO.length === 0 && selectedMETEO.push(OPEN_DATA_FILTERS.measure.TUTTI);
            state[viewName].measure.selected = {
                [DATA_TYPE_KEY.AQ]: selectedAQ,
                [DATA_TYPE_KEY.METEO]: selectedMETEO
            }
        },
        setFilteredStations: (state, action) => {
            const {viewName, stations} = action.payload;
            state[viewName].filteredStations = stations;
        },
        setStationFilterList: (state, action) => {
            const {viewName, list} = action.payload;
            state[viewName].station.list = list;
        },
        setSelectedStationFilter: (state, action) => {
            const {viewName, selected} = action.payload;
            state[viewName].station.selected = selected;
        },
        setSelectedStationTypeFilter: (state, action) => {
            const {viewName, selected} = action.payload;
            state[viewName].stationType.selected = selected;
        },
        setSelectedProjectTypeFilter: (state, action) => {
            const {viewName, selected} = action.payload;
            state[viewName].projectType.selected = selected;
        },
        setStationDataTypeFilterList: (state, action) => {
            const {viewName, list} = action.payload;
            state[viewName].stationDataType.list = list;
        },
        setSelectedStationDataTypeFilter: (state, action) => {
            const {viewName, selected} = action.payload;
            state[viewName].stationDataType.selected = selected;
        },
        setSelectedCuFilter: (state, action) => {
            const {viewName, selected} = action.payload;
            state[viewName].controlUnits.selected = selected;
        },
        setSelectedSensorFilter: (state, action) => {
            const {viewName, selected} = action.payload;
            state[viewName].sensors.selected = selected;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(getFilters.fulfilled, (state, action) => {
            Object.keys(action.payload).forEach((tenantId) => {
                Object.keys(action.payload[tenantId]).forEach((viewId) => {
                    Object.keys(action.payload[tenantId][viewId]).forEach((filterId) => {
                        action.payload[tenantId][viewId][filterId] = {
                            list: action.payload[tenantId][viewId][filterId],
                            selected: null
                        }
                    })
                })
            });

            return action.payload;
        });
        builder.addCase(activeTenantFilters.fulfilled, (state, action) => {
            const {tenantId, viewId, data} = action.payload;
            const deactiveFilters = Object.keys(state[tenantId][viewId]).filter((filterId) => !Object.keys(data[tenantId][viewId]).includes(filterId));
            deactiveFilters.forEach((filterId) => delete state[tenantId][viewId][filterId]);
            Object.keys(data[tenantId][viewId]).forEach((filterId) => {
                state[tenantId][viewId][filterId] = {
                    list: data[tenantId][viewId][filterId],
                    selected: null
                }
            });
        });
        builder.addCase(updateTenantFilters.fulfilled, (state, action) => {
            const {tenantId, viewId, data} = action.payload;
            Object.keys(data[tenantId][viewId]).forEach((filterId) => {
                state[tenantId][viewId][filterId] = {
                    ...state[tenantId][viewId][filterId],
                    list: data[tenantId][viewId][filterId],
                }
            });
        });
    }
});

export const {
    setCUModes, 
    setSelectedCUModes, 
    setSelectedTimeRangeInterval, 
    setEndingDate, 
    setStartingDate, 
    setMaxEndingDate, 
    setMinStartingDate, 
    setTimeRangeValue, 
    setMaxRangeValue, 
    setIsHourFormatted, 
    setTimeFormat,
    setSingleModeBtnTrue,
    setSingleModeBtnFalse,
    setAllModeBtnTrue, 
    setAllModeBtnFalse,
    setTypeFilterLabels,
    setLevelFilterLabels,
    setSelectedTypeFilter,
    setSelectedLevelFilter,
} = filtersSlice.actions;

export const filtersSliceActions = {
    ...filtersSlice.actions,
    getFilters,
    updateTenantFilters,
    activeTenantFilters
}

export default filtersSlice.reducer;