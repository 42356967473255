import moment from "moment";
import { openDataTimeRangeInterval, timeRangeInterval } from "src/components/StationHandling/utils";
import mkrBlack from "src/app/asset/markers/Black.png";
import mkrBlue from "src/app/asset/markers/Blue.png";
import mkrDarkBlue from "src/app/asset/markers/DarkBlue.png";
import mkrDarkBlue2 from "src/app/asset/markers/DarkBlue2.png";
import mkrDarkBlue3 from "src/app/asset/markers/DarkBlue3.png";
import mkrDarkGrey from "src/app/asset/markers/DarkGrey.png";
import mkrDarkGrey2 from "src/app/asset/markers/DarkGrey2.png";
import mkrDarkPink from "src/app/asset/markers/DarkPink.png";
import mkrDarkViolet from "src/app/asset/markers/DarkViolet.png";
import mkrEvocsThemeBlack from "src/app/asset/markers/EvocsThemeBlack.png";
import mkrEvocsThemeGreen from "src/app/asset/markers/EvocsThemeGreen.png";
import mkrGranata from "src/app/asset/markers/Granata.png";
import mkrGreen from "src/app/asset/markers/Green.png";
import mkrGreen2 from "src/app/asset/markers/Green2.png";
import mkrGreen3 from "src/app/asset/markers/Green3.png";
import mkrGreen4 from "src/app/asset/markers/Green4.png";
import mkrGreenAlpine from "src/app/asset/markers/GreenAlpine.png";
import mkrGrey from "src/app/asset/markers/Grey.png";
import mkrLightOrange from "src/app/asset/markers/LightOrange.png";
import mkrLightBlue from "src/app/asset/markers/LightBlue.png";
import mkrLime from "src/app/asset/markers/Lime.png";
import mkrOcra from "src/app/asset/markers/Ocra.png";
import mkrOrange from "src/app/asset/markers/Orange.png";
import mkrPink from "src/app/asset/markers/Pink.png";
import mkrPink2 from "src/app/asset/markers/Pink2.png";
import mkrPinkFluo from "src/app/asset/markers/PinkFluo.png";
import mkrPinkFluo2 from "src/app/asset/markers/PinkFluo2.png";
import mkrRed from "src/app/asset/markers/Red.png";
import mkrRed2Pie from "src/app/asset/markers/Red2Pie.png";
import mkrViolet from "src/app/asset/markers/Violet.png";
import mkrWhite from "src/app/asset/markers/White.png";
import mkrYellow from "src/app/asset/markers/Yellow.png";
import mkrRed3 from "src/app/asset/markers/Red3.png";
import mkrBrown from "src/app/asset/markers/Brown.png";


export const tileBytes = "6554a3a2af4b91cdd6da3285"
export const tenantGuest = "665061240f9299fe0b54b5f6"
export const sizerProject = [
  "67658c1b908765f2bab93e61", // Lenviros
  "67658c79908765f2bab93e84", // La spezia port service
];
export const calliopeProject = [
  "6654991de47bdaf0c9b79be7", // Jonian dolphin
  "668b9c6f9f7b769b4d862f1e", // Uniba
  "6735b3c9667e220759058d68", // Arpa
  "673b489cbf2cdde01fc1ae3f", // Planetek
];

export const getSelectedTheme = (selectedTenant, themes) => {
  if (!selectedTenant || !themes) return null;
  if (sizerProject.includes(selectedTenant)) return themes[1]
  else if (selectedTenant === tenantGuest) return themes[2]
  else return themes[0];
};


class SingleMapMarker {
  
  constructor(name, color, markerImage, icon = null) {
    this.name = name;
    this.color = color;
    this.markerImage = markerImage;
    this.icon = icon;
    this.defaultAlpha = 0.8;
  }

  // Metodo per ottenere il colore del bordo in formato rgb
  getBorderColor() {
    return `rgb(${this.color})`;
  }

  // Metodo per ottenere il colore di sfondo in formato rgba
  getBackgroundColor(alpha = this.defaultAlpha) {
    return `rgba(${this.color}, ${alpha})`;
  }

  // Metodo per impostare l'icona del marker
  getIcon(icon) {
    return this.icon;
  }  
  
  setIcon(icon) {
    this.icon = icon;
  }

  getMarkerImage() {
    return this.markerImage;
  }   
  
  getName() {
    return this.name;
  } 
}



export class MapMarkers {
  constructor() {
    this.markers = [
      new SingleMapMarker("mkrBlack", "0, 0, 0", mkrBlack),
      new SingleMapMarker("mkrBlue", "22, 139, 223", mkrBlue),
      new SingleMapMarker("mkrDarkBlue", "0, 103, 163", mkrDarkBlue),
      new SingleMapMarker("mkrDarkBlue2", "0, 0, 255", mkrDarkBlue2),
      new SingleMapMarker("mkrDarkBlue3", "67, 105, 120", mkrDarkBlue3),
      new SingleMapMarker("mkrDarkGrey", "87, 87, 87", mkrDarkGrey),
      new SingleMapMarker("mkrDarkGrey2", "163, 163, 163", mkrDarkGrey2),
      new SingleMapMarker("mkrDarkPink", "255, 89, 183", mkrDarkPink),
      new SingleMapMarker("mkrDarkViolet", "91, 57, 107", mkrDarkViolet),
      new SingleMapMarker("mkrEvocsThemeBlack", "36, 42, 48", mkrEvocsThemeBlack),
      new SingleMapMarker("mkrEvocsThemeGreen", "146, 200, 112", mkrEvocsThemeGreen),
      new SingleMapMarker("mkrGranata", "162, 51, 55", mkrGranata),
      new SingleMapMarker("mkrGreen", "59, 207, 51", mkrGreen),
      new SingleMapMarker("mkrGreen2", "133, 207, 87", mkrGreen2),
      new SingleMapMarker("mkrGreenAlpine", "19, 66, 60", mkrGreenAlpine),
      new SingleMapMarker("mkrGrey", "250, 250, 250", mkrGrey),
      new SingleMapMarker("mkrLightOrange", "255, 166, 0", mkrLightOrange),
      new SingleMapMarker("mkrLightBlue", "138, 218, 255", mkrLightBlue),
      new SingleMapMarker("mkrLime", "243, 247, 1", mkrLime),
      new SingleMapMarker("mkrOcra", "239, 193, 54", mkrOcra),
      new SingleMapMarker("mkrOrange", "246, 150, 48", mkrOrange),
      new SingleMapMarker("mkrPink", "255, 142, 127", mkrPink),
      new SingleMapMarker("mkrPinkFluo", "255, 89, 183", mkrPinkFluo),
      new SingleMapMarker("mkrPinkFluo2", "210, 82, 184", mkrPinkFluo2),
      new SingleMapMarker("mkrRed", "223, 22, 40", mkrRed),
      new SingleMapMarker("mkrRed2Pie", "237, 65, 62", mkrRed2Pie),
      new SingleMapMarker("mkrViolet", "210, 82, 184", mkrViolet),
      new SingleMapMarker("mkrWhite", "250, 250, 250", mkrWhite),
      new SingleMapMarker("mkrYellow", "243, 247, 1", mkrYellow),
      new SingleMapMarker("mkrRed3", "223, 22, 40", mkrRed3),
      new SingleMapMarker("mkrBrown", "104, 40, 0", mkrBrown)
    ];
  }

  // Metodo per ottenere un marker per nome
  getMarkerByName(name) {
    return this.markers.find((marker) => marker.name === name);
  }

  // Metodo per ottenere un marker per indice
  getMarkerByIndex(index) {
    return this.markers[index % this.markers.length];
  }

}

// export default new MapMarkers();





export const DATA_TYPE_KEY = {TUTTI: "TUTTI", AQ: "AQ", METEO: "METEO"};

export const OPEN_DATA_FILTERS = {
    timeRangeInterval: [
        {
          value: "Ultime 6 ore",
          maxRangeValue: 6,
          id : 0,
          isHour : true,
        },
        {
          value: "Ultime 12 ore",
          maxRangeValue: 12,
          id : 1,
          isHour : true,
        },
        {
          value: "Ultime 24 ore",
          maxRangeValue: 24,
          id : 2,
          isHour : true,
        },
    ],
    dataType: {
        [DATA_TYPE_KEY.TUTTI]: "Tutti",
        [DATA_TYPE_KEY.AQ]: "AQ",
        [DATA_TYPE_KEY.METEO]: "METEO"
    },
    source: {
        TUTTI: "Tutti",
        [DATA_TYPE_KEY.AQ]: {    
            ARPA: "Arpa",
            EEA: "EEA",
        },
        [DATA_TYPE_KEY.METEO]: {
            PROT_CIV: "Protezione Civile",
            MISTRAL: "Mistral",
            RON: "Rete ondametrica",
            RMN: "Rete mareografica"
        }    
    },
    measure: {
        TUTTI: "Tutti",
        [DATA_TYPE_KEY.AQ]: {
            AQ1: "AQ1",
            PM10: "Pm 10",
            PM25: "Pm 2.5",
            C6H6: "C6H6",
            O3: "O3",
            NO2: "NO2"
        },
        [DATA_TYPE_KEY.METEO]: {
            WIND: "Vento",
            TEMP: "Temperatura",
            PRESSURE: "Pressione",
            HUMIDITY: "Umidità",
            RAIN: "Pioggia",
        }      
    }
};

export const openDataSourceInAPIString = (source) => {
    switch(source) {
        case OPEN_DATA_FILTERS.source.TUTTI:
          return null;
        case OPEN_DATA_FILTERS.source[DATA_TYPE_KEY.AQ].ARPA:
          return "arpa"
        case OPEN_DATA_FILTERS.source[DATA_TYPE_KEY.AQ].EEA:
          return "EEA"
        case OPEN_DATA_FILTERS.source[DATA_TYPE_KEY.METEO].MISTRAL:
          return "mistral"
        case OPEN_DATA_FILTERS.source[DATA_TYPE_KEY.METEO].PROT_CIV:
          return "prot_civ";
        case OPEN_DATA_FILTERS.source[DATA_TYPE_KEY.METEO].RON:
          return "ron";
        case OPEN_DATA_FILTERS.source[DATA_TYPE_KEY.METEO].RMN:
          return "rmn";
        case null: 
         return null;
        default: 
          return source;
    }
}

export const openDataTypeInAPIString = (type) => {
    switch(type) {
        case OPEN_DATA_FILTERS.dataType[DATA_TYPE_KEY.TUTTI]:
          return null;
        case OPEN_DATA_FILTERS.dataType[DATA_TYPE_KEY.AQ]:
          return "AQ"
        case OPEN_DATA_FILTERS.dataType[DATA_TYPE_KEY.METEO]:
          return "METEO"
        default: 
          return type;
    }
}

export const OPEN_DATA_EXPIRATION_TIME = 60000 * 30;


  



export const toUTC = (startingDate) => {
  // Ottieni il timestamp della data locale
  const timestamp = startingDate.getTime();
  // Ottieni il fuso orario locale
  console.log("toUtc");
  const offset = startingDate.getTimezoneOffset();
  // Converti il timestamp in UTC
  const utcTimestamp = timestamp + offset * 60000;
  // Crea un nuovo oggetto Date con il timestamp UTC
  const utcDate = new Date(utcTimestamp);
  // Ritorna l'oggetto Date in UTC
  return utcDate;
};

export const isTimeDifferenceGreaterThanHours = (
  timestamp1,
  timestamp2,
  hours
) => {
  const time1 = null;
  const time2 = null;

  if (oggetto instanceof Date) {
    time1 = new Date(timestamp1).getTime();
    time2 = new Date(timestamp2).getTime();
  } else if (typeof oggetto === "number" && !isNaN(oggetto)) {
    time1 = timestamp1;
    time2 = timestamp2;
  } else {
    return null;
  }
  // Calcolo la differenza in millisecondi
  const timeDifference = Math.abs(time2 - time1);
  // Calcolo il numero di ore dalla differenza in millisecondi
  const hoursDifference = timeDifference / (1000 * 60 * 60);
  // Verifico se la differenza è maggiore delle ore specificate
  return hoursDifference > hours;
};

// export const differenceInHours = (timestamp1, timestamp2) => {
//   let time1 = null;
//   let time2 = null;

//   if (timestamp1 === null || timestamp2 === null) return null;

//   if (timestamp1 instanceof Date) {
//     time1 = new Date(timestamp1).getTime();
//   } else if (typeof timestamp1 === "number" && !isNaN(timestamp1)) {
//     time1 = timestamp1;
//   }

//   if (timestamp2 instanceof Date) {
//     time2 = new Date(timestamp2).getTime();
//   } else if (typeof timestamp2 === "number" && !isNaN(timestamp2)) {
//     time2 = timestamp2;
//   }

//   if (time1 === null || time2 === null) return null;

//   // Calcolo la differenza in millisecondi
//   const timeDifference = Math.abs(time2 - time1);
//   // Calcola la differenza in ore
//   const ore = timeDifference / (1000 * 60 * 60);
//   return ore;
// };

export const convertUtcArrayToLocalTimezone = (utcDatesArray) => {
  if (!Array.isArray(utcDatesArray)) {
    throw new Error("Input deve essere un array di oggetti Date.");
  }
  const convertedDates = utcDatesArray.map((date) => {
    if (!(date instanceof Date)) {
      throw new Error("Gli elementi dell'array devono essere oggetti Date.");
    }
    const localDates = [];
    for (const utcDate of utcDatesArray) {
      const localDate = moment(utcDate).toLocalTime();
      localDates.push(localDate);
    }
    return localDates;
  });

  return convertedDates;
};

export const convertUtcDateToLocalTimezone = (utcElem) => {

  if (!(utcElem instanceof Date)) {
    throw new Error("L'elemento in input deve essere un oggetto Date");
  }
  return moment(utcElem).local().toDate();
};

export const subtractDays = (date, days) => {
  return moment(date).subtract(days, "days").toDate();
}
export const subtractHours = (date, hours) => {
  return moment(date).subtract(hours, "hours").toDate();
}

export const getNewStartEndDate = (id, openData=false) => {
  try {
    // console.log('getNewStartEndDate');
    const intervalObj = openData === true ? OPEN_DATA_FILTERS.timeRangeInterval.filter((e) => e.id === id)[0]: timeRangeInterval.filter((e) => e.id === id)[0];
    const {isHour, maxRangeValue} = intervalObj;
    const end = new Date();
    const start = isHour? subtractHours(end, maxRangeValue) : subtractDays(end, maxRangeValue)
    return {start: start, end: end};
  } catch (error) {
    console.log(error);
    throw new Error(
      `Impossibile calcore le nuove date per l'intervallo selezionato`
    );
  }
};

export const differenceInHours = (data1, data2) => {
  const firstDate = moment(data1);
  const secondDate = moment(data2);
  const diff = secondDate.diff(firstDate, 'hours');
  return diff;
}

export const vesselStatus = {
  WITH_DESTINATION_PORT_AND_ARRIVE : 0,
  WITH_DESTINATION_PORT_NOT_YET_ARRIVED : 1,
  IN_PORT_AREA_WITH_A_DESTINATION_DIFFERENT_FROM_THE_PORT:2, 
  SHIPS_IN_PORT_ANCHORED_OR_IN_THE_AREA: 3, //case 0 union case 2
}

export const italianPortsId = {
  "Taranto": 10187, //aishub id 2217
  "Sabaudia": null, //aishub id 2448
  "Livorno": 10006, //aishub id 2394
  "Cagliari": 9950, //aishub id 2656
  "Licata": 10032, //aishub id 2873
  "Milazzo": 10054, // aishub id 2189
  "La Spezia": 10176, // aishub id 2169
  "Lecce": null, // aishub id 3326
  "Palermo": 10107 , // aishub id 2098
  "Mondello, Palermo": 10056, // aishub id 2986
  "Venezia": 10210 , // aishub id 2573
  "Gela": 10000 , // aishub id 2521
  "Napoli": 10070 , // aishub id 2525
  "Chioggia": 9960, // aishub id 2851
  "Ischia": 10018, // aishub id 2914
  "San Benedetto del Tronto": 10158 , // aishub id 3224
  "Genova": 10006, // aishub id 2489
  "Porto d'Ischia": 10092,
  "Barletta-Andria-Trani": 9945 ,
  "Brindisi": 9941,
  "Gallipoli": 9999, 
  "Bari": 9948,

};

export const navigationalStatus = {
  0: "in navigazione con motore",
  1: "all'ancora",
  2: "senza comando",
  3: "manovra limitata",
  4: "limitato dal suo pescaggio",
  5: "ormeggiato",
  6: "incagliato",
  7: "impegnato nella pesca",
  8: "in navigazione a vela",
  9: "riservato per futura modifica dello stato di navigazione per navi che trasportano merci pericolose, sostanze nocive o inquinanti marini, o categorie di pericolo o inquinanti IMO C, natanti ad alta velocità (HSC)",
  10: "riservato per futura modifica dello stato di navigazione per navi che trasportano merci pericolose (DG), sostanze nocive (HS) o inquinanti marini (MP), o categorie di pericolo o inquinanti IMO A, ala in superficie (WIG)",
  11: "imbarcazione a motore che traina a poppa (uso regionale)",
  12: "imbarcazione a motore che spinge avanti o traina a fianco (uso regionale)",
  13: "riservato per uso futuro",
  14: "AIS-SART (attivo), MOB-AIS, EPIRB-AIS",
  15: "indefinito = predefinito (usato anche da AIS-SART, MOB-AIS ed EPIRB-AIS in fase di prova)",
};

export const getActualDateAsTimestamp = () => {
  const currentDateTimeUTC = new Date();
  const timestamp = Math.floor(currentDateTimeUTC.getTime() / 1000);
  return timestamp;
}

function getCountryCodeFromIMO(imoNumber) {
  // Estrai le prime tre cifre dall'IMO number
  return countryCode = imoNumber.toString().substring(0, 3);
}

export const getApiTimestamp = (timestampOrDate) => {
  let timestamp;

  if (timestampOrDate instanceof Date) {
    timestamp = timestampOrDate.getTime();
  } else if (typeof timestampOrDate === 'number') {
    timestamp = timestampOrDate; 
  } else {
    return null;
  }

  if(timestamp > 9999999999) {
    return Math.floor(timestamp / 1000);
  }else return timestamp;


  // if (timestamp % 1000 !== 0) {
  //   return Math.floor(timestamp / 1000);
  // } else {
  //   return timestamp;
  // }
}

export const bytesToSize = (bytes, seperator = "") => {
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB']
  if (bytes == 0) return 'n/a'
  const i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)), 10)
  if (i === 0) return `${bytes}${seperator}${sizes[i]}`
  return `${(bytes / (1024 ** i)).toFixed(1)}${seperator}${sizes[i]}`
}

export const shipTypeEng = 
{
  0: 'Not available (default)',
  1: 'Reserved for future use',
  2: 'Reserved for future use',
  3: 'Reserved for future use',
  4: 'Reserved for future use',
  5: 'Reserved for future use',
  6: 'Reserved for future use',
  7: 'Reserved for future use',
  8: 'Reserved for future use',
  9: 'Reserved for future use',
  10: 'Reserved for future use',
  11: 'Reserved for future use',
  12: 'Reserved for future use',
  13: 'Reserved for future use',
  14: 'Reserved for future use',
  15: 'Reserved for future use',
  16: 'Reserved for future use',
  17: 'Reserved for future use',
  18: 'Reserved for future use',
  19: 'Reserved for future use',
  20: 'Wing in ground (WIG), all ships of this type',
  21: 'Wing in ground (WIG), Hazardous category A',
  22: 'Wing in ground (WIG), Hazardous category B',
  23: 'Wing in ground (WIG), Hazardous category C',
  24: 'Wing in ground (WIG), Hazardous category D',
  25: 'Wing in ground (WIG), Reserved for future use',
  26: 'Wing in ground (WIG), Reserved for future use',
  27: 'Wing in ground (WIG), Reserved for future use',
  28: 'Wing in ground (WIG), Reserved for future use',
  29: 'Wing in ground (WIG), Reserved for future use',
  30: 'Fishing',
  31: 'Towing',
  32: 'Towing: length exceeds 200m or breadth exceeds 25m',
  33: 'Dredging or underwater ops',
  34: 'Diving ops',
  35: 'Military ops',
  36: 'Sailing',
  37: 'Pleasure Craft',
  38: 'Reserved',
  39: 'Reserved',
  40: 'High speed craft (HSC), all ships of this type',
  41: 'High speed craft (HSC), Hazardous category A',
  42: 'High speed craft (HSC), Hazardous category B',
  43: 'High speed craft (HSC), Hazardous category C',
  44: 'High speed craft (HSC), Hazardous category D',
  45: 'High speed craft (HSC), Reserved for future use',
  46: 'High speed craft (HSC), Reserved for future use',
  47: 'High speed craft (HSC), Reserved for future use',
  48: 'High speed craft (HSC), Reserved for future use',
  49: 'High speed craft (HSC), No additional information',
  50: 'Pilot Vessel',
  51: 'Search and Rescue vessel',
  52: 'Tug',
  53: 'Port Tender',
  54: 'Anti-pollution equipment',
  55: 'Law Enforcement',
  56: 'Spare - Local Vessel',
  57: 'Spare - Local Vessel',
  58: 'Medical Transport',
  59: 'Noncombatant ship according to RR Resolution No. 18',
  60: 'Passenger, all ships of this type',
  61: 'Passenger, Hazardous category A',
  62: 'Passenger, Hazardous category B',
  63: 'Passenger, Hazardous category C',
  64: 'Passenger, Hazardous category D',
  65: 'Passenger, Reserved for future use',
  66: 'Passenger, Reserved for future use',
  67: 'Passenger, Reserved for future use',
  68: 'Passenger, Reserved for future use',
  69: 'Passenger, No additional information',
  70: 'Cargo, all ships of this type',
  71: 'Cargo, Hazardous category A',
  72: 'Cargo, Hazardous category B',
  73: 'Cargo, Hazardous category C',
  74: 'Cargo, Hazardous category D',
  75: 'Cargo, Reserved for future use',
  76: 'Cargo, Reserved for future use',
  77: 'Cargo, Reserved for future use',
  78: 'Cargo, Reserved for future use',
  79: 'Cargo, No additional information',
  80: 'Tanker, all ships of this type',
  81: 'Tanker, Hazardous category A',
  82: 'Tanker, Hazardous category B',
  83: 'Tanker, Hazardous category C',
  84: 'Tanker, Hazardous category D',
  85: 'Tanker, Reserved for future use',
  86: 'Tanker, Reserved for future use',
  87: 'Tanker, Reserved for future use',
  88: 'Tanker, Reserved for future use',
  89: 'Tanker, No additional information',
  90: 'Other Type, all ships of this type',
  91: 'Other Type, Hazardous category A',
  92: 'Other Type, Hazardous category B',
  93: 'Other Type, Hazardous category C',
  94: 'Other Type, Hazardous category D',
  95: 'Other Type, Reserved for future use',
  96: 'Other Type, Reserved for future use',
  97: 'Other Type, Reserved for future use',
  98: 'Other Type, Reserved for future use',
  99: 'Other Type, no additional information'
}

export const shipTypeIta = {
  0: 'Non disponibile (predefinito)',
  1: 'Riservato per uso futuro',
  2: 'Riservato per uso futuro',
  3: 'Riservato per uso futuro',
  4: 'Riservato per uso futuro',
  5: 'Riservato per uso futuro',
  6: 'Riservato per uso futuro',
  7: 'Riservato per uso futuro',
  8: 'Riservato per uso futuro',
  9: 'Riservato per uso futuro',
  10: 'Riservato per uso futuro',
  11: 'Riservato per uso futuro',
  12: 'Riservato per uso futuro',
  13: 'Riservato per uso futuro',
  14: 'Riservato per uso futuro',
  15: 'Riservato per uso futuro',
  16: 'Riservato per uso futuro',
  17: 'Riservato per uso futuro',
  18: 'Riservato per uso futuro',
  19: 'Riservato per uso futuro',
  20: 'Wing in ground (WIG), tutte le navi di questo tipo',
  21: 'Wing in ground (WIG), Categoria A pericolosa',
  22: 'Wing in ground (WIG), Categoria B pericolosa',
  23: 'Wing in ground (WIG), Categoria C pericolosa',
  24: 'Wing in ground (WIG), Categoria D pericolosa',
  25: 'Wing in ground (WIG), Riservato per uso futuro',
  26: 'Wing in ground (WIG), Riservato per uso futuro',
  27: 'Wing in ground (WIG), Riservato per uso futuro',
  28: 'Wing in ground (WIG), Riservato per uso futuro',
  29: 'Wing in ground (WIG), Riservato per uso futuro',
  30: 'Pesca',
  31: 'Rimorchio',
  32: 'Rimorchio: lunghezza superiore a 200 m o larghezza superiore a 25 m',
  33: 'Dragaggio o operazioni subacquee',
  34: 'Operazioni di immersione',
  35: 'Operazioni militari',
  36: 'Vela',
  37: 'Imbarcazione da diporto',
  38: 'Riservato',
  39: 'Riservato',
  40: 'Imbarcazione ad alta velocità (HSC), tutte le navi di questo tipo',
  41: 'Imbarcazione ad alta velocità (HSC), Categoria A pericolosa',
  42: 'Imbarcazione ad alta velocità (HSC), Categoria B pericolosa',
  43: 'Imbarcazione ad alta velocità (HSC), Categoria C pericolosa',
  44: 'Imbarcazione ad alta velocità (HSC), Categoria D pericolosa',
  45: 'Imbarcazione ad alta velocità (HSC), Riservato per uso futuro',
  46: 'Imbarcazione ad alta velocità (HSC), Riservato per uso futuro',
  47: 'Imbarcazione ad alta velocità (HSC), Riservato per uso futuro',
  48: 'Imbarcazione ad alta velocità (HSC), Riservato per uso futuro',
  49: 'Imbarcazione ad alta velocità (HSC), Nessuna informazione aggiuntiva',
  50: 'Nave pilota',
  51: 'Nave di soccorso',
  52: 'Rimorchiatore',
  53: 'Rimorchiatore portuale',
  54: 'Attrezzatura antinquinamento',
  55: 'Forze dell\'ordine',
  56: 'Riserva - Nave locale',
  57: 'Riserva - Nave locale',
  58: 'Trasporto medico',
  59: 'Nave non combattente secondo la risoluzione RR n. 18',
  60: 'Passeggeri, tutte le navi di questo tipo',
  61: 'Passeggeri, Categoria A pericolosa',
  62: 'Passeggeri, Categoria B pericolosa',
  63: 'Passeggeri, Categoria C pericolosa',
  64: 'Passeggeri, Categoria D pericolosa',
  65: 'Passeggeri, Riservato per uso futuro',
  66: 'Passeggeri, Riservato per uso futuro',
  67: 'Passeggeri, Riservato per uso futuro',
  68: 'Passeggeri, Riservato per uso futuro',
  69: 'Passeggeri, Nessuna informazione aggiuntiva',
  70: 'Cargo, tutte le navi di questo tipo',
  71: 'Cargo, Categoria A pericolosa',
  72: 'Cargo, Categoria B pericolosa',
  73: 'Cargo, Categoria C pericolosa',
  74: 'Cargo, Categoria D pericolosa',
  75: 'Cargo, Riservato per uso futuro',
  76: 'Cargo, Riservato per uso futuro',
  77: 'Cargo, Riservato per uso futuro',
  78: 'Cargo, Riservato per uso futuro',
  79: 'Cargo, Nessuna informazione aggiuntiva',
  80: 'Nave cisterna, tutte le navi di questo tipo',
  81: 'Nave cisterna, Categoria A pericolosa',
  82: 'Nave cisterna, Categoria B pericolosa',
  83: 'Nave cisterna, Categoria C pericolosa',
  84: 'Nave cisterna, Categoria D pericolosa',
  85: 'Nave cisterna, Riservato per uso futuro',
  86: 'Nave cisterna, Riservato per uso futuro',
  87: 'Nave cisterna, Riservato per uso futuro',
  88: 'Nave cisterna, Riservato per uso futuro',
  89: 'Nave cisterna, Nessuna informazione aggiuntiva',
  90: 'Altri tipi, tutte le navi di questo tipo',
  91: 'Altri tipi, Categoria A pericolosa',
  92: 'Altri tipi, Categoria B pericolosa',
  93: 'Altri tipi, Categoria C pericolosa',
  94: 'Altri tipi, Categoria D pericolosa',
  95: 'Altri tipi, Riservato per uso futuro',
  96: 'Altri tipi, Riservato per uso futuro',
  97: 'Altri tipi, Riservato per uso futuro',
  98: 'Altri tipi, Riservato per uso futuro',
  99: 'Altri tipi, Nessuna informazione aggiuntiva'
};



// "MMSI": 538006130,
// "TIME": "2023-10-12 18:22:29 GMT",
// "LONGITUDE": 17.19449,
// "LATITUDE": 40.47252,
// "COG": 109.2,
// "SOG": 0,
// "HEADING": 162,
// "ROT": 0,
// "NAVSTAT": 5,
// "IMO": 9708215,
// "NAME": "ARDMORE SEAFOX",
// "CALLSIGN": "V7JZ6",
// "TYPE": 80,
// "A": 150,
// "B": 33,
// "C": 8,
// "D": 24,
// "DRAUGHT": 8.6,
// "DEST": "IT TAR",
// "ETA": "10-12 03:00"



export const createLanguageObject = (originalObject, prefix) => {
  const newObject = {};
  
  for (const key in originalObject) {
      if (originalObject.hasOwnProperty(key)) {
          const newKey = `${prefix}_${key}`;
          newObject[newKey] = originalObject[key];
      }
  }
  return newObject;
};

export const translate = (t, textId, viewName) => {
  if (viewName) return t(`${viewName}_${textId}`);
  else return t(textId);
};

export const handleDownloadB64File = async (base64FileString, fileName, supportedExtensions) => {

  const fileType = fileName.split('.').pop().toLowerCase();
  
  // Usa le estensioni specificate o quelle predefinite se non fornite
  const extensionsToUse = supportedExtensions || [
    'png', 'jpeg', 'jpg', 'pdf', 'xls', 'xlsx', 'csv', 'doc', 'docx', 'json'
  ];

  const fileExtensions = extensionsToUse.map(ext => {
    switch (ext) {
      case 'png': return { extension: 'png', mime: 'image/png' };
      case 'jpeg': return { extension: 'jpeg', mime: 'image/jpeg' };
      case 'jpg': return { extension: 'jpg', mime: 'image/jpeg' };
      case 'pdf': return { extension: 'pdf', mime: 'application/pdf' };
      case 'xls': return { extension: 'xls', mime: 'application/vnd.ms-excel' };
      case 'xlsx': return { extension: 'xlsx', mime: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' };
      case 'csv': return { extension: 'csv', mime: 'text/csv' };
      case 'doc': return { extension: 'doc', mime: 'application/msword' };
      case 'docx': return { extension: 'docx', mime: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document' };
      case 'json': return { extension: 'json', mime: 'application/json' };
      default: return null; // Estensione non supportata
    }
  }).filter(Boolean); // Filtra via i valori null (estensioni non supportate)


  const file = fileExtensions.find(f => fileType === f.extension);

  if (base64FileString && file) {
    try {
      let byteArray;
      if (fileType === 'json') {
        const jsonString = atob(base64FileString);
        byteArray = new TextEncoder().encode(jsonString);
      } else {
        const byteCharacters = atob(base64FileString);
        const byteNumbers = new Array(byteCharacters.length).fill(0).map((_, i) => byteCharacters.charCodeAt(i));
        byteArray = new Uint8Array(byteNumbers);
      }

      const blob = new Blob([byteArray], { type: file.mime });

      const downloadUrl = URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = downloadUrl;
      a.download = fileName;
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
      URL.revokeObjectURL(downloadUrl);

    } catch (error) {
      console.error('Errore durante la conversione del file:', error);
    }
  } else {
    console.error('Tipo di file non supportato o base64 non valido');
  }
};
