import { viewNames } from 'app/configs/routesNames';
import TableComponent from '../SharingComponents/TableComponent';
import { donwloadReport } from 'app/store/evocsSlice/fileSlice/fileSlice';
import useMobile from 'src/app/hooks/useMobile';
import { useTranslation } from 'react-i18next';
import { translate } from "src/utilities/utils";
import { useEffect, useMemo, useState } from 'react';
import { getOpenDataFiles } from 'app/store/evocsSlice/reports/reportsActions';


const viewName = viewNames.OPEN_DATA_AIR_QUALITY_VIEW;

const AqTable = ({ data, onClickRow, handleDownloadFile, downloadFileProps }) => {
  const [xs] = useMobile();
  const { t } = useTranslation("evocs");
  const [tableData, setTableData] = useState([]);
  const [searchedText, setSearchedText] = useState();
  const handleTranslate = (textId, general = false) => {
    if (general) return translate(t, textId);
    return translate(t, textId, viewNames.OPEN_DATA_AIR_QUALITY_VIEW);
  };

  const aqTableColumns = useMemo(() => {
    return {
      station: handleTranslate('STATION', true),
      time: handleTranslate('DATE', true),
      source: handleTranslate('SOURCE', true),
      C6H6: handleTranslate('MEASURE_C6H6', true),
      O3: handleTranslate('MEASURE_O3', true),
      NO: handleTranslate('MEASURE_NO', true),
      NO2: handleTranslate('MEASURE_NO2', true),
      nox: handleTranslate('MEASURE_NOX', true),
      "PM2.5": handleTranslate('MEASURE_PM25', true),
      PM10: handleTranslate('MEASURE_PM10', true),
      CO2: handleTranslate('MEASURE_CO2', true),
      CO: handleTranslate('MEASURE_CO', true),
      SO2:handleTranslate('MEASURE_SO2', true)
    }
  }, [t]);


  useEffect(() => {
    if(!data || data.length === 0) {
      setTableData([]);
      return;
    };
    if(!searchedText || searchedText === "") {
      setTableData(data);
      return;
    }

    setTableData(() => {
      return data.filter((element) => Object.entries(element).find(([key, value]) => value.toString().toLowerCase().includes(searchedText.toLowerCase())));
    });
    
  }, [data, searchedText])

  const fileName = 'open_data_air';

  // const handleDownloadFile = async (fileType) => {
  //   const fileData = await getOpenDataFiles(timeRangeInView.startDate, timeRangeInView.endDate, sourceSelect.selected, null, null, 'AQ', fileType, fileName);
  //   return fileData;
  // };

  //TODO: Fix download from table AQI Table
  return (
    <TableComponent
      style={{ height: "100%" }}
      rowsForPage={xs ? 4 : 10}
      columns={aqTableColumns}
      data={tableData}
      clickable
      onClickRow={onClickRow}
      tableTitle={handleTranslate('TABLE_TITLE')}
      // downloadFileProps={donwloadReport}
      // downloadFileProps={{ handleDownloadFile: handleDownloadFile, fileName: fileName }} 
      onSearch={setSearchedText}
    />
  );
}

export default AqTable;
