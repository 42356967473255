const CustomPageCarded = ({ header, content, footer }) => {
  return (
    <div
      style={{
        position: "relative",
        display: "flex",
        flexDirection: "column",
        height: "100%",
      }}
    >
      <div>{header}</div>
      <div style={{ height: "100%" }}>{content}</div>
      {footer && <>{footer}</>}
    </div>
  );
};

export default CustomPageCarded;
