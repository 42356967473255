import { useCallback, useEffect, useMemo, useState } from 'react'
import { Box } from '@mui/system'
import { Button, TextField } from '@mui/material'
import { useSelector } from 'react-redux'
import AvatarImage from '../SharingComponents/Inputs/AvatarImage';
import LanguageSelect from '../SharingComponents/Inputs/LanguageSelect';
import EditCard from '../SharingComponents/EditCard';
import { updateUser } from 'app/store/evocsSlice/user/userAction';
import { useTranslation } from 'react-i18next';
import { translate } from 'src/utilities/utils';
import { viewNames } from 'app/configs/routesNames';
import { setGlobalError, setGlobalSuccess } from 'app/store/evocsSlice/globalError/errorActions';

const requiredInputs = ["username", "email", "tenantId", "language", "roleName"];

const ChangePasswordForm = ({isEdit, inputs, onChangeInput, passwordErrors}) => {
  const {t} = useTranslation("evocs");

  const handleTranslate = (textId, general = false) => {
    if (general) return translate(t, textId);
    return translate(t, textId, viewNames.USER_PROFILE_VIEW);
  };

  const getErrorText = useCallback((key) => {
    return passwordErrors[key];
  }, [passwordErrors]);


  return (
    <div style={{marginTop: "2rem", width: "100%"}}>
      <h3 style={{marginBottom: "1rem", textAlign: "center"}}>{handleTranslate("CHANGE", true) + " password"}</h3>
      <div style={{width: "70%", margin: "0 auto", display: "flex", justifyContent: "start", gap: "1.5rem", flexWrap: "wrap"}}>
        <TextField sx={{width: "100%", maxWidth: "300px"}} id='currentPassword' type='password' error={getErrorText("currentPassword") ? true : false} helperText={getErrorText("currentPassword")} disabled={!isEdit} 
        label={handleTranslate('CURRENT_PASSWORD')} value={inputs.currentPassword} onChange={(e) => onChangeInput?.("currentPassword", e.target.value)}/>
        <TextField sx={{width: "100%", maxWidth: "300px"}} id='newPassword' type='password' error={getErrorText("newPassword") ? true : false} helperText={getErrorText("newPassword")} disabled={!isEdit} 
        label={handleTranslate('NEW_PASSWORD')} value={inputs.newPassword} onChange={(e) => onChangeInput?.("newPassword", e.target.value)}/>
        <TextField sx={{width: "100%", maxWidth: "300px"}} id='confirmPassword' type='password' error={getErrorText("confirmPassword") ? true : false} helperText={getErrorText("confirmPassword")} disabled={!isEdit}
        label={handleTranslate('CONFIRM', true) + " password"} value={inputs.confirmPassword} onChange={(e) => onChangeInput?.("confirmPassword", e.target.value)}/>
      </div>
    </div>
  )
}


const UserProfileContent = () => {
  const {t} = useTranslation("evocs");
  const user = useSelector((state) => state.user);
  const tenants = useSelector((state) => state.tenants.list);
  const tenantId = user.tenantId;
  const roleName = tenants?.[tenantId]?.roles.filter((role) => role?.["_id"] === user.roleId).map((role) => role?.["roleName"])[0];
  const defaulInputs = useMemo(() => {
    return {
      userImage: user.proPic,
      username: user.username,
      email: user.email,
      tenantId: tenantId,
      language: user.language || "", 
      roleName : roleName,
      currentPassword: "",
      newPassword: "",
      confirmPassword: ""
    }
  }, [user]);

  const [loadingSave, setLoadingSave] = useState(false);
  const [showChangePasswordForm, setShowChangePasswordForm] = useState(false);
  const [inputs, setInputs] = useState(defaulInputs);
  const [isInputsChanged, setInputsChanged] = useState(false);
  const [canSave, setCanSave] = useState(false);
  const [isEdit, setEdit] = useState(false);
  const [passwordErrors, setPasswordErrors] = useState({
    currentPassword: "", newPassword: "", confirmPassword: ""
  });

  const handleTranslate = (textId, general = false) => {
    if (general) return translate(t, textId);
    return translate(t, textId, viewNames.USER_PROFILE_VIEW);
  };

  useEffect(() => {
    setInputs(defaulInputs);
  }, [user?.language])

  useEffect(() => {
    if(!inputs) return "";
    const newPasswordErrors = {...passwordErrors};
    if(["currentPassword", "newPassword", "confirmPassword"].some((key) => inputs[key])) {
      Object.keys(newPasswordErrors).forEach((key) => {
        if(!inputs[key]) newPasswordErrors[key] = handleTranslate("REQUIRED_INPUT", true);
        else newPasswordErrors[key] = "";
      });
      if(inputs.newPassword.length > 0 && inputs.newPassword.length < 8) {
        newPasswordErrors.newPassword = handleTranslate("PASSWORD_ERROR_LENGTH");
      } 
      else if(inputs.confirmPassword.length > 0 && inputs.newPassword !== inputs.confirmPassword) {
        newPasswordErrors.confirmPassword = handleTranslate("PASSWORD_NOT_MATCH");
      } 
    }else {
      Object.keys(newPasswordErrors).forEach((key) => {
        newPasswordErrors[key] = "";
      });
    }
    setPasswordErrors(newPasswordErrors);
  }, [t, inputs])

  useEffect(() => {
    const error = Object.keys(passwordErrors).find((key) => passwordErrors[key]);
    if(!isInputsChanged || error || (!inputs.currentPassword && (inputs.confirmPassword || inputs.newPassword))) {
      setCanSave(false);
      return;
    }
    const disabled = Object.keys(inputs).some((key) => (requiredInputs.includes(key) && (inputs[key] === "" || inputs[key]?.length === 0)));
    setCanSave(!disabled);
  }, [inputs, passwordErrors]);


  useEffect(() => {
    if(!loadingSave && !isEdit) {
      setInputs(defaulInputs);
      setInputsChanged(false);
      setShowChangePasswordForm(false);
    }
  }, [loadingSave, isEdit])

  const handleChangeInput = (key, value) => {
    setInputs({ ...inputs, [key]: value });
    setInputsChanged(true); 
  };

  const handleCancelChanges = () => {
    setEdit(false);
  };

  const handleSave = () => {
    const newPassword = !inputs.newPassword ? undefined : inputs.newPassword === "" ? undefined : inputs.newPassword;
    const oldPassword = inputs.currentPassword === "" ? undefined : inputs.currentPassword;
    setLoadingSave(true);
    updateUser(user.sqlId, undefined, inputs.language, inputs.userImage, newPassword, oldPassword)
    .then(() => setGlobalSuccess(true))
    .catch(() => setGlobalError(true))
    .finally(() => setLoadingSave(false))
  };


  return (
    <Box padding={{xs: "1rem", sm: "2rem 5rem"}}>
      <EditCard style={{width: "fit-content", margin: "0 auto"}} loading={loadingSave} isClosable={false} disableSave={!canSave} disableDelete onChangeEdit={setEdit} onCancelChanges={handleCancelChanges} onSave={handleSave}>
        <div style={{display: "flex", gap: "1rem", flexDirection: "column", alignItems: "center", margin: "0 auto"}}>
          <AvatarImage disabled={!isEdit} value={inputs.userImage} onChange={(value) => handleChangeInput("userImage", value)}/>
          <div style={{width: "70%", margin: "0 auto", display: "flex", justifyContent: "start", gap: "1.5rem", flexWrap: "wrap"}}>
            <TextField sx={{width: "100%", maxWidth: "300px"}} disabled id='username' label='Username' value={inputs.username} onChange={(e) => handleChangeInput("username", e.target.value)}/>
            <TextField sx={{width: "100%", maxWidth: "300px"}} disabled id='email' label='Email' value={inputs.email} onChange={(e) => handleChangeInput("email", e.target.value)}/>
            <TextField sx={{width: "100%", maxWidth: "300px"}} disabled id='tenant' label="Tenant" value={tenants[inputs.tenantId]?.name || ""} onChange={(e) => handleChangeInput("tenantId", e.target.value)}/>
            <TextField sx={{width: "100%", maxWidth: "300px"}} disabled id='roleName' label="Role" value={inputs.roleName || ""} onChange={(e) => handleChangeInput("roleId", e.target.value)}/>
            <LanguageSelect disabled={!isEdit} value={inputs.language} onChange={(value) => handleChangeInput("language", value)}/>
          </div>
          {showChangePasswordForm ? <ChangePasswordForm isEdit={isEdit} inputs={inputs} passwordErrors={passwordErrors} onChangeInput={handleChangeInput} />
          :<Button disabled={!isEdit} onClick={() => setShowChangePasswordForm(true)}>{handleTranslate("CHANGE_PASSWORD")}</Button>}
        </div>
      </EditCard>
    </Box>
  )
};

export default UserProfileContent;