import { Box } from "@mui/system";
import StationHandlingList from "./StationHandlingList";
import StationHandlingContentHeader from "./StationHandlingContentHeader";
import { useSelector } from "react-redux";
import { useCallback, useEffect, useMemo, useState } from "react";
import { viewNames } from "app/configs/routesNames";
import useFilters from "src/app/hooks/useFilters";
import { getStationMeasures, getStationSensors } from "app/store/evocsSlice/stationsMongo/stationsMongoActions";
import useLoadingDevices from "src/app/hooks/useLoadingDevices";
import LoadingGif from "../SharingComponents/LoadingGif";
import useActions from "src/app/hooks/useActions";
import useMongoData from "src/app/hooks/useMongoData";
import { setTimeRangeFilter } from "app/store/evocsSlice/filters/filtersActions";
import { calcIntervalDates } from "./utils";

const viewName = viewNames.STATIONS_VIEW;

const StationHandlingContent = () => { 
  const loadingDevices = useLoadingDevices();
  const userActions = useActions(viewName);
  const availableStations = useSelector((state) => state.stationsMongo.available);
  const timeRange = useSelector((state) => state.configuration.constants?.timeRange)
  const [stationList, setStationList] = useState();
  const filters = useFilters(viewName);
  const timeRangeInView = filters?.timeRange;
  const stationSelect = filters?.stationName;
  const measureSelect = filters?.measure;
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();
  const [timeRangeStartDate, setTimeRangeStartDate] = useState();
  const [timeRangeEndDate, setTimeRangeEndDate] = useState();
  const sensorsInStations = useMemo(() => {
    if (!availableStations) return {};
    return getStationSensors(availableStations);
  }, [availableStations]);
  const [datasetLoading] = useMongoData(timeRangeStartDate, timeRangeEndDate, sensorsInStations);

  const handleRefresh = useCallback(() => {
    let {start, end} = calcIntervalDates(timeRange[timeRangeInView.selected].timeRange);

    if(!start || !end) {
      start = startDate.valueOf(); end = endDate.valueOf();
    }
    setTimeRangeFilter(viewName, timeRangeInView.id, undefined, start, end);
    setStartDate(new Date(start));
    setEndDate(new Date(end));
  }, [timeRangeInView?.selected, startDate, endDate]);

  const stationsMeasures = useMemo(() => {
    const obj = {};
    availableStations?.forEach((stationId) => {
      obj[stationId] = getStationMeasures(stationId, true);
    });
    return obj;
  }, [loadingDevices, availableStations])

  const getStationList = useCallback(() => {
    const stations = stationSelect.selected?.length > 0 ? stationSelect.selected : availableStations;
    const selectedMeasures = measureSelect?.selected || [];
    if(selectedMeasures.length === 0) return stations;
    else return stations.filter((stationId) => selectedMeasures.every((measureId) => stationsMeasures[stationId].includes(measureId)));;
  }, [stationsMeasures, availableStations, stationSelect?.selected, measureSelect?.selected]);

  useEffect(() => {
    setStationList(getStationList());
  }, [getStationList])

  useEffect(() => {
    if(timeRangeInView.startDate && timeRangeInView.endDate) {
      setStartDate(new Date(timeRangeInView.startDate));
      setEndDate(new Date(timeRangeInView.endDate));
    }
  }, [])

  useEffect(() => {
    if(timeRangeInView.startDate && timeRangeInView.endDate) {
      setTimeRangeStartDate(new Date(timeRangeInView.startDate));
      setTimeRangeEndDate(new Date(timeRangeInView.endDate));
    }
  }, [timeRangeInView.startDate, timeRangeInView.endDate])



  return (
    <Box padding={{xs: "1rem", sm: "2rem 5rem"}}>
      <StationHandlingContentHeader userActions={userActions} filtersInView={filters} stationsMeasures={stationsMeasures}
      startDate={startDate} endDate={endDate} sliderStart={timeRangeStartDate} sliderEnd={timeRangeEndDate} onRefresh={handleRefresh}/> 
      {!loadingDevices ? 
        <StationHandlingList datasetLoading={datasetLoading} userActions={userActions} filtersInView={filters} list={stationList}/>
        :
        <LoadingGif />
      }
    </Box>
    )
}

export default StationHandlingContent;