import { sharingComponentNames } from "app/configs/routesNames";
import { createLanguageObject } from "src/utilities/utils";


const EN = {
    DROP_FILES: "Drop files here",
    DRAG_FILES: "Drag files here or click to select them",
    FILE_UPLOADED: "Files uploaded",
    UPLOAD_DISABLED: "File upload disabled",
    RELATED_DOCUMENTS: "Related documents",
    CLICK_TO_DOWNLOAD: "Click on the file icon to download it"
};

const FILE_UPLOADER_EN = createLanguageObject(EN, sharingComponentNames.FILE_UPLOADER);

export default FILE_UPLOADER_EN;