import Autocomplete from "@mui/material/Autocomplete";
import { useState, useMemo, useEffect } from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import TextField from "@mui/material/TextField";
import Checkbox from "@mui/material/Checkbox";
import { id } from "date-fns/locale";
import { translate } from "src/utilities/utils";
import { viewNames } from "app/configs/routesNames";
import { getCuMeasures } from "app/store/evocsSlice/controlUnits/cuActions";
import { getStationMeasures } from "app/store/evocsSlice/stationsMongo/stationsMongoActions";
import {
  isMeasureMultimedia,
  isMeasureGps,
  isMeasureWind,
  isSensorGps,
  isSensorMultimedia,
  hasSensorWindMeasures,
  getSensorsMeasures,
} from "app/store/evocsSlice/sensors/sensorsActions";
import { setFilter } from "app/store/evocsSlice/filters/filtersActions";
export default function DevicesAutocomplete({
  value,
  defaultValue,
  onChange,
  isUserTenant = true,
  stationId,
  cusId,
  devices,
  setDevices,
  controlUnits, 
  stationSensors,
  selectedDevices,
  setSelectedDevices
}) {
  const { t } = useTranslation("evocs");
  const [val, setValue] = useState(defaultValue ? defaultValue : []);
  const sensors = useSelector((state) => state.sensors.list);

  useEffect(() => {
    setDevices((prev) => ({
      ...prev,
      control_units: Object.entries(controlUnits)
        .map(([id, cu]) => ({
          id: id,
          type: "cu",
          serial: cu.serial,
          selected: false,
          measures: getCuMeasures(id),
        }))
        .filter((cu) => cusId.includes(cu.id)),
      sensors: Object.entries(sensors)
        .map(([id, sensor]) => ({
          id: id,
          type: "sensor",
          serial: sensor.serial,
          selected: false,
          measures: getSensorsMeasures(id),
        }))
        .filter(
          (sensor) => {
            const candidate = stationSensors.includes(sensor.id) && !isSensorMultimedia(sensor.id) && !hasSensorWindMeasures(sensor.id)
            if (candidate === false || candidate && !isSensorGps(sensor.id)) return candidate;
            return sensor.measures.some((m) => !isMeasureGps(m))
          }
        ),
    }));
  }, [stationId]);  

  useEffect(() => {
    setSelectedDevices([
      ...devices.control_units.filter((unit) => unit.selected),
      ...devices.sensors.filter((sensor) => sensor.selected),
    ]);
  }, [devices]);

  const getCuLabel = (option) => {
    return option.serial;
  };

  const handleChange = (newValues) => {
    onChange?.(newValues)
  };

  const handleTranslate = (textId, general = false) => {
    if (general) return translate(t, textId);
    return translate(t, textId, viewNames.SINGLE_STATION_VIEW);
  };

  return (
    <>
      <Autocomplete
        multiple
        options={[...devices.control_units, ...devices.sensors]}
        getOptionLabel={(option) => getCuLabel(option)}
        value={selectedDevices}
        onChange={(_, selected) => handleChange(selected)}
        renderInput={(params) => (
          <TextField {...params} label={handleTranslate("FILTER")} />
        )}
        renderOption={(props, option, { selected }) => (
          <li {...props}>
            <Checkbox checked={selected} />
            {getCuLabel(option)}
          </li>
        )}
      />
    </>
  );
}
