import StationHandlingContent from "src/components/StationHandling/StationHandlingContent";
import WithAuth from "./WithAuth";
import { viewNames } from "app/configs/routesNames";
import StationHandlingHeader from "src/components/StationHandling/StationHandlingHeader";
import CustomPageCarded from "./CustomPageCarded";
import Footer from "src/components/SharingComponents/Footer";

const StationHandling = () => {
  return (
    <WithAuth
      viewName={viewNames.STATIONS_VIEW}
      component={
        <CustomPageCarded
          header={<StationHandlingHeader />}
          content={<StationHandlingContent/>}
          footer={<Footer />}
        />
      }
    />
  );
};

export default StationHandling;
