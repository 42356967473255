import { useState } from "react";
import { FaSearch } from "react-icons/fa";
import useMobile from "src/app/hooks/useMobile";
import { translate } from "src/utilities/utils";
import { useTranslation } from 'react-i18next';
import { viewNames } from 'app/configs/routesNames';

export default function SearchBar({ handleChange }) {
  const [searchValue, setSearchValue] = useState("");
  const [xs, sm, md, lg, xl] = useMobile();
  const handleSearchedValue = (value) => {
    setSearchValue(value);
    handleChange(value);
  };

  const { t } = useTranslation("evocs");
  const handleTranslate = (textId, general = false) => {
    if (general) return translate(t, textId);
    return translate(t, textId, viewNames.INTERNAL_NETWORK_MAN_VIEW);
  };

  return (
    <div
      style={{
        display: "flex",
        gap: "0.5rem",
        alignItems: "center",
        color: "black",
        width: xl && "15%"
      }}
    >
      <FaSearch
        style={{
          fontSize: xl && "2rem",
        }}
      />
      <input
        type="text"
        style={{
            borderRadius: "0.6rem",
            padding: xl ? "0.8rem" : "0.5rem",
            backgroundColor: "rgb(226,232,240)",
            width: xl ? "67%" : "80%"

        }}
        value={searchValue}
        onChange={(e) => handleSearchedValue(e.target.value)}
        placeholder={handleTranslate("SEARCH")}
      />
    </div>
  );
}
