import { Box } from "@mui/system";
import AccordionComponent from "../SharingComponents/AccordionComponent";
import { UNIT_TYPE } from "../StationHandling/utils";
import { useSelector } from "react-redux";
import { memo, useCallback, useEffect, useLayoutEffect, useMemo, useState } from "react";
import TenantSelect from "../SharingComponents/Inputs/TenantSelect";
import { Button, CircularProgress, IconButton, Tooltip } from "@mui/material";
import { updateShareSensor } from "../../app/store/evocsSlice/sensors/sensorsActions";
import { updateShareCu } from "app/store/evocsSlice/controlUnits/cuActions";
import { updateShareStation } from "app/store/evocsSlice/stationsMongo/stationsMongoActions";
import LoadingGif from "../SharingComponents/LoadingGif";
import { getTenantCus, getTenantSensors, getTenantStations } from "app/store/evocsSlice/tenants/tenantsActions";
import { viewNames } from 'app/configs/routesNames';
import { useTranslation } from 'react-i18next';
import { translate } from "src/utilities/utils";
import EditIcon from '@mui/icons-material/Edit';
import { setGlobalError, setGlobalSuccess, setServerError } from "app/store/evocsSlice/globalError/errorActions";
import CreateSharingModal from "../Modals/CreateSharingModal";


const SingleUnit = ({ unitId, unit, tenantId, isEdit, selectedTenants, onChange }) => {
  
  const { t } = useTranslation("evocs");
  const handleTranslate = (textId, general = false) => {
    if (general) return translate(t, textId);
    return translate(t, textId, viewNames.ADMINISTRATION_VIEW);
  };
  const tenants = useSelector((state) => state.tenants.list);
  const [tenantsList, setTenantsList] = useState([]);

  useLayoutEffect(() => {
    if (!tenants) return;
    const newList = Object.keys(tenants).filter((id) => id !== tenantId);
    setTenantsList(newList);
  }, [tenants]);


  return (
    <div style={{ display: "flex", gap: "1rem", alignItems: "center" }}>
      <p style={{ width: "100%", maxWidth: "200px" }}>{unit.serial}</p>
      <TenantSelect disabled={!isEdit} tenantsList={tenantsList} placeholder={handleTranslate("SHARE_WITH")} multiple value={selectedTenants} onChange={(value) => onChange(unitId, value)} />
    </div>
  )
}

const SharedUnitsList = memo(({units, sharedUnits, shareState, tenantId, loadingSave, isEdit, canSave, onChange, onSave, onEdit, onDiscardChanges}) => {
  const { t } = useTranslation("evocs");
  const handleTranslate = (textId, general = false) => {
    if (general) return translate(t, textId);
    return translate(t, textId, viewNames.ADMINISTRATION_VIEW);
  };

  return (
    <div>
      <h3 style={{fontWeight: "bold", marginBottom: "1rem" }}> {handleTranslate('IN_SHARING')} 
      {sharedUnits.length > 0 && !isEdit && 
        <Tooltip title="Edit">
          <IconButton onClick={(e) => {
            e.stopPropagation();
            onEdit?.()
          }}>
            <EditIcon fontSize="small" />
          </IconButton>
        </Tooltip>}
      </h3>
      {sharedUnits.length > 0 ? (
        <>
          <div style={{ display: "flex", flexDirection: "column", gap: "1rem" }}>
            {sharedUnits.map((unitId) => (
              <SingleUnit key={unitId} unitId={unitId} unit={units[unitId]} tenantId={tenantId} isEdit={isEdit} selectedTenants={shareState[unitId]} onChange={onChange} />
            ))}
          </div>
          {!loadingSave ? 
            isEdit &&
              <>
                <Button disabled={!canSave} onClick={onSave}> {handleTranslate("SAVE", true)} </Button>
                <Button onClick={onDiscardChanges}> {handleTranslate("DISCARD_CHANGES", true)} </Button>
              </>
            :
            <Button endIcon={loadingSave && <CircularProgress size={15} />}> {handleTranslate('SAVING', true)} </Button>
          }
        </>
      ): (
        <label> {handleTranslate('NO_SHARED_STATIONS', true)} </label>
      )}
    </div>
  );
});

const SingleUnitType = ({ tenantId, unitType, units }) => {
  const { t } = useTranslation("evocs");
  const [loadingSave, setLoadingSave] = useState(false);
  const [isEdit, setEdit] = useState(false);
  const [canSave, setSave] = useState(false);

  const initialShareState = useMemo(() => {
    if (!units) return {};
    let state = {};
    Object.keys(units).forEach((id) => {
      if (Object.keys(units[id].sharing).length === 0) return;
      state[id] = [...Object.keys(units[id].sharing)];
    });
    return state;
  }, [units])
  const [shareState, setShareState] = useState(initialShareState);
  const [changedUnits, setChangedUnits] = useState([]);

  const isUnitChanged = useCallback((shareState, unitId) => {
    return shareState[unitId].length !== initialShareState[unitId].length || shareState[unitId].some((tenantId) => !initialShareState[unitId].includes(tenantId));
  }, [initialShareState]);

  const handleTranslate = (textId, general = false) => {
    if (general) return translate(t, textId);
    return translate(t, textId, viewNames.ADMINISTRATION_VIEW);
  };

  const sharedUnits = useMemo(() => {
    return Object.keys(units).filter((id) => units[id].owner === tenantId && Object.keys(units[id].sharing).length > 0);
  }, [units]);

  const accordionTitle = useMemo(() => {
    switch (unitType) {
      case UNIT_TYPE.stations:
        return handleTranslate("STATIONS", true);
      case UNIT_TYPE.controlUnits:
        return handleTranslate("CONTROL_UNITS", true);
      case UNIT_TYPE.sensors:
        return handleTranslate("SENSORS", true);
      default:
        return "";
    };
  }, [t, unitType]);

  useEffect(() => {
    setShareState(initialShareState);
  }, [initialShareState])

  useEffect(() => {
    if(changedUnits.length > 0) setSave(true);
    else setSave(false);
  }, [changedUnits])

  useEffect(() => {
    if(!isEdit) {
      setSave(false);
      setChangedUnits([]);
    }
  }, [isEdit])


  const handleChangeSingleUnit = (unitId, tenantsId) => {
    const newShareState = { ...shareState, [unitId]: tenantsId};
    setShareState(newShareState);
    if(isUnitChanged(newShareState, unitId)) {
      if(!changedUnits.includes(unitId)) setChangedUnits((prev) => [...prev, unitId]);
    }else {
      setChangedUnits((prev) => prev.filter((id) => id !== unitId))
    }
  };

  const handleDiscardChanges = () => {
    setShareState(initialShareState);
    setEdit(false);
  }

  const handleSave = () => {
    setLoadingSave(true);
    const allFetch = [];
    changedUnits.forEach((unitId) => {
      if (unitType === UNIT_TYPE.stations) allFetch.push(updateShareStation(unitId, shareState[unitId]));
      else if (unitType === UNIT_TYPE.controlUnits) allFetch.push(updateShareCu(unitId, shareState[unitId]));
      else allFetch.push(updateShareSensor(unitId, shareState[unitId]))
    });
    Promise.all(allFetch)
    .then(() => setGlobalSuccess(true))
    .catch(() => setGlobalError(true))
    .finally(() => {
      setEdit(false);
      setLoadingSave(false);
    });
  };

  return (
    <AccordionComponent title={accordionTitle}>
      <SharedUnitsList tenantId={tenantId} units={units} sharedUnits={sharedUnits} shareState={shareState} loadingSave={loadingSave} isEdit={isEdit} canSave={canSave} onChange={handleChangeSingleUnit} onEdit={() => setEdit(true)} onSave={handleSave} onDiscardChanges={handleDiscardChanges}/>
    </AccordionComponent>
  ) 
}

const SingleTenant = ({ tenantId }) => {
  const stationsList = useSelector((state) => state.stationsMongo.list);
  const cusList = useSelector((state) => state.controlUnits.list);
  const sensorsList = useSelector((state) => state.sensors.list);

  const tenant = useSelector((state) => state.tenants.list?.[tenantId]);
  const user = useSelector((state) => state.user);
  const [loading, setLoading] = useState(false);
  const [stations, setStations] = useState({});
  const [cus, setCus] = useState({});
  const [sensors, setSensors] = useState({});

  const fetchSharingData = useCallback(async() => {
    if (tenantId === user.selectedTenant)
      return [stationsList, cusList, sensorsList];

    const tenantsArray = [tenantId];
    const fetchData = [
      getTenantStations(tenantsArray).then((data) => data?.list),
      getTenantCus(tenantsArray).then((data) => data?.list),
      getTenantSensors(tenantsArray).then((data) => data?.list)
    ];

    return await Promise.all(fetchData);
  }, [user.selectedTenant, stationsList, cusList, sensorsList])

  useEffect(() => {
    if (tenantId !== user.selectedTenant) setLoading(true);
    fetchSharingData().then((res) => {
      setStations(res[0]);
      setCus(res[1]);
      setSensors(res[2]);
      setLoading(false);
    }).catch(() => {
      setServerError(true);
    });
  }, [fetchSharingData]);

  const getUnits = useCallback((unitType) => {
    switch (unitType) {
      case UNIT_TYPE.stations:
        return stations;
      case UNIT_TYPE.controlUnits:
        return cus;
      case UNIT_TYPE.sensors:
        return sensors;
    }
  }, [stations, cus, sensors])

  return (
    <AccordionComponent title={tenant.name}>
      <Box padding={{ xs: "0", md: "1rem 4rem" }}>
        {!loading ? (
          ["stations", "controlUnits", "sensors"].map((type, index) =>
            type !== "cu" && (
              <SingleUnitType key={type} tenantId={tenantId} unitType={UNIT_TYPE[type]} units={getUnits(type)} />
            ))
        ) :
          <LoadingGif />
        }
      </Box>
    </AccordionComponent>
  )
}

const SharingInfo = ({ selectedTenants }) => {

  return (
    <div style={{ display: "flex", flexDirection: "column" }}>
      {selectedTenants.map((tenantId, index) => (
        <SingleTenant key={tenantId} tenantId={tenantId} />
      ))}
    </div>
  );
}

const SharingConfiguration = ({ selectedTenants }) => {
  const { t } = useTranslation("evocs");
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleTranslate = (textId, general = false) => {
    if (general) return translate(t, textId);
    return translate(t, textId, viewNames.ADMINISTRATION_VIEW);
  };

  return (
    <div>
      <Box sx={{ width: "100%", padding: "1rem" }}>
        <div style={{ display: "flex", marginBottom: "1rem" }}>
          <button
            className="px-16 py-10 md:ml-auto w-full md:w-fit"
            style={{
              backgroundColor: "#5d9a60",
              borderRadius: "5px",
              color: "white",
              textTransform: "uppercase",
            }}
            onClick={() => setIsModalOpen(true)}
          >
            {handleTranslate("NEW_SHARING")}
          </button>
        </div>
        <SharingInfo selectedTenants={selectedTenants} />
      </Box>
      <CreateSharingModal isOpen={isModalOpen} onClose={() => setIsModalOpen(false)} onFinally={() => setIsModalOpen(false)}/>
    </div>
  )
}

export default SharingConfiguration;