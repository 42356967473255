import { useCallback, useEffect, useState } from 'react';
import TableComponent from '../SharingComponents/TableComponent';
import { viewNames } from 'app/configs/routesNames';
import {useTranslation} from 'react-i18next';
import { translate } from "src/utilities/utils";


const VesselsTable = ({vessels=[], onClickRow}) => {
  const { t } = useTranslation("evocs");
  const handleTranslate = (textId, general = false) => {
    if (general) return translate(t, textId);
    return translate(t, textId, viewNames.VESSELS_VIEW);
  };
  const [data, setData] = useState([]);

  useEffect(() => {
    vessels && setData(vessels);
  }, [vessels])


  const getFilerData = useCallback((searchText) => {
    return vessels.filter((element) => Object.keys(element).some((key) => element[key] && element[key].toString().toLowerCase().includes(searchText.toLowerCase())));
  }, [vessels]);

  const handleSearch = useCallback((value) => {
    if(!value || value === "") setData(vessels);
    else setData(getFilerData(value));
  }, [getFilerData]);

  const handleClickRow = (rowIndex) => {
    onClickRow && onClickRow(data[rowIndex], rowIndex)
  }

  const columns = {
    name: handleTranslate("NAME", true),
    flag : handleTranslate("COUNTRY"),
    mmsi: "MMSI",
    dest: handleTranslate("DESTINATION", true),
    time: handleTranslate("DATE", true),
    imo: "IMO",
    lat: handleTranslate("MEASURE_LAT", true),
    lon: handleTranslate("MEASURE_LON", true),
    // navstat: "Stato",
    // heading: "heading",
    // sog: "sog",
    // ship: "ID",
    // type: "Tipo",
  };


  return (
    <TableComponent clickable columns={columns} data={data} rowsForPage={5} onSearch={handleSearch} onClickRow={handleClickRow}/>
  )
}

export default VesselsTable