import React, { useEffect } from "react";
import SearchBar from "./InternalNetworkComponents/Searchbar";
import DeviceList from "./InternalNetworkComponents/DeviceList";
import { useSelector } from "react-redux";
import AddDeviceModal from "./InternalNetworkComponents/AddDeviceModal";
import { useState } from "react";
import { setGlobalSuccess } from "app/store/evocsSlice/globalError/errorActions";
import { viewNames } from "app/configs/routesNames";
import { translate } from "src/utilities/utils";
import { useTranslation } from "react-i18next";
import { BiSolidError } from "react-icons/bi";


const InternalNetworkManContent = () => {
  const { t } = useTranslation("evocs");
  const handleTranslate = (textId, general = false) => {
    if (general) return translate(t, textId);
    return translate(t, textId, viewNames.INTERNAL_NETWORK_MAN_VIEW);
  };
  //! LISTA DEI DISPOSITIVI
  const readVpnErorr = useSelector((state) => state.networkDevices.error);
  const devices = useSelector((state) => state.networkDevices.value);

  //* LISTA DEI DISPOSITIVI FILTRATI
  const [filteredDevices, setFilteredDevices] = useState(devices);

  //? FUNZIONE DI RICERCA
  const handleChange = (value) => {
    setFilteredDevices(
      devices.filter(
        (device) =>
          device?.name.toLowerCase().includes(value.toLowerCase()) ||
          device?.port.toString().includes(value)
      )
    );
  };
  const addMessage = () => {
    setGlobalSuccess(
      true,
      handleTranslate("NEW_DEVICE_SUCCESSFULLY_ADDED_TO_VPN")
    );
  };

  const VpnErrorComponent = () => {
    return (
      <div className= "flex flex-col items-center justify-center space-y-10 p-10 h-full w-full">
        <BiSolidError className="text-8xl"/> 
        <span className="font-semibold text-xl">{handleTranslate("INTERNAL_NETWORK_ERROR")}</span>
      </div>
    );
  };

  return (
    <section
      style={{
        padding: "5rem 0rem",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      {!readVpnErorr ? (
        <>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              width: "95%",
            }}
          >
            <SearchBar handleChange={handleChange} />
            <AddDeviceModal addMessage={addMessage} />
          </div>
          <DeviceList
            filteredDevices={filteredDevices}
            setFilteredDevices={setFilteredDevices}
          />
        </>
      ) : (
        <VpnErrorComponent />
      )}
    </section>
  );
};

export default InternalNetworkManContent;




