import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";

const getStationInfo = createAsyncThunk(
  "stationsMongo/getStationInfo",
  async (tenantIds) => {
    const data = await axios
      .post("/api/py/readStationInfo", {
        tenantIds: tenantIds,
      })
      .then((res) => {
        // console.log('getStationInfo: ', res);
        return res.data;
      })
      .catch((error) => {
        console.log("Error: ", error);
        throw error;
      });
    return data;
  }
);

const createStation = createAsyncThunk(
  "stationsMongo/createStation",
  async (payload) => {
    const {
      serial,
      owner,
      stationType,
      desc,
      project,
      listCu,
      listSensors,
      baseLocationId,
      newLocation,
      available,
      setStore,
    } = payload;
    const data = await axios
      .post("/api/py/createStation", {
        serial: serial,
        owner: owner,
        mobile: 0,
        station_type: stationType,
        desc: desc,
        project: project,
        list_sensors: listSensors,
        list_cu: listCu,
        base_location: baseLocationId,
        new_location: newLocation,
        available: available,
      })
      .then((res) => {
        return res.data;
      })
      .catch((error) => {
        console.log("Error: ", error);
        throw error;
      });

    const stationId = data._id;
    delete data._id;
    return { stationId, station: data, setStore };
  }
);

const updateStation = createAsyncThunk(
  "stations/updateStation",
  async (payload) => {
    const {
      stationId,
      serial,
      owner,
      available,
      stationType,
      desc,
      project,
      listSensors,
      listCu,
      baseLocationId,
      operationTime,
    } = payload;
    const data = await axios
      .post("/api/py/updateStation", {
        stationId: stationId,
        serial: serial,
        owner: owner,
        available: available,
        // mobile: mobile,
        station_type: stationType,
        desc: desc,
        project: project,
        list_sensors: listSensors,
        list_cu: listCu,
        base_location: baseLocationId,
        operationTime: operationTime,
      })
      .then((res) => {
        return res.data;
      })
      .catch((error) => {
        console.log("Error: ", error);
        throw error;
      });

    return data;
  }
);

const deleteStation = createAsyncThunk(
  "stationsMongo/deleteStation",
  async (stationId) => {
    const data = await axios
      .post("/api/py/deleteStation", {
        stationId: stationId,
      })
      .then((res) => {
        return stationId;
      })
      .catch((error) => {
        console.log("Error: ", error);
        throw error;
      });

    return data;
  }
);

const getHistory = createAsyncThunk(
  "stationsMongo/getHistory",
  async (deviceDict) => {
    const data = await axios
      .post("/api/py/readDeviceHistory", {
        deviceDict: deviceDict,
      })
      .then((res) => {
        return res.data;
      })
      .catch((error) => {
        console.log("Error: ", error);
        throw error;
      });
    return data;
  }
);

const updateHistory = createAsyncThunk(
  "stationsMongo/updateHistory",
  async ({
    stationId,
    oldTimeList,
    updateTimeList,
    listDeleted,
    listAdded,
  }) => {
    const data = await axios
      .post("/api/py/updateHistory", {
        deviceId: stationId,
        typeDevice: "station",
        oldTimeList: oldTimeList,
        updateTimeList: updateTimeList,
        listDeleted: listDeleted,
        listAdded: listAdded,
      })
      .then((res) => {
        return res.data;
      })
      .catch((error) => {
        console.log("Error: ", error);
        throw error;
      });
    return data;
  }
);

const downloadHistory = createAsyncThunk(
  "stationsMongo/updateHistory",
  async ({ stationId, form }) => {
    const data = await axios
      .post("/api/py/createHistoryFile", {
        deviceDict: {
          [stationId]: "station",
        },
        form: form, // csv o excel
      })
      .then((res) => {
        return res.data;
      })
      .catch((error) => {
        console.log("Error: ", error);
        throw error;
      });
    return data;
  }
);

// const getHistory = createAsyncThunk(
//   "stationsMongo/getHistory",
//   async ({tenant, stationId, cuId, sensorId, start, end, stationHist, cuHist, sensorHist}) => {
//     const data = await axios.get("/api/py/getHistory", {params:{
//       tenant: tenant,
//       stationId: stationId,
//       cuId: cuId,
//       sensorId: sensorId,
//       start: start,
//       end: end,
//       stationHist: stationHist,
//       cuHist: cuHist,
//       sensorHist: sensorHist
//     }})
//     .then((res) => {
//       return res.data;
//     }).catch((error) => {
//       console.log("Error: ", error);
//       throw error;
//     });
//     return data;
//   }
// );

const getThreshold = createAsyncThunk(
  "stationsMongo/getThreshold",
  async (payload) => {
    const { tenantId, stationIds } = payload;
    const data = await axios
      .post("api/py/readThreshold", {
        tenantId: tenantId,
        sensorIds: [],
        stationIds: stationIds,
      })
      .then((res) => {
        return res.data;
      })
      .catch((error) => {
        throw error;
      });
    return data;
  }
);

const createShareStation = createAsyncThunk(
  "stationsMongo/createShareStation",
  async (dictShare) => {
    const data = await axios
      .post(`/api/py/createShareStation`, {
        dictShare: dictShare,
      })
      .then((res) => {
        return res.data;
      })
      .catch((error) => {
        console.log("Error: ", error);
        throw error;
      });
    return data;
  }
);

const updateShareStation = createAsyncThunk(
  "stationsMongo/updateShareStation",
  async (payload) => {
    const { stationId, tenantsList } = payload;
    const data = await axios
      .post(`/api/py/updateShareStation`, {
        dictShare: {
          [stationId]: tenantsList,
        },
      })
      .then((res) => {
        return res.data;
      })
      .catch((error) => {
        console.log("Error: ", error);
        throw error;
      });
    return data;
  }
);

const lastDetectionStation = createAsyncThunk(
  "stationsMongo/lastDetectionStation",
  async (payload) => {
    const { stationIds, tenantId, interval } = payload;
    const data = await axios
      .post(`/api/py/lastDetectionStation`, {
        stationIds: stationIds,
        tenantId: tenantId,
        intervalToCheck: interval, // opzionale di base sono 15 minuti
        available: true, // opzionale
      })
      .then((res) => {
        if (res?.data) return res.data;
        throw error;
      })
      .catch((error) => {
        console.log("Error: ", error);
        throw error;
      });
    return data;
  }
);

const getLastPosition = createAsyncThunk(
  "stationsMongo/readLastSensorsPosition",
  async (payload) => {
    const { sensorIds, startDate, endDate, stationType } = payload;
    const data = await axios
      .post(`/api/py/readLastSensorsPosition`, {
        sensorIds: sensorIds,
        startDate: startDate,
        endDate: endDate,
        stationType: stationType,
      })
      .then((res) => {
        return res.data;
      })
      .catch((error) => {
        console.log("Error: ", error);
        throw error;
      });
    return data;
  }
);

const findStationRoute = createAsyncThunk(
  "stationsMongo/findStationRoute",
  async (payload) => {
    const { stationIds, startDate, endDate, minDistance } = payload;
    const data = await axios
      .post(`/api/py/findStationRoute`, {
        stationIds: stationIds,
        start_date: startDate,
        end_date: endDate,
        min_distance: minDistance,
      })
      .then((res) => {
        return res.data;
      })
      .catch((error) => {
        console.log("Error: ", error);
        throw error;
      });
    return data;
  }
);


// {stationId: , value: [
//   [timestamp, lat, lon, indicequalitAria]
// ]}

const getStationEcoPath = createAsyncThunk(
  "stationsMongo/getStationEcoPath",
  async (payload) => {
    const {stationId, startDate, endDate, minDistance} = payload;
    const data = await axios
    .post(`/api/py/ecoPath`, {
      stationId: stationId,
      start_date: startDate,
      end_date: endDate,
      min_distance: minDistance
    })
    .then((res) => {
      return res.data;
    })
    .catch((error) => {
      console.log("Error: ", error);
      throw error;
    });
    return data;
  }
);

const getPolarPlots = createAsyncThunk(
  "stationsMongo/getPolarPlots",
  async (payload) => {
    const { startDate, endDate } = payload;
    const data = await axios
      .post(`/api/py/polarPlot`, {
        start_date: startDate,
        end_date: endDate,
        sensorIdVoc: "6567543c1484d124275f87bb", // sede ecocatars id voc
        sensorIdMet: "65796c5cdc473d7e014d0bd0", // sede ecotaras id anemometro
        engine: "python",
      })
      .then((res) => {
        return res.data;
      })
      .catch((error) => {
        console.log(error);
        return null;
      });
    return data;
  }
);

const calculateR2Sensors = createAsyncThunk(
  "stationsMongo/calculateR2Sensors",
  async (payload) => {
    const { dictSensors, startDate, endDate } = payload;
    const data = await axios
      .post(`/api/py/calculateR2Sensors`, {
        dictSensors,
        startDate,
        endDate,
      })
      .then((res) => {
        return res.data;
      })
      .catch((error) => {
        console.log("Error: ", error);
        throw error;
      });
    return data;
  }
);

const getDeprecatedStations = createAsyncThunk(
  "configuration/getDeprecatedStations",
  async () => {
    const data = await axios
      .post(`/api/py/deprecationDeal`, {
        collectionName: "stations",
        action: "read",
      })
      .then((res) => {
        return res.data;
      })
      .catch((error) => {
        console.log("Error: ", error);
      });
    return data;
  }
);

const deleteDeprecatedStations = createAsyncThunk(
  "configuration/deleteDeprecatedStations",
  async (selectedIds) => {
    const data = await axios
      .post("/api/py/deprecationDeal", {
        collectionName: "stations",
        action: "delete",
        docIds: selectedIds,
      })
      .then((res) => {
        return res.data;
      })
      .catch((error) => {
        throw error;
      });
    return data;
  }
);

const restoreDeprecatedStations = createAsyncThunk(
  "configuration/restoreDeprecatedStations",
  async (selectedIds) => {
    const data = await axios
      .post("/api/py/deprecationDeal", {
        collectionName: "stations",
        action: "restore",
        docIds: selectedIds,
      })
      .then((res) => {
        return res.data;
      })
      .catch((error) => {
        throw error;
      });

    return data;
  }
);

const stationsMongoSlice = createSlice({
  name: "stationsMongo",
  initialState: {
    list: {},
    available: [],
    unavailable: [],
    loading: false,
  },
  reducers: {
    setLoading: (state, action) => {
      state.loading = action.payload;
    },
    setStationState: (state, action) => {
      const { id, station } = action.payload;
      state.list[id] = station;
    },
    installCu: (state, action) => {
      const { stationId, cuIds } = action.payload;
      cuIds.forEach((cuId) => {
        state.list[stationId].list_cu.push(cuId);
      });
    },
    uninstallCu: (state, action) => {
      const { stationId, cuId } = action.payload;
      state.list[stationId].list_cu = state.list[stationId].list_cu.filter(
        (id) => id !== cuId
      );
    },
    installSensor: (state, action) => {
      const { stationId, sensorIds } = action.payload;
      sensorIds.forEach((id) => {
        state.list[stationId].list_sensors.push(id);
      });
    },
    uninstallSensor: (state, action) => {
      const { stationId, sensorId } = action.payload;
      state.list[stationId].list_sensors = state.list[
        stationId
      ].list_sensors.filter((id) => id !== sensorId);
    },
    setListSensor: (state, action) => {
      const { stationId, listSensors } = action.payload;
      state.list[stationId].list_sensors = listSensors;
    },
    setListCu: (state, action) => {
      const { stationId, listCu } = action.payload;
      state.list[stationId].list_cu = listCu;
    },
    activateStation: (state, action) => {
      const stationId = action.payload;
      const index = state.unavailable.indexOf(stationId);
      state.list[stationId].available = 1;
      state.unavailable = state.unavailable.toSpliced(index, 1);
      state.available.push(stationId);
    },
    deactivateStation: (state, action) => {
      const stationId = action.payload;
      const index = state.available.indexOf(stationId);
      state.list[stationId].available = 0;
      state.available = state.available.toSpliced(index, 1);
      state.unavailable.push(stationId);
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getStationInfo.fulfilled, (state, action) => {
      const { list, available, unavailable } = action.payload;
      state.list = list || {};
      state.available = available || [];
      state.unavailable = unavailable || [];
    });
    builder.addCase(createStation.fulfilled, (state, action) => {
      const { stationId, station, setStore } = action.payload;
      if (!setStore) return;
      state.list[stationId] = station;
      if (station.available === 1) state.available.push(stationId);
      else state.unavailable.push(stationId);
    });
    builder.addCase(deleteStation.fulfilled, (state, action) => {
      const stationId = action.payload;
      state.unavailable = state.unavailable.filter((id) => id !== stationId);
      state.available = state.available.filter((id) => id !== stationId);
      delete state.list[stationId];
    });
  },
});

export const stationsMongoSliceActions = {
  ...stationsMongoSlice.actions,
  getStationInfo,
  createStation,
  updateStation,
  deleteStation,
  getHistory,
  updateHistory,
  downloadHistory,
  getThreshold,
  createShareStation,
  updateShareStation,
  lastDetectionStation,
  getLastPosition,
  findStationRoute,
  getStationEcoPath,
  getPolarPlots,
  calculateR2Sensors,
  getDeprecatedStations,
  deleteDeprecatedStations,
  restoreDeprecatedStations
};

export default stationsMongoSlice.reducer;
