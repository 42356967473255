import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import colorPalettes from "src/theme/themes.json";
import axios from "axios";

const getTenants = createAsyncThunk(
  "tenants/getTenants",
  async (payload) => {
    const {tenantId} = payload;
    const data = await axios
      .get(`/api/py/readTenant`, {params: {
        tenantId: tenantId,
      }})
      .then((res) => {
        return res.data;
      })
      .catch((error) => {
        console.log("Error: ", error);
        throw error;
      });
    return data;
  }
);

const createTenant = createAsyncThunk(
  "tenants/createTenant",
  async (payload) => {
    const {name, email, headquarter, limitUsers, sharingTenants, proPic, enabledCategoryAlerts, enabledViews, enabledActions} = payload;
    const data = await axios
      .post(`/api/py/createTenant`, {
        tenantName: name,
        tenantSede: headquarter,
        emailAddress: email,
        sharingTenants: sharingTenants,
        proPic: proPic,
        limitUsers, // numero di utenti max che ppuò creare
        enabledCategoryAlerts,
        enabledViews: enabledViews, 
        enabledActions: enabledActions,
      })
      .then((res) => {
        return res.data;
      })
      .catch((error) => {
        console.log("Error: ", error);
        throw error;
      });
    return data;
  }
);

const updateTenant = createAsyncThunk(
  "tenants/updateTenant",
  async (payload) => {
    const {tenantId, name, email, headquarter, limitUsers, sharingTenants, proPic, enabledCategoryAlerts, enabledViews, enabledActions} = payload;
    const data = await axios
      .post(`/api/py/updateTenant`, {
        dictUpdate: {
          [tenantId]: {
            name,
            emailAddress: email,
            sede: headquarter,
            sharingTenants: sharingTenants,
            proPic,
            limitUsers,
            enabledCategoryAlerts,
            enabledViews, 
            enabledActions
          }
        }
      })
      .then((res) => {
        return res.data;
      })
      .catch((error) => {
        console.log("Error: ", error);
        throw error;
      });
    return data;
  }
);

const getTenantsProPic = createAsyncThunk(
  "tenants/getTenantsProPic",
  async (tenantIds) => {
    const data = await axios
      .post(`/api/py/readTenantsProPic`, {
        tenantIds: tenantIds
      })
      .then((res) => {
        return res.data;
      })
      .catch((error) => {
        throw error;
      });
    return data;
  }
);

const deleteTenant = createAsyncThunk(
  "tenants/deleteTenant",
  async (payload) => {
    const {tenantId} = payload;
    const data = await axios
      .post(`/api/py/deleteTenant`, {
        tenantId: tenantId
      })
      .then((res) => {
        console.log("res: ", res);
        return tenantId;
      })
      .catch((error) => {
        console.log("Error: ", error);
        throw error;
      });
    return data;
  }
);

const getRole = createAsyncThunk(
  "tenants/getRole",
  async (tenantIds) => {
    const data = await axios
      .post(`/api/py/readRole`, {
        tenantIds: tenantIds
      })
      .then((res) => {
        return res.data;
      })
      .catch((error) => {
        console.log("Error: ", error);
        throw error;
      });
    return data;
  }
);

const createRole = createAsyncThunk(
  "tenants/createRole",
  async (payload) => {
    const {tenantId, roleName, listUsers, descr, viewIds, enabledActions, categoryIds} = payload;
    const data = await axios
      .post(`/api/py/createRole`, {
        tenantId: tenantId,
        roleName: roleName,
        listUsers: listUsers,
        descr: descr,
        viewIds: viewIds,
        enabledActions: enabledActions,
        enabledGroupActions: null,
        categoryIds: categoryIds
      })
      .then((res) => {
        return res.data;
      })
      .catch((error) => {
        console.log("Error: ", error);
        throw error;
      });
    return data;
  }
);

const updateRole = createAsyncThunk(
  "tenants/updateRole",
  async (payload) => {
    const {roleId, roleName, description, activeViews, categoryAlerts, actions} = payload;
    console.log('roleId roleName descr visibleViews categoryIds actions')
    const data = await axios
      .post(`/api/py/updateRole`, { 
        updateRoleDict: {
          roleId: roleId,
          roleName: roleName,
          descr: description,
          visibleViews: activeViews,
          categoryIds: categoryAlerts,
          actions: actions,
        }
      })
      .then((res) => {
        return res.data;
      })
      .catch((error) => {
        console.log("Error: ", error);
        throw error;
      });
    return data;
  }
);

const updateActionsRole = createAsyncThunk(
  "tenants/updateActionsRole",
  async (payload) => {
    const  {tenantId, roleId, actionsDict} = payload;
    const data = await axios
      .post(`/api/py/updateActionsRole`, { 
        updateRoleDict: actionsDict
      })
      .then((res) => {
        return res.data;
      })
      .catch((error) => {
        console.log("Error: ", error);
        throw error;
      });
    return {tenantId, roleId, data};
  }
);



const deleteRole = createAsyncThunk(
  "tenants/deleteRole",
  async (payload) => {
    const {tenantId, roleId} = payload;
    const data = await axios
      .post(`/api/py/deleteRole`, {
        tenantId: tenantId,
        roleId: roleId
      })
      .then((res) => {
        return {tenantId, roleId};
      })
      .catch((error) => {
        console.log("Error: ", error);
        throw error;
      });
    return data;
  }
);


const updateRoleByGroupId = createAsyncThunk(
  "tenants/updateRoleByGroupId",
  async (payload) => {
    const {roleIds, groupIds, newValue} = payload;
    const data = await axios
      .post(`/api/py/updateRoleByGroupId`, {
        roleIds: roleIds,
        groupIds: groupIds, 
        newValue: newValue
      })
      .then((res) => {
        return res.data;
      })
      .catch((error) => {
        console.log("Error: ", error);
        throw error;
      });
    return data;
  }
);


// ---- ACTIONS API ---- 
const createAction = createAsyncThunk(
  "tenants/createAction",
  async (payload) => {
    const {nameAction, listViews, labelAction, groupId} = payload;
    const data = await axios
      .post(`/api/py/createAction`, {
        nameAction: nameAction,
        listViews: listViews,
        labelAction: labelAction,
        groupId: groupId
      })
      .then((res) => {
        return res.data;
      })
      .catch((error) => {
        console.log("Error: ", error);
        throw error;
      });
    return data;
  }
);


const deleteAction = createAsyncThunk(
  "tenants/deleteAction",
  async (payload) => {
    const {actionId} = payload;
    const data = await axios
      .post(`/api/py/deleteAction`, {
        actionId: actionId
      })
      .then((res) => {
        return res.data;
      })
      .catch((error) => {
        console.log("Error: ", error);
        throw error;
      });
    return data;
  }
);

const readActionsByGroupId = createAsyncThunk(
  "tenants/readActionsByGroupId",
  async (payload) => {
    const {groupId} = payload;
    const data = await axios
      .post(`/api/py/readActionsByGroupId`, {
        groupId: groupId
      })
      .then((res) => {
        return res.data;
      })
      .catch((error) => {
        console.log("Error: ", error);
        throw error;
      });
    return data;
  }
);

const readActionsByViewId = createAsyncThunk(
  "tenants/readActionsByViewId",
  async (payload) => {
    const {viewId} = payload;
    const data = await axios
      .post(`/api/py/readActionsByViewId`, {
        viewId: viewId
      })
      .then((res) => {
        return res.data;
      })
      .catch((error) => {
        console.log("Error: ", error);
        throw error;
      });
    return data;
  }
);

const getTenantStations = createAsyncThunk(
  "tenants/getTenantStations",
  async (tenantIds) => {
    const data = await axios.post("/api/py/readStationInfo", {
        tenantIds: tenantIds
      }
    ).then((res) => {
      // console.log('getStationInfo: ', res);
      return res.data;
    }).catch((error) => {
      console.log("Error: ", error);
      throw error;
    });
    return data;
  }
);

const getTenantCus = createAsyncThunk(
  "tenants/getTenantCus",
  async (tenantIds) => {
    const data = await axios
      .post(`/api/py/readCuInfo`, {
        tenantIds: tenantIds
      })
      .then((res) => {
        return res.data;
      })
      .catch((error) => {
        console.log("Error: ", error);
        throw error;
      });
    return data;
  }
);

const getTenantSensors = createAsyncThunk(
  "tenants/getTenantSensors",
  async (tenantIds) => {
    const data = await axios.post("/api/py/readSensorInfo", {
        tenantIds: tenantIds
    })
      .then((res) => {
          return res.data;
      }).catch((error) => {
          console.log("Error: ", error);
          throw error;
      });
    return data;
  }
);

const getDeprecatedTenants = createAsyncThunk(
  "configuration/getDeprecatedTenants",
  async () => {
    const data = await axios
      .post(`/api/py/deprecationDeal`, {
        collectionName: "tenants",
        action: "read",
      })
      .then((res) => {
        return res.data;
      })
      .catch((error) => {
        console.log("Error: ", error);
      });
    return data;
  }
);

const deleteDeprecatedTenants = createAsyncThunk(
  "configuration/deleteDeprecatedTenants",
  async (selectedIds) => {
    const data = await axios
      .post("/api/py/deprecationDeal", {
        collectionName: "tenants",
        action: "delete",
        docIds: selectedIds,
      })
      .then((res) => {
        return res.data;
      })
      .catch((error) => {
        throw error;
      });
    return data;
  }
);

const restoreDeprecatedTenants = createAsyncThunk(
  "configuration/restoreDeprecatedTenants",
  async (selectedIds) => {
    const data = await axios
      .post("/api/py/deprecationDeal", {
        collectionName: "tenants",
        action: "restore",
        docIds: selectedIds,
      })
      .then((res) => {
        return res.data;
      })
      .catch((error) => {
        throw error;
      });

    return data;
  }
);

const tenantsSlice = createSlice({
  name: "tenants",
  initialState: {
    list: {},
    loading: false,
    themes: colorPalettes
  },
  reducers: {
    setLoading: (state, action) => {
      state.loading = action.payload;
    }
  },
  extraReducers: (builder)=> {
    builder.addCase(getTenants.fulfilled, (state, action) => {
      Object.keys(action.payload).forEach((tenantId) => {
        state.list = {
          ...state.list,
          [tenantId]: {
            ...state.list[tenantId],
            ...action.payload[tenantId]
          }
        }
      })
    });
    builder.addCase(createTenant.fulfilled, (state, action) => {
      const tenant = action.payload;
      const list = {
        ...state.list,
        [tenant._id]: {
          ...tenant
        }
      }
      delete list[tenant._id]._id;
      state.list = list;
    });
    builder.addCase(updateTenant.fulfilled, (state, action) => {
      let tenant = action.payload;
      const tenantId = tenant._id;
      delete tenant._id;
      state.list[tenantId] = tenant;
    });
    builder.addCase(deleteTenant.fulfilled, (state, action) => {
      const id = action.payload;
      delete state.list[id];
    });
    builder.addCase(getRole.fulfilled, (state, action) => {
      const roles = action.payload;
      Object.keys(roles).forEach((tenantId) => {
        state.list = {
          ...state.list,
          [tenantId]: {
            ...state.list[tenantId],
            roles: roles[tenantId]
          }
        } 
      })
    });
    builder.addCase(createRole.fulfilled, (state, action) => {
      const role = action.payload;
      state.list[role.tenantId].roles.push(role);
    });
    builder.addCase(updateRole.fulfilled, (state, action) => {
      const role = action.payload;
      const roleIndex = state.list[role.tenantId].roles.findIndex((element) => element._id === role._id);
      state.list[role.tenantId].roles[roleIndex] = role;
    });
    builder.addCase(updateActionsRole.fulfilled, (state, action) => {
      const {tenantId, roleId, data} = action.payload;
      const roleIndex = state.list[tenantId].roles.findIndex((element) => element._id === roleId);
      state.list[tenantId].roles[roleIndex].actions = data;
    });
    builder.addCase(deleteRole.fulfilled, (state, action) => {
      const {tenantId, roleId} = action.payload;
      state.list[tenantId].roles = state.list[tenantId].roles.filter((role) => role._id !== roleId);
    });
  }
});

export const tenantsSliceActions = {
  ...tenantsSlice.actions,
  getTenants,
  createTenant,
  updateTenant,
  deleteTenant,
  getTenantsProPic,
  getRole,
  createRole,
  updateRole,
  updateActionsRole,
  deleteRole,
  getTenantStations,
  getTenantCus,
  getTenantSensors,
  getDeprecatedTenants,
  deleteDeprecatedTenants,
  restoreDeprecatedTenants
};

export default tenantsSlice.reducer;