import axios from "axios";
import store from "../..";
import { mapsSliceActions } from "./mapsSlice";
import { Buffer } from 'buffer';

export const findAlertsPositions = async (userId, alertIds = null) => {
  if (!userId) throw new Error("Missing userId");
  if (alertIds && !Array.isArray(alertIds)) throw new Error("alertIds must be an array");
  const data = await store.dispatch(mapsSliceActions.findAlertsPositions({ userId, alertIds })).then(({ payload, error }) => {
    if (error) throw error;
    return payload;
  });
  return data;
};

export const getPlanetekCapabilities = async () => {
  const data = await store.dispatch(mapsSliceActions.getPlanetekCapabilities()).then(({ payload, error }) => {
    if (error) throw error;
    payload.layers.forEach((layer, index) => {
      const {dimension, years, months} = groupTimestampsByYearAndMonth(layer.dimension); 
      payload.layers[index].dimension = dimension;
      payload.layers[index].years = years;
      payload.layers[index].months = months;
    });
    return payload;
  });
  return data;
};
export const getPlanetekLegend = async (layer, width=20, height=20) => {
  const data = await store.dispatch(mapsSliceActions.getPlanetekLegend({layer, width, height})).then(({ payload, error }) => {
    if (error) throw error;
    return payload;
  });
  return data;
};
export const getPlanetekImageWms = async (bbox, timestamp, layers) => {
  const data = await store.dispatch(mapsSliceActions.getPlanetekImageWms({ bbox, timestamp, layers })).then(({ payload, error }) => {
    if (error) throw error;
    return payload;
  });
  return data;
  // const data = await axios.post("/api/py/readImageWms", {
  //   bbox,
  //   width: 1280,
  //   height: 720,
  //   timestamp,
  //   layers,
  // }
  // ).then((res) => {
  //   console.log(res);
  //   // const dio = Buffer.from(res.data, "utf-8");
  //   return res.data;
  // }).catch((error) => {
  //   console.log("Error: ", error);
  //   throw error;
  // });
  // return data;
  //  const response = await fetch(wmsUrl, {
  //         method: "POST",
  //         headers: {
  //           "Content-Type": "application/json",
  //         },
  //         body: JSON.stringify(wmsParamsObj),
  //       });

  //       if (!response.ok) {
  //         const errorText = await response.text();
  //         throw new Error(
  //           `HTTP error! status: ${response.status}, message: ${errorText}`
  //         );
  //       }

  //       const blob = await response.blob();
  //       const tempUrl = URL.createObjectURL(blob);
};

const groupTimestampsByYearAndMonth = (timestamps=[]) => {
  if(!timestamps) throw new Error("Timestamps can not be null.");
  const years = [];
  let months = {};
  let dimension = {};
  timestamps.forEach((timestamp) => {
    const date = new Date(timestamp * 1000);
    const year = date.getFullYear();
    const month = date.getMonth();
    if(!years.includes(year)) {
      years.push(year);
      months[year] = [];
      dimension[year] = {};
    }
    if(!months[year].includes(month)) {
      months[year].push(month);
      dimension[year][month] = [];
    }
    dimension[year][month].push(timestamp);
  });
  return {dimension, years, months};
}

// export const getPlanetkLayers = async() => {
//   return new Promise((resolve, reject) => {
//     setTimeout(() => {
//       resolve([
//         {
//           id: "layer_1",
//           label: "Layer 1"
//         },
//         {
//           id: "layer_2",
//           label: "Layer 2"
//         }
//       ])
//     }, 1500)
//   });
//   // {
//   //   bbox: bboxReordered,
//   //   width: resolution[0],
//   //   height: resolution[1],
//   //   time:
//   // }
// }
