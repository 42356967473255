import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import * as XLSX from 'xlsx';

const createExcelDownloadLink = (data, fileName) => {
  const worksheet = XLSX.utils.json_to_sheet(data);
  const workbook = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(workbook, worksheet, 'evocs_data');
  const excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'buffer' });
  const dataURI = URL.createObjectURL(new Blob([excelBuffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' }));
  const link = document.createElement('a');
  link.href = dataURI;
  link.setAttribute('download', `${fileName}.xlsx`);
  document.body.appendChild(link);
  link.click();
};

const createJsonDownloadLink = (data, fileName) => {
  const jsonData = JSON.stringify(data);
  const blob = new Blob([jsonData], { type: 'application/json' });
  const url = window.URL.createObjectURL(blob);
  const link = document.createElement('a');
  link.href = url;
  link.download = `${fileName}.json`;
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};


const getOdourFile = createAsyncThunk(
  "reports/getOdourFile",
  async (payload) => {
    const { startDate, endDate, typi, level, fileType, fileName } = payload;
    const data = await axios
      .post(`/api/py/createOdourFile`, {
        startDate: Math.floor(startDate / 1000),
        endDate: Math.floor(endDate / 1000),
        typi: typi,
        level: level,
        form: fileType === 'json' ? 'excel' : fileType,
      }
      )
      .then((res) => {
        if (res.status === 200) {
          if (fileType === 'csv') {
            return res.data;
          }
          else if (fileType === 'excel') {
            // console.log('daje:', res);
            createExcelDownloadLink(res.data.data, fileName);
          }
          else if (fileType === 'json') {
            createJsonDownloadLink(res.data, fileName);
          }
        }
      })
      .catch((error) => {
        console.log("Error: ", error);
      });
    return data;
  }
);


/**
 * Generates an open data file based on the provided parameters.
 * @param {Object} params
 * @param {number} params.startDate - The start date of the data range (in milliseconds)
 * @param {number} params.endDate - The end date of the data range (in milliseconds)
 * @param {string[]} params.sources - An array of ObjectId of data sources (e.g. "EEA", "mistral", "prot_civ", "Arpa-Puglia", "rmn" in ObjectId format)
 * @param {number[]} params.stationIds - An array of station IDs
 * @param {string[]} params.fields - An array of ObjectId of measures
 * @param {string} params.typeStation - The type of station (e.g. "AQ" or "METEO")
 * @param {string} params.fileType - The file type of the generated file (e.g. "csv", "excel", or "json")
 * @param {string} params.fileName - The name of the generated file
 * @returns {Promise<Object>} A promise that resolves with the response data from the `/api/py/createOpenFile` endpoint.
 */
const getOpenDataFiles = createAsyncThunk(
  "reports/getOpenDataFiles",
  async (payload) => {
    const { 
      startDate,
      endDate,
      sources,
      stationIds,
      fields,
      typeStation,
      fileType,
      fileName
    } = payload;
    const data = await axios
      .post(`/api/py/createOpenFile`, {
        start_date: Math.floor(startDate / 1000),
        end_date: Math.floor(endDate / 1000),
        sources: sources,
        stationIds: stationIds,
        fields: fields,
        typeStation: typeStation,
        form: fileType === 'json' ? 'excel' : fileType,
      }, {
        headers: {
          'content-type': 'text/json'
        }
      }
      )
      .then((res) => {
        if (res.status === 200) {
          if (fileType === 'csv') {
            return res.data;
          }
          else if (fileType === 'excel') {
            console.log('daje:', res);
            createExcelDownloadLink(res.data.data, fileName);
          }
          else if (fileType === 'json') {
            createJsonDownloadLink(res.data.data, fileName);
          }
        }
      })
      .catch((error) => {
        console.log("Error: ", error);
      });
    return data;
  }
);

const getAllReports = createAsyncThunk(
  "reports/getAllReports",
  async (payload) => {
    const { startDate, endDate, type, level } = payload;
    const data = await axios
      .get(`/api/py/readOdours`, {
        params: {
          start_date: startDate,
          end_date: endDate,
          typi: type,
          level: level,
        }
      }
      )
      .then((res) => {
        return res.data;
      })
      .catch((error) => {
        console.log("Error: ", error);
        throw error;
      });
    return data;
  }
);

const addOdourReport = createAsyncThunk(
  "reports/addOdourReport",
  async (payload) => {
    const { odourDate, baseLocationId, newLat, newLon, odourLevelId, odourTypeId, userId } = payload;
    const data = await axios
      .post(`/api/py/insertOdour`, {
        odourDate: odourDate,
        baseLocationId: baseLocationId,
        newLat: newLat,
        newLon: newLon,
        odourLevel: odourLevelId,
        odourType: odourTypeId,
        userId: userId
      })
      .then((res) => {
        return res.data;
      })
      .catch((error) => {
        console.log("Error: ", error);
        throw error;
      });
    return data;
  }
);

const deleteOdourReport = createAsyncThunk(
  "reports/deleteOdourReport",
  async (payload) => {
    const { odourId } = payload;
    const data = await axios
      .post(`/api/py/deleteOdours`, {
        odourId: odourId
      })
      .then((res) => {
        return odourId;
      })
      .catch((error) => {
        console.log("Error: ", error);
        throw error;
      });
    return data;
  }
);

const getReportsPercentages = createAsyncThunk(
  "reports/getReportsPercentages",
  async (payload) => {
    const { startDate, endDate, kind, type, level } = payload;
    const data = await axios
      .post(`/api/py/odourPieData`, {
        start_date: startDate,
        end_date: endDate,
        kind: kind,
        typi: type,
        level: level,
      })
      .then((res) => {
        return res.data;
      })
      .catch((error) => {
        console.log("Error: ", error);
      });
    return data;
  }
);

const getUnitFileData = createAsyncThunk(
  "reports/getUnitFile",
  async (payload) => {
    const { stationId, startDate, endDate, fileType, tenantId, fileName, cuId, sensorIds, measureIds } = payload;    
    const data = await axios
      .post(`/api/py/createFile`, {
        startDate: Math.floor(startDate / 1000),
        endDate: Math.floor(endDate / 1000),
        stationId: stationId || null,
        cuId: cuId || null,
        sensorIds: sensorIds || null,
        form: fileType === 'json' ? 'excel' : fileType,
        measureIds: measureIds || null,
        tenantId: tenantId,
        mean: false
      }
      )
      .then((res) => {
        if (res.status === 200) {
          if (fileType === 'csv') {
            return res.data;
          }
          else if (fileType === 'excel') {
            createExcelDownloadLink(res.data.data, fileName);
          }
          else if (fileType === 'json') {
            createJsonDownloadLink(res.data, fileName);
          }
        }
      })
      .catch((error) => {
        throw error;
      });
    return data;
  }
);

//! Nuova chiamata
  const getReportPdfInfo = createAsyncThunk(
    "reports/getReportPdfInfo",
    async (payload)=>{
      const {startDate, endDate, stationId, cuId, sensorIds} = payload
      const data = await axios.post("/api/py/createReportPdf", {
        startDate: Math.floor(startDate / 1000),
        endDate: Math.floor(endDate / 1000),
        stationId: stationId,
        cuId : cuId,
        sensorIds : sensorIds
      })
      .then(res=>{
        if(res.status === 200) return res.data
      })
      .catch(error=> {
        console.log("error: ", error);
        throw error
      }) 
      return data
    }
  )


 const getStationReportInfo = createAsyncThunk(
   "reports/getSensorReportInfo",
   async (payload) => {
     const { 
       startDate,
       endDate,
       stationId,
     } = payload;
     const data = await axios
       .post(`/api/py/createReportStation`, {
         startDate: Math.floor(startDate / 1000),
         endDate: Math.floor(endDate / 1000),
         stationId: stationId,
       }
       )
       .then((res) => {
         if (res.status === 200) {
           return res.data;
         }
       })
       .catch((error) => {
         console.log("Error: ", error);
         throw error;
       });
     return data;
   }
 );

 const getCuReportInfo = createAsyncThunk(
   "reports/getCuReportInfo",
   async (payload) => {
     const { 
       startDate,
       endDate,
       cuId,
     } = payload;
     const data = await axios
       .post(`/api/py/createReportCu`, {
         startDate: Math.floor(startDate / 1000),
         endDate: Math.floor(endDate / 1000),
         cuId: cuId,
       }
       )
       .then((res) => {
         if (res.status === 200) {
           return res.data;
         }
       })
       .catch((error) => {
         console.log("Error: ", error);
         throw error;
       });
     return data;
   }
 );


 const getSensorReportInfo = createAsyncThunk(
   "reports/getSensorReportInfo",
   async (payload) => {
     const { 
       startDate,
       endDate,
       sensorIds,
     } = payload;
     const data = await axios
       .post(`/api/py/createReport`, {
         startDate: Math.floor(startDate / 1000),
         endDate: Math.floor(endDate / 1000),
         sensorIds: sensorIds,
       }
       )
       .then((res) => {
         if (res.status === 200) {
           return res.data;
         }
       })
       .catch((error) => {
         console.log("Error: ", error);
       });
     return data;
   }
 );


const reportsSlice = createSlice({
  name: "reports",
  initialState: {
    list: null,
  },
  reducers: {},
});

export const reportsSliceActions = {
  ...reportsSlice.actions,
  getAllReports,
  addOdourReport,
  deleteOdourReport,
  getReportsPercentages,
  getUnitFileData,
  getOdourFile, 
  getOpenDataFiles, 
  getStationReportInfo,
  getCuReportInfo,
  getSensorReportInfo,
  getReportPdfInfo,
}

export default reportsSlice.reducer;