import { viewNames } from "app/configs/routesNames";
import { createLanguageObject } from "src/utilities/utils";

const IT = {
    HEADER : "Gestione rete interna",
    ADD_DEVICE: "Nuovo dispositivo",
    SEARCH: "Cerca...",
    DEVICE_NAME: "Nome Dispositivo",
    PORT: "Porta",
    STATUS: "Stato",
    ADD_DEVICE_TITLE: "Aggiungi un nuovo dispositivo",
    NAME_IN_USE: "Nome già in uso",
    ERROR_CHARS: "Il nome non può contenere caratteri speciali",
    PORT_IN_USE: "Porta già in uso",
    ERROR_PORT: "La porta non può superare i 5 caratteri",
    EDIT_INFO: "Modifica le informazioni del dispositivo",
    QUICK_ACTIONS: "Azioni Rapide",
    DELETE_DEVICE: "Elimina Dispositivo",
    REBOOT: "Riavvia",
    NEW_DEVICE_SUCCESSFULLY_ADDED_TO_VPN: "Nuovo dispositivo aggiunto con successo alla VPN",
    NEW_DEVICE_SUCCESSFULLY_DELETED_FROM_VPN: "Dispositivo eliminato con successo dalla VPN",
    FORGET : "Dimentica",
    AVAILABLE_NETWORKS: "Reti disponibili",
    DEVICES_DETECTED : "Dispositivi rilevati",
    PRIORITY_NETWORK : "Rete prioritaria",
    SAVED_NETWORKS: "Reti salvate",
	CONNECTED_TO: "Connesso a",
    NOT_CONNECTED: "Non connesso",
    DEVICE_UNREACHABLE: "Dispositivo momentaneamente non raggiungibile",
    EXCELLENT: "Eccellente",
    OPTIMAL: "Ottimo",
    GOOD: "Buono",
    WEAK: "Scarso",
    SCAN_WIFI : "Ricerca reti wifi",
    SCAN_BLT : "Scansione bluetooth",
    SCAN_ETHERNET : "Testa connessione ethernet",
    INTERNAL_NETWORK_ERROR: "Internal network temporarily unreachable, please try again later",
    NO_ETHERNET_CONNECTION : "Nessuna connessione ethernet",
    PAIRING: "Visibile",
    NOT_PAIRING: "Non visibile",
};

const INTERNAL_NETWORK_MANAGEMENT_IT = createLanguageObject(IT, viewNames.INTERNAL_NETWORK_MAN_VIEW);

export default INTERNAL_NETWORK_MANAGEMENT_IT;