import { useEffect, useMemo, useState } from "react";
import React from "react";
import LineGraph from "./LineGraph";
import { CircularProgress, Paper } from "@mui/material";
import SensorSelect from "../SharingComponents/Inputs/SensorSelect";
import { useSelector } from "react-redux";
import WindRose from "./WindRose";
import {
  createDatasetLineGraph,
  createTooltipLineGraph,
  getWindRoseData,
} from "app/store/evocsSlice/dataset/datasetActions";
import LoadingGif from "../SharingComponents/LoadingGif";
import { TIME_FORMAT, formatDate } from "./utils";
import MeasureSelect from "../SharingComponents/Inputs/MeasureSelect";
import LiveText from "../SharingComponents/LiveText";
import NoDataMessage from "../SharingComponents/NoDataMessage";
import DownloadComponent from "../SharingComponents/DownloadComponent/DownloadComponent";
import {
  getCorrelationMatrix,
  getSensorsMeasures,
  hasSensorWindMeasures,
  isMeasureWind,
} from "app/store/evocsSlice/sensors/sensorsActions";
import AddIcon from "@mui/icons-material/Add";
import CompareArrowsIcon from "@mui/icons-material/CompareArrows";
import ToggleButton from "../SharingComponents/Inputs/ToggleButton";
import { viewNames } from "app/configs/routesNames";
import { useTranslation } from "react-i18next";
import { translate } from "src/utilities/utils";
import { getR2BySensors } from "app/store/evocsSlice/stationsMongo/stationsMongoActions";
import { setGlobalError } from "app/store/evocsSlice/globalError/errorActions";
import { getCuMeasures } from "app/store/evocsSlice/controlUnits/cuActions";
import MultipleGroupSelect from "../SharingComponents/MultipleGroupSelect";
import TableComponent from "../SharingComponents/TableComponent";
import useMobile from "src/app/hooks/useMobile";
import { height, width } from "@mui/system";
import useActions from "src/app/hooks/useActions";

const windRoseMeasure = "windrose";

const MeasureSelection = ({
  measuresOptions,
  selectedMeasure,
  compare,
  compareOptions,
  selectedCompare,
  disabledCompare,
  onChangeMeasure,
  onChangeCompare,
  onChangeCompareMeasure,
  disabled,
  selectedGroupId,
  sensorsOptions,
  selectedSensor,
  disableSensorFilter = false,
  onChangeSensor,
  isControlUnit,
}) => {
  const { t } = useTranslation("evocs");
  const handleTranslate = (textId, general = false) => {
    if (general) return translate(t, textId);
    return translate(t, textId, viewNames.ADMINISTRATION_VIEW);
  };
  const controlUnits = useSelector((state) => state.controlUnits.list);
  const sensors = useSelector((state) => state.sensors.list);
  const dataset = useSelector((state) => state.dataset?.list)
  const hasComparison = (Object.keys(dataset)?.some((key) => key !== selectedMeasure) ?? 0);


  const groups = useMemo(() => {
    if (!controlUnits || !sensors) return [];
    const parentIds = [
      ...new Set(
        compareOptions.map((measureOption) =>
          measureOption.sensor.deviceParent.type === "cu"
            ? measureOption.sensor.deviceParent.device
            : measureOption.sensor.sensorId
        )
      ),
    ];
    let indexValue = 1;
    return parentIds.map((parentId) => {
      const groupOptions = [];
      compareOptions.forEach((measuresOption) => {
        if (
          measuresOption.sensor.deviceParent.device === parentId ||
          measuresOption.sensor.sensorId === parentId
        ) {
          groupOptions.push({
            groupId: parentId,
            index: indexValue,
            ...measuresOption,
          });
          indexValue++;
        }
      });
      return {
        id: parentId,
        title: controlUnits[parentId]?.serial || sensors[parentId]?.serial,
        options: groupOptions,
      };
    });
  }, [controlUnits, sensors, compareOptions]);



  const handleChangeCompareMeasure = (value) => {
    if (!value || !Array.isArray(value))
      throw new Error("Error in comparing measurements");
    const selectedIndexInGroups = value.map((v) => Number(v));
    const formattedValues = selectedIndexInGroups
      .map((index) => {
        for (const group of groups) {
          const foundElement = group.options.find(
            (element) => element.index === index
          );
          if (foundElement) {
            return foundElement;
          }
        }
        return null;
      })
      .filter(Boolean);
    onChangeCompareMeasure && onChangeCompareMeasure(formattedValues);
  };

  const handleChangeSensor = (sensorId) => {
    onChangeSensor?.(sensorId);
    onChangeMeasure?.(sensors[sensorId].measures[0]);
  };

  const handleChangeMeasure = (measureId) => {
    if (isControlUnit) {
      const sensorId = sensorsOptions.find((id) =>
        measureId === windRoseMeasure
          ? hasSensorWindMeasures(id)
          : sensors[id].measures.includes(measureId)
      );
      if (sensorId !== selectedSensor) {
        onChangeSensor?.(sensorId);
      }
    }
    onChangeMeasure?.(measureId);    
  };

  const hasOneMeasure =
    compareOptions &&
    compareOptions?.length === 1 &&
    (compareOptions?.[0]?.sensor?.measures?.length ?? 0) >= 1;



  return (
    <div className="flex flex-col md:flex-row gap-10 mt-[3rem]">
      {!disableSensorFilter && isControlUnit && isControlUnit === true && (
        <SensorSelect
          sensorsId={sensorsOptions}
          value={selectedSensor}
          onChange={handleChangeSensor}
          disabled={sensorsOptions.length <= 0}
        />
      )}
      <div className="flex flex-row items-center">
        <MeasureSelect
          label={handleTranslate("PARAM", true)}
          disabled={disabled}
          measuresId={measuresOptions}
          value={selectedMeasure}
          onChange={handleChangeMeasure}
          style={{ width: "300px" }}
        />
        {!disabledCompare && !hasOneMeasure && hasComparison && (
          <ToggleButton enabled={compare} onChange={onChangeCompare}>
            {!compare ? <AddIcon /> : <CompareArrowsIcon />}
          </ToggleButton>
        )}
      </div>
      {!disabledCompare && (
        <>
          {compare && (
            <MultipleGroupSelect
              groups={groups}
              handleChangeCompareMeasure={handleChangeCompareMeasure}
              maxSelectable={4}
              disabledMeasureIds={selectedMeasure ? [selectedMeasure] : []}
              selectedGroupId={selectedGroupId}
              selectedCompare={selectedCompare}
              style={{ width: "300px" }}
            />
          )}
        </>
      )}
    </div>
  );
};

const GraphChip = ({
  cu,
  cuId,
  sensor,
  sensorId,
  startDate,
  endDate,
  selectedSens,
  selectedMeas,
  compareOptions,
  isCompare,
  selectedCompare,
  graphHeight = "400px",
  onChangeSensor,
  onChangeMeasure,
  onChangeCompare,
  onChangeSelectedCompare,
  withDownloads = false,
  disableSensorFilter = false,
  isLive = false,
  userActions,
}) => {
  
  if (!(cu && cuId) && !(sensor && sensorId)) return null;
  const isControlUnit = sensor && sensorId ? false : true;
  const { t } = useTranslation("evocs");
  const handleTranslate = (textId, general = false) => {
    if (general) return translate(t, textId);
    return translate(t, textId, viewNames.STATIONS_VIEW);
  };
  const [xs, sm, md] = useMobile();
  const [isWindRose, setWindRose] = useState(false);
  const windRoseLoading = useSelector((state) => state.dataset.windRoseLoading);
  const datasets = useSelector((state) => state.dataset.list);
  const measures = useSelector(
    (state) => state.configuration.constants?.measure
  );
  const controlUnits = useSelector((state) => state.controlUnits.list);
  const sensors = useSelector((state) => state.sensors.list);

  //Variabili utuli solo se cu
  const allCuGraphMeasures = isControlUnit
    ? useMemo(() => getCuMeasures(cuId, true), [cuId])
    : null;

  const selectedStation = isControlUnit
    ? useSelector(
        (state) => state.controlUnits.list?.[cuId]?.deviceParent?.device
      )
    : null;

  const sensorsOptions = isControlUnit
    ? useMemo(() => {
        return cu.list_sensors.filter(
          (id) =>
            sensors[id]?.available === 1 &&
            allCuGraphMeasures.find((measureId) =>
              sensors[id].measures.includes(measureId)
            )
        );
      }, [allCuGraphMeasures])
    : null;


  let selectedSensValue;
  if (isControlUnit) {
    if (selectedSens !== undefined && selectedSens !== null) {
      selectedSensValue = selectedSens;
    }
    else if (selectedMeas !== undefined && selectedMeas !== null) {
      const sensorSelected = sensorsOptions.filter((id) => {
        return sensors[id].measures.includes(selectedMeas)
      });
      if (sensorSelected.length > 0) selectedSensValue = sensorSelected[0];
    } else {
      selectedSensValue = sensorsOptions[0];
    }
  }

  const [selectedSensor, setSelectedSensor] = useState(selectedSensValue);

  const measuresOptions = isControlUnit
    ? useMemo(() => {
        if (!selectedSensor) return [];
        if (!disableSensorFilter) {
          if (
            userActions.find((e) => e.name === "show-windrose") &&
            hasSensorWindMeasures(selectedSensor)
          )
            return [
              windRoseMeasure,
              ...getSensorsMeasures(selectedSensor, true),
            ];
          else return getSensorsMeasures(selectedSensor, true);
        } else {
          const allMeasures = getSensorsMeasures(sensorsOptions, true);
          if (
            userActions.find((e) => e.name === "show-windrose") &&
            allMeasures.some((measureId) => isMeasureWind(measureId))
          ) {
            return [windRoseMeasure, ...allMeasures];
          } else return allMeasures;
        }
      }, [selectedSensor, sensorsOptions, disableSensorFilter])
    : useMemo(() => {
        const allMeasures = getSensorsMeasures(sensorId, true);
        if (
          userActions.find((e) => e.name === "show-windrose") &&
          allMeasures.some((measureId) => isMeasureWind(measureId))
        ) {
          return [windRoseMeasure, ...allMeasures];
        } else return allMeasures;
      }, [userActions]);

  const [selectedMeasure, setSelectedMeasure] = isControlUnit
    ? useState(() => {
        if (selectedMeas) return selectedMeas;
        if (hasSensorWindMeasures(selectedSensor)) return windRoseMeasure;
        return measuresOptions.find((measureId) =>
          sensors[selectedSensor].measures.includes(measureId)
        );
      })
    : useState(measuresOptions[0]);

  const [compare, setCompare] = useState(isCompare);

  const [compareMeasuresOptions, setCompareOptions] = useState(
    compareOptions || []
  );
  const [selectedCompareMeasure, setSelectedCompare] = useState(
    selectedCompare || ""
  );

  const [checkedMenu, setCheckedMenu] = useState([]);
  const [lastCheckedMenu, setLastCheckedMenu] = useState([]);
  const [loadingR2, setLoadingR2] = useState(false);
  const [loadingMatrix, setLoadingMatrix] = useState(false);
  const [r2Value, setR2Value] = useState();
  const [matrixImage, setMatrixImage] = useState();
  const [graphInfo, setGraphInfo] = useState();

  const isGraphMenuDisabled = useMemo(() => {
    if (!userActions) return true;
    return !userActions.find((element) => element.name === "show-graph-menu");
  }, [userActions]);

  const measuresUnit = useMemo(() => {
    const units = [];
    if (selectedMeasure) {
      if (selectedMeasure !== windRoseMeasure) {
        units.push(measures?.[selectedMeasure]?.unit);
        if (selectedCompareMeasure && selectedCompareMeasure.length > 0) {
          const comparedMeasures = selectedCompareMeasure.map(
            (e) => e.measureId
          );
          comparedMeasures.forEach((measureId) => {
            units.push(measures?.[measureId].unit);
          });
        }
      }
    }
    return units;
  }, [selectedMeasure, selectedCompareMeasure]);

  const getDeviceParentSerialOrId = (
    selectedMeasure,
    isCompareMeasure = false,
    parentSerial = true
  ) => {
    const measureSource = isCompareMeasure
      ? selectedMeasure?.sensor
      : isControlUnit
      ? sensors?.[selectedSensor]
      : sensors?.[sensorId];
    let deviceParent = measureSource?.deviceParent;
    if (parentSerial === false) return deviceParent?.device;
    if (deviceParent?.type === "cu")
      return controlUnits?.[deviceParent?.device]?.serial;
    return measureSource?.serial;
  };

  //FUNZIONALITA' GRAFICO
  const graph = useMemo(() => {
    const graphPalette = [
      "#242a30", // Antracite scuro (colore base)
      "#74c365", // Vedre primario Evocs
      "#EF3934", // Rosso
      "#2E86EB", // Blu pastello
      "#f4a261", // Arancione tenue (caldo e accogliente)
      "#2a9d8f", // Verde acqua (fresco e moderno)
      "#e9c46a", // Giallo ocra (caldo e amichevole)
    ];

    if (selectedMeasure === windRoseMeasure) {
      if (datasets[selectedSensor]?.[selectedMeasure]) {
        return {
          data: datasets[selectedSensor][selectedMeasure]["data"].map(
            (e, index) => e[index]
          ),
        };
      }
      return { data: [] };
    }

    const graphDatasets = [],
      tooltips = [],
      avg = [],
      minMax = [];
    let labels = [];
    let rangeMinByMeasure = {};
    let rangeMaxByMeasure = {};
    let minMaxRange = [];

    const updateRangeMinMax = (measureId, newMin, newMax) => {
      if (!measureId || newMin === undefined || newMax === undefined) return;

      const oldMin = rangeMinByMeasure[measureId];
      if (oldMin === undefined || newMin < oldMin) {
        rangeMinByMeasure[measureId] = newMin;
      }

      const oldMax = rangeMaxByMeasure[measureId];
      if (oldMax === undefined || newMax > oldMax) {
        rangeMaxByMeasure[measureId] = newMax;
      }
    };

    if (selectedMeasure) {
      const yAxisId = "y0";
      const fill =
        isCompare && selectedCompareMeasure?.length > 0 ? false : true;
      const data = [];
      const measureDataset = isControlUnit
        ? datasets[selectedSensor]?.[selectedMeasure]
        : datasets[sensorId]?.[selectedMeasure];

      measureDataset?.data.forEach((dataset) => {
        labels.push(dataset[0]);
        data.push({
          x: formatDate(
            new Date(dataset[0] * 1000),
            TIME_FORMAT.DATE_AND_HOUR_SECONDS
          ),
          y: dataset[1],
        });
      });

      const graphColor =
        isCompare && selectedCompareMeasure?.length > 0
          ? graphPalette[0]
          : measures[selectedMeasure].color;

      const measureLabel =
        "MEASURE_" +
          (measures?.[selectedMeasure]?.measure?.toUpperCase() ?? "") || "";

      const graphLabel = `${handleTranslate(
        measureLabel,
        true
      )} (${getDeviceParentSerialOrId(selectedMeasure)})`;

      graphDatasets.push(
        createDatasetLineGraph(
          graphLabel,
          data,
          graphColor,
          graphColor,
          fill,
          yAxisId
        )
      );

      tooltips.push(
        createTooltipLineGraph(
          handleTranslate(
            `MEASURE_${measures[selectedMeasure].measure.toUpperCase()}`,
            true
          ).toUpperCase(),
          `${handleTranslate("DATE", true)}: `,
          "",
          `${handleTranslate("VALUE", true)}: `,
          measures[selectedMeasure].unit
        )
      );

      avg.push(measureDataset?.avg);
      minMax.push({ min: measureDataset?.min, max: measureDataset?.max });

      updateRangeMinMax(
        selectedMeasure,
        measureDataset?.rangeMin,
        measureDataset?.rangeMax
      );

      minMaxRange.push(selectedMeasure);

      if (isCompare && selectedCompareMeasure?.length > 0) {
        selectedCompareMeasure.forEach((compareMeasure, index) => {
          const yAxisId = `y${index + 1}`;
          const data = [];
          const compareMeasureDataset =
            datasets[compareMeasure.sensor.sensorId]?.[
              compareMeasure.measureId
            ];

          compareMeasureDataset?.data.forEach((dataset) => {
            labels.push(dataset[0]);
            data.push({
              x: formatDate(
                new Date(dataset[0] * 1000),
                TIME_FORMAT.DATE_AND_HOUR_SECONDS
              ),
              y: dataset[1],
            });
          });

          const graphColor = graphPalette[index + (1 % graphPalette.length)];
          const measureLabel =
            "MEASURE_" +
              (measures?.[compareMeasure.measureId]?.measure?.toUpperCase() ??
                "") || "";
          const graphLabel = `${handleTranslate(
            measureLabel,
            true
          )} (${getDeviceParentSerialOrId(compareMeasure, true)})`;

          graphDatasets.push(
            createDatasetLineGraph(
              graphLabel,
              data,
              graphColor,
              graphColor,
              false,
              yAxisId
            )
          );

          tooltips.push(
            createTooltipLineGraph(
              handleTranslate(
                `MEASURE_${measures[
                  compareMeasure.measureId
                ].measure.toUpperCase()}`,
                true
              ).toUpperCase(),
              `${handleTranslate("DATE", true)}: `,
              "",
              `${handleTranslate("VALUE", true)}: `,
              measures[compareMeasure.measureId].unit
            )
          );

          avg.push(compareMeasureDataset?.avg);

          minMax.push({
            min: compareMeasureDataset?.min,
            max: compareMeasureDataset?.max,
          });

          updateRangeMinMax(
            compareMeasure.measureId,
            compareMeasureDataset?.rangeMin,
            compareMeasureDataset?.rangeMax
          );
          minMaxRange.push(compareMeasure.measureId);
        });
      }
    }
    minMaxRange = minMaxRange.map((measureId) => {
      return {
        min: rangeMinByMeasure[measureId],
        max: rangeMaxByMeasure[measureId],
      };
    });

    labels = [...new Set(labels)]
      .sort((a, b) => a - b)
      .map((timestamp) =>
        formatDate(
          new Date(timestamp * 1000),
          TIME_FORMAT.DATE_AND_HOUR_SECONDS
        )
      );

    return {
      labels,
      datasets: graphDatasets,
      tooltips,
      avg,
      minMax,
      minMaxRange,
    };
  }, [datasets, selectedMeasure, isCompare, selectedCompareMeasure, t]);

  const isCompareDisabled = useMemo(() => {
    if (!useActions) return true;
    const showCompareIcon = () => {
      if (!graph || isWindRose == null) return false; // Condizione semplificata

      return !isWindRose && graph?.datasets?.[0]?.data?.length > 0; // Combinazione delle condizioni
    };
    const existsCompareAction = userActions.some(
      (element) => element.name === "show-graph-compare"
    );
    const isComparable = showCompareIcon();

    return !(existsCompareAction && isComparable);
  }, [isWindRose, userActions, graph]);

  useEffect(() => {
    if (!graph.datasets) {
      setGraphInfo([]);
      return;
    }

    let infoObj = [];

    if (selectedMeasure) {
      const dataset = isControlUnit
        ? datasets[selectedSensor]?.[selectedMeasure]
        : datasets?.[sensorId]?.[selectedMeasure];
      if (!dataset) return;
      const data = dataset.data;
      const unitString = measuresUnit[0] ? ` ${measuresUnit[0]}` : "";
      const lastValueString = data[data.length - 1]
        ? data[data.length - 1][1]?.toString() +
          unitString +
          ` - ${formatDate(
            new Date(data[data.length - 1][0] * 1000),
            TIME_FORMAT.DATE_AND_HOUR_SECONDS
          )}`
        : null;

      infoObj.push({
        serial: getDeviceParentSerialOrId(),
        last: lastValueString,
        max: dataset.max.toString() + unitString,
        min: dataset.min.toString() + unitString,
        avg: dataset.avg.toString() + unitString,
        sd: dataset.sd.toString() + unitString,
        var: dataset.var.toString() + unitString,
        median: dataset.median.toString() + unitString,
      });
    }
    if (
      compare &&
      selectedCompareMeasure &&
      selectedCompareMeasure.length > 0
    ) {
      selectedCompareMeasure.forEach((compareMeasure, index) => {
        const sensorID = compareMeasure.sensor.sensorId;
        const dataset = datasets[sensorID]?.[compareMeasure.measureId];
        if (!dataset) return;
        const data = dataset.data;
        const unitString = measuresUnit[1] ? ` ${measuresUnit[1]}` : "";
        const lastValueString = data[data.length - 1]
          ? data[data.length - 1][1].toString() +
            unitString +
            ` - ${formatDate(
              new Date(data[data.length - 1][0] * 1000),
              TIME_FORMAT.DATE_AND_HOUR
            )}`
          : null;

        infoObj.push({
          serial: getDeviceParentSerialOrId(compareMeasure, true),
          last: lastValueString,
          max: dataset.max.toString() + unitString,
          min: dataset.min.toString() + unitString,
          avg: dataset.avg.toString() + unitString,
          sd: dataset.sd.toString() + unitString,
          var: dataset.var.toString() + unitString,
          median: dataset.median.toString() + unitString,
        });
      });
    }
    setGraphInfo(infoObj);
  }, [graph]);

  useEffect(() => {    
    selectedMeas && setSelectedMeasure(selectedMeas);
  }, [selectedMeas]);

  useEffect(() => {
    compareOptions && setCompareOptions(compareOptions);
  }, [compareOptions]);

  useEffect(() => {
    setSelectedCompare(selectedCompare);
  }, [selectedCompare]);

  useEffect(() => {
    setCompare(isCompare);
  }, [isCompare]);

  if (isControlUnit) {
    useEffect(() => {
      selectedSens && setSelectedSensor(selectedSens);
    }, [selectedSens]);

    useEffect(() => {
      if (selectedMeasure === windRoseMeasure) {
        setWindRose(true);
        setLoadingR2(false);
        setLoadingMatrix(false);
        setR2Value(null);
        setMatrixImage(null);
      } else setWindRose(false);
    }, [selectedMeasure]);

    useEffect(() => {
      if (selectedMeasure === windRoseMeasure) {
        if (datasets[selectedSensor]?.[selectedMeasure]) return;
        getWindRoseData(startDate, endDate, [selectedSensor]);
      }
    }, [datasets, selectedMeasure]);
  }

  useEffect(() => {
    setLastCheckedMenu(checkedMenu);
    let canSetR2 = true,
      canSetMatrix = true;
    const newChecked = checkedMenu.filter(
      (option1) =>
        !lastCheckedMenu.find(
          (option2) =>
            option1.groupId === option2.groupId && option1.id === option2.id
        )
    );
    const unckecked = lastCheckedMenu.filter(
      (option1) =>
        !checkedMenu.find(
          (option2) =>
            option1.groupId === option2.groupId && option1.id === option2.id
        )
    );

    // get R2
    if (
      compare &&
      selectedCompareMeasure &&
      selectedCompareMeasure.length > 0
    ) {
      if (newChecked.find((option) => option.id === "r2")) {
        setLoadingR2(true);
        let dictSensors = {};
        if (isControlUnit) {
          dictSensors =
            selectedCompareMeasure[0].sensor.sensorId === selectedSensor
              ? {
                  [selectedSensor]: [
                    selectedMeasure,
                    selectedCompareMeasure[0].measureId,
                  ],
                }
              : {
                  [selectedSensor]: [selectedMeasure],
                  [selectedCompareMeasure[0].sensor.sensorId]: [
                    selectedCompareMeasure[0].measureId,
                  ],
                };
        } else {
          dictSensors =
            selectedCompareMeasure[0].sensor.sensorId === sensorId
              ? {
                  [sensorId]: [
                    selectedMeasure,
                    selectedCompareMeasure[0].measureId,
                  ],
                }
              : {
                  [sensorId]: [selectedMeasure],
                  [selectedCompareMeasure[0].sensor.sensorId]: [
                    selectedCompareMeasure[0].measureId,
                  ],
                };
        }

        getR2BySensors(dictSensors, startDate, endDate)
          .then((res) => {
            if (!canSetR2) return;
            setLoadingR2(false);
            setR2Value(res?.r2);
          })
          .catch(() => {
            setGlobalError(true);
          });
      } else if (unckecked.find((option) => option.id === "r2")) {
        setLoadingR2(false);
        setR2Value(null);
      }
    }

    // get Matrix
    if (newChecked.find((option) => option.id === "matrix")) {
      setLoadingMatrix(true);
      getCorrelationMatrix(selectedStation, startDate, endDate).then((res) => {
        if (!canSetMatrix) return;
        setMatrixImage(res);
        setLoadingMatrix(false);
      });
    } else if (unckecked.find((option) => option.id === "matrix")) {
      setMatrixImage(null);
      setLoadingMatrix(false);
    }

    return () => {
      canSetR2 = false;
      canSetMatrix = false;
    };
  }, [checkedMenu]);

  useEffect(() => {
    let canSetR2 = true,
      canSetMatrix = true;
    if (checkedMenu.find((option) => option.id === "r2")) {
      if (
        !compare ||
        !selectedCompareMeasure ||
        selectedCompareMeasure.length === 0
      ) {
        setLoadingR2(false);
        setR2Value(null);
      } else {
        setLoadingR2(true);
        let dictSensors = {};
        if (isControlUnit) {
          dictSensors =
            selectedCompareMeasure[0].sensor.sensorId === selectedSensor
              ? {
                  [selectedSensor]: [
                    selectedMeasure,
                    selectedCompareMeasure[0].measureId,
                  ],
                }
              : {
                  [selectedSensor]: [selectedMeasure],
                  [selectedCompareMeasure[0].sensor.sensorId]: [
                    selectedCompareMeasure[0].measureId,
                  ],
                };
        } else {
          dictSensors =
            selectedCompareMeasure.sensor.sensorId === sensorId
              ? {
                  [sensorId]: [
                    selectedMeasure,
                    selectedCompareMeasure[0].measureId,
                  ],
                }
              : {
                  [sensorId]: [selectedMeasure],
                  [selectedCompareMeasure[0].sensor.sensorId]: [
                    selectedCompareMeasure[0].measureId,
                  ],
                };
          getR2BySensors(dictSensors, startDate, endDate).then((res) => {
            if (!canSetR2) return;
            setLoadingR2(false);
            setR2Value(res?.r2);
          });
        }
        getR2BySensors(dictSensors, startDate, endDate)
          .then((res) => {
            if (!canSetR2) return;
            setLoadingR2(false);
            setR2Value(res?.r2);
          })
          .catch(() => {
            setGlobalError(true);
          });
      }
    }
    if (checkedMenu.find((option) => option.id === "matrix")) {
      if (
        compare &&
        selectedCompareMeasure &&
        selectedCompareMeasure.length > 0
      ) {
        setLoadingMatrix(false);
        setMatrixImage(null);
      } else {
        setLoadingMatrix(true);
        getCorrelationMatrix(selectedSensor, startDate, endDate).then((res) => {
          if (!canSetMatrix) return;
          setMatrixImage(res);
          setLoadingMatrix(false);
        });
      }
    }

    return () => {
      canSetR2 = false;
      canSetMatrix = false;
    };
  }, [selectedMeasure, compare, selectedCompareMeasure]);

  const handleChangeSensor = (sensorId) => {
    setSelectedSensor(sensorId);
    onChangeSensor && onChangeSensor(sensorId);
  };

  const handleChangeMeasure = (measureId) => {
    setSelectedMeasure(measureId);
    onChangeMeasure && onChangeMeasure(measureId);
    setSelectedCompare([]);
  };

  const handleChangeCompare = (compare) => {
    setCompare(compare);
    if (!compare || compare === false) setSelectedCompare([]);
    onChangeCompare && onChangeCompare(compare);
  };

  const handleChangeSelectedCompare = (compare) => {
    setSelectedCompare(compare);
    onChangeSelectedCompare && onChangeSelectedCompare(compare);
  };

  const handleChangeMenu = (checked, option, value) => {
    setCheckedMenu(checked);
  };

  const statsTableColumns = useMemo(() => {
    return {
      serial: handleTranslate("DEVICE", true),
      last: handleTranslate("LAST_RELEVATION", true),
      max: handleTranslate("MAX", true),
      min: handleTranslate("MIN", true),
      avg: handleTranslate("AVERAGE", true),
      sd: handleTranslate("SD", true),
      var: handleTranslate("VAR", true),
      median: handleTranslate("MEDIAN", true),
    };
  }, [t]);

  const renderTable = () => {
    if (
      !graphInfo ||
      selectedMeasure === "windrose" ||
      datasets[selectedMeasure]?.data?.length === 0
    )
      return null;
    return graphInfo.length > 0 ? (
      <TableComponent
        paper={false}
        style={{ height: "auto", padding: 0, marginBottom: 0, marginTop: 0 }}
        rowsForPage={
          graphInfo.length > 10
            ? xs
              ? 4
              : 10
            : graphInfo.length > 4
            ? xs
              ? 4
              : graphInfo.length
            : graphInfo.length
        }
        columns={statsTableColumns}
        data={graphInfo}
        disabledSearch
        sortable
        userActions={userActions}
        // downloadFileProps={downloadReport} // Fixare se necessario
      />
    ) : (
      <NoDataMessage fullWidth />
    );
  };

  const renderNoDataMessage = (message, style) => {
    if (graphInfo && graphInfo.length === 0)
      return <NoDataMessage message={message} style={style} fullWidth />;
    return null;
  };

  const graphInfoComponent = () => {
    if (!graphInfo || !selectedMeasure || selectedMeasure === "windrose")
      return <></>;

    // Raggruppiamo i dati per chiave escludendo "serial"
    const groupedData = {};

    graphInfo.forEach(({ serial, ...rest }) => {
      Object.entries(rest).forEach(([key, value]) => {
        if (!groupedData[key]) {
          groupedData[key] = [];
        }
        groupedData[key].push(`${value} (${serial})`);
      });
    });

    return (
      <div
        className={`grid ${
          xs ? "grid-cols-1" : "grid-cols-2 gap-20"
        } text-left w-full mb-[15px] gap-y-10`}
      >
        {(xs || sm || md) &&
          Object.entries(groupedData).map(([key, values]) => (
            <div key={key}>
              <span className="font-medium my-[5px] block">
                {handleTranslate(
                  key === "last" ? "LAST_RELEVATION" : key.toUpperCase(),
                  true
                )}
                :
              </span>
              {values.map((val, idx) => (
                <div key={idx}>
                  <p>{`- ${val}`}</p>
                </div>
              ))}
            </div>
          ))}
        {graphInfo.length === 2 && (
          <div className="text-left">
            <span className="font-medium my-[5px] block"> R2: </span>
            {(r2Value === undefined || r2Value === null) && !loadingR2 && (
              <p>{`- ${handleTranslate("NOT_AVAILABLE", true)}`}</p>
            )}
            {!loadingR2 ? (
              typeof r2Value === "number" && <p>{`- ${r2Value}`}</p>
            ) : (
              <label style={{ whiteSpace: "nowrap" }}>
                <CircularProgress size={"15px"} />
              </label>
            )}
          </div>
        )}
      </div>
    );
  };

  const r2InfoComponent = () => {
    if (!graphInfo || !selectedMeasure || selectedMeasure === "windrose")
      return <></>;

    return (
      <>
        {graphInfo.length === 2 && (
          <div className="text-left">
            {!loadingR2 ? (
              !(r2Value === undefined || r2Value === null) && (
                <span className="font-medium my-[5px] block">
                  R2: {typeof r2Value === "number" && `${r2Value}`}
                </span>
              )
            ) : (
              <label style={{ whiteSpace: "nowrap" }}>
                <CircularProgress size={"15px"} />
              </label>
            )}
          </div>
        )}
      </>
    );
  };

  const statsComponent = () => {
    if (isWindRose) {
      if (graph?.data?.length > 0 ?? 0)
        return renderNoDataMessage(
          handleTranslate("STATS_NOT_AVAILABLE", true)
        );
      else return <></>;
    } else if (graph?.datasets?.[0]?.data?.length === 0 ?? 0)
      return renderNoDataMessage(handleTranslate("STATS_NOT_AVAILABLE", true));
    return (
      <>
        <div className="w-full flex-1 justify-start items-center my-20">
          <span className="font-semibold text-xl">
            {handleTranslate("STATISTICS", true)}
          </span>
        </div>
        {!(xs || sm || md) ? renderTable() : graphInfoComponent()}
      </>
    );
  };

  // console.log('graph.datasets: ', graph.datasets);

  return (
    <>
      <div className="flex flex-col h-full border-none p-10">
        <div className={`basis-1/2`}>
          <div
            style={{
              display: "flex",
              gap: "0.5rem",
              flexWrap: "wrap",
              marginBottom: "1rem",
            }}
          >
            <div
              style={{
                display: "flex",
                gap: "1rem",
                alignItems: "center",
                height: "fit-content",
              }}
            >
              <h3>
                {isControlUnit
                  ? `${handleTranslate("CONTROL_UNIT", true)} : ${cu.serial}`
                  : `${handleTranslate("SENSOR", true)} : ${sensor?.serial}`}
              </h3>
              {/* {withDownloads && (
                <DownloadComponent
                  userActions={userActions}
                  startDate={startDate}
                  endDate={endDate}
                  cuId={cuId}
                  unitSerial={cu?.serial}
                />
              )} */}
            </div>
            <div
              style={{
                display: "flex",
                flex: 1,
                gap: "2rem",
                alignItems: "baseline",
                justifyContent: "end",
              }}
            >
              {!isGraphMenuDisabled && !(xs || sm || md) && r2InfoComponent()}
              {isLive && <LiveText />}
            </div>
          </div>
          <div style={{ display: "flex", height: graphHeight }}>
            {isWindRose ? (
              !windRoseLoading ? (
                graph.data?.length > 0 ? (
                  <WindRose data={graph.data} />
                ) : (
                  <NoDataMessage fullWidth />
                )
              ) : (
                <LoadingGif />
              )
            ) : graph.datasets?.[0]?.data?.length > 0 ? (
              <LineGraph
                disableMenu={isGraphMenuDisabled}
                graphShadow={false}
                datasets={graph.datasets}
                labels={graph.labels}
                tooltips={graph.tooltips}
                measuresUnit={measuresUnit}
                loadingMatrix={loadingMatrix}
                matrixImage={matrixImage}
                avg={graph.avg}
                minMax={graph.minMax}
                minMaxRange={graph.minMaxRange}
                checkedMenu={checkedMenu}
                onChangeMenu={handleChangeMenu}
              />
            ) : (
              <NoDataMessage fullWidth />
            )}
          </div>
          {isControlUnit ? (
            <MeasureSelection
              disabledCompare={isCompareDisabled}
              disableSensorFilter={disableSensorFilter}
              sensorsOptions={sensorsOptions}
              measuresOptions={measuresOptions}
              compareOptions={compareMeasuresOptions}
              compare={compare}
              selectedCompare={selectedCompareMeasure}
              selectedSensor={selectedSensor}
              selectedMeasure={selectedMeasure}
              onChangeSensor={handleChangeSensor}
              onChangeMeasure={handleChangeMeasure}
              onChangeCompare={handleChangeCompare}
              onChangeCompareMeasure={handleChangeSelectedCompare}
              selectedGroupId={
                selectedMeasure
                  ? getDeviceParentSerialOrId(selectedMeasure, false, false)
                  : undefined
              }
              isControlUnit={isControlUnit}
            />
          ) : (
            <MeasureSelection
              disableCompare={isCompareDisabled}
              compare={compare}
              measuresOptions={measuresOptions}
              compareOptions={compareMeasuresOptions}
              selectedMeasure={selectedMeasure}
              selectedCompare={selectedCompareMeasure}
              onChangeMeasure={handleChangeMeasure}
              onChangeCompare={handleChangeCompare}
              onChangeCompareMeasure={handleChangeSelectedCompare}
              selectedGroupId={sensorId ?? undefined}
              isControlUnit={isControlUnit}
            />
          )}
        </div>
        {!isGraphMenuDisabled && (
          <div className="basis-1/2">{statsComponent()}</div>
        )}
      </div>
    </>
  );
};

export default GraphChip;
