import { Grid, Paper, Typography } from "@mui/material";
import { viewNames } from "app/configs/routesNames";
import { getReportsPercentages } from "app/store/evocsSlice/reports/reportsActions";
import React, { useEffect, useState } from "react";
import { Doughnut } from "react-chartjs-2";
import { useSelector } from "react-redux";
import LoadingGif from "../SharingComponents/LoadingGif";
import { convertHexToRGBA } from "src/components/StationHandling/utils";
import useMobile from "../../app/hooks/useMobile";
import useFilters from "src/app/hooks/useFilters";
import NoDataMessage from "../SharingComponents/NoDataMessage";
import { useTranslation } from 'react-i18next';
import { translate } from "src/utilities/utils";

const viewName = viewNames.ODOR_REPORTS_VIEW;

const TypeGraph = ({
  data = [],
  labels = [],
  backgroundColors = [],
  borderColors = [],
  isMobile = false,
  graphHeight = "400px",
}) => {
  return (
    <div style={{ height: graphHeight }}>
      <Doughnut
        data={{
          labels: labels,
          datasets: [
            {
              data: data,
              backgroundColor: backgroundColors,
              borderColor: borderColors,
              borderWidth: 1,
              tooltip: {
                callbacks: {
                  label: function (tooltipItem) {
                    return `${tooltipItem.parsed} %`;
                  },
                },
              },
            },
          ],
        }}
        options={{
          layout: {
            padding: 15
          },
          maintainAspectRatio: false,
          responsive: true,
          hoverOffset: 30,
          plugins: {
            legend: {
              position: isMobile ? "bottom" : "right",
              maxHeight: isMobile && 150,
              maxWidth: isMobile && 115,
              align: isMobile ? "center" : "end",
              labels: {
                font: {
                  size: 11,
                },
              },
            },
          },
        }}
      />
    </div>
  );
};

const LevelGraph = ({
  data = [],
  labels = [],
  backgroundColors = [],
  borderColors = [],
  isMobile,
  graphHeight = "400px",
}) => {
  return (
    <div style={{ height: graphHeight }}>
      <Doughnut
        data={{
          labels: labels,
          datasets: [
            {
              data: data,
              backgroundColor: backgroundColors,
              borderColor: borderColors,
              borderWidth: 1,
              tooltip: {
                callbacks: {
                  label: function (tooltipItem) {
                    return `${tooltipItem.parsed} %`;
                  },
                },
              },
            },
          ],
        }}
        options={{
          layout: {
            padding: 15,
          },
          maintainAspectRatio: false,
          responsive: true,
          hoverOffset: 30,
          plugins: {
            legend: {
              position: isMobile ? "bottom" : "right",
              maxHeight: isMobile && 150,
              maxWidth: isMobile && 115,
              align: isMobile ? "center" : "end",
              labels: {
                font: {
                  size: 11,
                },
              },
            },
          },
        }}
      />
    </div>
  );
};

const NoDataComponent = ({ iconSize = "60px" }) => {
  return (
    <Paper
      sx={{
        display: "flex",
        gap: "1rem",
        padding: "1rem",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <NoDataMessage />
      </div>
    </Paper>
  );
};

const OdorReportsGraphs = ({sliderStart, sliderEnd}) => {
  const { t } = useTranslation("evocs");
  const [xs, sm, md, lg, xl] = useMobile();
  const isMobile = xs || sm;
  const odourType = useSelector((state) => state.configuration.constants?.odour_type);
  const odourLevel = useSelector((state) => state.configuration.constants?.odour_level);
  const filters = useFilters(viewName);
  const odourTypeSelect = filters.odour_type;
  const odourLevelSelect = filters.odour_level;
  const [typeGraph, setTypeGraph] = useState({});
  const [levelGraph, setLevelGraph] = useState({});
  const [loading, setLoading] = useState(false);
  const [noData, setNoData] = useState(false);

  const handleTranslate = (textId, general = false) => {
    if (general) return translate(t, textId);
    return translate(t, textId, viewNames.ODOR_REPORTS_VIEW);
  };

  useEffect(() => {
    setTypeGraph(prevState => {
      const translatedLabels = prevState.labelsId?.map(label => handleTranslate(`ODOUR_TYPE_${label.toUpperCase()}`, true));
      return { ...prevState, labels: translatedLabels };
    });
    setLevelGraph(prevState => {
      const translatedLabels = prevState.labelsId?.map(label => handleTranslate(`ODOUR_LEVEL_${label.toUpperCase()}`, true));
      return { ...prevState, labels: translatedLabels };
    });
  }, [t]);

  useEffect(() => {
    if (!sliderStart || !sliderEnd) return;
    let canSet = true;
    setLoading(true);
    retrieveDataChip().then((res) => {
      if(!canSet) return;
      setTypeGraph(res[0]);
      setLevelGraph(res[1]);
      setLoading(false);
      if(!res[0] || !res[1] || Object.keys(res[0]).length === 0 || Object.keys(res[1]).length === 0) setNoData(true);
      else setNoData(false);
    });

    return () => canSet = false;
  }, [sliderStart, sliderEnd, odourTypeSelect.selected, odourLevelSelect.selected]);

  const retrieveDataChip = async () => {
    const type = odourTypeSelect.selected !== "" ? odourTypeSelect.selected : null;
    const level = odourLevelSelect.selected !== "" ? odourLevelSelect.selected : null;
    const responses = []; 
    const getDonutInfo = [
      getReportsPercentages(sliderStart, sliderEnd, "type", type, level).then((res) => {
        if (!res || Object.keys(res).length === 0) {
          return null;
        }
        const newLabels = res.labels.map((id) => handleTranslate(`ODOUR_TYPE_${odourType[id].odour_type.toUpperCase()}`, true));
        const labelsId = res.labels.map((id) => odourType[id].odour_type);
        const backgroundColors = res.labels.map((id) => convertHexToRGBA(odourType[id].color, 0.7));
        const borderColors = res.labels.map((id) => convertHexToRGBA(odourType[id].color));
        responses.push({
          labels: newLabels,
          data: res.values,
          backgroundColors: backgroundColors,
          borderColors: borderColors,
          labelsId: labelsId,
        });

      }).catch((err) => console.log(err)),

      getReportsPercentages( sliderStart, sliderEnd, "level", type, level).then((res) => {
        if (!res || Object.keys(res).length === 0) {
          return null;
        }
        const newLabels = res.labels.map((id) =>  handleTranslate(`ODOUR_LEVEL_${odourLevel[id].odour_level.toUpperCase()}`, true));
        const labelsId = res.labels.map((id) => odourLevel[id].odour_level);
        const backgroundColors = res.labels.map((id) => convertHexToRGBA(odourLevel[id].color, 0.7));
        const borderColors = res.labels.map((id) => convertHexToRGBA(odourLevel[id].color));
        responses.push({
          labels: newLabels,
          data: res.values,
          backgroundColors: backgroundColors,
          borderColors: borderColors,
          labelsId: labelsId,
        });
      }).catch((err) => console.log(err))
    ];

    await Promise.all(getDonutInfo);
    return responses;
  };

  if (loading) return <LoadingGif />;

  return noData === true ? (
    <NoDataComponent />
  ) : (
    <Paper sx={{ padding: "1rem", paddingLeft: "2rem" }}>
      <Typography variant="h6">{handleTranslate('ODOUR_DISTRIBUTION')}</Typography>
      <Grid container spacing={1} justifyContent={"space-around"}>
        <Grid item sm={12} md={6} xl={6}>
          <div
            style={{
              width: "100%",
              display: "flex",
              flexDirection: "row",
              justifyContent: `${isMobile ? "center" : "start"}`,
            }}
          >
            <Typography variant="subtitle1">{handleTranslate('TYPE', true)}</Typography>
          </div>
          <TypeGraph
            data={typeGraph.data}
            labels={typeGraph.labels}
            backgroundColors={typeGraph.backgroundColors}
            borderColors={typeGraph.borderColors}
            graphHeight={"400px"}
            isMobile={isMobile}
          />
        </Grid>
        <Grid item sm={12} md={6} xl={6}>
          <div
            style={{
              width: "100%",
              display: "flex",
              flexDirection: "row",
              justifyContent: `${isMobile ? "center" : "start"}`,
            }}
          >
            <Typography variant="subtitle1">{handleTranslate('LEVEL', true)}</Typography>
          </div>
          <LevelGraph
            data={levelGraph.data}
            labels={levelGraph.labels}
            backgroundColors={levelGraph.backgroundColors}
            borderColors={levelGraph.borderColors}
            graphHeight={"400px"}
            isMobile={isMobile}
          />
        </Grid>
      </Grid>
    </Paper>
  );
};

export default OdorReportsGraphs;
