import React, { useCallback, useEffect, useMemo } from "react";
import { useSelector } from "react-redux";
import { viewNames } from "app/configs/routesNames";
import {
  setFilter,
  setTimeRangeFilter,
} from "app/store/evocsSlice/filters/filtersActions";
import StationSelect from "../SharingComponents/Inputs/StationSelect";
import MeasureAutocomplete from "../SharingComponents/Inputs/MeasureAutocomplete";
import { IconButton } from "@mui/material";
import { calcIntervalDates } from "../StationHandling/utils";
import RefreshIcon from "@mui/icons-material/Refresh";
import RangeTimeFilter_v2 from "../SharingComponents/RangeTimeFilter";

const viewName = viewNames.STATIONS_VIEW;

const StationHandlingContentHeader = ({
  userActions,
  filtersInView,
  stationsMeasures,
  startDate,
  endDate,
  sliderStart,
  sliderEnd,
  onRefresh,
}) => {
  const availableStations = useSelector(
    (state) => state.stationsMongo.available
  );
  const user = useSelector((state) => state.user);
  const timeRange = useSelector(
    (state) => state.configuration.constants?.timeRange
  );
  const loadingDatasets = useSelector((state) => state.dataset.loading);
  const timeRangeInView = filtersInView?.timeRange;
  const stationSelect = filtersInView?.stationName;
  const measureSelect = filtersInView?.measure;

  const disableFilters = useMemo(() => {
    if (!userActions) return true;
    return !userActions.find((action) => action.name === "show-filters");
  }, [userActions]);

  const { enabledIntervals, defaultInterval } = useMemo(() => {
    const intervals = [];
    let defaultId = null;
    timeRangeInView?.list.forEach((element) => {
      if (element.active === 1) intervals.push(element._id);
      if (element.default === 1) defaultId = element._id;
    });
    defaultId = timeRangeInView.selected
      ? timeRangeInView.selected
      : defaultId
      ? defaultId
      : intervals[0];
    return { enabledIntervals: intervals, defaultInterval: defaultId };
  }, [user.selectedTenant, timeRangeInView?.list]);

  const getStationsOptions = useCallback(() => {
    const measures = measureSelect?.selected || [];
    if (measures.length === 0) return availableStations;
    else
      return availableStations.filter((stationId) =>
        measures.every((measureId) =>
          stationsMeasures[stationId].includes(measureId)
        )
      );
  }, [stationsMeasures, measureSelect?.selected]);

  const getMeasuresOptions = useCallback(() => {
    let selected = [];
    if (!stationSelect.selected || stationSelect.selected.length === 0)
      selected = availableStations;
    else selected = stationSelect.selected;
    return [
      ...new Set(
        selected.map((stationId) => stationsMeasures[stationId]).flat()
      ),
    ];
  }, [stationsMeasures, stationSelect?.selected]);

  useEffect(() => {
    if (!defaultInterval) return;
    const intervalValue = timeRange?.[defaultInterval]?.timeRange;
    const range = calcIntervalDates(intervalValue);
    setTimeRangeFilter(
      viewName,
      timeRangeInView.id,
      defaultInterval,
      range.start,
      range.end
    );
  }, [defaultInterval]);

  useEffect(() => {
    const stationsOptions = getStationsOptions();
    const selectedStations = stationSelect.selected?.filter((stationId) =>
      stationsOptions.includes(stationId)
    );
    setFilter(viewName, stationSelect.id, selectedStations, stationsOptions);
  }, [getStationsOptions]);

  useEffect(() => {
    setFilter(viewName, measureSelect?.id, undefined, getMeasuresOptions());
  }, [getMeasuresOptions]);

  const handleChangeFilter = (filterId, value) => {
    setFilter(viewName, filterId, value);
  };

  const handleChangeInterval = (intervalId, startDate, endDate) => {
    setTimeRangeFilter(
      viewName,
      timeRangeInView.id,
      intervalId,
      startDate,
      endDate
    );
  };

  const handleChangeSlider = (startDate, endDate, value) => {
    setTimeRangeFilter(
      viewName,
      timeRangeInView.id,
      undefined,
      startDate.valueOf(),
      endDate.valueOf()
    );
  };

  return (
    // <div style={{display: "flex", alignItems: "center", flexWrap: "wrap", gap: "1rem", marginBottom: "2rem"}}>
    //   {!disableFilters &&
    //   <>
    //     <div style={{flexGrow: 1}}>
    //       <StationSelect multiple stationsId={stationSelect?.list || []} limitTags={2} value={stationSelect?.selected || []} onChange={(value) => handleChangeFilter(stationSelect.id, value)}/>
    //     </div>
    //     <div style={{flexGrow: 1}}>
    //       <MeasureAutocomplete multiple limitTags={1} measuresId={measureSelect?.list || []} value={measureSelect?.selected || []} onChange={(value) => handleChangeFilter(measureSelect?.id, value)}/>
    //     </div>
    //   </>
    //   }
    //  {!disableFilters && <div style={{flexGrow: 1}}>
    //     <RangeTimeFilter_v2 withDatePicker intervals={enabledIntervals} selectedInterval={timeRangeInView.selected}
    //       startDate={startDate} endDate={endDate} sliderStart={sliderStart} sliderEnd={sliderEnd}
    //       onChangeInterval={handleChangeInterval} onChangeSlider={handleChangeSlider}
    //       />
    //   </div>}
    //   <div style={{display: "flex", alignItems: "end", flexGrow: 1}}>
    //     <IconButton disabled={loadingDatasets} sx={{color: "black", marginLeft: "auto"}} onClick={onRefresh}>
    //       <RefreshIcon fontSize='large'/>
    //     </IconButton>
    //   </div>
    // </div>
    <div className="flex flex-col md:flex-row justify-between items-center md:my-20">
      {!disableFilters && (
      
          <div className="flex flex-col lg:flex-row gap-4 w-full">
            <div className="lg:w-[20%]">
              <StationSelect
                multiple
                stationsId={stationSelect?.list || []}
                limitTags={2}
                value={stationSelect?.selected || []}
                onChange={(value) =>
                  handleChangeFilter(stationSelect.id, value)
                }
              />
            </div>
            <div className="lg:w-[20%]">
              <MeasureAutocomplete
                multiple
                limitTags={1}
                measuresId={measureSelect?.list || []}
                value={measureSelect?.selected || []}
                onChange={(value) =>
                  handleChangeFilter(measureSelect?.id, value)
                }
              />
            </div>
            <div className="lg:w-[20%] mt-5 md:mt-0 ">
              <RangeTimeFilter_v2
                withDatePicker
                intervals={enabledIntervals}
                selectedInterval={timeRangeInView.selected}
                startDate={startDate}
                endDate={endDate}
                sliderStart={sliderStart}
                sliderEnd={sliderEnd}
                onChangeInterval={handleChangeInterval}
                onChangeSlider={handleChangeSlider}
              />
            </div>
          </div>
      )}
      <div style={{ display: "flex", alignItems: "end", flexGrow: 1 }}>
        <IconButton
          disabled={loadingDatasets}
          sx={{ color: "black", marginLeft: "auto" }}
          onClick={onRefresh}
        >
          <RefreshIcon fontSize="large" />
        </IconButton>
      </div>
    </div>
  );
};

export default StationHandlingContentHeader;
