import { viewNames } from "app/configs/routesNames";
import { useCallback, useEffect, useMemo, useState } from "react";
import { Box, Grid } from "@mui/material";
import { getNationFlag, getVessels } from "app/store/evocsSlice/vessels/vesselsActions";
import VesselsTrafficGraph from "./VesselsTrafficGraph";
import VesselsTrafficTable from "./VesselsTrafficTable";
import VesselsTrafficMap from "./VesselsTrafficMap";
import LoadingGif from "../SharingComponents/LoadingGif";
import useActions from "src/app/hooks/useActions";
import { LuFlagOff } from "react-icons/lu";
import {getLastStationPosition} from "app/store/evocsSlice/stationsMongo/stationsMongoActions";
import { useSelector } from "react-redux";
import { getApiTimestamp } from "src/utilities/utils";
import { MapMarkers } from 'src/utilities/utils';
import { FaShip } from "react-icons/fa6";
import { TbCircleLetterE } from "react-icons/tb";
import { LuContainer } from "react-icons/lu";
import { FaDroplet } from "react-icons/fa6";
import { RiSailboatLine } from "react-icons/ri";
import { IoSpeedometer } from "react-icons/io5";
import { RiShip2Fill } from "react-icons/ri";
import { BsFillPeopleFill } from "react-icons/bs";
import { GiPoliceOfficerHead } from "react-icons/gi";
import { FaBriefcaseMedical } from "react-icons/fa";
import { FaLeaf } from "react-icons/fa";
import { FaTruckMedical } from "react-icons/fa6";
import { GoCopilot } from "react-icons/go";
import { FaBan } from "react-icons/fa";
import { TbFishHook } from "react-icons/tb";


const viewName = viewNames.VESSELS_VIEW;


// Creazione di una nuova istanza della classe
const mapMarkers = new MapMarkers();


const getVesselTypeMarker = (colorIndex) => {
  let marker = null;
    //Riservato per uso futuro
    if (colorIndex >= 1 && colorIndex <= 19)
      {
        marker = mapMarkers.getMarkerByName("mkrGrey");
        marker.setIcon(<FaBan />);
      }
    //Wing in ground (WIG)
    else if (colorIndex >= 20 && colorIndex <= 29)
      {
        marker = mapMarkers.getMarkerByName("mkrLightOrange");
        marker.setIcon(<IoSpeedometer />);
      }
    //Pesca
    else if (colorIndex === 30)
      {
        marker = mapMarkers.getMarkerByName("mkrDarkBlue");
        marker.setIcon(<TbFishHook />);
      }
    //Rimorchio
    else if (colorIndex === 31 || colorIndex === 32)
      {
        marker = mapMarkers.getMarkerByName("mkrDarkViolet");
        marker.setIcon(<RiShip2Fill />);
      }
    //Operazioni dragaggio/immersione
    else if (colorIndex >= 33 && colorIndex <= 35)
      {
        marker = mapMarkers.getMarkerByName("mkrBrown");
      }
    //Vela
    else if (colorIndex === 36)
      {
        marker = mapMarkers.getMarkerByName("mkrEvocsThemeGreen");
        marker.setIcon(<RiSailboatLine />);
      }
    //Imbarcazione da diporto
    else if (colorIndex === 37)
      {
        marker = mapMarkers.getMarkerByName("mkrGrey");
      }
    //Riservato
    else if (colorIndex === 38 || colorIndex === 39)
      {
        marker = mapMarkers.getMarkerByName("mkrBlack");
        marker.setIcon(<FaBan />);
      }
    //Imbarcazione ad alta velocità (HSC)
    else if (colorIndex >= 40 && colorIndex <= 49)
      {
        marker = mapMarkers.getMarkerByName("mkrOcra");
      }
    //Nave pilota
    else if (colorIndex === 50)
      {
        marker = mapMarkers.getMarkerByName("mkrDarkBlue2");
        marker.setIcon(<GoCopilot />);
      }
    //Nave di soccorso
    else if (colorIndex === 51)
      {
        marker = mapMarkers.getMarkerByName("mkrRed");
        marker.setIcon(<FaBriefcaseMedical />);
      }
    //Rimorchiatore
    else if (colorIndex === 52 || colorIndex === 53) 
      {
        marker = mapMarkers.getMarkerByName("mkrOrange");
        marker.setIcon(<RiShip2Fill />);
      }
    //Attrezzatura antinquinamento
    else if (colorIndex === 54)
      {
        marker = mapMarkers.getMarkerByName("mkrGreen");
        marker.setIcon(<FaLeaf />);
      }
    //Forze dell\'ordine'
    else if (colorIndex === 55)
      {
        marker = mapMarkers.getMarkerByName("mkrEvocsThemeBlack");
        marker.setIcon(<GiPoliceOfficerHead />);
      }
    //Riserva - Nave locale
    else if (colorIndex === 56 || colorIndex === 57)
      {
        marker = mapMarkers.getMarkerByName("mkrLime");
      }
    //Trasporto medico
    else if (colorIndex === 58)
      {
        marker = mapMarkers.getMarkerByName("mkrRed");
        marker.setIcon(<FaTruckMedical />);
      }
    //Nave non combattente secondo la risoluzione RR n. 18
    else if (colorIndex === 59)
      {
        marker = mapMarkers.getMarkerByName("mkrPink");
      }
    //Passeggeri
    else if (colorIndex >= 60 && colorIndex <= 69)
      {
        marker = mapMarkers.getMarkerByName("mkrViolet");
        marker.setIcon(<BsFillPeopleFill />);
      }
    //Cargo
    else if (colorIndex >= 70 && colorIndex <= 79)
      {
        marker = mapMarkers.getMarkerByName("mkrDarkBlue3");
        marker.setIcon(<LuContainer />);
      }
    //Nave cisterna
    else if (colorIndex >= 80 && colorIndex <= 89)
      {
        marker = mapMarkers.getMarkerByName("mkrGranata");
        marker.setIcon(<FaDroplet/>);
      }
    //Altri tipi
    else if (colorIndex >= 90 && colorIndex <= 99)
      {
        marker = mapMarkers.getMarkerByName("mkrOcra");
      }
    //Ecotaras
    else if (colorIndex === 999)
      {
        marker = mapMarkers.getMarkerByName("mkrBlue");
        marker.setIcon(<TbCircleLetterE/>);
      }
    else
      {
        marker = mapMarkers.getMarkerByName("mkrEvocsThemeGreen"); 
      }
  if (marker.getIcon() == null)
    marker.setIcon(<FaShip/>);
  return marker;
};



const VesselsTrafficContent = () => {
  const userActions = useActions(viewName);
  const [loading, setLoading] = useState(true);
  const [mapCenter, setMapCenter] = useState(null);
  const [vessels, setVessels] = useState();
  const [portTraffic, setPortTraffic] = useState();
  const [selectedVessel, setSelectedVessel] = useState();
  const enabledActions = useMemo(() => {
    return userActions?.map((action) => action?.name);
  }, [userActions]);

  const [lonMin, setLonMin] = useState(17.299606);
  const [lonMax, setLonMax] = useState(17.116030);
  const [latMax, setLatMax] = useState(40.361097);
  const [latMin, setLatMin] = useState(40.516460);
  const [vesselIds, setVesselIds] = useState(null);
  const [vesselTypes, setVesselTypes] = useState(null);
  const [graphBackgroundColor, setGraphBackgroundColor] = useState()
  const [graphBorderColor, setgraphBorderColor] = useState()
  // const [graphMarkerIcon, setGraphMarkerIcon] = useState()


  let vesselTypeId = useSelector((state) => state.configuration.constants.station_type);
  vesselTypeId = Object.entries(vesselTypeId).map(([id, details]) => ({
    id,
    ...details
  }));
  vesselTypeId = vesselTypeId.filter((item) => item?.station_type === 'vessel').map((item) => item.id)[0];


  const getVesselsAndFlag = useCallback(async(lonMin,lonMax,latMax,latMin, vesselIds,vesselTypes) => {
    let data = await getVessels(lonMin, lonMax, latMin, latMax, null, null);
    try {
      let endDate = new Date().valueOf();
      let startDate = (endDate - 15 * 60 * 1000) // 15 minuti = 10 * 60 secondi * 1000 ms
      startDate = getApiTimestamp(startDate)
      endDate = getApiTimestamp(endDate)
      let ecoVessels = await getLastStationPosition(null, startDate, endDate, vesselTypeId)
      const updatedEcoVessels = ecoVessels.map(vessel => ({
        ...vessel,
        type: 999,
        mmsi: ""
      }));
      data = [...data, ...updatedEcoVessels];
    }
    catch (error) {
      console.log(error);
    }
    const allNationsCode = [...new Set(data.map((element) => element.mmsi.toString().slice(0, 3)))];
    const flags = await getNationFlag(allNationsCode);

    return data.map((element) => {
      const flag = flags?.find((e) => String(e?.nationCode) === element?.mmsi.toString().slice(0, 3));
      let newElement = { ...element };
      newElement.flag = (
        flag?.icon ?
        <img
          src={"data:image/png;base64," + flag.icon}
          alt={"flag image"}
          width={20}
          height={20}
        />
        :
        <LuFlagOff size={20} />
      )
      return newElement;
    });
  }, []);

  const fetchVesselData = useCallback(async() => {
    const vesselPromise = [
      getVesselsAndFlag(lonMin,lonMax,latMax,latMin, vesselIds,vesselTypes)
      .then((vessels) => {
        vessels = vessels.map((vessel) =>{
          let tempMarker = getVesselTypeMarker(Number(vessel?.type));
          vessel["icon"] = tempMarker.getMarkerImage();
          vessel["iconType"] = tempMarker.getIcon();
          return vessel;
        });
        setVessels(vessels);
      const shipCounter = vessels.reduce((acc, item) => {
        acc[item.type] = (acc[item.type] || 0) + 1;
        return acc;
      }, {});
      const data = {"data": vessels, "shipCounter": shipCounter};
      let vesselMarkerByType = {};
      Object.keys(data.shipCounter).forEach((vesselTypeId, index) => {
        vesselTypeId = Number(vesselTypeId);
        vesselMarkerByType[vesselTypeId] = getVesselTypeMarker(vesselTypeId);
      });
  
      const graphBack = Object.values(vesselMarkerByType).map((marker) => marker.getBackgroundColor())
      const graphBord = Object.values(vesselMarkerByType).map((marker) => marker.getBorderColor())

      setGraphBackgroundColor(graphBack);
      setgraphBorderColor(graphBord);
      setPortTraffic(data);

      }),
  ]
    await Promise.all(vesselPromise);
  }, []);
 

  useEffect(() => {
    setLoading(true);
    fetchVesselData().then(() => setLoading(false));
  }, [])


  const handleClickRow = (row, rowIndex) => {
    setMapCenter([row.lat, row.lon]);
    setSelectedVessel(vessels.findIndex((e) => e.name === row.name));
  }


  return(
    <Box padding={{ xs: "1rem", sm: "2rem 5rem"}}>
      {!loading ? 
      <Grid container spacing={1}>
        <Grid item xs={12} md={8}>
          <VesselsTrafficMap enabledActions={enabledActions} 
          vessels={vessels} 
          center={mapCenter} 
          selectedMarker={selectedVessel}
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <VesselsTrafficGraph portTraffic={portTraffic} 
          graphBackgroundColor={graphBackgroundColor} 
          graphBorderColor={graphBorderColor}
          />
        </Grid>
         <Grid item xs={12}>
         { vessels && <VesselsTrafficTable vessels={vessels} onClickRow={handleClickRow}/>}
        </Grid>
      </Grid>
      :
      <LoadingGif />
      }
    </Box>
  )
}

export default VesselsTrafficContent;