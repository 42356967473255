import store from "..";
import { configurationSliceActions } from "./configurationSlice";

export const readConstant = async (constantCategories = null) => {
  const data = await store.dispatch(configurationSliceActions.readConstant(constantCategories)).then(({ payload, error }) => {
    if (error) throw error;
    return payload;
  });
  return data;
};
export const createConstant = async (constantType, constants) => {
  const { tenantId, id } = store.getState().user;
  // console.log('constantType, constants', constantType, constants);
  const data = await store
    .dispatch(
      configurationSliceActions.createConstant({
        tenantId,
        userId: id,
        constants,
        constantType,
      })
    )
    .then(({ payload, error }) => {
      if (error) throw error;
      return payload;
    });
  return data;
};
export const updateConstant = async (constants) => {
  const { tenantIds, id } = store.getState().user;
  const data = await store.dispatch(configurationSliceActions.updateConstant({tenantIds,userId: id,constants,})).then(({ payload, error }) => {
    if (error) throw error;
    return payload;
  });
  return data;
};

export const deleteConstant = async (constants) => {
  const { tenantId, id } = store.getState().user;
  const data = await store
    .dispatch(
      configurationSliceActions.deleteConstant({
        tenantId,
        userId: id,
        constants,
      })
    )
    .then(({ payload, error }) => {
      if (error) throw error;
      return payload;
    });

  return data;
};
export const createBaseLocation = async (lat, lon, name) => {
  const data = store
    .dispatch(configurationSliceActions.createBaselocation({ lat, lon, name }))
    .then(({ payload, error }) => {
      if (error) throw error;
      return payload;
    });

  return data;
};
export const readBaseLocations = async (baseLocationId = null) => {
  const data = await store
    .dispatch(configurationSliceActions.readBaselocations(baseLocationId))
    .then(({ payload, error }) => {
      if (error) throw error;
      return payload;
    });
  return data;
};
export const updateBaseLocation = (baseLocationId, lat, lon, name) => {
  store.dispatch(
    configurationSliceActions.updateBaseLocation({
      baseLocationId: baseLocationId,
      lat: lat,
      lon: lon,
      name: name,
    })
  );
};
export const deleteBaseLocation = async (baseLocationId) => {
  const data = await store
    .dispatch(configurationSliceActions.deleteBaselocation(baseLocationId))
    .then(({ payload, error }) => {
      if (error) throw error;
      return payload;
    });

  return data;
};
export const getUnitInfo = (unitId) => {
  const state = store.getState();
  const units = ["stations", "controlUnits", "sensors"];
  const unitInfo = units
    .map((unit) => {
      if (state[unit].stations) {
        if (Object.keys(state[unit].stations).includes(unitId))
          return state[unit].stations[unitId];
      } else {
        if (Object.keys(state[unit].list).includes(unitId))
          return state[unit].list[unitId];
      }
    })
    .filter((e) => e)[0];

  return unitInfo;
};
export const getUnitType = (unitId) => {
  const state = store.getState();
  const units = ["stationsMongo", "controlUnits", "sensors"];
  const unitInfo = units.filter((unit) =>
    Object.keys(state[unit].list).includes(unitId)
  )[0];
  return unitInfo;
};

