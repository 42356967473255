import { Button, CircularProgress, IconButton, TextField } from "@mui/material";
import { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { Box } from "@mui/system";
import CreateTenantModal from "../Modals/CreateTenantModal";
import {
  deleteTenant,
  getTenantsProPic,
  updateTenant,
} from "app/store/evocsSlice/tenants/tenantsActions";
import TenantSelect from "../SharingComponents/Inputs/TenantSelect";
import AvatarImage from "../SharingComponents/Inputs/AvatarImage";
import LoadingGif from "../SharingComponents/LoadingGif";
import { viewNames } from "app/configs/routesNames";
import { useTranslation } from "react-i18next";
import { translate } from "src/utilities/utils";
import {
  setGlobalError,
  setGlobalSuccess,
  setGlobalWarning,
} from "app/store/evocsSlice/globalError/errorActions";
import ViewSelect from "../SharingComponents/Inputs/ViewSelect";
import AccordionComponent from "../SharingComponents/AccordionComponent";
import EditIcon from "@mui/icons-material/Edit";
import GroupActionSelect from "../SharingComponents/Inputs/GroupActionSelect";
import ActionSelect from "../SharingComponents/Inputs/ActionSelect";
import { ModalContext } from "src/views/WithAuth";
import useMobile from "src/app/hooks/useMobile";
import AlertCategorySelect from "../SharingComponents/Inputs/AlertCategorySelect";
import { FaCheck } from "react-icons/fa6";
import { IoCloseOutline } from "react-icons/io5";
import { MdDelete } from "react-icons/md";
import FloatingButtons from "../SharingComponents/FloatingButtons";

const tilebytesTenantId = "6554a3a2af4b91cdd6da3285";
const requiredInputs = ["name", "limitUsers", "enabledViews"];

const PermittedActions = ({
  listViews,
  isEdit,
  activeGroupActions,
  activeActions,
  onChangeGroupActions,
  onChangeActions,
}) => {
  const actions = useSelector((state) => state.configuration.constants.action);
  const views = useSelector((state) => state.configuration.constants.view_name);
  const [actionsList, setActionsList] = useState({});
  const [groupActionsList, setGroupActionsList] = useState({});
  const [xs] = useMobile();
  const getGroupActionsList = useCallback(() => {
    let list = {};
    listViews.forEach((viewId) => {
      list[viewId] = [];
      views[viewId].actions?.forEach((actionId) => {
        const groupId = actions[actionId].groupId;
        if (!list[viewId].includes(groupId)) list[viewId].push(groupId);
      });
    });
    return list;
  }, [listViews, views, actions]);

  const getActionsList = useCallback(() => {
    let list = {};
    listViews.forEach((viewId) => {
      list[viewId] = [...views[viewId].actions];
    });
    return list;
  }, [listViews, views]);

  // useEffect(() => {
  //   console.log("activeGroupActions: ", activeGroupActions)
  // }, [activeGroupActions])

  // useEffect(() => {
  //   console.log("activeActions: ", activeActions)
  // }, [activeActions])

  useEffect(() => {
    setGroupActionsList(getGroupActionsList());
  }, [getGroupActionsList]);

  useEffect(() => {
    setActionsList(getActionsList());
  }, [getActionsList]);

  const handleChangeGroupActions = (viewId, value) => {
    const newSelectedGroupActions = { ...activeGroupActions, [viewId]: value };
    let newSelected = [],
      deselected = [],
      oldSelected = [...activeActions[viewId]];
    groupActionsList[viewId]?.forEach((groupId) => {
      if (value.includes(groupId)) {
        if (!activeGroupActions[viewId]?.includes(groupId))
          newSelected = newSelected.concat(
            actionsList[viewId]?.filter(
              (actionId) => actions[actionId].groupId === groupId
            )
          );
      } else
        deselected = deselected.concat(
          oldSelected.filter(
            (actionId) => actions[actionId].groupId === groupId
          )
        );
    });
    const newSelectedActions = {
      ...activeActions,
      [viewId]: oldSelected
        .filter((actionId) => !deselected.includes(actionId))
        .concat(newSelected),
    };
    // console.log("newSelected: ", newSelected);
    // console.log("deselected: ", deselected);
    // console.log("oldSelected: ", oldSelected);
    onChangeActions?.(newSelectedActions);
    onChangeGroupActions?.(newSelectedGroupActions);
  };

  const handleChangeActions = (viewId, value) => {
    const newSelectedActions = { ...activeActions, [viewId]: value };
    const newSelectedGroupActions = {
      ...activeGroupActions,
      [viewId]: groupActionsList[viewId].filter((groupId) =>
        value.find((actionId) => actions[actionId].groupId === groupId)
      ),
    };
    onChangeActions?.(newSelectedActions);
    onChangeGroupActions?.(newSelectedGroupActions);
  };

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        gap: "1rem",
        padding: "1rem 0",
      }}
    >
      {listViews?.map((viewId) => (
        <div
          key={viewId}
          style={{
            display: !xs && "flex",
            gap: "2rem",
            alignItems: !xs && "center",
            margin: !xs && "1rem 0",
          }}
        >
          <label style={{ width: "100%", maxWidth: xs ? "60px" : "200px" }}>
            {views[viewId].view_name}
          </label>
          <div
            style={{
              display: "flex",
              flexDirection: xs && "column",
              justifyContent: "center",
              width: xs ? "100%" : "50%",
              gap: "1rem",
              marginTop: xs && "2rem",
            }}
          >
            <GroupActionSelect
              multiple
              disabled={!isEdit}
              groupsId={groupActionsList[viewId]}
              value={activeGroupActions[viewId]}
              onChange={(value) => handleChangeGroupActions(viewId, value)}
            />
            <ActionSelect
              multiple
              disabled={!isEdit}
              actionsId={actionsList[viewId]}
              value={activeActions[viewId]}
              onChange={(value) => handleChangeActions(viewId, value)}
            />
          </div>
        </div>
      ))}
    </div>
  );
};

const CustomAccordionTitle = ({
  title,
  visible = false,
  isEdit,
  onClickEdit,
}) => {
  return (
    <div style={{ display: "flex", alignItems: "center", gap: ".5rem" }}>
      <label>{title}</label>
      {visible && !isEdit && (
        <IconButton
          onClick={(e) => {
            e.stopPropagation();
            onClickEdit?.();
          }}
        >
          <EditIcon />
        </IconButton>
      )}
    </div>
  );
};

const SingleTenant = ({
  enabledActions,
  tenantId,
  tenant,
  onDelete,
  disableEdit,
  disableDelete,
}) => {
  const { t } = useTranslation("evocs");
  const modalContext = useContext(ModalContext);
  const user = useSelector((state) => state.user);
  const tenants = useSelector((state) => state.tenants.list);
  const actions = useSelector((state) => state.configuration.constants.action);
  const views = useSelector((state) => state.configuration.constants.view_name);
  const [isEdit, setEdit] = useState(false);
  const [disableSave, setDisableSave] = useState(true);
  const tenantsSelectList = useMemo(
    () => Object.keys(tenants).filter((id) => id !== tenantId),
    []
  );
  const [defaultTenantLogo, setDefaultTenantLogo] = useState("");
  const [loadingLogo, setLoadingLogo] = useState(true);
  const [inputs, setInputs] = useState();
  const [loadingUpdate, setLoadingUpdate] = useState(false);
  const [isInputsChanged, setInputsChanged] = useState(false);
  const [accordionOpen, setAccordionOpen] = useState(true);
  const [xs, sm] = useMobile();
  const viewsWithActions = useMemo(() => {
    return Object.keys(views).filter(
      (viewId) =>
        views[viewId].activable === 0 && views[viewId].actions.length > 0
    );
  }, [views]);

  const defaultTenant = useMemo(() => {
    const enabledViews =
      tenant?.enabledViews.filter((viewId) => views[viewId].activable === 1) ??
      [];
    const permittedActions = tenant?.enabledActions ?? {};
    let groupActions = {};
    Object.keys(permittedActions).forEach((viewId) => {
      groupActions[viewId] = [];
      permittedActions[viewId].forEach((actionId) => {
        const groupId = actions[actionId].groupId;
        if (!groupActions[viewId].includes(groupId))
          groupActions[viewId].push(groupId);
      });
    });
    return {
      name: tenant?.name ?? "",
      email: tenant?.email ?? "",
      headquarter: tenant?.sede ?? "",
      limitUsers: tenant?.limitUsers ?? 0,
      categoryAlerts: tenant?.enabledCategoryAlerts ?? [],
      sharingTenants: tenant?.sharingTenants ?? [],
      image: defaultTenantLogo,
      enabledViews: enabledViews,
      groupActions: groupActions,
      permittedActions: permittedActions,
    };
  }, [tenant, defaultTenantLogo]);
  const [listViews, setListViews] = useState([]);

  const getDefaultTenantLogo = useCallback(async () => {
    return await getTenantsProPic([tenantId]).then((res) => {
      if (res?.proPic !== "") {
        return "data:image/png;base64," + res.proPic;
      } else {
        return "";
      }
    });
  }, [tenantId]);

  useEffect(() => {
    setInputs(defaultTenant);
    setLoadingLogo(true);
    getDefaultTenantLogo().then((res) => {
      setInputs((prev) => ({ ...prev, image: res }));
      setDefaultTenantLogo(res);
      setLoadingLogo(false);
    });
  }, [tenantId]);

  useEffect(() => {
    if (!isInputsChanged) return;
    const disabled = requiredInputs.some(
      (key) => inputs[key] === "" || inputs[key]?.length === 0
    );
    setDisableSave(disabled);
  }, [inputs]);

  useEffect(() => {
    if (!inputs) return;
    setListViews([...viewsWithActions, ...inputs.enabledViews]);
  }, [inputs?.enabledViews]);

  useEffect(() => {
    if (!isEdit) {
      setDisableSave(true);
      setInputsChanged(false);
    }
  }, [isEdit]);

  const handleChange = (key, value) => {
    setInputs((prev) => ({ ...prev, [key]: value }));
    setInputsChanged(true);
  };

  const handleChangeEnabledViews = (value) => {
    setInputsChanged(true);
    const stationViewId = "655f295e86458728760700b5",
      singleStationViewId = "655f295f86458728760700b7";
    let newSelectedView = value.filter(
      (viewId) => !inputs.enabledViews.includes(viewId)
    );
    let deselectedViews = inputs.enabledViews.filter(
      (viewId) => !value.includes(viewId)
    );
    let newActions = { ...inputs.permittedActions };
    let newGroupActions = { ...inputs.groupActions };
    newSelectedView.forEach((viewId) => {
      newActions[viewId] = defaultTenant.permittedActions[viewId] ?? [];
      newGroupActions[viewId] = defaultTenant.groupActions[viewId] ?? [];
      if (viewId === stationViewId) {
        value.push(singleStationViewId);
        newActions[singleStationViewId] = [];
        newGroupActions[singleStationViewId] = [];
      } else if (viewId === singleStationViewId) {
        value.push(stationViewId);
        newActions[stationViewId] = [];
        newGroupActions[stationViewId] = [];
      }
    });
    deselectedViews.forEach((viewId) => {
      value = value.filter((id) => id !== viewId);
      delete newActions[viewId];
      delete newGroupActions[viewId];
      if (viewId === stationViewId || viewId === singleStationViewId) {
        value = value.filter(
          (id) => id !== stationViewId && id !== singleStationViewId
        );
        delete newActions[stationViewId];
        delete newActions[singleStationViewId];
        delete newGroupActions[stationViewId];
        delete newGroupActions[singleStationViewId];
      }
    });

    setInputs((prev) => ({
      ...prev,
      enabledViews: value,
      permittedActions: newActions,
      groupActions: newGroupActions,
    }));
  };

  const handleSave = () => {
    const imageWithoutPrefix =
      inputs?.image && inputs.image?.substring(inputs.image.indexOf(",") + 1);
    setLoadingUpdate(true);
    updateTenant(
      tenantId,
      inputs.name,
      inputs.email,
      inputs.headquarter,
      inputs.limitUsers,
      inputs.sharingTenants,
      imageWithoutPrefix,
      inputs.categoryAlerts,
      listViews,
      inputs.permittedActions
    )
      .then(() => setGlobalSuccess(true))
      .catch(() => setGlobalError(true))
      .finally(() => {
        setEdit(false);
        setLoadingUpdate(false);
      });
  };

  const handleDelete = () => {
    setLoadingUpdate(true);
    deleteTenant(tenantId)
      .then(() => {
        onDelete?.(tenantId);
        setGlobalSuccess(true);
      })
      .catch(() => setGlobalError(true))
      .finally(() => {
        setEdit(false);
        setLoadingUpdate(false);
      });
  };

  const handleClickDelete = () => {
    setGlobalWarning(true, "MODAL_WARNING_DELETE_TENANT");
    modalContext.setConfirmCallback(handleDelete);
  };

  const handleCancelChanges = () => {
    setEdit(false);
    setInputs(defaultTenant);
  };

  const handleTranslate = (textId, general = false) => {
    if (general) return translate(t, textId);
    return translate(t, textId, viewNames.ADMINISTRATION_VIEW);
  };

  return (
    tenant &&
    inputs && (
      <AccordionComponent
        title={
          <CustomAccordionTitle
            title={tenant.name}
            visible={!disableEdit && accordionOpen}
            isEdit={isEdit}
            onClickEdit={() => setEdit(true)}
          />
        }
        expanded={accordionOpen}
        onChange={setAccordionOpen}
        style={{ padding: xs && isEdit ? "0 0 80px 0" : "0" }}
      >
        {!loadingUpdate ? (
          isEdit &&
          accordionOpen && (
            <FloatingButtons
              containerStyle={{ top: !xs ? "20%" : "10%", zIndex: 1 }}
              disableDelete={disableDelete}
              disableSave={disableSave}
              onSave={handleSave}
              onDelete={handleDelete}
              onDiscard={handleCancelChanges}
            />
          )
        ) : (
          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
            }}
          >
            <Button endIcon={<CircularProgress size={15} />}>
              {" "}
              {handleTranslate("SAVING", true)}{" "}
            </Button>
          </div>
        )}
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            gap: "1rem",
            alignItems: xs && "center",
          }}
        >
          <div style={{ width: "fit-content", margin: "1rem 0" }}>
            <AvatarImage
              style={{
                objectFit: "contain",
                background: inputs.image ? "black" : "none",
              }}
              disabled={!isEdit}
              value={inputs.image}
              onChange={(value) => handleChange("image", value)}
            />
            {loadingLogo && <LoadingGif disableLogo />}
          </div>
          <div
            style={{
              display: "flex",
              flexWrap: "wrap",
              gap: "1rem",
              justifyContent: xs && "center",
            }}
          >
            <TextField
              sx={{ width: "100%", maxWidth: "300px" }}
              required
              disabled={!isEdit}
              label={handleTranslate("NAME", true)}
              value={inputs.name}
              onChange={(e) => handleChange("name", e.target.value)}
            />
            <TextField
              sx={{ width: "100%", maxWidth: "300px" }}
              disabled={!isEdit}
              value={inputs.email}
              onChange={(e) => handleChange("email", e.target.value)}
              label="Email"
            />
            <TextField
              sx={{ width: "100%", maxWidth: "300px" }}
              disabled={!isEdit}
              label={handleTranslate("HEADQUARTER", true)}
              value={inputs.headquarter}
              onChange={(e) => handleChange("headquarter", e.target.value)}
            />
            {user.tenantId === tilebytesTenantId && (
              <>
                <TextField
                  sx={{ width: "100%", maxWidth: "300px" }}
                  required
                  type="number"
                  InputProps={{ inputProps: { min: 1, max: 10 } }}
                  disabled={!isEdit}
                  label={handleTranslate("USER_LIMIT", true)}
                  value={inputs.limitUsers}
                  onChange={(e) => handleChange("limitUsers", e.target.value)}
                />
                <AlertCategorySelect
                  multiple
                  disabled={!isEdit}
                  value={inputs.categoryAlerts}
                  onChange={(value) => handleChange("categoryAlerts", value)}
                />
                {enabledActions.includes("sharing") && (
                  <TenantSelect
                    multiple
                    placeholder={handleTranslate("CAN_SHARE_WITH")}
                    disabled={!isEdit}
                    tenantsList={tenantsSelectList}
                    value={inputs.sharingTenants}
                    onChange={(value) => handleChange("sharingTenants", value)}
                  />
                )}
                <ViewSelect
                  required
                  onlyActivableViews
                  placeholder={handleTranslate("ENABLED_VIEWS", true)}
                  disabled={!isEdit}
                  multiple
                  value={inputs.enabledViews}
                  onChange={handleChangeEnabledViews}
                />
              </>
            )}
          </div>
          {user.tenantId === tilebytesTenantId && listViews.length > 0 && (
            <div>
              <h3 style={{ fontWeight: "bold" }}>
                {handleTranslate("PERMITTED_ACTIONS", true)}
              </h3>
              <PermittedActions
                listViews={listViews}
                isEdit={isEdit}
                activeGroupActions={inputs.groupActions}
                activeActions={inputs.permittedActions}
                onChangeGroupActions={(value) =>
                  handleChange("groupActions", value)
                }
                onChangeActions={(value) =>
                  handleChange("permittedActions", value)
                }
              />
            </div>
          )}
        </div>
      </AccordionComponent>
    )
  );
};

const TenantsInfo = ({ enabledActions, selectedTenants, onDeleteTenant }) => {
  const tenants = useSelector((state) => state.tenants.list);
  const canEdit = useMemo(() => {
    if (
      enabledActions.includes("manage-tenant") ||
      enabledActions.includes("update-info-tenant")
    )
      return true;
    return false;
  });

  return selectedTenants.map((tenantId, index) => (
    <SingleTenant
      key={tenantId}
      tenantId={tenantId}
      tenant={tenants[tenantId]}
      disableEdit={!canEdit}
      enabledActions={enabledActions}
      disableDelete={!enabledActions.includes("manage-tenant")}
      onDelete={onDeleteTenant}
    />
  ));
};

const TenantsConfiguration = ({
  enabledActions,
  selectedTenants,
  onDeleteTenant,
}) => {
  const { t } = useTranslation("evocs");
  const user = useSelector((state) => state.user);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleTranslate = (textId, general = false) => {
    if (general) return translate(t, textId);
    return translate(t, textId, viewNames.ADMINISTRATION_VIEW);
  };

  return (
    <div>
      <Box sx={{ width: "100%", padding: "1rem" }}>
        {user.selectedTenant === tilebytesTenantId &&
          enabledActions.includes("manage-tenant") && (
            <div style={{ display: "flex", marginBottom: "1rem" }}>
              <button
                className="px-16 py-10 md:ml-auto w-full md:w-fit"
                style={{
                  backgroundColor: "#5d9a60",
                  borderRadius: "5px",
                  color: "white",
                  textTransform: "uppercase",
                }}
                onClick={() => setIsModalOpen(true)}
              >
                {handleTranslate("NEW_TENANT")}{" "}
              </button>
            </div>
          )}
        <TenantsInfo
          enabledActions={enabledActions}
          selectedTenants={selectedTenants}
          onDeleteTenant={onDeleteTenant}
        />
      </Box>
      <CreateTenantModal
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        onFinally={() => setIsModalOpen(false)}
      />
    </div>
  );
};

export default TenantsConfiguration;
