import { viewNames } from "app/configs/routesNames";
import { createLanguageObject } from "src/utilities/utils";

const IT = {
    HEADER : "Configurazione",
    NO_INSTALLED_DEVICE: "Nessuna unità installata",
    INSTALLATION_DATE: "Data di installazione",
    CONFIGURE: "Configura",
    INSTALL : "Installa",
    UNINSTALL : "Disinstalla",
    REMOVE: "Rimuovi",
    NEW_STATION: "Nuova stazione",
    NEW_CONTOROL_UNIT: "Nuova centralina",
    NEW_SENSOR: "Nuovo sensore",
    NEW_MEASURE: "Nuova misura",
    ADD_ON: "Aggiungi su",
    SELECT_UNIT: "Seleziona una unità",
    AVAILABLE_WITH_UNITS: "Visibile con unità installate",
    AVAILABLE_WITHOUT_UNITS: "Visibile senza unità installate",
    AVAILABLE_WITH_SENSORS: "Visibile con sensori",
    AVAILABLE_WITHOUT_SENSORS: "Visibile senza sensori",
    NOT_AVAILABLE: "Non visibile",
    AVAILABLE_INSTALLED: "Visibile installato",
    AVAILABLE_NOT_INSTALLED: "Visibile non installato",
};

const CONFIGURATION_IT = createLanguageObject(IT, viewNames.CONFIGURATION_VIEW);

export default CONFIGURATION_IT;