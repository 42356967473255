import { viewNames } from "app/configs/routesNames";
import { createLanguageObject } from "src/utilities/utils";


const EN = {
    HEADER : "Configuration",
    NO_INSTALLED_DEVICE: "No installed device",
    INSTALLATION_DATE: "Installation date",
    CONFIGURE: "Configure",
    INSTALL : "Install",
    UNINSTALL : "Uninstall",
    REMOVE: "Remove",
    NEW_STATION: "New station",
    NEW_CONTOROL_UNIT: "New control unit",
    NEW_SENSOR: "New sensor",
    NEW_MEASURE: "New measure",
    ADD_ON: "Add on",
    SELECT_UNIT: "Select a unit",
    NOT_AVAILABLE: "Not available",
    AVAILABLE_WITH_UNITS: "Available with units installed",
    AVAILABLE_WITHOUT_UNITS: "Available without units installed",
    AVAILABLE_WITH_SENSORS: "Available with sensors",
    AVAILABLE_WITH_SENSORS: "Available with sensors",
    AVAILABLE_WITHOUT_SENSORS: "Available with sensors",
    AVAILABLE_INSTALLED: "Available installed",
    AVAILABLE_NOT_INSTALLED: "Available not installed",
};

const CONFIGURATION_EN = createLanguageObject(EN, viewNames.CONFIGURATION_VIEW);


export default CONFIGURATION_EN;