import { Checkbox, FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import { useCallback, useEffect, useLayoutEffect, useState } from "react";
import { useSelector } from "react-redux";
import { viewNames } from 'app/configs/routesNames';
import { useTranslation } from 'react-i18next';
import { translate } from "src/utilities/utils";


const MeasureSelect = ({ style, measuresId, value, defaultValue, onChange, label, multiple = false, required = false, disabled = false }) => {
  const measures = useSelector((state) => state.configuration.constants?.measure);
  const [options, setOptions] = useState(measuresId || []);
  const [val, setValue] = useState(defaultValue ? defaultValue : multiple ? [] : "");

  const { t } = useTranslation("evocs");
  const handleTranslate = (textId, general = false) => {
    if (general) return translate(t, textId);
    return translate(t, textId, viewNames.DASHBOARD_VIEW);
  };


  useLayoutEffect(() => {
    setOptions(() => {
      if (measuresId) {
        return measuresId;
      } else {
        return Object.keys(measures);
      }
    });
  }, [measuresId])

  useLayoutEffect(() => {
    value !== undefined && setValue(value);
  }, [value]);

  const handleChange = (newValue) => {
    setValue(newValue);
    onChange && onChange(newValue)
  };

  const getInputLabel = useCallback(() => {
    if(label) return required ? label + "*" : label;
    return required ? `${handleTranslate('PARAM', true)}*` : `${handleTranslate('PARAM', true)}`
  }, [label]);

  return (
    <FormControl sx={{ width: "100%", minWidth: "100px", maxWidth: "300px", ...style }}>
      <InputLabel id="measure-select-label">{getInputLabel()}</InputLabel>
      <Select
        disabled={disabled}
        labelId="measure-select-label"
        value={val}
        multiple={multiple}
        renderValue={(selected) => multiple ? selected.map((measureId) =>
          measureId === 'windrose' ? handleTranslate(`MEASURE_WINDROSE`, true) :
            handleTranslate(`MEASURE_${measures[measureId]?.measure.toUpperCase()}`, true) ?? measureId)
          .join(", ")
          : selected === 'windrose' ? handleTranslate(`MEASURE_WINDROSE`, true) :
            handleTranslate(`MEASURE_${measures[selected]?.measure.toUpperCase()}`, true) || selected}
        label={getInputLabel()}
        onChange={(e) => handleChange(e.target.value)}
      >
        {options?.map((measureId, index) => (
          <MenuItem key={index} value={measureId}>
            {multiple && <Checkbox checked={val?.indexOf(measureId) > -1} />}
            {
              measureId === 'windrose' ? handleTranslate(`MEASURE_WINDROSE`, true) :
                handleTranslate(`MEASURE_${measures[measureId]?.measure.toUpperCase()}`, true) ?? measureId
            }
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  )
}

export default MeasureSelect;