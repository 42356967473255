import BrowserRouter from "@fuse/core/BrowserRouter";
import FuseLayout from "@fuse/core/FuseLayout";
import FuseTheme from "@fuse/core/FuseTheme";
import { SnackbarProvider } from "notistack";
import { useSelector } from "react-redux";
import themeLayouts from "app/theme-layouts/themeLayouts";
import { selectMainTheme } from "src/app/store/fuse/settingsSlice";
import withAppProviders from "./withAppProviders";
import { AuthProvider } from "./auth/AuthContext";
import { generateToken, messaging } from "src/notifications/firebase";
import { useEffect } from "react";
import { onMessage } from "firebase/messaging";
import { payload } from "media-stream-library";


const App = () => {
  const mainTheme = useSelector(selectMainTheme);

  // useEffect(()=> {
  //   generateToken();
  //   onMessage(messaging, (payload) => {
  //     console.log('payload', payload);
  //   } )
  // }, [])

  return (
    <>
      <FuseTheme theme={mainTheme}>
        <AuthProvider>
          <BrowserRouter>
            <SnackbarProvider
              maxSnack={5}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "right",
              }}
              classes={{
                containerRoot:
                  "bottom-0 right-0 mb-52 md:mb-68 mr-8 lg:mr-80 z-99",
              }}
            >
              <FuseLayout layouts={themeLayouts} />
            </SnackbarProvider>
          </BrowserRouter>
        </AuthProvider>
      </FuseTheme>
    </>
  );
};

export default withAppProviders(App)();
