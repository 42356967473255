import WithAuth from "./WithAuth";
import { viewNames } from "app/configs/routesNames";
import CustomPageCarded from "./CustomPageCarded";
import Footer from "src/components/SharingComponents/Footer";
import InternalNetworkManHeader from "src/components/InternalNetworkMan/InternalNetworkManHeader";
import InternalNetworkManContent from "src/components/InternalNetworkMan/InternalNetworkManContent";

const InternalNetworkMan = () => {
  return (
    <WithAuth
      viewName={viewNames.INTERNAL_NETWORK_MAN_VIEW}
      component={
        <CustomPageCarded
          header={<InternalNetworkManHeader />}
          content={<InternalNetworkManContent/>}
          footer={<Footer />}
        />
      }
    />
  );
};

export default InternalNetworkMan;
