import React, { useCallback, useMemo } from "react";
import {
  Modal,
  Button,
  TextField,
  IconButton,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { useEffect, useState } from "react";
import { createTenantRole } from "app/store/evocsSlice/tenants/tenantsActions";
import TenantSelect from "../SharingComponents/Inputs/TenantSelect";
import { getUsers } from "app/store/evocsSlice/user/userAction";
import UserSelect from "../SharingComponents/Inputs/UserSelect";
import { modalsComponentNames } from 'app/configs/routesNames';
import { useTranslation } from 'react-i18next';
import { translate } from "src/utilities/utils";
import LoadingGif from "../SharingComponents/LoadingGif";
import { setGlobalError, setGlobalSuccess } from "app/store/evocsSlice/globalError/errorActions";
import { useSelector } from "react-redux";
import GroupActionSelect from "../SharingComponents/Inputs/GroupActionSelect";
import ActionSelect from "../SharingComponents/Inputs/ActionSelect";
import ViewSelect from "../SharingComponents/Inputs/ViewSelect";
import AlertCategorySelect from "../SharingComponents/Inputs/AlertCategorySelect";

const tilebytesTenantId = "6554a3a2af4b91cdd6da3285";

const PermittedActions = ({tenant, activeViews, permittedGroupActions, activeActions, onChangeGroupActions, onChangeActions }) => {
  const actions = useSelector((state) => state.configuration.constants.action);
  const views = useSelector((state) => state.configuration.constants.view_name);
  const [actionsList, setActionsList] = useState({});
  const [groupsActionsList, setGroupActionsList] = useState({});
  const [selectedActions, setSelectedActions] = useState(activeActions);
  const [selectedGroupActions, setSelectedGroupActions] = useState(permittedGroupActions);

  const getGroupActionsList = useCallback(() => {
    let list = {};
    tenant.enabledViews.forEach(viewId => {
      list[viewId] = [];
      tenant.enabledActions?.[viewId]?.forEach((actionId) => {
        const groupId = actions[actionId].groupId;
        if(!list[viewId].includes(groupId)) list[viewId].push(groupId);
      })
    });
    return list;
  }, [tenant, views, actions]);

  const getActionsList = useCallback(() => {
    let list = {};
    tenant.enabledViews.forEach(viewId => {
      list[viewId] = [];
      selectedGroupActions[viewId]?.forEach((groupId) => {
        list[viewId] = list[viewId].concat(tenant.enabledActions?.[viewId].filter((actionId) => actions[actionId].groupId === groupId));
      })
    });
    return list;
  }, [tenant, selectedGroupActions]);

  useEffect(() => {
    setGroupActionsList(getGroupActionsList());
  }, [getGroupActionsList]);

  useEffect(() => {
    setActionsList(getActionsList());
  }, [getActionsList]);

  useEffect(() => {
    const newList = getActionsList();
    let newSelectedActions = {};
    activeViews.forEach((viewId) => {
      const newSelected = newList[viewId].filter((actionId) => !actionsList[viewId]?.includes(actionId));
      const deselected = actionsList[viewId]?.filter((actionId) => !newList[viewId]?.includes(actionId)) ?? [];
      const oldSelected = selectedActions[viewId]?.filter((actionId) => !deselected?.includes(actionId)) ?? [];
      newSelectedActions[viewId] = oldSelected?.concat(newSelected) ?? [];
    })
    setActionsList(getActionsList());
    setSelectedActions(newSelectedActions);
    onChangeActions?.(newSelectedActions);
  }, [selectedGroupActions]);

  useEffect(() => {
    setSelectedActions(activeActions);
  }, [activeActions])

  useEffect(() => {
    setSelectedGroupActions(permittedGroupActions);
  }, [permittedGroupActions])

  const handleChangeGroupActions = (viewId, value) => {
    const newSelected = {...selectedGroupActions, [viewId]: value}
    setSelectedGroupActions(newSelected);
    onChangeGroupActions?.(newSelected);
  }

  const handleChangeActions = (viewId, value) => {
    const newSelected = {...selectedActions, [viewId]: value}
    setSelectedActions(newSelected);
    onChangeActions?.(newSelected);
  }

  const isActionsSelectDisabled = (viewId) => {
    if(selectedGroupActions[viewId] && selectedGroupActions[viewId].length > 0) return false;
    return true;
  }


  return (
    <div style={{ display: "flex", flexDirection: "column", gap: "1rem" }}>
      {activeViews?.map((viewId) => (
        <div key={viewId} style={{ display: "flex", gap: "1rem", alignItems: "center" }}>
          <label style={{ width: "100%", maxWidth: "200px" }}>{views[viewId].view_name}</label>
          <GroupActionSelect multiple groupsId={groupsActionsList[viewId]} value={selectedGroupActions[viewId]} onChange={(value) => handleChangeGroupActions(viewId, value)} />
          <ActionSelect multiple disabled={isActionsSelectDisabled(viewId)} actionsId={actionsList[viewId]} value={selectedActions[viewId]} onChange={(value) => handleChangeActions(viewId, value)} />
        </div>
      ))}
    </div>
  );
}

const defaultInputs = {
  tenant: "",
  name: "",
  users: [],
  categoryAlerts: [],
  description: "",
  activeViews: [],
  groupActions: {},
  actions: {}
};

const requiredInputs = ["tenant", "name", "activeViews"];

const ConfigurationChip = ({ onPending, onFinally }) => {
  const { t } = useTranslation("evocs");
  const tenants = useSelector((state) => state.tenants.list);
  const user = useSelector((state) => state.user);
  const views= useSelector((state) => state.configuration.constants?.view_name);
  const [inputs, setInputs] = useState({...defaultInputs, tenant: user.selectedTenant});
  const [buttonDisabled, setButtonDisabled] = useState(true);
  const [isInputsChanged, setInputsChanged] = useState(false);
  const [tenantUsers, setTenantUsers] = useState([]);
  const [enabledViews, setEnabledViews] = useState([]);
  
  const handleTranslate = (textId, general = false) => {
    if (general) return translate(t, textId);
    return translate(t, textId,modalsComponentNames.MODALS);
  };


  useEffect(() => {
    const tenantId = tilebytesTenantId === user.selectedTenant ? inputs.tenant : user.selectedTenant;
    getUsers([tenantId]).then((res) => {
      setTenantUsers(res)
    }).catch(() => setGlobalError(true));
    setEnabledViews(tenants[inputs.tenant].enabledViews.filter((viewId) => views[viewId].activable === 1));
    handleChangeInput("activeViews", []);
  }, [inputs.tenant]);

  useEffect(() => {
    if(!isInputsChanged) return;
    const disabled = requiredInputs.some((key) => !inputs[key] || inputs[key]?.length === 0);
    setButtonDisabled(disabled);
  }, [inputs])

  const handleChangeInput = (key, value) => {
    setInputs({ ...inputs, [key]: value });
    setInputsChanged(true);
  };

  const handleChangeActiveViews = (value) => {
    setInputsChanged(true);
    const stationViewId = "655f295e86458728760700b5", singleStationViewId = "655f295f86458728760700b7";
    const newSelectedView = value.find((viewId) => (viewId === stationViewId || viewId === singleStationViewId) && !inputs.activeViews.includes(viewId));
    if(newSelectedView) {
      const newValue = newSelectedView === stationViewId ? [...value, singleStationViewId] : [...value, stationViewId];
      setInputs((prev) => ({...prev, activeViews: newValue}));
      return;
    }else if(inputs.activeViews.some((viewId) => (viewId === stationViewId || viewId === singleStationViewId) && !value.includes(viewId))) {
      const newValue = value.filter((viewId) => viewId !== stationViewId && viewId !== singleStationViewId);
      setInputs((prev) => ({...prev, activeViews: newValue}));
      return;
    }
    setInputs((prev) => ({...prev, activeViews: value}));
  }


  const handleConfirm = () => {
    onPending();
    createTenantRole(inputs.tenant, inputs.name, inputs.users, inputs.description, inputs.activeViews, inputs.actions, inputs.categoryAlerts)
    .then(() => setGlobalSuccess(true))
    .catch(() => setGlobalError(true))
    .finally(onFinally);
  };


  return (
    <div style={{ display: "flex", flexDirection: "column", gap: "2rem", padding: "2rem" }}>
      <h2 style={{ fontWeight: "bold" }}> {handleTranslate("NEW_ROLE")} </h2>
      {tilebytesTenantId === user.selectedTenant && <TenantSelect required value={inputs.tenant} onChange={(value) => handleChangeInput("tenant", value)} />}
      <TextField
        sx={{ width: "100%", maxWidth: "300px" }}
        label={handleTranslate("NAME", true)}
        required
        value={inputs.name}
        onChange={(e) => handleChangeInput("name", e.target.value)}
      />
      <UserSelect limitTags={2} disablePortal multiple users={tenantUsers} value={inputs.users} onChange={(value) => handleChangeInput("users", value)} />
      <AlertCategorySelect multiple value={inputs.categoryAlerts} onChange={(value) => handleChangeInput("categoryAlerts", value)} />
      <TextField
        sx={{ width: "100%", maxWidth: "300px" }}
        label={handleTranslate("DESCRIPTION", true)}
        value={inputs.description}
        onChange={(e) => handleChangeInput("description", e.target.value)}
      />
      <div style={{ display: "flex", gap: "1rem", alignItems: "center", flexWrap: "wrap", margin: "1rem 0" }}>
        <label> {handleTranslate("VIEWABLE_PAGES")}: </label>
        <ViewSelect multiple required viewsId={enabledViews} value={inputs.activeViews} onChange={handleChangeActiveViews} />
      </div>
      {inputs.activeViews.length > 0 && <>
        <h3 style={{ fontWeight: "bold" }}>{handleTranslate("PERMITTED_ACTIONS", true)}</h3>
        <PermittedActions tenant={tenants[inputs.tenant]} activeViews={inputs.activeViews} permittedGroupActions={inputs.groupActions} activeActions={inputs.actions}
          onChangeGroupActions={(value) => handleChangeInput("groupActions", value)} onChangeActions={(value) => handleChangeInput("actions", value)}/>
      </>}
      <Button sx={{ width: "fit-content", margin: "0 auto", border: "1px solid black" }} disabled={buttonDisabled} onClick={handleConfirm}> {handleTranslate('CONFIRM', true)} </Button>
    </div>
  );
}

const CreateRoleModal = ({ isOpen = false, onClose, onFinally }) => {
  const [updateLoading, setUpdateLoading] = useState(false);

  const handleFinally = () => {
    setUpdateLoading(false);
    onFinally?.();
  }

  const handleClose = () => {
    setUpdateLoading(false);
    onClose?.();
  }

  return (
    <Modal open={isOpen}>
      <div style={styles.containerStyle}>
        <div style={{ display: "flex" }}>
          <IconButton sx={{ marginLeft: "auto" }} onClick={handleClose}>
            <CloseIcon fontSize="small" sx={{ color: "white" }} />
          </IconButton>
        </div>
        <div style={{ background: "white", borderRadius: "0 1rem 1rem 1rem", height: "100%", overflowY: "auto" }}>
          <ConfigurationChip onPending={() => setUpdateLoading(true)} onFinally={handleFinally}/>
          {updateLoading &&
            <div style={styles.loadingPopup}>
              <LoadingGif />
            </div>
          }
        </div>
      </div>
    </Modal>
  )
};

const styles = {
  containerStyle: {
    position: "absolute",
    display: "flex",
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    background: "#2d3740",
    padding: "1rem",
    borderRadius: "1rem",
    height: "80vh",
    width: "95%",
    maxWidth: "800px",
    flexDirection: "column"
  },
  loadingPopup: {
    position: "absolute",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    background: "rgba(255, 255, 255, .5)",
    top: 0,
    right: 0,
    bottom: 0,
    left: 0
  }
}

export default CreateRoleModal;
