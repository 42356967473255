import WithAuth from "./WithAuth";
import SingleStationHandlingContent from "src/components/SingleStationHandling/SingleStationHandlingContent";
import { viewNames } from "app/configs/routesNames";
import SingleStationHandlingHeader from "src/components/SingleStationHandling/SingleStationHandlingHeader";
import CustomPageCarded from "./CustomPageCarded";
import Footer from "src/components/SharingComponents/Footer";

const SingleStationHandling = () => {

  return (
    <WithAuth
      viewName={viewNames.STATIONS_VIEW}
      component={
        <CustomPageCarded
          header={<SingleStationHandlingHeader />}
          content={<SingleStationHandlingContent />}
          footer={<Footer />}
        />
      }
    />
  );
};

export default SingleStationHandling;
