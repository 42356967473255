import React, { useEffect, useMemo } from "react";
import {
  Modal,
  IconButton,
  Button,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { useState } from "react";
import LoadingGif from "../SharingComponents/LoadingGif";
import { setGlobalError, setGlobalSuccess, setServerError } from "app/store/evocsSlice/globalError/errorActions";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { translate } from "src/utilities/utils";
import { modalsComponentNames } from "app/configs/routesNames";
import TenantSelect from "../SharingComponents/Inputs/TenantSelect";
import StationSelect from "../SharingComponents/Inputs/StationSelect";
import CuSelect from "../SharingComponents/Inputs/CuSelect";
import SensorAutocomplete from "../SharingComponents/Inputs/SensorAutocomplete";
import SelectComponent from "../SharingComponents/Inputs/SelectComponent";
import { UNIT_TYPE } from "../StationHandling/utils";
import { shareSingleStation } from "app/store/evocsSlice/stationsMongo/stationsMongoActions";
import { shareSingleCu } from "app/store/evocsSlice/controlUnits/cuActions";
import { shareSingleSensor } from "app/store/evocsSlice/sensors/sensorsActions";
import { getTenantCus, getTenantSensors, getTenantStations } from "app/store/evocsSlice/tenants/tenantsActions";

const tilebytesTenantId = "6554a3a2af4b91cdd6da3285";

const defaultInputs = {
  tenant: "",
  sharingTenants: [],
  deviceType: "",
  selectedDevices: []
};

const requiredInputs = ["tenant", "sharingTenants", "deviceType", "selectedDevices"];

const getTenantDevices = async(tenantId) => {
  const tenantIds = [tenantId];
  const fetch = [
    getTenantStations(tenantIds),
    getTenantCus(tenantIds),
    getTenantSensors(tenantIds)
  ];
  
  return await Promise.all(fetch);
}

const DeviceSelect = ({stations, controlUnits, sensors, deviceType, selectedDevices, onChange}) => {
  const { t } = useTranslation("evocs");
  const [options, setOptions] = useState([]);
  const [selected, setSelected] = useState([]);


  useEffect(() => {
    setOptions(() => {
      switch(deviceType) {
        case UNIT_TYPE.station:
          return Object.keys(stations).map((stationId) => ({id: stationId,  ...stations[stationId]}));
        case UNIT_TYPE.cu:
          return Object.keys(controlUnits).map((cuId) => ({id: cuId,  ...controlUnits[cuId]}));
        case UNIT_TYPE.sensor:
          return Object.keys(sensors).map((sensorId) => ({id: sensorId,  ...sensors[sensorId]}));
      }
    })
    setSelected([]);
    onChange?.([]);
  }, [deviceType, stations, controlUnits, sensors])

  useEffect(() => {
    setSelected(selectedDevices);
  }, [selectedDevices])


  const handleTranslate = (textId, general = false) => {
    if (general) return translate(t, textId);
    return translate(t, textId, modalsComponentNames.MODALS);
  };

  const labelText = useMemo(() => {
    switch(deviceType) {
      case UNIT_TYPE.station:
        return handleTranslate("SELECT_STATIONS_TO_SHARE");
      case UNIT_TYPE.cu:
        return handleTranslate("SELECT_CUS_TO_SHARE");
      case UNIT_TYPE.sensor:
        return handleTranslate("SELECT_SENSORS_TO_SHARE");
    }
  }, [t, deviceType])

  return (
    <div style={{display: "flex", gap: "1rem", alignItems: "center"}}>
      <label>{labelText}</label>
      {deviceType === UNIT_TYPE.station ?
        <StationSelect required disablePortal multiple limitTags={2} isUserTenant={false} stationsId={options} value={selected} onChange={onChange}/>
      :
      deviceType === UNIT_TYPE.cu ?
        <CuSelect required disablePortal multiple limitTags={2} isUserTenant={false} cusId={options} value={selected} onChange={onChange}/>  
      : 
      deviceType === UNIT_TYPE.sensor &&
        <SensorAutocomplete required disablePortal multiple limitTags={2} isUserTenant={false} sensorsId={options} value={selected} onChange={onChange}/>  
      }
    </div>    
  )
}


const SharingForm = ({onPending, onFinally }) => {
  const { t } = useTranslation("evocs");
  const tenants = useSelector((state) => state.tenants.list);
  const user = useSelector((state) => state.user);
  const [loading, setLoading] = useState(true);
  const [stations, setStations] = useState([]);
  const [controlUnits, setControlUnits] = useState([]);
  const [sensors, setSensors] = useState([]);
  const [inputs, setInputs] = useState({
    ...defaultInputs,
    tenant: user.selectedTenant
  });
  const [buttonDisabled, setButtonDisabled] = useState(true);
  const [isInputsChanged, setInputsChanged] = useState(false);
  const handleTranslate = (textId, general = false) => {
    if (general) return translate(t, textId);
    return translate(t, textId, modalsComponentNames.MODALS);
  };
  const [sharingTenantsList, setSharingTenantsList] = useState([]);

  const deviceTypeOptions = useMemo(() => {
    return [UNIT_TYPE.station, UNIT_TYPE.cu, UNIT_TYPE.sensor];
  }, []);

  const deviceTypeLabels = useMemo(() => {
    return [handleTranslate("STATIONS", true), handleTranslate("CONTROL_UNITS", true), handleTranslate("SENSORS", true)];
  }, [t]);
  

  useEffect(() => {
    let canSet = true;
    setLoading(true);
    getTenantDevices(inputs.tenant).then((res) => {
      if(!canSet) return;
      setStations(res[0].list);
      setControlUnits(res[1].list);
      setSensors(res[2].list);
      setLoading(false);
    }).catch(() => {
      setServerError(true);
    });

    return () => canSet = false;
  }, [inputs.tenant])

  useEffect(() => {
    setSharingTenantsList(Object.keys(tenants).filter((tenantId) => tenantId !== inputs.tenant));
    setInputs((prev) => ({ ...prev, sharingTenants: [], selectedDevices: [] }));
  }, [inputs.tenant])

  useEffect(() => {
    if(!isInputsChanged) return;
    const disabled = requiredInputs.some((key) => (!inputs[key] || inputs[key] === "" || inputs[key].length === 0));
    setButtonDisabled(disabled);
  }, [inputs])

  const handleChangeInput = (key, value) => {
    setInputs({ ...inputs, [key]: value });
    setInputsChanged(true);
  };

  const handleChangeDeviceType = (value) => {
    setInputs({ ...inputs, deviceType: value, selectedDevices: []});
    setInputsChanged(true);
  };

  const handleConfirm = () => {
    onPending?.();
    switch(inputs.deviceType) {
      case UNIT_TYPE.station:
        shareSingleStation(inputs.selectedDevices, inputs.sharingTenants)
        .then(() => setGlobalSuccess(true))
        .catch(() => setGlobalError(true))
        .finally(onFinally);
        break;
      case UNIT_TYPE.cu: 
        shareSingleCu(inputs.selectedDevices, inputs.sharingTenants)
        .then(() => setGlobalSuccess(true))
        .catch(() => setGlobalError(true))
        .finally(onFinally);
        break;
      case UNIT_TYPE.sensor: 
        shareSingleSensor(inputs.selectedDevices, inputs.sharingTenants)
        .then(() => setGlobalSuccess(true))
        .catch(() => setGlobalError(true))
        .finally(onFinally);
        break;
    }
  };


  return (
    <div style={{ display: "flex", flexDirection: "column", gap: "2rem", padding: "2rem" }}>
      <h2 style={{ fontWeight: "bold" }}>{handleTranslate("NEW_SHARING")}</h2>
      {user.selectedTenant === tilebytesTenantId && <TenantSelect required value={inputs.tenant} onChange={(value) => handleChangeInput("tenant", value)} />}
      {!loading ? 
        <>
        <div style={{display: "flex", gap: "1rem", alignItems: "center"}}>
          <label>{handleTranslate("SHARE_WITH")}</label>
          <TenantSelect required multiple tenantsList={sharingTenantsList} value={inputs.sharingTenants} onChange={(value) => handleChangeInput("sharingTenants", value)}/>
        </div>
        <div style={{display: "flex", gap: "1rem", alignItems: "center"}}>
          <label>{handleTranslate("SHARE")}</label>
          <SelectComponent required labelId={"device_type"} placeholder={handleTranslate("DEVICE_TYPE", true)} options={deviceTypeOptions} labels={deviceTypeLabels} value={inputs.deviceType} onChange={handleChangeDeviceType}/>
        </div>
        <DeviceSelect stations={stations} controlUnits={controlUnits} sensors={sensors} deviceType={inputs.deviceType} selectedDevices={inputs.selectedDevices} onChange={(value) => handleChangeInput("selectedDevices", value)}/>
        </>
        :
        <LoadingGif />
      }
      <Button sx={{ width: "fit-content", margin: "0 auto", border: "1px solid black" }} disabled={buttonDisabled} onClick={handleConfirm}> {handleTranslate('CONFIRM', true)} </Button>
    </div>
  );
}

const CreateSharingModal = ({ isOpen = false, onClose, onFinally }) => {
  const [loading, setLoading] = useState(false);

  const handleFinally = () => {
    setLoading(false);
    onFinally?.();
  }

  const handleClose = () => {
    setLoading(false);
    onClose?.();
  }

  return (
    <Modal open={isOpen}>
      <div style={styles.containerStyle}>
        <div style={{ display: "flex" }}>
          <IconButton sx={{ marginLeft: "auto" }} onClick={handleClose}>
            <CloseIcon fontSize="small" sx={{ color: "white" }} />
          </IconButton>
        </div>
        <div style={{ background: "white", borderRadius: "0 1rem 1rem 1rem", height: "100%", overflowY: "auto" }}>
          <SharingForm onPending={() => setLoading(true)} onFinally={handleFinally} />
          {loading &&
            <div style={styles.loadingPopup}>
              <LoadingGif />
            </div>
          }
        </div>
      </div>
    </Modal>
  )
};

const styles = {
  containerStyle: {
    position: "absolute",
    display: "flex",
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    background: "#2d3740",
    padding: "1rem",
    borderRadius: "1rem",
    height: "80vh",
    width: "95%",
    maxWidth: "800px",
    flexDirection: "column"
  },
  loadingPopup: {
    position: "absolute",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    background: "rgba(255, 255, 255, .5)",
    top: 0,
    right: 0,
    bottom: 0,
    left: 0
  }
}

export default CreateSharingModal;