import { Button, IconButton, Menu, MenuItem } from "@mui/material";
import { memo, useEffect, useLayoutEffect, useMemo, useState } from "react";
import DragIndicatorIcon from '@mui/icons-material/DragIndicator';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { viewNames } from "app/configs/routesNames";
import { deleteCu } from "app/store/evocsSlice/controlUnits/cuActions";
import CuSelect from "../SharingComponents/Inputs/CuSelect";
import { useDrag } from "react-dnd";
import TenantSelect from "../SharingComponents/Inputs/TenantSelect";
import { useTranslation } from 'react-i18next';
import { translate } from "src/utilities/utils";
import LoadingGif from "../SharingComponents/LoadingGif";
import useMobile from "src/app/hooks/useMobile";
import { useSelector } from "react-redux";
import { setGlobalError, setGlobalSuccess } from "app/store/evocsSlice/globalError/errorActions";
import { IoEyeOffOutline } from "react-icons/io5";
import { IoMdEye } from "react-icons/io";
import { BsPlugin } from "react-icons/bs";
import { Tooltip } from "react-tooltip";

const viewName = viewNames.CONFIGURATION_VIEW;

const CuListItemMenu = ({ style, enabledActions, cu, onClickItem }) => {
  const { t } = useTranslation("evocs");
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = (e) => {
    setAnchorEl(null);
  };

  const stopPropagation = (e) => {
    e.stopPropagation()
  };

  const handleClickItem = (itemAction, actionCallback = null) => {
    handleClose();
    onClickItem && onClickItem(itemAction, {...cu, type: "cu"}, actionCallback);
  };

  const handleClickDelete = () => {
    handleClickItem("delete", () => {
      deleteCu(cu.id).then(() => setGlobalSuccess(true, "MODAL_SUCCESS_DELETE_CU")).catch(() => setGlobalError(true));
    });
  }

  const handleTranslate = (textId, general = false) => {
    if (general) return translate(t, textId);
    return translate(t, textId, viewNames.CONFIGURATION_VIEW);
  };

  return (
    <div style={{ ...style, width: "fit-content" }} onClick={stopPropagation}>
      <IconButton onClick={handleClick}>
        <MoreVertIcon fontSize="small" />
      </IconButton>
      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
      >
        <MenuItem onClick={() => handleClickItem("config-cu")}> {handleTranslate("CONFIGURE")} </MenuItem>
        {enabledActions.includes("manage-device") && <MenuItem sx={{ color: "red" }} onClick={handleClickDelete}> {handleTranslate("DELETE", true)} </MenuItem>}
      </Menu>
    </div>

  )
}

const CuListItem = ({ enabledActions, disableDrag, cu, parent, selected = false, onClick, onDrag, onClickItemMenu, handleTranslate }) => {
  
  const installedCu = useSelector((state) => state.controlUnits.installed) ?? [];
  const className = !selected ? "station-item-list close" : "station-item-list selected";
  const canDrag = useMemo(() => {
    return !disableDrag && enabledActions?.includes("manage-device")
  }, [enabledActions, disableDrag])
  const [{ isDragging }, drag] = useDrag({
    type: "cu",
    item: { unit: {...cu, type: "cu"}, parent },
    collect: (monitor) => {
      return {
        isDragging: monitor.isDragging()
      }
    },
    canDrag: canDrag
  }, [cu, canDrag]);

  useEffect(() => {
    if (!onDrag) return;
    if (isDragging) onDrag(cu);
    else onDrag(null);
  }, [isDragging])

  const handleClick = () => {
    if(disableDrag) 
      onClickItemMenu && onClickItemMenu("config-cu", {...cu, type: "cu"});
    else onClick && onClick(cu.id)
  }

  const isMenuEnabled = () => {
    if (!enabledActions.includes("manage-device") && !enabledActions.includes("update-info-device")) return false;
    return true;
  };

  return cu && (
    <div ref={drag} className={className} style={{ display: "flex", padding: "1rem 0rem", alignItems: "center", borderTop: "1px solid grey", cursor: canDrag ? "grab" : "default" }} onClick={handleClick}>
      {canDrag && <DragIndicatorIcon className="drag-icon" fontSize="small" />}
      <span style={{ fontWeight: "bold" }}>{cu.serial}</span>
      {(cu?.available === 1 ?? 0 ) ?
        (
          installedCu && installedCu.includes(cu.id) ?
            <div style={{ marginLeft: "auto"}}>
              <BsPlugin
                data-tooltip-id="available-with-sensors"
                data-tooltip-content={handleTranslate && handleTranslate("AVAILABLE_WITH_SENSORS")}
                style={{
                  minWidth: "20px",
                  minHeight: "20px",
                  color: "green",
                  cursor: "pointer",
                }}
              />
              <Tooltip id="available-with-sensors" />
            </div>
            :
            <div style={{ marginLeft: "auto"}}>
              <IoMdEye 
                style={{minWidth: "20px", minHeight: "20px" }}
                data-tooltip-id="available-without-sensors"
                data-tooltip-content={handleTranslate && handleTranslate("AVAILABLE_WITHOUT_SENSORS")}
              />
              <Tooltip id="available-without-sensors" />
          </div>
        )
        : 
          <div style={{ marginLeft: "auto"}}>
            <IoEyeOffOutline 
              style={{minWidth: "20px", minHeight: "20px" }} 
              data-tooltip-id="not-available"
              data-tooltip-content={handleTranslate && handleTranslate("NOT_AVAILABLE")}
            />
            <Tooltip id="not-available" />
          </div>
      }
      {isMenuEnabled() && <CuListItemMenu enabledActions={enabledActions} cu={cu} onClickItem={onClickItemMenu} />}
    </div>
  );
}

const CusList = memo(({ enabledActions, list, selectedItems, onClickItem, onDragCu, onClickItemMenu, handleTranslate }) => {
  const [xs, sm] = useMobile();
  const stations = useSelector((state) => state.stationsMongo.list);
  const controlUnits = useSelector((state) => state.controlUnits.list);
  const disableDrag = useMemo(() => xs || sm);


  const handleClick = (cuId) => {
    if (!selectedItems.includes(cuId)) onClickItem([...selectedItems, cuId], cuId);
    else onClickItem(selectedItems.filter((e) => e !== cuId), cuId);
  }

  const getCuElement = (cuId) => {
    return { id: cuId, ...controlUnits[cuId] }
  }

  const getParent = (cuId) => {
    const parentId = controlUnits[cuId]?.deviceParent?.device;
    if (parentId) return { id: parentId, type: "station", ...stations[parentId] }
  }

  return (
    <div style={{ height: "100%", overflowY: "auto" }}>
      {list?.map((cuId) => (
        <CuListItem key={cuId} enabledActions={enabledActions} disableDrag={disableDrag} cu={getCuElement(cuId)} parent={getParent(cuId)} selected={selectedItems.includes(cuId)} onClick={handleClick}
          onDrag={onDragCu} onClickItemMenu={onClickItemMenu} handleTranslate={handleTranslate} />
      ))}
    </div>
  )
});

const CusManagement = ({loading, selectedTenant, selectedCus, enabledActions, onChangeTenant, onCuItemClick, onDragCu, onClickItemMenu }) => {
  const { t } = useTranslation("evocs");
  const controlUnits = useSelector((state) => state.controlUnits.list);
  const [cusOptions, setCusOptions] = useState([]);
  const [filteredCus, setFilteredCus] = useState();
  const [visibleCus, setVisibleCus] = useState();

  useLayoutEffect(() => {
    setFilteredCus([]);
  }, [selectedTenant])

  useLayoutEffect(() => {
    setVisibleCus(() => {
      if (!filteredCus || filteredCus.length === 0) return Object.keys(controlUnits);
      return filteredCus;
    });
  }, [controlUnits, filteredCus])

  useEffect(() => {
    const allCusId = Object.keys(controlUnits);
    setCusOptions(allCusId.map((id) => ({ id, serial: controlUnits[id].serial })));
    setFilteredCus((prev) => prev.filter((cuId) => allCusId.includes(cuId)));
  }, [controlUnits])


  const handleTranslate = (textId, general = false) => {
    if (general) return translate(t, textId);
    return translate(t, textId, viewNames.CONFIGURATION_VIEW);
  };

  return (
    <div style={{ display: "flex", flexDirection: "column", gap: "1rem", padding: "1rem", height: "100%", overflowX: "visible" }}>
      {!loading ?
        <>
          <div className="flex flex-col xl:flex-row gap-[1rem] ">
            <div className="flex flex-col xl:flex-row gap-[1rem] items-stretch basis-2/3">
              <CuSelect style={{ maxWidth: "none"}} isUserTenant={false} multiple limitTags={1} cusId={cusOptions} value={filteredCus} onChange={setFilteredCus} />
              <TenantSelect style={{ maxWidth: "none"}} value={selectedTenant} onChange={onChangeTenant} />
            </div>
            <div className="flex basis-1/3">
              {enabledActions.includes("manage-device") && <button className="py-10 xl:py-0" style={{ backgroundColor: "#5d9a60", borderRadius: "5px", color: "white", width:"100%", textTransform: "uppercase" }} onClick={() => onClickItemMenu("create-cu", null)}>{handleTranslate("NEW_CONTOROL_UNIT")}</button>}
            </div>
          </div>
        <CusList enabledActions={enabledActions} list={visibleCus} selectedItems={selectedCus} onClickItem={onCuItemClick}
          onDragCu={onDragCu} onClickItemMenu={onClickItemMenu} handleTranslate={handleTranslate}/>
        </>
      :
      <LoadingGif />
      }
    </div>
  )
}

export default CusManagement;
