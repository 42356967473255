import { Box, display } from "@mui/system";
import { viewNames } from "app/configs/routesNames";
import OdorReportsHeader from "./OdorReportsContentHeader";
import LoadingGif from "../SharingComponents/LoadingGif";
import { useEffect, useMemo, useState } from "react";
import { deleteOdourReport, getAllReports } from "app/store/evocsSlice/reports/reportsActions";
import { useSelector } from "react-redux";
import OdorReportsGraphs from "./OdorReportsGraphs";
import { Grid } from "@mui/material";
import OdorReportsTable from "./OdorReportsTable";
import AddOdourReportModal from "../Modals/AddOdourReportModal";
import { getOdourFile } from "app/store/evocsSlice/reports/reportsActions";
import { setTimeRangeFilter } from "app/store/evocsSlice/filters/filtersActions";
import { calcIntervalDates } from "../StationHandling/utils";
import OdorReportsMap from "./OdorReportsMap";
import useFilters from "src/app/hooks/useFilters";
import useActions from "src/app/hooks/useActions";
import { getApiTimestamp } from "src/utilities/utils";
import { setGlobalSuccess } from "app/store/evocsSlice/globalError/errorActions";
import { useTranslation } from 'react-i18next';
import { translate } from "src/utilities/utils";

const viewName = viewNames.ODOR_REPORTS_VIEW;

const OdorReportsContent = () => {
  const userActions = useActions(viewName);
  const filters = useFilters(viewName);
  const timeRangeInView = filters.timeRange;
  const odourLevelSelect = filters.odour_level;
  const odourTypeSelect = filters.odour_type;
  const enabledActions = useMemo(() => {
    return userActions.map((action) => action.name);
  }, [userActions]);
  const [reports, setReports] = useState([]);
  const [mapCenter, setMapCenter] = useState();
  const [selectedReport, setSelectedReport] = useState();
  const [loadingReports, setLoadingReports] = useState(false);
  const [openOdourReportModal, setOpenOdourReportModal] = useState(false);
  const [defaultLatLon, setDefaultLatLon] = useState();
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();
  const [timeRangeStartDate, setTimeRangeStartDate] = useState();
  const [timeRangeEndDate, setTimeRangeEndDate] = useState();
  const [updatingReports, setUpdatingReports] = useState(false);

  const fileName = 'evocs_odours_signals';

  const { t } = useTranslation("evocs");
  const handleTranslate = (textId, general = false) => {
    if (general) return translate(t, textId);
    return translate(t, textId, viewNames.ODOR_REPORTS_VIEW);
  };

  const updateOdourReportsFromServer = async () => {
    setUpdatingReports(true);
    getAllReports(timeRangeStartDate, timeRangeEndDate, odourTypeSelect.selected || undefined, odourLevelSelect.selected || undefined).then((res) => {
      setReports(res);
      setUpdatingReports(false);
    });
    // getAllReports(timeRangeStartDate, getApiTimestamp(new Date()), odourTypeSelect.selected || undefined, odourLevelSelect.selected || undefined).then((res) => {
    //   setReports(res);
    //   setUpdatingReports(false);
    // });
  }

  useEffect(() => {
    if(!timeRangeStartDate || !timeRangeEndDate) return;
    let canSet = true;
    setLoadingReports(true);
    getAllReports(timeRangeStartDate, timeRangeEndDate, odourTypeSelect.selected || undefined, odourLevelSelect.selected || undefined).then((res) => {
      if(!canSet) return;
      setReports(res);
      setLoadingReports(false);
    });

    return () => canSet = false;
  }, [timeRangeStartDate, timeRangeEndDate, odourLevelSelect.selected, odourTypeSelect.selected]);


  useEffect(() => {
    if(timeRangeInView.startDate && timeRangeInView.endDate) {
      setStartDate(new Date(timeRangeInView.startDate));
      setEndDate(new Date(timeRangeInView.endDate));
    }
  }, [])

  useEffect(() => {
    if(timeRangeInView.startDate && timeRangeInView.endDate) {
      setTimeRangeStartDate(new Date(timeRangeInView.startDate));
      setTimeRangeEndDate(new Date(timeRangeInView.endDate));
    }
  }, [timeRangeInView.startDate, timeRangeInView.endDate])

  const handleClickTableRow = (rowIndex) => {
    window.scrollTo({top: -1000, behavior: "smooth"});
    setMapCenter({lat: reports[rowIndex].lat, lon: reports[rowIndex].lon});
    setSelectedReport(rowIndex);
  };

  const handleAddMarker = (e) => {
    if(!enabledActions.includes("odour-signal")) return;
    const coords = {lat: e.latlng?.lat || "", lon: e.latlng?.lng || ""};
    setDefaultLatLon(coords);
    setOpenOdourReportModal(true);
  };

  const handleClosePopup = () => {
    setSelectedReport(null);
  };

  const handleConfirm = (newReport) => {
    setOpenOdourReportModal(false);
    setGlobalSuccess(true, handleTranslate('INSERT_ODOUR'));
    // updateOdourReportsFromServer();
  };

  const handleDownloadFile = async (fileType) => {
    const fileData = await getOdourFile(timeRangeInView.startDate, timeRangeInView.endDate, odourTypeSelect.selected, odourLevelSelect.selected, fileType, fileName);
    return fileData;
  };

  const handleDeleteReport = (selectedRows) => {
    selectedRows.forEach((e) => {
      deleteOdourReport(reports[e]._id).then((id) => {
        setReports((prev) => prev.toSpliced(e, 1));
      })
    });
    reloadTimeInterval();
  };

  const reloadTimeInterval = () => {
    const dates = calcIntervalDates(timeRange[timeRangeInView.selected].timeRange);
    setTimeRangeFilter(viewName, timeRangeInView.id, timeRangeInView.selected, dates.start, dates.end);
  };


  return (
    <Box padding={{xs: "1rem", sm: "2rem 5rem"}}>
      <OdorReportsHeader startDate={startDate} endDate={endDate} sliderStart={timeRangeStartDate} sliderEnd={timeRangeEndDate}/>
      {updatingReports && <div style={{display: "flex", justifyContent: "center", alignItems: "center", width: "100%", height: "60vh", borderWidth: 1, borderColor: "black", borderColor: "red"}}> <LoadingGif/></div>}
      {!updatingReports &&
        <>
          <OdorReportsMap enabledActions={enabledActions} reports={reports} mapCenter={mapCenter} selectedMarker={selectedReport} onAddMarker={handleAddMarker} onClosePopup={handleClosePopup}/>
          <Grid container spacing={1}>
        <Grid item xs={12} lg={12}>
          <OdorReportsGraphs sliderStart={timeRangeStartDate} sliderEnd={timeRangeEndDate}/>
        </Grid>
        <Grid item xs={12} lg={12}>
        {loadingReports ? 
        <LoadingGif />
        :
        <OdorReportsTable userActions={userActions} reports={reports} onClickRow={handleClickTableRow} downloadFileProps={{handleDownloadFile: handleDownloadFile, fileName:fileName}} onDeleteReport={handleDeleteReport}/>
        }
        </Grid>
          </Grid>
        </>
      }
      <AddOdourReportModal isOpen={openOdourReportModal} defaultLatLon={defaultLatLon} onConfirm={handleConfirm} onClose={() => setOpenOdourReportModal(false)}/>
    </Box>
  );
};

export default OdorReportsContent;
