import React, { useCallback, useMemo } from 'react'
import { useSelector } from 'react-redux';
import RecordingElement from './RecordingElement';
import LoadingGif from '../SharingComponents/LoadingGif';
import NoDataMessage from '../SharingComponents/NoDataMessage';

const RecordingList = ({actions, list, selectedRecording, onPlayRecord, onStopRecord, onExportRecord, onDeleteRecord}) => {
  const loadingRecord = useSelector((state) => state.multimedia.loadingRecord);
  const loadingExport = useSelector((state) => state.multimedia.loadingRecordExport);
  const loadingDelete = useSelector((state) => state.multimedia.loadingRecordDelete);

  const isDownloadHide = useMemo(() => {
    if(actions.find((element) => element.name === "download-media")) return false;
    return true;
  }, [actions])

  const isDeleteHide = useMemo(() => {
    if(actions.find((element) => element.name === "delete-media")) return false;
    return true;
  }, [actions])

  const isLoading = useCallback((recording) => {
    if(!loadingRecord) return false;
    else if(recording.id === loadingRecord) return true;
  }, [loadingRecord])

  const isLoadingExport = useCallback((recording) => {
    if(!loadingExport) return false;
    else if(recording.id === loadingExport) return true;
  }, [loadingExport])

  const isLoadingDelete = useCallback((recording) => {
    if(!loadingDelete) return false;
    else if(recording.id === loadingDelete) return true;
  }, [loadingDelete])

  const isDisabled = useCallback((recording) => {
    if(!loadingRecord && !loadingExport) return false;
    else if(isLoading(recording) || isLoadingExport(recording)) return false;
    return true;
  }, [isLoading, isLoadingExport]);

  const isPlayDisabled = useCallback((recording) => {
    if(recording.recordingstatus === "pending" || isLoadingExport(recording) || isLoadingDelete(recording)) return true;
    return false;
  }, [isLoadingExport, loadingDelete]);

  const isExportDisabled = useCallback((recording) => {
    if(isLoading(recording) || isLoadingDelete(recording)) return true;
    return false;
  }, [isLoading, isLoadingDelete]);

  const isDeleteDisabled = useCallback((recording) => {
    if(isLoading(recording) || isLoadingExport(recording)) return true;
    return false;
  }, [isLoading, isLoadingExport])

  const isPlayinng = useCallback((recording) => {
    if(recording.id === selectedRecording?.id || recording.recordingstatus === "recording") return true;
    else return false;
  }, [selectedRecording])

  const isHideButtons = useCallback((recording) => {
    const hasRecording = actions.some((element) => element.name === "recording")
    if (!hasRecording) return  false;
    if(recording.recordingstatus !== "completed") return true;
    if(isDownloadHide && isDeleteHide) return true;
    return false;
  }, [selectedRecording, isDownloadHide, isDeleteHide]);


  return (
    <div>
      {list.map((recording, index) => (
        <RecordingElement key={recording.id} hideDownload={isDownloadHide} hideDelete={isDeleteHide} hideButtons={isHideButtons(recording)} disabled={isDisabled(recording)} disabledPlay={isPlayDisabled(recording)} disabledExport={isExportDisabled(recording)} disabledDelete={isDeleteDisabled(recording)} 
        recording={recording} isLoading={isLoading(recording)} isLoadingExport={isLoadingExport(recording)} isLoadingDelete={isLoadingDelete(recording)} isPlaying={isPlayinng(recording)} 
        onPlay={onPlayRecord} onStop={onStopRecord} onExport={onExportRecord} onDelete={onDeleteRecord}/>
      ))}
    </div>
  );
};


const SingleMultimediaVideoList = ({actions, list, selected, onPlayRecord, onStopRecord, onExportRecord, onDeleteRecord}) => {
  const loadingRecordingList = useSelector((state) => state.multimedia.loadingRecordingList);
  return loadingRecordingList || !list ? (
    <LoadingGif />
  ): (
    <div style={{fontWeight: "normal"}}>
      {
      list.length > 0 ? 
        <RecordingList actions={actions} list={list} selectedRecording={selected} onPlayRecord={onPlayRecord} onStopRecord={onStopRecord} onExportRecord={onExportRecord} onDeleteRecord={onDeleteRecord}/>
        : <NoDataMessage />
      }
    </div>
  )
}

export default SingleMultimediaVideoList