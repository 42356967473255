import { useEffect, useState } from "react";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import useMobile from "src/app/hooks/useMobile";
const styles = {
  menu: {
    position: "absolute",
    zIndex: "1000",
    boxShadow: "0 0 5px rgba(0, 0, 0, 0.3)",
    top: 0,
  },
  menuContainer: {
    background: "rgba(255, 255, 255, 0.4)",
    backdropFilter: "blur(0.5rem)",
    height: "100%",
    overflowX: "hidden",
  },
  menuButton: {
    position: "absolute",
    top: 0,
    background: "white",
    height: "fit-content",
    padding: ".5rem",
  },
};

const MenuButton = ({
  isOpen,
  rightPos,
  openIcon,
  closeIcon,
  setIsOpen,
}) => {
  const [xs] = useMobile();

  return rightPos ? (
    <button
      style={{
        ...styles.menuButton,
        left: 0,
        transform: "translateX(-100%)",
        borderRadius: "1rem 0 0 1rem",
        display: isOpen && !xs ? "none" : "block",
      }}
      onMouseEnter={() => !xs && setIsOpen(true)}
      onClick={() => xs && setIsOpen(!isOpen)}
    >
      {!isOpen ? openIcon : closeIcon}
    </button>
  ) : (
    <button
      style={{
        ...styles.menuButton,
        right: 0,
        transform: "translateX(100%)",
        borderRadius: "0 1rem 1rem 0",
      }}
      onMouseEnter={() => !xs && setIsOpen(true)}
      onClick={() => xs && setIsOpen(true)}
    >
      {isOpen ? openIcon : closeIcon}
    </button>
  );
};

const Menu = ({
  style,
  menuButtonStyle,
  children,
  open,
  defaultOpen = true,
  width = "250px",
  height = "80vh",
  animated = true,
  rightPos = false,
  openIcon = <KeyboardArrowLeftIcon />,
  closeIcon = <KeyboardArrowRightIcon />,
}) => {
  const [isOpen, setIsOpen] = useState(defaultOpen);
  const [isHovered, setIsHovered] = useState(false);
  const [xs] = useMobile();
  useEffect(() => {
    open !== undefined && setIsOpen(open);
  }, [open]);

  useEffect(() => {
    let timeout;
    if (!isHovered) {
      timeout = setTimeout(() => {
        setIsOpen(false);
      }, 200);
    }
    return () => clearTimeout(timeout);
  }, [isHovered]);

  return (
    <div
      style={{
        ...styles.menu,
        ...style,
        width: isOpen ? width : "0px",
        height: height,
        right: rightPos && 0,
        transition: animated && "all 0.2s ease",
      }}
      onMouseEnter={() => !xs && setIsHovered(true)}
      onMouseLeave={() => !xs && setIsHovered(false)}
    >
      <div style={{ position: "relative", width: "100%", height: "100%", zIndex: 1000 }}>
        <div
          style={{
            ...styles.menuContainer,
            borderRadius: rightPos ? "0 1rem 1rem 1rem" : "1rem 0 1rem 1rem",
            
          }}
          onMouseLeave={() => !xs && setIsOpen(false)}
        >
          {children}
        </div>
        <MenuButton
          isHovered={isHovered}
          rightPos={rightPos}
          isOpen={isOpen}
          setIsOpen={setIsOpen}
          openIcon={openIcon}
          closeIcon={closeIcon}
        />

        {isOpen && xs && (
          <div
            onClick={() => setIsOpen(false)}
            style={{
              position: "fixed",
              top: 0,
              left: 0,
              width: "100vw",
              height: "100vh",
              backgroundColor: "rgba(0, 0, 0, 0.3)",
              zIndex: -2,
            }}
          ></div>
        )}
      </div>
    </div>
  );

  return (
    <div style={{ display: "flex", height: "100%" }}>
      <div
        style={{
          ...style,
          background: "white",
          height: "100%",
          width: isOpen ? width : "0px",
          transition: animated && "all 0.2s ease",
          overflowX: "hidden",
          borderRadius: "1rem",
        }}
      >
        <div style={{ height: "100%", width: width }}>{children}</div>
      </div>
      <div
        style={{
          ...menuButtonStyle,
          background: "white",
          marginTop: "1rem",
          height: "fit-content",
          borderRadius: "0 1rem 1rem 0",
        }}
      >
        <button
          style={{ padding: ".5rem" }}
          onClick={() => setIsOpen((prev) => !prev)}
        >
          {isOpen ? <KeyboardArrowLeftIcon /> : <KeyboardArrowRightIcon />}
        </button>
      </div>
    </div>
  );
};

export default Menu;
