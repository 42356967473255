import { Checkbox, FormControl, InputLabel, MenuItem, Select } from '@mui/material'
import React, { useLayoutEffect, useMemo, useState } from 'react'
import { useSelector } from 'react-redux';
import { inputsComponentsNames } from 'app/configs/routesNames';
import { useTranslation } from 'react-i18next';
import { translate } from "src/utilities/utils";

const AlertCategorySelect = ({style, alertCategoriesId, defaultValue, disabled=false, value, onChange, required=false, multiple=false}) => {
  const { t } = useTranslation("evocs");
  const handleTranslate = (textId, general = false) => {
    if (general) return translate(t, textId);
    return translate(t, textId, inputsComponentsNames.INPUTS);
  };
  const alertsCategory = useSelector((state) => state.configuration.constants?.categoryAlert);
  const options = useMemo(() => {
    if(alertCategoriesId) {
      return alertCategoriesId;
    }else {
      return Object.keys(alertsCategory);
    }
  }, [alertCategoriesId]);
  const [val, setValue] = useState(defaultValue || multiple ? [] : "")

  useLayoutEffect(() => {
    value !== undefined && setValue(value);
  }, [value]);

  const handleChange = (newValue) => {
    setValue(newValue);
    onChange && onChange(newValue)
  };

  return(
    <FormControl disabled={disabled} sx={{...style, width: "100%", minWidth: "100px", maxWidth: "300px"}}>
        <InputLabel id="alert-category-select-label">{required ? `${handleTranslate("NOTIFICATION_CATEGORY")}*` : handleTranslate("NOTIFICATION_CATEGORY")}</InputLabel>
        <Select
          labelId="alert-category-select-label"
          value={val}
          multiple={multiple}
          renderValue={(selected) => multiple ? selected.map((categoryId) => alertsCategory[categoryId]?.categoryAlert).join(", ") : alertsCategory[selected]?.categoryAlert}
          label={required ? `${handleTranslate("NOTIFICATION_CATEGORY")}*` : handleTranslate("NOTIFICATION_CATEGORY")}
          onChange={(e) => handleChange(e.target.value)}
        >
          {options?.map((categoryId, index) => (
          <MenuItem key={index} value={categoryId}>
            {multiple && <Checkbox checked={val?.indexOf(categoryId) > -1} /> }
            {alertsCategory[categoryId]?.categoryAlert}
          </MenuItem>
          ))}
        </Select>
    </FormControl>     
  )
}

export default AlertCategorySelect