import { getApiTimestamp } from "src/utilities/utils";
import store from "../..";
import { cuSliceActions } from "../controlUnits/cuSlice";
import { sensorsSliceActions } from "../sensors/sensorSlice";
import { stationsMongoSliceActions } from "./stationsMongoSlice";
import {
  getCuMeasures,
  setCusLoading,
  setCuState,
} from "../controlUnits/cuActions";
import {
  getSensorsMeasures,
  isSensorGps,
  isSensorMultimedia,
  setSensorsLoading,
  setSensorState,
} from "../sensors/sensorsActions";
import { th } from "date-fns/locale";

export const setDevicesLoading = (loading = true) => {
  setStationsLoading(loading);
  setCusLoading(loading);
  setSensorsLoading(loading);
};

export const setStationsLoading = (loading = true) => {
  store.dispatch(stationsMongoSliceActions.setLoading(loading));
};

export const getStationInfo = async (tenantIds) => {
  const data = store
    .dispatch(stationsMongoSliceActions.getStationInfo(tenantIds))
    .then(({ payload, error }) => {
      if (error) throw error;
      return payload;
    });
  return data;
};

export const createStation = async (
  serial,
  owner,
  stationType = [],
  desc = null,
  project = null,
  listCu = null,
  listSensors = null,
  baseLocationId = null,
  newLocation = null,
  available = false
) => {
  const user = store.getState().user;
  const setStore = user.selectedTenant === owner;
  const availableNumber = available ? 1 : 0;
  const data = await store
    .dispatch(
      stationsMongoSliceActions.createStation({
        serial,
        owner,
        stationType,
        desc,
        project,
        listCu,
        listSensors,
        baseLocationId,
        newLocation,
        available: availableNumber,
        setStore,
      })
    )
    .then(({ payload, error }) => {
      if (error) throw error;
      return payload;
    });
  if (setStore) {
    listCu?.forEach((cuId) => {
      store.dispatch(
        cuSliceActions.installOnStation({
          cuId,
          device: data.stationId,
          start: Math.floor(new Date() / 1000),
        })
      );
    });
    listSensors?.forEach((sensorId) => {
      store.dispatch(
        sensorsSliceActions.installOnDevice({
          sensorId,
          device: data.stationId,
          type: "station",
          start: Math.floor(new Date() / 1000),
        })
      );
    });
  }
  return data;
};

export const updateStation = async (
  stationId,
  serial = undefined,
  owner = undefined,
  available = undefined,
  stationType = undefined,
  desc = undefined,
  project = undefined,
  listSensors = undefined,
  listCu = undefined,
  baseLocationId = undefined
) => {
  const availableNumber =
    available === undefined ? undefined : available ? 1 : 0;
  const startDate = getApiTimestamp(new Date());
  const data = await store
    .dispatch(
      stationsMongoSliceActions.updateStation({
        stationId,
        serial,
        owner,
        available: availableNumber,
        stationType,
        desc,
        project,
        listSensors,
        listCu,
        baseLocationId,
        operationTime: startDate,
      })
    )
    .then(({ payload, error }) => {
      if (error) throw error;

      return payload;
    });

  data[stationId]?.forEach((element) => {
    const deviceId = element._id;
    const typeDevice = element.typeDevice;
    delete element._id;
    delete element.typeDevice;
    switch (typeDevice) {
      case "station":
        setStationState(deviceId, element);
        break;
      case "cu":
        setCuState(deviceId, element);
        break;
      case "sensor":
        setSensorState(deviceId, element);
        break;
    }
  });

  if (available !== undefined) {
    if (available)
      store.dispatch(stationsMongoSliceActions.activateStation(stationId));
    else store.dispatch(stationsMongoSliceActions.deactivateStation(stationId));
  }

  return data;
};

export const setStationState = (stationId, state) => {
  store.dispatch(
    stationsMongoSliceActions.setStationState({ id: stationId, station: state })
  );
};

// export const updateStation = async(stationId, serial=undefined, owner=undefined, available=undefined, mobile=undefined, stationType=undefined, desc=undefined, project=undefined, listSensors=undefined, listCu=undefined, baseLocationId=undefined) => {
//     const stations = store.getState().stationsMongo.list;
//     const mobileNumber = mobile === undefined ? undefined: (mobile ? 1 : 0);
//     const availableNumber = available === undefined ? undefined : (available ? 1 : 0);
//     const startDate = getApiTimestamp(new Date());
//     const data = await store.dispatch(stationsMongoSliceActions.updateStation({stationId, serial, owner, available: availableNumber, mobile: mobileNumber, stationType, desc, project, listSensors, listCu, baseLocationId, operationTime: startDate})).then(({payload, error}) => {
//         if(error) throw error;

//         return payload;
//     });

//     if(available !== undefined) {
//         const devices = data[stationId];
//         devices.forEach((element) => {
//             const deviceId = element._id;
//             const typeDevice = element.typeDevice;
//             delete element._id; delete element.typeDevice;
//             switch(typeDevice) {
//                 case "station":
//                     activateStation(deviceId, available);
//                     break;
//                 case "cu":
//                     activateCu(deviceId, available);
//                     break;
//                 case "sensor":
//                     activateSensor(deviceId, available);
//                     break;
//             }
//         });
//     }
//     if(listCu) {
//         const uninstalled = stations[stationId].list_cu.filter((cuId) => !listCu.includes(cuId));
//         store.dispatch(stationsMongoSliceActions.setListCu({stationId, listCu}));
//         listCu.forEach((cuId) => {
//             store.dispatch(cuSliceActions.installOnStation({cuId, device: stationId, start: startDate}))
//         })
//         uninstalled.forEach((cuId) => {
//             store.dispatch(cuSliceActions.uninstallFromParent(cuId))
//         })
//     }
//     if(listSensors) {
//         const uninstalled = stations[stationId].list_sensors.filter((sensorId) => !listSensors.includes(sensorId));
//         store.dispatch(stationsMongoSliceActions.setListSensor({stationId, listSensors}));
//         listSensors.forEach((sensorId) => {
//             store.dispatch(sensorsSliceActions.installOnDevice({sensorId, device: stationId, type: "station", start: startDate}))
//         })
//         uninstalled.forEach((sensorId) => {
//             store.dispatch(sensorsSliceActions.uninstallFromParent(sensorId))
//         })
//     }

//     return data;
// };

export const activateStation = (stationId, active = true) => {
  if (active)
    store.dispatch(stationsMongoSliceActions.activateStation(stationId));
  else store.dispatch(stationsMongoSliceActions.deactivateStation(stationId));
};

export const deleteStation = async (stationId) => {
  const data = store
    .dispatch(stationsMongoSliceActions.deleteStation(stationId))
    .then(({ payload, error }) => {
      if (error) throw error;

      return true;
    });
  return data;
};

export const getStationHistory = async (stationId) => {
  const deviceDict = { [stationId]: "station" };
  const data = await store
    .dispatch(stationsMongoSliceActions.getHistory(deviceDict))
    .then(({ payload, error }) => {
      if (error) throw error;
      return payload;
    });

  return data;
};

export const updateStationHistory = async (
  stationId,
  oldTimeList,
  updateTimeList,
  listDeleted,
  listAdded
) => {
  if (!stationId) throw new Error("stationId is required");
  const data = await store
    .dispatch(
      stationsMongoSliceActions.updateHistory({
        stationId,
        oldTimeList,
        updateTimeList,
        listDeleted,
        listAdded,
      })
    )
    .then(({ payload, error }) => {
      if (error) throw error;
      return payload;
    });

  return data;
};

export const downloadStationHistory = async (stationId, form = "csv") => {
  const data = await store
    .dispatch(stationsMongoSliceActions.downloadHistory({ stationId, form }))
    .then(({ payload, error }) => {
      if (error) throw error;
      return payload;
    });

  return data;
};

export const moveDevice = async (
  deviceId,
  deviceType,
  sourcType,
  destinationId,
  destinationType
) => {
  const data = await store
    .dispatch(
      stationsMongoSliceActions.moveDevice({
        deviceId: deviceId,
        deviceType: deviceType,
        sourcType: sourcType,
        destinationId: destinationId,
        destinationType: destinationType,
      })
    )
    .then(({ payload, error }) => {
      if (error) return [];
      return payload;
    });

  return data;
};

export const getStationBySensor = (sensorId) => {
  if (!sensorId) throw new Error("sensorId is required");
  const stations = store.getState().stationsMongo.list;
  if (!stations) return null;
  return Object.keys(stations).find((stationId) =>
    getStationSensors(stationId).includes(sensorId)
  );
};

export const getStationSensors = (stationId) => {
  if (!stationId) throw new Error("stationId is required");
  const stations = store.getState().stationsMongo.list;
  const controlUnits = store.getState().controlUnits.list;
  if (Array.isArray(stationId)) {
    const sensorsList = [];
    stationId.forEach((id) => {
      stations[id]?.list_sensors.forEach((sensorId) =>
        sensorsList.push(sensorId)
      );
      stations[id]?.list_cu.forEach((cuId) => {
        controlUnits[cuId]?.list_sensors.forEach((sensorId) =>
          sensorsList.push(sensorId)
        );
      });
    });
    return sensorsList;
  } else {
    const sensorsList = [...stations[stationId]?.list_sensors];
    stations[stationId]?.list_cu.forEach((cuId) => {
      controlUnits[cuId]?.list_sensors.forEach((sensorId) =>
        sensorsList.push(sensorId)
      );
    });
    return sensorsList;
  }
};

export const getStationMeasures = (stationId, onlyGraphMeasures = false) => {
  if (!stationId) throw new Error("stationId is required");
  const stations = store.getState().stationsMongo.list;

  if (Array.isArray(stationId)) {
    const sensorsMeasures = [
      ...new Set(
        stationId
          .map((id) =>
            getSensorsMeasures(stations[id].list_sensors, onlyGraphMeasures)
          )
          .flat()
      ),
    ];
    const cusMeasures = [
      ...new Set(
        stationId
          .map((id) => getCuMeasures(stations[id].list_cu, onlyGraphMeasures))
          .flat()
      ),
    ];
    return [...new Set(sensorsMeasures.concat(cusMeasures))];
  } else {
    const sensorsMeasures = getSensorsMeasures(
      stations[stationId].list_sensors,
      onlyGraphMeasures
    );
    const cusMeasures = getCuMeasures(
      stations[stationId].list_cu,
      onlyGraphMeasures
    );
    return [...new Set(sensorsMeasures.concat(cusMeasures))];
  }
};

export const hasStationGps = (stationId) => {
  if (!stationId) throw new Error("stationId is required");
  return getStationSensors(stationId).some((sensorId) => isSensorGps(sensorId));
};

const hasEcoMeasures = (sensorId) => {
  const sensors = store.getState().sensors.list;
  const ecoMeasuresIds = ["6554a8f2af4b91cdd6da328c", "65548aabaf4b91cdd6da321f", "65ba16a651a89dd6f490a1bb"]
  const measures = sensors[sensorId]?.measures;
  return ecoMeasuresIds.some(element => measures.includes(element));
}

export const hasEcoPath = (stationId) => {
  if (!stationId) throw new Error("stationId is required");
  return getStationSensors(stationId).some((sensorId) => hasEcoMeasures(sensorId));
};

export const hasStationMultimedia = (stationId) => {
  if (!stationId) throw new Error("stationId is required");
  return getStationSensors(stationId).some((sensorId) =>
    isSensorMultimedia(sensorId)
  );
};

export const getStationsThreshold = async (tenantId, stationIds = []) => {
  const data = await store
    .dispatch(stationsMongoSliceActions.getThreshold({ tenantId, stationIds }))
    .then(({ payload, error }) => {
      if (error) throw error;
      return payload;
    });
  return data;
};

export const shareSingleStation = async (stationId, tenantList) => {
  if (!stationId || !tenantList) throw new Error("Missing parameters");
  let dictShare = {};
  if (Array.isArray(stationId)) {
    stationId.forEach((id) => {
      dictShare[id] = tenantList;
    });
  } else {
    dictShare[stationId] = tenantList;
  }
  const data = await store
    .dispatch(stationsMongoSliceActions.createShareStation(dictShare))
    .then(({ payload, error }) => {
      if (error) throw error;
      return payload;
    });
  const stations = [],
    cus = [],
    sensors = [];
  data?.forEach((element) => {
    const newElement = { ...element };
    const elementId = newElement._id;
    delete newElement._id;
    delete newElement.typeDevice;
    switch (element.typeDevice) {
      case "station":
        stations.push({ id: elementId, station: newElement });
        break;
      case "cu":
        cus.push({ id: elementId, cu: newElement });
        break;
      case "sensor":
        sensors.push({ id: elementId, sensor: newElement });
        break;
    }
  });
  if (stations.length > 0)
    stations.forEach((element) =>
      store.dispatch(stationsMongoSliceActions.setStationState(element))
    );
  if (cus.length > 0)
    cus.forEach((element) =>
      store.dispatch(cuSliceActions.setCuState(element))
    );
  if (sensors.length > 0)
    sensors.forEach((element) =>
      store.dispatch(sensorsSliceActions.setSensorState(element))
    );
  return data;
};

export const updateShareStation = async (stationId, tenantsList) => {
  const data = await store
    .dispatch(
      stationsMongoSliceActions.updateShareStation({ stationId, tenantsList })
    )
    .then(({ payload, error }) => {
      if (error) throw error;
      return payload;
    });
  const stations = [],
    cus = [],
    sensors = [];
  data?.forEach((element) => {
    const newElement = { ...element };
    const elementId = newElement._id;
    delete newElement._id;
    delete newElement.typeDevice;
    switch (element.typeDevice) {
      case "station":
        stations.push({ id: elementId, station: newElement });
        break;
      case "cu":
        cus.push({ id: elementId, cu: newElement });
        break;
      case "sensor":
        sensors.push({ id: elementId, sensor: newElement });
        break;
    }
  });
  if (stations.length > 0)
    stations.forEach((element) =>
      store.dispatch(stationsMongoSliceActions.setStationState(element))
    );
  if (cus.length > 0)
    cus.forEach((element) =>
      store.dispatch(cuSliceActions.setCuState(element))
    );
  if (sensors.length > 0)
    sensors.forEach((element) =>
      store.dispatch(sensorsSliceActions.setSensorState(element))
    );
  return data;
};

// interval is int minutes
export const getLastStationDetections = async (
  stationIds,
  tenantId,
  interval = undefined
) => {
  const data = await store
    .dispatch(
      stationsMongoSliceActions.lastDetectionStation({
        stationIds,
        tenantId,
        interval,
      })
    )
    .then(({ payload, error }) => {
      if (error) throw error;
      return payload;
    });
  return data;
};

export const getLastStationPosition = async (
  stationId,
  startDate,
  endDate,
  stationType = null
) => {
  if (!stationId && stationType === null) throw new Error("stationId or stationType is required");
  if (!stationId && stationType) {
    return await store.dispatch(
        sensorsSliceActions.readLastSensorsPosition({
          sensorIds: null,
          startDate: getApiTimestamp(startDate),
          endDate: getApiTimestamp(endDate),
          stationType: stationType,
        })
      ).then(({ payload, error }) => {
        if (error) throw error;
        return payload;
      });
  } else {
    const stations = store.getState().stationsMongo.list;
    const baseLocations = store.getState().configuration.constants?.base_location;
    const locations = [],gpsOnStations = [];
    if (!Array.isArray(stationId)) {
      const gpsIds = [];
      let base_location;
      try{
        base_location = baseLocations[stations[stationId].base_location];
        base_location && locations.push({ stationId, baseLocation: base_location });
      }catch(e){
        console.log('Missing base_location for station stationId', stationId);
      }
      getStationSensors(stationId).forEach((sensorId) => {
        if(isSensorGps(sensorId)) gpsIds.push(sensorId);
      });
      if(gpsIds?.length > 0 ?? 0)
        gpsOnStations.push({ stationId, sensorIds: gpsIds });
    } else {
      stationId.forEach((stationId) => {
        const gpsIds = [];
        let base_location;
        try{
          base_location = baseLocations[stations[stationId].base_location];
          base_location && locations.push({ stationId, baseLocation: base_location });
        }catch(e){
          console.log('Missing base_location for station stationId', stationId);
        }
        getStationSensors(stationId).forEach((sensorId) => {
          if(isSensorGps(sensorId)) gpsIds.push(sensorId);
        });
        if(gpsIds?.length > 0 ?? 0)
          gpsOnStations.push({ stationId, sensorIds: gpsIds });
      });
    }

    if (gpsOnStations?.length === 0) return locations;
    await store.dispatch(
        sensorsSliceActions.readLastSensorsPosition({
          sensorIds: gpsOnStations.flatMap(station => station.sensorIds),
          startDate: getApiTimestamp(startDate),
          endDate: getApiTimestamp(endDate),
          stationType: stationType,
        })
      ).then(({ payload, error }) => {
        if (error) throw error;
        payload.forEach((location) => {
          try {
            if (!location || !location.lat || !location.lon) return;
            const locationStationId = gpsOnStations.find((element) => element.sensorIds.includes(location.sensorId)).stationId;
            if(Object.keys(locations).includes(locationStationId)) throw new Error("Impossible to find location for station " + locationStationId);
            const locationIndex = locations.findIndex((element) => element.stationId === locationStationId);
            const firstGpsLocation = (locationIndex === -1 || !Object.keys(locations[locationIndex]).includes("gpsLocations")); 
            if(firstGpsLocation) {
              if(locationIndex === -1)
                locations.push({ stationId: locationStationId, gpsLocations: [location]});
              else
                locations[locationIndex].gpsLocations = [location];
            } else {
              let newElement = locations[locationIndex];
              newElement.gpsLocations.push(location);
              locations[locationIndex] = newElement;
            }
          } catch (error) {
            throw error;
          }
        });
      });
    return locations; }
};

export const getStationLocation = (stationId) => {
  const stations = store.getState().stationsMongo.list;
  const baseLocations = store.getState().configuration.constants.base_location;
  if (!stationId || !stations || !baseLocations) return [];
  if (Array.isArray(stationId)) {
    const locations = [];
    stationId.forEach((id) => {
      if (baseLocations[stations[id].base_location])
        locations.push({
          id: stations[id].base_location,
          stationId: id,
          ...baseLocations[stations[id].base_location],
        });
    });
    return locations;
  } else {
  }
};

export const findStationRoute = async (
  stationIds,
  startDate,
  endDate,
  minDistance = 15
) => {
  const data = await store
    .dispatch(
      stationsMongoSliceActions.findStationRoute({
        stationIds,
        startDate: getApiTimestamp(startDate),
        endDate: getApiTimestamp(endDate),
        minDistance,
      })
    )
    .then(({ payload, error }) => {
      if (error) throw error;
      return payload;
    });
  return data;
};

export const getStationEcoPath = async(stationId, startDate, endDate, minDistance=15) => {
    const data = await store.dispatch(stationsMongoSliceActions.getStationEcoPath({stationId, startDate: getApiTimestamp(startDate), endDate: getApiTimestamp(endDate), minDistance})).then(({payload, error}) => {
        if(error) throw error;
        return payload;
    }); 
    return data;
};

export const convertStationEcoPathToColor = (ecoPath) => {
    if(!ecoPath) throw new Error("ecoPath is required.");
    const newPath = [];
    let lastColor = "";
    let lastIndex = -1;
    ecoPath.forEach((element) => {
      const aqValue = element[3];
      let color = "";
      if(aqValue >= 0 && aqValue <= 50) {
        color = "green";
      }else if(aqValue > 50 && aqValue <= 100) {
        color = "yellow";
      }else if(aqValue > 100 && aqValue <= 150) {
        color = "orange";
      }else if(aqValue > 150 && aqValue <= 200) {
        color = "red";
      }else if(aqValue > 200 && aqValue <= 300) {
        color = "purple";
      }else if(aqValue > 300) {
        color = "brown";
      }
      if(color !== lastColor) {
        const lastElementIndex = newPath[lastIndex]?.length ? newPath[lastIndex]?.length-1 : null;
        newPath.push([]);
        lastIndex++;
        lastColor = color;
        if(lastElementIndex !== null) {
          let lastElement = {...newPath[lastIndex-1][lastElementIndex]};
          lastElement[3] = color;
          newPath[lastIndex].push(lastElement);
        }
      }
      const newElement = [element[0], element[1], element[2], color];
      newPath[lastIndex].push(newElement);
    });
    return newPath;
};

export const getPolarPlots = async(startDate, endDate) => {
    const data = await store.dispatch(stationsMongoSliceActions.getPolarPlots({startDate: getApiTimestamp(startDate), endDate: getApiTimestamp(endDate)})).then(({payload, error}) => {
      if(error) throw error;
        return payload;
    }); 
    return data;
};


export const getR2BySensors = async (dictSensors, startDate, endDate) => {
  const data = await store
    .dispatch(
      stationsMongoSliceActions.calculateR2Sensors({
        dictSensors,
        startDate: getApiTimestamp(startDate),
        endDate: getApiTimestamp(endDate),
      })
    )
    .then(({ payload, error }) => {
      if (error) throw error;
      return payload;
    });
  return data;
};

export const getDeprecatedStations = async () => {
  const data = await store
    .dispatch(stationsMongoSliceActions.getDeprecatedStations())
    .then(({ payload, error }) => {
      if (error) throw error;
      return payload;
    });
  return data;
};

export const deleteDeprecatedStations = async (selectedIds) => {
  const data = await store
    .dispatch(stationsMongoSliceActions.deleteDeprecatedStations(selectedIds))
    .then(({ payload, error }) => {
      if (error) throw error;
      return payload;
    });
  return data;
};

export const restoreDeprecatedStations = async (selectedIds) =>{
  const data = await store
  .dispatch(stationsMongoSliceActions.restoreDeprecatedStations(selectedIds))
  .then(({payload, error})=>{
    if(error) throw error
    return payload
  })
  return data
}
