import React from "react";
import { ToastContainer, toast, Flip } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { FaInfoCircle } from "react-icons/fa";

const showFirebaseNotification = (
  title, 
  body, 
  image,
  popupStyle = {},
  progressIconColor = null,
  type = "info"
) => {
  console.log('showFirebaseNotification', title, body, image, popupStyle, progressIconColor, type);
  toast[type](
    <div className="grid grid-cols-2 gap-2"> 
      {/* Colonna 1: Titolo e Messaggio (entrambi allineati a sinistra) */}
      <div className="text-left"> {/* Allineamento a sinistra per il testo */}
        <div className="text-xl font-bold">{title}</div> {/* Titolo */}
        <p className="m-0">{body}</p> {/* Messaggio */}
      </div>

      {/* Colonna 2: Immagine (opzionale, allineata a destra) */}
      {image && (
        <div className="flex justify-end"> {/* Centra l'immagine a destra */}
          <img
            src={`data:image/png;base64,${image}`} // Mostra l'immagine se fornita
            alt="notification"
            className="w-40 h-40 rounded-lg object-cover" // Tailwind classi per dimensioni e bordo
          />
        </div>
      )}
    </div>,
    {
      position: "top-right",
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "dark",
      transition: Flip,
      icon: false, // 🔥 Disabilita l'icona predefinita
      style: {
        ...popupStyle,
      },
      progressStyle: {
        background: progressIconColor,
      }
    }
  );
};



export { showFirebaseNotification };

const FirebaseNotification = () => {
  return <ToastContainer />;
};

export default FirebaseNotification;
