import React, { useEffect, useState } from "react";
import {
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  ListSubheader,
  Chip,
} from "@mui/material";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { translate } from "src/utilities/utils";

// groups è un array costituito da N oggetti. Gli oggetti possono essere di due tipi
// e si differenziano a senconda che la measureId che verrà visualizzata nella select
// sia una measure appartenente ad un sensore direttamente montato su una station oppure
// sia un measure appartenente ad un sensore di una centralina.
// In generale però entrambe le tipologie di oggetti hanno la stessa struttura e si differenziano
// in base ai valori. In particolare ogni oggetto ha un id, un title ed un campo options.
// L'id in caso di sensori direttamente installati sulla stazione rappresenta il sensorId del sensore
// mentre in caso di centralina, rappresenta il cuId della centralina.
// il title rappresenta l'etichetta che verrà visualizzata nella select mentre options è un array di oggetti.
// In particolare ogni oggetto rappresenta una misura che appartiene allo specifico sensore nel caso in cui
// il sensore sia direttamente installato sulla stazione oppure da una centralina.
// Gli elementi dell'array options saranno gli elementi visualizzati e selezionabili nella selex.
// Ogni elemento dell'array options è costituito da tre campi
// - measureId: rappresenta l'id di quella misura
// - index: è un intero progressivo
// - groupId: rappresenta l'id del gruppo a cui appartiene quella misura  e sarà uguale ad un sensorId o ad un cuId
//   a senconda che quella misura appartenga o meno ad un sensore installato direttamente su una stazione
// - sensor: rappresenta l'oggetto che discrive il sensore a cui appartiene quella misura

const MultipleGroupSelect = ({
  groups,
  handleChangeCompareMeasure,
  maxSelectable = 2,
  disabledMeasureIds = [], // array di measureId da disabilitare
  selectedCompare,
  selectedGroupId,
  style = {},
}) => {
  
  const inputSelectedValues = (selectedCompare?.length>0 ?? 0) ? selectedCompare.map((e) => e.index) : [];
  const [selectedValues, setSelectedValues] = useState( inputSelectedValues )
  const measures = useSelector((state) => state.configuration.constants?.measure);
  const dataset = useSelector((state) => state.dataset?.list)
  const { t } = useTranslation("evocs");
  const handleTranslate = (textId, general = false) => {
    if (general) return translate(t, textId);
    return translate(t, textId, viewNames.ADMINISTRATION_VIEW);
  };

  const capitalizeFirstLetter = (text) => {
    if (!text) return "";
    return text.charAt(0).toUpperCase() + text.slice(1);
  };

  // Gestisce la selezione dell'elemento, aggiungendo fino a 2 opzioni.
  const handleMultiSelectChange = (event) => {
    let newSelectedValues = [...event.target.value];
    if (newSelectedValues.length > maxSelectable) {
      newSelectedValues = newSelectedValues.slice(1);
    }
    setSelectedValues(newSelectedValues);
    handleChangeCompareMeasure(newSelectedValues);
  };

  // Funzione per ottenere il nome della misura
  const getMeasureName = (measureId) => {
    return handleTranslate(
      `MEASURE_${measures[measureId]?.measure.toUpperCase()}`,
      true
    );
  };

  // Creiamo un array di elementi per il Select, includendo i ListSubheader per i gruppi
  const selectItems = groups.flatMap((group) => [
    <ListSubheader
      key={`group-${group?.id}`}
      className="font-bold text-xl text-green-300"
    >
      {capitalizeFirstLetter(group?.title)}
    </ListSubheader>,
    ...group.options.map((option) => (
      <MenuItem
        key={option?.index}
        value={option?.index}
        disabled={
          selectedGroupId &&
          option?.groupId === selectedGroupId &&
          disabledMeasureIds?.includes(option?.measureId) 
          || !dataset?.[option?.sensor?.sensorId]
        } // Disabilita l'opzione se la misura è quella confrontata o se la misura non ha dati
      >
        {getMeasureName(option?.measureId)}
        {!dataset?.[option?.sensor?.sensorId] && ' (' + handleTranslate('NO_DATA', true) +')'}
      </MenuItem>
    )),
  ]);

  // Funzione per deselezionare tutte le opzioni
  const deselectAll = () => {
    setSelectedValues([]); // Reset all selected values
    handleChangeCompareMeasure([]); // Chiamata alla funzione per gestire la deselezione
  };

  useEffect(() => {
    if (
      selectedCompare &&
      selectedCompare.length == 0 &&
      selectedValues &&
      selectedValues.length > 0
    )
      deselectAll();
  }, [selectedCompare]);


  return (
    <FormControl className="w-full max-w-[300px]" style={style}>
      {/* Label del selettore */}
      <InputLabel id="grouped-measure-select-label">
        {handleTranslate("PARAM", true)}
      </InputLabel>
      <Select
        labelId="grouped-measure-select-label"
        id="grouped-measure-select"
        multiple
        value={selectedValues}
        onChange={handleMultiSelectChange}
        renderValue={(selected) => (
          <div style={{ display: "flex", flexWrap: "wrap", gap: "4px" }}>
            {selected.map((value) => {
              const selectedIndexInGroups = Number(value);
              let foundElement = null;
              for (const group of groups) {
                foundElement = group.options.find(
                  (element) => element.index === selectedIndexInGroups
                );
                if (foundElement) {
                  break;
                }
              }
              if (foundElement) {
                return (
                  <Chip
                    key={value}
                    label={getMeasureName(foundElement.measureId)}
                    size="small"
                    color="primary"
                  />
                );
              }
              return null;
            })}
          </div>
        )}
      >
        {selectItems}
      </Select>
    </FormControl>
  );
};

export default MultipleGroupSelect;
