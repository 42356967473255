import { useCallback, useMemo } from "react";
import { Modal, Button, TextField, IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import LoadingGif from "../SharingComponents/LoadingGif";
import { updateStation } from "app/store/evocsSlice/stationsMongo/stationsMongoActions";
import AccordionComponent from "../SharingComponents/AccordionComponent";
import StationTypeSelect from "../SharingComponents/Inputs/StationTypeSelect";
import LocationInput from "../SharingComponents/Inputs/LocationInput";
import { createBaseLocation } from "app/store/configurationSlice/configurationActions";
import TabsComponent from "../SharingComponents/TabsComponent";
import CuSelect from "../SharingComponents/Inputs/CuSelect";
import SwitchComponent from "../SharingComponents/Inputs/SwitchComponent";
import TenantSelect from "../SharingComponents/Inputs/TenantSelect";
import SensorAutocomplete from "../SharingComponents/Inputs/SensorAutocomplete";
import EditIcon from "@mui/icons-material/Edit";
import StationHistoryChip from "./StationHistoryChip";
import { modalsComponentNames } from "app/configs/routesNames";
import { useTranslation } from "react-i18next";
import { translate } from "src/utilities/utils";
import {
  setGlobalError,
  setGlobalSuccess,
} from "app/store/evocsSlice/globalError/errorActions";
import FloatingButtons from "../SharingComponents/FloatingButtons";
import useMobile from "src/app/hooks/useMobile";

const styles = {
  containerStyle: {
    position: "absolute",
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    background: "#2d3740",
    padding: "1rem",
    borderRadius: "1rem",
    height: "80vh",
    width: "95%",
    maxWidth: "1200px",
  },
  loadingPopup: {
    position: "absolute",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    background: "rgba(255, 255, 255, .5)",
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
  },
};
const requiredInputs = ["serial", "owner"];

const ConfigurationChip = ({
  enabledActions,
  stationId,
  onPending,
  onFinally,
  onChangeInputs,
}) => {
  const { t } = useTranslation("evocs");
  const stations = useSelector((state) => state.stationsMongo.list);
  const controlUnits = useSelector((state) => state.controlUnits.list);
  const sensors = useSelector((state) => state.sensors.list);
  const [sm] = useMobile()

  const [disableEdit, setDisableEdit] = useState(true);
  const [disableConfirm, setDisableConfirm] = useState(true);
  const [changedInputs, setChangedInputs] = useState([]);
  const userTenantId = useSelector((state) => state.user.tenantId);
  const getDefaultInputs = useCallback(() => {
    return {
      serial: stations[stationId].serial || "",
      available: stations[stationId].available === 1 ? true : false,
      owner: stations[stationId].owner || "",
      type: stations[stationId].station_type || "",
      position: stations[stationId].base_location || null,
      positionName: "",
      lat: "",
      lon: "",
      controlUnits: stations[stationId].list_cu,
      sensors: stations[stationId].list_sensors,
    };
  }, []);
  const [inputs, setInputs] = useState(getDefaultInputs());

  const handleTranslate = (textId, general = false) => {
    if (general) return translate(t, textId);
    return translate(t, textId, modalsComponentNames.MODALS);
  };

  const cuOptions = useMemo(() => {
    if (!stations) return [];
    const options = [];
    Object.keys(controlUnits).forEach((cuId) => {
      if (
        stations[stationId].list_cu.includes(cuId) ||
        !controlUnits[cuId].deviceParent
      )
        options.push(cuId);
    });
    return options;
  }, [stations, controlUnits]);

  const sensorOptions = useMemo(() => {
    if (!stations) return [];
    const options = [];
    Object.keys(sensors).forEach((sensorId) => {
      if (
        stations[stationId].list_sensors.includes(sensorId) ||
        !sensors[sensorId].deviceParent
      )
        options.push(sensorId);
    });
    return options;
  }, [stations, sensors]);

  useEffect(() => {
    if (changedInputs.length === 0) {
      setDisableConfirm(true);
      return;
    }
    const disabled = Object.keys(inputs).some(
      (key) =>
        requiredInputs.includes(key) &&
        (inputs[key] === "" || inputs[key]?.length === 0)
    );
    setDisableConfirm(disabled);
    onChangeInputs(changedInputs);
  }, [changedInputs]);

  const handleChangeInput = (key, value) => {
    setInputs({ ...inputs, [key]: value });
    setChangedInputs((prev) => [...prev, key]);
  };

  const handleCancelChanges = () => {
    setChangedInputs([]);
    setDisableEdit(true);
    setInputs(getDefaultInputs());
    onChangeInputs([]);
  };

  const handleSaveLocation = () => {
    createBaseLocation(inputs.lat, inputs.lon, inputs.positionName).then(
      (payload) => {
        handleChangeInput("position", payload.id);
        setInputs((prev) => ({
          ...prev,
          positionName: { ...prev.positionName, value: "" },
          lat: { ...prev.lat, value: "" },
          lon: { ...prev.lon, value: "" },
        }));
      }
    );
  };

  const handleConfirm = () => {
    onPending();
    let changed = { ...inputs };
    let oldBaseLocation = changed.position;
    Object.keys(changed).forEach(
      (key) => !changedInputs.includes(key) && delete changed[key]
    );
    const stationType =
      changed.type !== undefined
        ? changed.type === ""
          ? []
          : [changed.type]
        : undefined;
    updateStation(
      stationId,
      changed.serial,
      changed.owner,
      changed.available,
      stationType,
      undefined,
      undefined,
      changed.sensors,
      changed.controlUnits,
      changed.position ?? oldBaseLocation
    )
      .then(() => setGlobalSuccess("MODAL_SUCCESS_UPDATE_STATION"))
      .catch(() => setGlobalError(true))
      .finally(onFinally);
  };

  return (
    inputs && (
      <>
        <div
          style={{
            position: "relative",
            display: "flex",
            flexDirection: "column",
            gap: "2rem",
            padding: "2rem",
            paddingBottom: "7rem",
          }}
        >
          <div
            style={{
              display: "flex",
              gap: "5rem",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <h2 style={{ fontWeight: "bold" }}>
              {" "}
              {handleTranslate("STATION", true)}: {stations[stationId]?.serial}
              {disableEdit && (
                <IconButton
                  sx={{ ml: "1rem" }}
                  onClick={() => setDisableEdit(false)}
                >
                  <EditIcon />
                </IconButton>
              )}
            </h2>
            {enabledActions?.includes("manage-device") && (
              <SwitchComponent
                label={handleTranslate("ACTIVE", true)}
                disabled={disableEdit}
                checked={inputs.available}
                onChange={(value) => handleChangeInput("available", value)}
              />
            )}
          </div>
          {userTenantId === "6554a3a2af4b91cdd6da3285" && (
            <div className="pb-14">
              {" "}
              <span className="text-lg font-semibold ">Id: </span>
              {stationId}{" "}
            </div>
          )}
          {enabledActions?.includes("update-info-device") && (
            <>
              <TextField
                sx={{ width: "100%", maxWidth: "300px" }}
                disabled={disableEdit}
                label={`${handleTranslate("SERIAL", true)}`}
                required
                value={inputs.serial}
                onChange={(e) => handleChangeInput("serial", e.target.value)}
              />
              <TenantSelect
                required
                disabled={disableEdit}
                value={inputs.owner}
                onChange={(value) => handleChangeInput("owner", value)}
              />
              <StationTypeSelect
                enableNullValue
                disableRadioButtons
                disabled={disableEdit}
                stationType={inputs.type}
                onChangeType={(value) => handleChangeInput("type", value)}
              />
              {enabledActions?.includes("manage-device") && (
                <>
                  <div
                    style={{
                      display: "flex",
                      gap: "1rem",
                      alignItems: "center",
                    }}
                  >
                    <span style={{ minWidth: "100px" }}>
                      {" "}
                      {handleTranslate("INSTALLED")}{" "}
                    </span>
                    <CuSelect
                      disablePortal
                      multiple
                      disabled={disableEdit}
                      cusId={cuOptions}
                      value={inputs.controlUnits}
                      onChange={(value) =>
                        handleChangeInput("controlUnits", value)
                      }
                    />
                  </div>
                  <div
                    style={{
                      display: "flex",
                      gap: "1rem",
                      alignItems: "center",
                    }}
                  >
                    <span style={{ minWidth: "100px" }}>
                      {" "}
                      {handleTranslate("INSTALLED")}{" "}
                    </span>
                    <SensorAutocomplete
                      disablePortal
                      multiple
                      disabled={disableEdit}
                      sensorsId={sensorOptions}
                      value={inputs.sensors}
                      onChange={(value) => handleChangeInput("sensors", value)}
                    />
                  </div>
                </>
              )}
              <AccordionComponent
                title={handleTranslate("BASE_LOCATION", true)}
              >
                <LocationInput
                  disablePortal
                  disabled={disableEdit}
                  position={inputs.position}
                  positionName={inputs.positionName}
                  lat={inputs.lat}
                  lon={inputs.lon}
                  onChangePosition={(value) =>
                    handleChangeInput("position", value)
                  }
                  onChangePositionName={(value) =>
                    handleChangeInput("positionName", value)
                  }
                  onChangeLat={(value) => handleChangeInput("lat", value)}
                  onChangeLon={(value) => handleChangeInput("lon", value)}
                  onSave={handleSaveLocation}
                />
              </AccordionComponent>
            </>
          )}
        </div>
        {!disableEdit && (
          <FloatingButtons
            containerStyle={
              sm && {
                position: "fixed",
                height: "fit-content",
                width: "fit-content",
                left: "50%",
                top: "90%",
                transform: "translateX(-50%)",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }
            }
            disableSave={disableConfirm}
            disableDelete={true}
            onSave={handleConfirm}
            onDiscard={handleCancelChanges}
          />
        )}
      </>
    )
  );
};

const StationConfigurationModal = ({
  enabledActions,
  isOpen = false,
  stationId,
  onFinally,
  onClose,
}) => {
  const { t } = useTranslation("evocs");
  const [updateLoading, setUpdateLoading] = useState(false);
  const [changedInputs, setChangedInputs] = useState([]);
  const handleFinally = () => {
    setUpdateLoading(false);
    onFinally && onFinally();
  };

  const handleTranslate = (textId, general = false) => {
    if (general) return translate(t, textId);
    return translate(t, textId, modalsComponentNames.MODALS);
  };
 
  const tabs = useMemo(() => {
    return {
      generals: {
        header: `${handleTranslate("GENERAL", true)}`,
        content: (
          <ConfigurationChip
            enabledActions={enabledActions}
            stationId={stationId}
            onPending={() => setUpdateLoading(true)}
            onFinally={handleFinally}
            onChangeInputs={setChangedInputs}
          />
        ),
      },
      history: {
        header: `${handleTranslate("HISTORY", true)}`,
        content: <StationHistoryChip stationId={stationId} />,
      },
    };
  }, [stationId, enabledActions, t]);

  useEffect(() => {
    !isOpen && setChangedInputs([]);
  }, [isOpen]);

  const handleClose = () => {
    setUpdateLoading(false);
    if (!onClose) return;
    if (changedInputs?.length > 0) onClose(false);
    else onClose();
  };

  return (
    <Modal open={isOpen}>
      <div style={styles.containerStyle}>
        <div style={{ display: "flex" }}>
          <IconButton sx={{ marginLeft: "auto" }} onClick={handleClose}>
            <CloseIcon fontSize="small" sx={{ color: "white" }} />
          </IconButton>
        </div>
        <div style={{ position: "relative", height: "calc(100% - 36px)" }}>
          <TabsComponent tabs={tabs} unselectTextColor={"white"} />
          {updateLoading && (
            <div style={styles.loadingPopup}>
              <LoadingGif />
            </div>
          )}
        </div>
      </div>
    </Modal>
  );
};

export default StationConfigurationModal;
