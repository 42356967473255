import store from "../..";
import { openDataSliceActions } from "./openDataSlice";
import { openDataSourceInAPIString, openDataTypeInAPIString } from "src/components/OpenDataAir/utils";
import { getApiTimestamp, getNewStartEndDate } from "src/utilities/utils";
import defaultMarker from "src/app/asset/markers/DefaultMarker.png";
import boaIcon from "src/app/asset/markers/boaIcon_v2.png";
import faroIcon from "src/app/asset/markers/faroIcon.png";

// export const getOpenData = async(source=null, stationId=null, field=null) => {
//     if(store.getState().openData.loading) return;
//     const openData = store.getState().openData;
//     const expiration = openData.lastUpdate + OPEN_DATA_EXPIRATION_TIME;
//     const dataExists = () => {
//       if(Object.keys(openData.data).length > 0 ) return true;
//       return false;
//     }
//     if(dataExists() && new Date().valueOf() < expiration) return;
//     setOpenDataLoading();
//     const fetchData = async() => {
//         const allCalls = OPEN_DATA_FILTERS.timeRangeInterval.map((interval) => {
//             const {start, end} = getNewStartEndDate(interval.id, true)
//             const actionPayload = {
//                 startingDate: start,
//                 endingDate: end,
//                 source: openDataSourceInAPIString(source),
//                 stationId: stationId,
//                 field: field,
//             }
//             return store.dispatch(openDataSliceActions.getOpenData(actionPayload)).then(({payload}) => {
//                 setOpenDataInterval(payload, interval.value);
//             })
//         })

//         await Promise.all(allCalls);
//         const lastUpdate = new Date().valueOf();
//         setOpenDataLastUpdate(lastUpdate);
//         setOpenDataLoading(false);
//     }
    
//     fetchData();
// }

export const readOpenData = async(startDate, endDate, sources=null, stationIds=null, fields=null, typeStation=null) => {
    if(!startDate || !endDate) throw new Error("startDate & endDate are required");
    const data = await store.dispatch(openDataSliceActions.readOpenData({startDate: getApiTimestamp(startDate), endDate: getApiTimestamp(endDate), sources, stationIds, fields, typeStation})).then(({payload, error}) => {
        if(error) throw error;
        return payload;
    });
    return data;
}

const setOpenDataInterval = (openData, interval) => {
    store.dispatch(openDataSliceActions.setInterval({data: openData, interval: interval}));
}

const setOpenDataLastUpdate = (lastUpdate) => {
    store.dispatch(openDataSliceActions.setLastUpdate(lastUpdate))
}

export const getOpenDataMarkers = async(source, type) => {
    const data = await store.dispatch(openDataSliceActions.getOpenDataMarkers({ source: openDataSourceInAPIString(source), type: type})).then(({payload, error}) => {
        if(error) throw error;
        const markers = payload.filter((element) => {
            if(!element.lat || !element.lon) return;

            switch(element.source) {
                case "prot_civ":
                    element.icon = defaultMarker;
                    break;
                case "mistral":
                    element.icon = defaultMarker;
                    break;
                case "arpa":
                    element.icon = defaultMarker;
                    break;
                case "ron":
                    element.icon = boaIcon;
                    break;
                case "rmn":
                    element.icon = faroIcon;
                    break;
                default:
                    element.icon = defaultMarker;
                    break;
            }
            return element;
        });
        return markers;
    });
    return data;
}

export const getOpenDataLastRelevations = async(stationId=null) => {
    const date = getApiTimestamp(new Date());
    const data = await store.dispatch(openDataSliceActions.getLastRelevations({lastDate: date, stationId})).then(({payload}) => {
        return payload;
    }).catch(() => {
        return null;
    });

    return data;
}

export const setOpenDataLoading = (loading=true) => {
    store.dispatch(openDataSliceActions.setLoading(loading));
}