import { useEffect, useState } from "react";
import LoadingGif from "src/components/SharingComponents/LoadingGif";
import {
  getDeprecatedStations,
  deleteDeprecatedStations,
  restoreDeprecatedStations,
} from "app/store/evocsSlice/stationsMongo/stationsMongoActions";
import TableComponent from "src/components/SharingComponents/TableComponent";
import { useSelector } from "react-redux";
import {
  setGlobalError,
  setGlobalSuccess,
} from "app/store/evocsSlice/globalError/errorActions";
import Modal from "@mui/material/Modal";
import Button from "@mui/material/Button";
export default function StationsTab({ handleTranslate }) {
  const [isLoading, setIsLoading] = useState(true);
  const [stations, setStations] = useState([]);
  const [data, setData] = useState(stations);
  const [filteredData, setFilteredData] = useState([]);
  const [searchedText, setSearchedText] = useState("");

  const tenants = useSelector((state) => state.tenants.list);
  const stationTypes = useSelector(
    (state) => state.configuration.constants.station_type
  );

  useEffect(() => {
    setIsLoading(true);
    getDeprecatedStations()
      .then((res) => {
        setStations(res);
      })
      .finally(() => setIsLoading(false));
  }, []);

  const col = {
    serial: handleTranslate("SERIAL", true),
    owner: handleTranslate("OWNER", true),
    stationType: handleTranslate("STATION_TYPE", true),
  };

  useEffect(() => {
    setData(
      stations?.map((station) => {
        const validTenant = tenants[station.owner]?.name ?? "Sconosciuto";
        const stationType = stationTypes[station.station_type] ?? "Sconosciuto";

        return {
          id: station.serial,
          serial: station.serial,
          owner: validTenant ?? handleTranslate("UNKNOWN", true),
          stationType:
            stationType.station_type ?? handleTranslate("UNKNOWN", true),
        };
      })
    );
  }, [stations]);

  useEffect(() => {
    if (!searchedText) {
      setFilteredData(data);
      return;
    }
    const filtered = data.filter((station) =>
      Object.keys(station).some((key) =>
        station[key]?.toLowerCase().includes(searchedText.trim().toLowerCase())
      )
    );
    setFilteredData(filtered);
  }, [searchedText, data]);

  //? RESTORE
  const handleRestoreRow = (selection) => {
    const filterBySelection = stations
      .filter((station) => selection.includes(station.serial))
      .map((itemToRestore) => itemToRestore["_id"]);
    restoreDeprecatedStations(filterBySelection).then(() => {
      setStations(
        stations?.filter((station) => !selection.includes(station.serial))
      );
      setGlobalSuccess(true);
    });
  };

  //!DELETE
  const handleDeleteRow = (deletedSelection) => {
    const filterByDeleted = stations
      .filter((station) => deletedSelection.includes(station.serial))
      .map((deleted) => deleted["_id"]);

    deleteDeprecatedStations(filterByDeleted)
      .then(() => {
        setStations(
          stations?.filter(
            (station) => !deletedSelection.includes(station.serial)
          )
        );
        setGlobalSuccess(true);
      })
      .catch((error) => {
        setGlobalError(true);
        console.error("CIAO", error);
      });
  };

  if (isLoading) {
    return <LoadingGif />;
  }

  const translatedTitle = handleTranslate("RESTORE", true);
  return (
    <div>
      <div
        style={{
          marginTop: "10px",
          padding: "20px",
          fontSize: "2rem",
          fontWeight: "700",
        }}
      >
        {handleTranslate("STATIONS", true)}: {filteredData.length}
      </div>
      <TableComponent
        onCustomAction={handleRestoreRow}
        selectable
        onSearch={setSearchedText}
        onDeleteRows={handleDeleteRow}
        columns={col}
        data={filteredData}
        customActionTitle={translatedTitle}
      />
    </div>
  );
}

