import { viewNames } from 'app/configs/routesNames';
import { convertStationEcoPathToColor, getLastStationPosition, getStationEcoPath, findStationRoute } from 'app/store/evocsSlice/stationsMongo/stationsMongoActions';
import React, { useCallback, useEffect, useLayoutEffect, useMemo, useState } from 'react'
import MapComponent from '../SharingComponents/MapComponent';
import { useSelector } from 'react-redux';
import { CircleMarker, LayerGroup, LayersControl, Polyline, Popup } from 'react-leaflet';
import { TIME_FORMAT, formatDate } from '../StationHandling/utils';
import LoadingGif from '../SharingComponents/LoadingGif';
import { useLocation } from 'react-router-dom';
import useFilters from 'src/app/hooks/useFilters';
import mkrIcon from 'src/app/asset/markers/Blue.png';
import { MdGpsFixed } from "react-icons/md";
import { useTranslation } from 'react-i18next';
import { translate } from "src/utilities/utils";
import { setGlobalError } from 'app/store/evocsSlice/globalError/errorActions';
import PlanetekMap from '../SharingComponents/PlanetekMap';

const viewName = viewNames.SINGLE_STATION_VIEW;

const styles = {
  mapModalView: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    position: "absolute",
    zIndex: 1000,
    background: "rgba(0, 0, 0, .5)",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0
  }
};

const existAction = (userActions, actionName) => {
  if (!Array.isArray(userActions)) {
    console.error("L'input non è un array.");
    return false;
  }

  for (let i = 0; i < userActions.length; i++) {
    if (typeof userActions[i] === 'object' && userActions[i] !== null && typeof userActions[i].name === 'string') {
      if (userActions[i].name === actionName) {
        return true;
      }
    }
  }
  return false;
};




const NoPositionView = ({ loading = false, visible = false }) => {

  const { t } = useTranslation("evocs");
  const handleTranslate = (textId, general = false) => {
    if (general) return translate(t, textId);
    return translate(t, textId, viewNames.SINGLE_STATION_VIEW);
  };

  return (
    <div style={{ ...styles.mapModalView, opacity: visible ? 1 : 0, pointerEvents: visible ? "auto" : "none" }}>
      {!loading ?
        <h2 style={{ color: "white", fontWeight: "bold" }}>{handleTranslate("POSITION_ROUTE_NOT_AVAILABLE", true)}</h2>
        : <LoadingGif />}
    </div>
  )
};

const CustomPolyline = ({color="#0f53ff", markers }) => {

  if(!markers || !markers?.length || markers?.[0]?.length === 0) return;

  const polylines = useMemo(() => {
    return markers.map((m) => [m[1], m[2]]);
  }, [markers]);

  // const getColor = useCallback((index) => {
  //   console.log("length: ", markers.length)
  //   return index === markers.length - 1 ? "red" : "white"; //"#1588D9"
  //   // return index === 0 ? "black" : index === markers.length-1  ? "red" : "blue"
  // }, [markers.length])

  const getColor = useCallback((index) => {
    return index === 0 ? "black" : index === markers.length-1 ? "red" : "white"; 
  }, [markers.length]);

  const getFillColor = useCallback((index) => {
    return index === 0 ? "white" : index === markers.length-1 ? "red" : "white"; 
    // return index === 0 ? "black" : index === markers.length-1  ? "red" : "blue"
  }, [markers.length]);


  const getTitle = useCallback((index) => {
    return index === 0 ? handleTranslate('DEPARTURE', true) : index === markers.length - 1 ? handleTranslate('ARRIVAL', true) : `Step ${index}`
  }, [markers.length]);

  const { t } = useTranslation("evocs");
  const handleTranslate = (textId, general = false) => {
    if (general) return translate(t, textId);
    return translate(t, textId, viewNames.SINGLE_STATION_VIEW);
  };

  return (
    <>
      <Polyline color={color} positions={polylines} weight={8} smoothFactor={1.5} />
      {markers?.map((m, index) => (
        <CircleMarker key={index} pathOptions={{color: getColor(index), fillColor: getFillColor(index)}} center={polylines[index]} 
        weight={index === 0 ? 2 : 0} radius={index === 0 || index === polylines.length-1 ? 8 : 2}  fillOpacity={1}
        eventHandlers={{
          mouseover: (event) => event.target.openPopup(),
        }}>
          <Popup closeButton={false}>
            <div style={{ display: "flex", flexDirection: "column" }}>
              <h3 style={{ textAlign: "center", fontWeight: "bold" }}>{getTitle(index)}</h3>
              <label>{handleTranslate('MEASURE_LAT', true)}: {m[1]}</label>
              <label>{handleTranslate('MEASURE_LON', true)}: {m[2]}</label>
              <label>{handleTranslate('ON_THE_DATE_OF', true)}: {formatDate(new Date(m[0] * 1000), TIME_FORMAT.COMPLETE_DATE)}</label>
            </div>
          </Popup>
        </CircleMarker>
      ))}
    </>

  )
}

const SingleStationHandlingMap = ({ stationId, userActions, displayMap, enableLastPosition, enableRoute, enableEcoPath }) => {
  const location = useLocation();
  const filters = useFilters(viewName);
  const timeRangeInView = filters.timeRange;
  const [markers, setMarkers] = useState([]);
  const [popupsContent, setPopupsContent] = useState([]);
  const [mapCenter, setMapCenter] = useState();
  const [isLoadingMap, setIsLoadingMap] = useState(false);
  const [polylines, setPolylines] = useState({});
  const [coloredPolylines, setColoredPolylines] = useState({});
  const [positionRouteAvailable, setPositionRouteAvailable] = useState(true);
  const { t } = useTranslation("evocs");
  const handleTranslate = (textId, general = false) => {
    if (general) return translate(t, textId);
    return translate(t, textId, viewNames.SINGLE_STATION_VIEW);
  };

  const polylineColors = [
    "#0f53ff",  // Blu brillante
    "#ffd700",  // Giallo acceso
    "#ff4500",  // Rosso vivo
    "#32cd32",  // Verde lime
    "#1a1a1a"   // Nero profondo
  ];

  const polyLength = polylineColors.length;
  const disableOpenMapLayers = !existAction(userActions, "show-open-map-layers");
  const disableMapFullView = !existAction(userActions, "map-full-view");

  const getLastPosition = useCallback(async() => {
    return await getLastStationPosition(stationId, timeRangeInView.startDate, timeRangeInView.endDate)
      .then((res) => {
        if(res?.length === 0) return [];
        const stationPositions = res?.[0];
        if(stationPositions?.gpsLocations)
          return stationPositions.gpsLocations.map((posGps) => ({ ...posGps, icon: mkrIcon, iconType: <MdGpsFixed />}));
        if(stationPositions?.baseLocation)
          return [{ ...stationPositions.baseLocation, icon: mkrIcon, iconType: <MdGpsFixed /> }]
        return [];
      });
  }, [stationId, timeRangeInView.startDate, timeRangeInView.endDate]);

  const getRoute = useCallback(async() => {
    return await findStationRoute([stationId], timeRangeInView.startDate, timeRangeInView.endDate).then((res) =>res?.[stationId] ?? {});
  }, [stationId, timeRangeInView.startDate, timeRangeInView.endDate]);

  const getEcoPath = useCallback(async() => {
    return await getStationEcoPath(stationId, timeRangeInView.startDate, timeRangeInView.endDate).then((res) => {
      let ecoObject = {};
      if(!res?.[stationId]) return ecoObject;
      Object.keys(res[stationId]).forEach((gpsSensorId) => {
        if(res[stationId][gpsSensorId].length <= 0) return;
        ecoObject[gpsSensorId] = convertStationEcoPathToColor(res[stationId][gpsSensorId]);
      });
      return ecoObject;
    });
  }, [stationId, timeRangeInView.startDate, timeRangeInView.endDate]);
  
  useEffect(() => {
    setMarkers([]);
    setPolylines({});
    if (!timeRangeInView.startDate || !timeRangeInView.endDate) return;
    setIsLoadingMap(true);
    let timeoutId;
    if (enableLastPosition) {
      timeoutId = setTimeout(() =>  {
        getLastPosition().then((res) => {
          res[0] && setMapCenter({ lat: res[0].lat, lon: res[0].lon });
          setMarkers(res);
          setIsLoadingMap(false);
        }).catch(() => setGlobalError(true));
      }, location.state?.timeout ?? 0);
    }else if(enableRoute) {
      timeoutId = setTimeout(() => {
        getRoute().then((res) => {
          setPolylines(res);
          setIsLoadingMap(false);
        }).catch(() => setGlobalError(true));
      }, location.state.timeout ?? 0) 
    } else if(enableEcoPath) {
      timeoutId = setTimeout(() => {
        getEcoPath().then((res) => {
          setColoredPolylines(res);
          setIsLoadingMap(false);
        }).catch(() => setGlobalError(true));
      }, location.state?.timeout ?? 0);
    }
    return () => clearTimeout(timeoutId)
  }, [stationId])

  useEffect(() => {
    if (!enableLastPosition || !timeRangeInView.startDate || !timeRangeInView.endDate) return;
    let canSet = true;
    setIsLoadingMap(true);
    setPolylines({});
    setColoredPolylines({});
    getLastPosition().then((res) => {
      if(!canSet) return;
      res?.[0] && setMapCenter({ lat: res[0].lat, lon: res[0].lon });
      setMarkers(res);
      setIsLoadingMap(false);
    }).catch(() => setGlobalError(true));
    return () => canSet = false;
  }, [enableLastPosition, timeRangeInView.startDate, timeRangeInView.endDate]);

  useEffect(() => {
    if (!enableRoute || !timeRangeInView.startDate || !timeRangeInView.endDate) return;
    let canSet = true;
    setIsLoadingMap(true);
    setMarkers([]);
    setColoredPolylines({});
    getRoute().then((res) => {
      if(!canSet) return;
      setPolylines(res);
      setIsLoadingMap(false);
    }).catch(() => setGlobalError(true));
    return () => canSet = false;
  }, [enableRoute, timeRangeInView.startDate, timeRangeInView.endDate]);

  useEffect(() => {
    if (!enableEcoPath || !timeRangeInView.startDate || !timeRangeInView.endDate) return;
    let canSet = true;
    setIsLoadingMap(true);
    setMarkers([]);
    setPolylines({});
    getEcoPath().then((res) => {
      if(!canSet) return;
      setColoredPolylines(res);
      setIsLoadingMap(false);
    }).catch(() => setGlobalError(true));
    return () => canSet = false;
  }, [enableEcoPath, timeRangeInView.startDate, timeRangeInView.endDate]);

  useEffect(() => {
    setPopupsContent(() =>
      markers.map((m) => ({
        title: handleTranslate('LAST_POSITION', true),
        time: {
          label: m.time ? handleTranslate('ON_THE_DATE_OF', true) : handleTranslate('BASE_LOCATION', true),
          value: m.time ? formatDate(new Date(m.time * 1000), TIME_FORMAT.COMPLETE_DATE) : handleTranslate('CURRENT', true)
        },
      }))
    )
  }, [markers, t])

  useEffect(() => {
    let available = true;
    if (isLoadingMap) available = true;
    else if (enableLastPosition && enableRoute) {
      if (markers?.length <= 0 || polylines?.length <= 0) available = false;
    } else if (enableLastPosition) {
      if (markers?.length <= 0) available = false;
    } else if (enableRoute) {
      if (polylines?.length <= 0 && coloredPolylines.length <= 0) available = false;
    }
    setPositionRouteAvailable(available);
  }, [isLoadingMap, markers, polylines, enableLastPosition, enableRoute])


  return displayMap && (
    !userActions.find((action) => action.name === "show-planetek-map") ?
      <MapComponent 
        style={{ zIndex: 0 }} 
        center={mapCenter} 
        markers={markers} 
        popupsContent={popupsContent}
        disableFullView={disableMapFullView}
        disableOpenLayers={disableOpenMapLayers}
        cluster={true}
      >
        {isLoadingMap && <LoadingGif />}
        <NoPositionView visible={positionRouteAvailable === false} />
        {(enableRoute && polylines) &&
          Object.keys(polylines).map((gpsSensorId, index) => (
            <CustomPolyline 
              key={index} 
              color={polylineColors[index % polyLength]}
              markers={polylines[gpsSensorId]} 
            />
          ))
        }
        {enableEcoPath && coloredPolylines.map((positions, index) => (
          <CustomPolyline key={index} color={positions[0][3]} markers={positions}/>
        ))}
      </MapComponent>
    :
      <PlanetekMap 
        style={{ zIndex: 0 }} 
        center={mapCenter} 
        markers={markers} 
        popupsContent={popupsContent}
        disableOpenLayers= {disableOpenMapLayers}
        disableFullView= {disableMapFullView}
      >
        <NoPositionView loading={isLoadingMap} visible={!positionRouteAvailable} />
          {(enableRoute && polylines) &&
            Object.keys(polylines).map((gpsSensorId, index) => (
              <CustomPolyline 
                key={index} 
                color={polylineColors[index % polyLength]}
                markers={polylines[gpsSensorId]} 
              />
            ))
          }
          {enableEcoPath && Object.keys(coloredPolylines).map((gpsSensorId, index) => (
            coloredPolylines[gpsSensorId].map((positions, index) => (
              <CustomPolyline key={index} color={positions[0][3]} markers={positions}/>
            ))
          ))}
      </PlanetekMap>
  )
}

export default SingleStationHandlingMap