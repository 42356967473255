import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import {
  readVpn,
  setActiveDevice,
} from "app/store/evocsSlice/network/networkActions";
import { DataGrid } from "@mui/x-data-grid";
import Paper from "@mui/material/Paper";
import DeviceInfo from "./DeviceInfo";
import { FaCheckCircle } from "react-icons/fa";
import AddDeviceModal from "./AddDeviceModal";
import useMobile from "src/app/hooks/useMobile";
import { ToastContainer, toast } from "react-toastify";
import { translate } from "src/utilities/utils";
import { useTranslation } from 'react-i18next';
import { viewNames } from 'app/configs/routesNames';
import "react-toastify/dist/ReactToastify.css";
import { formatDateFromTimestamp, TIME_FORMAT } from "src/components/StationHandling/utils";
import LoadingGif from "src/components/SharingComponents/LoadingGif";
import { setGlobalSuccess } from "app/store/evocsSlice/globalError/errorActions";

export default function DeviceList({ filteredDevices, setFilteredDevices }) {
  //! LISTA DEI DISPOSITIVI
  const devices = useSelector((state) => state.networkDevices.value);

  const [loading, setLoading] = useState(true);
  const [isInputModify, setIsInputModify] = useState(false);
  const [open, setOpen] = useState(false);
  const [sm] = useMobile();

  const addMessage = () => {
    setGlobalSuccess(true, handleTranslate("NEW_DEVICE_SUCCESSFULLY_ADDED_TO_VPN"));
  };
  const updateMessage = () => {
    toast.info("Dispositivo modificato con successo", {
      position: "top-center",
    });
  };
  const deleteMessage = (activeDevice) => {
    setGlobalSuccess(true, handleTranslate("NEW_DEVICE_SUCCESSFULLY_DELETED_FROM_VPN"));
  };

  const { t } = useTranslation("evocs");
  const handleTranslate = (textId, general = false) => {
    if (general) return translate(t, textId);
    return translate(t, textId, viewNames.INTERNAL_NETWORK_MAN_VIEW);
  };

  useEffect(() => {
    if (devices) {
      setFilteredDevices(devices);
    }
  }, [devices]);

  const toggleDrawer = (newOpen, device = null) => {
    if (device) {
      setActiveDevice(device.id);
    }
    if (!newOpen) {
      setActiveDevice(null);
      setIsInputModify(false);
    }
    setOpen(newOpen);
  };

  const columns = [
    { field: "id", headerName: "ID", width: 70 },
    {
      field: "name",
      headerName: handleTranslate("DEVICE_NAME"),
      width: !sm ? 250 : 150,
    },
    { field: "port", headerName: handleTranslate("PORT"), width: !sm ? 150 : 80 },
    { field: "lastUpdate", headerName: handleTranslate("LAST_UPDATE", true), width: !sm ? 150 : 80 },
    // {
    //   field: "status",
    //   headerName: handleTranslate("STATUS"),
    //   width: 130,
    //   renderCell: (params) => {
    //     return (
    //       <div
    //         style={{
    //           backgroundColor: "rgb(226,232,240)",
    //           borderRadius: "10px",
    //           display: "flex",
    //           justifyContent: "center",
    //           alignItems: "center",
    //           gap: 2,
    //           height: "80%",
    //           marginTop: "0.5rem",
    //         }}
    //       >
    //         <span>
    //           {params.value === "Online" && (
    //             <FaCheckCircle style={{ color: "green" }} />
    //           )}
    //         </span>
    //         <span
    //           style={{
    //             color: params.value === "Online" ? "green" : "red",
    //             fontWeight: "600",
    //           }}
    //         >
    //           {params.value}
    //         </span>
    //       </div>
    //     );
    //   },
    // },
  ];

  const rows = filteredDevices ? filteredDevices.map((device) => ({
    id: device.id,
    name: device.name,
    port: device.port,
    lastUpdate: device?.lastUpdate ? formatDateFromTimestamp((device?.lastUpdate * 1000), TIME_FORMAT.COMPLETE_DATE) : "",
    status: device.isOn ? "Online" : "Offline",
  })) : [];

  const paginationModel = { page: 0, pageSize: 20 };

  useEffect(() => {
    readVpn().then(() => setLoading(false));
  }, []);

  if (loading) {
    return <LoadingGif/>
  }

  return (
    <div style={{width: "100%"}}>
      <div
        style={{
          marginTop: "0.75rem",
          fontSize: "0.8rem",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Paper
          sx={{
            height: "100%",
            width: "95%",
            borderRadius: "10px",
            position: !sm ? "relative" : "static",
            boxShadow: "1px 1px 15px rgba(0,0,0,0.2)",
          }}
        >
          <DataGrid
            rows={rows}
            columns={columns}
            initialState={{ pagination: { paginationModel } }}
            pageSizeOptions={[10, 20, 50]}
            sx={{
              border: 0,
              "& .MuiDataGrid-row": {
                transition: "all 0.5s",
              },
              "& .MuiDataGrid-row:hover": {
                cursor: "pointer",
                backgroundColor: "rgb(240,240,240)",
              },
            }}
            onRowClick={(params) => {
              toggleDrawer(true, params.row);
            }}
            columnVisibilityModel={{
              id: false,
            }}
          />
        </Paper>
      </div>
      <DeviceInfo
        toggleDrawer={toggleDrawer}
        open={open}
        setOpen={setOpen}
        setIsInputModify={setIsInputModify}
        isInputModify={isInputModify}
        addMessage={addMessage}
        updateMessage={updateMessage}
        deleteMessage={deleteMessage}
      />
      <ToastContainer />
    </div>
  );
}
