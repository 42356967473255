import { Button, IconButton, Menu, MenuItem } from "@mui/material";
import { memo, useEffect, useLayoutEffect, useMemo, useState } from "react";
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { deleteStation } from "app/store/evocsSlice/stationsMongo/stationsMongoActions";
import StationSelect from "../SharingComponents/Inputs/StationSelect";
import TenantSelect from "../SharingComponents/Inputs/TenantSelect";
import { viewNames } from 'app/configs/routesNames';
import { useTranslation } from 'react-i18next';
import { translate } from "src/utilities/utils";
import LoadingGif from "../SharingComponents/LoadingGif";
import useMobile from "src/app/hooks/useMobile";
import { useSelector } from "react-redux";
import { setGlobalError, setGlobalSuccess } from "app/store/evocsSlice/globalError/errorActions";
import { IoEyeOffOutline } from "react-icons/io5";
import { IoMdEye } from "react-icons/io";
import { FaNetworkWired } from "react-icons/fa";
import { Tooltip } from "react-tooltip";



const StationListItemMenu = ({ style, enabledActions, station, onClickItem }) => {
  const { t } = useTranslation("evocs");
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleClickItem = (itemAction, actionCallback = null) => {
    handleClose();
    onClickItem && onClickItem(itemAction, {...station, type: "station"}, actionCallback);
  };

  const handleClickDelete = () => {
    handleClickItem("delete", () => {
      deleteStation(station.id).then(() => setGlobalSuccess(true, "MODAL_SUCCESS_DELETE_STATION")).catch(() => setGlobalError(true));
    });
  }

  const stopPropagation = (e) => {
    e.stopPropagation()
  };

  const handleTranslate = (textId, general = false) => {
    if (general) return translate(t, textId);
    return translate(t, textId, viewNames.CONFIGURATION_VIEW);
  };

  return (
    <div style={{ ...style, width: "fit-content" }} onClick={stopPropagation}>
      <IconButton sx={style} onClick={handleClick}>
        <MoreVertIcon fontSize="small" />
      </IconButton>
      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
      >
        <MenuItem onClick={() => handleClickItem("config-station")}> {handleTranslate("CONFIGURE")} </MenuItem>
        {enabledActions.includes("manage-device") && <MenuItem sx={{ color: "red" }} onClick={handleClickDelete}> {handleTranslate("DELETE", true)} </MenuItem>}
      </Menu>
    </div>
  )
}

const StationListItem = ({ enabledActions, disableDrag, station, selected = false, onClick, onClickItemMenu, handleTranslate }) => {
  const hasUnitInstalled = ((station?.list_cu?.length ?? 0) + (station?.list_sensors?.length ?? 0)) > 0; 
  const className = !selected ? "station-item-list close" : "station-item-list selected";
  const isMenuEnabled = () => {
    if (!enabledActions.includes("manage-device") && !enabledActions.includes("update-info-device")) return false;
    return true;
  };

  const handleClick = () => {
    if(disableDrag) 
      onClickItemMenu && onClickItemMenu("config-station", {...station, type: "station"});
    else onClick && onClick(station.id)
  };


  return station && (
    <div 
      className={`${className} flex justify-between py-10 items-center border-t border-gray-500 font-sans font-bold text-[14px]`}
      onClick={handleClick}>
      <>
        <span style={{ fontWeight: "bold" }}>{station.serial}</span>
      </>
      <div className="flex gap-5 items-center">
        { 
          ((station?.available === 1 ?? 0 ) ?
            (
              hasUnitInstalled ?
                <div>
                  <FaNetworkWired
                    className="min-w-[20px] min-h-[20px] text-green-800 font-bold"
                    data-tooltip-id="available-with-units"
                    data-tooltip-content={handleTranslate && handleTranslate("AVAILABLE_WITH_UNITS")}
                  />
                  <Tooltip id="available-with-units" />
                </div>
              :
              <div style={{ marginLeft: "auto"}}>
                <IoMdEye 
                  style={{minWidth: "20px", minHeight: "20px" }}
                  data-tooltip-id="available-without-units"
                  data-tooltip-content={handleTranslate && handleTranslate("AVAILABLE_WITHOUT_UNITS")}
                />
                <Tooltip id="available-without-units" />
              </div>
            )
          :
            <div style={{ marginLeft: "auto"}}>
              <IoEyeOffOutline 
                style={{minWidth: "20px", minHeight: "20px" }} 
                data-tooltip-id="not-available"
                data-tooltip-content={handleTranslate && handleTranslate("NOT_AVAILABLE")}
              />
              <Tooltip id="not-available" />
            </div>
        )
      }
      
      {isMenuEnabled() && <StationListItemMenu style={{ marginLeft: "auto" }} enabledActions={enabledActions} station={station} onClickItem={onClickItemMenu} />}
      </div>
    </div>
  );
}

const StationsList = memo(({ enabledActions, list, selectedItems, onClickItem, onClickItemMenu, handleTranslate }) => {
  const [xs, sm] = useMobile();
  const stations = useSelector((state) => state.stationsMongo.list);
  const disableDrag = useMemo(() => xs || sm);

  const handleClick = (stationId) => {
    if (!selectedItems.includes(stationId)) onClickItem([...selectedItems, stationId], stationId);
    else onClickItem(selectedItems.filter((e) => e !== stationId), stationId);
  };

  const getStationElement = (stationId) => {
    return { id: stationId, ...stations[stationId] }
  };

  return (
    <div style={{ height: "100%", overflowY: "auto" }}>
      {list?.map((stationId) => (
        <StationListItem key={stationId} enabledActions={enabledActions} disableDrag={disableDrag} station={getStationElement(stationId)} selected={selectedItems.includes(stationId)}
          onClick={handleClick} onClickItemMenu={onClickItemMenu} handleTranslate={handleTranslate} />
      ))}
    </div>
  )
});


const StationsManagement = ({ loading, selectedTenant, selectedStations, enabledActions, onChangeTenant, onStationItemClick, onClickItemMenu }) => {
  const { t } = useTranslation("evocs");
  const stations = useSelector((state) => state.stationsMongo.list);
  const [stationsOptions, setStationsOptions] = useState([]);
  const [filteredStations, setFilteredStations] = useState();
  const [visibleStations, setVisibleStations] = useState();

  useLayoutEffect(() => {
    setFilteredStations([]);
  }, [selectedTenant])


  // useEffect(() => {
  //   console.log('visibleStations: ', visibleStations);
  // }, [visibleStations])  
  
  // useEffect(() => {
  //   console.log('filteredStations: ', filteredStations);
  // }, [filteredStations])

  useLayoutEffect(() => {
    setVisibleStations(() => {
      if (!filteredStations || filteredStations.length === 0) return Object.keys(stations);
      return filteredStations;
    });
  }, [stations, filteredStations])

  useLayoutEffect(() => {
    const allStationsId = Object.keys(stations);
    setStationsOptions(allStationsId.map((id) => ({ id, serial: stations[id].serial })));
    setFilteredStations((prev) => prev.filter((stationId) => allStationsId.includes(stationId)));
  }, [stations])

  const handleTranslate = (textId, general = false) => {
    if (general) return translate(t, textId);
    return translate(t, textId, viewNames.CONFIGURATION_VIEW);
  };

  return (
    <div style={{ display: "flex", flexDirection: "column", gap: "1rem", padding: "1rem", height: "100%", overflowX: "visible" }}>
      {!loading ?
      <>
      <div className="flex flex-col xl:flex-row gap-[1rem] ">
          <div className="flex flex-col xl:flex-row gap-[1rem] items-stretch basis-2/3">
            <StationSelect style={{ maxWidth: "none"}} isUserTenant={false} limitTags={1} multiple stationsId={stationsOptions} value={filteredStations} onChange={setFilteredStations} />
            <TenantSelect style={{ maxWidth: "none"}} value={selectedTenant} onChange={onChangeTenant} />
          </div>
          <div className="flex basis-1/3">
            {enabledActions.includes("manage-device") && <button className="py-10 xl:py-0" style={{ backgroundColor: "#5d9a60", borderRadius: "5px", color: "white", width:"100%", textTransform: "uppercase" }} onClick={() => onClickItemMenu("create-station", null)}>{handleTranslate("NEW_STATION")}</button>}
          </div>
      </div>
        <StationsList enabledActions={enabledActions} list={visibleStations} selectedItems={selectedStations} onClickItem={onStationItemClick}
          onClickItemMenu={onClickItemMenu} handleTranslate={handleTranslate}/>
      </>
      :
      <LoadingGif />
      }
    </div>
  )
}

export default StationsManagement;
