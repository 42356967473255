import { useCallback } from "react";
import { useMap } from "react-leaflet";

const CustomControl = ({ style, children }) => {
  const map = useMap();

  const onMouseEnter = useCallback(() => {
    if (!map) return;
    map.dragging.disable();
  }, [map]);

  const onMouseLeave = useCallback(() => {
    if (!map) return;
    map.dragging.enable();
  }, [map]);

  return (
    <div
      style={{ position: "absolute", zIndex: 2000, ...style }}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
    >
      {children}
    </div>
  );
};

export default CustomControl