import TabPanel from "@mui/lab/TabPanel";
import { FaPlus } from "react-icons/fa";
import { IoReload } from "react-icons/io5";
import NetworkMenu from "./NetworkMenu";
import { useSelector } from "react-redux";
import {
  getDeviceById,
  readNetData,
} from "app/store/evocsSlice/network/networkActions";
import { useState, useEffect } from "react";
import {
  scanWifi,
  getNetData,
  scanBlt,
  readConnectedBltDevices,
  bltPairing,
  connectDisconnectBltDevices,
  checkEthernet,
} from "app/store/evocsSlice/network/networkActions";
import LoadingGif from "src/components/SharingComponents/LoadingGif";
import { MdOutlineWifi } from "react-icons/md";
import { MdOutlineWifi2Bar } from "react-icons/md";
import { MdOutlineWifi1Bar } from "react-icons/md";
import { MdOutlineSignalWifiStatusbarNull } from "react-icons/md";
import { viewNames } from "app/configs/routesNames";
import { useTranslation } from "react-i18next";
import { translate } from "src/utilities/utils";
import { RiSignalWifiErrorLine } from "react-icons/ri";
import { IoMdRefresh } from "react-icons/io";
import { PiBluetoothBold } from "react-icons/pi";
import { FaWifi } from "react-icons/fa";
import { MdCable } from "react-icons/md";
import { MdOutlineReportProblem } from "react-icons/md";
import SwitchComponent from "src/components/SharingComponents/Inputs/SwitchComponent";

export default function TabContent({ pairing }) {
  const activeDeviceId = useSelector(
    (state) => state.networkDevices.activeDevice
  );
  const { t } = useTranslation("evocs");
  const handleTranslate = (textId, general = false) => {
    if (general) return translate(t, textId);
    return translate(t, textId, viewNames.INTERNAL_NETWORK_MAN_VIEW);
  };
  const activeDevice = getDeviceById(activeDeviceId);
  const [netWorkError, setNetWorkError] = useState(false);
  // Bluetooth variable
  const [firstBltScan, setFirstBltScan] = useState(true);
  const [loadingBlt, setLoadinBlt] = useState(false);
  const [allBltNetworks, setAllBltNetworks] = useState([]);
  const [connectedBltNetwork, setConnectedBltNetwork] = useState([]);
  // Wifo variable
  const [firstWifiScan, setFirstWifiScan] = useState(true);
  const [loadingWifi, setLoadingWifi] = useState(false);
  const [allWifiNetworks, setAllWifiNetworks] = useState([]);
  const [knownWifiNetworks, setKnownWifiNetworks] = useState([]);
  const [connectedWifiNetwork, setConnectedWifiNetwork] = useState();
  const [priorityWifiNetwork, setPriorityWifiNetwork] = useState();
  // Ethernet variable
  const [firstEthernetScan, setFirstEthernetScan] = useState(true);
  const [loadingEthernet, setLoadingEthernet] = useState(false);
  const [connectedEthernetNetwork, setConnectedEthernetNetwork] = useState([]);
  const [hasEthernetConnection, setHasEthernetConnection] = useState(false);

  // const savedNetworksOptions = [
  //   {
  //     label: handleTranslate("CONNECT", true),
  //     onClick: () => console.log("Connessione in corso..."),
  //   },
  //   {
  //     label: handleTranslate("EDIT", true),
  //     onClick: () => console.log("Modifica in corso..."),
  //   },
  //   {
  //     label: handleTranslate("FORGET"),
  //     onClick: () => console.log("Dimentica in corso..."),
  //   },
  // ];

  // const newNetworkOptions = [
  //   {
  //     label: handleTranslate("CONNECT", true),
  //     onClick: (port, macAddress, choice) =>
  //       connectDisconnectBltDevices(port, macAddress, choice),
  //   },
  // ];

  const getWifiQualityIcon = (quality) => {
    if (!quality) return;
    switch (quality) {
      case 1:
        return <MdOutlineWifi1Bar />;
      case 2:
        return <MdOutlineWifi2Bar />;
      case 3:
        return <MdOutlineWifi />;
      default:
        return <MdOutlineSignalWifiStatusbarNull />;
    }
  };

  const scanBltNetworks = async () => {
    if (firstBltScan === true) setFirstBltScan(false);
    setNetWorkError(false);
    setLoadinBlt(true);
    try {
      await scanBlt(activeDevice.port).then((res) => setAllBltNetworks(res));
      await readConnectedBltDevices(activeDevice.port).then((res) =>
        setConnectedBltNetwork(res)
      );
    } catch (error) {
      setNetWorkError(true);
    } finally {
      setLoadinBlt(false);
    }
  };

  const scanWifiNetworks = async () => {
    if (firstWifiScan === true) setFirstWifiScan(false);
    setNetWorkError(false);
    setLoadingWifi(true);
    try {
      await scanWifi(activeDevice.port).then((res) => setAllWifiNetworks(res));
      await readNetData(activeDevice.port).then((res) => {
        setKnownWifiNetworks(res?.ssidList ?? []);
        setConnectedWifiNetwork(res?.connected);
        setPriorityWifiNetwork(res?.priority);
      });
    } catch (error) {
      setNetWorkError(true);
    } finally {
      setLoadingWifi(false);
    }
  };

  const scanEthernetNetwork = async () => {
    if (firstEthernetScan === true) setFirstEthernetScan(false);
    setNetWorkError(false);
    setLoadingEthernet(true);
    try {
      await checkEthernet(activeDevice.port).then((res) => {
        if (checkEthernet === false) setHasEthernetConnection(false);
        else setHasEthernetConnection(true);
        setConnectedEthernetNetwork(res);
      });
    } catch (error) {
      setNetWorkError(true);
    } finally {
      setLoadingEthernet(false);
    }
  };

  const NetworkError = ({ refresh }) => {
    return (
      <div className="flex flex-col items-center justify-start space-y-10 p-10">
        <div className="flex items-center gap-10">
          {/* <div>
            <RiSignalWifiErrorLine className="text-[30px] md:text-[35px]" />
          </div> */}
          <span className="text-lg md:text-2xl">
            {handleTranslate("DEVICE_UNREACHABLE")}
          </span>
        </div>
        <button onClick={refresh}>
          <IoMdRefresh size={50} />
        </button>
      </div>
    );
  };

  const WifiMenu = () => {
    if (netWorkError) return <NetworkError refresh={scanWifiNetworks} />;

    return (
      <>
        {loadingWifi ? (
          <LoadingGif />
        ) : firstWifiScan ? (
          <div className="flex justify-center items-center p-10">
            <button
              className="w-full md:w-3/4 h-[70px] border border-white 
              hover:border-green-300 hover:text-green-300 rounded-lg 
              uppercase grid grid-flow-col auto-cols-max items-center justify-center space-x-10"
              onClick={scanWifiNetworks}
            >
              <FaWifi size={20} />
              <span>{handleTranslate("SCAN_WIFI")}</span>
            </button>
          </div>
        ) : (
          <>
            <div className="flex justify-between items-center pb-10">
              {priorityWifiNetwork && connectedWifiNetwork !== "" && (
                <span className="text-greenCustom font-regular text-lg">
                  {handleTranslate("PRIORITY_NETWORK")} : {priorityWifiNetwork}{" "}
                </span>
              )}
              {connectedWifiNetwork && connectedWifiNetwork !== "" && (
                <h3>
                  {handleTranslate("CONNECTED_TO")}:{" "}
                  <span className="text-greenCustom font-bold text-xl">
                    {connectedWifiNetwork}
                  </span>{" "}
                </h3>
              )}
            </div>
            <h2 className="font-bold uppercase text-green-300">
              {handleTranslate("SAVED_NETWORKS")}
            </h2>
            <div>
              {knownWifiNetworks.map((wifiSsid, i) => (
                <div
                  key={i}
                  className="p-1 lg:p-2 border-b border-white/10 flex justify-between items-center"
                >
                  <div className="flex gap-5 py-4">
                    <span className="font-bold">SSID: {wifiSsid}</span>
                  </div>
                  <div>
                    <NetworkMenu
                      items={[
                        {
                          label: handleTranslate("CONNECT", true),
                          onClick: () => console.log("Connessione in corso..."),
                        },
                        {
                          label: handleTranslate("EDIT", true),
                          onClick: () => console.log("Modifica in corso..."),
                        },
                        {
                          label: handleTranslate("FORGET"),
                          onClick: () => console.log("Dimentica in corso..."),
                        },
                      ]}
                    />
                  </div>
                </div>
              ))}
            </div>
            <div className="flex justify-between items-center py-10">
              <h2 className="font-bold uppercase text-green-300">
                {handleTranslate("AVAILABLE_NETWORKS")}
              </h2>
              <button onClick={scanWifiNetworks}>
                <IoReload className="text-3xl" />
              </button>
            </div>
            <div>
              {allWifiNetworks.map((wifi, i) => (
                <div
                  key={i}
                  className="p-2 border-b border-white/10 flex justify-between items-center"
                >
                  <div className="flex w-[180px] lg:w-auto">
                    <span className="font-bold truncate">
                      SSID: {wifi?.ssid}
                    </span>
                  </div>
                  <div className="flex justify-center items-center gap-3">
                    <span className="font-bold text-2xl text-green-300">
                      {" "}
                      {getWifiQualityIcon(wifi?.quality)}{" "}
                    </span>
                    <NetworkMenu
                      items={[
                        {
                          label: handleTranslate("CONNECT", true),
                          onClick: () => console.log("Connessione in corso..."),
                        },
                        {
                          label: handleTranslate("EDIT", true),
                          onClick: () => console.log("Modifica in corso..."),
                        },
                        {
                          label: handleTranslate("FORGET"),
                          onClick: () => console.log("Dimentica in corso..."),
                        },
                      ]}
                    />
                  </div>
                </div>
              ))}
            </div>
          </>
        )}
      </>
    );
  };

  const EthernetMenu = () => {
    if (netWorkError) return <NetworkError refresh={scanEthernetNetwork} />;

    return (
      <>
        {loadingEthernet ? (
          <LoadingGif />
        ) : firstEthernetScan ? (
          <div className="flex justify-center items-center p-10">
            <button
              className="w-full md:w-3/4 h-[70px] border border-white 
              hover:border-green-300 hover:text-green-300 rounded-lg 
              uppercase grid grid-flow-col auto-cols-max items-center justify-center space-x-10"
              onClick={scanEthernetNetwork}
            >
              <MdCable size={20} />
              <span className="flex">{handleTranslate("SCAN_ETHERNET")}</span>
            </button>
          </div>
        ) : hasEthernetConnection ? (
          <div className="flex flex-col items-center justify-start space-y-10 p-10">
            <div className="flex items-center gap-10">
              <div>
                <MdOutlineReportProblem className="text-[30px] md:text-[35px]" />
              </div>
              <span className="text-lg md:text-2xl">
                {handleTranslate("NO_ETHERNET_CONNECTION")}
              </span>
            </div>
          </div>
        ) : (
          <>
            <h3>
              {handleTranslate("CONNECTED_TO")}:{" "}
              <span className="text-greenCustom font-bold text-xl">
                {connectedEthernetNetwork}
              </span>{" "}
            </h3>
          </>
        )}
      </>
    );
  };

  const getBltSignalQualityString = (rssi) => {
    if (rssi === undefined || rssi === null) return;
    const quality = Number(rssi);

    if (quality >= -50) {
      return handleTranslate("EXCELLENT");
    } else if (quality >= -70 && quality <= -51) {
      return handleTranslate("OPTIMAL");
    } else if (quality >= -80 && quality <= -71) {
      return handleTranslate("GOOD");
    } else {
      return handleTranslate("WEAK");
    }
  };

  const BluetoothMenu = ({ pairing }) => {
    // const handlePairing = () => {
    //   setPairing(!pairing);
    //   handlePairable(activeDevice.port, 1);
    //   console.log(pairing);
    // };

    if (netWorkError) return <NetworkError refresh={scanBltNetworks} />;
    return (
      <>
        {/* {connectedBltNetwork && connectedBltNetwork === true && (
          <div className="flex justify-between items-center pb-5">
            <>
              <h3>
                {handleTranslate("CONNECTED_TO")} :{" "}
                <span className="text-greenCustom font-bold text-2xl">
                  {connectedBltNetwork ?? handleTranslate("NOT_CONNECTED")}
                </span>{" "}
              </h3>
              <button>
                <FaPlus className="text-3xl" />
              </button>
            </>
          </div>
        )} */}
        {/* <h2>Dispositivi salvati</h2>
    <div>
      {knownBltNetworks.map(
        (device, i) =>
          device.isSaved && (
            <div
              key={i}
              className="p-2 border-b border-white/10 flex justify-between items-center"
            >
              <div className="flex gap-5 py-2 lg:py-0">
                <span className="font-bold">Nome: {device.name}</span>
                <span> {device.type}</span>
              </div>
              <div>
                <NetworkMenu items={savedNetworksOptions} />
              </div>
            </div>
          )
      )}
    </div> */}
        {loadingBlt ? (
          <LoadingGif />
        ) : firstBltScan ? (
          <div className="flex justify-center items-center p-10">
            <button
              className="w-full md:w-1/2 h-[70px] border border-white 
              hover:border-green-300 hover:text-green-300 rounded-lg 
              uppercase grid grid-flow-col auto-cols-max items-center justify-center space-x-10"
              onClick={scanBltNetworks}
            >
              <PiBluetoothBold size={20} />
              <span>{handleTranslate("SCAN_BLT")}</span>
            </button>
          </div>
        ) : (
          <>
            <div className="flex justify-between items-center">
              <h2 className="text-green-300 uppercase font-bold">
                {" "}
                {handleTranslate("DEVICES_DETECTED")}
              </h2>
              <button onClick={scanBltNetworks}>
                <IoReload className="text-3xl" />
              </button>
            </div>
            <div>
              {allBltNetworks.map(
                (device, i) =>
                  !device.isSaved && (
                    <div
                      className="flex justify-between border-b border-white/10 flex-col sm:flex-row"
                      key={i}
                    >
                      <div className="flex flex-col sm:flex-row items-start sm:items-center justify-start py-14 sm:py-5 sm:gap-20 w-2/3">
                        {/* column 1 */}
                        <div className=" flex flex-col gap-6 items-start basis-1/2 py-4 ">
                          <span className="font-bold">
                            {handleTranslate("NAME", true)}:
                          </span>
                          <span className="text-xl">{device.name}</span>
                        </div>
                        {/* column 2 */}
                        <div className=" flex flex-col gap-6 items-start basis-1/2 py-4 ">
                          <span className="font-bold">MAC:</span>
                          <span className="text-xl">{device.mac}</span>
                        </div>
                      </div>
                      {/* column 3 */}
                      <div className="flex justify-end items-center gap-5 ml-auto order-first sm:order-last w-1/3">
                        <span className="md:text-xl">
                          {getBltSignalQualityString(device.rssi)}
                        </span>
                        <NetworkMenu
                          type="bluetooth"
                          items={[
                            {
                              label: handleTranslate("CONNECT", true),
                              onClick: () =>
                                connectDisconnectBltDevices(
                                  activeDevice?.port,
                                  device?.mac
                                ),
                            },
                          ]}
                          pairing={pairing}
                        />
                      </div>
                    </div>
                  )
              )}
            </div>
          </>
        )}
      </>
    );
  };

  // useEffect(() => {
  // scanBltNetworks();
  // scanWifiNetworks();
  // }, []);

  return (
    <>
      <TabPanel value="1">
        <div className="flex flex-col gap-5">
          <BluetoothMenu pairing={pairing} />
          {/* {firstBltScan ? <ScanBlt /> : <BluetoothMenu />} */}
        </div>
      </TabPanel>
      <TabPanel value="2">
        <div className="flex flex-col gap-5">
          <WifiMenu />
          {/* {firstWifiScan ? <ScanWifi /> : <WifiMenu />} */}
        </div>
      </TabPanel>
      <TabPanel value="3">
        <div className="flex flex-col gap-5">
          <EthernetMenu />
        </div>
      </TabPanel>
    </>
  );
}

{
  /* <LoadingButton
onClick={scanWifiNetworks}
style={{
  padding: "4rem 6rem",
  width: "150px",
  borderRadius: "10px",
  textTransform: "uppercase",
  backgroundColor: "#F44336",
}}
>
  {handleTranslate("SCAN_WIFI")}
</LoadingButton>  */
}
