import { Checkbox, FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { translate } from "src/utilities/utils";

const ViewSelect = ({style, viewsId, defaultValue, value, onChange, placeholder, required=false, multiple=false, disabled=false, onlyActivableViews=false}) => {
  const {t} = useTranslation("evocs");
  const views = useSelector((state) => state.configuration.constants.view_name);
  const options = useMemo(() => {
    if(viewsId) {
      return viewsId;
    }else if(!onlyActivableViews){
      return Object.keys(views);
    }else return Object.keys(views).filter((viewId) => views[viewId].activable === 1)
  }, [viewsId, onlyActivableViews]);
  const [val, setValue] = useState(defaultValue !== undefined ? defaultValue : multiple ? [] : "")

  const handleTranslate = (textId) => {
    return translate(t, textId);
  };

  const placeholderText = useMemo(() => {
    const text = placeholder ?? handleTranslate("VIEWS");
    return !required ? text : text+"*";
  }, [t, placeholder, required]);

  useEffect(() => {
    value !== undefined && setValue(value);
  }, [value]);

  const handleChange = (newValue) => {
    setValue(newValue);
    onChange?.(newValue)
  }


  return (
    <FormControl disabled={disabled} sx={{...style, width: "100%", minWidth: "100px", maxWidth: "300px"}}>
      <InputLabel id="view-select-label">{placeholderText}</InputLabel>
      <Select
        labelId="view-select-label"
        value={val}
        multiple={multiple}
        renderValue={(selected) => multiple ? selected.map((viewId) => views[viewId]?.view_name).join(", ") : views[selected]?.view_name}
        label={placeholderText}
        onChange={(e) => handleChange(e.target.value)}
      >
        {options?.map((viewId, index) => (
        <MenuItem key={index} value={viewId}>
        {multiple && <Checkbox checked={val?.indexOf(viewId) > -1} /> }
        {views[viewId]?.view_name}
        </MenuItem>
        ))}
      </Select>
    </FormControl>     
  )
}

export default ViewSelect;