import { useCallback, useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import GraphSwitcher from "../StationHandling/GraphSwitcher";
import LoadingGif from "../SharingComponents/LoadingGif";
import { viewNames } from "app/configs/routesNames";
import SingleStationHandlingMap from "./SingleStationHandlingMap";
import useFilters from "src/app/hooks/useFilters";
import useActions from "src/app/hooks/useActions";
import { getCuMeasures } from "app/store/evocsSlice/controlUnits/cuActions";
import { getStationSensors } from "app/store/evocsSlice/stationsMongo/stationsMongoActions";
import {
  isMeasureGps,
  isMeasureMultimedia,
  isSensorGps,
  isSensorMultimedia,
} from "app/store/evocsSlice/sensors/sensorsActions";
import { Paper } from "@mui/material";
import { setFilter } from "app/store/evocsSlice/filters/filtersActions";

const viewName = viewNames.SINGLE_STATION_VIEW;

const ChildrensList = ({
  stationId,
  station,
  displayMap,
  enableLastPositionMap,
  enableMapRoute,
  enableEcoPath,
  isLoading = false,
  isLive = false,
  devices,
  setDevices,
}) => {


  // console.log('station', station);

  const userActions = useActions(viewNames.SINGLE_STATION_VIEW);
  const controlUnits = useSelector((state) => state.controlUnits.list);
  const sensors = useSelector((state) => state.sensors.list);
  const filters = useFilters(viewName);
  const [children, setChildren] = useState([]);
  const allStationMeasures = useMemo(() => {
    const measures = [];
    getStationSensors(stationId).forEach((sensorId) => {
      sensors[sensorId]?.measures.forEach(
        (measureId) =>
          !isMeasureGps(measureId) &&
          !isMeasureMultimedia(measureId) &&
          measures.push({
            measureId: measureId,
            sensor: { sensorId, ...sensors[sensorId] },
          })
      );
    });
    return measures;
  }, [stationId]);

  const timeRangeInView = filters.timeRange;
  const measureSelect = filters.measure;
  const devicesNameSelect = filters?.devicesName;
  const newCuElement = useCallback(
    (
      cuId,
      isCompare = false,
      selectedCompare = [],
      selectedSensor = null,
      selectedMeasure = null
    ) => {
      return {
        id: cuId,
        isCompare,
        selectedCompare,
        selectedSensor,
        selectedMeasure,
      };
    },
    []
  );

  const newSensorElement = useCallback(
    (
      sensorId,
      isCompare = false,
      selectedCompare = null,
      selectedMeasure = null
    ) => {
      return { id: sensorId, isCompare, selectedCompare, selectedMeasure };
    },
    []
  );

  useEffect(() => {
    let list = [];

    station.list_sensors.forEach((sensorId) => {
      if ( !devicesNameSelect?.selected || (devicesNameSelect?.selected?.length === 0 && !isSensorMultimedia(sensorId))) {
        
        list.push(newSensorElement(sensorId, false, [], measureSelect.selected?.[measureSelect?.selected?.length -1]));
      } else {

        const foundElement = devicesNameSelect?.selected?.find(
          (el) => el.id === sensorId
        );
        if (foundElement) {
          list.push(newSensorElement(foundElement.id, false, [], measureSelect.selected?.[measureSelect?.selected?.length -1]));
        }
      }
    });

    station.list_cu.forEach((cuId) => {
      const sensorsByCu = controlUnits[cuId].list_sensors;
      const sensorByMeasure = sensorsByCu.find(id=> id === measureSelect.selected )
      if ( !devicesNameSelect?.selected || devicesNameSelect?.selected?.length === 0) {
        list.push( newCuElement(cuId, false, [], sensorByMeasure, measureSelect.selected?.[measureSelect?.selected?.length -1] ) );
      } else {
        const foundElement = devicesNameSelect?.selected?.find(
          (el) => el.id === cuId
        );
        if (foundElement) {
          list.push(newCuElement(foundElement.id, false, [], sensorByMeasure, measureSelect.selected?.[measureSelect?.selected?.length -1]));
        }
      }
    });
    
    const listSensors = list
    .filter((device) => devices?.sensors.find((el) => el.id === device.id))
      .filter((el) => el);
    const listCus = list
      .filter((device) => devices?.control_units.find((el) => el.id === device.id))
      .filter((el) => el);
      

    setChildren(listSensors.concat(listCus));
  }, [devices]);

  const handleChangeSensor = (unitId, sensorId) => {
    const newChildren = [...children];
    const unitIndex = children.findIndex((element) => element.id === unitId);
    newChildren[unitIndex].selectedSensor = sensorId;
    setChildren(newChildren);
  };

  const handleChangeMeasure = (unitId, measureId) => {
    const newChildren = [...children];
    const unitIndex = children.findIndex((element) => element.id === unitId);
    newChildren[unitIndex].selectedMeasure = measureId;
    setChildren(newChildren);
  };

  const handleChangeCompare = (unitId, isCompare) => {
    const newChildren = [...children];
    const unitIndex = children.findIndex((element) => element.id === unitId);
    newChildren[unitIndex].isCompare = isCompare;
    setChildren(newChildren);
  };

  //in input abbiamo l'array di oggetti con le misure da comparare e l'id del device
  const handleChangeSelectedCompare = (unitId, compare) => {
    const newChildren = [...children];
    // Cerco l'indice del children che corrisponde allo unitId in input (i children saranno la centralina e i sensori)
    const unitIndex = children.findIndex((element) => element.id === unitId);
    // console.log('ChildrenList compare', compare);
    // lo aggiungo nella prop selectedCompare
    newChildren[unitIndex].selectedCompare = compare;
    setChildren(newChildren);
  };

  // console.log('children', children);

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        gap: "2rem",
        marginBottom: "4rem",
      }}
    >
      <SingleStationHandlingMap
        stationId={stationId}
        displayMap={displayMap}
        enableLastPosition={enableLastPositionMap}
        enableRoute={enableMapRoute}
        enableEcoPath={enableEcoPath}
        userActions={userActions}
      />
      {!isLoading ? (
        children.map((unit) => (
          <Paper key={unit.id}>
            <GraphSwitcher
              disableSensorFilter
              userActions={userActions}
              unit={unit}
              startDate={timeRangeInView.startDate}
              endDate={timeRangeInView.endDate}
              sensorGraphShadow={false}
              compareOptions={allStationMeasures}
              onChangeSensor={handleChangeSensor}
              onChangeMeasure={handleChangeMeasure}
              onChangeCompare={handleChangeCompare}
              onChangeSelectedCompare={handleChangeSelectedCompare}
              isLive={isLive}
            />
          </Paper>
        ))
      ) : (
        <LoadingGif />
      )}
    </div>
  );
};

export default ChildrensList;
