import { sharingComponentNames } from "app/configs/routesNames";
import { createLanguageObject } from "src/utilities/utils";


const IT = {
    DROP_FILES: "Rilascia i file qui",
    DRAG_FILES: "Trascina qui i file o clicca per selezionarli",
    FILE_UPLOADED: "File caricati",
    UPLOAD_DISABLED: "Caricamento file disabilitato",
    RELATED_DOCUMENTS: "Documenti correlati",
    CLICK_TO_DOWNLOAD: "Clicca sull'icona del file per scaricarlo"
};

const FILE_UPLOADER_IT = createLanguageObject(IT, sharingComponentNames.FILE_UPLOADER);

export default FILE_UPLOADER_IT;