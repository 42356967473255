import Button from "@mui/material/Button";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import MenuItem from "@mui/material/MenuItem";
import Popover from "@mui/material/Popover";
import Typography from "@mui/material/Typography";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { selectLanguages } from "src/app/store/i18nSlice/i18nSlice";
import { changeLanguage } from "src/app/store/i18nSlice/i18nActions";
import { updateUser } from "app/store/evocsSlice/user/userAction";
import { useTranslation } from "react-i18next";
import { useEffect } from "react";
import useTenantPalette from "src/app/hooks/useTenantPalette";

function LanguageSwitcher({ textColor = "white" }) {
  // const currentLanguage = useSelector(selectCurrentLanguage);
  const languages = useSelector(selectLanguages);
  const [menu, setMenu] = useState(null);
  const dispatch = useDispatch();
  const currentUser = useSelector((state) => state.user);
  const { t, i18n } = useTranslation("evocs");
  const currentLanguage = i18n.language;
  const selectedTheme = useTenantPalette();


  const langMenuClick = (event) => {
    setMenu(event.currentTarget);
  };

  const langMenuClose = () => {
    setMenu(null);
  };

  function handleLanguageChange(lng) {
    // changeLanguage(lng.id);

    if (currentUser && Object.keys(currentUser).length > 0) {
      updateUser(currentUser.sqlId, undefined, lng.id, currentUser.proPic);
    } else {
      changeLanguage(lng.id);
      window.localStorage.setItem("evocs_lang", lng.id);
    }
    langMenuClose();
  }

  return (
    <>
      <Button className="h-40 w-64" onClick={langMenuClick}>
        <img
          className="mx-4 min-w-20"
          src={`assets/images/flags/${currentLanguage.toLocaleUpperCase()}.svg`}
          alt={currentLanguage.toLocaleUpperCase()}
        />

        <Typography className="mx-4 font-semibold uppercase" color={textColor}>
          {currentLanguage.toLocaleUpperCase()}
        </Typography>
      </Button>

      <Popover
        open={Boolean(menu)}
        anchorEl={menu}
        onClose={langMenuClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        sx={{ paddingX: 8, marginTop: 1 }}
      >
        {languages.map((lng, index) => (
          <div style={{ background: selectedTheme?.primaryColor}} key={index}>
            <MenuItem key={lng.id} onClick={() => handleLanguageChange(lng)}>
              <ListItemIcon className="min-w-40">
                <img
                  // className="min-w-20"
                  style={{ width: 20, height: 15 }}
                  src={`assets/images/flags/${lng.flag.toLocaleUpperCase()}.svg`}
                  alt={lng.title}
                />
              </ListItemIcon>
              <ListItemText primary={lng.title} />
            </MenuItem>
          </div>
        ))}
      </Popover>
    </>
  );
}

export default LanguageSwitcher;
