import React from 'react'
import TestPageHeader from 'src/components/TestPage/TestPageHeader';
import TestPageContent from 'src/components/TestPage/TestPageContent';
import WithAuth from './WithAuth';
import { viewNames } from 'app/configs/routesNames';
import CustomPageCarded from './CustomPageCarded';

const TestPage = () => {
  return (
    <WithAuth
      viewName={viewNames.TEST_PAGE_VIEW}
      component={
        <CustomPageCarded
          header={<TestPageHeader />}
          content={<TestPageContent />}
        />
      }
    />
  )
}

export default TestPage