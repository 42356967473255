import React, {
  useState,
  useEffect,
  useLayoutEffect,
  isValidElement,
  useCallback,
  useContext,
} from "react";
import {
  Button,
  Checkbox,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
  TextField,
} from "@mui/material";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import EditIcon from "@mui/icons-material/Edit";
import DownloadComponent from "./DownloadComponent/DownloadComponent";
import { viewNames } from "app/configs/routesNames";
import { useTranslation } from "react-i18next";
import { translate } from "src/utilities/utils";
import { get } from "lodash";
import { setGlobalWarning } from "app/store/evocsSlice/globalError/errorActions";
import { ModalContext } from "src/views/WithAuth";
import useMobile from "src/app/hooks/useMobile";

const SearchInput = ({ value, defaultValue = "", onChange, onSearch }) => {
  const [val, setValue] = useState(defaultValue);
  const [isChanged, setChanged] = useState(false);

  useEffect(() => {
    value && setValue(value);
  }, [value]);

  useEffect(() => {
    if (!isChanged) {
      setChanged(true);
      return;
    }

    if (val === "") {
      onSearch && onSearch();
      return;
    }

    const timeout = onSearch && setTimeout(() => onSearch(val), 700);

    return () => {
      onSearch && clearTimeout(timeout);
    };
  }, [val]);

  const handleChange = (newValue) => {
    setValue(newValue);
    onChange && onChange(newValue);
  };

  const { t } = useTranslation("evocs");

  const handleTranslate = (textId, general = false) => {
    if (general) return translate(t, textId);
    return translate(t, textId, viewNames.DASHBOARD_VIEW);
  };

  return (
    <TextField
      type="search"
      label={handleTranslate("SEARCH", true) + "..."}
      value={val}
      onChange={(e) => handleChange(e.target.value)}
      size="small"
      sx={{
        maxHeight: "40px",
        borderRadius: "30px",
        textAlign: "start",
        display: "flex",
        alignItems: "center",
        justifyContent: "start",
        marginLeft: "1rem",
      }}
    />
  );
};

const stringToDOM = (htmlString) => {
  const parser = new DOMParser();
  const doc = parser.parseFromString(htmlString, "text/html");
  return doc?.body;
};

const TableHeadContent = ({
  userActions,
  tableTitle,
  columns,
  selectable,
  editMode,
  canDelete,
  downloadFileProps,
  onChangeEditMode,
  onDeleteRow,
  onSearch,
  disabledSearch,
  onCustomAction,
  customActionTitle,
  searchTexValue,
  sortable,
  orderBy,
  order,
  handleSort, // Callback per gestire l'ordinamento
  textAlign,
}) => {
  const { t } = useTranslation("evocs");
  const [xs] = useMobile();
  const handleDownloadFile = downloadFileProps?.handleDownloadFile;
  const fileName = downloadFileProps?.fileName;

  const handleTranslate = (textId, general = false) => {
    if (general) return translate(t, textId);
    return translate(t, textId, viewNames.DASHBOARD_VIEW);
  };

  return (
    <>
      {(tableTitle || selectable || !disabledSearch) && (
        <TableRow sx={{ marginLeft: 0, paddingLeft: 0 }}>
          <TableCell
            align={textAlign}
            colSpan={Object.keys(columns).length}
            sx={{ marginLeft: 0, paddingLeft: 0 }}
          >
            {tableTitle && (
              <div
                style={{
                  marginBottom: "15px",
                  display: "flex",
                  gap: "1rem",
                  alignItems: "center",
                  marginLeft: '1rem'
                }}
              >
                <span className="text-xl font-semibold">{tableTitle}</span>
                {downloadFileProps && (
                  <DownloadComponent
                    fromTable
                    userActions={userActions}
                    onDownloadFile={handleDownloadFile}
                    fileName={fileName}
                  />
                )}
              </div>
            )}
            <div style={{ display: "flex", marginBottom: 10 }}>
              {!disabledSearch && (
                <SearchInput onSearch={onSearch} value={searchTexValue} />
              )}
              {selectable &&
                (editMode ? (
                  <div style={{ display: "flex", gap: 4, marginLeft: "20px" }}>
                    <Button
                      disabled={!canDelete}
                      onClick={onCustomAction}
                      sx={{ color: "blue" }}
                    >
                      {customActionTitle}
                    </Button>
                    <Button
                      sx={{ color: "red" }}
                      disabled={!canDelete}
                      onClick={onDeleteRow}
                    >
                      {handleTranslate("DELETE", true)}
                    </Button>
                    <Button onClick={() => onChangeEditMode(false)}>
                      {handleTranslate("CANCEL", true)}
                    </Button>
                  </div>
                ) : (
                  <IconButton onClick={() => onChangeEditMode(true)}>
                    <EditIcon />
                  </IconButton>
                ))}
              {!tableTitle && downloadFileProps && (
                <DownloadComponent
                  fromTable
                  userActions={userActions}
                  onDownloadFile={handleDownloadFile}
                  fileName={fileName}
                />
              )}
            </div>
          </TableCell>
        </TableRow>
      )}
      <TableRow>
        {selectable && editMode && (
          <TableCell
            align={textAlign}
            valign="middle"
            sx={{ marginLeft: 0, paddingLeft: 0 }}
          ></TableCell>
        )}
        {Object.keys(columns).map((columnId, i) => (
          <TableCell
            key={columnId}
            align={textAlign}
            valign="middle"
            sx={{
              fontWeight: "semibold",
              paddingTop: tableTitle ? "0rem" : "2.5rem",
              whiteSpace: "nowrap",
              marginLeft: 0,
              paddingLeft: 2,
              backgroundColor: i === 0 && xs && "white",
              position: i === 0 && xs && "sticky",
              left: 0,
            }}
          >
            {sortable ? (
              <TableSortLabel
                active={orderBy === columnId} // Evidenzia la colonna attualmente ordinata
                direction={orderBy === columnId ? order : "asc"} // Direzione dell'ordinamento
                onClick={() => handleSort(columnId)} // Gestisce il clic per ordinare
              >
                {columns[columnId]}
              </TableSortLabel>
            ) : (
              !sortable && columns[columnId]
            )}
          </TableCell>
        ))}
      </TableRow>
    </>
  );
};

const TableBodyContent = ({
  data,
  rows,
  pageNumber,
  columns,
  clickable,
  editMode = false,
  selectedRows,
  onClickRow,
  onClickCell,
  onSelectRow,
  statusStationTab,
  sortable,
  orderBy,
  handleSort,
  textAlign,
}) => {
  const className = clickable ? "station-item-list close" : "";
  const offset = pageNumber * rows.length;
  const { t } = useTranslation("evocs");
  const redRgba = "rgba(244, 67, 54,  0.9)";
  const yellowRgba = "rgba(255, 235, 59, 0.9)";
  const greenRgba = "rgba(133, 207, 87, 0.9)";
  const greyRgba = "rgba(158, 158, 158,0.9)";
  const [xs] = useMobile();

  const handleTranslate = (textId, general = false) => {
    if (general) return translate(t, textId);
    return translate(t, textId, viewNames.ADMINISTRATION_VIEW);
  };

  const getCell = (columnId, index, offset) => {
    const cellElement = data[index + offset]?.[columnId];
    if (!cellElement) return null;
    if (isValidElement(cellElement)) {
      return cellElement;
    }
    return String(cellElement);
  };

  const handleClickRow = (rowIndex) => {
    clickable && onClickRow && onClickRow(rowIndex);
  };

  const handleClickCell = (rowIndex, columnId, index) => {
    clickable && onClickCell && onClickCell(rowIndex, columnId, index);
  };

  const isRowChecked = (index) => {
    return data[index].id
      ? selectedRows.includes(data[index].id)
      : selectedRows.includes(index);
  };

  const getStatusColor = useCallback(
    (columnId, index, offset) => {
      if (columnId !== "status") return;
      const status = data[index + offset]?.[columnId];
      switch (status) {
        case handleTranslate("NOT_WORKING", true):
          return redRgba;
        case handleTranslate("PARTIALLY_WORKING", true):
          return yellowRgba;
        case handleTranslate("WORKING", true):
          return greenRgba;
        default:
          return greyRgba;
      }
    },
    [t, rows]
  );

  return (
    data?.length > 0 &&
    rows.map((e, index) =>
      data[index + offset] ? (
        <TableRow
          key={index + offset}
          className={className}
          sx={{ cursor: clickable && "pointer", marginLeft: 0, paddingLeft: 0 }}
          selected={selectedRows.includes(index + offset)}
          onClick={() => handleClickRow(index + offset)}
        >
          {editMode && (
            <TableCell
              sx={{ color: "inherit", paddingLeft: 0 }}
              align={textAlign}
              onClick={(e) => e.stopPropagation()}
            >
              <Checkbox
                disableRipple={true}
                checked={isRowChecked(index + offset)}
                onChange={(e, value) =>
                  onSelectRow(data[index + offset], index + offset, value)
                }
              />
            </TableCell>
          )}
          {Object.keys(columns).map((columnId, i) => (
            <TableCell
              key={i}
              sx={{
                color: "inherit",
                whiteSpace: "wrap",
                backgroundColor: statusStationTab
                  ? getStatusColor(columnId, index, offset)
                  : i === 0
                  ? "rgb(224,224,224)"
                  : "none",
                paddingLeft: 2,
                position: i === 0 && xs && "sticky",
                width: i === 0 && xs && "200px",
                border: i !== 0 && "1px solid rgb(224,224,224)",
                left: 0,
              }}
              align={textAlign}
              onClick={() => handleClickCell(index + offset, columnId, i)}
            >
              {sortable && columns[columnId]?.sortable ? (
                <TableSortLabel
                  active={orderBy === columnId}
                  direction={orderBy === columnId ? order : "asc"}
                  onClick={() => handleSort(columnId)}
                >
                  {columns[columnId].label}
                </TableSortLabel>
              ) : (
                getCell(columnId, index, offset)
              )}
            </TableCell>
          ))}
        </TableRow>
      ) : (
        <TableRow key={index + offset}>
          {Object.keys(columns).map((columnId, i) => (
            <TableCell
              key={i}
              sx={{ whiteSpace: "nowrap", marginLeft: 0, paddingLeft: 0 }}
              align={textAlign}
            />
          ))}
        </TableRow>
      )
    )
  );
};

const TableComponent = ({
  style = {},
  userActions,
  columns,
  data,
  clickable = false,
  selectable = false,
  selectedRows,
  onClickRow,
  onClickCell,
  onSelectRows,
  tableTitle,
  downloadFileProps,
  onDeleteRows,
  onSearch,
  rowsForPage = 10,
  paper = true,
  disabledSearch = false,
  statusStationTab = false,
  onCustomAction,
  customActionTitle = false,
  searchTexValue,
  handleOpen,
  sortable,
  textAlign = "left",
}) => {
  const modalContext = useContext(ModalContext);
  const [tableRowData, setTableRowData] = useState([]);
  const [pageNumber, setPageNumber] = useState(0);
  const [totPageNumber, setTotPageNumber] = useState(0);
  const rowsTable = new Array(rowsForPage).fill(0);
  const [selected, setSelected] = useState([]);
  const [editMode, setEditMode] = useState(false);
  const [canDelete, setCanDelete] = useState(false);

  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState(""); // Stato per la colonna ordinata

  useEffect(() => {
    setTableRowData(data);
  }, [data]);

  const handleSort = (columnId) => {
    if (!sortable) return;
    const isAsc = orderBy === columnId && order === "asc";
    const newOrder = isAsc ? "desc" : "asc";
    setOrder(newOrder);
    setOrderBy(columnId);

    const sortedData = [...tableRowData].sort((a, b) => {
      if (newOrder === "asc") {
        return a[columnId] > b[columnId] ? 1 : -1;
      }
      return a[columnId] < b[columnId] ? 1 : -1;
    });

    setTableRowData(sortedData);
  };

  useEffect(() => {
    setPageNumber(0);
    calculateTotPageNumber();
  }, [data]);

  useLayoutEffect(() => {
    selectedRows !== undefined && setSelected(selectedRows);
  }, [selectedRows]);

  useLayoutEffect(() => {
    if (editMode === false) {
      setSelected([]);
      onSelectRows && onSelectRows([]);
    }
  }, [editMode]);

  useLayoutEffect(() => {
    if (selected.length > 0) setCanDelete(true);
    else setCanDelete(false);
  }, [selected]);

  const calculateTotPageNumber = () => {
    const n = Math.ceil(data?.length / rowsTable.length);
    setTotPageNumber(n);
  };

  const changePage = (value) => {
    if (
      (pageNumber === 0 && value < 0) ||
      (pageNumber === totPageNumber - 1 && value > 0)
    )
      return;
    setPageNumber((n) => n + value);
  };

  const handleSelectRow = (rowElement, rowIndex, checked) => {
    let newSelected = [];
    if (checked) {
      newSelected = rowElement.id
        ? [...selected, rowElement.id]
        : [...selected, rowIndex];
    } else {
      newSelected = rowElement.id
        ? selected.filter((id) => id !== rowElement.id)
        : selected.filter((index) => index !== rowIndex);
    }
    setSelected(newSelected);
    onSelectRows && onSelectRows(newSelected);
  };

  const handleDeleteRow = () => {
    setGlobalWarning(true, "MODAL_SURE_TO_DELETE_WARNING");
    modalContext.setConfirmCallback(() => {
      onDeleteRows?.(selected);
      setEditMode(false);
    });
  };

  const handleSelectionActions = () => {
    setEditMode(false);
    onCustomAction && onCustomAction(selected);
  };

  return (
    <TableContainer
      component={paper ? Paper : null}
      sx={{ ...style, position: "relative" }}
    >
      <Table>
        <TableHead>
          <TableHeadContent
            userActions={userActions}
            tableTitle={tableTitle}
            columns={columns}
            selectable={selectable}
            editMode={editMode}
            canDelete={canDelete}
            downloadFileProps={downloadFileProps}
            onChangeEditMode={setEditMode}
            onDeleteRow={handleDeleteRow}
            onSearch={onSearch}
            disabledSearch={disabledSearch}
            onCustomAction={handleSelectionActions}
            customActionTitle={customActionTitle}
            searchTexValue={searchTexValue}
            handleOpen={handleOpen}
            sortable={sortable}
            handleSort={handleSort}
            order={order}
            orderBy={orderBy}
            textAlign={textAlign}
          />
        </TableHead>
        <TableBody>
          <TableBodyContent
            data={tableRowData}
            rows={rowsTable}
            pageNumber={pageNumber}
            columns={columns}
            clickable={clickable}
            editMode={editMode}
            selectedRows={selected}
            onClickRow={onClickRow}
            onClickCell={onClickCell}
            onSelectRow={handleSelectRow}
            statusStationTab={statusStationTab}
            handleOpen={handleOpen}
            sortable={sortable}
            handleSort={handleSort}
            order={order}
            orderBy={orderBy}
            textAlign={textAlign}
          />
        </TableBody>
        {/* <TableFooter>
          <TableRow>
            <TableCell colSpan={Object.keys(columns).length} align={textAlign}>
             
            </TableCell>
          </TableRow>
        </TableFooter> */}
      </Table>
      {totPageNumber > 1 && (
        <div
          style={{
            padding: "20px 0px",
            position: "sticky",
            left: "50%",
            transform: "translateX(-50%)",
            width: "fit-content",
            marginBottom: "1rem",
          }}
        >
          <ArrowBackIosIcon className="arrow" onClick={() => changePage(-1)} />
          <span>{`${pageNumber + 1}/${totPageNumber}`}</span>
          <ArrowForwardIosIcon
            className="arrow"
            onClick={() => changePage(1)}
          />
        </div>
      )}
    </TableContainer>
  );
};

export default TableComponent;
