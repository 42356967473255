import { useEffect, useMemo } from 'react'
import { setFilter, setOdourLevelSelect, setOdourTypeSelect, setTimeIntervalValue, setTimeRangeFilter } from 'app/store/evocsSlice/filters/filtersActions'
import { viewNames } from 'app/configs/routesNames'
import OdourLevelSelect from '../SharingComponents/Inputs/OdourLevelSelect'
import OdourTypeSelect from '../SharingComponents/Inputs/OdourTypeSelect'
import { useSelector } from 'react-redux'
import useFilters from 'src/app/hooks/useFilters'
import RangeTimeFilter_v2 from '../SharingComponents/RangeTimeFilter'
import { calcIntervalDates } from '../StationHandling/utils'
const viewName = viewNames.ODOR_REPORTS_VIEW;

const OdorReportsContentHeader = ({startDate, endDate, sliderStart, sliderEnd}) => {
  const user = useSelector((state) => state.user);
  const timeRange = useSelector((state) => state.configuration.constants?.timeRange);
  const filters = useFilters(viewName);
  const timeRangeInView = filters.timeRange;
  const odourLevelSelect = filters.odour_level;
  const odourTypeSelect = filters.odour_type;
  const {enabledIntervals, defaultInterval} = useMemo(() => {
    const intervals = [];
    let defaultId = null;
     timeRangeInView.list.forEach((element) => {
      if(element.active===1) intervals.push(element._id);
      if(element.default===1) defaultId = element._id;
     });
    defaultId = timeRangeInView.selected ? timeRangeInView.selected : defaultId ? defaultId : intervals[0];
    return {enabledIntervals: intervals, defaultInterval: defaultId}
  }, [user.selectedTenant, timeRangeInView.list])


  // useEffect(() => {
  //   return () => {
  //     setTimeRangeFilter(viewName, timeRangeInView.id, undefined, null, null);
  //   }
  // }, [])

  useEffect(() => {
    if(!defaultInterval) return;
    const intervalValue = timeRange?.[defaultInterval]?.timeRange;
    const range = calcIntervalDates(intervalValue);
    setTimeRangeFilter(viewName, timeRangeInView.id, defaultInterval, range.start, range.end);
  }, [defaultInterval]);

  const handleChangeFilter = (filterId, selected) => {
    setFilter(viewName, filterId, selected);
  }

  const handleChangeInterval = (interval, start, end) => {
    setTimeRangeFilter(viewName, timeRangeInView.id, interval, start, end);
  }

  const handleChangeSlider = (start, end, value) => {
    setTimeRangeFilter(viewName, timeRangeInView.id, undefined, start.valueOf(), end.valueOf());
  }


  return (
    <div style={{display: "flex", flexWrap: "wrap", gap: "1rem", marginBottom: "2rem", alignItems: "center"}}>
      <div style={{flexGrow: 1}}>
        <OdourLevelSelect defaultValue={odourLevelSelect.selected} onChange={(value) => handleChangeFilter(odourLevelSelect.id, value)}/>
      </div>
      <div style={{flexGrow: 1}}>
        <OdourTypeSelect defaultValue={odourTypeSelect.selected} onChange={(value) => handleChangeFilter(odourTypeSelect.id, value)}/>
      </div>
      <div style={{flexGrow: 1}}>
        <RangeTimeFilter_v2 withDatePicker intervals={enabledIntervals} selectedInterval={timeRangeInView.selected}
        startDate={startDate} endDate={endDate} sliderStart={sliderStart} sliderEnd={sliderEnd}
        onChangeInterval={handleChangeInterval} onChangeSlider={handleChangeSlider}
        />
      </div>
    </div>
  )
}

export default OdorReportsContentHeader;