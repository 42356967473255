import { useEffect, useMemo, useState } from 'react'
import { Box, Button, Checkbox, CircularProgress, IconButton, TextField } from '@mui/material'
import CreateParamModal from '../Modals/CreateParamModal'
import { useSelector } from 'react-redux'
import ConstantSelect from '../SharingComponents/Inputs/ConstantSelect'
import SelectComponent from '../SharingComponents/Inputs/SelectComponent'
import EditIcon from '@mui/icons-material/Edit';
import { useTranslation } from 'react-i18next'
import { translate } from 'src/utilities/utils'
import { viewNames } from 'app/configs/routesNames'
import { deleteConstant, readConstant, updateConstant } from 'app/store/configurationSlice/configurationActions'
import { getTenants } from 'app/store/evocsSlice/tenants/tenantsActions'
import { setGlobalError, setGlobalSuccess } from 'app/store/evocsSlice/globalError/errorActions'
import { getFilters } from 'app/store/evocsSlice/filters/filtersActions'
import TenantSelect from '../SharingComponents/Inputs/TenantSelect'


const translatableConstants = ["measure", "timeRange", "project", "sensor_type", "station_type", "odour_type", "odour_level"];

const inputStyle = { width: "100%", minWidth: "100px", maxWidth: "300px" };


const ConstantConfiguration = ({ requiredKeys, constant, loadingSave, edit, onChangeParam, onEdit }) => {
  const { t } = useTranslation("evocs");
  const constants = useSelector((state) => state.configuration.constants);
  const handleTranslate = (textId, general = false) => {
    if (general) return translate(t, textId);
    return translate(t, textId, viewNames.ADMINISTRATION_VIEW);
  };

  const constantLabel = useMemo(() => constant.identifier, [constant.id]);
  const VALID_KEYS = useMemo(() => {
    return {
      identifier: {
        label: handleTranslate("IDENTIFIER", true),
        inputType: "text",
      },
      unit: {
        label: handleTranslate("MEASUREMENT_UNIT", true),
        inputType: "text"
      },
      color: {
        label: handleTranslate("COLOR", true),
        inputType: "text"
      },
      title: {
        label: handleTranslate("TITLE", true),
        inputType: "text"
      },
      templateMessage: {
        label: handleTranslate("MESSAGE", true),
        inputType: "textarea"
      },
      temp: {
        label: handleTranslate("TIME", true),
        inputType: "number"
      },
      lat: {
        label: handleTranslate("LATITUDE", true),
        inputType: "text"
      },
      lon: {
        label: handleTranslate("LONGITUDE", true),
        inputType: "text"
      },
      groupId: {
        label:  handleTranslate("ACTION_GROUP"),
        inputType: "select",
        multiple: false,
        constantCategory: "actionGroup"
      },
      listFilters: {
        label: handleTranslate("FILTERS_IN_VIEW", true),
        inputType: "select",
        multiple: true,
        constantCategory: "filterCategory"
      },
      activable: {
        label: handleTranslate("ACTIVABLE", true),
        inputType: "checkbox"
      },
      dynamic: {
        label: handleTranslate("DYNAMIC", true),
        inputType: "checkbox"
      },
      actions: {
        label: handleTranslate("ACTIONS", true),
        inputType: "select",
        multiple: true,
        constantCategory: "action"
      },
      tenantIds: {
        label: "Tenant",
        inputType: "tenant",
        multiple: true,
      }
    }
  }, [t]);
   
  const allInputs = useMemo(() => {
    const validKeys = Object.keys(constant).filter((key) => VALID_KEYS[key]);
    return validKeys.map((key) => {
      const required = requiredKeys.includes(key);
      const multiple = VALID_KEYS[key].multiple;
      switch (VALID_KEYS[key].inputType) {
        case "text":
          return <TextField key={key} sx={inputStyle} required={required} disabled={!edit} label={VALID_KEYS[key].label} value={constant[key] || ""} onChange={(e) => onChangeParam(key, e.target.value)}/>
        case "checkbox":
          const checked = !constant[key] ? false : true;
          return (
            <div key={key} style={{display: "flex", alignItems: "center"}}>
              <label>{VALID_KEYS[key].label}:</label>
              <Checkbox disabled={!edit} checked={checked} onChange={(_, value) => onChangeParam(key, value)}/>
            </div>
          );
        case "select": 
          const constantCategory = VALID_KEYS[key].constantCategory;
          const options = !constants ? [] : Object.keys(constants[constantCategory]).map((id) => id );
          const labels = options.map((constantId) => constants[constantCategory][constantId][constantCategory]);
          return (
            <SelectComponent key={key} required={required} disabled={!edit} labelId={key} placeholder={VALID_KEYS[key].label} multiple={multiple} options={options} labels={labels}
            value={constant[key] || []} onChange={(value) => onChangeParam(key, value)}/>
          );
        case "tenant": 
          return constant.activable !== 0 && constant.activable && (
            <TenantSelect key={key} required={required} disabled={!edit} labelId={key} multiple={multiple} value={constant[key] || []} onChange={(value) => onChangeParam(key, value)}/>
          );
      }
    });
  }, [t, constant, requiredKeys, edit])


  return (
    <div style={{ width: "100%"}}>
      <h3 style={{ marginBottom: "2rem" }}> {handleTranslate("EDIT", true)} {constant.constantType}: <b>{constantLabel}</b>
        {!edit && <IconButton sx={{ marginLeft: "1rem" }} disabled={loadingSave} onClick={onEdit}><EditIcon /></IconButton>}
      </h3>
      <div style={{ display: "flex", gap: "1rem", marginBottom: "1rem", flexWrap: "wrap" }}>
        {allInputs.map((input) => (
          input
        ))}
      </div>
    </div>
  )
}

const ConfigurationContent = () => {
  const { t } = useTranslation("evocs");
  const user = useSelector((state) => state.user);
  const constants = useSelector((state) => state.configuration.constants);
  const tenants = useSelector((state) => state.tenants.list);
  const [loadingSave, setLoadingSave] = useState(false);
  const [constantsTypeOptions, setConstantsTypeOptions] = useState([]);
  const [constantType, setConstantType] = useState();
  const [options, setOptions] = useState([]);
  const [optionsLabels, setOptionsLabels] = useState();
  const [selectedConstant, setSelectedConstant] = useState();

  const [constant, setConstant] = useState();
  const [edit, setEdit] = useState(false);
  const [isChanged, setIsChanged] = useState(false);
  const [disableSave, setDisableSave] = useState(true);
  const requiredKeys = useMemo(() => {
    if(!constant || constant.constantType !== "view_name") return ["identifier", "unit", "title", "lat", "lon"];
    else if(constant.activable && constant.activable !== 0) return ["identifier", "unit", "title", "lat", "lon", "tenantIds"];
    else return ["identifier", "unit", "title", "lat", "lon"];
  }, [constant]);


  useEffect(() => {
    if (!constants) return;
    setConstantsTypeOptions(Object.keys(constants).filter((constantId) => constantId !== "role" && constantId !== "filterId" && constantId !== "alert" && constantId !== "categoryAlert" && constantId !== "groupId"));
  }, [constants])

  useEffect(() => {
    if (!constantType) return;
    const translate = translatableConstants.includes(constantType);
    const identifiers = [], labels = [];
    Object.keys(constants[constantType]).forEach((id) => {
      identifiers.push(constants[constantType][id][constantType] || constants[constantType][id].name);
      translate && labels.push(handleTranslate(constantType.toUpperCase() + "_" + constants[constantType][id][constantType]?.toUpperCase(), true));
    });
    translate ? setOptionsLabels(labels) : setOptionsLabels(null);
    setOptions(identifiers);
    setSelectedConstant("");
    setEdit(false);
  }, [constants, constantType])

  useEffect(() => {
    if (!constantType) return;
    setOptionsLabels(
      Object.keys(constants[constantType]).map((id) =>
        handleTranslate(constantType.toUpperCase() + "_" + constants[constantType][id][constantType]?.toUpperCase(), true)
      )
    );
  }, [t])

  useEffect(() => {
    setConstant(selectedConstant);
    setEdit(false);
  }, [selectedConstant])

  useEffect(() => {
    !edit && setIsChanged(false);
  }, [edit])

  useEffect(() => {
    if(!constant) return;
    const disabled = !isChanged ? true : Object.keys(constant).some((key) => requiredKeys.includes(key) && (!constant[key] || constant[key] === "" || constant[key]?.length === 0) )
    setDisableSave(disabled);
  }, [constant, isChanged])

  const handleTranslate = (textId, general = false) => {
    if (general) return translate(t, textId);
    return translate(t, textId, viewNames.ADMINISTRATION_VIEW);
  };

  const handleChangeConstant = (value) => {
    const constantId = Object.keys(constants[constantType])?.find((id) => constants[constantType][id][constantType] === value || constants[constantType][id].name === value);
    const constantElement = constants[constantType][constantId];
    if (!constantElement) {
      setSelectedConstant(null);
      return;
    };
    let newSelected = {
      id: constantId,
      constantType: constantType,
      identifier: constantElement[constantType] || constantElement.name,
      ...constantElement
    };
    newSelected.name && delete newSelected.name;
    newSelected.label && delete newSelected.label;
    if(constantType === "view_name") newSelected.tenantIds = Object.keys(tenants).filter((tenantId) => tenants[tenantId].enabledViews.includes(constantId));
    setSelectedConstant(newSelected);
  };

  const handleChangeConstantParam = (key, value) => {
    setIsChanged(true);
    if(key === "activable") {
      setConstant((prev) => ({ ...prev, [key]: value, tenantIds: null }));
      return;
    }
    setConstant((prev) => ({ ...prev, [key]: value }));
  };

  const handleCancelChanges = () => {
    setConstant(selectedConstant);
    setEdit(false);
  };

  const handleDelete = () => {
    setLoadingSave(true);
    let apiObj = {constantId: constant.id, ...constant};
    delete apiObj.id; delete apiObj.identifier;
    deleteConstant([apiObj])
    .then(() => {
      const fetch = [getTenants(user.tenantId), getFilters(), readConstant(["view_name"])]
      Promise.all(fetch)
      .then(() => setGlobalSuccess(true))
      .catch(() => setGlobalError(true))
      .finally(() => {
        setEdit(false);
        setLoadingSave(false);
      })
    })
    .catch(() => setGlobalError(true));
  };

  const handleSave = () => {
    const apiObj = {_id: constant.id, ...constant, [constantType]: constant.identifier};
    delete apiObj.id; delete apiObj.identifier;
    setLoadingSave(true);
    updateConstant([apiObj]).then(() => {
      getTenants(user.tenantId)
      .then(() => setGlobalSuccess(true))
      .catch(() => setGlobalError(true))
      .finally(() => {
        setEdit(false);
        setLoadingSave(false)
      })
    }).catch(() => setGlobalError(true))
  };

  return (
    <div>
      <div style={{ display: "flex", gap: "1rem", marginBottom: "2rem" }}>
        <ConstantSelect constantsId={constantsTypeOptions} value={constantType} onChange={setConstantType} />
        {constantType && <SelectComponent labelId={constantType} placeholder={constantType} options={options} labels={optionsLabels} value={selectedConstant?.identifier || ""} onChange={handleChangeConstant} />}
      </div>
      {constant &&
        <>
          <ConstantConfiguration requiredKeys={requiredKeys} constant={constant} loadingSave={loadingSave} edit={edit} onChangeParam={handleChangeConstantParam} onEdit={() => setEdit(true)} />
          {!loadingSave ? 
            edit &&
              <>
                <Button disabled={disableSave} onClick={handleSave}> {handleTranslate("SAVE", true)} </Button>
                <Button sx={{ color: "red" }} disabled={loadingSave} onClick={handleDelete}> {handleTranslate('DELETE', true)} </Button>
                <Button sx={{ marginLeft: "1rem" }} onClick={handleCancelChanges}> {handleTranslate("DISCARD_CHANGES", true)} </Button>
              </>
            :
            <Button endIcon={loadingSave && <CircularProgress size={15} />}> {handleTranslate('SAVING', true)} </Button>
          }
        </>
      }
    </div>
  )
}

const ParamsConfiguration = () => {
  const { t } = useTranslation("evocs");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const handleTranslate = (textId, general = false) => {
    if (general) return translate(t, textId);
    return translate(t, textId, viewNames.ADMINISTRATION_VIEW);
  };


  return (
    <div>
      <Box sx={{ width: "100%", padding: "1rem" }}>
        <div style={{ display: "flex", marginBottom: "1rem", alignItems: "center" }}>
          <h3 style={{ fontWeight: "bold" }}> {handleTranslate('EDIT_VARIABLE')} </h3>
          <button
            className="px-16 py-10 md:ml-auto w-full md:w-fit"
            style={{
              backgroundColor: "#5d9a60",
              borderRadius: "5px",
              color: "white",
              textTransform: "uppercase",
            }}
            onClick={() => setIsModalOpen(true)}
            >
              {handleTranslate('NEW_VARIABLE')}
            </button>
        </div>
        <ConfigurationContent />
      </Box>
      <CreateParamModal isOpen={isModalOpen} onFinally={() => setIsModalOpen(false)} onClose={() => setIsModalOpen(false)} />
    </div>
  )
}

export default ParamsConfiguration