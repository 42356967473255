import { useEffect, useState } from "react";
import LoadingGif from "src/components/SharingComponents/LoadingGif";
import TableComponent from "src/components/SharingComponents/TableComponent";
import { useSelector } from "react-redux";
import {
  getDeprecatedUnits,
  deleteDeprecatedUnits,
  restoreDeprecatedUnits,
} from "app/store/evocsSlice/controlUnits/cuActions";
import {
  setGlobalError,
  setGlobalSuccess,
} from "app/store/evocsSlice/globalError/errorActions";

export default function ControlUnitsTab({ handleTranslate }) {
  const [isLoading, setIsLoading] = useState(true);
  const [units, setUnits] = useState([]);
  const [data, setData] = useState(units);
  const [filteredData, setFilteredData] = useState([]);
  const [searchedText, setSearchedText] = useState("");
  const tenants = useSelector((state) => state.tenants.list);
  useEffect(() => {
    setIsLoading(true);
    getDeprecatedUnits()
      .then((res) => {
        setUnits(res);
      })
      .finally(() => setIsLoading(false));
  }, []);

  const col = {
    serial: handleTranslate("SERIAL", true),
    owner: handleTranslate("OWNER", true),
  };

  useEffect(() => {
    setData(
      units?.map((unit) => {
        const validTenant =
          tenants[unit.owner]?.name ?? handleTranslate("UNKNOWN", true);
        return {
          id: unit.serial,
          serial: unit.serial,
          owner: validTenant,
        };
      })
    );
  }, [units]);

  useEffect(() => {
    if (!searchedText) {
      setFilteredData(data);
      return;
    }

    const filtered = data.filter((station) =>
      Object.keys(station).some((key) =>
        station[key]?.toLowerCase().includes(searchedText.trim().toLowerCase())
      )
    );
    setFilteredData(filtered);
  }, [searchedText, data]);

  //? Restore
  const handleRestoreRow = (selection) => {
    const filterBySelection = units
      .filter((unit) => selection.includes(unit.serial))
      .map((itemToRestore) => itemToRestore["_id"]);
    restoreDeprecatedUnits(filterBySelection).then(() => {
      setUnits(units?.filter((unit) => !selection.includes(unit.serial)));
      setGlobalSuccess(true);
    });
  };

  //!DELETE
  const handleDeleteRow = (deletedSelection) => {
    const filterByDeleted = units
      .filter((unit) => deletedSelection.includes(unit.serial))
      .map((deleted) => deleted["_id"]);

    deleteDeprecatedUnits(filterByDeleted)
      .then(() => {
        setUnits(
          units?.filter((unit) => !deletedSelection.includes(unit.serial))
        );
        setGlobalSuccess(true);
      })
      .catch((error) => {
        setGlobalError(true);
        console.error("CIAO", error);
      });
  };

  if (isLoading) {
    return <LoadingGif />;
  }
  const translatedTitle = handleTranslate("RESTORE", true)

  return (
    <div>
      <div
        style={{
          marginTop: "10px",
          padding: "20px",
          fontSize: "2rem",
          fontWeight: "700",
        }}
      >
        {handleTranslate("CONTROL_UNITS", true)}: {filteredData.length}
      </div>
      <TableComponent
        onCustomAction={handleRestoreRow}
        customActionTitle={translatedTitle}
        selectable
        onDeleteRows={handleDeleteRow}
        columns={col}
        data={filteredData}
        onSearch={setSearchedText}
      />
    </div>
  );
}
