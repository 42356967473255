import React, { useRef } from 'react'
import { Pie } from 'react-chartjs-2'
import ChartDataLabels from 'chartjs-plugin-datalabels';
import useMobile from 'src/app/hooks/useMobile';
import { viewNames } from 'app/configs/routesNames';
import { useTranslation } from 'react-i18next';
import { translate } from "src/utilities/utils";
import { LuInspect } from "react-icons/lu";
import { Link } from 'react-router-dom';

const StationsStateGraph = ({data=[], labels=[], backgroundColor=[], borderColor=[], onClickPie, onDoubleClickPie}) => {
  const chartRef = useRef();
  const [xs, sm] = useMobile();
  const { t } = useTranslation('evocs');
  const handleTranslate = (textId, general = false) => {
  	if (general) return (translate(t, textId));
  	return (translate(t, textId,  viewNames.DASHBOARD_VIEW));
  };

  const handleClickPie = (id) => {
    onClickPie && onClickPie(id);
  }

  const handleDoubleClick = (e) => {
    const points = chartRef.current.getElementsAtEventForMode(e, "nearest", {intersect: "true"}, true);
    const id = chartRef.current.data.labels[points[0]?.index];
    onDoubleClickPie && onDoubleClickPie(id);
  }

  return data?.length > 0 ? (
    <Pie 
      ref={chartRef}
      onDoubleClick={handleDoubleClick}
      data={{
          labels: labels,
          datasets: [
            {
              data: data,
              borderWidth: 1,
              backgroundColor: backgroundColor,
              borderColor: borderColor
            },
          ],
      }} 
      plugins={[ChartDataLabels]}
      options={{
          maintainAspectRatio: false,
          onClick: (e, elements) => {
            if(!e.chart.data.labels[elements[0]?.index]) return;
            handleClickPie(e.chart.data.labels[elements[0]?.index]);
          },
          hoverOffset: 30,
          plugins: {
            tooltip: {
              enabled: false
            },
            // title: {
            //   display: true,
            //   color: "black",
            //   text: ()=> handleTranslate('MONITORING_STATIONS', true),
            //   font: {
            //     size: "20px"
            //   }
            // },
            legend: {
              display: false,
            },
            datalabels: { 
              anchor: "end",
              align: "center",
              color: "white",
              backgroundColor: "rgba(0, 0, 0, .6)",
              font: {
                size: `${xs? 8 : sm ? 10 : 12}px`,
                weight: "bold"
              },
              formatter: (value, ctx) => {      
                return ctx.chart.data.labels[ctx.dataIndex]
              }
            }
          },
          layout: {
            padding: {
              left: 30,
              right: 30,
              top: 10,
              bottom: 30
            }
          }
      }} 
    />
    ) : 
    (
      <div style={{ display: "flex", justifyContent: "center", alignItems: "center", height: "100%" }}>
        <label> {handleTranslate("NO_ACTIVE_STATIONS", true)}</label>
      </div>
    )
}

export default StationsStateGraph