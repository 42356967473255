import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import networkDevices from "./devices.json";

/**
 * Take an array of objects and return a new array with the same objects, but
 * with the `_id` key renamed to `id`.
 *
 * @param {object[]} response - An array of objects with an `_id` key.
 * @returns {object[]} - An array of objects with an `id` key.
 */
function swipeIdKeys(response) {
  if (response && Array.isArray(response) && response.length > 0) {
    return response.map((item) => {
      const { _id, ...rest } = item;
      return { id: _id, ...rest };
    });
  }
  return [];
}

const scanWifi = createAsyncThunk(
  "networkDevices/scanWifi",
  async (payload) => {
    const { port } = payload;
    const data = await axios
      .post("/api/py/scanWifi", {
        port: port,
      })
      .then((res) => {
        return res?.data?.foundNetwork.sort(
          (a, b) => Number(b.quality) - Number(a.quality)
        );
      })
      .catch((error) => {
        throw error;
      });
    return data;
  }
);

const scanBlt = createAsyncThunk("networkDevices/scanBlt", async (payload) => {
  const { port, time } = payload;
  const data = await axios
    .post("/api/py/scanBlt", {
      port: port,
      time: time,
    })
    .then((res) => {
      return res.data.foundBltDevices.sort(
        (a, b) => Number(b.rssi) - Number(a.rssi)
      );
    })
    .catch((error) => {
      throw error;
    });
  return data;
});

const rebootVpnDevice = createAsyncThunk(
  "networkDevices/rebootVpnDevice",
  async (payload) => {
    const { id, port } = payload;
    const data = await axios
      .post("/api/py/rebootTilestation", {
        port: port,
      })
      .then((res) => {
        return res.data;
      })
      .catch((error) => {
        throw error;
      });
    return data ? id : null;
  }
);

const readNetData = createAsyncThunk(
  "networkDevices/readNetData",
  async (payload) => {
    const { port } = payload;
    const data = await axios
      .post("/api/py/readNetData", {
        port: port,
      })
      .then((res) => {
        return res?.data;
      })
      .catch((error) => {
        throw error;
      });
    return data;
  }
);

const readConnectedBltDevices = createAsyncThunk(
  "networkDevices/readConnectedBltDevices",
  async (payload) => {
    const { port } = payload;
    const data = await axios
      .post("/api/py/readConnectedBltDevices", {
        port: port,
      })
      .then((res) => {
        return res.data.connectedBltDevices;
      })
      .catch((error) => {
        throw error;
      });
    return data;
  }
);

// 'pairing': 0 o 1 (0 disattiva il discoverable e il pairable e 1 li attiva)}
const bltPairing = createAsyncThunk(
  "networkDevices/bltPairing",
  async (payload) => {
    const { port, pairing } = payload;
    const data = await axios
      .post("/api/py/bltPairing", {
        port: port,
        pairing: pairing,
      })
      .then((res) => {
        console.log("res: ", res);
        return res.data;
      })
      .catch((error) => {
        throw error;
      });
    return data;
  }
);

//Ti connette ad un dispositivo bluetooth passandogli l'indirzzo MAC allo stesso tempo se il dispositivo è connesso lo disconnette
// 'choice': 0 o 1 (0 disconnette e 1 li connette) }
const connectDisconnectBltDevices = createAsyncThunk(
  "networkDevices/connectDisconnectBltDevices",
  async (payload) => {
    const { port, macAddress, choice } = payload;
    const data = await axios
      .post("/api/py/connectDisconnectBltDevices", {
        port: port,
        macAddress: macAddress,
        choice: choice,
      })
      .then((res) => {
        console.log("res: ", res);
        return res.data;
      })
      .catch((error) => {
        throw error;
      });
    return data;
  }
);

//mi dice se è connesso oppure no alla rete ethernet la response è ethernetConnection:true ethernetConnection:false è un json
const checkEthernet = createAsyncThunk(
  "networkDevices/checkEthernet",
  async (payload) => {
    const { port } = payload;
    const data = await axios
      .post("/api/py/checkEthernet", { port })
      .then((res) => {
        return res.data.ethernetConnection;
      })
      .catch((error) => {
        throw error;
      });
    return data;
  }
);

//aggiunge al wpa supplicant una nuova rete wifi quindi gli si può mandare ssid e password per farlo connettere ad una nuova rete wifi
const addWifiNetwork = createAsyncThunk(
  "networkDevices/addWifiNetwork",
  async (payload) => {
    const { port, ssid, pswd } = payload;
    const data = await axios
      .post("/api/py/addWifiNetwork", {
        port: port,
        ssid: ssid,
        pswd: pswd,
      })
      .then((res) => {
        console.log('res: ', res);
        return res.data;
      })
      .catch((error) => {
        throw error;
      });
    return data;
  }
);

//srrve a connetterti ad una rete wifi o a modificare ssid e password
// 'ssid': SSID DELLA RETE DA MODIFICARE, 'pswd': PASSWORD DELLA RETE DA MODIFICARE, 
// SE IMPOSTATA COME None PROCEDE A COLLEGARSI ALLA RETE SALVATA SOLO SE 'emptyPswd' è False, 
const connectModifyNet = createAsyncThunk(
  "networkDevices/connectModifyNet",
  async (payload) => {
    const { port, ssid, pswd, emptyPswd } = payload;
    const data = await axios
      .post("/api/py/connectModifyNet", {
        port: port,
        ssid: ssid,
        pswd: pswd,
        emptyPswd: emptyPswd,
      })
      .then((res) => {
        console.log('res: ', res);
        return res.data;
      })
      .catch((error) => {
        throw error;
      });
    return data;
  }
);

const addVpnDevice = createAsyncThunk(
  "networkDevices/addVpnDevice",
  async (payload) => {
    const { name, port } = payload;
    const data = await axios
      .post("/api/py/addVpnDevice", {
        name: name,
        port: port,
        status: 0,
      })
      .then((res) => {
        return res.data;
      })
      .catch((error) => {
        throw error;
      });
    return data;
  }
);

const updateVpnDevice = createAsyncThunk(
  "networkDevices/updateVpnDevice",
  async (payload) => {
    const { id, port, name } = payload;
    const data = await axios
      .post("/api/py/updateVpn", {
        deviceId: id,
        name: name,
        port: port,
        status: 1,
      })
      .then((res) => {
        return res.data;
      })
      .catch((error) => {
        throw error;
      });
    return data;
  }
);

const readVpn = createAsyncThunk("networkDevices/readVpn", async () => {
  const data = await axios
    .post(`/api/py/readVpn`, {})
    .then((res) => {
      return swipeIdKeys(res.data);
    })
    .catch((error) => {
      throw error;
    });
  return data;
});

const removeVpn = createAsyncThunk(
  "networkDevices/removeVpn",
  async (payload) => {
    const { deviceIds } = payload;
    const data = await axios
      .post("/api/py/removeVpn", {
        deviceIds: deviceIds,
      })
      .then((res) => {
        return swipeIdKeys(res.data);
      })
      .catch((error) => {
        throw error;
      });
    return data;
  }
);

const devicePing = createAsyncThunk(
  "networkDevices/devicePing",
  async (payload) => {
    const { port } = payload;
    const data = await axios
      .get(`/api/py/devicePing`, {
        params: { port }, // Passa `port` come parametro di query
      })
      .then((res) => {
        return res.data;
      })
      .catch((error) => {
        throw error;
      });
    return data;
  }
);

const initialState = {
  value: [],
  status: "idle",
  error: false,
  activeDevice: null,
};

export const networkSlice = createSlice({
  name: "networkDevices",
  initialState,
  reducers: {
    addDevice: (state, action) => {
      console.log("action.payload: ", action.payload);
      state.value.push(action.payload);
    },
    deleteDevice: (state, action) => {
      state.value = state.value.filter(
        (device) => device.id !== action.payload
      );
    },
    updateDevice: (state, action) => {
      state.value.map((device) => {
        if (device.id === action.payload.id) {
          device.deviceName = action.payload.deviceName;
          device.port = action.payload.port;
        }
      });
    },
    setActiveDevice: (state, action) => {
      state.activeDevice = action.payload;
    },

    setError: (state, action) => {
      state.error = action.payload;
    },
  },

  //per gestire i vari casi della fetch
  extraReducers: (builder) => {
    builder.addCase(readVpn.fulfilled, (state, action) => {
      state.status = "succeded";
      state.error = false;
      state.value = action.payload;
    });
    builder.addCase(readVpn.rejected, (state, action) => {
      state.error = true;
    });

    builder.addCase(addVpnDevice.fulfilled, (state, action) => {
      const { _id, ...rest } = action.payload;
      const newObj = { id: _id, ...rest };
      state.value.push(newObj);
    });

    builder.addCase(removeVpn.fulfilled, (state, action) => {
      state.value = action.payload;
    });

    builder.addCase(rebootVpnDevice.fulfilled, (state, action) => {
      const id = action.payload;
      if (id)
        state.value.map((device) => {
          if (device.id === id) {
            device.status = 0;
          }
        });
    });
  },
});
export const networkSliceActions = {
  ...networkSlice.actions,
  scanWifi,
  scanBlt,
  rebootVpnDevice,
  readNetData,
  readConnectedBltDevices,
  bltPairing,
  connectDisconnectBltDevices,
  checkEthernet,
  addWifiNetwork,
  connectModifyNet,
  addVpnDevice,
  updateVpnDevice,
  readVpn,
  removeVpn,
  devicePing,
};
export default networkSlice.reducer;
