import {
  Page,
  Text,
  Image,
  Document,
  StyleSheet,
  PDFViewer,
  View,
} from "@react-pdf/renderer";
import CoverImg from "src/app/asset/images/Cover_image_automatic_report_V0.png";
import EvocsLogo from "src/app/asset/images/logoEvocsBlack.png";
import missingAllData from "src/app/asset/images/missingData.png";
import missingSensorData from "src/app/asset/images/missingData2.png";
import { Font } from "@react-pdf/renderer";
import RobotoLight from "src/app/asset/fonts/Roboto/Roboto-Light.ttf";
import RobotoRegular from "src/app/asset/fonts/Roboto/Roboto-Regular.ttf"; 
import RobotoItalic from "src/app/asset/fonts/Roboto/Roboto-Italic.ttf"; 
import RobotoMedium from "src/app/asset/fonts/Roboto/Roboto-Medium.ttf";
import RobotoBold from "src/app/asset/fonts/Roboto/Roboto-Bold.ttf"; 

Font.register({
  family: "Roboto",
  fonts: [
    { src: RobotoLight, fontWeight: "light" },
    { src: RobotoRegular, fontWeight: "normal" },
    { src: RobotoItalic, fontWeight: "normal", fontStyle: "italic" },
    { src: RobotoMedium, fontWeight: "500" },  // Medium
    { src: RobotoBold, fontWeight: "bold" }, 
  ],
});


const style = StyleSheet.create({
  pdfViewer: {
    width: "100%",
    height: "100%",
  },
  page: {
    paddingTop: 35,
    paddingBottom: 65,
    paddingHorizontal: 35,
    fontFamily: "Roboto",
    fontWeight: "normal",
  },
  firstTitle: {
    fontSize: 16,
    textAlign: "left",
    marginTop: 20,
    marginBottom: 20,
    fontFamily: "Roboto",
    fontWeight: "bold",
  },
  secondTitle: {
    fontSize: 14,
    textAlign: "left",
    marginBottom: 10,
    marginTop: 20,
    fontFamily: "Roboto",
    fontWeight: "500",
  },
  paragraph: {
    fontSize: 12,
    fontFamily: "Roboto",
    fontWeight: "normal",
  },
  footer: {
    position: "absolute",
    bottom: 15,
    left: 0,
    right: 0,
  },
  line: {
    border: "0.5px solid black",
    borderRadius: "1px",
  },
});


const CoverPage = ({ reportTitle }) => {
  
  const coverStyle = StyleSheet.create({
    coverCaption: {
      marginTop: 10,
      fontSize: 11,
      marginBottom: 15,
      textAlign: "center",
      color: "242A30",
      fontFamily: "Roboto",
      fontWeight: "normal",
      fontStyle: "italic",
    },
    coverContainer: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
    },
    coverSubOne: {
      fontSize: 15,
      marginVertical: 10,
    },
    coverSubTwo: {
      fontSize: 11,
    },
    coverImage: {
      marginVertical: 25,
      width: "100%",
      height: "90%",
    },
    coverDiv: {
      marginTop: 10,
      width: "100%",
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
    },
  });

  // const title =  reportTitle?? 'Report generato automaticamente da Evocs.it';
  const title = 'Report generato automaticamente da Evocs.it';

  return (
    <Page style={style.page} wrap>
      <View>
      {/* <Text style={coverStyle.headerTitle}>{title}</Text> */}
        <View style={coverStyle.coverContainer}>
          <Image style={coverStyle.coverImage} src={CoverImg} />
        </View>
        <Text style={coverStyle.coverCaption}>{title}</Text>
      </View>
      <Footer />
  </Page>
  );
};

const DescriptionSection = ({ descrSectiontitle, descrParagraph }) => {
  // const descrStyle = StyleSheet.create({});

  return (
    <View>
      <Text style={style.secondTitle}>{descrSectiontitle}</Text>
      <Text style={style.paragraph}>{descrParagraph}</Text>
    </View>
  );
};

const ImageSection = ({
  graphSectionTitle,
  captionParagraph,
  srcImg,
  imageCaption,
  imgWidth = 600,
  imgHeight = 320,
  firstTitle = false,
}) => {
  const graphStyle = StyleSheet.create({
    graphImage: {
      paddingTop: 5,
      width: imgWidth,
      height: imgHeight,
      margin: 0,
    },
    graphContainer: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
    },
    imgCap: {
      fontSize: 11,
      textAlign: "center",
      color: "grey",
      fontFamily: "Roboto",
      fontWeight: "normal",
      fontStyle: "italic",
    },
  });

  return (
    <View>
      <Text style={firstTitle === true ? style.firstTitle : style.secondTitle}> {graphSectionTitle} </Text>
      <Text style={style.paragraph}>{captionParagraph}</Text>
      <View style={graphStyle.graphContainer}>
        <Image src={srcImg} style={graphStyle.graphImage} />
        <Text style={graphStyle.imgCap}>{imageCaption}</Text>
      </View>
    </View>
  );
};

const NoDataSection = ({isSensor=true}) => {
  
  const nodataStyle = StyleSheet.create({
    coverContainer: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
    },
    sensorDataImgStyle: {
      marginVertical: 25,
      width: "100%",
      height: "80%",
    },
    allDataImgStyle: {
      marginVertical: 25,
      width: "100%",
      height: "70%",
    },
  });

  return (
    <Page style={style.page}>
      <View>
        <View style={nodataStyle.coverContainer}>
          <Image style={isSensor? nodataStyle.sensorDataImgStyle: nodataStyle.allDataImgStyle} src={isSensor? missingSensorData:missingAllData} />
        </View>
        <Text style={{...style.paragraph, textAlign: "center"}}>{ "Nessun dato presente nell'intervallo selezionato per questa stazione" }</Text>
      </View>
      <Footer />
    </Page>
  );
};


const removeEmptyObjects = (obj) => {
  return Object.entries(obj).reduce((acc, [key, value]) => {
    if (!(typeof value === 'object' && value !== null && Object.keys(value).length === 0)) {
      acc[key] = value;
    }
    return acc;
  }, {});
};



const SensorSection = ({ sensorData }) => {
  if (!sensorData || Object.keys(sensorData ?? {}).length === 0) return <NoDataSection />;
  const {
    data,
    year,
    start_date_str,
    end_date_str,
    descr_title,
    sensor,
    titolo_analisi,
    descrizione,
    images,
    positionTitle,
    positionParagraph,
    stationImg,
    positionInfo,
  } = sensorData;
  if(!sensorData) return <></>
  const { descr, img, caption } = images;
  const isGpsSensor =
    ((positionTitle && stationImg && positionParagraph && positionInfo) ?? 0);
  return (
    <Page style={style.page} wrap>
      <ImageSection
        graphSectionTitle={titolo_analisi}
        captionParagraph={descr}
        sensorName={sensor}
        srcImg={img}
        imageCaption={caption}
        firstTitle={true}
      />
      <DescriptionSection
        descrSectiontitle={descr_title}
        descrParagraph={descrizione}
        />
      {isGpsSensor && (
        <ImageSection
          imgHeight={360}
          imgWidth={520}
          graphSectionTitle={positionTitle}
          captionParagraph={positionParagraph}
          srcImg={stationImg}
          imageCaption={positionInfo}
        />
      )}
      <Footer />
    </Page>
  );
};

const StationReport = ({ sensors }) => {
    if (!sensors) return <></>;
  return (
    <>
      {Object.keys(sensors).map((key, index) => {
        return <SensorSection key={key} sensorData={sensors[key]} />;
      })}
    </>
  );
};

// const DeviceLocation = ({ deviceData}) => {
//   if (!deviceData || Object.keys(deviceData).length === 0) return <></>;
//   let positionTitle, positionParagraph, stationImg, positionInfo;
//   try{
//     const keysToCheck = ['positionTitle', 'positionParagraph', 'stationImg', 'positionInfo'];
//     let hasAllKeys = keysToCheck.every(key => deviceData.hasOwnProperty(key));
//     if (!hasAllKeys) deviceData = deviceData[Object.keys(deviceData)[0]];
//     hasAllKeys = keysToCheck.every(key => deviceData.hasOwnProperty(key));
//     if (!hasAllKeys) return <></>;
//     positionTitle = deviceData['positionTitle'];
//     positionParagraph = deviceData['positionParagraph'];
//     stationImg = deviceData['stationImg'];
//     positionInfo = deviceData['positionTitle'];
//     return (
//       <Page style={style.page} wrap>
//       <ImageSection
//         imgHeight={360}
//         imgWidth={520}
//         graphSectionTitle={positionTitle}
//         captionParagraph={positionParagraph}
//         srcImg={stationImg}
//         imageCaption={positionInfo}
//       />
//       <Footer />
//     </Page>
//     )
//   }
//   catch(err){
//     console.log('err: ', err);
//     return <></>
//   }
// }

const Footer = () => {
  const footerStyle = StyleSheet.create({
    footerContainer: {
      fontSize: 11,
      flexDirection: "row",
      alignItems: "center",
      justifyContent: "space-between",
      margin: "0 35px",
    },
    footerLogo: {
      width: 60,
      height: 13,
      marginRight: 10,
    },
    pageNumber: {
      textAlign: "center",
      fontSize: 10,
      color: "black",
      fontFamily: "Roboto",
      fontWeight: "light",
      },
  });
  return (
    <View style={style.footer} fixed>
      <View style={footerStyle.footerContainer}>
        <Image src={EvocsLogo} style={footerStyle.footerLogo} />
        <Text
          render={({ pageNumber, totalPages }) =>
            `${pageNumber} / ${totalPages}`
          }
          style={footerStyle.pageNumber}
        />
      </View>
    </View>
  );
};

const DynamicReportPDF = ({
  // reportWith,
  // reportHeight,
  reportsData,
}) => {
  
  // const containerStyle = StyleSheet.create({
  //   pdfViewer: {
  //     width: reportWith ? reportHeight : window.innerWidth,
  //     height: reportHeight ? reportHeight : window.innerHeight,
  //   },
  // });

  const { sensors } = reportsData;
  let sensorWithData;
  if (sensors) {
    sensorWithData = JSON.parse(JSON.stringify(sensors));
    sensorWithData = removeEmptyObjects(sensorWithData);
  }

  console.log('reportsData: ', reportsData);

  return (
    <>

      {reportsData && (
        <Document>
            <CoverPage reportTitle={reportsData?.report_title} />
            {/* <DeviceLocation deviceData={type === "sensor" ? reportsData.sensors : reportsData} /> */}
            {(!sensorWithData ||  Object.keys(sensorWithData).length === 0) && <NoDataSection isSensor={false}/>}
            {(sensorWithData && Object.keys(sensorWithData).length > 0) && <StationReport sensors={sensorWithData}/>}
        </Document>
      )}
    </>
  );
};

export default DynamicReportPDF;
