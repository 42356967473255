import { useSelector } from "react-redux";
import CuGraph from "./CuGraph";
import SensorGraph from "./SensorGraph";
import { useEffect, useMemo } from "react";
import GraphChip from "./GraphChip";

const GraphSwitcher = ({
  unit,
  startDate,
  endDate,
  compareOptions,
  graphHeight = "400px",
  sensorGraphShadow = true,
  onChangeSensor,
  onChangeMeasure,
  onChangeCompare,
  onChangeSelectedCompare,
  disableSensorFilter = false,
  isLive = false,
  userActions,
}) => {
  const controlUnits = useSelector((state) => state.controlUnits.list);
  const sensors = useSelector((state) => state.sensors.list);
  const unitId = unit?.id;

  // console.log('unit: ', unit);

  return (
    unit && 
    controlUnits[unitId] ?
      <GraphChip
        withDownloads
        userActions={userActions}
        disableSensorFilter={disableSensorFilter}
        cu={controlUnits[unitId]}
        cuId={unitId}
        startDate={startDate}
        endDate={endDate}
        selectedSens={unit.selectedSensor}
        selectedMeas={unit.selectedMeasure}
        compareOptions={compareOptions}
        isCompare={unit.isCompare}
        selectedCompare={unit.selectedCompare}
        onChangeCompare={(value) => onChangeCompare(unitId, value)}
        onChangeSelectedCompare={(value) => onChangeSelectedCompare(unitId, value)}
        onChangeSensor={(value) => onChangeSensor(unitId, value)}
        onChangeMeasure={(value) => onChangeMeasure(unitId, value)}
        isLive={isLive}
        unit={unit}
        graphHeight={graphHeight}
      />
      :
        <GraphChip
          withDownloads
          userActions={userActions}
          // disableSensorFilter={disableSensorFilter} manca
          sensor={sensors[unitId]} // al posto di cu
          sensorId={unitId} //al posto di cuId
          startDate={startDate}
          endDate={endDate}
          // selectedSens={unit.selectedSensor} //manca
          selectedMeas={unit.selectedMeasure}
          compareOptions={compareOptions}
          isCompare={unit.isCompare}
          selectedCompare={unit.selectedCompare}
          onChangeCompare={(value) => onChangeCompare(unitId, value)}
          onChangeSelectedCompare={(value) => onChangeSelectedCompare(unitId, value)}
          // onChangeSensor={(value) => onChangeSensor(unitId, value)} //manca 
          onChangeMeasure={(value) => onChangeMeasure(unitId, value)}
          isLive={isLive}
          graphShadow={sensorGraphShadow} //manca in cu
          graphHeight={graphHeight}
        />
      )
};

export default GraphSwitcher;
