import store from "../.."
import {vesselsSliceActions } from "./vesselsSlice"
import { getApiTimestamp } from "src/utilities/utils";

export const getVessels = async(lonMin, lonMax, latMin, latMax, vesselIds=null, vesselTypes=null) => {
  
  const data = store.dispatch(vesselsSliceActions.getVessels({lonMin, lonMax, latMin, latMax, vesselIds, vesselTypes})).then(({payload, error}) => {
    if(error) throw error;
    return payload;
  });

  return data;
}

export const getPortTraffic = async(startDate, endDate) => {
  const data = store.dispatch(vesselsSliceActions.getPortTraffic({startDate: getApiTimestamp(startDate), endDate: getApiTimestamp(endDate)})).then(({payload, error}) => {
    if(error) throw error;

    return payload;
  });

  return data;
}

export const getVesselPaths = async(startDate, endDate, minDistance=5) => {
  const data = store.dispatch(vesselsSliceActions.getVesselPaths({startDate: getApiTimestamp(startDate), endDate: getApiTimestamp(endDate), minDistance})).then(({payload, error}) => {
    if(error) throw error;

    return payload;
  });

  return data;
}

export const getNationFlag = async(countryCode) => {
  const data = store.dispatch(vesselsSliceActions.getNationFlag(countryCode)).then(({payload, error}) => {
    if(error) throw error;

    return payload;
  });

  return data;
}