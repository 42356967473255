import { Button, CircularProgress, IconButton } from '@mui/material'
import { Box } from '@mui/system'
import React, { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import AccordionComponent from '../SharingComponents/AccordionComponent';
import FilterCategorySelect from '../SharingComponents/Inputs/FilterCategorySelect';
import EditIcon from "@mui/icons-material/Edit";
import { activeTenantFilters, updateTenantFilters } from 'app/store/evocsSlice/filters/filtersActions';
import { viewNames } from 'app/configs/routesNames';
import { useTranslation } from 'react-i18next';
import { translate } from "src/utilities/utils";
import SelectComponent from '../SharingComponents/Inputs/SelectComponent';
import { setGlobalError, setGlobalSuccess } from 'app/store/evocsSlice/globalError/errorActions';

const CustomTitle = ({ title, visible = false, isEdit, onClickEdit, onClickCancel }) => {

  return (
    <div style={{ display: "flex", alignItems: "center", gap: ".5rem" }}>
      <label>{title}</label>
      {visible && !isEdit && <IconButton onClick={(e) => { e.stopPropagation(); onClickEdit() }}><EditIcon /></IconButton>}
    </div>
  )
};

const SingleView = ({ tenantId, filters, view, onDelete }) => {
  const constants = useSelector((state) => state.configuration.constants);
  const filtersCategory = useSelector((state) => state.configuration.constants.filterCategory);
  const [loadingSave, setLoadingSave] = useState(false);
  const [activeFilters, setActiveFilters] = useState(Object.keys(filters));
  const [filtersInput, setFiltersInput] = useState({});
  const [isEdit, setEdit] = useState(false);
  const [canSave, setCanSave] = useState(false);
  const [madeChanges, setMadeChanges] = useState(false);
  const [accordionOpen, setAccordionOpen] = useState(false);

  const getFiltersState = useCallback((filterIds) => {
    let inputs = {};
    filterIds.forEach((filterId) => {
      const selected = [];
      let defaultElement;
      filters[filterId]?.list?.forEach((element) => {
        if (element.active === 1) selected.push(element._id);
        if (!defaultElement && element.default === 1) defaultElement = element._id;
      });
      inputs[filterId] = {
        selected: selected,
        default: defaultElement
      }
    });
    return inputs;
  }, [filters, activeFilters]);

  useEffect(() => {
    const deactiveFilters = Object.keys(filtersInput).filter((filterId) => !activeFilters.includes(filterId));
    const newStaticFilters = activeFilters.filter((filterId) => !Object.keys(filtersInput).includes(filterId) && !filtersCategory[filterId].dynamic);

    const filtersState = getFiltersState(newStaticFilters);
    setFiltersInput((prev) => {
      let newInputs = { ...prev };
      deactiveFilters.forEach((filterId) => delete newInputs[filterId]);
      newStaticFilters.forEach((filterId) => {
        newInputs[filterId] = filtersState[filterId];
      });
      return newInputs;
    });
  }, [filters, activeFilters])

  useEffect(() => {
    if (!madeChanges) { setCanSave(false); return; }
    setCanSave(true);
  }, [activeFilters, filtersInput])

  useEffect(() => {
    !accordionOpen && handleCancelChanges();
  }, [accordionOpen])


  const { t } = useTranslation("evocs");
  const handleTranslate = (textId, general = false) => {
    if (general) return translate(t, textId);
    return translate(t, textId, viewNames.ADMINISTRATION_VIEW);
  };

  const handleChangeActiveFilters = (value) => {
    setActiveFilters(value);
    setMadeChanges(true);
  };

  const handleChangeFilter = (filterId, value, key) => {
    setFiltersInput((prev) => {
      let newInputs = { ...prev, [filterId]: { ...prev[filterId], [key]: value } };
      if (key === "selected" && prev[filterId].default !== "") {
        if (!value.includes(prev[filterId]?.default))
          newInputs[filterId].default = "";
      }
      return newInputs
    });
    setMadeChanges(true);
  };

  const handleCancelChanges = () => {
    const staticFilters = Object.keys(filters).filter((filterId) => !filtersCategory[filterId].dynamic);
    const filtersState = getFiltersState(staticFilters);
    setFiltersInput((prev) => {
      let newInputs = {};
      staticFilters.forEach((filterId) => {
        newInputs[filterId] = filtersState[filterId];
      });
      return newInputs;
    });
    setActiveFilters(Object.keys(filters));
    setEdit(false);
    setMadeChanges(false);
  };

  const handleSave = () => {
    setLoadingSave(true);
    activeTenantFilters(tenantId, view.id, activeFilters).then(() => {
      updateTenantFilters(tenantId, view.id, filtersInput)
      .then(() => setGlobalSuccess(true))
      .catch(() => setGlobalError(true))
      .finally(() => {
        setEdit(false);
        setMadeChanges(false);
        setLoadingSave(false);
      });
    }).catch(() => setGlobalError(true));
  };

  const getFilterOptions = useCallback((filterId) => {
    const filterCategory = filtersCategory[filterId].filterCategory;
    return Object.keys(constants[filterCategory]);
  }, [filters, constants]);

  const getFilterLabels = useCallback((filterId) => {
    const filterCategory = filtersCategory[filterId].filterCategory;
    return Object.keys(constants[filterCategory]).map((id) => {
      if (id === "660bc8f8e4db0b4972d2fb69") return handleTranslate("TIMERANGE_LIVE", true);
      const constantLabel = constants[filterCategory][id]?.[filterCategory];
      return handleTranslate(`${filterCategory.toUpperCase()}_${constantLabel?.toUpperCase()}`, true);
    });
  }, [filters, constants]);

  const getDefaultFilterLabels = useCallback((filterId) => {
    const filterCategory = filtersCategory[filterId].filterCategory;
    return filtersInput[filterId]?.selected.map((id) => {
      if (id === "660bc8f8e4db0b4972d2fb69") return handleTranslate("TIMERANGE_LIVE", true);
      const constantLabel = constants[filterCategory][id]?.[filterCategory];
      return handleTranslate(`${filterCategory.toUpperCase()}_${constantLabel?.toUpperCase()}`, true);
    });
  }, [filtersInput, filters, constants]);

  return (
    <AccordionComponent
      title={<CustomTitle title={view.view_name} visible={accordionOpen} isEdit={isEdit} onClickEdit={() => setEdit(true)}/>}
      expanded={accordionOpen}
      onChange={setAccordionOpen} >
      <div style={{ display: "flex", gap: "1rem", alignItems: "center", marginBottom: "1rem" }}>
        <label> {handleTranslate('ACTIVE_FILTER')} </label>
        <FilterCategorySelect disabled={!isEdit} multiple categoriesId={view.listFilters} value={activeFilters} onChange={handleChangeActiveFilters} />
      </div>
      {Object.keys(filtersInput).length > 0 &&
        <>
          <h3 style={{ fontWeight: "bold", marginTop: "2rem", marginBottom: "1rem" }}> {handleTranslate('CUSTOMIZE_FILTER')} </h3>
          {Object.keys(filtersInput).map((filterId) =>(
            <div key={filterId} style={{ display: "flex", flexWrap: "wrap", gap: "1rem", alignItems: "center" }}>
              <label>{filtersCategory[filterId].filterCategory}</label>
              <SelectComponent multiple disabled={!isEdit} labelId={filterId} placeholder={filtersCategory[filterId].filterCategory} options={getFilterOptions(filterId)} labels={getFilterLabels(filterId)} value={filtersInput[filterId]?.selected ?? []} onChange={(value) => handleChangeFilter(filterId, value, "selected")}/>
              <SelectComponent disabled={!isEdit} placeholder="Default" options={filtersInput[filterId]?.selected} labels={getDefaultFilterLabels(filterId)} value={filtersInput[filterId]?.default} onChange={(value) => handleChangeFilter(filterId, value, "default")}/>
            </div>
          ))}
        </>
      }
      {!loadingSave ? 
        isEdit &&
          <>
            <Button disabled={!canSave} onClick={handleSave}> {handleTranslate("SAVE", true)} </Button>
            <Button onClick={handleCancelChanges}> {handleTranslate("DISCARD_CHANGES", true)} </Button>
          </>
        :
        <Button endIcon={loadingSave && <CircularProgress size={15} />}> {handleTranslate('SAVING', true)} </Button>
      }
    </AccordionComponent>
  )
}

const ViewsList = ({ tenantId }) => {
  const filters = useSelector((state) => state.filters[tenantId]);
  const views = useSelector((state) => state.configuration.constants?.view_name)

  const getViewById = useCallback((viewId) => {
    return { id: viewId, ...views[viewId] }
  }, [views]);

  return Object.keys(filters).map((viewId) => (
    <SingleView key={viewId} tenantId={tenantId} view={getViewById(viewId)} filters={filters[viewId]} />
  ))
};

const SingleTenant = ({ tenantId }) => {
  const tenants = useSelector((state) => state.tenants.list);
  return (
    <AccordionComponent title={tenants[tenantId].name}>
      <ViewsList tenantId={tenantId} />
    </AccordionComponent>
  )
};

const TenantsList = ({ tenants }) => {
  return tenants.map((tenantId) => (
    <SingleTenant key={tenantId} tenantId={tenantId} />
  ))
};

const FiltersConfiguration = ({ selectedTenants }) => {

  const { t } = useTranslation("evocs");
  const handleTranslate = (textId, general = false) => {
    if (general) return translate(t, textId);
    return translate(t, textId, viewNames.ADMINISTRATION_VIEW);
  };

  return (
    <Box sx={{ width: "100%", padding: "1rem" }}>
      <div style={{ display: "flex", marginBottom: "1rem" }}>
      <button
        className="px-16 py-10 md:ml-auto w-full md:w-fit"
        style={{
          backgroundColor: "#5d9a60",
          borderRadius: "5px",
          color: "white",
          textTransform: "uppercase",
        }}
      >
        {handleTranslate('NEW_FILTER')}
      </button>
      </div>
      <TenantsList tenants={selectedTenants} />
    </Box>
  )
}

export default FiltersConfiguration