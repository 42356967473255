import { viewNames } from "app/configs/routesNames";
import { createLanguageObject } from "src/utilities/utils";


const IT = {
	HEADER : "Amministrazione",
	ACTIVE_FILTER: "Active filters",
	CUSTOMIZE_FILTER: "Personalizza filtri",
	NEW_FILTER: "Nuovo filtro",
	NEW_TENANT: "Nuovo tenant",
	NEW_USER_INVITATION_SUCCESS: "Invito spedito correttamente",
	NO_TENANTS: "Non ci sono tenant",
	INVITE_USER: "Invita utente",
	WARNING_DELETE_TENANT: 	"Attenzione, questa operazione cancellerà tutti i \
							dati relativi a questo inquilino, sei sicuro di voler \
							procedere con la cancellazione ",
	NEW_ROLE: "Nuovo ruolo",
	WARNING_DELETE_ROLE: "Sei sicuro di voler eliminare il ruolo",
	NEW_VARIABLE: "Nuova variabile",
	SAVED_SUCCESSFULLY: "Salvataggio avvenuto con successo",
	SHARE_WITH: "Condividi con",
	SHARE: "Condividi",
	IN_SHARING: "In condivisione",
    CAN_SHARE_WITH: "Può condividere con",
	NEW_SHARING: "Nuova condivisione",
    NO_PENDING : "Non ci sono inviti al momento",
    NO_SHARED_STATIONS: "Nessuna stazione in condivisione",
	EDIT_VARIABLE: "Modifica variable",
};

const ADMINISTRATION_IT = createLanguageObject(IT, viewNames.ADMINISTRATION_VIEW);

export default ADMINISTRATION_IT;