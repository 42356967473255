import { useEffect, useMemo, useState } from "react";
import {
  Modal,
  Button,
  TextField,
  IconButton,
  Checkbox,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import ConstantSelect from "../SharingComponents/Inputs/ConstantSelect";
import LoadingGif from "../SharingComponents/LoadingGif";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { translate } from "src/utilities/utils";
import { viewNames } from "app/configs/routesNames";
import { createConstant } from "app/store/configurationSlice/configurationActions";
import SelectComponent from "../SharingComponents/Inputs/SelectComponent";
import { setGlobalError, setGlobalSuccess } from "app/store/evocsSlice/globalError/errorActions";
import TenantSelect from "../SharingComponents/Inputs/TenantSelect";

const styles = {
  containerStyle: {
    position: "absolute",
    display: "flex",
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    background: "#2d3740",
    padding: "1rem",
    borderRadius: "1rem",
    height: "80vh",
    width: "95%",
    maxWidth: "800px",
    flexDirection: "column"
  },
  loadingPopup: {
    position: "absolute",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    background: "rgba(255, 255, 255, .5)",
    top: 0,
    right: 0,
    bottom: 0,
    left: 0
  }
}

const inputStyle = {width: "100%", minWidth: "100px", maxWidth: "300px" };


const ConstantConfiguration = ({requiredInputs, constant, onChangeParam, defaultValidKeys}) => {
  const { t } = useTranslation("evocs");
  const constants = useSelector((state) => state.configuration.constants);
  const handleTranslate = (textId, general = false) => {
    if (general) return translate(t, textId);
    return translate(t, textId, viewNames.ADMINISTRATION_VIEW);
  };


  const allInputs = useMemo(() => {
    if (!constant || !defaultValidKeys) return [];
    const validKeys = Object.keys(constant).filter((key) => defaultValidKeys[key]);
    return validKeys.map((key) => {
      const required = requiredInputs.includes(key);
      const multiple = defaultValidKeys[key].multiple;
      switch(defaultValidKeys[key].inputType) {
        case "text":
          return <TextField key={key} sx={inputStyle} required={required} label={defaultValidKeys[key].label} value={constant[key] || ""} onChange={(e) => onChangeParam(key, e.target.value)}/>
        case "number":
          return <TextField key={key} sx={inputStyle} required={required} label={defaultValidKeys[key].label} value={constant[key] ?? ""} onChange={(e) => onChangeParam(key, Number(e.target.value))}/>
        case "checkbox":
          const checked = !constant[key] ? false : true;
          return (
            <div key={key} style={{display: "flex", alignItems: "center"}}>
              <label>{defaultValidKeys[key].label}:</label>
              <Checkbox checked={checked} onChange={(_, value) => onChangeParam(key, value)}/>
            </div>
          );
        case "select": 
          const constantCategory = defaultValidKeys[key].constantCategory;
          const options = !constants ? [] : Object.keys(constants[constantCategory]).map((id) => id);
          const labels = options.map((constantId) => constants[constantCategory][constantId][constantCategory]);
          return (
            <SelectComponent key={key} required={required} labelId={key} placeholder={defaultValidKeys[key].label} multiple={multiple} options={options} labels={labels}
            value={constant[key] || []} onChange={(value) => onChangeParam(key, value)}/>
          );
        case "tenant": 
          return constant.activable !== 0 && constant.activable && (
            <TenantSelect key={key} required={required} labelId={key} multiple={multiple} value={constant[key] || []} onChange={(value) => onChangeParam(key, value)}/>
          );
      }
    });
  }, [t, constant, requiredInputs])
  

  return (
    <div>
      <h3 style={{marginBottom: "2rem"}}> {handleTranslate("NEW_PLURAL", true)} {constant.constantType}</h3>
      <div style={{display: "flex", gap: "1rem", marginBottom: "1rem", flexWrap: "wrap"}}>
        {allInputs.map((input) => (
          input
        ))}
      </div>
    </div>
  )
}

const FormCreation = ({onPending, onFinally }) => {
  const { t } = useTranslation("evocs");
  const constants = useSelector((state) => state.configuration.constants);
  const [inputs, setInputs] = useState({
    constantType: "",
  });
  const [constantsTypeOptions, setConstantsTypeOptions] = useState([]);
  const [disableConfirm, setDisableConfirm] = useState(true);

  const requiredInputs = useMemo(() => {
    if(inputs.constantType !== "view_name") return ["constantType", "identifier", "name", "unit", "title", "lat", "lon"];
    else if(inputs.activable && inputs.activable !== 0) return ["constantType", "identifier", "name", "unit", "title", "lat", "lon", "tenantIds"];
    else return ["constantType", "identifier", "name", "unit", "title", "lat", "lon"];
  }, [inputs]);

  const handleTranslate = (textId, general = false) => {
    if (general) return translate(t, textId);
    return translate(t, textId, viewNames.ADMINISTRATION_VIEW);
  };

  const defaultValidKeys = useMemo(() => {
    return {
      identifier: {
        label: handleTranslate("IDENTIFIER", true),
        inputType: "text",
      },
      name: {
        label: handleTranslate("NAME", true),
        inputType: "text"
      },
      unit: {
        label: handleTranslate("MEASUREMENT_UNIT", true),
        inputType: "text"
      },
      color: {
        label: handleTranslate("COLOR", true),
        inputType: "text"
      },
      title: {
        label: handleTranslate("TITLE", true),
        inputType: "text"
      },
      templateMessage: {
        label: handleTranslate("MESSAGE", true),
        inputType: "textarea"
      },
      temp: {
        label: handleTranslate("TIME", true),
        inputType: "number"
      },
      lat: {
        label: handleTranslate("LATITUDE", true),
        inputType: "number"
      },
      lon: {
        label: handleTranslate("LONGITUDE", true),
        inputType: "number"
      },
      groupId: {
        label: handleTranslate("ACTION_GROUP", true),
        inputType: "select",
        multiple: false,
        constantCategory: "actionGroup"
      },
      listFilters: {
        label: handleTranslate("FILTERS_IN_VIEW", true),
        inputType: "select",
        multiple: true,
        constantCategory: "filterCategory"
      },
      activable: {
        label: handleTranslate("ACTIVABLE", true),
        inputType: "checkbox"
      },
      dynamic: {
        label: handleTranslate("DYNAMIC", true),
        inputType: "checkbox"
      },
      actions: {
        label: handleTranslate("ACTIONS", true),
        inputType: "select",
        multiple: true,
        constantCategory: "action"
      },
      tenantIds: {
        label: "Tenant",
        inputType: "tenant",
        multiple: true,
      }, 
      dynamic : {
        label: "Dynamic",
        inputType: "checkbox",
        constantCategory: "filterCategory",
        checked: false,
      },
    }
  }, [t]);

  useEffect(() => {
    if(!constants) return;
    setConstantsTypeOptions(Object.keys(constants).filter((constantId) => constantId!=="role" && constantId !== "filterId" && constantId !== "alert" && constantId!=="categoryAlert" && constantId!=="groupId"));
  }, [constants])

  useEffect(() => {
    if(inputs.constantType === "") return;
    const constantKeys = Object.keys(Object.values(constants[inputs.constantType])[0]);
    let newInputs = {constantType: inputs.constantType};
    constantKeys.forEach((key) => {
      if(key === inputs.constantType) key = "identifier";
      else if(key == "filterId") {
        newInputs[key] = Object.keys(constants.filterCategory).find((filterId) => constants.filterCategory[filterId].filterCategory === inputs.constantType);
        return;
      }
      else if(key === "label") return;
      if (defaultValidKeys?.[key]?.inputType === 'checkbox') {
        newInputs[key] = defaultValidKeys?.[key]?.checked ?? 0;
      } else {
        return newInputs[key] = null;
      }
    });
    if(inputs.constantType === "view_name") newInputs.tenantIds = null;
    setInputs(newInputs);
  }, [inputs.constantType])

  useEffect(() => {
    const disabled = Object.keys(inputs).some((key) => requiredInputs.includes(key) && (!inputs[key] || inputs[key] === "" || inputs[key]?.length === 0));
    setDisableConfirm(disabled);
  }, [inputs]);



  const handleChangeInput = (key, value) => {
    if(key === "activable") {
      setInputs((prev) => ({ ...prev, [key]: value, tenantIds: null }));
      return;
    }
    setInputs((prev) => ({...prev, [key]: value}));
  }

  const handleConfirm = () => {
    let apiObj = {...inputs, [inputs.constantType]: inputs.identifier};
    delete apiObj.identifier;
    onPending();
    createConstant(inputs.constantType, [apiObj])
    .then(() => setGlobalSuccess(true, "MODAL_SUCCESS_CREATION_CONSTANT"))
    .catch(() => setGlobalError(true))
    .finally(onFinally)
  }

  return(
    <div style={{ display: "flex", flexDirection: "column", gap: "2rem", padding: "2rem" }}>
      <h2 style={{ fontWeight: "bold" }}> {handleTranslate('NEW_VARIABLE')} </h2>
      <ConstantSelect constantsId={constantsTypeOptions} value={inputs.constantType} onChange={(value) => handleChangeInput("constantType", value)}/>
      {inputs.constantType !== "" && <ConstantConfiguration requiredInputs={requiredInputs} constant={inputs} onChangeParam={handleChangeInput} defaultValidKeys={defaultValidKeys}/>}
      <Button sx={{ width: "fit-content", margin: "0 auto", border: "1px solid black" }} disabled={disableConfirm} onClick={handleConfirm}> {handleTranslate('CONFIRM', true)} </Button>
    </div>
  )
}


const CreateParamModal = ({isOpen = false, onFinally, onClose}) => {
  const [loading, setLoading] = useState(false);

  const handleFinally = () => {
    setLoading(false);
    onFinally && onFinally();
  }

  const handleClose = () => {
    setLoading(false);
    onClose && onClose();
  }

  return (
    <Modal open={isOpen}>
      <div style={styles.containerStyle}>
        <div style={{ display: "flex" }}>
          <IconButton sx={{ marginLeft: "auto" }} onClick={handleClose}>
            <CloseIcon fontSize="small" sx={{ color: "white" }} />
          </IconButton>
        </div>
        <div style={{ background: "white", borderRadius: "0 1rem 1rem 1rem", height: "100%", overflowY: "auto" }}>
          <FormCreation onPending={() => setLoading(true)} onFinally={handleFinally}  />
          {loading &&
            <div style={styles.loadingPopup}>
              <LoadingGif />
            </div>
          }
        </div>
      </div>
    </Modal>
  )
};

export default CreateParamModal;
