const GENERAL_SUCCESS_MESSAGE = {
	MODAL_SUCCESS_GENERAL_MESSAGE : "Azione eseguita con successo",
}

const GENERAL_WARNING_MESSAGE = {
    MODAL_NO_SAVE_EXIT_WARNING : "Se esci senza salvare le modifiche andranno perse",
    MODAL_ALREADY_INSTALLED_WARNING : "Questo dispositivo è già installato su questa unità",
    MODAL_SURE_TO_DELETE_WARNING : "Sei sicuro di eliminare gli elementi selezionati?",
}

const GENERAL_ERROR_MESSAGE = {
	MODAL_ERROR_GENERAL_MESSAGE : "Ops qualcosa è andato storto",
}

const STATION_SUCCESS_MESSAGE = {
    MODAL_SUCCESS_CREATION_STATION : "Stazione creata con successo",
    MODAL_SUCCESS_UPDATE_STATION : "Stazione aggiornata con successo",
    MODAL_SUCCESS_DELETE_STATION : "Stazione eliminata con successo",
    MODAL_SUCCESS_UPDATE_STATION_HISTORY : "Storico stazione aggiornato con successo",
}

const STATION_WARNING_MESSAGE = {
    MODAL_WARNING_DELETE_STATION : "Sei sicuro di voler eliminare la stazione?",
}

const STATION_ERROR_MESSAGE = {
    MODAL_ERROR_DELETE_STATION : "Impossibile eliminare la stazione, ci sono unità installate su di essa",
}

const CU_SUCCESS_MESSAGE = {
    MODAL_SUCCESS_CREATION_CU : "Stazione creata con successo",
    MODAL_SUCCESS_UPDATE_CU : "Centralina aggiornata con successo",
    MODAL_SUCCESS_DELETE_CU : "Centralina eliminata con successo",
    MODAL_SUCCESS_UPDATE_CU_HISTORY : "Storico centralina aggiornato con successo",
}

const CU_WARNING_MESSAGE = {
    MODAL_WARNING_DELETE_CU : "Sei sicuro di voler eliminare la centralina?",
    MODAL_WARNING_UNINSTALL_CU : "Sicuro di voler disinstallare la centralina?",
}

const CU_ERROR_MESSAGE = {
    MODAL_ERROR_DELETE_CU : "Impossibile eliminare la centralina, essa è installata su una stazione o ci sono sensori installati su di essa",
    MODAL_ERROR_INSTALL_CU : "Questa centralina è già installata in questa stazione"
}

const SENSOR_SUCCESS_MESSAGE = {
    MODAL_SUCCESS_CREATION_SENSOR : "Sensore creato con successo",
    MODAL_SUCCESS_UPDATE_SENSOR : "Sensore aggiornato con successo",
    MODAL_SUCCESS_DELETE_SENSOR : "Sensore eliminato con successo",
    MODAL_SUCCESS_UPDATE_SENSOR_HISTORY : "Storico sensore aggiornato con successo",
}

const SENSOR_WARNING_MESSAGE = {
    MODAL_WARNING_DELETE_SENSOR : "Sicuro di voler eliminare il sensore?",
    MODAL_WARNING_UNINSTALL_SENSOR : "Sicuro di voler disinstallare il sensore?",
    MODAL_WARNING_REMOVE_MEASURE : "Sicuro di voler rimuovere la misura?",
}

const SENSOR_ERROR_MESSAGE = {
    MODAL_ERROR_DELETE_SENSOR : "Impossibile eliminare il sensore, esso è installato su una unità",
    MODAL_ERROR_INSTALL_SENSOR : "Questo sensore è già installato in questa unità"
}

const MEASURE_ERROR_MESSAGE = {
    MODAL_ERROR_ADD_MEASURE: "Impossibile aggiungere la misura, essa è già presente nel sensore"
}

const TENANT_SUCCESS_MESSAGE = {
    MODAL_SUCCESS_CREATION_TENANT : "Tenant creato con successo"
}

const TENANT_WARNING_MESSAGE = {
    MODAL_WARNING_DELETE_TENANT : "Sicuro di voler eliminare questo tenant?",
}

const USER_SUCCESS_MESSAGE = {
    MODAL_SUCCESS_CREATION_USER : "Utente creato con successo"
}

const USER_WARNING_MESSAGE = {
    MODAL_WARNING_DELETE_USER : "Sicuro di voler eliminare questo utente?",
}

const ROLE_WARNING_MESSAGE = {
    MODAL_WARNING_DELETE_ROLE : "Sicuro di voler eliminare questo ruolo?",
}

const CONSTANT_SUCCESS_MESSAGE = {
    MODAL_SUCCESS_CREATION_CONSTANT: "Constante creata con successo"
}

const RECORDING_SUCCESS_MESSAGE = {
    MODAL_SUCCESS_DELETE_RECORDING: "Registrazione eliminata con successo"
}

const RECORDING_WARNING_MESSAGE = {
    MODAL_WARNING_DELETE_RECORDING: "Sicuro di voler eliminare la registrazione?"
}

const USER_INVITATION_SUCCESS_MESSAGE = {
    MODAL_SUCCESS_INVITED_USER: "Utente invitato con successo",
}

const VPN_MODAL_MESSAGE = {
    MODAL_CONFIRM_DELETE_DEVICE_VPN: "Sei sicuro di voler eliminare il dispositivo?",
    MODAL_SUCCESS_REBOOT: "Reboot avvenuto con successo",
}

export const MODALS_IT = {
    ...GENERAL_SUCCESS_MESSAGE,
    ...GENERAL_WARNING_MESSAGE,
    ...GENERAL_ERROR_MESSAGE,
    ...STATION_SUCCESS_MESSAGE,
    ...STATION_WARNING_MESSAGE,
    ...STATION_ERROR_MESSAGE,
    ...CU_SUCCESS_MESSAGE,
    ...CU_WARNING_MESSAGE,
    ...CU_ERROR_MESSAGE,
    ...SENSOR_SUCCESS_MESSAGE,
    ...SENSOR_WARNING_MESSAGE,
    ...SENSOR_ERROR_MESSAGE,
    ...MEASURE_ERROR_MESSAGE,
    ...TENANT_SUCCESS_MESSAGE,
    ...TENANT_WARNING_MESSAGE,
    ...USER_SUCCESS_MESSAGE,
    ...USER_WARNING_MESSAGE,
    ...ROLE_WARNING_MESSAGE,
    ...CONSTANT_SUCCESS_MESSAGE,
    ...RECORDING_SUCCESS_MESSAGE,
    ...RECORDING_WARNING_MESSAGE,
    ...USER_INVITATION_SUCCESS_MESSAGE,
    ...VPN_MODAL_MESSAGE
};
