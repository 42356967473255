import { Box } from "@mui/system";
import { viewNames } from "app/configs/routesNames";
import useOpendata from "src/app/hooks/useOpendata";
import OpenDataAirContentHeader from "./OpenDataAirContentHeader";
import LoadingGif from "../SharingComponents/LoadingGif";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import AqTable from "./AqTable";
import MapComponent from "../SharingComponents/MapComponent";
import { getOpenDataLastRelevations } from "app/store/evocsSlice/openaData/openaDataActions";
import { getOpenDataFiles } from "app/store/evocsSlice/reports/reportsActions";
import useFilters from "src/app/hooks/useFilters";
import { Grid, Paper } from "@mui/material";
import NoDataMessage from "../SharingComponents/NoDataMessage";
import useMobile from "src/app/hooks/useMobile";
import { useTranslation } from 'react-i18next';
import { translate } from "src/utilities/utils";

const viewName = viewNames.OPEN_DATA_AIR_QUALITY_VIEW;

const OpenDataAirContent = () => {
  const [xs] = useMobile();
  const measures = useSelector((state) => state.configuration.constants.measure);
  const filters = useFilters(viewName);
  const timeRangeInView = filters.timeRange;
  const sourceSelect = filters.open_source;
  const [popupsContent, setPoupsContent] = useState([]);
  const [mapCenter, setMapCenter] = useState();
  const [selectedMarker, setSelecteMarker] = useState();
  const fileName = 'open_data_air_quality';
  const { t } = useTranslation("evocs");
  const handleTranslate = (textId, general = false) => {
    if (general) return translate(t, textId);
    return translate(t, textId, viewNames.OPEN_DATA_AIR_QUALITY_VIEW);
  };

  const [data, markers, loadingMarkers, loadingData] = useOpendata(timeRangeInView.startDate, timeRangeInView.endDate, "AQ", sourceSelect.selected);

  useEffect(() => {
    if (!markers || !measures) return;
    setPoupsContent(markers.map((element, index) => {
      const newElement = { ...element };
      delete newElement.lat; delete newElement.lon; delete newElement.icon;
      const measuresLabels = [];
      newElement.measures?.forEach((measureId) => {
        let labelTemp = measures[measureId]?.measure || measure
        measuresLabels.push(handleTranslate(`MEASURE_${labelTemp.toUpperCase()}`, true));
      })
      newElement.title =  `${newElement.station} (${newElement.source})`;
      newElement.source = { label: handleTranslate("SOURCE", true), value: handleTranslate(newElement.source, true) };
      newElement.station = { label: handleTranslate("STATION", true), value: newElement.station };
      newElement.type = { label: handleTranslate("TYPOLOGY", true), value: handleTranslate("AIR_QUALITY", true) };
      newElement.measures = { label: handleTranslate("MEASURES", true), value: measuresLabels };
      return newElement;
    }))
  }, [markers, measures, t]);


  const getNewPopups = (lastRelevations) => {
    if (!lastRelevations || lastRelevations.length === 0) return;
    const newPopups = [...popupsContent];
    lastRelevations.forEach((el) => {
      
      let values = []
      Object.keys(el).forEach((field) => {
        if(field === 'time' || field === 'stationId') return;
        const measureId = field;
        const label = handleTranslate(`MEASURE_${measures[measureId]?.measure.toUpperCase()}`, true) || '';
        values.push({ label, value: el[measureId] });
      });

      const index = newPopups?.findIndex((popup) => popup._id === el.stationId);
      newPopups[index].lastRelevations = { 
        title: handleTranslate("LAST_RELEVATIONS", true),
        subtitle: el.time,
        values: values
      };
    });

    setPoupsContent(newPopups);
  };
  
  const handleClickMarker = useCallback((marker, popup, index) => {
    setSelecteMarker(index);
  }, [popupsContent])  
  
  useEffect(() => {
    if (!selectedMarker) return;
    getOpenDataLastRelevations(markers[selectedMarker].station).then((res) => {
      getNewPopups(res);
    });
  }, [selectedMarker])

  const handleClickTableRow = (rowIndex) => {
    const dataElement = data[rowIndex];
    const mIndex = markers.findIndex((m) => m._id === dataElement.id);
    const newCenter = { lat: markers[mIndex].lat, lon: markers[mIndex].lon };
    window.scrollTo({ top: -1000, behavior: "smooth" });
    setMapCenter(newCenter);
    setSelecteMarker(mIndex);
  };

  const handleDownloadFile = async (fileType) => {
    const fileData = await getOpenDataFiles(timeRangeInView.startDate, timeRangeInView.endDate, sourceSelect, null, null, 'AQ', fileType, fileName);
    return fileData;
  };

  const tableData = useMemo(() => {
    const allowedKeys = ["time", "station", "source", "type"];
    const transformMeasuresObj = (e) => {
      const newObj = {};
      Object.entries(e).forEach(([key, value]) => {
        if (allowedKeys.includes(key)) {
          newObj[key] = value;
        } else {
          if (measures?.[key] && measures?.[key]?.measure) {
            newObj[measures[key].measure.toUpperCase()] = value;
          }
        }
      });
      return newObj;
    };
    return data.map((e) => transformMeasuresObj(e));
  }, [data, measures]);

  return (
    <Box padding={{ xs: "1rem", sm: "2rem 5rem" }}>
      <OpenDataAirContentHeader />
      <Grid container spacing={2}>
        <Grid item xs={12} md={6} lg={8}>
          {loadingData ?
            <Paper sx={{ display: "flex", alignItems: "center", height: "100%", minHeight: "40vh" }}><LoadingGif /></Paper>
            :
            data?.length > 0 ?
              <AqTable data={tableData} onClickRow={handleClickTableRow} downloadFileProps={{ handleDownloadFile: handleDownloadFile, fileName: fileName }} />
              :
              <Paper sx={{ display: "flex", alignItems: "center", justifyContent: "center", height: "100%", minHeight: "40vh" }}>
                <NoDataMessage />
              </Paper>
          }
        </Grid>
        <Grid item xs={12} md={6} lg={4}>
          <MapComponent height={xs ? "40vh" : "77vh"} cluster center={mapCenter} markers={markers} popupsContent={popupsContent} selectedMarker={selectedMarker}
            onClickMarker={handleClickMarker} />
        </Grid>
      </Grid>


    </Box>
  )
}

export default OpenDataAirContent;