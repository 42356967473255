import { viewNames } from "app/configs/routesNames";
import { createLanguageObject } from "src/utilities/utils";

const EN = {
    HEADER : "Internal network management",
    ADD_DEVICE: "Add Device",
    SEARCH: "Search...",
    DEVICE_NAME: "Device Name",
    PORT: "Port",
    STATUS: "Status",
    ADD_DEVICE_TITLE: "Add a new device",
    NAME_IN_USE: "Name already in use",
    ERROR_CHARS: "Name can't contain special chars",
    PORT_IN_USE: "Port already in use",
    ERROR_PORT: "The port cannot exceed 5 characters",
    EDIT_INFO: "Edit device information",
    QUICK_ACTIONS: "Quick Actions",
    DELETE_DEVICE: "Delete Device",
    REBOOT: "Reboot",
    NEW_DEVICE_SUCCESSFULLY_ADDED_TO_VPN: "The new device has been successfully added to the VPN",
    NEW_DEVICE_SUCCESSFULLY_DELETED_FROM_VPN: "The device has been successfully deleted from the VPN",
    FORGET : "Forget", 
    AVAILABLE_NETWORKS: "Available networks",
    DEVICES_DETECTED : "Devices detected",
    PRIORITY_NETWORK : "Priority network",
    SAVED_NETWORKS: "Saved networks",
	CONNECTED_TO: "Connected to",
    NOT_CONNECTED: "Not connected",
    DEVICE_UNREACHABLE: "Device temporarily unreachable",
    EXCELLENT: "Excellent",
    OPTIMAL: "Optimal",
    GOOD: "Good",
    WEAK: "Weak",
    SCAN_WIFI: "Wi-Fi networks scan",
    SCAN_BLT: "Bluetooth scan",
    SCAN_ETHERNET : "Check ethernet connection",
    INTERNAL_NETWORK_ERROR: "Internal network temporarily unreachable, please try again later",
    NO_ETHERNET_CONNECTION : "No Ethernet connection",
    PAIRING: "Visible",
    NOT_PAIRING: "Not visible",
};

const INTERNAL_NETWORK_MANAGEMENT_EN = createLanguageObject(EN, viewNames.INTERNAL_NETWORK_MAN_VIEW);

export default INTERNAL_NETWORK_MANAGEMENT_EN;