import { useSelector } from "react-redux";
import AccordionComponent from "../SharingComponents/AccordionComponent";
import GraphSwitcher from "./GraphSwitcher";
import { memo, useCallback, useEffect, useMemo, useState } from "react";
import LoadingGif from "../SharingComponents/LoadingGif";
import { routesNames, viewNames } from "app/configs/routesNames";
import { Link, useNavigate } from "react-router-dom";
import { getStationSensors, hasStationMultimedia } from "app/store/evocsSlice/stationsMongo/stationsMongoActions";
import { LuInspect } from "react-icons/lu";
import SentimentVeryDissatisfiedIcon from '@mui/icons-material/SentimentVeryDissatisfied';
import Carousel from "../SharingComponents/Carousel";
import DownloadComponent from "../SharingComponents/DownloadComponent/DownloadComponent";
import { getCuMeasures } from "app/store/evocsSlice/controlUnits/cuActions";
import { isMeasureGps, isMeasureMultimedia, isSensorGps, isSensorMultimedia } from "app/store/evocsSlice/sensors/sensorsActions";
import { useTranslation } from 'react-i18next';
import { translate } from "src/utilities/utils";
import { IconButton, Tooltip } from "@mui/material";
import VideocamIcon from '@mui/icons-material/Videocam';


const viewName = viewNames.STATIONS_VIEW;

const StationItem = ({datasetLoading, stationId, station, measuresSelected, startDate, endDate, userActions }) => {
  const {t} = useTranslation("evocs");
  const navigate = useNavigate();
  const controlUnits = useSelector((state) => state.controlUnits.list);
  const sensors = useSelector((state) => state.sensors.list);

  const [childrens, setChildrens] = useState([]);
  const [currentCarouselIndex, setCurrentCarouselIndex] = useState(0);

  const allStationMeasures = useMemo(() => {
    const measures = [];
    getStationSensors(stationId).forEach((sensorId) => {
      sensors[sensorId]?.measures.forEach((measureId) => !isMeasureGps(measureId) && !isMeasureMultimedia(measureId) && measures.push({
        measureId: measureId,
        sensor: { sensorId, ...sensors[sensorId] }
      }));
    });
    return measures;
  }, [stationId])

  const newCuElement = useCallback((cuId, isCompare = false, selectedCompare = "", selectedSensor = null, selectedMeasure = null) => {
    return { id: cuId, isCompare, selectedCompare, selectedSensor, selectedMeasure };
  }, []);

  const newSensorElement = useCallback((sensorId, isCompare = false, selectedCompare = "", selectedMeasure = null) => {
    return { id: sensorId, isCompare, selectedCompare, selectedMeasure };
  }, []);
  
  const hasStationCamera = useMemo(() => hasStationMultimedia(stationId), [stationId]);

  useEffect(() => {
    const list = [];
    station.list_sensors.forEach((sensorId) => {
      if(sensors[sensorId]?.available === 1 && !isSensorGps(sensorId) && !isSensorMultimedia(sensorId)) {
        const childrenElement = childrens.find((element) => element.id === sensorId);
        if (!measuresSelected || measuresSelected.length === 0) {
          if (childrenElement) list.push(childrenElement);
          else list.push(newSensorElement(sensorId));
          return;
        }
        const measureSelected = measuresSelected.findLast((measureId) => sensors[sensorId]?.measures.includes(measureId))
        if(!measureSelected) return;
        if(childrenElement) {
          childrenElement.selectedMeasure = measureSelected;
          list.push(childrenElement);
        }
        else list.push(newSensorElement(sensorId, false, "", measureSelected));
      }
    });
    station.list_cu.forEach((cuId) => {
      if (controlUnits[cuId]?.available === 0) return;
      const childrenElement = childrens.find((element) => element.id === cuId);
      if (!measuresSelected || measuresSelected.length === 0) {
        if (childrenElement) list.push(childrenElement);
        else list.push(newCuElement(cuId));
        return;
      }
      const cuMeasures = getCuMeasures(cuId, true);
      const measureSelected = measuresSelected.findLast((measureId) => cuMeasures.includes(measureId))
      if (measureSelected) {
        const sensorSelected = controlUnits[cuId].list_sensors.find((sensorId) => sensors[sensorId]?.measures.includes(measureSelected));
        if (childrenElement) {
          childrenElement.selectedSensor = sensorSelected;
          childrenElement.selectedMeasure = measureSelected;
          list.push(childrenElement);
        } else
          list.push(newCuElement(cuId, false, "", sensorSelected, measureSelected));

      }
    });
    setChildrens(list);
  }, [measuresSelected])


  const handleChangeSensor = (unitId, sensorId) => {
    const newChildrens = [...childrens];
    const unitIndex = childrens.findIndex((element) => element.id === unitId);
    newChildrens[unitIndex].selectedSensor = sensorId;
    setChildrens(newChildrens);
  }

  const handleChangeMeasure = (unitId, measureId) => {
    const newChildrens = [...childrens];
    const unitIndex = childrens.findIndex((element) => element.id === unitId);
    newChildrens[unitIndex].selectedMeasure = measureId;
    setChildrens(newChildrens);
  }

  const handleChangeCompare = (unitId, isCompare) => {
    const newChildrens = [...childrens];
    const unitIndex = childrens.findIndex((element) => element.id === unitId);
    newChildrens[unitIndex].isCompare = isCompare;
    setChildrens(newChildrens);
  }

  const handleChangeSelectedCompare = (unitId, compare) => {
    const newChildrens = [...childrens];
    const unitIndex = childrens.findIndex((element) => element.id === unitId);
    newChildrens[unitIndex].selectedCompare = compare;
    setChildrens(newChildrens);
  }

  const handleClickCamera = () => {
    navigate(`${routesNames.MULTIMEDIA_VIEW}/${station.serial}`, {state: {backView: routesNames.STATIONS_VIEW}});
  };

  const handleTranslate = (textId, general = false) => {
    if (general) return translate(t, textId);
    return translate(t, textId, viewNames.STATIONS_VIEW);
  };


  return childrens?.length > 0 && (
    <AccordionComponent
      defaultExpanded
      title={
        <div style={{ display: "flex", flexDirection: "row", alignItems: "center", gap: "1rem", flexWrap: "wrap"}}>
          <Link to={`/stations/${station.serial}`} state={{ stationId: stationId }}
            style={{ fontSize: "15px" }}>
            <div style={{
              display: "flex", flexDirection: "row",
              alignItems: "baseline", gap: "0.3rem"
            }}>
              <LuInspect color="#92c870" />
              {station.serial}
            </div>
          </Link>
          <DownloadComponent userActions={userActions} startDate={startDate} endDate={endDate} stationId={stationId} stationSerial={station.serial} />
          {hasStationCamera &&
          <Tooltip title={handleTranslate("GO_TO_MULTIMEDIA", true)}>
            <IconButton size="small" onClick={handleClickCamera}>
              <VideocamIcon />
            </IconButton>
          </Tooltip>
          }
        </div>
      }>

      {!datasetLoading ?
        <Carousel value={currentCarouselIndex} onChange={setCurrentCarouselIndex} disableArrows={false}>
          {childrens.map((unit) => (
            <GraphSwitcher key={unit.id} userActions={userActions} disableSensorFilter unit={unit} startDate={startDate} endDate={endDate}
              compareOptions={allStationMeasures} sensorGraphShadow={false} graphHeight="300px"
              onChangeSensor={handleChangeSensor} onChangeMeasure={handleChangeMeasure} onChangeCompare={handleChangeCompare} onChangeSelectedCompare={handleChangeSelectedCompare} />
          ))}
        </Carousel>
        : <LoadingGif />
      }
    </AccordionComponent>
  )
}

const StationHandlingList = memo(({datasetLoading, userActions, filtersInView, list }) => {
  const { t } = useTranslation("evocs");
  const stations = useSelector((state) => state.stationsMongo.list);
  const timeRangeInView = filtersInView?.timeRange;
  const measureSelect = filtersInView?.measure;


  const handleTranslate = (textId, general = false) => {
    if (general) return translate(t, textId);
    return translate(t, textId, viewNames.STATIONS_VIEW);
  };

  return list && (
    <div style={{ minHeight: "50vh" }}>
      {list.length > 0 ?
        list.map((stationId) => (
          <StationItem key={stationId} datasetLoading={datasetLoading} stationId={stationId} station={stations[stationId]} measuresSelected={measureSelect?.selected} startDate={timeRangeInView.startDate} endDate={timeRangeInView.endDate} userActions={userActions} />
        )) :
        <div style={{ display: "flex", flexDirection: "column", gap: "1rem", alignItems: "center", color: "rgba(0, 0, 0, .5)" }} >
          <SentimentVeryDissatisfiedIcon sx={{ width: "70px", height: "70px" }} fontSize="large" />
          <h2>{handleTranslate('NO_ACTIVE_STATIONS', true)}</h2>
        </div>
      }
    </div>
  )
})

export default StationHandlingList