import { th } from "date-fns/locale";
import store from "../..";
import { userSliceActions } from "./userSlice";
import { changeLanguage } from "app/store/i18nSlice/i18nActions";

const getSqlIdFromStorage = () => {
  try {
    const sqlId = window.localStorage.getItem("sql_id");
    if (!sqlId) throw new Error("usersAction: sql_id not found");
    return sqlId;
  } catch (error) {
    console.log("usersAction: error while getting sql_id");
    throw new Error(error);
  }
};

export const getUsers = async (tenantIds) => {
  if (!tenantIds) throw new Error("tenantIds cannot be null");
  const data = await store
    .dispatch(userSliceActions.getTenantsUsers(tenantIds))
    .then(({ payload, error }) => {
      if (error) throw error;
      return payload;
    });
  return data;
};

//FIXME: Variabile fcmToken momentaneamente commentata in funzione getMyUser
// export const getMyUser = async (fcmToken) => {
export const getMyUser = async () => {
  // console.log('getMyUser fcmToken: ', fcmToken);
  let sqlId = getSqlIdFromStorage();
  const data = await store
    .dispatch(userSliceActions.getMyUser({sqlId:sqlId}))
    .then(({ payload, error }) => {
      if (error) throw error;
      return payload;
    });

  let selectedTenant = window.localStorage.getItem("selectedTenant");
  let roleId = window.localStorage.getItem("roleId");
  if (!selectedTenant || !roleId) {
    selectedTenant = data.tenantId;
    roleId = data.roleId;
  }
  const evocsLang = window.localStorage.getItem("evocs_lang");
  if (!evocsLang) changeLanguage(data?.language);
  setUserTenant(selectedTenant);
  setUserRole(roleId);
  //FIXME: Set dell'fcmToken momentaneamente commentata
  // setFcmtoken(fcmToken);
  // if (
  //   data?.sqlId &&
  //   data?.language &&
  //   data?.proPic !== undefined &&
  //   fcmToken
  // ) {
  //   console.log('getMyUser');
  //   updateUser(data.sqlId, undefined, evocsLang, data.proPic, undefined, undefined, fcmToken);
  //   // window.removeItem("evocs_lang");
  // } 
  return data;
};

/**
 * Update user profile
 * @param {number} sqlId - sql id of the user
 * @param {number} roleId - role id of the user
 * @param {string} language - language of the user
 * @param {string} proPic - profile picture of the user
 * @param {string} password - new password of the user
 * @param {string} currentPassword - current password of the user
 * @param {string} fcmToken - fcm token of the user
 * @returns {Promise<Object>} - the updated user object
 */
export const updateUser = async (
  sqlId = undefined,
  roleId = undefined,
  language = undefined,
  proPic = undefined,
  password = undefined,
  currentPassword = undefined, 
  fcmToken = undefined
) => {
  changeLanguage(language);
  const data = await store
    .dispatch(
      userSliceActions.updateUser({
        sqlId,
        roleId,
        language,
        proPic,
        password,
        currentPassword,
        fcmToken,
      })
    )
    .then(({ payload, error }) => {
      if (error) throw error;
      return payload;
    });
  return data;
};

export const inviteUser = async (
  email,
  username,
  tenantId,
  language,
  roleId
) => {
  await store
    .dispatch(userSliceActions.inviteUser({ email, username, language }))
    .then(({ payload, error }) => {
      if (error) throw error;
    });
  const data = await store
    .dispatch(userSliceActions.createInvitedUser({ email, tenantId, roleId }))
    .then(({ payload, error }) => {
      if (error) throw error;
    });
  return data;
};

export const checkRecaptchaV3 = async (token, hostname) => {
  const data = await store
    .dispatch(userSliceActions.checkRecaptchaV3({ token, hostname }))
    .then(({ payload, error }) => {
      if (error) throw error;
      return payload;
    });
  return data;
};

export const activateUser = async (
  email,
  username,
  password,
  secureCode,
  language,
  userImage = null
) => {
  const data = await store
    .dispatch(
      userSliceActions.activateUser({
        email,
        username,
        password,
        secureCode,
        language,
        userImage,
      })
    )
    .then(({ payload, error }) => {
      if (error) throw error;
      return payload;
    });
  return data;
};

export const setUserTenant = (tenantId) => {
  store.dispatch(userSliceActions.setSelectedTenant(tenantId));
  window.localStorage.setItem("selectedTenant", tenantId);
};

export const setUserRole = (roleId) => {
  store.dispatch(userSliceActions.setRole(roleId));
  window.localStorage.setItem("roleId", roleId);
};

export const setUserLanguage = (language) => {
  store.dispatch(userSliceActions.setLanguage(language));
};

export const setFcmtoken = (fcmToken) => {
  store.dispatch(userSliceActions.setFcmtoken(fcmToken));
};

export const setLastUserTenant = (tenantId) => {
  store.dispatch(userSliceActions.setLastSelectedTenant(tenantId));
};

const setLoading = (loading) => {
  store.dispatch(userSliceActions.setLoading(loading));
};

export const readAlerts = async (alertIds, lang = "en") => {
  const data = await store
    .dispatch(userSliceActions.readAlerts({ alertIds, lang }))
    .then(({ payload, error }) => {
      if (error) throw error;
      return payload;
    });
  return data;
};

export const readAlertsByUser = async (userId, startDate, endDate) => {
  const data = await store
    .dispatch(userSliceActions.readAlertsByUser({ userId, startDate, endDate }))
    .then(({ payload, error }) => {
      if (error) throw error;
      return payload;
    });
  return data;
};

export const deleteAlertsByUser = async (userId, alertIds) => {
  const data = await store
    .dispatch(userSliceActions.deleteAlertsByUser({ userId, alertIds }))
    .then(({ payload, error }) => {
      if (error) {
        // setGenericError(true)
        throw error;
      }
      return payload;
    });
  return data;
};

export const passwordForget = async (email) => {
  const data = await store
    .dispatch(userSliceActions.passwordForget({ email }))
    .then(({ payload, error }) => {
      if (error) {
        // setGenericError(true)
        throw error;
      }
      return payload;
    });
  return data;
};

export const updateCookies = async (dictUpdate) => {
  if (!dictUpdate) throw new Error("dictUpdate is required.");
  const user = store.getState().user;
  const data = await store
    .dispatch(
      userSliceActions.updateCookies({
        userId: user.id,
        tenantId: user.tenantId,
        dictUpdate,
      })
    )
    .then(({ payload, error }) => {
      if (error) throw error;
      return payload;
    });
  return data;
};

export const readInvitedUsers = async (tenantId) => {
  const data = await store
    .dispatch(userSliceActions.readInvitedUsers({ tenantId }))
    .then(({ payload, error }) => {
      if (error) throw error;
      return payload;
    });
  return data;
};

export const deleteInvitedUsers = async (userIds, tenantId) => {
  const data = await store
    .dispatch(userSliceActions.deleteInvitedUsers({ userIds, tenantId }))
    .then(({ payload, error }) => {
      if (error) throw error;
      return payload;
    });
  return data;
};

export const updateFcmToken = async (user, fcmToken) => {
  const {propic, sqlId} = user;
  const language = window.localStorage.getItem("evocs_lang");
  console.log('propic sqlId language: ', propic, sqlId, language);
  // const data = await store
  //   .dispatch(userSliceActions.updateFcmToken({ fcmToken }))
  //   .then(({ payload, error }) => {
  //     if (error) throw error;
  //     return payload;
  //   });
  // return data;
};