import { useEffect, useMemo, useState } from "react";
import TimeIntervalSelector from "../StationHandling/TimeIntervalSelector";
import { useSelector } from "react-redux";
import TimeRangeSlider from "./TimeRangeSlider";
import { TIME_FORMAT, calcIntervalDates } from "../StationHandling/utils";
import DateTimeModal from "../Modals/DateTimeModal";
import { useTranslation } from 'react-i18next';
import { translate } from "src/utilities/utils";
const timeFormat = TIME_FORMAT.COMPLETE_DATE;

const customIntervalId = "65f011b0dbaa0fa4b9d66ebe";
// const liveId = "660bc8f8e4db0b4972d2fb69";

const RangeTimeFilter_v2 = ({
  style,
  startDate,
  endDate,
  sliderStart,
  sliderEnd,
  intervals,
  selectedInterval,
  onChangeInterval,
  onChangeSlider,
}) => {
  const timeRangeConstants = useSelector(
    (state) => state.configuration.constants.timeRange
  );
  const [options, setOptions] = useState(intervals ?? []);
  const [interval, setInterval] = useState(selectedInterval ?? "");
  const [startingDate, setStartDate] = useState(startDate);
  const [endingDate, setEndDate] = useState(endDate);
  const [sliderStartDate, setSliderStartDate] = useState(sliderStart);
  const [sliderEndDate, setSliderEndDate] = useState(sliderEnd);
  const [calendarStart, setCalendarStart] = useState(sliderStart);
  const [calendarEnd, setCalendarEnd] = useState(sliderEnd);
  const [openDateTimeModal, setOpenDateTimeModal] = useState(false);
  const [maxRangeTimeLabel, setMaxRangeTimeLabel] = useState("");


  const { t } = useTranslation("evocs");
  const handleTranslate = (textId, general = false) => {
    if (general) return translate(t, textId);
    return translate(t, textId, viewNames.DASHBOARD_VIEW);
  };

  const sortedIntervals = useMemo(() => {
    if(!options) return Object.keys(timeRangeConstants);
    const intervalasObj = intervals.map((id) => {
      return {...timeRangeConstants[id], constantId: id};
    });
    const timeUnits = { 'mm': 1, 'h': 60, 'd': 1440, 'm': 43200 }; // Minuti per unità
  
    function parseTimeRange(timeRange) {
        if (timeRange === '*') return Infinity; // Il valore jolly deve essere sempre l'ultimo
        
        const match = timeRange.match(/(\d+)(mm|h|d|m)/);
        if (!match) return NaN;
        
        const value = parseInt(match[1], 10);
        const unit = match[2];
        return value * timeUnits[unit]; // Convertiamo tutto in minuti
    }
    
    let test = intervalasObj.sort((a, b) => parseTimeRange(a.timeRange) - parseTimeRange(b.timeRange))
    test = test.map(item => item.constantId);
    return test;
  }, [options, timeRangeConstants])

  const maxRangeTime = useMemo(() => {
    if(!sortedIntervals) return 86400000*7;
    const lastId = sortedIntervals[sortedIntervals.length-2];
    const timeLabel = (timeRangeConstants[lastId].timeRange??'');
    if (timeLabel !== '') {
      const match = timeLabel.match(/(\d+)(mm|h|d|m)/);
      let tempLabel = '';
      if (match) {
        const timeNumber = match[1];
        tempLabel = ' ' + timeNumber + ' ';
        const timeUnit = match[2];
        switch(timeUnit) {
          case 'mm':
            if (timeNumber === '1') tempLabel += handleTranslate('MINUTE', true);
            else tempLabel += handleTranslate('MINUTES', true);
            break;
          case 'h':
            if (timeNumber === '1') tempLabel += handleTranslate('HOUR', true);
            else tempLabel += handleTranslate('HOURS', true);
            break;
          case 'd':
            if (timeNumber === '1') tempLabel += handleTranslate('DAY', true);
            else tempLabel += handleTranslate('DAYS', true);
            break;
          case 'm':
            if (timeNumber === '1') tempLabel += handleTranslate('MONTH', true);
            else tempLabel += handleTranslate('MONTHS', true);            
            break;
          default:
            break;
        }
      }
      setMaxRangeTimeLabel(tempLabel);
    }
    const dates = calcIntervalDates(timeRangeConstants[lastId].timeRange);
    return dates.end - dates.start;
  }, [sortedIntervals, t])


  useEffect(() => {
    if (intervals) {
      setOptions(intervals);
      setInterval(intervals[0]);
    } else {
      const newOptions = Object.keys(timeRangeConstants);
      setOptions(newOptions[0]);
      setInterval(newOptions[0]);
    }
  }, [intervals]);

  useEffect(() => {
    selectedInterval && setInterval(selectedInterval);
  }, [selectedInterval]);

  useEffect(() => {
    if (!interval || interval === customIntervalId) return;
    const range = calcIntervalDates(timeRangeConstants[interval].timeRange);
    setStartDate(new Date(range.start));
    setEndDate(new Date(range.end));
  }, [interval]);

  useEffect(() => {
    startDate && setStartDate(startDate);
    endDate && setEndDate(endDate);
  }, [startDate, endDate]);

  useEffect(() => {
    setSliderStartDate(sliderStart);
    setSliderEndDate(sliderEnd);
  }, [sliderStart, sliderEnd]);

  useEffect(() => {
    if (openDateTimeModal) {
      setCalendarStart(sliderStart);
      setCalendarEnd(sliderEnd);
    }
  }, [openDateTimeModal]);

  const handleChangeInterval = (selected) => {
    if (selected === customIntervalId) {
      setOpenDateTimeModal(true);
      return;
    }
    const range = calcIntervalDates(timeRangeConstants[selected].timeRange);
    setInterval(selected);
    onChangeInterval(selected, range.start, range.end);
  };

  const handleChangeSlider = (start, end, value) => {
    setSliderStartDate(start);
    setSliderEndDate(end);
    onChangeSlider?.(start, end, value);
  };

  const handleChangeCalendar = (calendarStart, calendarEnd) => {
    setStartDate(new Date(calendarStart));
    setEndDate(new Date(calendarEnd));
    setInterval(customIntervalId);
    setOpenDateTimeModal(false);
    onChangeInterval?.(customIntervalId, calendarStart, calendarEnd);
  };

  return (
    <div style={{ ...style }}>
      <div>
        <TimeIntervalSelector
          disableNullValue
          intervals={sortedIntervals}
          value={interval}
          onChange={handleChangeInterval}
          customIntervalId={customIntervalId}
        />
      </div>
      <DateTimeModal
        isOpen={openDateTimeModal}
        start={calendarStart}
        end={calendarEnd}
        maxRangeTime={maxRangeTime}
        maxRangeTimeLabel={maxRangeTimeLabel}
        onConfirm={handleChangeCalendar}
        onClose={() => setOpenDateTimeModal(false)}
      />
    </div>
  );
};

export default RangeTimeFilter_v2;
