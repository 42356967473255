import React, { useEffect, useMemo, useState } from "react";
import TabsComponent from "../SharingComponents/TabsComponent";
import StationsTab from "./TabComponents/StationsTab";
import SensorsTab from "./TabComponents/SensorsTab";
import ControlUnitsTab from "./TabComponents/ControlUnitsTab";
import TenantsTab from "./TabComponents/TenantsTab";
import { Box } from "@mui/material";
import { useTranslation } from "react-i18next";
import { translate } from "src/utilities/utils";
import { viewNames } from "app/configs/routesNames";

const TrashContent = () => {
  const [selectedTab, setSelectedTab] = useState("tab1");

  const { t } = useTranslation("evocs");

  const handleTranslate = (textId, general = false) => {
    if (general) return translate(t, textId);
    return translate(t, textId, viewNames.TEST_PAGE_VIEW);
  };
  const tabs = useMemo(() => {
    return {
      tab1: {
        header: handleTranslate("STATIONS", true),
        content: <StationsTab handleTranslate={handleTranslate} />,
      },
      tab2: {
        header: handleTranslate("SENSORS", true),
        content: <SensorsTab handleTranslate={handleTranslate} />,
      },
      tab3: {
        header: handleTranslate("CONTROL_UNITS", true),
        content: <ControlUnitsTab handleTranslate={handleTranslate} />,
      },
      tab4: {
        header: handleTranslate("TENANTS", true),
        content: <TenantsTab handleTranslate={handleTranslate} />,
      },
    };
  }, [t]);

 

  return (
    <Box
      sx={{ display: "flex", height: "100%" }}
      padding={{ xs: "1rem", sm: "2rem 5rem" }}
    >
      <TabsComponent
        onChange={setSelectedTab}
        tabs={tabs}
        style={{ width: "100%" }}
      />
    </Box>
  );
};

export default TrashContent;
