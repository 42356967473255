import React, { useEffect, useState } from 'react'
import CloseIcon from "@mui/icons-material/Close";
import { IconButton } from '@mui/material';


const CustomModal = ({children, isOpen, defaultOpen=false, onClose, style}) => {
  const [open, setOpen] = useState(defaultOpen);

  useEffect(() => {
    setOpen(isOpen);
  }, [isOpen])


  return (
    <div className='custom-modal' style={ {opacity: open ? 1 : 0, pointerEvents: open ? "auto" : "none", ...style}}>
      <div className='container'>
        <div style={{display: "flex"}}>
          <IconButton sx={{marginLeft: "auto"}} onClick={onClose}>
            <CloseIcon fontSize="small" sx={{color: "white"}}/>
          </IconButton>
        </div>
        <div style={{background: "white", borderRadius: "1rem", height: "100%", overflowY: "auto"}}>
          {children}
        </div>
      </div>
    </div>
  )
}

export default CustomModal