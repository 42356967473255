import { useEffect, useLayoutEffect, useMemo, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { routesNames, viewNames } from "app/configs/routesNames";
import {
  setFilter,
  setTimeRangeFilter,
} from "app/store/evocsSlice/filters/filtersActions";
import MeasureAutocomplete from "../SharingComponents/Inputs/MeasureAutocomplete";
import {
  getStationMeasures,
  hasStationGps,
  hasStationMultimedia,
  hasEcoPath,
} from "app/store/evocsSlice/stationsMongo/stationsMongoActions";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import ArrowBack from "@mui/icons-material/ArrowBackIosNew";
import ArrowForward from "@mui/icons-material/ArrowForwardIos";
import { IconButton, Paper, Tooltip } from "@mui/material";
import RefreshIcon from "@mui/icons-material/Refresh";
import { FaMapLocationDot } from "react-icons/fa6";
import { FaRoute } from "react-icons/fa";
import ToggleButton from "../SharingComponents/Inputs/ToggleButton";
import useFilters from "src/app/hooks/useFilters";
import useLoadingDevices from "src/app/hooks/useLoadingDevices";
import { calcIntervalDates } from "../StationHandling/utils";
import useActions from "src/app/hooks/useActions";
import VideocamIcon from "@mui/icons-material/Videocam";
import { translate } from "src/utilities/utils";
import { useTranslation } from "react-i18next";
import RangeTimeFilter_v2 from "../SharingComponents/RangeTimeFilter";
import CuSelect from "../SharingComponents/Inputs/CuSelect";
import { getStationSensors } from "app/store/evocsSlice/stationsMongo/stationsMongoActions";
import SensorAutocomplete from "../SharingComponents/Inputs/SensorAutocomplete";
import DevicesAutocomplete from "./DevicesAutocomplete";
import { getCuMeasures } from "app/store/evocsSlice/controlUnits/cuActions";
import { getSensorsMeasures } from "app/store/evocsSlice/sensors/sensorsActions";
import {
  isSensorGps,
  isSensorMultimedia,
  hasSensorWindMeasures,
} from "app/store/evocsSlice/sensors/sensorsActions";

const viewName = viewNames.SINGLE_STATION_VIEW;

const SwitchStation = ({
  style,
  stationList,
  value,
  defaultValue = undefined,
  onChange,
}) => {
  const stations = useSelector((state) => state.stationsMongo.list);
  const [selected, setSelected] = useState(defaultValue);

  useLayoutEffect(() => {
    value && setSelected(value);
  }, [value]);

  const handleClickArrow = (direction) => {
    const index = stationList.indexOf(selected) + direction;
    if (index < 0 || index >= stationList.length) return;
    setSelected(stationList[index]);
    onChange && onChange(stationList[index]);
  };

  return (
    <Paper
      sx={{
        ...style,
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        gap: "1rem",
        width: "100%",
        maxWidth: "300px",
        padding: "1rem",
      }}
    >
      <IconButton
        disabled={selected === stationList[0]}
        onClick={() => handleClickArrow(-1)}
      >
        <ArrowBack />
      </IconButton>
      <h3 style={{ textAlign: "center" }}>{stations[selected]?.serial}</h3>
      <IconButton
        disabled={selected === stationList[stationList.length - 1]}
        onClick={() => handleClickArrow(1)}
      >
        <ArrowForward />
      </IconButton>
    </Paper>
  );
};

const SingleStationHandlingContentHeader = ({
  userActions,
  enableLastPositionMap,
  enableMapRoute,
  enableEcoPath,
  onToggleLastPosition,
  onToggleMapRoute,
  onToggleEcoPath,
  onRefresh,
  startDate,
  endDate,
  sliderStart,
  sliderEnd,
  devices,
  setDevices
  
}) => {
  // console.log('devices: ', devices);
  const { t } = useTranslation("evocs");
  const location = useLocation();
  const params = useParams();
  const navigate = useNavigate();
  const loadingDevices = useLoadingDevices();
  const actionsInStationHandling = useActions(viewNames.STATIONS_VIEW);
  const loadingDatasets = useSelector((state) => state.dataset.loading);
  const user = useSelector((state) => state.user);
  const stations = useSelector((state) => state.stationsMongo.list);
  const controlUnits = useSelector((state) => state.controlUnits.list);
  const availableStations = useSelector(
    (state) => state.stationsMongo.available
  );
  const timeRange = useSelector(
    (state) => state.configuration.constants?.timeRange
  );
  const sensors = useSelector((state) => state.sensors.list);

  const filters = useFilters(viewName);

  const timeRangeInView = filters.timeRange;
  const measureSelect = filters.measure;
  const devicesSelect = filters.devicesName
  const stationId = useMemo(() => {
    if (location.state?.stationId) return location.state.stationId;
    return Object.keys(stations).find(
      (id) => stations[id].serial === params.serial
    );
  }, [location.state]);
  const stationSensors = useSelector(
    (state) => state.stationsMongo.list[stationId].list_sensors
  );
  const cuInstalled = useSelector(
    (state) => state.stationsMongo.list[stationId].list_cu
  );
  const sensorIds = getStationSensors(stationId);

  const [disalbledGPS, setDisabledGPS] = useState(true);
  const [disalbledEcoPath, setDisalbledEcoPath] = useState(true);

  const [selectedMeasures, setSelectedMeasures] = useState([]);
  const [selectedDevices, setSelectedDevices] = useState([]);

  const disableFilters = useMemo(() => {
    if (!actionsInStationHandling) return true;
    return !actionsInStationHandling.find(
      (action) => action.name === "show-filters"
    );
  }, [actionsInStationHandling]);

  const { enabledIntervals, defaultInterval } = useMemo(() => {
    const intervals = [];
    let defaultId = null;
    timeRangeInView?.list.forEach((element) => {
      if (element.active === 1) intervals.push(element._id);
      if (element.default === 1) defaultId = element._id;
    });
    defaultId = timeRangeInView?.selected
      ? timeRangeInView.selected
      : defaultId
      ? defaultId
      : intervals[0];
    return { enabledIntervals: intervals, defaultInterval: defaultId };
  }, [user.selectedTenant, timeRangeInView.list]);

  const stationsMeasures = useMemo(() => {
    return getStationMeasures(stationId, true);
  }, [stationId]);

  const hasStationCamera = useMemo(
    () => hasStationMultimedia(stationId),
    [stationId]
  );

  useEffect(() => {
    if (!defaultInterval) return;
    const intervalValue = timeRange?.[defaultInterval]?.timeRange;
    const range = calcIntervalDates(intervalValue);
    if (!range.start || !range.end) return;
    setTimeRangeFilter(
      viewName,
      timeRangeInView.id,
      defaultInterval,
      range.start,
      range.end
    );
  }, [defaultInterval]);

  useEffect(() => {
    if (loadingDevices) return;
    setDisabledGPS(!hasStationGps(stationId));
    setDisalbledEcoPath(!hasEcoPath(stationId));
  }, [loadingDevices, stationId]);

  useEffect(() => {
    if (enableMapRoute && disalbledGPS) {
      onToggleMapRoute(false);
      onToggleEcoPath(false);
    }
  }, [stationId, disalbledGPS]);

  useEffect(() => {
    setFilter(viewName, measureSelect.id, [], stationsMeasures);
    setFilter(viewName, devicesSelect?.id, []);
    setDevices((prev) => ({
      ...prev,
      measures: getStationMeasures(stationId, true),
    }));
  }, [stationId]);

  const handleChangeMeasureSelect = (measureId) => {
    setFilter(viewName, measureSelect?.id, measureId);
    if(measureId.length === 0){
      setFilter(viewName, devicesSelect?.id, [])
    }
    setStationUnits(measureId);
  };

  const handleChangeStation = (stationId) => {
    navigate(`/stations/${stations[stationId].serial}`, {
      state: { stationId, timeout: 800 },
    });
  };

  const handleChangeInterval = (intervalId, startDate, endDate) => {
    setTimeRangeFilter(
      viewName,
      timeRangeInView.id,
      intervalId,
      startDate,
      endDate
    );
  };

  const handleChangeSlider = (startDate, endDate, value) => {
    setTimeRangeFilter(
      viewName,
      timeRangeInView.id,
      undefined,
      startDate.valueOf(),
      endDate.valueOf()
    );
  };

  const handleClickCamera = () => {
    navigate(`${routesNames.MULTIMEDIA_VIEW}/${stations[stationId].serial}`, {
      state: {
        backView: `${routesNames.STATIONS_VIEW}/${stations[stationId].serial}`,
      },
    });
  };

  const handleTranslate = (textId, general = false) => {
    if (general) return translate(t, textId);
    return translate(t, textId, viewNames.STATIONS_VIEW);
  };

  const setStationUnits = (measures) => {
    if (measures.length > 0) {
      const filteredCus = cuInstalled.filter((id) => {
        const cuMeasures = getCuMeasures(id);
        return measures.every((measure) => cuMeasures.includes(measure));
      });
      const filteredSensors = sensorIds.filter((id) => {
        const sensorMeasures = getSensorsMeasures(id);
        return measures.every((measure) => sensorMeasures.includes(measure));
      });
      setDevices((prev) => ({
        ...prev,
        control_units: prev.control_units.filter((el) =>
          filteredCus.includes(el.id)
        ),
        sensors: prev.sensors.filter((el) => filteredSensors.includes(el.id)),
      }));
    } else {
      setDevices((prev) => ({
        ...prev,
        control_units: Object.entries(controlUnits)
          .map(([id, cu]) => ({
            id: id,
            type: "cu",
            serial: cu.serial,
            selected: false,
            measures: getCuMeasures(id),
          }))
          .filter((cu) => cuInstalled.includes(cu.id)),
        sensors: Object.entries(sensors)
          .map(([id, sensor]) => ({
            id: id,
            type: "sensor",
            serial: sensor.serial,
            selected: false,
            measures: getSensorsMeasures(id),
          }))
          .filter(
            (sensor) =>
              stationSensors.includes(sensor.id) &&
              !isSensorGps(sensor.id) &&
              !isSensorMultimedia(sensor.id) &&
              !hasSensorWindMeasures(sensor.id)
          ),
      }));
    }
  };
  const setFilteredMeasures = (newValues) => {
    const selectedCusId = newValues
      .filter((device) => device.type === "cu")
      .map((cu) => cu.id);
    const selectedSensorsId = newValues
      .filter((device) => device.type === "sensor")
      .map((sensor) => sensor.id);
    const measuresByCus = getCuMeasures(selectedCusId, true);
    const measuresBySensors = getSensorsMeasures(selectedSensorsId, true);
    if (newValues.length > 0) {
      return [...measuresByCus, ...measuresBySensors];
    } else {
      return getStationMeasures(stationId);
    }
  };
  const handleChangeDevice = (deviceId) => {
    setFilter(viewName, devicesSelect?.id, deviceId);
    setSelectedDevices(deviceId);
    setDevices((prev) => ({
      ...prev,
      control_units: prev.control_units.map((unit) => ({
        ...unit,
        selected:
          deviceId.length > 0
            ? deviceId.some((newUnit) => newUnit.id === unit.id)
            : false,
      })),
      sensors: prev.sensors.map((sensor) => ({
        ...sensor,
        selected:
          deviceId.length > 0
            ? deviceId.some((newSensor) => newSensor.id === sensor.id)
            : false,
      })),
      measures: setFilteredMeasures(deviceId),
    }));
  };

  return (
    <div style={{ marginBottom: "2rem" }}>
      {availableStations.length > 1 && (
        <SwitchStation
          style={{ margin: "0 auto 1rem auto" }}
          stationList={availableStations}
          defaultValue={stationId}
          onChange={handleChangeStation}
        />
      )}
      <div className="flex flex-col md:flex-row justify-between items-center md:mt-20">
        {disableFilters ? (
          <div className="basis-2/3"></div>
        ) : (
          <div className="flex flex-col lg:flex-row gap-4 w-full">
            <div className="lg:w-[20%]">
              <DevicesAutocomplete
                stationSensors={stationSensors}
                stationId={stationId}
                cusId={cuInstalled}
                sensorsId={sensorIds}
                devices={devices}
                setDevices={setDevices}
                controlUnits={controlUnits}
                onChange={handleChangeDevice}
                selectedDevices={selectedDevices}
                setSelectedDevices={setSelectedDevices}
              />
            </div>
            <div className="lg:w-[20%]">
              <MeasureAutocomplete
                multiple
                limitTags={1}
                measuresId={devices.measures}
                onChange={handleChangeMeasureSelect}
              />
            </div>
            <div className="lg:w-[20%] mt-5 md:mt-0 ">
              <RangeTimeFilter_v2
                withDatePicker
                intervals={enabledIntervals}
                startDate={startDate}
                endDate={endDate}
                sliderStart={sliderStart}
                sliderEnd={sliderEnd}
                onChangeInterval={handleChangeInterval}
                onChangeSlider={handleChangeSlider}
                selectedInterval={timeRangeInView.selected}
              />
            </div>
          </div>
        )}

        {/* Sezione dei pulsanti */}
        <div>
          <div className="flex justify-end items-center">
            <Tooltip title={handleTranslate("REFRESH", true)}>
              <div>
                <IconButton
                  disabled={loadingDatasets}
                  sx={{ color: "black", marginLeft: "auto" }}
                  onClick={onRefresh}
                >
                  <RefreshIcon style={{ width: "35px", height: "35px" }} />
                </IconButton>
              </div>
            </Tooltip>

            <Tooltip title={handleTranslate("SHOW_POSITION", true)}>
              <div>
                <ToggleButton
                  enabled={enableLastPositionMap}
                  onChange={onToggleLastPosition}
                >
                  <FaMapLocationDot style={{ width: "27px", height: "27px" }} />
                </ToggleButton>
              </div>
            </Tooltip>

            {!disalbledGPS && (
              <>
                <Tooltip title={handleTranslate("SHOW_PATH", true)}>
                  <div>
                    <ToggleButton
                      enabled={enableMapRoute}
                      onChange={onToggleMapRoute}
                    >
                      <FaRoute style={{ width: "27px", height: "27px" }} />
                    </ToggleButton>
                  </div>
                </Tooltip>

                {!disalbledEcoPath && (
                  <Tooltip title={handleTranslate("SHOW_PATH", true)}>
                    <div>
                      <ToggleButton
                        enabled={enableEcoPath}
                        onChange={onToggleEcoPath}
                      >
                        AQ
                      </ToggleButton>
                    </div>
                  </Tooltip>
                )}
              </>
            )}

            {hasStationCamera && (
              <Tooltip title={handleTranslate("GO_TO_MULTIMEDIA", true)}>
                <IconButton sx={{ color: "black" }} onClick={handleClickCamera}>
                  <VideocamIcon style={{ width: "35px", height: "35px" }} />
                </IconButton>
              </Tooltip>
            )}
          </div>
        </div>
      </div>
      
    </div>
  );
};

export default SingleStationHandlingContentHeader;
