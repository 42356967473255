import { viewNames } from "app/configs/routesNames";
import { createLanguageObject } from "src/utilities/utils";


const EN = {
    HEADER : "Station:",
    NO_LAST_REL : "No detections in the last 15 minutes",
    FILTER: "Filter by unit",
    NO_DEVICES_SELECTED: "No devices are selected; data from all available units will be downloaded.", 
    SUMMARY_DOWNLOAD: "Summary of data to be downloaded",
};

const SINGLE_STATION_EN = createLanguageObject(EN, viewNames.SINGLE_STATION_VIEW);


export default SINGLE_STATION_EN;