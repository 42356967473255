import { Checkbox, FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import { useLayoutEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";

const TenantSelect = ({style, tenantsList, defaultValue, value, placeholder="Tenant", required=false, onChange, multiple=false, disabled=false}) => {
  const tenants = useSelector((state) => state.tenants.list);
  const options = useMemo(() => {
    if(tenantsList) return tenantsList;
    return Object.keys(tenants);
  }, [tenantsList]);
  const [val, setVal] = useState(defaultValue ? defaultValue : multiple ? [] : "");

  useLayoutEffect(() => {
    value !== undefined && setVal(value);
  }, [value]);


  const handleChange = (selected) => {
    setVal(selected);
    if(onChange) onChange(selected)
  }
  
  return(
    <FormControl disabled={disabled} sx={{ width: "100%", minWidth: "100px", maxWidth: "300px", ...style}}>
      <InputLabel id="owner-select-label">{required ? `${placeholder}*`: placeholder}</InputLabel>
      <Select        
        labelId="owner-select-label"
        value={val}
        multiple={multiple}
        renderValue={(selected) => multiple ? selected.map((tenantId) => tenants?.[tenantId]?.name).join(", ") : tenants?.[selected]?.name}
        label={required ? `${placeholder}*`: placeholder}
        onChange={(e) => handleChange(e.target.value)}
      >
        {options.map((tenantId, index) => ( 
        <MenuItem key={index} value={tenantId}>
          {multiple && <Checkbox checked={val?.indexOf(tenantId) > -1} />}
          {tenants?.[tenantId]?.name}
        </MenuItem>
        ))}
      </Select>
    </FormControl>     
  )
}

export default TenantSelect;