import { Button, CircularProgress, IconButton, TextField } from "@mui/material"
import { Box } from "@mui/system"
import CreateRoleModal from "../Modals/CreateRoleModal"
import { useCallback, useContext, useEffect, useMemo, useState } from "react";
import AccordionComponent from "../SharingComponents/AccordionComponent";
import { useSelector } from "react-redux";
import ActionSelect from "../SharingComponents/Inputs/ActionSelect";
import { deleteTenantRole, updateActionsRole, updateTenantRole } from "app/store/evocsSlice/tenants/tenantsActions";
import ViewSelect from "../SharingComponents/Inputs/ViewSelect";
import EditIcon from "@mui/icons-material/Edit";
import AlertCategorySelect from "../SharingComponents/Inputs/AlertCategorySelect";
import GroupActionSelect from "../SharingComponents/Inputs/GroupActionSelect";
import { viewNames } from 'app/configs/routesNames';
import { useTranslation } from 'react-i18next';
import { translate } from "src/utilities/utils";
import { setGlobalError, setGlobalSuccess, setGlobalWarning } from "app/store/evocsSlice/globalError/errorActions";
import { ModalContext } from "src/views/WithAuth";
import useMobile from "src/app/hooks/useMobile";
import FloatingButtons from "../SharingComponents/FloatingButtons";

const CustomTitle = ({ title, visible = false, isEdit, onClickEdit }) => {

  return (
    <div style={{ display: "flex", alignItems: "center", gap: ".5rem" }}>
      <label>{title}</label>
      {visible && !isEdit && <IconButton onClick={(e) => { e.stopPropagation(); onClickEdit?.() }}><EditIcon /></IconButton>}
    </div>
  )
};

const PermittedActions = ({isEdit, role, listViews, activeGroupActions, activeActions, onChangeGroupActions, onChangeActions }) => {
  const actions = useSelector((state) => state.configuration.constants.action);
  const views = useSelector((state) => state.configuration.constants.view_name);
  const [actionsList, setActionsList] = useState({});
  const [groupsActionsList, setGroupActionsList] = useState({});
  const [xs] = useMobile();
  //crea obj con chiavi le view e valori gli id dei gruppi di azioni
  const getGroupActionsList = useCallback(() => {
    let list = {};
    listViews.forEach(viewId => {
      list[viewId] = [];
      if (!role?.actions?.[viewId]) return;
      Object.keys(role.actions[viewId]).forEach((actionId) => {
        // if(role?.actions[viewId][actionId] !== 1) return;
        const groupId = actions[actionId].groupId;
        if(!list[viewId].includes(groupId)) list[viewId].push(groupId);
      })
    });
    return list;
  }, [role, listViews, actions]);

  const getActionsList = useCallback(() => {
    let list = {};
    listViews.forEach(viewId => {
      if (!role.actions[viewId]) return;
      list[viewId] = Object.keys(role.actions[viewId]);
    });
    return list;
  }, [listViews, role]);

  useEffect(() => {
    setGroupActionsList(getGroupActionsList());
  }, [getGroupActionsList]);

  useEffect(() => {
    setActionsList(getActionsList());
  }, [getActionsList]);

  const handleChangeGroupActions = (viewId, value) => {
    const newSelectedGroupActions = {...activeGroupActions, [viewId]: value};
    let newSelected = [], deselected = [], oldSelected = [...activeActions[viewId]];
    groupsActionsList[viewId]?.forEach((groupId) => {
      if(value.includes(groupId)) {
        if(!activeGroupActions[viewId]?.includes(groupId)) newSelected = newSelected.concat(actionsList[viewId]?.filter((actionId) => actions[actionId].groupId === groupId));
      }else deselected = deselected.concat(oldSelected.filter((actionId) => actions[actionId].groupId === groupId));
    });
    const newSelectedActions = {
      ...activeActions, 
      [viewId]: oldSelected.filter((actionId) => !deselected.includes(actionId)).concat(newSelected)
    };

    onChangeActions?.(newSelectedActions);
    onChangeGroupActions?.(newSelectedGroupActions);
  }

  const handleChangeActions = (viewId, value) => {
    const newSelectedActions = {...activeActions, [viewId]: value}
    const newSelectedGroupActions = {...activeGroupActions, [viewId]: groupsActionsList[viewId].filter((groupId) => value.find((actionId) => actions[actionId].groupId === groupId))};
    onChangeActions?.(newSelectedActions);
    onChangeGroupActions?.(newSelectedGroupActions);
  }

  return (
    <div style={{ display: "flex", flexDirection: "column", gap: "1rem" }}>
      {listViews?.map((viewId) => (
        <div key={viewId} style={{ display: "flex", gap: "1rem", alignItems: !xs && "center",  marginBottom: "1rem", flexDirection: xs && "column" }}>
          <label style={{ width: "100%", maxWidth: "200px", marginBottom: xs && "1rem" }}>{views[viewId].view_name}</label>
          <GroupActionSelect multiple disabled={!isEdit} groupsId={groupsActionsList[viewId]} value={activeGroupActions[viewId]} onChange={(value) => handleChangeGroupActions(viewId, value)} />
          <ActionSelect multiple disabled={!isEdit} actionsId={actionsList[viewId]} value={activeActions[viewId]} onChange={(value) => handleChangeActions(viewId, value)} />
        </div>
      ))}
    </div>
  );
}

const requiredInputs = ["name", "activeViews"];
const SingleRole = ({ role, tenant }) => {
  const [xs] = useMobile();
  const { t } = useTranslation("evocs");
  const modalContext = useContext(ModalContext);
  const actions = useSelector((state) => state.configuration.constants?.action);
  const views = useSelector((state) => state.configuration.constants?.view_name);
  const viewsWithActions = useMemo(() => {
    return Object.keys(views).filter((viewId) => views[viewId].activable === 0 && role.actions[viewId] && Object.keys(role.actions[viewId]).length > 0);
  }, [views]);
  const defaultRole = useMemo(() => {
    const activeViews = role.visibleViews.filter((viewId) => views[viewId].activable === 1);
    let permittedActions = {}, groupActions = {};
    Object.keys(role.actions).forEach((viewId) => {
      permittedActions[viewId] = Object.keys(role.actions[viewId]).filter((actionId) => role.actions[viewId][actionId] === 1);
    });
    Object.keys(permittedActions).forEach((viewId) => {
      groupActions[viewId] = [];
      permittedActions[viewId].forEach((actionId) => {
        const groupId = actions[actionId].groupId;
        if(!groupActions[viewId].includes(groupId)) groupActions[viewId].push(groupId);
      })
    });
    return {
      name: role.roleName,
      description: role.descr,
      categoryAlerts: role.categoryIds,
      activeViews: activeViews,
      groupActions: groupActions,
      actions: permittedActions
    }
  }, [role]);

  const [roleInputs, setRoleInputs] = useState(defaultRole);
  const [loadingSave, setLoadingSave] = useState(false);
  const [isEdit, setEdit] = useState(false);
  const [canSave, setCanSave] = useState(false);
  const [madeChanges, setMadeChanges] = useState(false);
  const [accordionOpen, setAccordionOpen] = useState(false);
  const [listViews, setListViews] = useState([]);
  const listCategoryAlerts = useMemo(() => {
    return tenant.enabledCategoryAlerts;
  }, [tenant]);
  const handleTranslate = (textId, general = false) => {
    if (general) return translate(t, textId);
    return translate(t, textId, viewNames.ADMINISTRATION_VIEW);
  };

  const enabledViews = useMemo(() => {
    return tenant.enabledViews.filter((viewId) => views[viewId].activable === 1);
  }, [tenant]);


  useEffect(() => {
    if (!madeChanges) return;
    if (requiredInputs.some((key) => roleInputs[key] === "" || roleInputs[key].length === 0)) setCanSave(false);
    else setCanSave(true);
  }, [roleInputs, madeChanges])

  useEffect(() => {
    if(!accordionOpen) handleCancelChanges();
  }, [accordionOpen])

  useEffect(() => {
    if(!isEdit) {
      setCanSave(false);
      setMadeChanges(false);
    }
  }, [isEdit])

  useEffect(() => {
    if(!roleInputs) return;
    setListViews([...viewsWithActions, ...roleInputs.activeViews]);
  }, [roleInputs?.activeViews])

  const handleChangeInputs = (key, value) => {
    setRoleInputs((prev) => ({ ...prev, [key]: value }))
    setMadeChanges(true);
  };


  const handleChangeActiveViews = (value) => {
    setMadeChanges(true);
    const stationViewId = "655f295e86458728760700b5", singleStationViewId = "655f295f86458728760700b7";
    let newSelectedView = value.filter((viewId) => !roleInputs.activeViews.includes(viewId));
    let deselectedViews = roleInputs.activeViews.filter((viewId) => !value.includes(viewId));
    let newActions = {...roleInputs.actions};
    let newGroupActions = {...roleInputs.groupActions};
    newSelectedView.forEach((viewId) => {
      newActions[viewId] = defaultRole.actions[viewId] ?? [];
      newGroupActions[viewId] = defaultRole.groupActions[viewId] ?? [];
      if(viewId === stationViewId) {
        value.push(singleStationViewId);
        newActions[singleStationViewId] = [];
        newGroupActions[singleStationViewId] = [];
      }
      else if(viewId === singleStationViewId) {
        value.push(stationViewId);
        newActions[stationViewId] = [];
        newGroupActions[stationViewId] = [];
      }
    });
    deselectedViews.forEach((viewId) => {
      value = value.filter((id) => id !== viewId);
      delete newActions[viewId];
      delete newGroupActions[viewId];
      if(viewId === stationViewId || viewId === singleStationViewId) {
        value = value.filter((id) => id !== stationViewId && id !== singleStationViewId);
        delete newActions[stationViewId];
        delete newActions[singleStationViewId];
        delete newGroupActions[stationViewId];
        delete newGroupActions[singleStationViewId];
      }
    });

    setRoleInputs((prev) => ({...prev, activeViews: value, actions: newActions, groupActions: newGroupActions}));
  }

  const handleCancelChanges = () => {
    setEdit(false);
    setRoleInputs(defaultRole);
  };

  const handleDeleteRole = () => {
    setLoadingSave(true);
    deleteTenantRole(role.tenantId, role._id)
    .then(() => setGlobalSuccess(true))
    .catch(() => setGlobalError(true))
    .finally(() => {
      setEdit(false);
      setLoadingSave(false);
    })
  };

  const handleClickDelete = () => {
    setGlobalWarning(true, "MODAL_WARNING_DELETE_ROLE");
    modalContext.setConfirmCallback(handleDeleteRole);
  }

  const handleConfirm = () => {
    setLoadingSave(true);
    updateTenantRole(role._id, roleInputs.name, roleInputs.description, listViews, roleInputs.categoryAlerts, roleInputs.actions)
    .then(() => setGlobalSuccess(true))
    .catch(() => setGlobalError(true))
    .finally(() => {
      setEdit(false);
      setLoadingSave(false);
    });
  };


  return (
    <AccordionComponent
      title={<CustomTitle title={role.roleName} visible={accordionOpen} isEdit={isEdit} onClickEdit={() => setEdit(true)} />}
      expanded={accordionOpen}
      onChange={setAccordionOpen}
    >
      <div style={{ display: "flex", gap: "1rem", flexWrap: "wrap", marginBottom: "1rem", marginTop: "1rem" }}>
        <TextField sx={{ width: "100%", maxWidth: "300px" }} required label={handleTranslate("NAME", true)} disabled={!isEdit} value={roleInputs.name} onChange={(e) => handleChangeInputs("name", e.target.value)} />
        <TextField sx={{ width: "100%", maxWidth: "300px" }} label={handleTranslate("DESCRIPTION", true)} disabled={!isEdit} value={roleInputs.description} onChange={(e) => handleChangeInputs("description", e.target.value)} />
        <AlertCategorySelect multiple disabled={!isEdit} alertCategoriesId={listCategoryAlerts} value={roleInputs.categoryAlerts} onChange={(value) => handleChangeInputs("categoryAlerts", value)} />
      </div>
      <ViewSelect multiple required disabled={!isEdit} viewsId={enabledViews} value={roleInputs.activeViews} onChange={handleChangeActiveViews} />
      {listViews.length > 0 && 
          <div>
            <h4 style={{ fontWeight: "bold", marginTop: "1.5rem", marginBottom: "1.5rem" }}>{handleTranslate("PERMITTED_ACTIONS", true)}:</h4>
            <PermittedActions isEdit={isEdit} role={role} listViews={listViews} activeGroupActions={roleInputs.groupActions} activeActions={roleInputs.actions}
              onChangeGroupActions={(value) => handleChangeInputs("groupActions", value)} onChangeActions={(value) => handleChangeInputs("actions", value)}/>
          </div>
      }
      {!loadingSave ? 
        isEdit &&
          <div style={{ display: "flex", flexDirection: xs && "column", alignItems: !xs && "center", justifyContent: !xs && "flex-end", gap: !xs ? "20px" : "5px", marginTop: "2rem"}}
          >
            <FloatingButtons
              containerStyle={{ top: !xs ? "20%" : "10%", zIndex: 1 }}
              disableSave={!canSave}
              onSave={handleConfirm}
              onDelete={handleClickDelete}
              onDiscard={handleCancelChanges}
            />
          </div>
        :
        <div style={{ display: "flex", justifyContent: "flex-end"}}>
          <Button endIcon={loadingSave && <CircularProgress size={15} />}> {handleTranslate('SAVING', true)} </Button>
        </div>
      }
    </AccordionComponent>
  )
};

const SingleTenant = ({ tenant, roles }) => {
  return (
    <AccordionComponent title={tenant.name}>
      <Box>
        {roles?.map((role) => (
          <SingleRole key={role._id} role={role} tenant={tenant} />
        ))}
      </Box>
    </AccordionComponent>
  )
};

const RolesInfo = ({ selectedTenants }) => {
  const tenants = useSelector((state) => state.tenants.list);
  const getTenant = useCallback((tenantId) => {
    return {id: tenantId, ...tenants[tenantId]};
  }, [tenants])
  return (
    <div style={{ display: "flex", flexDirection: "column" }}>
      {selectedTenants.map((tenantId) => (
        <SingleTenant key={tenantId} tenant={getTenant(tenantId)} roles={tenants[tenantId]?.roles} />
      ))}
    </div>
  );
};

const RolesConfiguration = ({ selectedTenants }) => {
  const { t } = useTranslation("evocs");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const handleTranslate = (textId, general = false) => {
    if (general) return translate(t, textId);
    return translate(t, textId, viewNames.ADMINISTRATION_VIEW);
  };

  return (
    <div>
      <Box sx={{ width: "100%", padding: "1rem" }}>
        <div style={{ display: "flex", marginBottom: "1rem" }}>
        <button
          className="px-16 py-10 md:ml-auto w-full md:w-fit"
          style={{
            backgroundColor: "#5d9a60",
            borderRadius: "5px",
            color: "white",
            textTransform: "uppercase",
          }}
          onClick={() => setIsModalOpen(true)}
        >
          {handleTranslate("NEW_ROLE")} 
          </button>
        </div>
        <RolesInfo selectedTenants={selectedTenants} />
      </Box>
      <CreateRoleModal isOpen={isModalOpen} onClose={() => setIsModalOpen(false)} onFinally={() => setIsModalOpen(false)}/>
    </div>
  )
};

export default RolesConfiguration;