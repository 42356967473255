import { useSelector } from "react-redux";
import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import { useState, useEffect } from "react";
import TabContent from "./TabContent";
import SideDrawer from "./SideDrawer";
import { GrClose } from "react-icons/gr";
import TextField from "@mui/material/TextField";
import { FaCheck } from "react-icons/fa6";
import useMobile from "src/app/hooks/useMobile";
import { translate } from "src/utilities/utils";
import { useTranslation } from "react-i18next";
import { viewNames } from "app/configs/routesNames";
import { FaPencilAlt } from "react-icons/fa";
import { bltPairing } from "app/store/evocsSlice/network/networkActions";
import SwitchComponent from "src/components/SharingComponents/Inputs/SwitchComponent";
import { FaBluetooth } from "react-icons/fa";

import {
  removeVpn,
  setActiveDevice,
  updateVpnDevice,
  checkFields,
  getDeviceById,
  rebootVpnDevice,
  devicePing,
  scanWifi,
  readNetData,
  scanBlt,
  readConnectedBltDevices,
} from "app/store/evocsSlice/network/networkActions";
import LoadingButton from "@mui/lab/LoadingButton";
import {
  setGlobalSuccess,
  setGlobalWarning,
} from "app/store/evocsSlice/globalError/errorActions";
import { useContext } from "react";
import { ModalContext } from "src/views/WithAuth";

export default function DeviceInfo({
  toggleDrawer,
  open,
  setOpen,
  isInputModify,
  setIsInputModify,
  updateMessage,
  deleteMessage,
}) {
  const activeDeviceId = useSelector(
    (state) => state.networkDevices.activeDevice
  );
  const activeDevice = getDeviceById(activeDeviceId);
  const [sm, md, xl] = useMobile();
  const [updatedName, setUpdatedName] = useState(activeDevice?.name || "");
  const [updatedPort, setUpdatedPort] = useState(activeDevice?.port || null);
  const [errors, setErrors] = useState({ name: false, port: false });

  const { t } = useTranslation("evocs");
  const handleTranslate = (textId, general = false) => {
    if (general) return translate(t, textId);
    return translate(t, textId, viewNames.INTERNAL_NETWORK_MAN_VIEW);
  };

  //HandlePairable 
  const [pairing, setPairing] = useState(false);
  const handlePairable = async () => {
    try {
      const pairingMode = pairing ? 0 : 1;
      const res = await bltPairing(activeDevice.port, pairingMode); //1      
      if (res.status === "Success") {
        setPairing(pairingMode === 1);
      } else {
        throw new Error("Errore nella richiesta: " + res.message);
      }
    } catch (error) {
      setPairing(false);
      throw error;
    }
  };

  useEffect(() => {
    if (activeDeviceId) {
      const device = getDeviceById(activeDeviceId);
      setUpdatedName(device.name || "");
      setUpdatedPort(device.port || null);
    }
  }, [activeDeviceId]);

  const handleUpdateSubmit = (e) => {
    e.preventDefault();
    const errorsObj = checkFields(updatedName, updatedPort, true);
    setErrors({
      name: {
        exist: errorsObj.name.isExisting,
        regex: errorsObj.name.regexSpecialChars,
      },
      port: {
        exist: errorsObj.port.isExisting,
        regex: errorsObj.port.regexNumber,
      },
    });

    if (
      Object.values(errorsObj.name).every((key) => !key) &&
      Object.values(errorsObj.port).every((key) => !key)
    ) {
      updateVpnDevice(activeDevice.id, updatedName, updatedPort);
      updateMessage();
      toggleDrawer(false);
    }
  };

  return (
    <SideDrawer open={open} toggleDrawer={toggleDrawer} sx={{ height: "100%" }}>
      <div
        style={{
          color: "white",
          padding: "1.5rem",
          backgroundColor: "#232a30",
          minHeight: "100vh",
        }}
        className="w-[100vw] md:w-[40vw]"
      >
        {/* close icon */}
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            marginBottom: "0.2rem",
          }}
          className="py-10"
        >
          <div className="flex gap-x-10 items-center">
            <span>
              <FaBluetooth
                className={`text-3xl ${
                  pairing ? "text-green-400 font-semibold" : "text-white"
                }`}
              />
            </span>
            <SwitchComponent
              label={
                pairing
                  ? handleTranslate("PAIRING")
                  : handleTranslate("NOT_PAIRING")
              }
              onChange={(e) => handlePairable()}
            />
          </div>
          <button onClick={() => toggleDrawer(false)}>
            <span>
              <GrClose
                style={{
                  fontSize: "2.4rem",
                  color: "white",
                }}
              />
            </span>
          </button>
        </div>

        <div>
          {/* info form */}
          <div
            style={{
              display: "flex",
              alignItems: "center",
              margin: xl && "1.5rem 0",
            }}
          >
            {/* text field div */}
            <div
              style={{
                flexBasis: sm ? "100%" : "70%",
                display: "flex",
                flexDirection: "column",
                gap: "0.5rem",
              }}
            >
              <TextField
                type="text"
                disabled={!isInputModify ? true : false}
                error={errors.name.exist || errors.name.regex}
                id="inputUpdateName"
                value={updatedName ?? ""}
                onChange={(e) => setUpdatedName(e.target.value)}
                helperText={
                  errors.name.exist
                    ? handleTranslate("NAME_IN_USE")
                    : errors.name.regex
                    ? handleTranslate("ERROR_CHARS")
                    : ""
                }
                sx={{
                  "& .MuiInputBase-input": {
                    width: "100%",
                    color: "#92C870",
                    fontSize: !sm ? "2rem" : "1.5rem",
                    fontWeight: "bold",
                    backgroundColor: "rgba(255,255,255,0.1)",
                    borderRadius: "10px",
                  },

                  "& .MuiFormHelperText-root": {
                    color: "white",
                  },
                }}
              />
              <TextField
                type="number"
                disabled={!isInputModify ? true : false}
                id="inputUpdateName"
                error={errors.port.exist || errors.port.regex}
                value={updatedPort || ""}
                onChange={(e) =>
                  setUpdatedPort(e.target.value ? Number(e.target.value) : null)
                }
                helperText={
                  errors.port.exist
                    ? handleTranslate("PORT_IN_USE")
                    : errors.port.regex
                    ? handleTranslate("ERROR_PORT")
                    : ""
                }
                sx={{
                  "& .MuiInputBase-input": {
                    width: "100%",
                    color: "#92C870",
                    fontSize: !sm ? "2rem" : "1.5rem",
                    fontWeight: "bold",
                    backgroundColor: "rgba(255,255,255,0.1)",
                    borderRadius: "10px",
                  },

                  "& .MuiFormHelperText-root": {
                    color: "white",
                  },
                }}
              />
            </div>

            {/* modify text field button */}
            <div
              style={{
                flexBasis: "auto",
                marginLeft: "2.5rem",
              }}
            >
              {!isInputModify ? (
                <button
                  title={handleTranslate("EDIT_INFO")}
                  onClick={() => setIsInputModify(!isInputModify)}
                  style={{
                    padding: "0.3rem",
                    borderRadius: "50%",
                  }}
                >
                  <span>
                    <FaPencilAlt
                      style={{
                        fontSize: "2rem",
                      }}
                      className="text-2xl lg:text-2xl"
                    />
                  </span>
                </button>
              ) : (
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <button
                    title={handleTranslate("EDIT_INFO")}
                    onClick={() => setIsInputModify(!isInputModify)}
                    style={{
                      padding: "0.3rem",
                      borderRadius: "50%",
                    }}
                  >
                    <span>
                      <GrClose
                        style={{
                          fontSize: "2rem",
                        }}
                      />
                    </span>
                  </button>
                  <button
                    title={handleTranslate("EDIT_INFO")}
                    onClick={handleUpdateSubmit}
                    style={{
                      padding: "0.3rem",
                      borderRadius: "50%",
                    }}
                  >
                    <span>
                      <FaCheck
                        style={{
                          fontSize: "2rem",
                        }}
                      />
                    </span>
                  </button>
                </div>
              )}
            </div>
          </div>

          {/* status label */}
          {/* <div
            style={{
              display: "flex",
              justifyContent: xl && "flex-end",
              paddingBottom: "1rem",
            }}
          >
            <h3>
              <span
                style={{
                  fontSize: "1.8rem",
                }}
              >
                {handleTranslate("STATUS")}:{" "}
              </span>
              <span
                style={{
                  fontSize: xl && "1.8rem",
                  fontWeight: "bold",
                  color: activeDevice?.isOn ? "#92C870" : "red",
                }}
              >
                {activeDevice?.isOn ? "ONLINE" : "OFFLINE"}
              </span>
            </h3>
          </div> */}
        </div>

        {/* quick actions */}
        <Actions
          activeDevice={activeDevice}
          setOpen={setOpen}
          toggleDrawer={toggleDrawer}
          deleteMessage={deleteMessage}
          handleTranslate={handleTranslate}
        />

        {/* wifi blt card */}
        <Connections pairing={pairing} />
      </div>
    </SideDrawer>
  );
}

const Actions = ({
  activeDevice,
  toggleDrawer,
  deleteMessage,
  handleTranslate,
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [confirmOpen, setConfirmOpen] = useState(false);
  const handleConfirmOpen = () => setConfirmOpen(true);
  // const handleConfirmClose = () => setConfirmOpen(false);
  const modalContext = useContext(ModalContext);

  const handleDelete = () => {
    if (!activeDevice) return;
    setIsLoading(true);
    try {
      handleConfirmOpen();
      removeVpn([activeDevice.id]);
      deleteMessage(activeDevice.name);
      setActiveDevice(null);
      toggleDrawer(false);
    } catch (error) {
      console.error("Errore durante l'eliminazione del dispositivo", error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleConfirmDelete = () => {
    setGlobalWarning(true, "MODAL_CONFIRM_DELETE_DEVICE_VPN");
    modalContext.setConfirmCallback(handleDelete);
  };

  const handleReboot = () => {
    if (!activeDevice) return;
    setIsLoading(true);
    try {
      handleConfirmOpen();
      rebootVpnDevice(activeDevice.port);
      setGlobalSuccess(true, "MODAL_SUCCESS_REBOOT");
      setActiveDevice(null);
      toggleDrawer(false);
    } catch (error) {
      console.error("Errore durante il riavvio del dispositivo", error);
    } finally {
      setIsLoading(false);
    }
  };

  const handlePing = () => {
    if (!activeDevice) return;
    setIsLoading(true);
    try {
      devicePing(activeDevice.port);
    } catch (error) {
      throw error;
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div
      style={{
        borderWidth: "1px",
        borderColor: "rgba(255,255,255,0.2)",
        borderRadius: "1.2rem",
        margin: "1rem 0",
        padding: "2rem 0",
      }}
    >
      <h2
        style={{
          textAlign: "center",
        }}
      >
        {handleTranslate("QUICK_ACTIONS")}
      </h2>
      <div className="grid grid-cols-1 lg:grid-cols-2 gap-8 p-10 md:gap-10">
        {/* Ping button */}
        <button
          className="w-3/4 md:w-auto h-[70px] md:basis-1/3 rounded-[10px] bg-[#0077b6] uppercase"
          onClick={handlePing}
        >
          <span>Ping</span>
        </button>
        {/* Reboot button */}
        <button
          className="w-3/4 md:w-auto h-[70px] md:basis-1/3 rounded-[10px] bg-orange-500 uppercase"
          onClick={handleReboot}
        >
          <span>{handleTranslate("REBOOT")}</span>
        </button>
        {/* Delete button */}
        <button
          className="w-3/4 md:w-auto h-[70px] md:basis-1/3 rounded-[10px] bg-red-500 uppercase"
          onClick={handleConfirmDelete}
        >
          <span>{handleTranslate("DELETE_DEVICE")}</span>
        </button>
        {/* <button
          className="w-3/4 md:w-auto h-[70px] md:basis-1/3 rounded-[10px] bg-blue-300 uppercase"
          onClick={handlePairable}
        >
          <span>Set as pairable </span>
        </button> */}
      </div>

      {/* <ConfirmDeleteModal
        confirmOpen={confirmOpen}
        handleConfirmClose={handleConfirmClose}
        activeDevice={activeDevice}
        handleDelete={handleDelete}
      /> */}
    </div>
  );
};

const Connections = ({pairing}) => {
  const [value, setValue] = useState("1");

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <div
      style={{
        width: "100%",
        borderWidth: "1px",
        borderColor: "rgba(255,255,255,0.2)",
        borderRadius: "1.2rem",
        margin: "1rem 0",
        minHeight: "50%",
        backgroundColor: "#232a30",
      }}
    >
      <Box sx={{ width: "100%", typography: "body1" }}>
        <TabContext value={value}>
          <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
            <TabList
              onChange={handleChange}
              aria-label="lab API tabs example"
              sx={{
                "& .MuiTab-root": {
                  color: "gray",
                },
                "& .Mui-selected": {
                  color: "#92C870 !important",
                  fontWeight: "bold",
                },
                "& .MuiTabs-indicator": {
                  backgroundColor: "#92C870 !important",
                },
              }}
            >
              <Tab label="Bluetooth" value="1" />
              <Tab label="WI-FI" value="2" />
              <Tab label="Ethernet" value="3" />
            </TabList>
          </Box>
          <TabContent pairing={pairing} />
        </TabContext>
      </Box>
    </div>
  );
};

// const ConfirmDeleteModal = ({
//   confirmOpen,
//   handleConfirmClose,
//   activeDevice,
//   handleDelete,
// }) => {
//   return (
//     <div>
//       <Modal
//         open={confirmOpen}
//         onClose={handleConfirmClose}
//         aria-labelledby="modal-modal-title"
//         aria-describedby="modal-modal-description"
//         disableEnforceFocus
//       >
//         <div
//           tabIndex="-1"
//           style={{
//             padding: "1rem",
//             display: "flex",
//             flexDirection: "column",
//             gap: "1rem",
//             borderRadius: "0.8rem",
//             alignItems: "center",
//             justifyContent: "center",
//             backgroundColor: "#232a30",
//             color: "white",
//             position: "absolute",
//             top: "50%",
//             left: "50%",
//             transform: "translate(-50%, -50%)",
//           }}
//         >
//           <h2>Sei sicuro di voler eliminare il dispositivo?</h2>
//           <div
//             style={{
//               display: "flex",
//               gap: "0.4rem",
//             }}
//           >
//             <Button
//               size="large"
//               variant="outlined"
//               onClick={handleConfirmClose}
//             >
//               Annulla
//             </Button>
//             <Button
//               size="large"
//               color="error"
//               variant="contained"
//               onClick={() => handleDelete(activeDevice)}
//             >
//               Si, elimina
//             </Button>
//           </div>
//         </div>
//       </Modal>
//     </div>
//   );
// };
