import { useContext, useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import AccordionComponent from "../SharingComponents/AccordionComponent";
import { Box } from "@mui/system";
import Avatar from "@mui/material/Avatar";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import { Button } from "@mui/material";
import { getUsers } from "app/store/evocsSlice/user/userAction";
import TableComponent from "../SharingComponents/TableComponent";
import UserConfigurationModal from "../Modals/UserConfigurationModal";
import CreateUserModal from "../Modals/CreateUserModal";
import { viewNames } from "app/configs/routesNames";
import { useTranslation } from "react-i18next";
import { translate } from "src/utilities/utils";
import LoadingGif from "../SharingComponents/LoadingGif";
import {
  setGlobalWarning,
  setGlobalSuccess,
  setServerError,
} from "app/store/evocsSlice/globalError/errorActions";
import { ModalContext } from "src/views/WithAuth";
import Modal from "@mui/material/Modal";
import { readInvitedUsers } from "app/store/evocsSlice/user/userAction";
import useMobile from "src/app/hooks/useMobile";
import "./Administration.css";
import { deleteInvitedUsers } from "app/store/evocsSlice/user/userAction";
import Checkbox from "@mui/material/Checkbox";

const UserTable = ({
  enabledActions,
  tenantId,
  users,
  selectedRows,
  onSelectRow,
  onUpdateUser,
  onDeleteUser,
}) => {
  const { t } = useTranslation("evocs");
  const modalContext = useContext(ModalContext);
  const [data, setData] = useState({});
  const [searchText, setSearchText] = useState("");
  const [selectedUser, setSelectedUser] = useState();
  const [openModal, setOpenModal] = useState(false);
  const [openPendingModal, setOpenPendingModal] = useState(false);
  const [xs] = useMobile();

  const handleTranslate = (textId, general = false) => {
    if (general) return translate(t, textId);
    return translate(t, textId, viewNames.ADMINISTRATION_VIEW);
  };

  const tableColumns = useMemo(() => {
    return {
      email: "Email",
      username: "Username",
      role: handleTranslate("ROLE", true),
      language: handleTranslate("LANGUAGE", true),
    };
  }, [t]);

  useEffect(() => {
    if (!users) {
      setData([]);
      return;
    }
    if (!searchText || searchText === "") {
      setData(users);
      return;
    }
    setData(searchUser(searchText));
  }, [users, searchText]);

  const searchUser = (text) => {
    return users.filter((element) =>
      Object.keys(element).find((key) =>
        element[key]?.toString().toLowerCase().includes(text.toLowerCase())
      )
    );
  };

  const handleClickRow = (rowIndex) => {
    setSelectedUser({ ...users[rowIndex], tenantId });
    setOpenModal(true);
  };

  const handleCloseModal = (saved = true) => {
    if (!saved) {
      setGlobalWarning(true, "Tutte le modifiche andranno perse");
      modalContext.setConfirmCallback(() => setOpenModal(false));
    } else {
      setOpenModal(false);
      setSelectedUser(null);
    }
  };

  const handleUpdateUser = (updatedUser) => {
    onUpdateUser?.(selectedUser.id, updatedUser);
    handleCloseModal();
  };

  const handlePendingOpen = () => {
    setOpenPendingModal(true);
  };

  return (
    <>
      <div
        style={{ display: "flex", justifyContent: !xs ? "flex-end" : "center" }}
      >
        <Button
          sx={{
            backgroundColor: "#91c76f !important",
            borderRadius: "10px",
            padding: "15px 25px",
            marginRight: !xs && "30px",
            fontWeight: "600",
          }}
          onClick={handlePendingOpen}
        >
          {handleTranslate("CHECK_INVITATION", true)}
        </Button>
      </div>
      {openPendingModal && (
        <PendingModal
          handleTranslate={handleTranslate}
          tenantId={tenantId}
          openPendingModal={openPendingModal}
          setOpenPendingModal={setOpenPendingModal}
        />
      )}
      <TableComponent
        paper={false}
        tableTitle={handleTranslate("USERS", true)}
        columns={tableColumns}
        data={data}
        clickable={enabledActions.includes("update-info-user")}
        selectable={enabledActions.includes("update-info-user")}
        selectedRows={selectedRows}
        onClickRow={handleClickRow}
        onSelectRows={onSelectRow}
        onSearch={setSearchText}
        onDeleteRows={onDeleteUser}
      />
      <UserConfigurationModal
        isOpen={openModal}
        user={selectedUser}
        onClose={handleCloseModal}
        onFinally={handleUpdateUser}
      />
    </>
  );
};

const SingleTenant = ({ tenantId, enabledActions }) => {
  const tenants = useSelector((state) => state.tenants.list);
  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(false);
  const [usersTable, setUsersTable] = useState([]);
  const [selectedTableRows, setSelectedTableRow] = useState([]);

  useEffect(() => {
    let canSet = true;
    setLoading(true);
    getUsers([tenantId])
      .then((res) => {
        if (!canSet) return;

        setLoading(false);
        setUsers(res);
      })
      .catch(() => setServerError(true));

    return () => (canSet = false);
  }, [tenantId]);

  useEffect(() => {
    setUsersTable(() => {
      return users.map((element) => ({
        ...element,
        role: tenants[tenantId].roles.find(
          (role) => role._id === element.roleId
        )?.roleName,
      }));
    });
  }, [users]);

  const handleUpdateUser = (userId, updatedUser) => {
    setUsers((prev) => {
      const newData = [...prev];
      const index = prev.findIndex((user) => user.id === userId);
      newData[index] = { ...prev[index], ...updatedUser };
      return newData;
    });
  };

  const handleDeleteUser = (selectedUsers) => {
    // const usersToDelete = selectedTableRows.map((rowNumber) => users[rowNumber]);
    console.log("users: ", selectedUsers);
  };

  return (
    <AccordionComponent title={tenants[tenantId]?.name}>
      {!loading ? (
        <UserTable
          enabledActions={enabledActions}
          tenantId={tenantId}
          users={usersTable}
          selectedRows={selectedTableRows}
          onSelectRow={setSelectedTableRow}
          onUpdateUser={handleUpdateUser}
          onDeleteUser={handleDeleteUser}
        />
      ) : (
        <LoadingGif />
      )}
    </AccordionComponent>
  );
};

const UsersConfiguration = ({ selectedTenants, enabledActions }) => {
  const { t } = useTranslation("evocs");
  const [openModal, setOpenModal] = useState(false);

  const disableTable = useMemo(() => {
    if (!enabledActions) return true;
    if (
      !enabledActions.includes("read-info-user") &&
      !enabledActions.includes("update-info-user")
    )
      return true;
    return false;
  }, [enabledActions]);

  const handleTranslate = (textId, general = false) => {
    if (general) return translate(t, textId);
    return translate(t, textId, viewNames.ADMINISTRATION_VIEW);
  };

  return (
    <Box sx={{ width: "100%", padding: "1rem" }}>
      {enabledActions.includes("invite-user") && (
        <div style={{ display: "flex", marginBottom: "1rem" }}>
          <button
            className="px-16 py-10 md:ml-auto w-full md:w-fit"
            style={{
              backgroundColor: "#5d9a60",
              borderRadius: "5px",
              color: "white",
              textTransform: "uppercase",
            }}
            onClick={() => setOpenModal(true)}
          >
            {handleTranslate("INVITE_USER")}{" "}
          </button>
        </div>
      )}
      {!disableTable &&
        selectedTenants.map((tenantId) => (
          <SingleTenant
            key={tenantId}
            tenantId={tenantId}
            enabledActions={enabledActions}
          />
        ))}
      <CreateUserModal
        isOpen={openModal}
        onClose={() => setOpenModal(false)}
        onFinally={() => setOpenModal(false)}
      />
    </Box>
  );
};

const PendingModal = ({
  tenantId,
  openPendingModal,
  setOpenPendingModal,
  handleTranslate,
}) => {
  const [invitedUsers, setInvitedUsers] = useState([]);
  const [loading, setLoading] = useState(false);
  const [checkedInvitations, setCheckedInvitations] = useState([]);
  const roles = useSelector((state) => state.tenants.list);
  const [xs] = useMobile();
  const handleClose = () => {
    setOpenPendingModal(false);
  };

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: !xs ? "50vw" : "95%",
    height: "50vh",
    bgcolor: "background.paper",
    boxShadow: 24,
    borderRadius: "10px",
    padding: "20px",
  };

  useEffect(() => {
    if (openPendingModal) {
      setLoading(true);
      readInvitedUsers(tenantId)
        .then((res) => {
          setInvitedUsers(res);
          setLoading(false);
        })
        .catch(() => setServerError(true));
    }
  }, [openPendingModal]);

  const getRoleName = (roleId) => {
    return roles[tenantId].roles.find((role) => role._id === roleId).roleName;
  };

  const getRandomColor = (index) => {
    let colors = ["#820263", "#d90368", "#eadeda", "#2e294e", "#ffd400"];
    return colors[index % colors.length];
  };

  const handleDeleteInvitedUser = (userId, tenantId) => {
    deleteInvitedUsers(userId, tenantId);
    handleClose();
    setGlobalSuccess(true);
  };

  const handleChecked = (userId) => {
    setCheckedInvitations((prev) => {
      if (prev.includes(userId)) {
        return prev.filter((id) => id !== userId);
      }
      return [...prev, userId];
    });
  };

  useEffect(() => {
    console.log(checkedInvitations);
  }, [checkedInvitations]);

  return (
    <div>
      <Modal
        open={openPendingModal}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          {loading ? (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                width: "100%",
                height: "100%",
              }}
            >
              <LoadingGif />
            </div>
          ) : invitedUsers?.length > 0 ? (
            <>
              <div
                style={{
                  overflowY: "auto",
                  height: "90%",
                  padding: "10px",
                }}
              >
                {!loading ? (
                  invitedUsers?.map((user, i) => (
                    <div key={user._id}>
                      <Stack
                        spacing={2}
                        direction="row"
                        sx={{
                          alignItems: "center",
                          marginY: xs ? "25px" : "10px",
                          padding: !xs ? "10px" : "5px",
                          borderRadius: "10px",
                          boxShadow: "1px 1px 5px rgba(0,0,0,0.2)",
                          display: "flex",
                          alignItems: "center",
                          position: "relative",
                          zIndex: 10,
                          backgroundColor: "white",
                        }}
                      >
                        <Avatar
                          sx={{ width: xs ? 24 : 46, height: xs ? 24 : 46 }}
                          style={{ backgroundColor: getRandomColor(i) }}
                        >
                          {user.email[0]}
                        </Avatar>
                        <Typography
                          style={{ minWidth: "30%", fontSize: xs && "1.2rem" }}
                          noWrap
                        >
                          {user.email}
                        </Typography>
                        <Typography
                          style={{ marginLeft: "auto", marginRight: "30px" }}
                          className="roleTag"
                          noWrap
                        >
                          {getRoleName(user.roleId)}
                        </Typography>

                        <Checkbox
                          disableRipple={true}
                          onChange={() => handleChecked(user._id)}
                        />
                      </Stack>
                    </div>
                  ))
                ) : (
                  //* CARICAMENTO
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      width: "100%",
                      height: "100%",
                    }}
                  >
                    {handleTranslate("NO_PENDING")}
                  </div>
                )}
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "flex-end",
                }}
              >
                <Button
                  disabled={checkedInvitations.length === 0}
                  onClick={() =>
                    handleDeleteInvitedUser(checkedInvitations, tenantId)
                  }
                  sx={{
                    backgroundColor: "#e9ecef !important",
                    padding: !xs && "10px 30px",
                    borderRadius: "10px",
                    marginLeft: "0px !important",
                  }}
                >
                  {handleTranslate("DELETE", true)}
                </Button>
              </div>
            </>
          ) : (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                width: "100%",
                height: "100%",
              }}
            >
              {handleTranslate("NO_PENDING")}
            </div>
          )}
        </Box>
      </Modal>
    </div>
  );
};

export default UsersConfiguration;
