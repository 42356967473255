import { useState, useRef, useEffect, useMemo } from "react";
import { useSelector } from "react-redux";
import { Button } from "@mui/material";
// import styles from "./DownloadSet.module.css";
import styles from "./DownloadSet.module.css";
import {
  getReportPdfInfo,
  getUnitFileData,
} from "app/store/evocsSlice/reports/reportsActions";
import { CSVLink } from "react-csv";
import { TIME_FORMAT } from "src/components/StationHandling/utils";
import { formatDate } from "src/components/StationHandling/utils";
import DynamicReportPDF from "src/components/DynamicFile/DynamicReportPDF";
import { PDFDownloadLink } from "@react-pdf/renderer";
import LoadingReportGif from "../LoadingReportGif";
import {
  BsFiletypeCsv,
  BsFiletypeXls,
  BsFilePdf,
  BsFiletypeJson,
} from "react-icons/bs";
import { setGlobalError } from "app/store/evocsSlice/globalError/errorActions";

const DownloadSet = ({
  startDate,
  endDate,
  stationId,
  cuId,
  sensorId,
  unitSerial,
  disableCsv = false,
  disableExcel = false,
  disableJson = false,
  disablePdf = false,
}) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const tenantId = useSelector((state) => state.user.selectedTenant);
  const isStation = stationId ? true : false;
  const [isRotated, setIsRotated] = useState(false);
  const [data, setData] = useState([]);
  const csvLinkRef = useRef(null);
  const [loading, setLoading] = useState(false);
  const stations = useSelector((state) => state.stationsMongo.list);
  const [reportsData, setReportsData] = useState();
  const [loadingPdfFile, setLoadingPdfFile] = useState(false);
  const [pdfBlob, setPdfBlob] = useState(null);
  const pdfLinkRef = useRef(null);

  const memoizedValues = useMemo(() => {
    const unitType = stationId
      ? "station"
      : cuId
      ? "cu"
      : sensorId
      ? "sensor"
      : null;
    const unitTypeExtended = unitType === "cu" ? "control_unit" : unitType;
    const unitId = stationId
      ? stationId
      : cuId
      ? cuId
      : sensorId
      ? sensorId
      : null;
    const startDateString = formatDate(
      new Date(startDate),
      TIME_FORMAT.CSV_DATE
    );
    const endDateString = formatDate(new Date(endDate), TIME_FORMAT.CSV_DATE);
    const fileName = `${unitTypeExtended}_${unitSerial}_from_${startDateString}_to_${endDateString}`;

    return {
      unitType,
      unitTypeExtended,
      unitId,
      startDateString,
      endDateString,
      fileName,
    };
  }, [startDate, endDate, stationId, cuId, sensorId, unitSerial]);

  const { unitType, unitId, fileName } = memoizedValues;

  const handleClick = (event) => {
    if (isStation) event.stopPropagation();
    setAnchorEl(event.currentTarget);
    setIsRotated(!isRotated);
  };

  useEffect(() => {
    if (data.length > 0) {
      csvLinkRef.current.link.click();
    }
  }, [data]);

  const handleDownload = (event, fileExtension) => {
    if (isStation) event.stopPropagation();
    if (!unitType) return;
    setLoading(fileExtension);
    getUnitFileData(
      unitId,
      startDate,
      endDate,
      fileExtension,
      tenantId,
      fileName
    )
      .then((responseData) => {
        if (fileExtension === "csv") setData(responseData);
      })
      .catch(() => setGlobalError(true))
      .finally(() => setLoading(false));
  };

  const handleDownloadPdf = async (event) => {
    event.stopPropagation();
    setLoadingPdfFile(true);

    try {
      const data = await getReportPdfInfo(startDate, endDate, stationId);
      setReportsData(data);
    } catch (error) {
      setGlobalError(true);
    } finally {
      setLoadingPdfFile(false);
    }
  };

  useEffect(() => {
    if (pdfBlob && pdfLinkRef.current) {
      pdfLinkRef.current.click();
      setReportsData(null);
      setPdfBlob(null);
    }
  }, [pdfBlob]);

  return (
    <>
      <div
        style={{
          height: "40px",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <button
          className={isStation ? styles.BtnStation : styles.Btn}
          onClick={handleClick}
          style={{
            transform: isRotated ? "rotate(-90deg)" : "rotate(0deg)",
          }}
        >
          <svg
            className={isStation ? styles.svgIconStation : styles.svgIcon}
            viewBox="0 0 384 512"
            height="1em"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path d="M169.4 470.6c12.5 12.5 32.8 12.5 45.3 0l160-160c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L224 370.8 224 64c0-17.7-14.3-32-32-32s-32 14.3-32 32l0 306.7L54.6 265.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l160 160z" />
          </svg>
          <span
            className={isStation ? styles.icon2Station : styles.icon2}
          ></span>
          <span
            className={isStation ? styles.tooltipStation : styles.tooltip}
            style={{
              opacity: isRotated ? 0 : null,
            }}
          >
            Download
          </span>
        </button>
        {!disableCsv &&
          (loading !== "csv" ? (
            <Button
              sx={{
                padding: "0px",
                display: isRotated ? true : "none",
                marginHorizontal: "0px",
              }}
              disabled={loading ? true : false}
            >
              <BsFiletypeCsv
                fontSize={30}
                className={`${styles.FileCsv} ${isRotated && styles.visible}`}
                onClick={
                  isRotated ? (event) => handleDownload(event, "csv") : null
                }
              />
              <CSVLink
                ref={csvLinkRef}
                data={data}
                style={{ display: "none" }}
                filename={`${fileName}.csv`}
              >
                Scarica CSV
              </CSVLink>
            </Button>
          ) : (
            <LoadingReportGif />
          ))}
        {!disableExcel &&
          (loading !== "excel" ? (
            <Button
              sx={{
                padding: "0px",
                display: isRotated ? true : "none",
                marginHorizontal: "0px",
              }}
              disabled={loading ? true : false}
            >
              <BsFiletypeXls
                fontSize={30}
                className={`${styles.FileCsv} ${isRotated && styles.visible}`}
                onClick={
                  isRotated ? (event) => handleDownload(event, "excel") : null
                }
              />
            </Button>
          ) : (
            <LoadingReportGif />
          ))}
        {!disableJson &&
          (loading !== "json" ? (
            <Button
              sx={{
                padding: "0px",
                display: isRotated ? true : "none",
                marginHorizontal: "0px",
              }}
              disabled={loading ? true : false}
            >
              <BsFiletypeJson
                fontSize={30}
                className={`${styles.FileCsv} ${isRotated && styles.visible}`}
                onClick={
                  isRotated ? (event) => handleDownload(event, "json") : null
                }
              />
            </Button>
          ) : (
            <LoadingReportGif />
          ))}
        {/* {!disablePdf &&
          (loadingPdfFile === false ? (
            <Button
              sx={{
                padding: "0px",
                display: isRotated ? true : "none",
                marginHorizontal: "0px",
              }}
              onClick={(event) => handleDownloadPdf(event)}
              disabled={loading ? true : false}
            >
              <BsFilePdf fontSize={25} />
            </Button>
          ) : (
            <LoadingReportGif />
          ))}
        {!disablePdf && reportsData && (
          <div display="none">
            <PDFDownloadLink
              document={<DynamicReportPDF reportsData={reportsData || {}} />}
              fileName="Evocs dynamic report.pdf"
            >
              {({ blob, url, loading, error }) =>
                !loadingPdfFile && blob ? handleBlobChange(blob, url, loadingPdfFile): null
              }
            </PDFDownloadLink>
          </div>
        )} */}
        {!disablePdf && (
          <>
            <Button
              sx={{ padding: "0px", display: isRotated ? "block" : "none" }}
              onClick={handleDownloadPdf}
              disabled={loading}
            >
              {loadingPdfFile ? (
                <LoadingReportGif />
              ) : (
                <BsFilePdf fontSize={25} />
              )}
            </Button>

            {reportsData && (
              <PDFDownloadLink
                document={<DynamicReportPDF reportsData={reportsData} />}
              >
                {({ blob, url, loading }) => {
                  if (!loadingPdfFile && blob && !pdfBlob) {
                    setPdfBlob(blob);
                  }
                  return null;
                }}
              </PDFDownloadLink>
            )}

            {pdfBlob && (
              <a
                ref={pdfLinkRef}
                href={window.URL.createObjectURL(pdfBlob)}
                download={`${stations[stationId].serial} from ${startDate} to ${endDate}.pdf`}
                style={{ display: "none" }}
              ></a>
            )}
          </>
        )}
      </div>
    </>
  );
};

export default DownloadSet;
