import { useSelector } from "react-redux";
import { useEffect, useState, useRef, useMemo, useCallback } from "react";
import { PDFDownloadLink } from "@react-pdf/renderer";
import DynamicReportPDF from "../DynamicFile/DynamicReportPDF";
import { TIME_FORMAT } from "src/components/StationHandling/utils";
import { formatDate } from "src/components/StationHandling/utils";
import {
  getReportPdfInfo,
  getUnitFileData,
} from "app/store/evocsSlice/reports/reportsActions";
import { setGlobalError } from "app/store/evocsSlice/globalError/errorActions";
import { CSVLink } from "react-csv";
import { useTranslation } from "react-i18next";
import { translate } from "src/utilities/utils";
import { viewNames } from "app/configs/routesNames";
import useTenantPalette from "src/app/hooks/useTenantPalette";
import { get } from "lodash";
import LoadingReportGif from "../SharingComponents/LoadingReportGif";
import { pdf } from "@react-pdf/renderer";

export default function SummaryDownloadModal({
  value,
  stationId,
  viewName,
  getCuSerialById,
  getSensorSerialById,
  devices,
}) {
  const { t } = useTranslation("evocs");
  const handleTranslate = (textId, general = false) => {
    if (general) return translate(t, textId);
    return translate(t, textId, viewNames.SINGLE_STATION_VIEW);
  };
  const selectedTenant = useSelector((state) => state.user.selectedTenant);
  const views = useSelector((state) => state.configuration.constants.view_name);
  const stations = useSelector((state) => state.stationsMongo.list);
  const control_units = useSelector((state) => state.controlUnits.list);
  const sensors = useSelector((state) => state.sensors.list);
  const measures = useSelector(
    (state) => state.configuration.constants.measure
  );
  const stationSerial = useSelector(
    (state) => state.stationsMongo.list[stationId].serial
  );
  const timeRange = useSelector(
    (state) => state.configuration.constants?.timeRange
  );
  const currentViewId = Object.keys(views).find(
    (viewId) => views[viewId].view_name === viewName
  );
  const selectedTheme = useTenantPalette();
  const tenantId = useSelector((state) => state.user.selectedTenant);
  const currentFilters = useSelector(
    (state) => state.filters[selectedTenant][currentViewId]
  );
  const selectedTimeRange =
    currentFilters["660fbfb3a4323ac9fd6238ba"]?.selected;
  const filterCategories = useSelector(
    (state) => state.configuration.constants.filterCategory
  );
  const measureFilterId = Object.keys(filterCategories).find(
    (filterId) => filterCategories[filterId].filterCategory === "measure"
  );
  const selectedMeasureIds = currentFilters[measureFilterId]?.selected ?? [];
  const selectedSensorIds = devices.sensors
    .filter((device) => device.selected)
    .map((sensor) => sensor.id);
  const selectedCuIds = devices.control_units
    .filter((device) => device.selected)
    .map((cu) => cu.id);
  const devicesNameFilterId = Object.keys(filterCategories).find(
    (filterId) => filterCategories[filterId].filterCategory === "devicesName"
  );
  let selectedDevice = currentFilters?.[devicesNameFilterId]?.selected ?? [];
  selectedDevice = selectedDevice?.map((item) => item?.id);

  const pdfType = useMemo(() => {
    if (!selectedMeasureIds || !selectedDevice) return;
    if (Array.isArray(selectedDevice) && selectedDevice.length > 0) return "cu";
    if (Array.isArray(selectedMeasureIds) && selectedMeasureIds.length > 0)
      return "measure";
    return "station";
  }, [selectedMeasureIds, selectedDevice]);

  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [data, setData] = useState([]);
  const csvLinkRef = useRef(null);
  const [reportsData, setReportsData] = useState();
  const [loadingPdfFile, setLoadingPdfFile] = useState(false);
  const [pdfBlob, setPdfBlob] = useState(null);
  const pdfLinkRef = useRef(null);

  const memorizedValues = useMemo(() => {
    const selectedCuId = selectedCuIds?.length ? selectedCuIds : null;
    const selectedSensorId = selectedSensorIds?.length
      ? selectedSensorIds
      : null;
    const selectedMeasureId = selectedMeasureIds?.length
      ? selectedMeasureIds
      : null;
    const selectedStationId =
      selectedCuId || selectedSensorId ? null : stationId;
    const unitSerial = selectedCuId?.length
      ? getCuSerialById(selectedCuId[selectedCuId.length - 1])
      : selectedSensorId
      ? getSensorSerialById(selectedSensorId)
      : stationSerial;

    const startingDateString = formatDate(
      new Date(startDate),
      TIME_FORMAT.CSV_DATE
    );
    const endingDateString = formatDate(
      new Date(endDate),
      TIME_FORMAT.CSV_DATE
    );
    const fileName = `${unitSerial}_from_${startingDateString}_to_${endingDateString}`;

    return {
      startingDateString,
      endingDateString,
      fileName,
      selectedCuId,
      selectedSensorId,
      selectedStationId,
      selectedMeasureId,
    };
  }, [
    startDate,
    endDate,
    selectedCuIds,
    selectedSensorIds,
    stationId,
    selectedMeasureIds,
  ]);

  const {
    fileName,
    selectedCuId,
    selectedSensorId,
    selectedStationId,
    selectedMeasureId,
  } = memorizedValues;

  useEffect(() => {
    setStartDate(currentFilters["660fbfb3a4323ac9fd6238ba"].startDate);
    setEndDate(currentFilters["660fbfb3a4323ac9fd6238ba"].endDate);
  }, [currentFilters]);

  useEffect(() => {
    if (data.length > 0) {
      csvLinkRef.current.link.click();
    }
  }, [data]);

  const getTimeLabelFromTimeRangeId = (timeRangeId) => {
    if (!timeRangeId) return;
    let timeLabel = timeRange[timeRangeId]?.timeRange;
    if (!timeLabel) return;
    switch (timeLabel) {
      case "1h":
        if (timeRangeId === "660bc8f8e4db0b4972d2fb69")
          timeLabel = handleTranslate("TIMERANGE_LIVE", true);
        else timeLabel = handleTranslate("TIMERANGE_1H", true);
        break;
      case "*":
        timeLabel = handleTranslate("TIMERANGE_CUSTOM", true);
        break;
      default:
        timeLabel = handleTranslate(
          `TIMERANGE_${timeLabel.toUpperCase()}`,
          true
        );
        break;
    }
    return timeLabel;
  };

  const getDeviceSelected = () => {
    const getSensorsByFilterMeasures = () => {
      let installedCus = stations[stationId].list_cu;
      let installedSensors = stations[stationId].list_sensors;

      let sensorsForPdf = [];

      const sensorIncludeMeasure = (sensorId) => {
        const measuresOnSensor = sensors[sensorId].measures;
        return measuresOnSensor.some((measureId) =>
          selectedMeasureIds.includes(measureId)
        );
      };

      installedCus.forEach((cuId) => {
        const sensorsFound = control_units[cuId].list_sensors.filter(
          (sensorId) => {
            return sensorIncludeMeasure(sensorId);
          }
        );
        if (sensorsFound.length > 0 && !sensorsForPdf.includes(sensorsFound))
          sensorsForPdf = [...sensorsForPdf, ...sensorsFound];
      });

      const sensorsFound = installedSensors.filter((sensorId) => {
        return sensorIncludeMeasure(sensorId);
      });
      if (sensorsFound.length > 0 && !sensorsForPdf.includes(sensorsFound))
        sensorsForPdf = [...sensorsForPdf, ...sensorsFound];

      return sensorsForPdf.length == 0 ? undefined : sensorsForPdf;
    };

    const filterCuIds =
      selectedCuIds && Array.isArray(selectedCuIds)
        ? selectedCuIds.length == 0
          ? undefined
          : selectedCuIds
        : undefined;
    let filterSensorIds =
      selectedSensorIds && Array.isArray(selectedSensorIds)
        ? selectedSensorIds.length == 0
          ? undefined
          : selectedSensorIds
        : undefined;
    let sensorsByFilterMeasures;
    if (
      !filterCuIds ||
      (!filterSensorIds && selectedMeasureIds && selectedMeasureIds.length > 0)
    ) {
      sensorsByFilterMeasures = getSensorsByFilterMeasures();
    }
    return { filterCuIds, filterSensorIds, sensorsByFilterMeasures };
  };

  const getFileName = (fileExtension = "") => {
    const { filterCuIds, filterSensorIds, sensorsByFilterMeasures } =
      getDeviceSelected();
    // console.log('filterCuIds, filterSensorIds, sensorsByFilterMeasures', filterCuIds, filterSensorIds, sensorsByFilterMeasures);

    let fileName =
      fileExtension && fileExtension === "pdf" ? "Dynamic report " : "";
    if (!filterCuIds && !filterSensorIds && !sensorsByFilterMeasures) {
      fileName += `${stations[stationId].serial}`;
    } else if (sensorsByFilterMeasures) {
      let sensorsByFilterMeasuresSerials = sensorsByFilterMeasures.map(
        (sensorId) => sensors[sensorId].serial
      );
      fileName += `(${sensorsByFilterMeasuresSerials.join(", ")})`;
    } else {
      if (filterCuIds) {
        let filterCuIdsSerials = selectedCuIds.map(
          (cuId) => control_units[cuId].serial
        );
        fileName += `(${filterCuIdsSerials.join(", ")})`;
      }
      if (filterSensorIds) {
        let filterSensorIdsSerials = inputSensorIds.map(
          (sensorId) => sensors[sensorId].serial
        );
        fileName += `(${filterSensorIdsSerials.join(", ")})`;
      }
    }

    fileName += ` from ${startDate} to ${endDate}.${fileExtension}`;
    return fileName;
  };

  const handleDownload = (event, fileExtension) => {
    setLoadingFile(true);
    getUnitFileData(
      selectedStationId,
      startDate,
      endDate,
      fileExtension,
      tenantId,
      fileName,
      selectedCuId,
      selectedSensorId,
      selectedMeasureId
    )
      .then((responseData) => {
        if (fileExtension === "csv") setData(responseData);
      })
      .catch(() => setGlobalError(true))
      .finally(() => setLoadingFile(false));
  };

  const handleDownloadPdf = async (event) => {
    event.stopPropagation();
    setLoadingPdfFile(true);

    try {
      const { filterCuIds, filterSensorIds, sensorsByFilterMeasures } =
        getDeviceSelected();
      const data = await getReportPdfInfo(
        startDate,
        endDate,
        pdfType === "station" ? stationId : undefined,
        filterCuIds,
        filterSensorIds ?? sensorsByFilterMeasures
      );
      setReportsData(data);
    } catch (error) {
      setGlobalError(true);
    } finally {
      setLoadingPdfFile(false);
    }
  };

  useEffect(() => {
    if (reportsData) {
      const generatePdfBlob = async () => {
        try {
          // Genera il blob del PDF
          const blob = await pdf(
            <DynamicReportPDF reportsData={reportsData} />
          ).toBlob();
          setPdfBlob(blob);
        } catch (error) {
          console.error("Errore durante la generazione del PDF:", error);
          setGlobalError(true);
        }
      };
      generatePdfBlob();
    }
  }, [reportsData]);

  useEffect(() => {
    if (pdfBlob && pdfLinkRef.current) {
      pdfLinkRef.current.click();
      setReportsData(null);
      setPdfBlob(null);
    }
  }, [pdfBlob]);

  return (
    <div className="p-14 flex flex-col gap-10 h-full">
      <div className="flex flex-col md:flex-row justify-between items-center md:items-start md:gap-6">
        <h2 className="text-center font-semibold">
          {handleTranslate("SUMMARY_DOWNLOAD")}
        </h2>
        <h4>
          {handleTranslate("INTERVAL", true)}:{" "}
          <span className="font-semibold">
            {getTimeLabelFromTimeRangeId(selectedTimeRange)}
          </span>
        </h4>
      </div>
      <div className="flex flex-col gap-20 my-[2rem]">
        {!selectedCuIds.length &&
        !selectedSensorIds.length &&
        !selectedMeasureIds.length ? (
          <h3 className="text-center md:px-[20%]">
            {handleTranslate("NO_DEVICES_SELECTED")}{" "}
          </h3>
        ) : (
          <>
            {selectedCuIds.length > 0 && (
              <div className="flex flex-col gap-6">
                <h3 className="font-bold">
                  {handleTranslate("CONTROL_UNITS", true)}:
                </h3>
                <div className="flex flex-wrap gap-4">
                  {selectedCuIds.map((cuId) => (
                    <p key={cuId} className="bg-grey-300 p-6 rounded-xl">
                      {getCuSerialById(cuId)}
                    </p>
                  ))}
                </div>
              </div>
            )}

            {selectedSensorIds.length > 0 && (
              <div className="flex flex-col gap-6">
                <h3 className="font-bold">
                  {handleTranslate("SENSORS", true)}:
                </h3>
                <div className="flex flex-wrap gap-4">
                  {selectedSensorIds.map((sensorId) => (
                    <p key={sensorId} className="bg-grey-300 p-6 rounded-xl">
                      {getSensorSerialById(sensorId)}
                    </p>
                  ))}
                </div>
              </div>
            )}

            {selectedMeasureIds.length > 0 && (
              <div className=" flex flex-col gap-6">
                <h3 className="font-bold">
                  {handleTranslate("PARAMS", true)}:
                </h3>
                <div className="flex flex-wrap gap-4">
                  {selectedMeasureIds.map((measureId) => (
                    <p key={measureId} className="bg-grey-300 p-6 rounded-xl">
                      {handleTranslate(
                        `MEASURE_${measures[measureId]?.measure.toUpperCase()}`,
                        true
                      )}
                    </p>
                  ))}
                </div>
              </div>
            )}
          </>
        )}
      </div>
      {value === "pdf" ? (
        <div className="mt-14 flex justify-center">
          {loadingPdfFile ? (
            <LoadingReportGif style={{ width: "60px", height: "60px" }} />
          ) : (
            <button
              style={{
                backgroundColor: selectedTheme.primaryColor,
                color: selectedTheme.secondaryColor,
              }}
              onClick={(event) => handleDownloadPdf(event)}
              className="p-8 rounded-lg border-2 border-black hover:bg-black/20"
            >
              <span className="uppercase"> Download PDF</span>
            </button>
          )}

          {pdfBlob && (
            <a
              ref={pdfLinkRef}
              href={window.URL.createObjectURL(pdfBlob)}
              download={getFileName("pdf")}
              style={{ display: "none" }}
            ></a>
          )}
        </div>
      ) : value === "csv" ? (
        <div className="flex justify-center">
          <CSVLink
            ref={csvLinkRef}
            data={data}
            style={{ display: "none" }}
            filename={`${fileName}.csv`}
          />
          <button
            style={{
              backgroundColor: selectedTheme?.primaryColor,
              color: selectedTheme?.secondaryColor,
            }}
            onClick={(event) => handleDownload(event, value)}
            className="p-8 rounded-lg border-2 border-black hover:bg-black/20"
          >
            Download <span className="uppercase">{value}</span>
          </button>
        </div>
      ) : (
        <div className="flex justify-center">
          <button
            style={{
              backgroundColor: selectedTheme?.primaryColor,
              color: selectedTheme?.secondaryColor,
            }}
            onClick={(event) => handleDownload(event, value)}
            className="p-8 rounded-lg border-2 border-black hover:bg-black/20"
          >
            Download <span className="uppercase">{value}</span>
          </button>
        </div>
      )}
    </div>
  );
}
