import { IconButton } from '@mui/material';
import React, { useLayoutEffect, useState } from 'react'

const ToggleButton = ({style, children, enabled, defaultEnabled=true, onChange}) => {
  const [active, setActive] = useState(enabled);

  useLayoutEffect(() => {
    enabled !== undefined && setActive(enabled);
  }, [enabled])

  const handleChange = () => {
    onChange ? onChange(!active) : setActive(!active);
  }

  return (
    <IconButton sx={{height: "fit-content", color: active ? "black" : "grey", ...style}} onClick={handleChange}>
      {children}
    </IconButton>
  )
}

export default ToggleButton