import React from 'react'
import { useTranslation } from 'react-i18next';
import { translate } from "src/utilities/utils";
import { viewNames } from 'app/configs/routesNames';

const TrashHeader = () => {
    const { t } = useTranslation("evocs");
    const handleTranslate = (textId, general = false) => {
      if (general) return translate(t, textId);
      return translate(t, textId, viewNames.TRASH_VIEW);
    };

  return (
    <div className='view-header'>
      <span className='title'>{handleTranslate('HEADER')}</span>
    </div>
  )
}

export default TrashHeader