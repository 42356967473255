import { IconButton, Stack } from '@mui/material';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import ArrowBack from '@mui/icons-material/ArrowBackIosNew';
import { routesNames } from 'app/configs/routesNames';
import { viewNames } from 'app/configs/routesNames';
import { useTranslation } from 'react-i18next';
import { translate } from "src/utilities/utils";


const SingleMultimediaHeader = () => {
  const { t } = useTranslation("evocs");
  const location = useLocation();
  const { serial } = useParams();
  const navigate = useNavigate();

  const handleClick = () => {
    const path = location.state?.backView ? `${location.state.backView}` : routesNames.MULTIMEDIA_VIEW;
    navigate(path);
  }

  const handleTranslate = (textId, general = false) => {
    if (general) return translate(t, textId);
    return translate(t, textId, viewNames.SINGLE_MULTIMEDIA_VIEW);
  };

  return (
    <div className='view-header'>
      <Stack spacing={1} direction="row" alignItems="center">
        <IconButton onClick={handleClick}><ArrowBack /></IconButton>
        <span className='title'>{`${handleTranslate('HEADER')} ${serial}`}</span>
      </Stack>
    </div>
  )
}

export default SingleMultimediaHeader;