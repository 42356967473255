import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { saveAs } from "file-saver";
import { PDFDocument, rgb, PDFLib } from "pdf-lib";
import { fileTypes } from "./utils";
import { getFileExtension } from "./utils";
import { STATION_DATA_TYPE } from "src/components/StationHandling/utils";


export const getDayReportsFileNames = createAsyncThunk(
  "reports/getDayReportsFileNames",
  async (payload) => {
    const { tenant="T000001" } = payload;
    const data = await axios
      .get(`/api/py/get_day_reports_filenames`, { params: { tenant: tenant } })
      .then((res) => {
        if (res.status === 200 && res.data && res.data.length > 0) {
          return res.data;
        }
        return [];
      })
      .catch((error) => {
        console.log("Error: ", error);
      });
    return data;
  }
);

const getFilesNames = createAsyncThunk(
  "file/downloadDailyReport",
  async (payload, { dispatch }) => {
    const {folderName, type, order} = payload;
    const fileNames = await axios
    .get(`/api/rest/files/sharedFolder/${folderName}/${order}`)
    .then((res) => {
      return res.data.value;
    })
    return {fileNames: fileNames, type: type};
  },
);

const downloadFile = createAsyncThunk(
  "file/downloadDailyReport",
  async (payload, { dispatch }) => {
    console.log('downloadFile asyncThunk payload: ', payload);
    const currentDate = new Date();

    const year = currentDate.getFullYear();
    const month = (currentDate.getMonth() + 1).toString().padStart(2, '0'); // Months are 0-based
    const day = currentDate.getDate().toString().padStart(2, '0');
    const formattedDate = `${year}${month}${day}`;
    const dateString = formattedDate;
    const {fileName} = payload;
    const data = await axios
    .get(`/api/rest/files/download/s/sharedFolder/AUTOREPORT_${dateString}/${fileName}`, {
      responseType: "arraybuffer"
    })
    .then((res) => {
      if (res.status === 200){
        console.log('res.data: ', res.data);
        const newFile = new Blob([res.data], { type: getFileExtension(fileName) });
        // const fileName = stationName + "_" + deviceType + "_Report";
        saveAs(newFile, `${fileName}`);
      }
    })
  },
);



export const donwloadReport = createAsyncThunk(
  "file/donwloadReport",
  async (payload, { dispatch }) => {
    const { deviceType, fileType } = payload;
    let blob = null;
    switch (deviceType) {
      case STATION_DATA_TYPE.voc:
        blob = (await dispatch(getFile(payload))).payload;
        break;
      case STATION_DATA_TYPE.gas:
        blob = (await dispatch(getFile(payload))).payload;
        break;
      case STATION_DATA_TYPE.meteo:
        blob = (await dispatch(getFile(payload))).payload;
        break
      case "PBL":
        blob = (await dispatch(getFile(payload))).payload;
        break;
      default:
        break;
    }
    // saveAs(blob, `New_file.${fileType}`);
    // Avvia l'interfaccia di download per il browser
    // try{
    //     const fileName = "VocReport";

    //     const url = window.URL.createObjectURL(new Blob([blob]));
    //     const link = document.createElement("a");
    //     link.href = url;
    //     link.setAttribute("download", `${fileName}.${fileType}`);
    //     document.body.appendChild(link);
    //     link.click();


    // } catch (err) {
    //   // Handle error
    //   console.error(err);
    // }

    // Puoi anche modificare il documento PDF se necessario
    // Ad esempio, puoi aggiungere pagine, testo, immagini, ecc.

    // Ora, crea un BlobStream per il documento PDF

    // Crea un URL oggetto dal BlobStream
    return blob;
  }
)

const getFile = createAsyncThunk(
  "file/getFile",
  async (payload, { dispatch, getState }) => {
    const { startingDate, endingDate, stationId, cuSerial, deviceType, fileType } = payload;
    console.log('getFile');
    const data = await axios
      .get(
        `/api/rest/report/Device${deviceType.charAt(0).toUpperCase() + deviceType.slice(1).toLowerCase()}`,
        {
          params: {
            cuSerialNumber: stationId,
            end: endingDate,
            field: deviceType.toUpperCase(),
            flStation: "1",
            orderBy: "+TS_STARTING",
            searchDatetime: "true",
            start: startingDate,
            tenant: "none"
          }
        }
      )
      .then(() => {
        return dispatch(downloadSingleReport(payload));
      })
      .catch((err) => {
        dispatch(setError(err));
      });

    return data;
  }
);

const downloadSingleReport = createAsyncThunk(
  "file/downloadSingleReport",
  async (payload) => {
    const { startingDate, endingDate, stationId, cuSerial, deviceType, fileType, stationName } = payload;
    // console.log("Payload: ", payload);
    axios.get(`/api/rest/report/download/Device${deviceType.charAt(0).toUpperCase() + deviceType.slice(1).toLowerCase()}`, {
      responseType: "blob",
      params: {
        cuSerialNumber: stationId,
        type: fileType,
        timezone: "Europe/Rome",
        end: endingDate,
        field: deviceType.toUpperCase(),
        flStation: "1",
        orderBy: "+TS_STARTING",
        searchDatetime: "true",
        start: startingDate,
        tenant: "none"
      }
    })
    .then((response) => {

      const newFile = new Blob([response.data], { type: response.headers['content-type'] });
      const fileName = stationName + "_" + deviceType + "_Report";
      saveAs(newFile, `${fileName}.${fileType}`);
      // const blob = new Blob([response.data], { type: response.headers['content-type'] });
      // const url = window.URL.createObjectURL(blob);
      // const a = document.createElement('a');
      // a.href = url;
      // const fileName = stationName + "_" + deviceType + "_Report";
      // a.download = `${fileName}.${fileType}`; // Replace with the desired file name
      // a.style.display = 'none';
      // document.body.appendChild(a);
      // a.click();
      // window.URL.revokeObjectURL(url);
      // document.body.removeChild(a);
    })
    .catch((error) => {console.log("Error: ", error)})
  }
)

// const downloadVOCReport = createAsyncThunk(
//   "stations/donwloadVOCReport",
//   async (payload) => {
//     const { 
//       startingDate,
//       endingDate, 
//       stationId,
//       cuSerial,
//       deviceType, 
//       fileType,
//     } = payload;
//     const data = await axios
//       .post("/api/rest/cu/data", [
//         startingDate,
//         endingDate,
//         cuSerial,
//         deviceType,
//         null, //tenant 
//         false, //flStation
//       ])
//       .then((res) => {
//         return res.data.value[0];
//       }).catch((error) => console.log("Error: ", error));
//     return data;
//   }
// )

// Funzione per creare il documento PDF con i dati JSON
async function createCSVFromData(data) {
  // Leggi i dati JSON dal Blob
  // const jsonData = await jsonBlob.text();
  // console.log("JsonData: ", jsonData);

  // // Creazione di un documento PDF vuoto
  // const pdfDoc = await PDFDocument.create();
  // const page = pdfDoc.addPage([400, 600]); // Dimensioni della pagina

  // // Aggiungi i dati JSON come testo al documento PDF
  // const textSize = 12;
  // page.drawText(jsonData, {
  //   x: 50,
  //   y: 350,
  //   size: textSize,
  //   color: rgb(0, 0, 0), // Colore del testo (nero)
  // });

  // // Salva il documento PDF in un Blob
  // const pdfBytes = await pdfDoc.save();
  // console.log("DATA: ", data);
  const pdfBlob = new Blob([data], { type: 'text/csv' });
  console.log("PDF blob: ", pdfBlob);
  saveAs(pdfBlob, "Ciccio.csv");
}


export const getMonthCSV = createAsyncThunk(
  "file/getMonthCSV",
  async () => {
    const data = await axios.get(`/api/rest/files/monthCSV/false`)
      .then((res) => {
        return res.data.value;
      })
      .catch((error) => {
        console.log("Error: ", error);
      })
    return data;
  }
);

export const downloadMonthCSV = createAsyncThunk(
  "file/downloadMonthCSV",
  async (file) => {
    // console.log("File: ", file);
    const data = await axios.get(`/api/rest/files/download/s/monthCSV/${file.name}`, {
      responseType: "arraybuffer"
    })
      .then((res) => {
        const blob = new Blob([res.data], { type: `${file.type}` });
        saveAs(blob, file.name);
        return res.data;
      })
      .catch((error) => {
        console.log("Error: ", error);
      })
    return data;
  }
);

const file = createSlice({
  name: "file",
  initialState: {
    fileLoading: false,
    fileData: [],
    monthCSV: [],
    streamMonthCSV: [],
    dailyReports: [],
    error: null,
  },
  reducers: {
    setError: (state, action) => {
      state.error = action.payload;
    },
    clearError: (state) => {
      state.error = null;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getMonthCSV.fulfilled, (state, action) => {
      state.monthCSV = action.payload;
    });
    builder.addCase(getFilesNames.fulfilled, (state, action) => {
      if (!action.payload) return;
      const {fileNames, type} = action.payload;
      switch (type) {
        case fileTypes.MONTH_CSV:
          streamMonthCSV = fileNames;          
          break;
        case fileTypes.DAILY_REPORT:
          state.dailyReports = fileNames;
          break;
        default:
          break;
      }
    });
    builder.addCase(getDayReportsFileNames.fulfilled, (state, action) => {
      if (!action.payload) return;
      state.dailyReports = action.payload;
    });
  },
});

export const fileSliceActions = {
  getFilesNames,
  downloadFile,
  getDayReportsFileNames,
}
export const { clearError } = file.actions;
export default file.reducer;
