import React from "react";
import {
  Modal,
  Button,
  TextField,
  IconButton,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { useEffect, useState } from "react";
import { getUsers, inviteUser } from "app/store/evocsSlice/user/userAction";
import LanguageSelect from "../SharingComponents/Inputs/LanguageSelect";
import TenantSelect from "../SharingComponents/Inputs/TenantSelect";
import { useSelector } from "react-redux";
import { modalsComponentNames } from 'app/configs/routesNames';
import { useTranslation } from 'react-i18next';
import { translate } from "src/utilities/utils";
import RoleSelect from "../SharingComponents/Inputs/RoleSelect";
import { setGlobalError, setGlobalSuccess } from "app/store/evocsSlice/globalError/errorActions";
import LoadingGif from "../SharingComponents/LoadingGif";


const tilebytesTenantId = "6554a3a2af4b91cdd6da3285";

const defaultInputs = {
  tenant: "",
  email: "",
  username: "",
  roleId: "",
  language: ""
};

const requiredInputs = ["tenant", "email", "username", "roleId", "language"];

const ConfigurationChip = ({onPending, onFinally }) => {
  const { t } = useTranslation("evocs");
  const user = useSelector((state) => state.user);
  const [inputs, setInputs] = useState({
    ...defaultInputs,
    tenant: user.selectedTenant
  });
  const [buttonDisabled, setButtonDisabled] = useState(true);
  const [isInputsChanged, setInputsChanged] = useState(false);
  const [tenantUsers, setTenantUsers] = useState([]);
  const [errors, setErrors] = useState(defaultInputs);

  useEffect(() => {
    if(!inputs.tenant) return;
    getUsers([inputs.tenant])
    .then((res) => setTenantUsers(res))
    .catch(() => setGlobalError(true))
    handleChangeInput("roleId", "");
  }, [inputs.tenant])

  useEffect(() => {
    const timeout = setTimeout(() => {
      const match = tenantUsers.find((element) => element.username === inputs.username);
      if(match) {
        setErrors((prev) => ({...prev, username: `Username ${handleTranslate("ALREADY_EXIST", true)}`}));
      }else setErrors((prev) => ({...prev, username: ""}));
    }, 500);

    return () => {
      clearTimeout(timeout);
    }
  }, [t, inputs.username, tenantUsers])

  useEffect(() => {
    const disabled = Object.keys(errors).some((key) => errors[key]) || requiredInputs.some((key) => (!inputs[key] || inputs[key] === "" || inputs[key].length === 0));
    isInputsChanged && setButtonDisabled(disabled);
  }, [inputs, errors])

  const handleChangeInput = (key, value) => {
    setInputs({ ...inputs, [key]: value });
    setInputsChanged(true);
  };

  const handleConfirm = async() => {
    onPending?.();
    inviteUser(inputs.email, inputs.username, inputs.tenant, inputs.language, inputs.roleId)
    .then(() => setGlobalSuccess(true, "MODAL_SUCCESS_INVITED_USER"))
    .catch(() => setGlobalError(true))
    .finally(onFinally);
  };

  const handleTranslate = (textId, general = false) => {
    if (general) return translate(t, textId);
    return translate(t, textId, modalsComponentNames.MODALS);
  };


  return (
    <div style={{ display: "flex", flexDirection: "column", gap: "2rem", padding: "2rem" }}>
      <h2 style={{ fontWeight: "bold" }}> {handleTranslate("NEW_USER")} </h2>
      {user.selectedTenant === tilebytesTenantId && <TenantSelect value={inputs.tenant} onChange={(value) => handleChangeInput("tenant", value)} />}
      <TextField
        sx={{ width: "100%", maxWidth: "300px" }}
        label="Email"
        required
        value={inputs.email}
        onChange={(e) => handleChangeInput("email", e.target.value)}
      />
      <TextField
        sx={{ width: "100%", maxWidth: "300px" }}
        label="Username"
        error={errors.username ? true : false} 
        helperText={errors.username}
        required
        value={inputs.username}
        onChange={(e) => handleChangeInput("username", e.target.value)}
      />
      <RoleSelect value={inputs.roleId} onChange={(value) => handleChangeInput("roleId", value)} tenantId={inputs.tenant} />
      <LanguageSelect required value={inputs.language} onChange={(value) => handleChangeInput("language", value)} />
      <Button sx={{ width: "fit-content", margin: "0 auto", border: "1px solid black" }} disabled={buttonDisabled} onClick={handleConfirm}> {handleTranslate('CONFIRM', true)} </Button>
    </div>
  );
}


const styles = {
  containerStyle: {
    position: "absolute",
    display: "flex",
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    background: "#2d3740",
    padding: "1rem",
    borderRadius: "1rem",
    height: "80vh",
    width: "95%",
    maxWidth: "800px",
    flexDirection: "column"
  },
  loadingPopup: {
    position: "absolute",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    background: "rgba(255, 255, 255, .5)",
    top: 0,
    right: 0,
    bottom: 0,
    left: 0
  }
};


const CreateUserModal = ({ isOpen = false, onClose, onFinally }) => {
  const [loading, setLoading] = useState(false);

  const handleFinally = () => {
    setLoading(false);
    onFinally && onFinally();
  }

  const handleClose = () => {
    setLoading(false);
    onClose && onClose();
  }

  return (
    <Modal open={isOpen}>
      <div style={styles.containerStyle}>
        <div style={{ display: "flex" }}>
          <IconButton sx={{ marginLeft: "auto" }} onClick={handleClose}>
            <CloseIcon fontSize="small" sx={{ color: "white" }} />
          </IconButton>
        </div>
        <div style={{ background: "white", borderRadius: "0 1rem 1rem 1rem", height: "100%", overflowY: "auto" }}>
          <ConfigurationChip onPending={() => setLoading(true)} onFinally={handleFinally} />
          {loading &&
            <div style={styles.loadingPopup}>
              <LoadingGif />
            </div>
          }
        </div>
      </div>
    </Modal>
  )
};

export default CreateUserModal;
