import { useCallback, useEffect, useRef, useState } from "react";
import {
  Modal,
  IconButton,
  Button,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { DateTimeField, StaticDateTimePicker } from "@mui/x-date-pickers";
import dayjs from "dayjs";
import { useTranslation } from "react-i18next";
import { translate } from "src/utilities/utils";
import { styled } from "@mui/system";

const CustomDateTimeField = styled(DateTimeField)(({selected, slotProps}) => {
  return selected && {
    "& label": { color: "#74c365" },
    ".MuiOutlinedInput-root": {
      "& fieldset": { borderColor: "#74c365", borderWidth: "2px" },
      "&:hover fieldset": !slotProps?.textField.helperText && { borderColor: "#74c365", borderWidth: "2px" }
    }
  }
});


const BasicDateTimeRangePicker = ({timestamp, minDateTime, maxDateTime, disableFuture=false, onChange}) => {

  const handleChange = (value) => {
    onChange?.(value.toDate().valueOf());
  }

  return (
    <div style={{width: "300px"}}>
      <StaticDateTimePicker 
      slotProps={{
        actionBar: {
          actions: null
      }}}
      disableFuture={disableFuture}
      minDateTime={dayjs(minDateTime)}
      maxDateTime={dayjs(maxDateTime)}
      value={dayjs(timestamp)}
      onChange={handleChange}
      />
    </div>
  );
}

const defaultStartDate = new Date().valueOf() - 86400000;

const DateTimeModal = ({ isOpen = false, start=defaultStartDate, end=null, maxRangeTime=86400000*7, onConfirm, onClose, maxRangeTimeLabel }) => {
  const { t } = useTranslation("evocs");
  const [startDate, setStartDate] = useState(start?.valueOf());
  const [endDate, setEndDate] = useState(end?.valueOf());
  const [minDate, setMinDate] = useState(null);
  const [maxDate, setMaxDate] = useState(null);
  const [endDateError, setEndDateError] = useState("");
  const [calendarDate, setCalendarDate] = useState(startDate);
  const [selectedDateField, setSelectedDateField] = useState(0);
  const [disableSave, setDisableSave] = useState(false);

  const startFieldRef = useRef();
  const endFieldRef = useRef();

  useEffect(() => {
    if(!start || !end) return;
    const newStartDate = start.valueOf();
    const newEndDate = end.valueOf();
    if(selectedDateField === 0) {
      startFieldRef.current?.focus();
      setCalendarDate(newStartDate);
    }else {
      endFieldRef.current?.focus();
      setCalendarDate(newEndDate);
    }
    setStartDate(newStartDate);
    setEndDate(newEndDate);
  }, [start, end])

  useEffect(() => {
    if(selectedDateField === 0) {
      startFieldRef.current?.focus();
      setCalendarDate(startDate);
    }else {
      endFieldRef.current?.focus();
      setCalendarDate(endDate);
    }
  }, [selectedDateField])

  useEffect(() => {
    if(selectedDateField === 0) {
      setStartDate(calendarDate);
    }else {
      setEndDate(calendarDate);
    }
  }, [calendarDate])


  useEffect(() => {
    if(!startDate) return;
    setMinDate(startDate);
    setMaxDate(startDate + maxRangeTime);
  }, [startDate])

  useEffect(() => {
    setDisableSave(endDateError ? true : false);
  }, [endDateError])

  const handleChangeStartDate = (value) => {
    const newValue = value?.toDate()?.valueOf() ?? null;
    setStartDate(newValue);
    setCalendarDate(newValue);
  }

  const handleChangeEndDate = (value) => {
    const newValue = value?.toDate()?.valueOf() ?? null;
    setEndDate(newValue);
    setCalendarDate(newValue);
  }

  const handleClose = () => {
    onClose?.();
  }

  const handleConfirm = () => {
    onConfirm?.(startDate, endDate);
  }


  const handleTranslate = (textId) => {
    return translate(t, textId);
  };

  const getEndDateErrorText = useCallback((error) => {
    switch(error) {
      case "invalidDate": 
        return handleTranslate("INVALID_DATE_ERROR", true);
      case "minDate": 
        return handleTranslate("MIN_DATE_ERROR", true);
      case "minTime": 
        return handleTranslate("MIN_DATE_ERROR", true);
      case "maxDate":
        if(maxRangeTimeLabel)
          return handleTranslate("MAX_DATE_ERROR", true)+ ' ' + maxRangeTimeLabel.toLowerCase();
        return handleTranslate("MAX_DATE_ERROR_GENERIC", true);
      case "maxTime": 
        if(maxRangeTimeLabel)
          return handleTranslate("MAX_DATE_ERROR", true)+ ' ' + maxRangeTimeLabel.toLowerCase();
        return handleTranslate("MAX_DATE_ERROR_GENERIC", true);
      case "disableFuture": 
        return handleTranslate("DISABLE_FUTURE_ERROR", true);
      default:
        return "";
    }
  }, [t]);


  return (
    <Modal open={isOpen}>
      <div style={styles.containerStyle}>
        <div style={{ display: "flex" }}>
          <IconButton sx={{ marginLeft: "auto" }} onClick={handleClose}>
            <CloseIcon fontSize="small" sx={{ color: "white" }} />
          </IconButton>
        </div>
        <div style={{background: "white", borderRadius: "0 1rem 1rem 1rem", height: "100%", overflowY: "auto", padding: "2rem" }}>
          <h2 style={{textAlign: "start", fontWeight: "bold", marginBottom: "2rem" }}>{handleTranslate("CUSTOMIZE_TIME_RANGE")}</h2>
          <div style={{display: "flex", flexDirection: "column", alignItems: "center"}}>
            <div style={{display: "flex", gap: "1rem", marginBottom: "1rem"}}>
              <CustomDateTimeField ref={startFieldRef} selected={selectedDateField===0} disableFuture autoFocus label="Start date" value={dayjs(startDate)} onChange={handleChangeStartDate}  onFocus={() => setSelectedDateField(0)}/>
              <CustomDateTimeField ref={endFieldRef} selected={selectedDateField===1} onError={(error) => setEndDateError(getEndDateErrorText(error))} minDateTime={dayjs(minDate)} maxDateTime={dayjs(maxDate)} disabled={!startDate} disableFuture label="End date" value={dayjs(endDate)} onChange={handleChangeEndDate} onFocus={() => setSelectedDateField(1)}
              slotProps={{textField: {helperText: endDateError}}}/>
            </div>
            <BasicDateTimeRangePicker disableFuture minDateTime={selectedDateField === 1 ? minDate : null} maxDateTime={selectedDateField === 1 ? maxDate : null} timestamp={calendarDate} onChange={setCalendarDate}/>
            <div style={{display: "flex", left: 0, bottom: 0, width: "100%"}}>
              <Button sx={{margin: "0 auto"}} disabled={disableSave} onClick={handleConfirm}>{handleTranslate("CONFIRM")}</Button>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  )
};

const styles = {
  containerStyle: {
    position: "absolute",
    display: "flex",
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    background: "#2d3740",
    padding: "1rem",
    borderRadius: "1rem",
    height: "80vh",
    width: "95%",
    maxWidth: "700px",
    flexDirection: "column"
  }
};

export default DateTimeModal;
