import { useEffect, useState } from "react";
import LoadingGif from "src/components/SharingComponents/LoadingGif";
import {
  getDeprecatedSensors,
  deleteDeprecatedSensors,
  restoreDeprecatedSensors,
} from "app/store/evocsSlice/sensors/sensorsActions";
import TableComponent from "src/components/SharingComponents/TableComponent";
import { useSelector } from "react-redux";
import {
  setGlobalError,
  setGlobalSuccess,
} from "app/store/evocsSlice/globalError/errorActions";

export default function SensorsTab({ handleTranslate }) {
  const [isLoading, setIsLoading] = useState(true);
  const [sensors, setSensors] = useState([]);
  const [data, setData] = useState(sensors);
  const [filteredData, setFilteredData] = useState([]);
  const [searchedText, setSearchedText] = useState("");
  const tenants = useSelector((state) => state.tenants.list);
  const sensorTypes = useSelector(
    (state) => state.configuration.constants.sensor_type
  );
  const measures = useSelector(
    (state) => state.configuration.constants.measure
  );
  useEffect(() => {
    setIsLoading(true);
    getDeprecatedSensors()
      .then((res) => {
        setSensors(res);
      })
      .finally(() => setIsLoading(false));
  }, []);

  const col = {
    serial: handleTranslate("SERIAL", true),
    owner: handleTranslate("OWNER", true),
    sensorType: handleTranslate("SENSOR_TYPE", true),
    measures: handleTranslate("MEASURES", true),
  };

  useEffect(() => {
    setData(
      sensors?.map((sensor) => {
        const validTenant =
          tenants[sensor.owner]?.name ?? handleTranslate("UNKNOWN", true);
        const validSensorType =
          sensorTypes[sensor.sensor_type] ?? handleTranslate("UNKNOWN", true);
        const matchedMeasures = sensor.measures.map(
          (measureId) =>
            measures[measureId]?.measure ?? handleTranslate("UNKNOWN", true)
        );
        return {
          id: sensor.serial,
          serial: sensor.serial,
          owner: validTenant,
          sensorType: validSensorType?.sensor_type,
          measures: matchedMeasures.join("-"),
        };
      })
    );
  }, [sensors]);

  useEffect(() => {
    if (!searchedText) {
      setFilteredData(data);
      return;
    }
    const filtered = data.filter((station) =>
      Object.keys(station).some((key) =>
        station[key]?.toLowerCase().includes(searchedText.trim().toLowerCase())
      )
    );
    setFilteredData(filtered);
  }, [searchedText, data]);

  //? Restore
  const handleRestoreRow = (selection) => {
    const filterBySelection = sensors
      .filter((sensor) => selection.includes(sensor.serial))
      .map((itemToRestore) => itemToRestore["_id"]);
    restoreDeprecatedSensors(filterBySelection).then(() => {
      setSensors(
        sensors?.filter((sensor) => !selection.includes(sensor.serial))
      );
      setGlobalSuccess(true);
    });
  };

  //!DELETE
  const handleDeleteRow = (deletedSelection) => {
    const filterByDeleted = sensors
      .filter((sensor) => deletedSelection.includes(sensor.serial))
      .map((deleted) => deleted["_id"]);

    deleteDeprecatedSensors(filterByDeleted)
      .then(() => {
        setSensors(
          sensors?.filter((sensor) => !deletedSelection.includes(sensor.serial))
        );
        setGlobalSuccess(true);
      })
      .catch((error) => {
        setGlobalError(true);
        console.error("Errore", error);
      });
  };



  if (isLoading) {
    return <LoadingGif />;
  }

  const translatedTitle = handleTranslate("RESTORE", true)

  return (
    <div>
      <div
        style={{
          marginTop: "10px",
          padding: "20px",
          fontSize: "2rem",
          fontWeight: "700",
        }}
      >
        {handleTranslate("SENSORS", true)}: {filteredData.length}
      </div>
      <TableComponent
        onCustomAction={handleRestoreRow}
        customActionTitle={translatedTitle}
        onSearch={setSearchedText}
        columns={col}
        data={filteredData}
        selectable
        onDeleteRows={handleDeleteRow}
      />
    </div>
  );
}
