import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { TIME_FORMAT, formatDateFromTimestamp } from "src/components/StationHandling/utils";

const getVessels = createAsyncThunk(
  "vessels/readVessels",
  async (payload) => {
    const {lonMin, lonMax, latMin, latMax, vesselIds, vesselTypes} = payload;
    const data = await axios
      .get("api/vessel/search/area", {
        params: {
          lonMin: lonMin, 
          lonMax: lonMax,
          latMin: latMin,
          latMax: latMax,
          // vesselIds: vesselIds,
          // vesselTypes: vesselTypes
        }
      })
      .then((res) => {
        return res.data.map((item) => {
          const date = formatDateFromTimestamp(item.time, TIME_FORMAT.DATE_AND_HOUR_SECONDS)
          return { ...item, time: date };
        });
      })
      .catch((error) => {
        throw error;
      });
    return data;
  }
);

const getPortTraffic = createAsyncThunk(
  "vessels/readPortTraffic",
  async (payload) => {
    const {startDate, endDate} = payload;
    const data = await axios
      .post("/api/py/readPortTraffic", {
        start_date: startDate,
        end_date: endDate,
      })
      .then((res) => {
        return res.data;
      })
      .catch((error) => {
        throw error;
      });
    return data;
  }
);

const getVesselPaths = createAsyncThunk(
  "vessels/vesselPaths",
  async (payload) => {
    const {startDate, endDate, minDistance} = payload;
    const data = await axios
      .post("/api/py/vesselpaths", {
        start_date: startDate,
        end_date: endDate,
        min_distance: minDistance // 5, 10, 15
      })
      .then((res) => {
        return res.data;
      })
      .catch((error) => {
        throw error;
      });
    return data;
  }
);

const getNationFlag = createAsyncThunk(
  "vessels/vesselPaths",
  async (nationCode) => {
    const data = await axios
      .post("/api/py/find_nation_flag", {
        nationCode: nationCode
      })
      .then((res) => {
        return res.data;
      })
      .catch((error) => {
        throw error;
      });
    return data;
  }
);

const vesselsSlice = createSlice({
  name: "vessels",
  initialState: {
    ports: {
      list: [],
      loading: true,
    },
    vessels: {
      list: [],
      loading: false
    }
  },
  reducers : {

  },
  extraReducers: (builder) => {
      
  }
});

export const vesselsSliceActions = {
  ...vesselsSlice.actions,
  getVessels,
  getPortTraffic,
  getVesselPaths,
  getNationFlag
}

export default vesselsSlice.reducer;