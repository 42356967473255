import { viewNames } from "app/configs/routesNames";
import { createLanguageObject } from "src/utilities/utils";


const IT = {
    HEADER : "Stazione:",
    NO_LAST_REL : "Non ci sono ultime rilevazioni negli ultimi 15 minuti",
    FILTER: "Filtra per unità",
    NO_DEVICES_SELECTED: "Non ci sono dispositivi selezionati, verrà effettuato il download dei dati di tutte le unità disponibili",
    SUMMARY_DOWNLOAD: "Riepilogo dei dati da scaricare",
};

const SINGLE_STATION_IT = createLanguageObject(IT, viewNames.SINGLE_STATION_VIEW);

export default SINGLE_STATION_IT;