import { initializeApp } from "firebase/app";
import { getMessaging, getToken } from "firebase/messaging";

const evocsFirebaseConfig = {
  apiKey: "AIzaSyBYEG7V-zFKRRsETUnIuVL-q9wn1rngwCo",
  authDomain: "city-monitoring.firebaseapp.com",
  databaseURL: "https://city-monitoring-default-rtdb.europe-west1.firebasedatabase.app",
  projectId: "city-monitoring",
  storageBucket: "city-monitoring.firebasestorage.app",
  messagingSenderId: "589309287563",
  appId: "1:589309287563:web:9e51b32d1976104e1f088e",
  measurementId: "G-GPVJF1CPDQ"
}

const vapidEvocs =  "BHC4KjXI6PsQktiu9IepVzfo0t_PFVr2aLx2jOAVProkSmODcgxjKcozKIqVxQ55xeyiYH9_fhUoX62fB5-Y8D0"


const app = initializeApp(evocsFirebaseConfig);
export const messaging = getMessaging(app);

export const generateToken = async () => {
  const permission = await Notification.requestPermission();
  // console.log(permission);
  if (permission === "granted") {
    const token = await getToken(messaging, {
      vapidKey: vapidEvocs
    });
    return token;
  }
};
