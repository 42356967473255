import { viewNames } from "app/configs/routesNames";
import { createLanguageObject } from "src/utilities/utils";

const IT = {
    HEADER : "Segnalazioni odorigene",
    ODOUR_DISTRIBUTION: "Distribuzione odori",
    ODOUR_REPORTS_DETECTED: "Segnalazioni rilevate",
    INSERT_ODOUR: "Nuova segnalazione odorigena effettuata con successo",
};

const ODOR_REPORTS_IT = createLanguageObject(IT, viewNames.ODOR_REPORTS_VIEW);

export default ODOR_REPORTS_IT;