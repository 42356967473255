import { Autocomplete, Checkbox, styled, TextField } from "@mui/material";
import { useEffect, useLayoutEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { viewNames } from "app/configs/routesNames";
import { useTranslation } from "react-i18next";
import { translate } from "src/utilities/utils";

// const CustomAutocomplete = styled(Autocomplete, {shouldForwardProp: (prop)=> prop !== "erorr" })(({ error }) => error && ({
//   "& .MuiTextField-root": {
//     "label.Mui-focused": { color: "red" },
//   },
//   "& .MuiOutlinedInput-root": {
//     "fieldset": {
//       borderColor: "red"
//     },
//     "&:hover fieldset": {
//       borderColor: "red"
//     },
//     "&.Mui-focused fieldset": { borderColor: "red" },
//   }
// }));

const CuSelect = ({
  style,
  installed = false,
  uninstalled = false,
  value,
  defaultValue,
  onChange,
  required = false,
  multiple = false,
  limitTags,
  disabled = false,
  isUserTenant = true,
  error = false,
  disablePortal = false,
  cusId,

}) => {
  const controlUnits = useSelector((state) => state.controlUnits);
  const options = useMemo(() => {
    if (!isUserTenant) {
      return cusId?.map((cu) => cu.id);
    } else if (cusId) {
      return cusId;
    } else if (installed) {
      return controlUnits.installed;
    } else if (uninstalled) {
      return controlUnits.uninstalled;
    } else {
      return Object.keys(controlUnits.list);
    }
  }, [isUserTenant, cusId, installed, uninstalled]);
  const [val, setValue] = useState(
    defaultValue ? defaultValue : multiple ? [] : null
  );

  useLayoutEffect(() => {
    value !== undefined && setValue(value);
  }, [value]);

  const handleChange = (newValues) => {
    setValue(newValues);
    onChange && onChange(newValues);
  };

  const getCuLabel = (option) => {
    if (isUserTenant) return controlUnits.list[option]?.serial || "";
    else return cusId.find((cu) => cu.id === option)?.serial || "";
  };

  const { t } = useTranslation("evocs");
  const handleTranslate = (textId, general = false) => {
    if (general) return translate(t, textId);
    return translate(t, textId, viewNames.DASHBOARD_VIEW);
  };

  return (
    <Autocomplete
      disablePortal={disablePortal}
      id="cu-autocomplete"
      // error={error}
      disabled={disabled}
      sx={{ width: "100%", minWidth: "100px", maxWidth: "300px", ...style }}
      limitTags={limitTags}
      multiple={multiple}
      disableCloseOnSelect={multiple}
      onChange={(_, selected) => handleChange(selected)}
      options={options}
      value={val}
      getOptionLabel={(option) => getCuLabel(option)}
      renderInput={(params) => <TextField {...params} error={error} label={required ? `${handleTranslate("CONTROL_UNITS", true)}*` : `${handleTranslate("CONTROL_UNITS", true)}`} />}
      renderOption={(props, option, { selected }) => (
        <li {...props}>
          {multiple && <Checkbox checked={selected} />}
          {getCuLabel(option)}
        </li>
      )}
    />
  );
};

export default CuSelect;
