import {routesNames, groupIds, viewNames} from "./routesNames";
import i18next from 'i18next';
import en from "src/views/i18n/en.ts";
import it from "src/views/i18n/it.ts";


i18next.addResourceBundle('it', 'evocs', it);
i18next.addResourceBundle('en', 'evocs', en);


const stationNavGroup = [
  {
    id: groupIds.DATA_CENTER,
    title: 'STATION_GROUP',
    type: "group",
  },
  {
    groupId: groupIds.DATA_CENTER,
    title: viewNames.DASHBOARD_VIEW,
    url: routesNames.DASHBOARD_VIEW,
    icon: "heroicons-outline:chart-pie",
  },
  {
    groupId: groupIds.DATA_CENTER,
    title: viewNames.STATIONS_VIEW,
    url: routesNames.STATIONS_VIEW,
    icon: "heroicons-outline:presentation-chart-line",
    // icon: "heroicons-outline:presentation-chart-bar",
  },
];

const multimediaNavGroup = [
  {
    id: groupIds.MULTIMEDIA,
    title: 'MULTIMEDIA_GROUP',
    type: "group",
  },
  {
    groupId: groupIds.MULTIMEDIA,
    title: viewNames.MULTIMEDIA_VIEW,
    url: routesNames.MULTIMEDIA_VIEW,
    icon: "feather:video",
    // icon: "heroicons-outline:video-camera",
    // icon: "heroicons-outline:presentation-chart-line",
  }
]

const odourNavGroup = [
  {
    id: groupIds.PORT_ODOR,
    title: 'ODOUR_PORTAL_GROUP',
    type: "group",
  },
  {
    groupId: groupIds.PORT_ODOR,
    title:  viewNames.ODOR_REPORTS_VIEW,
    url: routesNames.ODOR_REPORTS_VIEW,
    icon: "HiOutlineLocationMarker",
  },
];

const openDataNavGroup = [
  {
    id: groupIds.OPEN_DATA,
    title: 'OPEN_DATA_PORTAL_GROUP',
    type: "group",
  },
  {
    groupId: groupIds.OPEN_DATA,
    title: viewNames.OPEN_DATA_AIR_QUALITY_VIEW,
    url: routesNames.OPEN_DATA_AIR_QUALITY_VIEW,
    icon: "feather:wind",
  },
  {
    groupId: groupIds.OPEN_DATA,
    title: viewNames.OPEN_DATA_METEOROLOGY_VIEW,
    url: routesNames.OPEN_DATA_METEOROLOGY_VIEW,
    icon: "TiWeatherPartlySunny"
  },
];


const vesselsTrafficNavGroup = [
  {
    id: groupIds.VESSELS,
    title: 'VESSELS_GROUP',
    type: "group",
  },
  {
    groupId: groupIds.VESSELS,
    title: viewNames.VESSELS_VIEW,
    url: routesNames.VESSELS_VIEW,
    icon: "material-outline:directions_boat",
  },
];

const docReportNavGroup = [
  {
    id: groupIds.REPORTS_AND_NOTIF,
    title: 'ALERTS_AND_REPORTS_GROUP',
    type: "group",
  },
  {
    groupId: groupIds.REPORTS_AND_NOTIF,
    title: viewNames.ALERTS_VIEW,
    url: routesNames.ALERTS_VIEW,
    icon: "material-solid:notifications_none",
  },
  // {
  //   title: viewNames.MONTHLY_REPORTS_VIEW_HEADER,
  //   url: routesNames.MONTHLY_REPORTS_VIEW,
  //   icon: "heroicons-outline:document-download",
  //   // icon: "heroicons-outline:document-report",
  // },
];

const configurationNavGroup = [
  {
    id: groupIds.CONFIG,
    title: 'CONFIGURATION_GROUP',
    type: "group",
  },
  {
    groupId: groupIds.CONFIG,
    title: viewNames.CONFIGURATION_VIEW,
    url: routesNames.CONFIGURATION_VIEW,
    icon:"heroicons-outline:cog",
  },
  {
    groupId: groupIds.CONFIG,
    title: viewNames.ADMINISTRATION_VIEW,
    url: routesNames.ADMINISTRATION_VIEW,
    icon: "MdOutlineAdminPanelSettings"
  },  
  {
    groupId: groupIds.CONFIG,
    title: viewNames.INTERNAL_NETWORK_MAN_VIEW,
    url: routesNames.INTERNAL_NETWORK_MAN_VIEW,
    icon: "material-solid:cable"
  },  
  {
    groupId: groupIds.CONFIG,
    title: viewNames.TRASH_VIEW,
    url: routesNames.TRASH_VIEW,
    icon: "feather:trash-2"
  },
  ];

const testNavGroup = [
  {
    id: groupIds.TEST,
    title: 'TEST',
    type: "group",
  },
  {
    groupId: groupIds.TEST,
    title: viewNames.TEST_PAGE_VIEW,
    url: routesNames.TEST_PAGE_VIEW,
    icon:"heroicons-outline:beaker",
  },
];



const withIdStationNavGroup = stationNavGroup.map((data, index) => {
  if (index !== 0) {
    return {
      ...data,
      type: "item",
      id: `${groupIds.DATA_CENTER}-${index + 1}`,
    };
  } else {
    return data;
  }
});

const withIdMultimediaNavGroup = multimediaNavGroup.map((data, index) => {
  if (index !== 0) {
    return {
      ...data,
      type: "item",
      id: `${groupIds.MULTIMEDIA}-${index + 1}`,
    };
  } else {
    return data;
  }
});

const withIdOdourNavGroup = odourNavGroup.map((data, index) => {
  if (index !== 0) {
    return {
      ...data,
      type: "item",
      id: `${groupIds.PORT_ODOR}-${index + 1}`,
    };
  } else {
    return data;
  }
});

const withIdOpenDataNavGroup = openDataNavGroup.map((data, index) => {
  if (index !== 0) {
    return {
      ...data,
      type: "item",
      id: `${groupIds.OPEN_DATA}-${index + 1}`,
    };
  } else {
    return data;
  }
});

const withIdVesselsTrafficNavGroup = vesselsTrafficNavGroup.map((data, index) => {
  if (index !== 0) {
    return {
      ...data,
      type: "item",
      id: `${groupIds.VESSELS}-${index + 1}`,
    };
  } else {
    return data;
  }
});

const withIdDocReportNavGroup = docReportNavGroup.map((data, index) => {
  if (index !== 0) {
    return {
      ...data,
      type: data.type ? data.type : "item",
      id: `${groupIds.REPORTS_AND_NOTIF}-${index + 1}`,
    };
  } else {
    return data;
  }
});

const withIdConfigurationNavGroup = configurationNavGroup.map((data, index) => {
  if (index !== 0) {
    return {
      ...data,
      type: "item",
      id: `${groupIds.CONFIG}-${index + 1}`,
    };
  } else {
    return data;
  }
});

const withIdTestNavGroup = testNavGroup.map((data, index) => {
  if (index !== 0) {
    return {
      ...data,
      type: data.type ? data.type : "item",
      id: `${groupIds.TEST}-${index + 1}`,
    };
  } else {
    return data;
  }
});

const withIdNavs = [
  ...withIdStationNavGroup,
  ...withIdMultimediaNavGroup,
  ...withIdOdourNavGroup,
  ...withIdOpenDataNavGroup,
  ...withIdVesselsTrafficNavGroup,
  ...withIdDocReportNavGroup,
  ...withIdConfigurationNavGroup,
  ...withIdTestNavGroup,
];

export default withIdNavs;