import useMobile from "src/app/hooks/useMobile";
import { Button } from "@mui/material";
import { IoCloseOutline } from "react-icons/io5";
import { MdDelete } from "react-icons/md";
import { FaCheck } from "react-icons/fa";
export default function FloatingButtons({
  disableDelete,
  disableSave,
  onDiscard,
  onDelete,
  onSave,
  buttonStyle = {},
  containerStyle = {},
}) {
  const [xs] = useMobile();

  return (
    <div
      style={{
        position: "absolute",
        top: "50%",
        right: !xs ? 10 : "50%",
        height: !xs ? "80%" : "90%",
        transform: xs && "translateX(50%)",
        zIndex: !xs ? 0 : 2,
        ...containerStyle,
      }}
    >
      <div
        style={{
          display: "flex",
          flexDirection: !xs && "column",
          alignItems: !xs && "flex-end",
          justifyContent: !xs && "flex-end",
          gap: !xs ? "20px" : "10px",
          marginTop: "2rem",
          position: "sticky",
          top: !xs ? "50%" : "92%",
          transform: "translateY(-50%)",
          zIndex: 999,
        }}
      >
        <Button
          sx={{
            borderRadius: "50%",
            backgroundColor: "#e9ecef !important",
            boxShadow: "2px 2px 10px rgba(0,0,0,0.2)",
            padding: "10px",
            minWidth: "0px",
            maxHeight: "none",
            width: !xs ? "60px" : "50px",
            height: !xs ? "60px" : "50px",
            ...buttonStyle,
          }}
          onClick={onDiscard}
        >
          {" "}
          <IoCloseOutline style={{ fontSize: "4rem", color: "black" }} />
        </Button>

        {!disableDelete && (
          <Button
            sx={{
              color: "white",
              borderRadius: "50%",
              backgroundColor: "#c9184a !important",
              boxShadow: "2px 2px 10px rgba(0,0,0,0.2)",
              padding: "10px",
              minWidth: "0px",
              maxHeight: "none",
              width: !xs ? "60px" : "50px",
              height: !xs ? "60px" : "50px",
              ...buttonStyle,
            }}
            onClick={onDelete}
          >
            <MdDelete style={{ fontSize: "4rem" }} />
          </Button>
        )}
        <Button
          sx={{
            color: "white",
            borderRadius: "50%",
            boxShadow: "2px 2px 10px rgba(0,0,0,0.2)",
            backgroundColor: !disableSave
              ? "#91c76f !important"
              : "#e9ecef !important",
            padding: "10px",
            minWidth: "0px",
            maxHeight: "none",
            width: !xs ? "60px" : "50px",
            height: !xs ? "60px" : "50px",
            ...buttonStyle,
          }}
          color="success"
          disabled={disableSave}
          onClick={onSave}
        >
          {" "}
          <FaCheck style={{ fontSize: "4rem", color: "white" }} />
        </Button>
      </div>
    </div>
  );
}
