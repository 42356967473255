import { Autocomplete, Checkbox, TextField } from "@mui/material";
import { useLayoutEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { viewNames } from "app/configs/routesNames";
import { useTranslation } from "react-i18next";
import { translate } from "src/utilities/utils";
import { useEffect } from "react";

const SensorAutocomplete = ({
  style,
  sensorsId,
  installed = false,
  uninstalled = false,
  defaultValue,
  value,
  onChange,
  required = false,
  multiple = false,
  limitTags = undefined,
  disabled = false,
  isUserTenant = true,
  error = false,
  disablePortal = false,

}) => {
  const sensors = useSelector((state) => state.sensors);
  const controlUnits = useSelector((state) => state.controlUnits.list);

  const options = useMemo(() => {
    if (!isUserTenant) {
      return sensorsId?.map((cu) => cu.id);
    } else if (sensorsId) {
      return sensorsId;
    } else if (installed) {
      return sensors.installed;
    } else if (uninstalled) {
      return sensors.uninstalled;
    } else {
      return Object.keys(sensors.list);
    }
  }, [isUserTenant, sensorsId, installed, uninstalled]);
  const [val, setValue] = useState(
    defaultValue !== undefined ? defaultValue : multiple ? [] : null
  );

  useLayoutEffect(() => {
    value !== undefined && setValue(value);
  }, [value]);

  const handleChange = (newValues) => {
    setValue(newValues);
    onChange && onChange(newValues);
  };

  const getSensorLabel = (option) => {
    if (isUserTenant) return sensors.list[option]?.serial || "";
    else return sensorsId.find((sensor) => sensor.id === option)?.serial || "";
  };

  const { t } = useTranslation("evocs");
  const handleTranslate = (textId, general = false) => {
    if (general) return translate(t, textId);
    return translate(t, textId, viewNames.DASHBOARD_VIEW);
  };

  return (
    <Autocomplete
      disablePortal={disablePortal}
      id="sensor-autocomlete"
      disabled={disabled}
      sx={{ width: "100%", minWidth: "100px", maxWidth: "300px",...style }}
      multiple={multiple}
      disableCloseOnSelect={multiple}
      limitTags={limitTags}
      onChange={(_, selected) => handleChange(selected)}
      options={options}
      value={val}
      getOptionLabel={(option) => getSensorLabel(option)}
      renderInput={(params) => (
        <TextField
          error={error}
          {...params}
          label={
            required
              ? `${handleTranslate("SENSORS", true)}*`
              : `${handleTranslate("SENSORS", true)}`
          }
        />
      )}
      renderOption={(props, option, { selected }) => (
        <li {...props}>
          {multiple && <Checkbox checked={selected} />}
          {getSensorLabel(option)}
        </li>
      )}
    />
  );
};

export default SensorAutocomplete;
