import React, { useEffect, useMemo } from 'react'
import { viewNames } from 'app/configs/routesNames'
import { useSelector } from 'react-redux';
import { setFilter, setOpenDataSourceSelect, setTimeIntervalValue, setTimeRangeFilter } from 'app/store/evocsSlice/filters/filtersActions';
import OpenDataSourceSelect from '../SharingComponents/Inputs/OpenDataSourceSelect';
import useFilters from 'src/app/hooks/useFilters';
import RangeTimeFilter_v2 from '../SharingComponents/RangeTimeFilter';
import { calcIntervalDates } from '../StationHandling/utils';

const viewName = viewNames.OPEN_DATA_AIR_QUALITY_VIEW;

const OpenDataAirContentHeader = () => {
  const user = useSelector((state) => state.user);
  const timeRange = useSelector((state) => state.configuration.constants?.timeRange);
  const filters = useFilters(viewName);
  const timeRangeInView = filters.timeRange;
  const sourceSelect = filters.open_source;

  const {enabledIntervals, defaultInterval} = useMemo(() => { 
    const intervals = [];
    let defaultId = null;
     timeRangeInView.list.forEach((element) => {
      if(element.active===1) intervals.push(element._id);
      if(element.default===1) defaultId = element._id;
     });
    defaultId = timeRangeInView.selected ?? defaultId ?? intervals[0];
    return {enabledIntervals: intervals, defaultInterval: defaultId}
  }, [user.selectedTenant, timeRangeInView.list])

  useEffect(() => {
    const range = calcIntervalDates(timeRange[defaultInterval]?.timeRange);
    setTimeRangeFilter(viewName, timeRangeInView.id, defaultInterval, range.start, range.end);
    return () => {
      setTimeRangeFilter(viewName, timeRangeInView.id, undefined, null, null);
    }
  }, []);


  const handleChangeInterval = (intervalId, startDate, endDate) => {
    setTimeRangeFilter(viewName, timeRangeInView.id, intervalId, startDate, endDate);
  }

  const handleChangeSource = (value) => {
    setFilter(viewName, sourceSelect.id, value);
  }

  return (
    <div style={{display: "flex", gap: "1rem", marginBottom: "1rem"}}>
    <RangeTimeFilter_v2 withSlider={false} selectedInterval={timeRangeInView.selected} intervals={enabledIntervals} onChangeInterval={handleChangeInterval}/>
    <OpenDataSourceSelect defaultValue={sourceSelect.selected} onChange={handleChangeSource} meteo={false}/>
    </div>
  )
}

export default OpenDataAirContentHeader;