import { useLayoutEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { Autocomplete, Checkbox, TextField } from "@mui/material";
import { viewNames } from "app/configs/routesNames";
import { useTranslation } from "react-i18next";
import { translate } from "src/utilities/utils";

const MeasureAutocomplete = ({
  style,
  measuresId,
  value,
  defaultValue,
  onChange,
  multiple = false,
  required = false,
  disabled = false,
  error = false,
  limitTags = undefined,
  disablePortal = false,
  enableAllChecked = false,
}) => {
  const measures = useSelector(
    (state) => state.configuration.constants?.measure
  );

  const allOptions = useMemo(() => Object.keys(measures), [measures]);

  const options = useMemo(() => {
    if (measuresId) {
      return measuresId;
    } else if (enableAllChecked) {
      return ["all", ...allOptions];
    } else {
      return Object.keys(measures);
    }
  }, [measures, measuresId]);

  const [val, setValue] = useState(
    defaultValue !== undefined ? defaultValue : multiple ? [] : null
  );

  useLayoutEffect(() => {
    value !== undefined && setValue(value);
  }, [value]);

  const handleChange = (newValue) => {
    setValue(newValue);
    onChange && onChange(newValue);    
  };

  const { t } = useTranslation("evocs");
  const handleTranslate = (textId, general = false) => {
    if (general) return translate(t, textId);
    return translate(t, textId, viewNames.DASHBOARD_VIEW);
  };

  return (
    <Autocomplete
      disablePortal={disablePortal}
      id="measure-autocomplete"
      sx={{ ...style, width: "100%" }}
      disabled={disabled}
      multiple={multiple}
      disableCloseOnSelect={multiple}
      limitTags={limitTags}
      onChange={(_, selected) => handleChange(selected)}
      options={options}
      value={val}
      getOptionLabel={(option) =>
        handleTranslate(
          `MEASURE_${measures[option]?.measure.toUpperCase()}`,
          true
        )
      }
      renderInput={(params) => (
        <TextField
          error={error}
          {...params}
          label={
            required
              ? `${handleTranslate("PARAM", true)}*`
              : ` ${handleTranslate("ALL_PARAMS", true)}`
          }
        />
      )}
      renderOption={(props, option, { selected }) =>{
        return (
          <li  {...props}>
            {multiple && <Checkbox checked={selected} />}
            {option === "all"
              ? handleTranslate("ALL_PARAMS", true)
              : handleTranslate(
                  `MEASURE_${measures[option]?.measure.toUpperCase()}`,
                  true
                )}
          </li>
        )
      } }
    />
  );
};

export default MeasureAutocomplete;
