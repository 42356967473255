import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import AlertCard from "./AlertCard";
import {
  readAlerts,
  deleteAlertsByUser,
} from "app/store/evocsSlice/user/userAction";
import Button from "@mui/material/Button";
import LoadingGif from "../SharingComponents/LoadingGif";
import { Grid, Typography, IconButton } from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import { Box } from "@mui/system";
import NoNotificationsMessage from "../SharingComponents/NoNotificationsMessage";
import { FaMapLocationDot } from "react-icons/fa6";
import MapComponent from "src/components/SharingComponents/MapComponent.jsx";
import { findAlertsPositions } from "app/store/evocsSlice/maps/mapsActions";
import ToggleButton from "../SharingComponents/Inputs/ToggleButton";
import CategoryAllertSelect from "../SharingComponents/Inputs/CategoryAllertSelect";
import mkrIcon from "src/app/asset/markers/Orange.png";
import { MdWarningAmber } from "react-icons/md";
import { PiGridFourFill } from "react-icons/pi";
import { viewNames } from "app/configs/routesNames";
import { useTranslation } from "react-i18next";
import { translate } from "src/utilities/utils";

const ScroolMoreComponent = () => {
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        marginBottom: "5rem",
        marginTop: "1rem",
      }}
    >
      <h3
        style={{
          color: "#29384e",
          marginBottom: "2rem",
          textAlign: "center",
        }}
      >
        Scroll to read more...
      </h3>
    </div>
  );
};

const AlertsCardGrid = ({
  filteredAlerts,
  visibleAlertsCount,
  handleReadNotification,
  selectableCard,
  handleCheckedCard,
}) => {
  return (
    <>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "start",
          justifyContent: "start",
          gap: "1rem",
          flexWrap: "wrap",
        }}
      >
        <Grid container spacing={1}>
          {filteredAlerts.slice(0, visibleAlertsCount).map((alert, index) => (
            <Grid item key={alert.alertId} xs={12} sm={6} md={4} lg={3}>
              <AlertCard
                {...alert}
                handleReadNotification={handleReadNotification}
                selectable={selectableCard}
                handleCheckedCard={handleCheckedCard}
              ></AlertCard>
            </Grid>
          ))}
        </Grid>
      </div>
      {filteredAlerts.length > visibleAlertsCount && <ScroolMoreComponent />}
    </>
  );
};

const ControlEditButton = ({
  checkedCard,
  handleMarkCheckedAsRead,
  handleSelectableCard,
}) => {
  const { t } = useTranslation("evocs");
  const handleTranslate = (textId, general = false) => {
    if (general) return translate(t, textId);
    return translate(t, textId, viewNames.ALERTS_VIEW);
  };

  return (
    <div>
      <Button
        sx={{ color: "red" }}
        disabled={checkedCard.length === 0}
        onClick={handleMarkCheckedAsRead}
      >
        {handleTranslate("MARK_READ")}
      </Button>
      <Button onClick={handleSelectableCard}>
        {" "}
        {handleTranslate("CANCEL")}{" "}
      </Button>
    </div>
  );
};

const EditButton = ({ handleSelectableCard }) => {
  return (
    <IconButton onClick={handleSelectableCard}>
      <EditIcon />
    </IconButton>
  );
};

const MarkAllRead = ({ handleMarkAllRead }) => {
  const { t } = useTranslation("evocs");
  const handleTranslate = (textId, general = false) => {
    if (general) return translate(t, textId);
    return translate(t, textId, viewNames.ALERTS_VIEW);
  };

  return (
    <Box sx={{ marginLeft: { sm: 0, md: "auto" } }}>
      <Button
        variant="contained"
        onClick={handleMarkAllRead}
        style={{ backgroundColor: "#242a30", minWidth: "100px" }}
      >
        <Typography
          sx={{ color: "white", textAlign: "center" }}
          fontSize={{ xs: "12px", sm: "12px", md: "14px" }}
        >
          {handleTranslate("MARK_ALL_READ")}
        </Typography>
      </Button>
    </Box>
  );
};

const AlertsContent = () => {
  const translation = useTranslation("evocs");
  const maxNotifications = 12;
  const userId = useSelector((state) => state?.user?.id);
  const userAlerts = useSelector((state) => state?.user?.alerts);
  const [formattedAlerts, setFormattedAlerts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [visibleAlertsCount, setVisibleAlertsCount] =
    useState(maxNotifications); // Numero di notifiche da visualizzare inizialmente
  const [selectedCategory, setSelectedCategory] = useState("");
  const [filteredAlerts, setFilteredAlerts] = useState([]);
  const [selectableCard, setSelectableCard] = useState(false);
  const [checkedCard, setCheckedCard] = useState([]);
  const [isMapEnable, setIsMapEnable] = useState(true);
  const [markers, setMarkers] = useState();
  const [filteredMarkers, setFilteredMarkers] = useState([]);
  const [popupsContent, setPopupsContent] = useState();

  const handleSelectableCard = () => {
    setSelectableCard(!selectableCard);
  };

  useEffect(() => {
    if (!userAlerts) return;
    if (userAlerts.length === 0) {
      setLoading(false);
      setFormattedAlerts([]);
      return;
    }
    let canSet = true;
    setLoading(true);


    readAlerts(
      userAlerts.map((alert) => alert._id),
      translation[1].language,
    ).then((alerts) => {
      if (!canSet) return;
      setFormattedAlerts(alerts.sort((a, b) => b.datetime - a.datetime));
      setLoading(false);
    });

    return () => (canSet = false);
  }, [userAlerts, translation.t]);

  useEffect(() => {
    const handleScroll = () => {
      if (Math.ceil(window.innerHeight + window.scrollY) >= document.body.offsetHeight) {
        handleReadMore();
      }
    };

    if (filteredAlerts.length > visibleAlertsCount) {
      window.addEventListener("scroll", handleScroll);
      return () => {
        window.removeEventListener("scroll", handleScroll);
      };
    }
  }, [filteredAlerts, visibleAlertsCount]);

  const iconType = <MdWarningAmber />;

  useEffect(() => {
    findAlertsPositions(userId).then((markers) => {
      const tempMarkers = Object.keys(markers).map((stationId) => {
        const categoryIds = markers[stationId]
          ? [
              ...new Set(
                markers[stationId].alerts.map((alert) => alert.categoryId)
              ),
            ]
          : [];
        // console.log(stationId, markers[stationId]?.lat, markers[stationId]?.lon);
        // console.log(categoryIds);
        const marker = {
          lat: markers[stationId]?.lat,
          lon: markers[stationId]?.lon,
          stationId: stationId,
          categoryIds,
          icon: mkrIcon,
          iconType: iconType,
        };
        return marker;
      });

      const tempPopupContent = Object.values(markers).map((stationAlertsDict) => stationAlertsDict["alerts"]);
      setMarkers(tempMarkers);
      setPopupsContent(tempPopupContent);
    });
  }, []);

  useEffect(() => {
    if (!selectedCategory) {
      setFilteredAlerts(formattedAlerts);
      setFilteredMarkers(markers);
    } else {
      setFilteredAlerts(formattedAlerts.filter((alert) => alert.categoryId === selectedCategory));
      setFilteredMarkers(markers.filter((e) => e.categoryIds.includes(selectedCategory)));
    }
  }, [selectedCategory, formattedAlerts, markers]);

  const handleReadNotification = (alertId) => {
    deleteAlertsByUser(userId, [alertId])
      .then(() => {
        setFormattedAlerts(
          formattedAlerts.filter((alert) => alert.alertId !== alertId)
        );
      })
      .catch((error) => {
        throw error;
      });
  };

  const handleReadMore = () => {
    setVisibleAlertsCount((prevCount) => prevCount + maxNotifications);
  };

  const handleChangeCategory = (categoryId) => {
    setSelectedCategory(categoryId);
    setVisibleAlertsCount(maxNotifications);
  };

  const handleMarkAllRead = () => {
    const allAlertIds = filteredAlerts.map((alert) => alert.alertId);
    deleteAlertsByUser(userId, allAlertIds)
      .then(() => {
        setFormattedAlerts([]);
      })
      .catch((error) => {
        throw error;
      });
  };

  const handleCheckedCard = (alertId) => {
    if (checkedCard.includes(alertId))
      setCheckedCard((prev) => prev.filter((card) => card !== alertId));
    else setCheckedCard((prev) => [...prev, alertId]);
  };

  const handleMarkCheckedAsRead = () => {
    deleteAlertsByUser(userId, checkedCard)
      .then(() => {
        setCheckedCard([]);
      })
      .catch((error) => {
        throw error;
      });
  };

  const handleSwitchMap = (value) => {
    setIsMapEnable(value);
  };

  return (
    <Box sx={{ height: "100%",}}padding={{ xs: "1rem", sm: "2rem 5rem" }}>
      <Box
        sx={{
          marginBottom: "2rem",
          display: "flex",
          justifyContent: "start",
          alignItems: "center",
          gap: "1rem",
          flexWrap: "wrap",
          width: "100%",
        }}
      >
        {/* <CategorySelector onSelectCategory={handleCategoryChange} /> */}
        <CategoryAllertSelect
          nullValue
          value={selectedCategory}
          onChange={handleChangeCategory}
        />
        <ToggleButton
          enabled={isMapEnable}
          onChange={() => handleSwitchMap(true)}
        >
          <FaMapLocationDot />
        </ToggleButton>
        <ToggleButton
          enabled={!isMapEnable}
          onChange={() => handleSwitchMap(false)}
        >
          <PiGridFourFill />
        </ToggleButton>
        {isMapEnable ? (
          <></>
        ) : selectableCard ? (
          <ControlEditButton
            checkedCard={checkedCard}
            handleMarkCheckedAsRead={handleMarkCheckedAsRead}
            handleSelectableCard={handleSelectableCard}
          />
        ) : (
          <EditButton handleSelectableCard={handleSelectableCard} />
        )}

        {!isMapEnable && <MarkAllRead handleMarkAllRead={handleMarkAllRead} />}
      </Box>
      <Box
        sx={{
          // border: "1px solid red",
          height: "fit-content",
          minHeight: "60vh",
          // height: "93%"
        }}
      >
        {loading ? (
          <LoadingGif />
        ) : filteredAlerts.length === 0 ? (
          <NoNotificationsMessage />
        ) : (
          <>
            {isMapEnable ? (
              filteredMarkers ? (
                <Box sx={{ height: "75vh" }}>
                  <MapComponent
                    style={{ zIndex: 0 }}
                    // center={mapCenter}
                    height={"100%"}
                    markers={filteredMarkers}
                    popupsTableContent={popupsContent ?? unedefined}
                  ></MapComponent>
                </Box>
              ) : (
                <LoadingGif />
              )
            ) : (
              <AlertsCardGrid
                filteredAlerts={filteredAlerts}
                visibleAlertsCount={visibleAlertsCount}
                handleReadNotification={handleReadNotification}
                selectableCard={selectableCard}
                handleCheckedCard={handleCheckedCard}
              />
            )}
          </>
        )}
      </Box>
    </Box>
  );
};

export default AlertsContent;
