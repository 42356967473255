import { useEffect, useState } from 'react'
import { getMongoFile } from 'app/store/evocsSlice/multimedia/multimediaActions';
import { Box, CircularProgress, styled } from '@mui/material';

const ImageViewer = styled(Box)(() => ({
  position: "relative",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  background: "black",
  width: "100%",
  height: "100%",
  "&:hover div": {
    opacity: 1
  }
}));

const ImageOptions = styled(Box)(() => ({
  position: "absolute",
  padding: "1rem",
  bottom: 0,
  left: 0,
  right: 0,
  background: "linear-gradient(0deg, rgba(0,0,0,.7) 40%, rgba(0,0,0,0) 100%)",
  opacity: 0,
  color: "white"
}));

const ImageGallery = ({selectedImage, height="100%"}) => {
  const [loadingImage, setLoadingImage] = useState(false);
  const [imageUrl, setImageUrl] = useState(null);

  useEffect(() => {
    if(!selectedImage) return;
    let canSet = true;
    setLoadingImage(true);
    getMongoFile(selectedImage.id, null, "image").then((res) => {
      if(!canSet) return;
      setImageUrl("data:image/png;base64,"+res);
      setLoadingImage(false);
    });

    return () => canSet = false;
  }, [selectedImage])
  
  return (
    <div style={{width: "100%", height: height}}>
      <ImageViewer>
        {!loadingImage ?
        <>
          <img style={{ pointerEvents: "none", objectFit: "scale-down", maxHeight: "82vh",}} src={imageUrl}/>
          <ImageOptions>
            <h2>{selectedImage?.filename}</h2>
            <label>{selectedImage?.resolution}</label>
          </ImageOptions>
        </>
      :
        <CircularProgress sx={{color: "#92c870"}}/>
      }
      </ImageViewer>
        {/* <img src={"https://images.pexels.com/photos/10434596/pexels-photo-10434596.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1"}/> */}
    </div>
  )
}

export default ImageGallery