import { useCallback, useEffect, useRef, useState } from "react";
import { viewNames } from "app/configs/routesNames";
import useFilters from "./useFilters";
import { useSelector } from "react-redux";
import { isSensorMultimedia, readAliveSensors } from "app/store/evocsSlice/sensors/sensorsActions";
import { calcIntervalDates, formatDate, TIME_FORMAT } from "src/components/StationHandling/utils";
import { getMaxMinData } from "app/store/evocsSlice/dataset/datasetActions";
import { getLastStationDetections, getStationSensors, getStationsThreshold } from "app/store/evocsSlice/stationsMongo/stationsMongoActions";
import { setServerError } from "app/store/evocsSlice/globalError/errorActions";

const viewName = viewNames.DASHBOARD_VIEW;

const useDashboardData = (selectedStation) => {
  const selectedStationRef = useRef(selectedStation);
  const user = useSelector((state) => state.user);
  const sensors = useSelector((state) => state.sensors.list);
  const availableStations = useSelector((state) => state.stationsMongo.available);
  const timeRangeConstants = useSelector((state) => state.configuration.constants.timeRange);
  const filtersInView = useFilters(viewName);
  const timeRangeInView = filtersInView?.timeRange;
  const [loadingAliveSensors, setLoadingAliveSensors] = useState(false);
  const [aliveSensors, setAliveSensors] = useState();
  const [loadingMinMax, setLoadingMinMax] = useState(false);
  const [maxMinMeasures, setMaxMinMeasures] = useState([]);
  const [loadingThresholds, setLoadingThresholds] = useState(false);
  const [thresholds, setThresholds] = useState([]);
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();

  const fetchAliveSensors = useCallback(async() => {
    return await readAliveSensors(user.tenantId, startDate, endDate, availableStations)
    .then((res) => {
      if(!res) return;
      Object.keys(res).forEach((stationId) => {
        const sensorIds = getStationSensors(stationId).filter((sensorId) => isSensorMultimedia(sensorId));
        const cuStatus = res?.[stationId]?.status ?? 0;
        sensorIds.forEach((sensorId) => {
          res[stationId][sensorId] = cuStatus;
        });
      });
      return res;
    });
  }, [user.tenantId, startDate, endDate, availableStations]);

  const fetchMaxMinMeasures = useCallback(async() => {
    let maxMin = [];
    let fetch = [ 
      getMaxMinData(startDate, endDate, [selectedStationRef.current]).then((data) => {
        Object.keys(data).forEach((sensorId) => {
          Object.keys(data[sensorId]).forEach((measureId) => {
            const index = maxMin.findIndex((element) => element.measureId === measureId && element.sensorParent === sensorId);
            if(index < 0) {
              const cuParent = sensors[sensorId].deviceParent.type === "cu" ? sensors[sensorId].deviceParent.device : null;
              maxMin.push({
                measureId: measureId,
                sensorParent: sensorId,
                cuParent: cuParent,
                ...data[sensorId][measureId],
              });
            }else {
              const oldElement = maxMin[index];
              maxMin = maxMin.toSpliced(index, 1, {
                ...oldElement,
                ...data[sensorId][measureId],
              });
            }
          });
        });
      }),
      getLastStationDetections([selectedStationRef.current], user.tenantId).then((data) => {
        const station = data[selectedStationRef.current];
        if(!station) return null;
        Object.keys(station).forEach((sensorId) => {
          const time = formatDate(new Date(station[sensorId].time * 1000), TIME_FORMAT.COMPLETE_DATE);
          delete station[sensorId].time;
          Object.keys(station[sensorId]).forEach((measureId) => {
            const index = maxMin.findIndex((element) => element.measureId === measureId && element.sensorParent === sensorId);
            if(index < 0) {
              const cuParent = sensors[sensorId].deviceParent.type === "cu" ? sensors[sensorId].deviceParent.device : null;
              maxMin.push({
                measureId: measureId,
                sensorParent: sensorId,
                cuParent: cuParent,
                last: `${station[sensorId][measureId]} (${time})`,
              });
            }else {
              const oldElement = maxMin[index];
              maxMin = maxMin.toSpliced(index, 1, {
                ...oldElement,
                last: `${station[sensorId][measureId]} (${time})`,
              });
            }
          });
        });
      })
    ];
    return await Promise.all(fetch).then((response)=>  maxMin);
  }, [user.tenantId, startDate, endDate]);

  const fetchThresholds = useCallback(async() => {
    return await getStationsThreshold(user.tenantId, [selectedStationRef.current]).then((res) => {
      const stationThresholds = [];
      Object.keys(res).forEach((stationId) => {
        Object.keys(res[stationId]).forEach((sensorId) => {
          Object.keys(res[stationId][sensorId]).forEach((measureId) => {
            const cuParent = sensors[sensorId].deviceParent.type === "cu" ? sensors[sensorId].deviceParent.device : null;
            stationThresholds.push({
              measureId: measureId,
              sensorParent: sensorId,
              cuParent: cuParent,
              ...res[stationId][sensorId][measureId]
            })
          })
        })
      });
      return stationThresholds;
    });
  }, [user.tenantId]);

  useEffect(() => {
    selectedStationRef.current = selectedStation;
  }, [selectedStation])

  useEffect(() => {
    if(!timeRangeInView?.selected) return;
    const range = calcIntervalDates(timeRangeConstants[timeRangeInView.selected].timeRange);
    setEndDate(range.end);
    setStartDate(range.start);
  }, [timeRangeInView?.selected])

  useEffect(() => { 
    if(!startDate || !endDate) return;
    let canSet = true, intervalId = null;
    const fetchAllData = async() => {
      setLoadingAliveSensors(true);

      let fetch = [
        fetchAliveSensors().then((res) => {
          if(!canSet) return;
          setAliveSensors(res);
          setLoadingAliveSensors(false);
        }),
      ]
      if(selectedStationRef.current) {
        setLoadingMinMax(true);
        setLoadingThresholds(true);
        fetch = [...fetch,
          fetchMaxMinMeasures().then((res) => {
            if(!canSet) return;
            setMaxMinMeasures(res);
            setLoadingMinMax(false);
          }),
          fetchThresholds().then((res) => {
            if(!canSet) return;
            setThresholds(res);
            setLoadingThresholds(false);
          })     
        ];
      }
      await Promise.all(fetch).catch((error) => { throw error; });
    }
    fetchAllData().then(() => {
      if(timeRangeInView.selected) {
        const time = endDate - startDate;
        intervalId = setInterval(() => {
          fetchAllData();
        }, [time])
      }
    }).catch((error) => {
      setServerError(true)
    });

    return () => {
      intervalId && clearInterval(intervalId);
      canSet = false
    };
  }, [startDate, endDate])

  useEffect(() => {
    if (!selectedStation) return;
    let canSet = true;
    setLoadingMinMax(true);

    fetchMaxMinMeasures().then((res) => {
      if(!canSet) return;
      setMaxMinMeasures(res);
      setLoadingMinMax(false);
    });
    return () => canSet = false;
  }, [selectedStation, user.tenantId]);

  useEffect(() => {
    if (!selectedStation) return;
    let canSet = true;
    setLoadingThresholds(true);
    fetchThresholds().then((res) => {
      if(!canSet) return;
      setThresholds(res);
      setLoadingThresholds(false);
    });

    return () => canSet = false;
  }, [selectedStation, user.tenantId])

  return {loadingAliveSensors, loadingMinMax, loadingThresholds, aliveSensors, maxMinMeasures, thresholds, endDate}

}

export default useDashboardData