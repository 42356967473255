import {
  Box,
  List,
  ListItem,
  ListItemText,
  Paper,
  Typography,
} from "@mui/material";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import StationsStateGraph from "./StationsStateGraph";
import SensorsStateGraph from "./SensorsStateGraph";
import { Link, useNavigate } from "react-router-dom";
import { lightGreen, red, yellow, grey } from "@mui/material/colors";
import LoadingGif from "../SharingComponents/LoadingGif";
import Carousel from "../SharingComponents/Carousel";
import { routesNames } from "app/configs/routesNames";
import CusStateGraph from "./CusStateGraph";
import InfoButton from "../SharingComponents/InfoButton";
import useTouch from "src/app/hooks/useTouch";
import { useTranslation } from "react-i18next";
import { translate } from "src/utilities/utils";
import { viewNames } from "app/configs/routesNames";
import { LuInspect } from "react-icons/lu";
import useMobile from "src/app/hooks/useMobile";
import { isSensorMultimedia } from "app/store/evocsSlice/sensors/sensorsActions";
import SwitchDisplayStack from "./SwitchDisplayStack";
import { borderColor } from "@mui/system";
import TableComponent from "../SharingComponents/TableComponent";
import { formatDateFromTimestamp, TIME_FORMAT } from "../StationHandling/utils";
import VideocamIcon from '@mui/icons-material/Videocam';
import { ta } from "date-fns/locale";
import { BsPieChartFill } from "react-icons/bs";
import { setFilter } from "app/store/evocsSlice/filters/filtersActions";
// import { BsPieChartFill } from "react-icons/bs";
import { MdAdsClick } from "react-icons/md";
import { SlArrowRight } from "react-icons/sl";

const GraphLink = ({ carouselValue, stationSelected, cuSelected }) => {
  const { t } = useTranslation("evocs");
  const handleTranslate = (textId, general = false) => {
    if (general) return translate(t, textId);
    return translate(t, textId, viewNames.DASHBOARD_VIEW);
  };
  const [xs, sm] = useMobile();
  const stations = useSelector((state) => state.stationsMongo.list);
  const controlUnits = useSelector((state) => state.controlUnits.list);
  const [backToDashObj, setBackToDashObj] = useState({ backView: routesNames.DASHBOARD_VIEW});

  const linkTo = useMemo(() => {
    switch (carouselValue) {
      case 0:
        return routesNames.STATIONS_VIEW;
      case 1:
        return `${routesNames.STATIONS_VIEW}/${stations[stationSelected].serial}`;
      case 2:
        return `${routesNames.STATIONS_VIEW}/${stations[stationSelected].serial}`;
      default:
        return "";
    }
  }, [carouselValue]);

  const linkText = useMemo(() => {
    switch (carouselValue) {
      case 0:
        return handleTranslate("GO_TO_STATIONS", true);
      case 1:
        return (
          handleTranslate("GO_TO", true) +
          " " +
          stations[stationSelected].serial
        );
      case 2:
        return (
          handleTranslate("GO_TO", true) + " " + controlUnits[cuSelected].serial
        );
    }
  }, [t, carouselValue]);

  useEffect(() => {
    if (!carouselValue || carouselValue < 2) setBackToDashObj({ backView: routesNames.DASHBOARD_VIEW});
    else setBackToDashObj({ backView: routesNames.DASHBOARD_VIEW, cuId: cuSelected  });
  }, [carouselValue]);

  return (
    <Link
      style={{
        position: "absolute",
        right: "2rem",
        bottom: "3rem",
        display: "flex",
        gap: "0.3rem",
        alignItems: "center",
        fontSize: xs ? "12px" : "1em",
      }}
      to={linkTo}
      state={backToDashObj}
    >
      <LuInspect />
      {linkText}
    </Link>
  );
};

const StationTooltipText = ({ fontsize = 12 }) => {
  const pixelSize = `${fontsize}px`;
  const { t } = useTranslation("evocs");
  const handleTranslate = (textId, general = false) => {
    if (general) return translate(t, textId);
    return translate(t, textId, viewNames.DASHBOARD_VIEW);
  };

  return (
    <>
      <Typography variant="body1" gutterBottom style={{ fontSize: pixelSize }}>
        {handleTranslate("PIE_LEGEND_1")}
      </Typography>
      <List>
        <ListItem>
          <ListItemText>
            <Box
              component="span"
              sx={{
                color: `#85cf57`,
                fontWeight: "bold",
                fontSize: pixelSize,
                textTransform: "uppercase",
              }}
            >
              {" "}
              {handleTranslate("GREEN", true)}:
            </Box>{" "}
            <span style={{ fontSize: pixelSize }}>
              {handleTranslate("PIE_LEGEND_2")}
            </span>
          </ListItemText>
        </ListItem>
        <ListItem>
          <ListItemText>
            <Box
              component="span"
              sx={{
                color: yellow[500],
                fontWeight: "bold",
                fontSize: pixelSize,
                textTransform: "uppercase",
              }}
            >
              {handleTranslate("YELLOW", true)}:
            </Box>{" "}
            <span style={{ fontSize: pixelSize }}>
              {handleTranslate("PIE_LEGEND_3")}
            </span>
          </ListItemText>
        </ListItem>
        <ListItem>
          <ListItemText>
            <Box
              component="span"
              sx={{
                color: red[500],
                fontWeight: "bold",
                fontSize: pixelSize,
                textTransform: "uppercase",
              }}
            >
              {handleTranslate("RED", true)}:
            </Box>{" "}
            <span style={{ fontSize: pixelSize }}>
              {" "}
              {handleTranslate("PIE_LEGEND_4")}
            </span>
          </ListItemText>
        </ListItem>
        <ListItem>
          <ListItemText>
            <Box
              component="span"
              sx={{
                color: "black",
                fontWeight: "bold",
                fontSize: pixelSize,
                textTransform: "uppercase",
              }}
            >
              {handleTranslate("GREY", true)}:
            </Box>{" "}
            <span style={{ fontSize: pixelSize }}>
              {" "}
              {handleTranslate("PIE_LEGEND_5")}
            </span>
          </ListItemText>
        </ListItem>
      </List>
      <Typography variant="body1" style={{ fontSize: pixelSize }}>
        {handleTranslate("PIE_LEGEND_6")}
      </Typography>
    </>
  );
};

const GraphsStateChip = ({
  style,
  aliveSensors,
  selectedStation,
  defaultStation,
  onSelectStation,
  loading = false,
  lastCheck,
}) => {
  const navigate = useNavigate();
  const allStations = useSelector((state) => state.stationsMongo.list);
  const controlUnits = useSelector((state) => state.controlUnits.list);
  const sensors = useSelector((state) => state.sensors.list);
  const [stationSelected, setStationSelected] = useState();
  const [cuSelected, setCuSelected] = useState();
  const [stationsGraph, setStationsGraph] = useState();
  const [cusGraph, setCusGraph] = useState();
  const [sensorsGraph, setSensorsGraph] = useState();
  const [carouselValue, setCarouselValue] = useState();
  const [isTouchDevice] = useTouch();
  const [tabularDisplay, setTabularDisplay] = useState(false);
  const [xs, sm, md, lg, xl] = useMobile();
  const [allStationsTableData, setAllStationsTableData] = useState([]);
  const [filteredAllStationsTableData, setAllStationsFilteredTableData] = useState([]);
  const [singleStationTableData, setSingleStationTableData] = useState([]);
  const [filteredSingleStationTableData, setFilteredSingleStationTableData] = useState([]);
  const [cuStationTableData, setCuStationTableData] = useState([]);
  const [filteredCuStationTableData, setFilteredCuStationTableData] = useState([]);
  const [searchedText, setSearchedText] = useState();
  const [searchedTextCu, setSearchedTextCu] = useState();
  const [searchedTextSingleStation, setSearchedTextSingleStation] = useState();
  const { t } = useTranslation("evocs");
  
  let lastCheckFormatted = lastCheck ? formatDateFromTimestamp(lastCheck, TIME_FORMAT.DATE_AND_HOUR_SECONDS) : "";

  const handleMoveCarouselBackward = (carouselIndex) => {
    if (carouselIndex !== undefined && carouselIndex !== null) {
      switch (carouselIndex) {
        case 0:
          setStationSelected(null);
          onSelectStation(null);
          break;
        case 1:
          setCuSelected(null);
          break;
        default:
          break;
      }
    }
  };

  const handleClickTablePieIcon = (e, stationId) => {
    e.preventDefault();
    e.stopPropagation();
    setTabularDisplay(!tabularDisplay);
    onSelectStation && onSelectStation(stationId);
  };

  const handleClickAllStationsTableIcon = (e, stationId) => {
    e.preventDefault();
    e.stopPropagation();
    onSelectStation && onSelectStation(stationId);
  };  
  
  const handleClickSingleStationTableIcon = useCallback((e, cuId) => {
    e.preventDefault();
    e.stopPropagation();
    setCuSelected(cuId);
    setCarouselValue(2);
  }, [stationSelected]);

  const handleClickMultimediaSensorIcon = (e, stationSelected) => {
    e.preventDefault();
    e.stopPropagation();
    navigate(
      `/multimedia-stations/${allStations[stationSelected].serial}`,
      {
        state: {
          station: { id: stationSelected },
          backView: routesNames.DASHBOARD_VIEW,
        },
      }
    );
  };

  const iconArrowAllStationsTable =
    (stationId) => <SlArrowRight size={20} onClick={(e) => handleClickAllStationsTableIcon(e, stationId)} />;  
  
  const iconArrowSingleStationTable = useCallback( 
    (cuId) => <SlArrowRight size={20} onClick={(e) => handleClickSingleStationTableIcon(e, cuId)} />
  , []);   
  
  const iconArrowSensorDirectLink = useCallback( 
    () => <MdAdsClick size={20} />
  , []);   
  
  const iconArrowSensorMultimediaDirectLink = 
    (stationId) => <VideocamIcon size={20} onClick={(e) => handleClickMultimediaSensorIcon(e, stationId)} />; 
  
  const handleTranslate = (textId, general = false) => {
    if (general) return translate(t, textId);
    return translate(t, textId, viewNames.DASHBOARD_VIEW);
  };

  const getColors = useCallback((status) => {
    switch (status) {
      case 0:
        // return { backgroundColor: `#df1628`, borderColor: `${red[600]}` };
        // return { backgroundColor: `#ed413e`, borderColor: `${red[600]}` };
        return { backgroundColor: `${red[500]}`, borderColor: `${red[700]}` };
      case 1:
        // return { backgroundColor: `${yellow['A400']}`, borderColor: `${yellow[600]}` };
        return {
          backgroundColor: `${yellow[500]}`,
          borderColor: `${yellow[700]}`,
        };
      case 2:
        return {
          backgroundColor: `#85cf57`,
          borderColor: `${lightGreen[700]}`,
        };
      // return { backgroundColor: `${lightGreen[400]}`, borderColor: `${lightGreen[600]}` };
      // return { backgroundColor: `#92C870`, borderColor: `${lightGreen[600]}` };
      default:
        return { backgroundColor: `${grey[500]}`, borderColor: `${grey[700]}` };
    }
  }, []);

  const calcStationsGraph = useCallback(
    (res) => {
      let graph = {
        data: [],
        labels: [],
        backgroundColor: [],
        borderColor: [],
      };
      Object.keys(res).forEach((stationId) => {
        graph.labels.push(allStations[stationId]?.serial);
        const colors = getColors(res[stationId].status);
        graph.backgroundColor.push(colors.backgroundColor);
        graph.borderColor.push(colors.borderColor);
      });
      graph.data = new Array(graph.labels.length).fill(
        100 / graph.labels.length
      );
      return graph;
    },
    [allStations]
  );

  const calcCusGraph = useCallback(() => {
    let graph = {
      data: [],
      labels: [],
      backgroundColor: [],
      borderColor: [],
    };
    Object.keys(aliveSensors[stationSelected]).forEach((sensorId) => {
      if (sensorId === "status") return;
      if (sensorId === "cus") {
        Object.keys(aliveSensors[stationSelected].cus).forEach((cuId) => {
          graph.labels.push(controlUnits[cuId].serial);
          const colors = getColors(
            aliveSensors[stationSelected].cus[cuId].status
          );
          graph.backgroundColor.push(colors.backgroundColor);
          graph.borderColor.push(colors.borderColor);
        });
        return;
      }
      graph.labels.push(sensors[sensorId].serial);
      const colors = getColors(aliveSensors[stationSelected][sensorId]);
      graph.backgroundColor.push(colors.backgroundColor);
      graph.borderColor.push(colors.borderColor);
    });
    graph.data = new Array(graph.labels.length).fill(100 / graph.labels.length);
    return graph;
  }, [aliveSensors, stationSelected]);

  const calcSensorsGraph = useCallback(() => {
    let graph = {
      data: [],
      labels: [],
      backgroundColor: [],
      borderColor: [],
    };
    const cu = aliveSensors[stationSelected].cus[cuSelected];
    Object.keys(cu).forEach((sensorId) => {
      if (sensorId === "status") return;
      graph.labels.push(sensors[sensorId].serial);
      const colors = getColors(cu[sensorId]);
      graph.backgroundColor.push(colors.backgroundColor);
      graph.borderColor.push(colors.borderColor);
    });
    graph.data = new Array(graph.labels.length).fill(100 / graph.labels.length);
    return graph;
  }, [aliveSensors, stationSelected, cuSelected]);

  useEffect(() => {
    if (!aliveSensors) return;
    setStationsGraph(calcStationsGraph(aliveSensors));
  }, [aliveSensors]);

  useEffect(() => {
    if (defaultStation) {
      setStationSelected(defaultStation);
    }  else  {
      setStationSelected(selectedStation);
    }
  }, [selectedStation]);

  useEffect(() => {
    setCuSelected(null);
    stationSelected ? setCarouselValue(1) : setCarouselValue(0);
  }, [stationSelected]);

  const getSensorsTableRowsFromCusAliveObj = (stationId, cuObj, actualTableRows) => {
    if (!cuObj) return;
    const {status, ...rest} = cuObj;
    const tableRows = Object.entries(rest).map(([sensorId, sensorStatus]) => {
      const serial = sensors?.[sensorId]?.serial;
      if (serial !== undefined && serial !== null && sensorStatus !== undefined && sensorStatus !== null) {
        let status = sensorStatus;
        status = getStatusString(status);
        return {
          serial: serial, 
          status: status, 
          icon: isSensorMultimedia(sensorId) ? iconArrowSensorMultimediaDirectLink() : iconArrowSensorDirectLink()};
      }
    });
    if (!actualTableRows) return tableRows;
    return [...actualTableRows, ...tableRows];
  };

  const getSingleStationTableRows = (stationId) => {
    const stationObj = aliveSensors[stationId];
    const {cus, status, ...rest } = stationObj;
    const cuTableRows = [];
    if (cus) {
      Object.keys(cus).forEach((cuId) => {
        const cuSerial = controlUnits?.[cuId]?.serial;
        let cuStatus = cus?.[cuId]?.status;
        if (cuStatus !== undefined && cuStatus !== null && cuSerial !== undefined && cuSerial !== null) {
          cuStatus = getStatusString(cuStatus);
          cuTableRows.push({serial: cuSerial, deviceType: handleTranslate("CONTROL_UNIT", true), status: cuStatus, icon: iconArrowSingleStationTable(cuId)});
        }
      });
    }
    if (rest){
      Object.entries(rest).map(([sensorId, sensorStatus]) => {
        const serial = sensors?.[sensorId]?.serial;
        if (serial !== undefined && serial !== null && sensorStatus !== undefined && sensorStatus !== null) {
          let status = sensorStatus;
          status = getStatusString(status);
          cuTableRows.push({
            serial: serial, 
            deviceType: handleTranslate("SENSOR", true), 
            status: status, 
            icon: isSensorMultimedia(sensorId) ? iconArrowSensorMultimediaDirectLink(stationId) : iconArrowSensorDirectLink()});
        }
      });
    }
    return cuTableRows;
  }  
  
  const getCuStationTableRows = (cuId) => {
    const cuObj = aliveSensors[selectedStation]['cus'][cuId];
    let cuTableRows = [];
    return getSensorsTableRowsFromCusAliveObj(selectedStation, cuObj, cuTableRows);
  }

  useEffect(() => {
    if (!aliveSensors || !stationSelected) return;
    const singleStationTableRows = getSingleStationTableRows(stationSelected)
    setSingleStationTableData(singleStationTableRows);
    setFilteredSingleStationTableData(searchedText ? filterSingleStationTableData(singleStationTableRows) : singleStationTableRows);
    setCusGraph(calcCusGraph());
  }, [stationSelected, aliveSensors]);

  useEffect(() => {
    if (!aliveSensors || !cuSelected) return;
    const cuStationTableRows = getCuStationTableRows(cuSelected)
    setCuStationTableData(cuStationTableRows);
    setFilteredCuStationTableData(searchedText ? filterCuStationTable(cuStationTableRows) : cuStationTableRows);
    setSensorsGraph(calcSensorsGraph());
  }, [cuSelected, aliveSensors]);

  const handleClickStationGraph = useCallback(
    (serial) => {
      const stationId = Object.keys(allStations).find(
        (id) => allStations[id].serial === serial
      );
      stationId ? setCarouselValue(1) : setCarouselValue(0);
      setStationSelected(stationId);
      onSelectStation && onSelectStation(stationId);
    },
    [allStations]
  );

  const handleClickSensorGraph = useCallback(
    (serial) => {
      const sensorId = Object.keys(sensors).find(
        (id) => sensors[id].serial === serial
      );
      if (!isSensorMultimedia(sensorId)) {
        navigate(`/stations/${allStations[stationSelected].serial}`, {
          state: {
            stationId: stationSelected,
            sensorId: sensorId,
            backView: routesNames.DASHBOARD_VIEW,
          },
        });
      } else {
        navigate(
          `/multimedia-stations/${allStations[stationSelected].serial}`,
          {
            state: {
              station: { id: stationSelected, ...allStations[stationSelected] },
              backView: routesNames.DASHBOARD_VIEW,
            },
          }
        );
      }
    },
    [sensors, stationSelected]
  );

  const handleClickCuGraph = useCallback(
    (serial) => {
      const cuId = Object.keys(controlUnits).find(
        (id) => controlUnits[id].serial === serial
      );
      if (cuId) setCarouselValue(2);
      else handleClickSensorGraph(serial);
      setCuSelected(cuId);
    },
    [controlUnits, handleClickSensorGraph]
  );

  const getStationIdFromSerial = useCallback(
    (serial) => {
      return Object.keys(allStations).find(
        (id) => allStations[id].serial === serial
      );
    },
    [allStations]
  );

  const getCuIdFromSerial = useCallback(
    (serial) => {
      return Object.keys(controlUnits).find(
        (id) => controlUnits[id].serial === serial
      );
    },
    [controlUnits]
  );

  const getSensorIdFromSerial = useCallback(
    (serial) => {
      return Object.keys(sensors).find(
        (id) => sensors[id].serial === serial
      );
    },
    [sensors]
  );

  const gotToSelectedStation = (stationId) => {
    {
      navigate(`/stations/${allStations[stationId].serial}`, {
        state: {
          backView: routesNames.DASHBOARD_VIEW,
        },
      })
    }
  };
  
  const gotToSpecificCu = (cuId, stationId) => {
    navigate(`/stations/${allStations[stationId].serial}`, {
      state: {
        backView: routesNames.DASHBOARD_VIEW,
        cuId: cuId
      },
    })
  };
  
  const gotToSpecificSensor = (sensorId, stationId) => {
    {
      navigate(`/stations/${allStations[stationId].serial}`, {
        state: {
          backView: routesNames.DASHBOARD_VIEW,
          sensorId: sensorId
        },
      })
    }
  };
  
  const onClickRow = (rowIndex) => {
    let stationFiltered = null;
    let stationId = null;
    switch(carouselValue) {
      case 0:
        stationFiltered = allStationsTableData.filter((el, index)=> index === rowIndex)
        if(stationFiltered.length > 0) {
          stationId= getStationIdFromSerial(stationFiltered[0].serial);
          gotToSelectedStation(stationId);
        }
        break;
      case 1:
        stationFiltered = allStations[selectedStation] ?? [];
        if (stationFiltered && singleStationTableData && singleStationTableData.length > 0) {
          stationId= getStationIdFromSerial(stationFiltered.serial);
          const clickedRow = singleStationTableData[rowIndex];
          const deviceType = clickedRow.deviceType
          const deviceSerial = clickedRow.serial
          if (deviceType === handleTranslate("CONTROL_UNIT", true)) {
            const cuId = getCuIdFromSerial(deviceSerial);
            gotToSpecificCu(cuId, stationId);
          }
          else if (deviceType === handleTranslate('SENSOR', true)) {
            const sensorId = getSensorIdFromSerial(deviceSerial);
            gotToSpecificSensor(sensorId, stationId);
          }
        }
        break;
      case 2:
        stationFiltered = allStations[selectedStation];
        if(stationFiltered && cuStationTableData && cuStationTableData.length > 0) {
          stationId= getStationIdFromSerial(stationFiltered.serial);
          const clickedRow = cuStationTableData[rowIndex];
          const sensorSerial = clickedRow.serial
          const sensorId = getSensorIdFromSerial(sensorSerial);
          gotToSpecificSensor(sensorId, stationId);
        }
        break;
    }
  };

  const allStationsStatusColumns = useMemo(() => {
    return {
      serial: handleTranslate("STATION", true),
      status: "Status",
      icon: "",
    }
  }, [t]);  
  
  const singleStationStatusColumns = useMemo(() => {
    return {
      serial: handleTranslate("DEVICE", true),
      deviceType: handleTranslate("TYPE", true),
      status: "Status",
      icon: "",
    }
  }, [t]);  
  
  const cuStatusColumns = useMemo(() => {
    return {
      serial: handleTranslate("SENSOR", true),
      status: "Status",
      icon: "",
    }
  }, [t]);

  const filterAllStationsTableData = (data) => {
    const searchTerms = searchedText?.trim().toLowerCase().split(' ');
  
    setAllStationsFilteredTableData(() => {
      const filterData = data || allStationsTableData;
      return filterData.filter((element) => {
        const allValues = Object.values(element)
          .map((value) => (typeof value === 'string' ? value.toLowerCase() : ''))
          .join(' ');
  
        return searchTerms.every((term) => allValues.includes(term));
      });
    });
  };
  
  const filterSingleStationTableData = (data) => {
    const searchTerms = searchedTextSingleStation?.trim().toLowerCase().split(' ');
  
    setFilteredSingleStationTableData(() => {
      const filterData = data || singleStationTableData;
  
      return filterData.filter((element) => {
        const allValues = Object.values(element)
          .map((value) => (typeof value === 'string' ? value.toLowerCase() : ''))
          .join(' ');
  
        return searchTerms.every((term) => allValues.includes(term));
      });
    });
  };

  const filterCuStationTable = (data) => {
    const searchTerms = searchedTextCu?.trim().toLowerCase().split(' ');
  
    setFilteredCuStationTableData(() => {
      const filterData = data || cuStationTableData;
  
      return filterData.filter((element) => {
        const allValues = Object.values(element)
          .map((value) => (typeof value === 'string' ? value.toLowerCase() : ''))
          .join(' ');
        return searchTerms.every((term) => allValues.includes(term));
      });
    });
  };

  useEffect(() => {
    if (!searchedText || (searchedText && searchedText === '')){
      if (allStationsTableData?.length !== filteredAllStationsTableData?.length) setAllStationsFilteredTableData(allStationsTableData);
    }else{
      filterAllStationsTableData();
    }
  }, [searchedText])  
  
  useEffect(() => {
    if (!searchedTextSingleStation || (searchedTextSingleStation && searchedTextSingleStation === '')){
      if (selectedStation && singleStationTableData?.length !== filteredSingleStationTableData?.length) setFilteredSingleStationTableData(singleStationTableData);
    }else{
      filterSingleStationTableData();
    }
  }, [searchedTextSingleStation])  
  
  useEffect(() => {
    if (!searchedTextCu || (searchedTextCu && searchedTextCu === '')){
      if (cuSelected && cuStationTableData?.length !== filteredCuStationTableData?.length) setFilteredCuStationTableData(cuStationTableData);
    }else{
      filterCuStationTable();
    }
  }, [searchedTextCu])

  const getStatusString = useCallback((status) => {
    switch (status) {
      case 0:
        return handleTranslate("NOT_WORKING", true);
      case 1: 
        return handleTranslate("PARTIALLY_WORKING", true);
      case 2:
        return handleTranslate("WORKING", true);
      default:
        return handleTranslate("NOT_AVAILABLE", true);
    }
  }, [t]);

  const getAllStationsTableRows = () => {
    return Object.keys(aliveSensors).map((stationId) => {
      const stationObj = allStations[stationId];
      const aliveSensorsStation = aliveSensors?.[stationId];
      const statusString = getStatusString(aliveSensorsStation?.status)
      return {status: statusString, serial: stationObj?.serial,
        icon: iconArrowAllStationsTable(stationId)};
        // icon: <BsPieChartFill size={20} onClick={(e, stationId) => handleClickTablePieIcon(e, stationIdClicked)} />};
    });
  }

  useEffect(() => {
    if (!aliveSensors) return;
    const tableRows = getAllStationsTableRows();
    setAllStationsTableData(tableRows);
    setAllStationsFilteredTableData(tableRows);
  },[aliveSensors]);

  const renderNoActiveDevices = () => {
    return (
      <div style={{ display: "flex", justifyContent: "center", alignItems: "center", height: "100%" }}>
        <label>{handleTranslate("NO_ACTIVE_DEVICES", true)}</label>
      </div>
    );
  };  
  
  const renderNoActiveStations = () => {
    return (
      <div style={{ display: "flex", justifyContent: "center", alignItems: "center", height: "100%" }}>
        <label>{handleTranslate("NO_ACTIVE_STATIONS", true)}</label>
      </div>
    );
  };

  const renderLoading = () => (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100%",
      }}
    >
      <LoadingGif />
    </Box>
  );

  const renderAllStationsTable = useMemo(() => {
    if (allStationsTableData.length === 0) {return renderNoActiveStations();}
    else{
      return (
        <TableComponent
          style={{ height: "100%" }}
          rowsForPage={xs ? 4 : 10}
          columns={allStationsStatusColumns}
          data={filteredAllStationsTableData}
          clickable
          onClickRow={onClickRow}
          // tableTitle={"Status " + handleTranslate("MONITORING_STATIONS", true).toLowerCase()}
          // tableTitle={'Status stazioni'}
          onSearch={setSearchedText}
          searchTexValue={searchedText}
          statusStationTab
          paper={false}
          sortable={true}
        />
    )}
  }, [filteredAllStationsTableData, searchedText]); 
  
  const renderSingleStationsTable = useMemo(() => {
    if (singleStationTableData.length === 0) return renderNoActiveDevices();
    return (
      <TableComponent
        style={{ height: "100%" }}
        rowsForPage={xs ? 4 : 10}
        columns={singleStationStatusColumns}
        data={filteredSingleStationTableData}
        clickable
        onClickRow={onClickRow}
        // tableTitle={handleTranslate("MONITORING_STATIONS", true)}
        onSearch={setSearchedTextSingleStation}
        searchTexValue={searchedTextSingleStation}
        statusStationTab
        paper={false}
        sortable={true}
      />
    )}
  , [filteredSingleStationTableData, searchedTextSingleStation]);  
  
  const renderCuStationTable = useMemo(() => {
    if (cuStationTableData.length === 0) return renderNoActiveDevices();
    return (
      <TableComponent
        style={{ height: "100%" }}
        rowsForPage={xs ? 4 : 10}
        columns={cuStatusColumns}
        data={filteredCuStationTableData}
        clickable
        onClickRow={onClickRow}
        // tableTitle={handleTranslate("MONITORING_STATIONS", true)}
        onSearch={setSearchedTextCu}
        searchTexValue={searchedTextCu}
        statusStationTab
        paper={false}
        sortable={true}
      />
    )}
  , [filteredCuStationTableData, searchedTextCu]);

  const renderAllStationsStateGraph = useMemo(() => {
    if (allStationsTableData.length === 0) return renderNoActiveStations();
    return (
      <StationsStateGraph
        data={stationsGraph?.data}
        labels={stationsGraph?.labels}
        backgroundColor={stationsGraph?.backgroundColor}
        borderColor={stationsGraph?.borderColor}
        onClickPie={handleClickStationGraph}
      />
    );
  }, [loading, allStationsTableData, tabularDisplay]);

  const renderSingleStationStateGraph = useMemo(() => {
    if (allStationsTableData.length === 0) return renderNoActiveStations();
    return (
      <CusStateGraph
        title={`${handleTranslate("STATION", true)}: ${
          allStations?.[stationSelected]?.serial
        }`}
        data={cusGraph?.data}
        labels={cusGraph?.labels}
        backgroundColor={cusGraph?.backgroundColor}
        borderColor={cusGraph?.borderColor}
        onClickPie={handleClickCuGraph}
      />
    );
  }, [[loading, allStationsTableData, tabularDisplay, stationSelected]]);

  const renderCuStateGraph = useMemo(() => {
    if (allStationsTableData.length === 0) return renderNoActiveStations();
    return (
      <SensorsStateGraph
        title={`${handleTranslate("CONTROL_UNIT", true)}: ${
          controlUnits?.[cuSelected]?.serial
        }`}
        data={sensorsGraph?.data}
        labels={sensorsGraph?.labels}
        backgroundColor={sensorsGraph?.backgroundColor}
        borderColor={sensorsGraph?.borderColor}
        onClickPie={handleClickSensorGraph}
      />
    );
  }, [[loading, allStationsTableData, tabularDisplay, cuSelected]]);
  
  const handleSwitchTabular = () => {
    setTabularDisplay(!tabularDisplay);
    if (!tabularDisplay === true)
      handleMoveCarouselBackward(carouselValue);
    
  };

  const linkGraphCarousel =() => {
    return(
      <GraphLink
        carouselValue={carouselValue}
        stationSelected={stationSelected}
        cuSelected={cuSelected}
      />
    )
  };

  const graphCarousel =() => {
    return(
      <>
          <Carousel
            disableArrows={false}
            value={carouselValue}
            onChange={setCarouselValue}
            handleMoveCarouselBackward={handleMoveCarouselBackward}
            isTableCarousel={false}
            >
                  {renderAllStationsStateGraph}
                  {stationSelected && renderSingleStationStateGraph}
                  {cuSelected && renderCuStateGraph}
            </Carousel>
            {linkGraphCarousel()}
      </>
    )
  };  
  
  const tableCarousel =() => {
    return(
      <>
          <Carousel
            disableArrows={false}
            value={carouselValue}
            onChange={setCarouselValue}
            handleMoveCarouselBackward={handleMoveCarouselBackward}
            isTableCarousel={true}
            >
              {renderAllStationsTable}
              {stationSelected && renderSingleStationsTable}
              {cuSelected && renderCuStationTable}
            </Carousel>
      </>
    )
  } ;

  useEffect(() => {
    if(!stationSelected){
      if (searchedTextSingleStation) setSearchedTextSingleStation(null)
    }
  }, [stationSelected])   
  
  useEffect(() => {
    if(!cuSelected){
      if (searchedTextCu) setSearchedTextCu(null)
    }
  }, [cuSelected])


const StatusComponent = () => {

  return (
    <div style={{ flex: 1, textAlign: "start", marginLeft: "1rem" }}>
      <Typography
        variant="h6"
        component="h2"
        style={{ display: "flex", alignItems: "center", gap: "0.3rem" }}
      >
        <div
          style={{ display: "inline-block", cursor: "default" }}
        >
            <div>
              {handleTranslate("ACTIVE_STATIONS", true)}
            </div>
               {/* <Link to={`/stations`} state={{ backView: routesNames.STATIONS_VIEW }} style={{ textDecoration: "none", cursor: "pointer" }}>
                {handleTranslate("STATIONS", true).toLowerCase()}
              </Link>  */}
        </div>
      </Typography>
    </div>

  );
}

  return (
    <Paper>
      <div 
        style={{ 
          display: "flex", flexDirection: "row", justifyContent: "end", alignItems: "center", 
        }}
      >
        <StatusComponent />
        
        <div>
          < SwitchDisplayStack tabular={tabularDisplay} onChange={handleSwitchTabular} pieIcon/>
        </div>
      </div>
      <Box sx={{ ...style, position: "relative", height: "400px" }}>
          {loading ? renderLoading()
          :
          !tabularDisplay ?
          <>
            <InfoButton
              style={{ position: "absolute", right: "0.5rem", top: "1rem" }}
              fontSize="medium"
              placement="bottom"
              enableModal={isTouchDevice}
            >
              <StationTooltipText fontsize={12} />
            </InfoButton>
            {graphCarousel()}
          </>
          :
          tableCarousel()
        } 
      </Box>
    </Paper>
  );
};

export default GraphsStateChip;
