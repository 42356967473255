import { readOpenData, getOpenDataMarkers } from 'app/store/evocsSlice/openaData/openaDataActions';
import { useEffect, useState } from 'react'


// function filterNullData(dataArray) {
//   console.log('dataArray: ', dataArray);
//   return dataArray.reduce((acc, obj) => {
//     const filteredObj = Object.entries(obj).reduce((innerAcc, [key, value]) => {
//       if (value !== null && value !== undefined && !(typeof value === 'number' && Number.isNaN(value))) {
//         innerAcc[key] = value;
//       }
//       return innerAcc;
//     }, {});

//     if (Object.keys(filteredObj).length > 0) {
//       acc.push(filteredObj);
//     }
//     return acc;
//   }, []);
// }


const useOpendata = (startDate, endDate, typeStation=null, sourceId=null) => {
  const [loadingData, setLoadingData] = useState(true);
  const [loadingMarkers, setLoadingMarkers] = useState(true);
  const [data, setData] = useState([]);
  const [markers, setMarkers] = useState([]);

  useEffect(() => {
    let canSet = true;
    setLoadingMarkers(true);
    fetchMarkers().then((res) => {
      if(!canSet) return;
      setLoadingMarkers(false);
      setMarkers(res);
    });

    return () => canSet = false;
  }, [sourceId, typeStation])

  useEffect(() => {
    if(!startDate || !endDate) return;
    let canSet = true;
    setLoadingData(true);
    fetchData().then((res) => {
      if(!canSet) return;
      setLoadingData(false);
      setData(res);
    });

    return () => canSet = false;
  }, [startDate, endDate, typeStation, sourceId]);

  const fetchMarkers = async() => {
    return await getOpenDataMarkers(sourceId ? sourceId : undefined, typeStation)
  };

  const fetchData = async() => {
    return await readOpenData(startDate, endDate, sourceId ? [sourceId] : null, null, null, typeStation);
  };

  // const filteredData = filterNullData(data);

  return [data, markers, loadingMarkers, loadingData];
}

export default useOpendata;