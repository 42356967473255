import React, { useCallback, useEffect, useMemo, useState } from 'react'
import AxisCameraSelect from '../SharingComponents/Inputs/AxisCameraSelect'
import SwitchComponent from '../SharingComponents/Inputs/SwitchComponent';
import { Divider } from '@mui/material';
import SelectComponent from '../SharingComponents/Inputs/SelectComponent';
import TabsComponent from '../SharingComponents/TabsComponent';
import SingleMultimediaVideoList from './SingleMultimediaVideoList';
import SingleMultimediaPhotoList from './SingleMultimediaPhotoList';
import { viewNames } from 'app/configs/routesNames';
import { useTranslation } from 'react-i18next';
import { translate } from "src/utilities/utils";

const cameraOptions = ["Camera 1", "Camera 2", "Camera 3", "Camera 4", "Quad"];


const SideBarContent = ({ actions, live, selectedCamera, cameraResolution, quadResolution, selectedRecording, selectedImage, resolutionList, recordinglist, imageList, disableLiveOptions = false, onChangeTab,
  multimediaTypes, onChangeCamera, onChangeResolution, onChangeLive, onPlayRecord, onStopRecord, onExportRecord, onDeleteRecord, onSelectImage, onScrollNextImages }) => {

  const { t } = useTranslation("evocs");
  const handleTranslate = (textId, general = false) => {
    if (general) return translate(t, textId);
    return translate(t, textId, viewNames.SINGLE_MULTIMEDIA_VIEW);

  };

  const tabs = useMemo(() => {
    const listHeight = disableLiveOptions ? 700 : 550;
    let allTabs = {};
    if(actions?.find((action) => action.name === "show-video")) {
      allTabs.video = {
        header: "Video",
        content: <SingleMultimediaVideoList actions={actions} list={recordinglist} selected={selectedRecording} onPlayRecord={onPlayRecord} onStopRecord={onStopRecord} onExportRecord={onExportRecord} onDeleteRecord={onDeleteRecord} />
      }
    }
    if(actions?.find((action) => action.name === "show-image") && multimediaTypes.includes("image")) {
      allTabs.image = {
        header: handleTranslate("IMAGES", true),
        content: <SingleMultimediaPhotoList height={listHeight} list={imageList} selectedImage={selectedImage} onSelectImage={onSelectImage} onScrollNext={onScrollNextImages} />
      }
    }
    return allTabs;
  }, [recordinglist, imageList, selectedRecording, selectedImage, disableLiveOptions, multimediaTypes, t])

  const getSelectedResolution = useCallback(() => {
    if (selectedCamera !== "Quad") {
      return cameraResolution;
    } else {
      return quadResolution;
    }
  }, [resolutionList]);


  return (
    <div style={{ display: "flex", flexDirection: "column", gap: "1.5rem", padding: "2rem 1rem" }}>
      {!disableLiveOptions &&
        <>
          <h3 style={{ fontWeight: "bolder" }}>Live streaming</h3>
          <div style={{ display: "flex", gap: "1rem" }}>
            <AxisCameraSelect options={cameraOptions} value={selectedCamera} onChange={onChangeCamera} />
            <SwitchComponent label='Live' checked={live} onChange={onChangeLive} />
          </div>
          <SelectComponent labelId='resolution' disableNullValue placeholder={handleTranslate("RESOLUTION", true)} options={resolutionList} value={getSelectedResolution()} onChange={onChangeResolution} />
          <Divider />
        </>
      }
      <TabsComponent style={{ fontWeight: "bolder" }} tabs={tabs} onChange={onChangeTab} unselectTextColor='#c2c2c2' />
    </div>
  )
}

export default SideBarContent