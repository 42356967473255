import { useEffect, useMemo, useState } from 'react';
import MapComponent from '../SharingComponents/MapComponent';
import { getPolarPlots } from 'app/store/evocsSlice/stationsMongo/stationsMongoActions';
import legendImage from 'src/app/asset/images/polarPlotLegend.png';
import { useTranslation } from 'react-i18next';
import { translate } from "src/utilities/utils";

const styles = {
  popoupLegend: {
    position: 'absolute',
    bottom: '0.5rem',  // Adjusted to bottom
    right: '1rem',
    backgroundColor: 'white',
    padding: '0.5rem',
    borderRadius: '1rem',
    zIndex: 1000,  // You can adjust the z-index as needed
    width: '250px',
    boxShadow: '0 2px 4px rgba(0, 0, 0, 0.2)', // Add a shadow
    pointerEvents: "none"
  },
  imageLegend: {
    position: 'absolute',
    width: "50px",
    bottom: '5rem',  // Adjusted to bottom
    left: '1rem',
    zIndex: 1000,  // You can adjust the z-index as needed
    pointerEvents: "none"
  }
};


const VesselMap = ({enabledActions, vessels, vesselsTypeMarkers, center, selectedMarker, graphMarkerIcon}) => {
  const { t } = useTranslation("evocs");
  const [mapPopups, setMapPopups] = useState([]);
  const [displayPolarLegend, setDisplayPolarLegend] = useState(false);
  const [overlays, setOverlays] = useState([]);
  const disableMapFullView = useMemo(() => {
    return !enabledActions.includes("map-full-view");
  }, [enabledActions]);


useEffect(() => {
  const hasAction = (action) => enabledActions.includes(action);

  // Controlla se l'effetto deve essere eseguito
  if (!hasAction("show-polarplot") && !hasAction("show-ecotaras-vessels")) return;

  // Funzione per aggiungere overlay solo se non esiste già
  const addOverlayIfNotExists = (id, createOverlay) => {
    if (!overlays.some((element) => element.id === id)) {
      setOverlays((prev) => [...prev, createOverlay()]);
    }
  };

  // Gestione degli Ecotaras Vessels
  if (hasAction("show-ecotaras-vessels") && vessels) {
    addOverlayIfNotExists("ecoVessels", () => {
      const ecotarasVessels = vessels.filter((element) => element.type === 999);
      return {
        id: "ecoVessels",
        name: "Ecotaras vessels",
        checked: true,
        markers: ecotarasVessels,
      };
    });
  }

  // Gestione asincrona dei Polar Plots
  const handlePolarPlots = async () => {
    if (!hasAction("show-polarplot") || overlays.some((element) => element.id === "polarPlots")) {
      return;
    }

    const endDate = new Date().valueOf();
    const startDate = endDate - 3600000 * 4;

    try {
      const res = await getPolarPlots(startDate, endDate);

      if (res.plot) {
        const overlay = {
          id: "polarPlots",
          name: "Polar Plots",
          checked: false,
          imageOverlays: [
            {
              lat: 40.4769776766326,
              lon: 17.1974215834441,
              url: "data:image/png;base64," + res.plot,
            },
          ],
          onHover: () => setDisplayPolarLegend(true),
          onBlur: () => setDisplayPolarLegend(false),
        };

        setOverlays((prev) => [...prev, overlay]);
      }
    } catch (error) {
      console.error("Errore nel recupero dei Polar Plots:", error);
    }
  };

  handlePolarPlots();
}, [enabledActions, vessels]); // Aggiungi solo `enabledActions` e `vessels` come dipendenze



  useEffect(() => {
    if(!vessels) return;
      setOverlays((prev) => {
        if (!prev) return;
        let newPrev = [...prev];
        const aisVessels = vessels.filter((element) => element.type !== 999);
        const aisOverlay = {
          id: "AisVessels",
          name: "Ais vessels",
          checked: true,
          markers: aisVessels,
        }
        newPrev.push(aisOverlay);
        return newPrev;
      });
    
    
    setMapPopups(vessels.map((element, index) => ({
      title: 
      <div key={index} style={{display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center", gap: "0.5rem"}}>
        {element.flag}
        {element.name}
      </div>
      ,
      type: {
        label: handleTranslate("TYPE", true),
        value: handleTranslate(`VESSEL_TYPE_${element.type}`, true)
      },
      id: element.id,
    })))
  }, [vessels, t])


  const handleTranslate = (textId, general = false) => {
    if (general) return translate(t, textId);
    return translate(t, textId, viewNames.VESSELS_VIEW);
  };


  return (
    <MapComponent 
    cluster 
    center={center} 
    maxClusterRadius={20} 
    disableClusteringAtZoom={13} 
    selectedMarker={selectedMarker} 
    overlays={overlays}
    popupsContent={mapPopups} 
    clusterColor={"white"}
    disableFullView={disableMapFullView}
    >
      {displayPolarLegend &&
      <div>
        <div style={styles.popoupLegend}>
        <p>{handleTranslate("POLAR_LEGEND_1", true)}
          <label style={{ color: 'blue' }}> {handleTranslate("BLUE", true)} </label> 
          {handleTranslate("POLAR_LEGEND_2", true)} 
          <label style={{ color: 'red' }}> {handleTranslate("RED", true)} </label>
          {handleTranslate("POLAR_LEGEND_3", true)} 
        </p>
        </div>
        <div style={styles.imageLegend}>
          <img src={legendImage}/>
        </div>
      </div>
      }
    </MapComponent>
  )
}

export default VesselMap