import store from "../..";
import { networkSliceActions } from "./networkSlice";

export const checkFields = (name, port, update = false) => {
  const deviceNameRegex = /^[a-zA-Z0-9_\s]+$/;
  const portRegex = /^\d{1,5}$/;
  let error = {
    name: {
      isExisting: false,
      regexSpecialChars: false,
    },
    port: {
      isExisting: false,
      regexNumber: false,
    },
  };
  const deviceList = store.getState().networkDevices?.value || [];

  const checkExistingFields = () => {
    if (
      deviceList.some((el) =>
        update
          ? el.name.toLowerCase().trim() === name.toLowerCase().trim() &&
            el.name !== name
          : el.name.toLowerCase().trim() === name.toLowerCase().trim()
      )
    ) {
      error.name.isExisting = true;
    }

    if (
      deviceList.some((el) =>
        update ? el.port === port && el.port !== port : el.port === port
      )
    ) {
      error.port.isExisting = true;
    }
  };
  const validateFields = () => {
    if (!deviceNameRegex.test(name)) {
      error.name.regexSpecialChars = true;
    }

    if (!portRegex.test(port)) {
      error.port.regexNumber = true;
    }
  };
  validateFields();
  if (!error.name.regexSpecialChars && !error.port.regexNumber) {
    checkExistingFields();
  }
  return error;
};

export const setActiveDevice = (id) => {
  store.dispatch(networkSliceActions.setActiveDevice(id));
};

// export const addDevice = (name, port) => {
//   const value = store.getState().networkDevices.value;
//   const newDevice = { id: value.length + 1, name, port };
//   store.dispatch(networkSliceActions.addDevice(newDevice));
// };

// export const deleteDevice = (deviceId) => {
//   store.dispatch(networkSliceActions.deleteDevice(deviceId));
// };

// export const updateDevice = (device) => {
//   store.dispatch(networkSliceActions.updateDevice(device));
// };

export const getDeviceById = (deviceId) => {
  const list = store.getState().networkDevices.value;
  return list ? list.find((item) => item.id === deviceId) : null;
};

export const scanWifi = async (port) => {
  const data = await store
    .dispatch(networkSliceActions.scanWifi({ port }))
    .then(({ payload, error }) => {
      if (error) throw error;
      return payload;
    });
  return data;
};

export const scanBlt = async (port) => {
  const data = await store
    .dispatch(
      networkSliceActions.scanBlt({
        port: port,
        time: 2,
      })
    )
    .then(({ payload, error }) => {
      if (error) throw error;
      return payload;
    });

  return data;
};

export const rebootVpnDevice = async (port) => {
  const data = await store
    .dispatch(networkSliceActions.rebootVpnDevice({ port }))
    .then(({ payload, error }) => {
      if (error) throw error;
      return payload;
    });

  return data;
};

export const readNetData = async (port) => {
  const data = await store
    .dispatch(networkSliceActions.readNetData({ port }))
    .then(({ payload, error }) => {
      if (error) throw error;
      return payload;
    });

  return data;
};

export const readConnectedBltDevices = async (port) => {
  const data = await store
    .dispatch(networkSliceActions.readConnectedBltDevices({ port }))
    .then(({ payload, error }) => {
      if (error) throw error;
      return payload;
    });

  return data;
};

export const bltPairing = async (port, pairing) => {
  
  if (pairing !== 0 && pairing !== 1) {
    throw new Error("Invalid pairing value. Must be 0 or 1.");
  }
  const data = await store
    .dispatch(networkSliceActions.bltPairing({ port: port, pairing: pairing }))
    .then(({ payload, error }) => {
      if (error) throw error;
      return payload;
    });

  return data;
};

export const connectDisconnectBltDevices = async (
  port,
  macAddress,
  choice = 1
) => {
  const data = await store
    .dispatch(
      networkSliceActions.connectDisconnectBltDevices({
        port: port,
        macAddress: macAddress,
        choice: choice,
      })
    )
    .then(({ payload, error }) => {
      if (error) throw error;
      return payload;
    });

  return data;
};

export const checkEthernet = async (port) => {
  const data = await store
    .dispatch(networkSliceActions.checkEthernet({ port }))
    .then(({ payload, error }) => {
      if (error) {
        // if (error?.request?.response) {
        //   const errorMessage = JSON.parse(error?.request?.response);
        //   if (
        //     errorMessage?.error &&
        //     errorMessage?.error === "Could not establish session to SSH gateway"
        //   ) {
        //     store.dispatch(networkSliceActions.setError({ error: true }));
        //   }
        // } else throw error;
        throw error
      }
      return payload;
    });
  console.log('data: ', data);
  return data;
};

export const addWifiNetwork = async (port, ssid, password) => {
  const data = await store
    .dispatch(networkSliceActions.addWifiNetwork({ port: port, ssid: ssid, pswd: password }))
    .then(({ payload, error }) => {
      if (error) throw error;
      return payload;
    });

  return data;
};

export const connectModifyNet = async (port, ssid, pswd, emptyPswd=false) => {
  const data = store
    .dispatch(networkSliceActions.connectModifyNet({ port: port, ssid: ssid, pswd: pswd, emptyPswd: emptyPswd }))
    .then(({ payload, error }) => {
      if (error) throw error;
      return payload;
    });

  return data;
};

export const addVpnDevice = async (name, port) => {
  const data = await store
    .dispatch(networkSliceActions.addVpnDevice({ port: port, name: name }))
    .then(({ payload, error }) => {
      if (error) throw error;
      return payload;
    });

  return data;
};

export const updateVpnDevice = async (id, name, port) => {
  const data = await store
    .dispatch(
      networkSliceActions.updateVpnDevice({ id: id, name: name, port: port })
    )
    .then(({ payload, error }) => {
      if (error) throw error;
      return payload;
    });

  return data;
};

export const readVpn = async () => {
  const data = await store
    .dispatch(networkSliceActions.readVpn())
    .then(({ payload, error }) => {
      if (error) {
        console.error(error);
        return null;
      }
      return payload;
    });
  return data;
};

export const removeVpn = async (deviceIds) => {
  const data = await store
    .dispatch(networkSliceActions.removeVpn({ deviceIds: deviceIds }))
    .then(({ payload, error }) => {
      if (error) {
        throw error;
      }
      return payload;
    });
  return data;
};

export const devicePing = async (port) => {
  const data = await store
    .dispatch(networkSliceActions.devicePing({ port }))
    .then(({ payload, error }) => {
      if (error) throw error;
      return payload;
    });
  return data;
};
