import {
  MapContainer,
  Marker,
  Popup,
  TileLayer,
  useMap,
  LayersControl,
  LayerGroup,
  WMSTileLayer,
  ImageOverlay,
} from "react-leaflet";
import { divIcon, point } from "leaflet";
import {
  useEffect,
  useLayoutEffect,
  useMemo,
  useState,
  useRef,
  isValidElement,
  memo,
} from "react";
import { TARANTO_COORDS } from "app/store/evocsSlice/maps/utils";
import MarkerClusterGroup from "react-leaflet-cluster";
import { FullscreenControl } from "react-leaflet-fullscreen";
import L from "leaflet";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Typography,
  Button,
  Tabs,
  Tab,
  Box,
} from "@mui/material";
import useMobile from "src/app/hooks/useMobile";
import { GoGraph } from "react-icons/go";
import { useSelector } from "react-redux";
import NoDataMessage from "./NoDataMessage";
import { useNavigate } from "react-router-dom";
import { routesNames, viewNames } from "app/configs/routesNames";
import CopernicusLogo from "src/app/asset/images/copernicus.png";
import ReactDOMServer from "react-dom/server";
import defaultMarker from "src/app/asset/markers/DefaultMarker.png";
import LeafletVelocity from "./LeafletVelocity";
import { useTranslation } from "react-i18next";
import { translate } from "src/utilities/utils";
import { setTimeRangeFilter } from "app/store/evocsSlice/filters/filtersActions";
import { parse } from "date-fns";
import "leaflet-easyprint";
import 'leaflet.fullscreen/Control.FullScreen.css' 

const createClusterCustomIcon = (cluster, clusterColor) => {
  const styledIcon = (
    <span
      className="custom-marker-cluster"
      style={{ border: `5px solid ${clusterColor}` }}
    >
      {cluster.getChildCount()}
    </span>
  );

  return divIcon({
    html: ReactDOMServer.renderToString(styledIcon),
    iconSize: point(33, 33, true),
  });
};

const iconStyle = {
  position: "absolute",
  top: "35%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  color: "white",
  fontSize: 20,
};

const createBaseMarkerIcon = (icon, iconType) => {
  const customIcon = () => {
    return (
      <div style={{ position: "relative" }}>
        <div style={iconStyle}>{iconType}</div>
        <img src={icon || defaultMarker} style={{ width: 50, height: 50 }} />
      </div>
    );
  };
  return ReactDOMServer.renderToString(customIcon());
};

const CustomMarker = ({
  marker,
  selected = false,
  onClick,
  popupContent,
  popupTableContent,
}) => {
  const markerRef = useRef();
  const { t } = useTranslation("evocs");
  const handleTranslate = (textId, general = false) => {
    if (general) return translate(t, textId);
    return translate(t, textId, viewNames.DASHBOARD_VIEW);
  };
  const customIcon = useMemo(() => {
    if (!isValidElement(marker.icon)) {
      // return createBaseMarkerIcon(marker.icon);
      return divIcon({
        html: createBaseMarkerIcon(marker.icon, marker.iconType),
      });
      // return new Icon({
      //   iconUrl: marker.icon || tilebytesMarker,
      //   iconSize: marker.size || [40, 40],
      //   className: "mapMarker",
      // });
    } else {
      return divIcon({
        html: ReactDOMServer.renderToString(marker.icon),
      });
    }
  }, [marker.icon]);
  const [xs, sm, md] = useMobile();

  useEffect(() => {
    let timeout;
    if (selected) {
      timeout = setTimeout(() => markerRef?.current?.openPopup(), 600);
    } else {
      markerRef?.current?.closePopup();
    }

    return () => {
      timeout && clearTimeout(timeout);
    };
  }, [selected]);

  const AlertTable = ({ stationId, stationSerial }) => {
    if (!popupTableContent) return null;

    const alertCategories = useSelector(
      (state) => state?.configuration?.constants?.categoryAlert
    );
    const alertTypes = useSelector(
      (state) => state?.configuration?.constants?.alert
    );
    const [tableContent, setTableContent] = useState();
    const dataCatId = "65f2df23a2a783c7dd07f623";
    const maintanceCatId = "65f2df23a2a783c7dd07f622";
    const sharingCatId = "65f2df23a2a783c7dd07f624";
    const othersCatId = "65f2df23a2a783c7dd07f625";
    const [activeTab, setActiveTab] = useState(dataCatId);
    const navigate = useNavigate();

    const maintanceAlertColumns = [
      "categoryAlert",
      "data",
      "tipo alert",
      "tenant",
      "_id",
    ];
    const sharingAlertColumns = [
      "categoryAlert",
      "data",
      "tipo alert",
      "tenant",
      "ha condiviso con",
      "_id",
    ];

    const dataAlertColumns = {
      categoryAlert: handleTranslate("categoryAlert", true),
      data: handleTranslate("DATE", true),
      "tipo alert": handleTranslate("ALERT_TYPE", true),
      device: handleTranslate("DEVICE", true),
      misura: handleTranslate("MEASURE", true),
      valore: handleTranslate("VALUE", true),
      "unita di misura": handleTranslate("UNIT_OF_MEASUREMENT", true),
      "soglia massima": handleTranslate("MAX_THRESHOLD", true),
      "soglia minima": handleTranslate("MIN_THRESHOLD", true),
      "n superamenti": handleTranslate("THRESHOLD_EXCEEDED", true),
      "tipo device": handleTranslate("DEVICE_TYPE", true),
    };

    useEffect(() => {
      if (!popupTableContent) return;
      if (tableContent) return;

      let dataAlert = popupTableContent
        .filter((alert) => alert["categoryId"] === dataCatId)
        .map((alert) => Object.keys(dataAlertColumns).map((key) => alert[key]));

      // dataAlertColumns.map((key) => alert[key])
      const maintanceAlert = popupTableContent
        .filter((alert) => alert["categoryId"] === maintanceCatId)
        .map((alert) => maintanceAlertColumns.map((key) => alert[key]));

      const sharingAlert = popupTableContent
        .filter((alert) => alert["categoryId"] === sharingCatId)
        .map((alert) => sharingAlertColumns.map((key) => alert[key]));

      const tableContentTemp = {
        [dataCatId]: dataAlert,
        [maintanceCatId]: maintanceAlert,
        [sharingCatId]: sharingAlert,
      };

      setTableContent(tableContentTemp);
    }, []);

    const handleTabChange = (event, newValue) => {
      setActiveTab(newValue);
    };

    const columns = (key) => {
      switch (key) {
        case dataCatId: //dataCategory
          return dataAlertColumns;
        case maintanceCatId: //maintanceAlert
          return maintanceAlertColumns;
        case sharingCatId: //sharingAlert
          return sharingAlertColumns;
        default:
          return [];
      }
    };

    const handleGotoGraph = (date) => {
      const newDate = parse(date, "dd/MM/yyyy HH:mm:ss", new Date());
      const startDate = newDate.setSeconds(0, 0).valueOf() - 3600000;
      let endDate = startDate + 3600000 * 2;
      if (endDate > new Date().valueOf()) endDate = new Date().valueOf();
      setTimeRangeFilter(
        viewNames.SINGLE_STATION_VIEW,
        "660fbfb3a4323ac9fd6238ba",
        "65f011b0dbaa0fa4b9d66ebe",
        startDate,
        endDate
      );
      navigate(`${routesNames.STATIONS_VIEW}/${stationSerial}`, {
        state: { stationId: stationId, backView: routesNames.ALERTS_VIEW },
      });
    };

    return (
      <>
        {tableContent && (
          <>
            <Tabs
              value={activeTab}
              onChange={handleTabChange}
              aria-label="alert tabs"
            >
              {Object.keys(tableContent).map((key, i) => (
                <Tab
                  label={handleTranslate(
                    alertCategories[key].categoryAlert.toUpperCase(),
                    true
                  )}
                  value={key}
                  key={key}
                />
              ))}
            </Tabs>
            {Object.keys(tableContent).map(
              (key) =>
                activeTab === key &&
                (tableContent[key].length > 0 ? (
                  <TableContainer component={Paper} key={key}>
                    <Table size="small" aria-label="responsive table">
                      <TableHead>
                        <TableRow>
                          {Object.keys(columns(key)).map((column, index) => (
                            <TableCell
                              key={column + "_" + index}
                              sx={{ fontWeight: "bold" }}
                            >
                              {index == 0 ? "" : dataAlertColumns[column]}
                            </TableCell>
                          ))}
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {tableContent[key].map((row, index) => (
                          <TableRow key={row._id + "_" + index}>
                            {row.map((colValue, index) => (
                              <TableCell key={index}>
                                {index === 0 && (
                                  <Button
                                    onClick={() => handleGotoGraph(row[1])}
                                  >
                                    <GoGraph fontSize={16} />
                                  </Button>
                                )}
                                {index === 2 && alertTypes[colValue]?.title}
                                {index !== 0 && index !== 2 && colValue}
                              </TableCell>
                            ))}
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                ) : (
                  <NoDataMessage message="No alerts" />
                ))
            )}
          </>
        )}
      </>
    );
  };

  const PopupContent = () => {
    const stationSerial = useSelector(
      (state) => state?.stationsMongo?.list?.[marker?.stationId]?.serial
    );

    const valueDisplay = (key) => {
      if (!popupContent || !key || !(popupContent[key].value || popupContent[key].values)) return;
      let value;
      if ('value' in popupContent[key])
        value = popupContent[key].value;
      else if ('values' in popupContent[key])
        value = popupContent[key].values;
      else return;
      if (Array.isArray(value)) {
        if (value.every(item => typeof item === 'string'))
          return value.join(", ");
        if (value.every(item => 
          typeof item === 'object' && 
          item !== null &&
          'label' in item &&
          'value' in item))
          return value.map((el, index) => <div key={`${el.label}_${index}`}><label><strong> {el.label} </strong>: {el.value ?? handleTranslate("NO_DATA", true)} </label><br/></div>)
      } else return value;
    };


    const { t } = useTranslation("evocs");
    const handleTranslate = (textId, general = false) => {
      if (general) return translate(t, textId);
      return translate(t, textId, viewNames.DASHBOARD_VIEW);
    };

    if (popupTableContent) {
      return (
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: "0.5rem",
            overflow: "auto",
          }}
          width={xs ? 250 : sm ? 350 : md ? 550 : 1000}
          height={xs ? 350 : 400}
        >
          <Typography
            gutterBottom
            fontSize={xs ? "16px" : "18px"}
            fontWeight={"bold"}
            textAlign={"center"}
          >
            Alert {handleTranslate("STATION", true)} {stationSerial}
          </Typography>
          <div
            style={{
              margin: "0.5rem",
              display: "flex",
              flexDirection: "column",
              gap: "0.5rem",
            }}
          >
            <label
              style={{
                fontSize: `${xs ? "12px" : "14px"}`,
                fontWeight: "bold",
              }}
            >
              {handleTranslate("LATITUDE", true) + ": "}
              <span style={{ fontWeight: "normal" }}>{marker.lat} </span>
            </label>
            <label
              style={{
                fontSize: `${xs ? "12px" : "14px"}`,
                fontWeight: "bold",
              }}
            >
              {handleTranslate("LONGITUDE", true) + ": "}

              <span style={{ fontWeight: "normal" }}> {marker.lon}</span>
            </label>
          </div>
          <AlertTable
            stationId={marker.stationId}
            stationSerial={stationSerial}
          />
        </Box>
      );
    } else {
      // console.log('popupContent: ', popupContent);
      return (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            gap: "0.5rem",
            width: "200px",
            maxHeight: "200px",
            overflow: "auto",
          }}
        >
          {popupContent?.title && (
            <h2 style={{ textAlign: "center" }}> {popupContent.title} </h2>
          )}
          <label><strong> {handleTranslate("MEASURE_LAT", true)} </strong> : {marker.lat}</label>
          <label><strong> {handleTranslate("MEASURE_LON", true)} </strong> : {marker.lon}</label>
          {popupContent &&
            Object.keys(popupContent).map(
              (key, index) => {
                if (key !== "title" &&
                key != "id" &&
                key != "_id") {
                  return (
                    < div key={index}>
                    {popupContent[key]?.title && <h3 style={{ textAlign: "center", marginBottom: popupContent[key]?.subtitle ? "0" : "0.5rem" }}> {popupContent[key].title} </h3>}
                    {popupContent[key]?.subtitle && <h5 style={{ textAlign: "center", marginBottom: "0.5rem"}}> ({popupContent[key].subtitle}) </h5>}
                    {popupContent[key]?.label && popupContent[key]?.value &&  <label><strong> {popupContent[key].label} </strong>: {valueDisplay(key) ?? handleTranslate("NO_DATA", true)}</label>}
                    {popupContent[key]?.values && <div>{valueDisplay(key)}</div>}
                   </div> 
                  )
                }
              })}
        </div>
      );
    }
  };

  return (
    <Marker
      ref={markerRef}
      position={[marker.lat, marker.lon]}
      icon={customIcon}
      eventHandlers={{
        click: (e) => {
          onClick(marker.id, marker);
        },
      }}
    >
      <Popup
        closeButton={false}
        maxWidth={"70vw"}
        // maxHeight={'60%'}
        // maxWidth={xs ? 300 : sm ? 600 : md ? 900 : 1200}
        // maxHeight={xs ? 300 : sm ? 400 : md ? 500 : 700}
      >
        <PopupContent />
      </Popup>
    </Marker>
  );
};

const SetView = ({ coords, zoom, autoZoom, onClosePopup, onDoubleClick, onGetMap }) => {
  const map = useMap();

  useEffect(() => {
    onGetMap?.(map);
  }, [map])

  useEffect(() => {
    if (!coords) return;
    const mapZoom = map.getZoom();
    const newZoom = !autoZoom
      ? mapZoom
      : mapZoom < autoZoom
      ? autoZoom
      : mapZoom;
    map?.flyTo(coords, newZoom, { animate: true, duration: 0.5 });
  }, [coords]);

  useEffect(() => {
    const onPopupClose = () => {
      onClosePopup && onClosePopup();
    };
    const handleDoubleClick = (e) => {
      onDoubleClick && onDoubleClick(e);
    };

    map?.addEventListener("dblclick", handleDoubleClick);
    map?.addEventListener("click", onPopupClose);

    return () => {
      map?.removeEventListener("click", onPopupClose);
      map?.removeEventListener("click", handleDoubleClick);
    };
  }, [onClosePopup, onDoubleClick]);

  return null;
};

function MapPrint(props) {
  const map = useMap();
  useEffect(() => {
    const control = L.easyPrint({
      ...props,
    });
    map.addControl(control);
    return () => {
      map.removeControl(control);
    };
  }, [map]);

  return null;
}

const EcmwfOverlay = (payload) => {
  const { name, layer } = payload;

  return (
    <LayersControl.Overlay name={name}>
      <LayerGroup>
        <WMSTileLayer
          url="http://eccharts.ecmwf.int/wms/?"
          layers={layer}
          format="image/png"
          transparent={true}
          opacity={0.6}
          zIndex={9000}
          params={{
            token: "public",
            request: "GetMap",
            bbox: "-90,-180,90,180",
          }}
        />
      </LayerGroup>
    </LayersControl.Overlay>
  );
};
const ProtCivOverlay = (payload) => {
  const { name, layer, opacity = 0.6 } = payload;

  return (
    <LayersControl.Overlay name={name}>
      <LayerGroup>
        <WMSTileLayer
          url="https://radar-geowebcache.protezionecivile.it/service/wms?VERSION=1.1.1&TILED=true"
          layers={layer}
          format="image/png"
          transparent={true}
          opacity={opacity}
          zIndex={9000}
          params={{
            // token: "public",
            // request: "GetMap",
            // bbox: "-90,-180,90,180",
            name: "WMI",
            overlay: true,
            control: true,
          }}
        />
      </LayerGroup>
    </LayersControl.Overlay>
  );
};


const customControlStyleBottomLeft = {
  position: "absolute",
  bottom: "5px",
  left: "0px",
  border: "none",
  zIndex: 1000,
};

const ImageCustomControl = () => {
  return (
    <div style={customControlStyleBottomLeft}>
      <img
        src={CopernicusLogo}
        style={{ width: "80px", height: "40px", border: "none" }}
      ></img>
    </div>
  );
};

const getImgBounds = (lat, lon) => {
  if (!lat || !lon) return null;
  // console.log('getImgBounds: ', lat, lon);
  const soglia = 0.009;
  const imageBounds = [
    [lat - soglia, lon + soglia + 0.0025], //sw (angolo in basso a sx)
    [lat + soglia, lon - soglia - 0.0025], //ne (angolo in alto a dx)
  ];
  return imageBounds;
};

const CustomCluster = ({
  children,
  disableClusteringAtZoom,
  maxClusterRadius,
  clusterColor,
}) => {
  return (
    <MarkerClusterGroup
      disableClusteringAtZoom={disableClusteringAtZoom}
      maxClusterRadius={maxClusterRadius}
      iconCreateFunction={(event) =>
        createClusterCustomIcon(event, clusterColor)
      }
      polygonOptions={{
        fillColor: clusterColor,
        color: clusterColor,
        weight: 2,
        opacity: 1,
        fillOpacity: 0.3,
      }}
    >
      {children}
    </MarkerClusterGroup>
  );
};

const MapComponent = memo(
  ({
    style = {},
    center,
    defaultCenter = TARANTO_COORDS,
    zoom,
    defaultZoom = 12,
    markers = [],
    scrollWheelZoom,
    defaultScrollWheelZoom = true,
    selectedMarker,
    onClickMarker,
    onDoubleClick,
    popupsContent,
    popupsTableContent,
    onClosePopup,
    overlays = [],
    cluster = false,
    maxClusterRadius = 80,
    disableClusteringAtZoom = 18,
    clusterColor = "#92c870",
    children,
    height,
    copernicusLogo = true,
    disableDownload = true,
    disableFullView = true,
    disablePrint = true,
    disableOpenLayers = true,
    onGetMap
  }) => {
    const [centerPos, setCenter] = useState(defaultCenter);
    const [zoomVal, setZoom] = useState();
    const [scroll, setScroll] = useState(defaultScrollWheelZoom);
    const [selected, setSelected] = useState();
    const { t } = useTranslation("evocs");
    const handleTranslate = (textId, general = false) => {
      if (general) return translate(t, textId);
      return translate(t, textId, viewNames.DASHBOARD_VIEW);
    };

    const emwfLayers = [
      { name: "<span>CO<sub>2</sub></span>", layer: "composition_co2_surface" },
      { name: "<span>PM<sub>25</sub></span>", layer: "composition_pm2p5" },
      {
        name: "<span>PM<sub>10</sub></span>",
        layer: "composition_europe_pm10_analysis_surface",
      },
      { name: "<span>NO<sub>2</sub></span>", layer: "composition_no2_surface" },
      { name: "<span>O<sub>3</sub></span>", layer: "composition_o3_surface" },
      { name: "<span>CO</span>", layer: "composition_co_surface" },
      {
        name: "<span>nmV</span>",
        layer: "composition_europe_nmvoc_forecast_surface",
      },
    ];
    const protCivLayers = [
      { name: handleTranslate("MEASURE_PP", true), layer: "radar:vmi" },
      {
        name: handleTranslate("MEASURE_TEMP", true),
        layer: "radar:temperature",
      },
    ];

    const trafficLayer = {
      name: handleTranslate("TRAFFIC", true),
      layer:
        "https://mt1.google.com/vt?lyrs=h@159000000,traffic|seconds_into_week:-1&style=3&x={x}&y={y}&z={z}",
    };


    useLayoutEffect(() => {
      setCenter(center);
    }, [center]);

    // console.log('test');

    useLayoutEffect(() => {
      zoom && setZoom(zoom);
    }, [zoom]);

    useLayoutEffect(() => {
      scrollWheelZoom !== undefined && setScroll(scrollWheelZoom);
    }, [scrollWheelZoom]);

    useLayoutEffect(() => {
      selectedMarker !== undefined && setSelected(selectedMarker);
    }, [selectedMarker]);

    const handleClickMarker = (m, popupContent, index) => {
      let popup;
      if (popupContent) {
        popup = popupContent;
      } else {
        const index = popupsContent?.findIndex((e) => e.id === m.id);
        popup = popupsContent?.[index];
      }
      onClickMarker?.(m, popup, index);
    };

    const layerControlRef = useRef();
    const [xs, sm] = useMobile();

    const mapHeight = useMemo(() => {
      if (height) return height;
      const defaultMapHeight = { xs: "400px", sm: "500px" };
      const dashboardMapHeight = {
        xs: "400px",
        sm: "400px",
        md: "400px",
        lg: "100%",
      };
      if (window.location.href.includes("dashboard")) return dashboardMapHeight;
      else return defaultMapHeight;
    }, [xs, sm, window.location.href]);

    return (
      <Box sx={{width: "100%", ...style}} height={height || mapHeight}>
        <MapContainer
          className="card"
          style={{width: "100%", height: "100%", maxHeight: "100%" }}
          center={defaultCenter}
          zoom={defaultZoom}
          zoomControl={false}
          doubleClickZoom={false}
          scrollWheelZoom={scroll}
        >
          <LayersControl position="topright" ref={layerControlRef}>
            <LayersControl.BaseLayer checked name="Satellite">
              <TileLayer
                url="http://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}"
                tileSize={256}
                noWrap={true}
              />
            </LayersControl.BaseLayer>

            <LayersControl.BaseLayer name="Street v1">
              <TileLayer url="http://server.arcgisonline.com/ArcGIS/rest/services/World_Topo_Map/MapServer/tile/{z}/{y}/{x}" />
            </LayersControl.BaseLayer>

            <LayersControl.BaseLayer name="Street v2">
              <TileLayer url="http://server.arcgisonline.com/ArcGIS/rest/services/World_Street_Map/MapServer/tile/{z}/{y}/{x}" />
            </LayersControl.BaseLayer>

            <LayersControl.BaseLayer name="Openstreetmap">
              <TileLayer
                attribution='&copy; <a href="http://openstreetmap.org">OpenStreetMap</a> contributors'
                url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
              />
            </LayersControl.BaseLayer>

            {overlays.map((element, i) => (
              <LayersControl.Overlay
                key={element.id}
                checked={element.checked}
                name={element.name}
              >
                <LayerGroup>
                  {cluster ? (
                    <CustomCluster
                      disableClusteringAtZoom={disableClusteringAtZoom}
                      maxClusterRadius={maxClusterRadius}
                      clusterColor={clusterColor}
                    >
                      {element.markers?.map((m, index) => {
                        const popupIndex = popupsContent.findIndex(
                          (e) => e.id === m.id
                        );
                        return (
                          <CustomMarker
                            key={index}
                            marker={m}
                            selected={m.id === selectedMarker}
                            onClick={() =>
                              handleClickMarker(
                                m,
                                popupsContent?.[popupIndex],
                                index
                              )
                            }
                            popupContent={popupsContent?.[popupIndex]}
                          />
                        );
                      })}
                    </CustomCluster>
                  ) : (
                    element.markers?.map((m, index) => {
                      const popupIndex = popupsContent.findIndex(
                        (e) => e.id === m.id
                      );
                      return (
                        <CustomMarker
                          key={index}
                          marker={m}
                          selected={m.id === selected}
                          onClick={() =>
                            handleClickMarker(
                              m,
                              popupsContent[popupIndex],
                              index
                            )
                          }
                          popupContent={popupsContent?.[popupIndex]}
                        />
                      );
                    })
                  )}
                  {element.imageOverlays?.map((image, index) => (
                    <ImageOverlay
                      key={index}
                      url={image?.url}
                      bounds={getImgBounds(image?.lat, image?.lon)}
                      className="polarMarker"
                      interactive={true}
                      // onMouseOver={handleMouseOverImage}
                      // onMouseOut={handleMouseOutImage}
                      eventHandlers={{
                        mouseover: () => element.onHover(index),
                        mouseout: () => element.onBlur(index),
                      }}
                    />
                  ))}
                </LayerGroup>
              </LayersControl.Overlay>
            ))}
            {!disableOpenLayers && (
              <>
                {emwfLayers.map((element) => (
                  <EcmwfOverlay
                    key={element.name}
                    name={element.name}
                    layer={element.layer}
                  />
                ))}
                {protCivLayers.map((element) => (
                  <ProtCivOverlay
                    key={element.name}
                    name={element.name}
                    layer={element.layer}
                    opacity={0.8}
                  />
                ))}
                <LayersControl.Overlay name={trafficLayer.name}>
                  <WMSTileLayer url={trafficLayer.layer} layers="traffic" />
                </LayersControl.Overlay>
                <LeafletVelocity ref={layerControlRef} />
              </>
            )}

            {cluster ? (
              <CustomCluster
                disableClusteringAtZoom={disableClusteringAtZoom}
                maxClusterRadius={maxClusterRadius}
                clusterColor={clusterColor}
              >
                {markers?.map((m, index) => (
                  <CustomMarker
                    key={index}
                    marker={m}
                    selected={index === selected}
                    onClick={() =>
                      handleClickMarker(m, popupsContent?.[index], index)
                    }
                    popupContent={popupsContent?.[index]}
                  />
                ))}
              </CustomCluster>
            ) : (
              markers?.map((m, index) => (
                <CustomMarker
                  key={index}
                  marker={m}
                  selected={index === selected}
                  onClick={() => {
                    const popupContentToPass = popupsContent
                      ? popupsContent?.[index]
                      : popupsTableContent
                      ? popupsTableContent?.[index]
                      : null;
                    handleClickMarker(m, popupContentToPass, index);
                  }}
                  popupContent={
                    popupsContent ? popupsContent?.[index] : undefined
                  }
                  popupTableContent={
                    popupsTableContent ? popupsTableContent?.[index] : undefined
                  }
                />
              ))
            )}
          </LayersControl>
          <SetView
            coords={centerPos}
            autoZoom={cluster ? disableClusteringAtZoom : null}
            onClosePopup={onClosePopup}
            onDoubleClick={onDoubleClick}
            onGetMap={onGetMap}
          />
          {/* <ZoomControl position="topright" /> */}
          {!disablePrint && (
            <MapPrint
              position="topright"
              sizeModes={["A4Portrait", "A4Landscape"]}
              hideControlContainer={false}
              title="Print"
            />
          )}
          {!disableDownload && (
            <MapPrint
              position="topright"
              sizeModes={["A4Portrait", "A4Landscape"]}
              hideControlContainer={false}
              title="Export as PNG"
              exportOnly
            />
          )}

          {!disableFullView && (
            <FullscreenControl
              forceSeparateButton={false}
              position="topright"
            />
          )}
          {children}
          {copernicusLogo && <ImageCustomControl />}
        </MapContainer>
      </Box>
    );
  }
);

export default MapComponent;
