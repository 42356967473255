import { useEffect, useState } from "react";
import LoadingGif from "src/components/SharingComponents/LoadingGif";
import TableComponent from "src/components/SharingComponents/TableComponent";
import {
  getDeprecatedTenants,
  deleteDeprecatedTenants,
  restoreDeprecatedTenants,
} from "app/store/evocsSlice/tenants/tenantsActions";
import {
  setGlobalSuccess,
  setGlobalError,
} from "app/store/evocsSlice/globalError/errorActions";

export default function TenantsTab({ handleTranslate }) {
  const [isLoading, setIsLoading] = useState(true);
  const [tenants, setTenants] = useState([]);
  const [data, setData] = useState(tenants);
  const [filteredData, setFilteredData] = useState([]);
  const [searchedText, setSearchedText] = useState("");

  useEffect(() => {
    setIsLoading(true);
    getDeprecatedTenants()
      .then((res) => {
        setTenants(res);
      })
      .finally(() => setIsLoading(false));
  }, []);

  const col = {
    name: handleTranslate("NAME", true),
    site: handleTranslate("HEADQUARTER", true),
    email: "Email",
  };
  useEffect(() => {
    setData(
      tenants?.map((tenant, i) => {
        return {
          id: i.toString(),
          name: tenant.name || handleTranslate("UNKNOWN", true),
          site: tenant.sede || handleTranslate("UNKNOWN", true),
          email: tenant.email || handleTranslate("UNKNOWN", true),
        };
      })
    );
  }, [tenants]);

  useEffect(() => {
    if (!searchedText) {
      setFilteredData(data);
      return;
    }
    const filtered = data.filter((tenant) =>
      Object.keys(tenant).some((key) =>
        tenant[key]?.toLowerCase().includes(searchedText.trim().toLowerCase())
      )
    );
    setFilteredData(filtered);
  }, [searchedText, data]);

  //? Restore
  const handleRestoreRow = (selection) => {
    const filterBySelection = selection.map((deleted) => {
      const index = data.findIndex((el) => el.id === deleted);
      return tenants[index]._id;
    });
    restoreDeprecatedTenants(filterBySelection).then(() => {
      setTenants(
        tenants?.filter((tenant) => !filterBySelection.includes(tenant._id))
      );
      setGlobalSuccess(true);
    });
  };

  //!DELETE
  const handleDeleteRow = (deletedSelection) => {
    const filterByDeleted = deletedSelection.map((deleted) => {
      const index = data.findIndex((el) => el.id === deleted);
      return tenants[index]._id;
    });

    deleteDeprecatedTenants(filterByDeleted)
      .then(() => {
        setTenants(
          tenants?.filter((tenant) => !filterByDeleted.includes(tenant._id))
        );
        setGlobalSuccess(true);
      })
      .catch((error) => {
        setGlobalError(true);
        console.error("CIAO", error);
      });
  };

  if (isLoading) {
    return <LoadingGif />;
  }
  const translatedTitle = handleTranslate("RESTORE", true)

  return (
    <div>
      <div
       style={{
        marginTop: "10px",
        padding: "20px",
        fontSize: "2rem",
        fontWeight: "700",
      }}
      >
        {handleTranslate("TENANTS", true)}: {filteredData.length}
      </div>
      <TableComponent
        onCustomAction={handleRestoreRow}
        customActionTitle={translatedTitle}
        selectable
        onDeleteRows={handleDeleteRow}
        onSearch={setSearchedText}
        columns={col}
        data={filteredData}
      />
    </div>
  );
}
