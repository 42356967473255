import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import TextField from "@mui/material/TextField";
import { useState } from "react";
import { translate } from "src/utilities/utils";
import { useTranslation } from 'react-i18next';
import { viewNames } from 'app/configs/routesNames';
import {
  addVpnDevice,
  checkFields,
} from "app/store/evocsSlice/network/networkActions";
import { Button } from "@mui/material";
import useMobile from "src/app/hooks/useMobile";
import { useSelector } from "react-redux";

export default function AddDeviceModal({ addMessage }) {
  const devices = useSelector((state) => state?.networkDevices?.value);
  const [open, setOpen] = useState(false);
  const [name, setDeviceName] = useState("");
  const [port, setDevicePort] = useState();
  const [errors, setErrors] = useState({ name: false, port: false });
  const [xs, sm, md, lg, xl] = useMobile();
  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    setDeviceName("");
    setDevicePort("");
    setErrors({
      name: { exist: false, regex: false },
      port: { exist: false, regex: false },
    });
    setOpen(false);
  };

  const { t } = useTranslation("evocs");
  const handleTranslate = (textId, general = false) => {
    if (general) return translate(t, textId);
    return translate(t, textId, viewNames.INTERNAL_NETWORK_MAN_VIEW);
  };

  const handleClickAdd = (e) => {
    e.preventDefault();
    const errorsObj = checkFields(name, port);
    setErrors({
      name: {
        exist: errorsObj.name.isExisting,
        regex: errorsObj.name.regexSpecialChars,
      },
      port: {
        exist: errorsObj.port.isExisting,
        regex: errorsObj.port.regexNumber,
      },
    });

    if (
      Object.values(errorsObj.name).every((key) => !key) &&
      Object.values(errorsObj.port).every((key) => !key)
    ) {
      addVpnDevice(name, port);
      handleClose();
      addMessage();
    }
  };

  return (
    <div>
      <button
        onClick={handleOpen}
        style={{
          backgroundColor: "#92C870",
          padding: xs ? "0.5rem" : "1.4rem",
          borderRadius: "0.75rem",
          color: "black",
          fontWeight: "bold",
          textTransform: "uppercase",
          gap: "0.25rem",
        }}
      >
        <span
          style={{
            fontSize: "1.5rem",
          }}
        >
          {handleTranslate("ADD_DEVICE")}
        </span>
      </button>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          style={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: xl ? "20%" : "80%",
            padding: "1.5rem",
            backgroundColor: "white",
            borderRadius: "1.2rem",
            display: "flex",
            flexDirection: "column",
            gap: "1.5rem",
          }}
        >
          <h2
            style={{
              textAlign: "center",
              fontSize: "2.4rem",
              marginBottom: "0.5rem",
            }}
          >
            {handleTranslate("ADD_DEVICE_TITLE")}
          </h2>         
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              gap: "0.8rem",
            }}
          >
            <TextField
              required
              style={{
                width: "100%",
              }}
              id="inputDeviceName"
              label={handleTranslate("ADD_DEVICE")}
              type="text"
              value={name}
              onChange={(e) => setDeviceName(e.target.value)}
              variant="filled"
              error={errors.name.exist || errors.name.regex}
              helperText={
                errors.name.exist
                  ? handleTranslate("NAME_IN_USE")
                  : errors.name.regex
                  ? handleTranslate("ERROR_CHARS")
                  : ""
              }
            />
            <TextField
              required
              style={{
                width: "100%",
              }}
              id="inputDevicePort"
              label={handleTranslate("PORT")}
              type="number"
              value={port}
              onChange={(e) => setDevicePort(e.target.value ? Number(e.target.value) : null)}
              variant="filled"
              error={errors.port.exist || errors.port.regex}
              helperText={
                errors.port.exist
                  ? handleTranslate("PORT_IN_USE")
                  : errors.port.regex
                  ? handleTranslate("ERROR_PORT")
                  : ""
              }
            />
          </div>
          <div
            style={{
              display: "flex",
              gap: "0.2rem",
            }}
          >
            <Button
              id="btnDismiss"
              onClick={handleClose}
              style={{
                borderWidth: "1px",
                padding: "0.3rem",
                borderRadius: "0.8rem",
                marginTop: "0.3rem",
                width: "100%",
                textTransform: "uppercase"
              }}
            >
              {handleTranslate("CANCEL", true)}
            </Button>
            <Button
              id="btnSubmit"
              disabled={!name || !port}
              onClick={handleClickAdd}
              style={{
                backgroundColor: "#92C870",
                padding: "0.3rem",
                borderRadius: "0.8rem",
                marginTop: "0.3rem",
                width: "100%",
                textTransform: "uppercase"
              }}
            >
              {handleTranslate("CONFIRM", true)}
            </Button>
          </div>
        </Box>
      </Modal>
    </div>
  );
}
