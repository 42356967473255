import React, { useEffect } from "react";
import { useDropzone } from "react-dropzone";
import {
  FaFileUpload,
  FaFileImage,
  FaFilePdf,
  FaFileExcel,
  FaFileCsv,
  FaFileAlt,
  FaFileWord,
} from "react-icons/fa";
import { MdClose } from "react-icons/md";
import { useTranslation } from "react-i18next";
import { translate } from "src/utilities/utils";
import { sharingComponentNames } from "app/configs/routesNames";
import { HiDownload } from "react-icons/hi";


const FileUploader = ({ base64Files, setBase64Files, handleDonwload, disabled = false }) => {
  const { t } = useTranslation("evocs");
  const handleTranslate = (textId, general = false) => {
    if (general) return translate(t, textId);
    return translate(t, textId, sharingComponentNames.FILE_UPLOADER);
  };

  const getFileIcon = (fileName) => {
    const fileType = fileName ? fileName.split(".").pop() : "";
    const iconSize = 50;
    if (["jpg", "jpeg", "png"].includes(fileType))
      return <FaFileImage size={iconSize} className="text-orange-600" />;
    if (fileType === "pdf")
      return <FaFilePdf size={iconSize} className="text-red-600" />;
    if (["xlsx", "xls"].includes(fileType))
      return <FaFileExcel size={iconSize} className="text-green-600" />;
    if (fileType === "csv")
      return <FaFileCsv size={iconSize} className="text-green-600" />;
    if (["doc", "docx"].includes(fileType))
      return <FaFileWord size={iconSize} className="text-blue-700" />;
    return <FaFileAlt size={iconSize} className="text-gray-600" />;
  };

  const onDrop = (acceptedFiles) => {
    if (disabled) return;

    acceptedFiles.forEach((file) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        setBase64Files((prev) => [
          ...prev,
          { fileName: file.name, fileData: reader?.result?.includes(',') ? reader?.result.split(',')[1] : reader?.result },
        ]);
      };
    });
  };

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    accept: {
      "image/png": [".png"],
      "image/jpeg": [".jpeg", ".jpg"],
      "application/pdf": [".pdf"],
      "application/vnd.ms-excel": [".xls"],
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet": [".xlsx"],
      "text/csv": [".csv"],
      "application/msword": [".doc"],
      "application/vnd.openxmlformats-officedocument.wordprocessingml.document": [".docx"],
    },
    multiple: true,
    onDrop,
    disabled,
  });

  const handleDeleteFile = (index) => {
    if (disabled) return;
    setBase64Files((prev) => prev.filter((_, i) => i !== index));
  };

  const handleDownloadFile = (fileName) => {
    handleDonwload(fileName);
  };

  const drawDragArea = () => (
    <div
      {...getRootProps()}
      className={`h-full w-full flex ${
        base64Files.length === 0 && "items-center"
      } justify-center border-2 border-dashed p-6 rounded-lg text-center cursor-pointer transition ${
        disabled ? "border-gray-300 bg-gray-100 cursor-not-allowed" : isDragActive ? "border-blue-500 bg-blue-50" : "border-gray-300"
      }`}
    >
      <input {...getInputProps()} className="hidden" disabled={disabled} />
      {base64Files.length === 0 ? (
        <div className="flex flex-col justify-center items-center">
          <FaFileUpload size={60} className="text-gray-600" />
          <p className="text-gray-600 font-sans text-[14px] mt-25">
            {handleTranslate(disabled ? "UPLOAD_DISABLED" : isDragActive ? "DROP_FILES" : "DRAG_FILES")}
          </p>
        </div>
      ) : (
        filesList()
      )}
    </div>
  );

  const filesList = () => {
    if (!base64Files || base64Files.length === 0) return null;
    return (
      <div className="mt-20">
        <h4 className="text-lg font-semibold text-center mb-10">
          {disabled ?  handleTranslate("RELATED_DOCUMENTS") : handleTranslate("DROP_FILES")}
        <div>
          <span className="italic font-light text-sm"> ({handleTranslate("CLICK_TO_DOWNLOAD")}) </span>
        </div>
        </h4>
        <div className="grid grid-cols-3 gap-4">
          {base64Files.map((file, index) => (
            <div
              key={index}
              className="flex flex-col justify-center items-center p-4 relative cursor-pointer"
              onClick={(e) => 
                {
                  if (disabled) return handleDownloadFile(file.fileName)
                  e.stopPropagation();
                  return handleDeleteFile(index);
              } }
            >
              <div>{getFileIcon(file?.fileName)}</div>
              <div className="mt-2 text-gray-700 text-center text-sm w-2/3 truncate">
                <span>{file?.fileName}</span>
              </div>
              {disabled ? (
                <button
                  // onClick={() => handleDownloadFile(file.fileName)}
                  className="absolute left-[52%] bottom-[80%] bg-gray-900 text-white rounded-full p-1 text-lg"
                >
                  <HiDownload />
                </button>
              ) : (
                <button
                  // onClick={(e) => {
                  //   e.stopPropagation();
                  //   handleDeleteFile(index);
                  // }}
                  className="absolute left-[52%] bottom-[80%] bg-blue-gray-900 text-white rounded-full p-1 text-lg"
                >
                  <MdClose />
                </button>
              )}
            </div>
          ))}
        </div>
      </div>
    );
  };

  return (
    <div className="h-[80%] w-full max-w-lg max-h-lg mx-auto p-4 bg-white shadow-lg rounded-lg flex flex-col mb-20">
      {drawDragArea()}
    </div>
  );
};

export default FileUploader;
