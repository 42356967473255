import { Checkbox, FormControl, FormHelperText, InputLabel, MenuItem, Select } from "@mui/material";
import { useCallback, useEffect, useLayoutEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { inputsComponentsNames } from 'app/configs/routesNames';
import { useTranslation } from 'react-i18next';
import { translate } from "src/utilities/utils";


const RoleSelect = ({ style, rolesList, defaultValue, placeholder, value, required = false, onChange, multiple = false, disabled = false, tenantId, error=false, helperText=""}) => {
  const { t } = useTranslation("evocs");
  const handleTranslate = (textId, general = false) => {
    if (general) return translate(t, textId);
    return translate(t, textId, inputsComponentsNames.INPUTS);
  };
  const user = useSelector((state) => state.user);
  const tenants = useSelector((state) => state.tenants.list);
  const roles = useMemo(() => {
    const id = tenantId ?? user.tenantId;
    return tenants?.[id]?.roles;
  }, [tenantId])
  const [options, setOptions] = useState([]);
  const [val, setVal] = useState(defaultValue ? defaultValue : multiple ? [] : "");
  placeholder = placeholder ?? handleTranslate('ROLE', true);

  useEffect(() => {
    if (rolesList) setOptions(rolesList);
    else setOptions(roles.map((role) => role._id));
  }, [tenantId, rolesList])

  useEffect(() => {
    value !== undefined && setVal(value);
  }, [value]);


  const handleChange = (selected) => {
    setVal(selected);
    if (onChange) onChange(selected)
  };

  const getRoleName = useCallback((roleId) => {
    return roles.find((element) => element._id === roleId)?.roleName;
  }, [roles]);


  return (
    <FormControl error={error} disabled={disabled} sx={{ ...style, width: "100%", minWidth: "100px", maxWidth: "300px" }}>
      <InputLabel id="owner-select-label">{required ? `${placeholder}*` : placeholder}</InputLabel>
      <Select
        labelId="owner-select-label"
        value={val}
        multiple={multiple}
        renderValue={(selected) => multiple ? selected.map((roleId) => getRoleName(roleId)).join(", ") : getRoleName(selected)}
        label={required ? `${placeholder}*` : placeholder}
        onChange={(e) => handleChange(e.target.value)}
      >
        {options.map((roleId, index) => (
          <MenuItem key={index} value={roleId}>
            {multiple && <Checkbox checked={val?.indexOf(roleId) > -1} />}
            {getRoleName(roleId)}
          </MenuItem>
        ))}
      </Select>
      <FormHelperText>{helperText}</FormHelperText>
    </FormControl>
  )
}

export default RoleSelect;