import React, { useEffect, useState } from "react";
import DownloadSet from "./DownloadSet";
import DownloadFileFromTable from "./DownloadFileFromTable";

const DownloadComponent = ({
  userActions,
  startDate,
  endDate,
  stationId,
  cuId,
  sensorId,
  unitSerial,
  measureIds = [],
  fromTable = false,
  onDownloadFile,
  fileName,
}) => {

  const [disableCsv, setDisableCsv] = useState(true);
  const [disableJson, setDisableJson] = useState(true);
  const [disableExcel, setDisableExcel] = useState(true);
  const [disablePdf, setDisablePdf] = useState(true);

  useEffect(() => {
    let csv = true,
      json = true,
      excel = true,
      pdf = true;
    if (!userActions) {
      (csv = false), (json = false), (excel = false), (pdf = false);
    } else {
      userActions?.forEach((action) => {
        switch (action.name) {
          case "download-csv":
            csv = false;
            break;
          case "download-json":
            json = false;
            break;
          case "download-excel":
            excel = false;
            break;
          case "download-pdf":
            pdf = false;
            break;
        }
      });
    }

    setDisableCsv(csv);
    setDisableJson(json);
    setDisableExcel(excel);
    setDisablePdf(pdf);
  }, [userActions]);

  if (disableCsv && disableExcel && disableJson && disablePdf) return;

  return !fromTable ? (
    <DownloadSet
      startDate={startDate}
      endDate={endDate}
      stationId={stationId}
      cuId={cuId}
      sensorId={sensorId}
      measureIds={measureIds}
      unitSerial={unitSerial}
      disableCsv={disableCsv}
      disableExcel={disableExcel}
      disableJson={disableJson}
      disablePdf={disablePdf}
    />
  ) : (
    <DownloadFileFromTable
      onDownloadFile={onDownloadFile}
      fileName={fileName}
      disableCsv={disableCsv}
      disableExcel={disableExcel}
      disableJson={disableJson}
      disablePdf={disablePdf}
    />
  );
};

export default DownloadComponent;
