import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";

const findAlertsPositions = createAsyncThunk(
  "maps/findAlertsPositions",
  async (payload) => {
    const { alertIds, userId } = payload;
    const data = await axios
      .post("/api/py/findAlertsPositions", {
        alertIds: alertIds,
        userId: userId,
      })
      .then((res) => {
        return res.data;
      })
      .catch((error) => {
        console.log("Error: ", error);
        throw error;
      });
    return data;
  }
);

const getPlanetekCapabilities = createAsyncThunk(
  "maps/getPlanetekCapabilities",
  async () => {
    const data = await axios.get("/api/py/getPlanetekCapabilities")
      .then((res) => {
        return res.data;
      })
      .catch((error) => {
        console.log("Error: ", error);
        throw error;
      });
    return data;
  }
);
const getPlanetekLegend = createAsyncThunk(
  "maps/getPlanetekLegend",
  async (payload) => {
    const { layer, width, height } = payload;
    const data = await axios
      .get("/api/py/getPlanetekLegend", {
        params: {
          layer,
          width, 
          height
        },
      })
      .then((res) => {
        return res.data;
      })
      .catch((error) => {
        console.log("Error: ", error);
        throw error;
      });
    return data;
  }
);
const getPlanetekImageWms = createAsyncThunk("maps/getPlanetekImageWms", 
  async ({ bbox, timestamp, layers }) => {
    const data = await axios.post("/api/py/readImageWms", {
      bbox,
      width: 800,
      // width: 1280,
      height: 600,
      // height: 720,
      timestamp,
      layers,
    }
  ).then((res) => {
      return res.data;
    }).catch((error) => {
      console.log("Error: ", error);
      throw error;
    });
    return data;
});

const mapsSlice = createSlice({
  name: "maps",
  initialState: {
    // planetek: {
    //   format: [],
    //   crs: [],
    //   layers: [],
    // },
  },
  reducers: {
    // getPlanetekLayers: (state, action) => {
    //   return state.planetek.layers;
    // },
    // getPlanetekCrs: (state, action) => {
    //   return state.planetek.crs;
    // },
    // getPlanetekFormat: (state, action) => {
    //   return state.planetek.format;
    // },
  },
  extraReducers: {
    // [getPlanetekCapabilities.fulfilled]: (state, action) => {
    //   const { format, crs, layers } = action.payload;
    //   state.planetek.format = format;
    //   state.planetek.crs = crs;
    //   state.planetek.layers = layers;
      
    //   // if (layers) {
    //   //   const updatedLayers = layers.map(layer => ({
    //   //     ...layer,
    //   //     dimension: groupTimestampsByYearAndMonth(layer?.dimension)
    //   //   }));
    //   //   state.planetek.layers = updatedLayers;
    //   // } else {
    //   //   state.planetek.layers = layers;
    //   // }
    // },
  },
  
});

export const mapsSliceActions = {
  ...mapsSlice.actions,
  findAlertsPositions,
  getPlanetekCapabilities,
  getPlanetekLegend,
  getPlanetekImageWms,
};
export default mapsSlice.reducer;
