import WithAuth from "./WithAuth";
import OpenDataAirContent from "src/components/OpenDataAir/OpenDataAirContent";
import { viewNames } from "app/configs/routesNames";
import CustomPageCarded from "./CustomPageCarded";
import Footer from "src/components/SharingComponents/Footer";
import OpenDataAirHeader from "src/components/OpenDataAir/OpenDataAirHeader";

const OpenDataAir = () => {
  return (
    <WithAuth
      viewName={viewNames.OPEN_DATA_AIR_QUALITY_VIEW}
      component={
        <CustomPageCarded
          header={<OpenDataAirHeader />}
          content={<OpenDataAirContent />}
          footer={<Footer />}
        />
      }
    />
  );
};

export default OpenDataAir;
