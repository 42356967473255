import store from "../.."
import { getFilters } from "../filters/filtersActions";
import { tenantsSliceActions } from "./tenantsSlice"

const tileBytesTenantId = "6554a3a2af4b91cdd6da3285";

export const getTenants = async(tenantId=null) => {
    setLoading();
    const data = await store.dispatch(tenantsSliceActions.getTenants({tenantId: tenantId})).then(({payload, error}) => {
        if(error) throw error;
        return payload;
    });
    setLoading(false);
    return data;
};

export const createTenant = async(name, email=null, headquarter, limitUsers=null, sharingTenants=null, proPic=null, enabledCategoryAlerts, enabledViews, enabledActions) => {
    const user = store.getState().user;
    const data = await store.dispatch(tenantsSliceActions.createTenant({name, email, headquarter, limitUsers, sharingTenants, proPic, enabledCategoryAlerts, enabledViews, enabledActions})).then(({payload, error}) => {
        if(error) throw error;
        return payload;
    });
    getFilters(user.tenantId === tileBytesTenantId ? null : user.tenantId);
    return data;
};

export const updateTenant = async(tenantId, name=undefined, email=undefined, headquarter=undefined, limitUsers=undefined, sharingTenants=undefined, proPic=undefined, enabledCategoryAlerts, enabledViews, enabledActions) => {
    const user = store.getState().user;
    const data = await store.dispatch(tenantsSliceActions.updateTenant({tenantId, name, email, headquarter, limitUsers, sharingTenants, proPic, enabledCategoryAlerts, enabledViews, enabledActions})).then(({payload, error}) => {
        if(error) throw error;
        return payload;
    });
    getFilters(user.tenantId === tileBytesTenantId ? null : user.tenantId)
    return data;
};

export const deleteTenant = async(tenantId) => {
    const user = store.getState().user;
    const data = await store.dispatch(tenantsSliceActions.deleteTenant({tenantId})).then(({payload, error}) => {
        if(error) throw error;
        return true;
    });
    getFilters(user.tenantId === tileBytesTenantId ? null : user.tenantId);
    return data;
};

export const getTenantRole = async(tenantIds) => {
    if(!tenantIds) throw new Error("tenantIds cannot be null");
    const data = await store.dispatch(tenantsSliceActions.getRole(tenantIds)).then(({payload, error}) => {
        if(error) throw error;
        return payload;
    });

    return data;
};

export const updateTenantRole = async(roleId, roleName, description=null, activeViews, categoryAlerts, actions) => {
    const data = await store.dispatch(tenantsSliceActions.updateRole({roleId, roleName, description, activeViews, categoryAlerts, actions})).then(({payload, error}) => {
        if(error) throw error;
        return payload;
    });
    return data;
}

export const updateActionsRole = async(tenantId, roleId, actions) => {
    if(!tenantId || !roleId) throw new Error("missing parameters");
    const tenantRole = store.getState().tenants.list[tenantId].roles.find((element) => element._id === roleId);
    let actionsDict = {[roleId]: {}};
    Object.keys(actions).forEach((viewId) => {
        actionsDict[roleId][viewId] = {};
        Object.keys(tenantRole.actions[viewId]).forEach((actionId) => {
            actionsDict[roleId][viewId][actionId] = actions[viewId].includes(actionId) ? 1 : 0;
        });
    });

    const data = await store.dispatch(tenantsSliceActions.updateActionsRole({tenantId, roleId, actionsDict})).then(({payload, error}) => {
        if(error) throw error;
        return payload;
    });

    return data;
};

export const deleteTenantRole = async(tenantId, roleId) => {
    if(!tenantId || !roleId) throw new Error("missing parameters");
    const data = await store.dispatch(tenantsSliceActions.deleteRole({tenantId, roleId})).then(({payload, error}) => {
        if(error) throw error;
        return payload;
    });

    return data;
};

export const getTenantsProPic = async(tenantIds) => {
    if(!tenantIds) throw new Error("tenantIds cannot be null");
    const data = await store.dispatch(tenantsSliceActions.getTenantsProPic(tenantIds)).then(({payload, error}) => {
        if(error) throw error;
        return payload;
    });
    return data;
};

export const createTenantRole = async(tenantId, roleName, listUsers, descr, viewIds, enabledActions, categoryIds) => {
    if(!tenantId) throw new Error("tenantId cannot be null");
    const data = await store.dispatch(tenantsSliceActions.createRole({tenantId, roleName, listUsers, descr, viewIds, enabledActions, categoryIds})).then(({payload, error}) => {
        if(error) throw error;
        return payload;
    });
    return data;
}

export const getTenantStations = async(tenantIds) => {
    if(!tenantIds || !Array.isArray(tenantIds)) throw new Error("tenantIds is required and it must be an array.")
    const data = store.dispatch(tenantsSliceActions.getTenantStations(tenantIds)).then(({payload, error}) => {
        if(error) return null;
        return payload;
    })
    return data;
};

export const getTenantCus = async(tenantIds) => {
    const data = store.dispatch(tenantsSliceActions.getTenantCus(tenantIds)).then(({payload, error}) => {
        if(error) return null;
        return payload;
    })
    return data;
};

export const getTenantSensors = async(tenantIds) => {
    const data = store.dispatch(tenantsSliceActions.getTenantSensors(tenantIds)).then(({payload, error}) => {
        if(error) return null;
        return payload;
    })
    return data;
};

export const getDeprecatedTenants = async () => {
  const data = await store
    .dispatch(tenantsSliceActions.getDeprecatedTenants())
    .then(({ payload, error }) => {
      if (error) throw error;
      return payload;
    });
  return data;
};

export const deleteDeprecatedTenants = async (selectedIds) => {
  const data = await store
    .dispatch(tenantsSliceActions.deleteDeprecatedTenants(selectedIds))
    .then(({ payload, error }) => {
      if (error) throw error;
      return payload;
    });
  return data;
};

export const restoreDeprecatedTenants = async (selectedIds) =>{
  const data = await store
  .dispatch(tenantsSliceActions.restoreDeprecatedTenants(selectedIds))
  .then(({payload, error})=>{
    if(error) throw error
    return payload
  })
  return data
}

const setLoading = (loadingState = true) => {
  store.dispatch(tenantsSliceActions.setLoading(loadingState));
};
